import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Button } from "dsl/src/atoms/Button/Button"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"

export const HeaderAddToCartButton = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [
    c.ecommerce,
    c.designAndProductDriver,
  ])

  if (!containerSet) {
    return null
  }

  const { ecommerce } = containerSet

  if (!ecommerce.available) {
    return null
  }

  const { cartStore, ecommerceController } = ecommerce
  const { isAddingToCartLocked } = cartStore
  const isApiCustomersMode = ecommerce.ecommerceStore.isApiCustomersMode

  const onClick = () => {
    ecommerceController.addToCart()
  }

  return (
    <Button
      disabled={isAddingToCartLocked}
      onClick={onClick}
      e2eTargetName="add-to-cart"
      analyticsSection="top-right-corner"
      analyticsType="button"
      analyticsName="add-to-cart"
    >
      {t(
        isApiCustomersMode
          ? I18N.generic.saveAndContinue
          : I18N.generic.addToCart
      )}
    </Button>
  )
})
