import EuRegion from "./eu"

const Region = {
  ...EuRegion,
  mainRegion: false,
  countryCode: "no",
  phoneCountryCode: "+47",
  defaultShippingCountryCode: "NO",
  additionalCustoms: 40,
  supportsPlus: false,
}

export default Region
