import hotkeys from "hotkeys-js"
import { ee, eventTree } from "../../events/editor.events"
import { ActiveTabEvents } from "../../events/partials/active-tab.events"
import { ActiveObjectEvents } from "../../events/partials/active-object.events"
import { KeyboardEvents } from "../../events/partials/keyboard.events"

export type KeyboardScope = "all" | "active-object" | "active-tab"
export type Event = ActiveTabEvents | ActiveObjectEvents | KeyboardEvents
export type EventDefinition = { down: Event; up?: Event }

export class KeyboardControlsService {
  private scopes: KeyboardScope[] = []

  private readonly keyEventMap: Record<
    KeyboardScope,
    Record<string, EventDefinition>
  > = {
    all: {
      "ctrl+v": {
        down: eventTree.activeObject.paste,
      },
      "cmd+v": {
        down: eventTree.activeObject.paste,
      },
      space: {
        down: eventTree.keyboard.spaceDown,
        up: eventTree.keyboard.spaceUp,
      },
    },
    "active-object": {
      "ctrl+c": {
        down: eventTree.activeObject.copy,
      },
      "cmd+c": {
        down: eventTree.activeObject.copy,
      },
      backspace: {
        down: eventTree.activeObject.delete,
      },
      delete: {
        down: eventTree.activeObject.delete,
      },
      up: {
        down: eventTree.activeObject.moveUp,
      },
      down: {
        down: eventTree.activeObject.moveDown,
      },
      left: {
        down: eventTree.activeObject.moveLeft,
      },
      right: {
        down: eventTree.activeObject.moveRight,
      },
      escape: {
        down: eventTree.activeObject.deselect,
      },
    },
    "active-tab": {
      up: {
        down: eventTree.activeTab.up,
      },
      down: {
        down: eventTree.activeTab.down,
      },
      enter: {
        down: eventTree.activeTab.select,
      },
      escape: {
        down: eventTree.activeTab.close,
      },
    },
  }

  constructor() {
    this.setEventListeners()
    this.setKeyboardListeners()
  }

  public setScope(scope: KeyboardScope) {
    this.scopes.push(scope)
    hotkeys.setScope(scope)
  }

  public clearScope(scope: KeyboardScope) {
    hotkeys.setScope("all")

    this.scopes = this.scopes.filter((s) => s !== scope)

    if (this.scopes.length > 0) {
      hotkeys.setScope(this.scopes[this.scopes.length - 1])
    }
  }

  private setEventListeners() {
    ee.on(eventTree.keyboard.setScope, this.setScope.bind(this))
    ee.on(eventTree.keyboard.clearScope, this.clearScope.bind(this))
  }

  private setKeyboardListeners() {
    for (const [scope, scopedKeyEventMap] of Object.entries(this.keyEventMap)) {
      for (const [key, event] of Object.entries(scopedKeyEventMap)) {
        hotkeys(key, { scope, keyup: !!event.up }, (keyboardEvent) => {
          if (keyboardEvent.type === "keydown") {
            ee.emit(event.down)
          }

          if (event.up && keyboardEvent.type === "keyup") {
            ee.emit(event.up)
          }
        })
      }
    }
  }
}
