export const forms = {
  FORM_LOGIN: "formLogin",
  FORM_REGISTER: "formRegister",
  FORM_FORGOTTEN_PASSWORD: "formForgottenPassword",
  FORM_SET_PASSWORD: "formSetPassword",
  FORM_ORDER_DETAILS_GROUP: "formOrderDetailsGroup",
  FORM_SHARE_BOX_DESIGN: "formShareBoxDesign",
  FORM_ORDER_DETAILS_SECTION_DISCOUNT_CODES:
    "formOrderDetailsSectionDiscountCodes",
  FORM_USER_DETAILS_GROUP: "formUserDetailsGroup",
  FORM_USER_DETAILS_PERSONAL: "formUserDetailsSectionPersonal",
  FORM_USER_DETAILS_COMPANY: "formUserDetailsSectionCompany",
  FORM_USER_DETAILS_DELIVERY: "formUserDetailsSectionDelivery",
  FORM_STORE_AGREEMENTS: "formAgreements",
  FORM_SAVE_DESIGN: "formSaveDesign",
  FORM_GET_GUIDE: "formSaveDesign",
}
