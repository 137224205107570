import {
  VirtualDielineDataLessObject,
  VirtualDielineObject,
} from "../../../types/asset.types"
import { VirtualDielineObjectParser } from "./virtual-dieline-object.parser"
import type { DesignDto } from "@ph/design-api"

export class ProductDesignDtoParser {
  constructor(private readonly dto: DesignDto) {}

  public parse() {
    for (const vdData of this.virtualDielines) {
      this.parseObjects(vdData.objects)
    }
  }

  private parseObjects(objects: VirtualDielineObject[]) {
    for (const object of objects) {
      new VirtualDielineObjectParser(object).parse()
    }
  }

  private get virtualDielines(): VirtualDielineDataLessObject[] {
    if (this.designData.type === "dby") {
      return []
    }

    return Object.values(this.designData.virtual_dielines)
  }

  private get designData() {
    return this.dto.design_data
  }
}
