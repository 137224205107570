import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import { Select } from "dsl/src/atoms/Select/Select"
import { QuantityPicker } from "../../QuantityPicker/QuantityPicker"
import { PropertyGroupSection } from "./partials/PropertyGroupSection"
import { RelatedProductsSection } from "./partials/RelatedProductsSection"
import { FormGroup } from "../../../molecules/FormGroup/FormGroup"

export const TabConfiguration = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui, c.ecommerce])

  if (!containerSet) {
    return null
  }

  const { ui, ecommerce } = containerSet
  const { configurationTabController } = ui
  const {
    selectedSize,
    isChangeSizeAvailable,
    propertyGroups,
    currentSku,
    isRelatedProductSectionAvailable,
    relatedProducts,
    isAnyActionDisabled,
  } = configurationTabController

  const onSizeClick = () => {
    configurationTabController.openSizeTab()
  }

  const onProductClick = (sku: string) => {
    configurationTabController.changeProduct(sku)
  }

  return (
    <Tab title={t(I18N.tabs.configuration)} e2eTargetName="configuration">
      <FormGroup label={t(I18N.tabs.size)}>
        <Select
          items={[]}
          selectedItem={{
            id: selectedSize,
            name: selectedSize,
          }}
          size={SelectSizes.medium}
          disabled={!isChangeSizeAvailable}
          onClick={onSizeClick}
          hideMenu
          analyticsSection="left-tab-configuration"
          analyticsType="select"
          analyticsName="size"
          e2eTargetName="size"
        />
      </FormGroup>

      {ecommerce.available && (
        <FormGroup label={t(I18N.generic.quantity)}>
          <QuantityPicker analyticsSection="left-tab-configuration" />
        </FormGroup>
      )}

      {isRelatedProductSectionAvailable && (
        <RelatedProductsSection
          products={relatedProducts}
          onProductClick={onProductClick}
          currentSku={currentSku}
          isAnyActionDisabled={isAnyActionDisabled}
        />
      )}

      {propertyGroups.map((group) => {
        return (
          <PropertyGroupSection
            key={group.type}
            group={group}
            onPropertyClick={onProductClick}
            currentSku={currentSku}
            isAnyActionDisabled={isAnyActionDisabled}
          />
        )
      })}
    </Tab>
  )
})
