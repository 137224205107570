import {
  IndexConfigFragments,
  PackhelpEditableObject,
} from "../../object-extensions/packhelp-objects"
import { SpaceId } from "../../../../../libs/products-render-config/types"
import { SpaceBoundingRect } from "../dieline-navigator/calculators/translation.calculator"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import { RotationHelper } from "../assets-module/helpers/rotation.helper"

export class HighlightCreator {
  constructor(private readonly dielineNavigator: DielineNavigator) {}

  public async create(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpEditableObject> {
    const highlightBgShape = await this.cloneVdSpace(spaceId)
    const { width, height, top, left } = this.getVdSpaceBoundingRect(spaceId)

    highlightBgShape.set({
      left,
      top,
      width,
      height,
      evented: false,
      selectable: false,
      visible: true,
      opacity: 0,
      id: `temp_background_${spaceId}`,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
      originSpaceArea: spaceId,
      fill: "rgba(51, 83, 216, 0.7)",
      stroke: "rgba(0, 0, 0, 0)",
      strokeWidth: 0,
      strokeUniform: true,
    })

    RotationHelper.rotateObjectAroundCanvasCenter(
      highlightBgShape,
      this.dielineNavigator.getCurrentRotation()
    )

    return highlightBgShape
  }

  private async cloneVdSpace(
    spaceId: SpaceId
  ): Promise<PackhelpEditableObject> {
    const vdSpace = this.dielineNavigator.getVirtualDielineSpace(spaceId)

    return new Promise((resolve) => {
      vdSpace.clone(
        (clonedVdSpace) => {
          resolve(clonedVdSpace)
        },
        ["id", "rotation", "originSpaceArea"]
      )
    })
  }

  private getVdSpaceBoundingRect(spaceId: SpaceId): SpaceBoundingRect {
    return this.dielineNavigator.getSpaceBoundingRect(spaceId)
  }
}
