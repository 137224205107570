import React, { useRef } from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import {
  Dropzone,
  DropzoneProps,
  DropzoneRef,
} from "../../../../atoms/Dropzone/Dropzone"
import { I18N } from "../../../../../i18n"
import { ContextNav } from "../../../../atoms/ContextNav/ContextNav"
import { SettingsSection } from "./SettingsSection"
import { ContextNavItem } from "../../../../atoms/ContextNav/ContentNavItem"
import { UploadError } from "../../../../molecules/UploadError/UploadError"

const i18n = I18N.component.assetsUploader

export const ArtworkSubTab = observer(function () {
  const t = useTranslate()
  const dropzoneRef = useRef<DropzoneRef>()

  const [containerSet] = useContainerSet((c) => [c.replicablePatterns])

  if (!containerSet) {
    return null
  }

  const { replicablePatterns } = containerSet

  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns
  const { isArtworkLoading, isArtworkUploaded, imageError, imageUrl } =
    replicablePatternsStore

  const onDrop = (files: File[]) => {
    replicablePatternUiController.selectArtwork(files[0])
  }

  const onLogoReuploadClick = () => {
    dropzoneRef.current?.open()
  }

  const onLogoRemoveClick = () => {
    replicablePatternUiController.removeArtwork()
  }

  const getUploadState = (): DropzoneProps["state"] => {
    if (isArtworkLoading && !imageError) {
      return "uploading"
    }

    return "initial"
  }

  return (
    <>
      <TabSection>
        <Dropzone
          ref={dropzoneRef}
          onDrop={onDrop}
          multiple={false}
          state={getUploadState()}
          label={t(i18n.uploadImage)}
          analytics-section="left-tab-pattern"
          analytics-name="upload-image"
        >
          {isArtworkUploaded && (
            <div onClick={onLogoReuploadClick}>
              <img src={imageUrl} alt="Logo" />
            </div>
          )}
        </Dropzone>

        {imageError && <UploadError error={imageError} />}

        {isArtworkUploaded && (
          <ContextNav>
            <ContextNavItem
              label={t(i18n.reuploadLogo)}
              onClick={onLogoReuploadClick}
            />
            <ContextNavItem
              label={t(i18n.removeLogo)}
              onClick={onLogoRemoveClick}
            />
          </ContextNav>
        )}
      </TabSection>

      {isArtworkUploaded && <SettingsSection />}
    </>
  )
})
