import VirtualDielineEditor from "./modules/vd-editor/virtual-dieline-editor"
import { ThreeDimThumbnailGenerator } from "./modules/thumbnail-generator/three-dim-thumbnail.generator"
import { TwoDimThumbnailGenerator } from "./modules/thumbnail-generator/two-dim-thumbnail.generator"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { ModelContext, SpaceId } from "../libs/products-render-config/types"
import { ThumbnailGeneratorConfig } from "./types"
import { ModelConfigProvider } from "./modules/3d-renderer/model-config.provider"

const DEFAULT_CONFIG = {
  width: 1280,
  height: 720,
  mimeType: "image/webp",
  quality: 0.8,
}

export class ThumbnailGenerator {
  private readonly config: ThumbnailGeneratorConfig

  constructor(
    private readonly vdEditors: VirtualDielineEditor[],
    private readonly modelConfigProvider: ModelConfigProvider,
    private readonly productRenderPilot: ProductRenderPilot,
    config: Partial<ThumbnailGeneratorConfig> = {}
  ) {
    this.config = Object.assign({}, DEFAULT_CONFIG, config)
  }

  public async createBase64Thumbnail(is3D: boolean): Promise<string> {
    if (is3D) {
      return this.create3DThumbnail()
    }

    return this.create2DThumbnail()
  }

  /**
   * Gets canvases for preview.
   *
   * Optinally, can filter out canvases that we don't need for preview.
   * Consider
   * - poly-mailer-eu--poly-mailer--90--foil-white--mono-pantone-one-side--none
   * - poly-mailer-eu--poly-mailer--90--foil-white--mono-pantone-two-sides--none
   *
   * For `mono-pantone-one-side-` we don't need a preview of a back side
   */
  private async getVdCanvases(
    config: {
      filterOnlyActive: boolean
    } = {
      filterOnlyActive: false,
    }
  ): Promise<Record<string, HTMLCanvasElement>> {
    const activeVdEditors = this.vdEditors.filter((vdEditor) => {
      if (config.filterOnlyActive) {
        return !this.productRenderPilot.isEditContextDisabled(
          vdEditor.editContext
        )
      }

      return true
    })

    //rigid envelope
    if (this.productRenderPilot.isFoldableProduct2D()) {
      return Object.fromEntries(
        (
          await Promise.all(
            activeVdEditors.map(async (vdEditor) => {
              const defaultContext =
                vdEditor.productRenderPilot.getDefaultEditContext()

              const spaces =
                vdEditor.productRenderPilot.getContextSpaces(defaultContext)

              return await Promise.all(
                spaces.map(async (space) => {
                  const canvas = await this.getCanvasToPreview(
                    vdEditor,
                    space.spaceId
                  )

                  return [space.spaceId, canvas]
                })
              )
            })
          )
        ).flat()
      )
    }

    return Object.fromEntries(
      await Promise.all(
        activeVdEditors.map(async (vdEditor) => {
          const canvas = await this.getCanvasToPreview(vdEditor)

          return [vdEditor.editContext, canvas]
        })
      )
    )
  }

  /**
   * We don't need texture for 3D
   * - for 2D: returns canvas with applied background e.g. black polymailer
   * - for 3D: returns only user graphcis, so 3D engine can apply own texture and filters
   */
  private getCanvasToPreview(
    vdEditor: VirtualDielineEditor,
    space?: SpaceId
  ): Promise<HTMLCanvasElement> {
    return vdEditor.getCanvasToPreview(
      this.productRenderPilot.uiConfig.thumbnail,
      space
    )
  }

  private async create2DThumbnail(): Promise<string> {
    const canvases = await this.getVdCanvases({
      filterOnlyActive: true,
    })

    const twoDimThumbGenerator = new TwoDimThumbnailGenerator(
      canvases,
      this.config
    )
    return twoDimThumbGenerator.getThumbBase64()
  }

  private async create3DThumbnail(): Promise<string> {
    const modelConfig = await this.modelConfigProvider.call(
      this.productRenderPilot,
      ModelContext.CLOSED
    )
    const canvases = await this.getVdCanvases()

    return new ThreeDimThumbnailGenerator(
      canvases,
      modelConfig,
      this.config
    ).generate()
  }
}
