import { useCallback, useEffect, useState } from "react"
import _debounce from "lodash/debounce"

export const useDebouncedSearchValue = (
  searchValue: string,
  debounce: number
) => {
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>()

  const setSearchValue = useCallback(
    _debounce(setDebouncedSearchValue, debounce),
    [debounce]
  )

  useEffect(() => {
    setSearchValue(searchValue)
  }, [searchValue])

  return debouncedSearchValue
}
