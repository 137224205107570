import type { LogoUiController } from "../controllers/logo-ui.controller"
import type { LogoStore } from "../stores/logo.store"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { UtilEnvContainer } from "./util.container"
import type { TemplatesMaybeContainer } from "./templates-maybe.container"
import type { UiContainer } from "./ui.container"

async function importDeps() {
  const { LogoStore } = await import("../stores/logo.store")

  const { LogoUiController } = await import("../controllers/logo-ui.controller")

  return {
    LogoStore,
    LogoUiController,
  }
}

export async function provideLogoContainer(
  utilEnvContainer: UtilEnvContainer,
  designAndProduct: DesignAndProductDriverContainer,
  templatesContainer: TemplatesMaybeContainer,
  uiContainer: UiContainer
): Promise<LogoContainer> {
  const { LogoStore, LogoUiController } = await importDeps()

  const { productDriver, assetStore } = designAndProduct
  const { ee } = utilEnvContainer
  const { templatesStore } = templatesContainer
  const { sidebarStore } = uiContainer

  const logoStore = new LogoStore()

  const logoUiController = new LogoUiController(
    logoStore,
    templatesStore,
    sidebarStore,
    productDriver,
    assetStore,
    ee
  )

  return {
    logoStore,
    logoUiController,
  }
}

export type LogoContainer = {
  logoStore: LogoStore
  logoUiController: LogoUiController
}
