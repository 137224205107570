import React, { PropsWithChildren } from "react"
import {
  ArrowPlacements,
  TooltipDirections,
} from "dsl/src/atoms/BaseTooltip/BaseTooltip"
import { Tooltip } from "dsl/src/molecules/Tooltip/Tooltip"
import styles from "./Popover.module.scss"

export interface PopoverProps {
  text: string
  iconUrl?: string
  zIndex?: number
}

export function Popover({
  children,
  text,
  zIndex,
}: PropsWithChildren<PopoverProps>) {
  return (
    <Tooltip
      value={<div className={styles.popover}>{text}</div>}
      direction={TooltipDirections.bottom}
      arrowPlacement={ArrowPlacements.center}
      zIndex={zIndex}
    >
      <div className={styles.wrapper}>{children}</div>
    </Tooltip>
  )
}
