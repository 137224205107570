import { computed, makeObservable, observable, action } from "mobx"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import { ProductDesignStore } from "../stores/product-design.store"
import { EditorMode } from "../libs/products-render-config/types"
import { Cart } from "../stores/cart.store"
import ProductDriver from "../drivers/product.driver"
import { EcommerceStore } from "../stores/ecommerce.store"
import { SaveProductDesignController } from "./product-design/save-product-design.controller"

export class EditorModeController {
  @observable public loadingMode?: EditorMode

  private readonly productDriver: ProductDriver
  private readonly uri: UrlManipulatorProvider
  private readonly cart?: Cart
  private readonly saveProductDesignController: SaveProductDesignController
  private readonly productDesignStore: ProductDesignStore
  private readonly ecommerceStore?: EcommerceStore
  private readonly previousModeDesignId: string | null

  constructor(
    services: {
      productDriver: ProductDriver
      uri: UrlManipulatorProvider
      cart?: Cart
      saveProductDesignController: SaveProductDesignController
    },
    stores: {
      productDesignStore: ProductDesignStore
      ecommerceStore?: EcommerceStore
    }
  ) {
    this.productDriver = services.productDriver
    this.uri = services.uri
    this.cart = services.cart
    this.saveProductDesignController = services.saveProductDesignController
    this.productDesignStore = stores.productDesignStore
    this.ecommerceStore = stores.ecommerceStore
    this.previousModeDesignId = this.uri.getPreviousModeDesignId()

    makeObservable(this)
  }

  @computed
  public get editorMode(): EditorMode {
    return this.productDriver.state.productRenderPilot.getEditorMode()
  }

  @computed
  public get activeMode(): EditorMode | "skip-design" {
    if (this.isSkippingDesign) {
      return "skip-design"
    }

    return this.editorMode
  }

  @computed
  public get isEditorModeAvailable(): boolean {
    return !this.productDriver.state.productRenderPilot.isDbyOnly()
  }

  @computed
  public get isSkippingDesignAvailable(): boolean {
    return !!this.ecommerceStore?.isDesignLaterAvailable
  }

  @computed
  public get isDesignReadOnly(): boolean {
    return this.productDesignStore.isDesignReadOnly
  }

  public get isDtpPreviewMode(): boolean {
    return this.uri.isDtpPreviewMode()
  }

  @computed
  public get isDesignerMode(): boolean {
    return this.activeMode === "designer"
  }

  public async switchToDbyMode(): Promise<void> {
    if (this.editorMode === "dby" && this.isSkippingDesign) {
      return this.toggleIsSkippingDesign()
    }

    await this.switchToMode("dby")
  }

  public async switchToEditorMode(): Promise<void> {
    if (this.editorMode === "editor" && this.isSkippingDesign) {
      return this.toggleIsSkippingDesign()
    }

    await this.switchToMode("editor")
  }

  public toggleIsSkippingDesign(): void {
    this.ecommerceStore?.toggleIsSkippingDesign()
  }

  public get isAfterPurchaseEdit(): boolean {
    return !!this.ecommerceStore?.isAfterPurchaseEdit
  }

  @computed
  public get isSkippingDesign(): boolean {
    return !!this.ecommerceStore?.isSkippingDesign
  }

  private async switchToMode(mode: EditorMode): Promise<void> {
    this.setLoadingMode(mode)

    await this.saveProductDesignController.save()
    const { id: designId, quantity } = this.productDesignStore.state.meta
    const { productSku: sku } = this.productDriver.productStore

    const shouldRedirectWithinCurrentDesignId =
      this.ecommerceStore?.isAfterPurchaseEdit ||
      this.isDraftDesignAlreadyInCart

    if (designId && shouldRedirectWithinCurrentDesignId) {
      return this.uri.redirectToDesign({ designId, mode })
    }

    if (this.previousModeDesignId) {
      return this.uri.redirectToDesign({
        designId: this.previousModeDesignId,
        prevModeDesignId: designId,
        mode,
      })
    }

    this.uri.redirectToSku({
      sku,
      quantity,
      mode,
      prevModeDesignId: designId,
    })
  }

  private get isDraftDesignAlreadyInCart(): boolean {
    if (!this.cart) {
      return false
    }

    return this.cart.line_items.some((item) => {
      if (!item.design) {
        return false
      }

      return item.design.id === this.productDesignStore.state.meta.id
    })
  }

  @action
  private setLoadingMode(mode: EditorMode): void {
    this.loadingMode = mode
  }
}
