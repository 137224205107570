import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { getBoundingRect } from "../helpers/get-bounding-rect"
import { ObjectEdge, positionAttributeMap } from "../types"
import { setPositionOnAxis } from "../helpers/set-position-on-axis"

export class MoveSnapper {
  public snap(
    object: PackhelpObject,
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): void {
    const { axis, points } = nextEdge
    const boundingRect = getBoundingRect(object)
    const attr = positionAttributeMap[axis]
    const boundingRectOffset = object[attr] - boundingRect[attr]

    const newPosition =
      points.start[axis] - currentEdge.offset + boundingRectOffset

    setPositionOnAxis(object, axis, newPosition)
  }
}
