var SkuProductModel;
(function (SkuProductModel) {
    SkuProductModel["SKU_MODEL_WOOD_WOOL"] = "wood-wool";
    SkuProductModel["SKU_MODEL_SELF_ADHESIVE_ENVELOPE"] = "self-adhesive-envelope";
    SkuProductModel["SKU_MODEL_STICKER_CIRCLE"] = "sticker-circle";
    SkuProductModel["SKU_MODEL_STICKER_RECTANGLE"] = "sticker-rectangle";
    SkuProductModel["SKU_MODEL_THANK_YOU_CARD"] = "thank-you-card";
    SkuProductModel["SKU_MODEL_PAPER_BUBBLE_WRAP"] = "paper-bubble-wrap";
})(SkuProductModel || (SkuProductModel = {}));
export default SkuProductModel;
