import React from "react"
import cxBinder from "classnames/bind"
import styles from "./TileButton.module.scss"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

const cx = cxBinder.bind(styles)

interface TileButtonProps extends AnalyticsProps, TestsProps {
  label: string
  onClick: () => void
  active?: boolean
  disabled?: boolean
  iconUrl?: string
  type?: "normal" | "light"
}

export function TileButton({
  label,
  onClick,
  active = false,
  disabled = false,
  iconUrl,
  type = "normal",
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: TileButtonProps) {
  return (
    <button
      className={cx("button", type, {
        active,
      })}
      onClick={onClick}
      disabled={disabled}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {iconUrl && <SvgIcon url={iconUrl} size="xs" />}
      {label}
    </button>
  )
}
