import { Shape } from "../../../../../../models/shape"
import { PackhelpEditableShape } from "../../../object-extensions/packhelp-objects"
import FabricAssetLoader from "../../../../../../libs/services/loaders/fabric-asset.loader"

export class ShapeHelper {
  public static async shapeToPackhelpObject(
    shape: Shape
  ): Promise<PackhelpEditableShape> {
    const object = (await FabricAssetLoader.loadAssetSvg(shape.src, {
      id: shape.id,
      assetObjectMeta: shape.toSource(),
    })) as PackhelpEditableShape

    if (shape.keepRatio) {
      object.set({
        keepRatio: true,
        lockUniScaling: true,
      })
    }

    return object
  }
}
