import { INode } from "svgson"
import _groupBy from "lodash/groupBy"
import { SvgElement } from "./svg-elements/svg-element"
import { SvgElementFactory } from "./svg-elements/svg-element.factory"

const ATTRIBUTE_NAME = "data-space"

export class SpacesExtractor {
  public constructor(private readonly svgObject: INode) {}

  public call(): Record<string, SvgElement[]> {
    return _groupBy(this.spaceElements, (element) => element.getSpace())
  }

  private get spaceElements(): SvgElement[] {
    return this.svgObject.children
      .filter(({ attributes }) => !!attributes[ATTRIBUTE_NAME])
      .map((element) => {
        const spaces = element.attributes[ATTRIBUTE_NAME].split(",")

        return spaces
          .filter((space) => !parseInt(space)) // removes debug numbers
          .map((space) =>
            SvgElementFactory({
              ...element,
              attributes: {
                ...element.attributes,
                [ATTRIBUTE_NAME]: space,
              },
            })
          )
      })
      .flat()
      .filter((element) => {
        // removes dots
        const { start, end } = element.getSegment()

        return start.x !== end.x || start.y !== end.y
      })
  }
}
