import ThreeTextureLoader from "../../../../libs/services/loaders/three-texture.loader"
import { TextureDefinition } from "../../../../libs/products-render-config/types"

function threeLoadTexturesConfig(texturePaths: TextureDefinition[]) {
  return Promise.all(
    texturePaths.map(async (textureConfig) => {
      const texture = textureConfig.paths
        ? await ThreeTextureLoader.loadCubeTexture(textureConfig.paths)
        : await ThreeTextureLoader.loadTexture(textureConfig.path)

      return {
        texture: texture,
        type: textureConfig.type,
      }
    })
  )
}

export default threeLoadTexturesConfig
