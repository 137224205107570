import type { Alpha2CountryCode } from "@ph/api-studio"
import { string } from "yup"
import { i18nId } from "./translations"

export const sanitizeZipCode = (code: string): string => {
  const charsToSanitize = /[!@#$%^&*()?:{}|<>]/g
  return code.replace(charsToSanitize, "")
}

export const validateZipCode = (
  zipCode: string,
  countryIso?: Alpha2CountryCode
): boolean => {
  if (countryIso === "PL") {
    return validateZipCodeForPoland(zipCode)
  }

  return validateZipCodeForOtherCountries(zipCode)
}

const validateZipCodeForOtherCountries = (code: string): boolean => {
  const schema = string().min(3).max(9).required()

  return schema.isValidSync(code)
}

const validateZipCodeForPoland = (code: string): boolean => {
  const schema = string()
    .length(6)
    .matches(/^[0-9]{2}-[0-9]{3}/, {
      message: i18nId.ZipCodeValidationError,
      excludeEmptyString: true,
    })
    .required()

  return schema.isValidSync(code)
}
