function radiansToDegrees(radians) {
  const pi = Math.PI
  return radians * (180 / pi)
}

function degreesToRadians(degrees) {
  const pi = Math.PI
  return degrees * (pi / 180)
}

export { radiansToDegrees, degreesToRadians }
