import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import changeIconUrl from "../../../../assets/_shape-icons/change.svg"
import { I18N } from "../../../../i18n"
import { Modal } from "../Modal/Modal"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.modals.changeMode

export const ModalChangeEditorModeConfirmation = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { changeProductController } = containerSet.designAndProductDriver

  if (changeProductController.confirmationModalType !== "mode") {
    return null
  }

  const onClose = () => {
    changeProductController.closeConfirmationModal()
  }

  const onConfirm = () => {
    changeProductController.confirmChange()
  }

  return (
    <Modal
      title={t(i18n.title)}
      iconUrl={changeIconUrl}
      onClose={onClose}
      link={{ label: t(i18n.confirm), onClick: onConfirm }}
      center
    >
      {t(i18n.message)}
    </Modal>
  )
})
