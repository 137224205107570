import { Size } from "../types"

export function getSvgSize(svg: any): Size {
  const attributes = svg["attributes"] || {}

  const width = attributes.width?.value
  const height = attributes.height?.value

  if (width && height) {
    return { width, height }
  }

  const viewBox = attributes.viewBox?.value

  if (!viewBox) {
    throw new Error("Virtual Dieline SVG should have viewBox attribute")
  }

  const viewBoxValues = viewBox.split(" ")

  return {
    width: viewBoxValues[2],
    height: viewBoxValues[3],
  }
}
