export enum Fonts {
  Cardo = "Cardo",
  TimesNewRoman = "TimesNewRoman",
  Cairo = "Cairo",
  IBMPlexSerif = "IBMPlexSerif",
  IndieFlower = "IndieFlower",
  Lato = "Lato",
  Montserrat = "Montserrat",
  Muli = "Muli",
  Roboto = "Roboto",
  Varela = "Varela",
  Alegreya = "Alegreya",
  Archivo = "Archivo",
  Oswald = "Oswald",
  Poppins = "Poppins",
  Vollkorn = "Vollkorn",
  Lora = "Lora",
  CrimsonText = "CrimsonText",
  Pacifico = "Pacifico",
  JustAnotherHand = "JustAnotherHand",
  PlayfairDisplay = "PlayfairDisplay",
  BioRhyme = "BioRhyme",
  AbrilFatface = "AbrilFatface",
  Aleo = "Aleo",
  AmaticSC = "AmaticSC",
  ArchivoBlack = "ArchivoBlack",
  Bahiana = "Bahiana",
  BerkshireSwash = "BerkshireSwash",
  BigShouldersDisplay = "BigShouldersDisplay",
  Bitter = "Bitter",
  CedarvilleCursive = "CedarvilleCursive",
  ChakraPetch = "ChakraPetch",
  DMSerifDisplay = "DMSerifDisplay",
  Itim = "Itim",
  JetBrainsMono = "JetBrainsMono",
  KronaOne = "KronaOne",
  KumarOneOutline = "KumarOneOutline",
  MajorMonoDisplay = "MajorMonoDisplay",
  Mali = "Mali",
  Manrope = "Manrope",
  PetitFormalScript = "PetitFormalScript",
  Plaster = "Plaster",
  PoiretOne = "PoiretOne",
  Prompt = "Prompt",
  Quicksand = "Quicksand",
  ReenieBeanie = "ReenieBeanie",
  RobotoMono = "RobotoMono",
  RobotoSlab = "RobotoSlab",
  Rubik = "Rubik",
  RubikMonoOne = "RubikMonoOne",
  TenorSans = "TenorSans",
  Yellowtail = "Yellowtail",
  YesevaOne = "YesevaOne",
  BebasNeue = "BebasNeue",
  Chivo = "Chivo",
  CormorantGaramond = "CormorantGaramond",
  Domine = "Domine",
  EBGaramond = "EBGaramond",
  GrandHotel = "GrandHotel",
  GreatVibes = "GreatVibes",
  Judson = "Judson",
  Meddon = "Meddon",
  OpenSans = "OpenSans",
  Parisienne = "Parisienne",
  PatrickHandSC = "PatrickHandSC",
  Raleway = "Raleway",
  Sacramento = "Sacramento",
  Sen = "Sen",
  WorkSans = "WorkSans",
  BirthstoneBounce = "BirthstoneBounce",
  Bungee = "Bungee",
  Comforter = "Comforter",
  OoohBaby = "OoohBaby",
  Ultra = "Ultra",
  Barriecito = "Barriecito",
  Ambidexter = "Ambidexter",
  CutItOut = "CutItOut",
  GranaPadano = "GranaPadano",
  DayCare = "DayCare",
  Kawoszeh = "Kawoszeh",
  Lombard = "Lombard",
  LemonTuesday = "LemonTuesday",
  RibesBlack = "RibesBlack",
  SpaceGrotesk = "SpaceGrotesk",
}

export type FontDef = {
  normal: boolean
  bold: boolean
  italic: boolean
  boldItalic: boolean
  name: string
}

export type FontSet = { [key in Fonts]?: FontDef }

const LOADABLE_FONTS_CONFIGS: FontSet = {
  [Fonts.Cardo]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: false,
    name: "Cardo",
  },
  [Fonts.TimesNewRoman]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Times New Roman",
  },
  [Fonts.Cairo]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Cairo",
  },
  [Fonts.IBMPlexSerif]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "IBM Plex Serif",
  },
  [Fonts.IndieFlower]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Indie Flower",
  },
  [Fonts.Lato]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Lato",
  },
  [Fonts.Montserrat]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Montserrat",
  },
  [Fonts.Muli]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Muli",
  },
  [Fonts.Roboto]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Roboto",
  },
  [Fonts.Varela]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Varela",
  },
  [Fonts.Alegreya]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Alegreya",
  },
  [Fonts.Archivo]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Archivo",
  },
  [Fonts.Oswald]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Oswald",
  },
  [Fonts.Poppins]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Poppins",
  },
  [Fonts.Vollkorn]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Vollkorn",
  },
  [Fonts.Lora]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Lora",
  },
  [Fonts.CrimsonText]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Crimson Text",
  },
  [Fonts.Pacifico]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Pacifico",
  },
  [Fonts.JustAnotherHand]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Just Another Hand",
  },
  [Fonts.PlayfairDisplay]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Playfair Display",
  },
  [Fonts.BioRhyme]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "BioRhyme",
  },
  [Fonts.AbrilFatface]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Abril Fatface",
  },
  [Fonts.Aleo]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Aleo",
  },
  [Fonts.AmaticSC]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Amatic SC",
  },
  [Fonts.ArchivoBlack]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Archivo Black",
  },
  [Fonts.Bahiana]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Bahiana",
  },
  [Fonts.BerkshireSwash]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Berkshire Swash",
  },
  [Fonts.BigShouldersDisplay]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Big Shoulders",
  },
  [Fonts.Bitter]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Bitter",
  },
  [Fonts.CedarvilleCursive]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Cedarville Cursive",
  },
  [Fonts.ChakraPetch]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Chakra Petch",
  },
  [Fonts.DMSerifDisplay]: {
    normal: true,
    bold: false,
    italic: true,
    boldItalic: false,
    name: "DM Serif Display",
  },
  [Fonts.Itim]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Itim",
  },
  [Fonts.JetBrainsMono]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "JetBrains Mono",
  },
  [Fonts.KronaOne]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Krona One",
  },
  [Fonts.KumarOneOutline]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Kumar One Outline",
  },
  [Fonts.MajorMonoDisplay]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Major Mono Display",
  },
  [Fonts.Mali]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Mali",
  },
  [Fonts.Manrope]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Manrope",
  },
  [Fonts.PetitFormalScript]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Petit Formal Script",
  },
  [Fonts.Plaster]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Plaster",
  },
  [Fonts.PoiretOne]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Poiret One",
  },
  [Fonts.Prompt]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Prompt",
  },
  [Fonts.Quicksand]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Quicksand",
  },
  [Fonts.ReenieBeanie]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Reenie Beanie",
  },
  [Fonts.RobotoMono]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Roboto Mono",
  },
  [Fonts.RobotoSlab]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Roboto Slab",
  },
  [Fonts.Rubik]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Rubik",
  },
  [Fonts.RubikMonoOne]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Rubik Mono One",
  },
  [Fonts.TenorSans]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Tenor Sans",
  },
  [Fonts.Yellowtail]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Yellowtail",
  },
  [Fonts.YesevaOne]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Yeseva One",
  },

  [Fonts.BebasNeue]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Bebas Neue",
  },
  [Fonts.Chivo]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Chivo",
  },
  [Fonts.CormorantGaramond]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Cormorant Garamond",
  },
  [Fonts.Domine]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Domine",
  },
  [Fonts.EBGaramond]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "EB Garamond",
  },
  [Fonts.GrandHotel]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Grand Hotel",
  },
  [Fonts.GreatVibes]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Great Vibes",
  },
  [Fonts.Judson]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: false,
    name: "Judson",
  },
  [Fonts.Meddon]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Meddon",
  },
  [Fonts.OpenSans]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Open Sans",
  },
  [Fonts.Parisienne]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Parisienne",
  },
  [Fonts.PatrickHandSC]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Patrick Hand SC",
  },
  [Fonts.Raleway]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Raleway",
  },
  [Fonts.Sacramento]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Sacramento",
  },
  [Fonts.Sen]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Sen",
  },
  [Fonts.WorkSans]: {
    normal: true,
    bold: true,
    italic: true,
    boldItalic: true,
    name: "Work Sans",
  },
  [Fonts.BirthstoneBounce]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Birthstone Bounce",
  },
  [Fonts.Bungee]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Bungee",
  },
  [Fonts.Comforter]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Comforter",
  },
  [Fonts.OoohBaby]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Oooh Baby",
  },
  [Fonts.Ultra]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Ultra",
  },
  [Fonts.Barriecito]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Barriecito",
  },
  [Fonts.Ambidexter]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Ambidexter",
  },
  [Fonts.CutItOut]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Cut It Out",
  },
  [Fonts.GranaPadano]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Grana Padano",
  },
  [Fonts.DayCare]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Daycare",
  },
  [Fonts.Kawoszeh]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Kawoszeh",
  },
  [Fonts.Lombard]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Lombard",
  },
  [Fonts.LemonTuesday]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Lemon Tuesday",
  },
  [Fonts.RibesBlack]: {
    normal: true,
    bold: false,
    italic: false,
    boldItalic: false,
    name: "Ribes Black",
  },
  [Fonts.SpaceGrotesk]: {
    normal: true,
    bold: true,
    italic: false,
    boldItalic: false,
    name: "Space Grotesk",
  },
}

export { LOADABLE_FONTS_CONFIGS }
