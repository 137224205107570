import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import { AppPreload } from "./AppPreload"
import * as serviceWorker from "./serviceWorker"
import "shared-libs/src/js/libs/overrides/mobx-isolate-global-state"

// window.localStorage.debug = "ph:*"

ReactDOM.render(<AppPreload />, document.getElementById("root"))

// ReactDOM.render(<div>fast build test</div>, document.getElementById("root"))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
