import { observable, action, computed, makeObservable } from "mobx"
import { StrapiPredefinedTextAssetsProvider } from "../services/strapi/strapi-predefined-text-assets.provider"
import { PredefinedText } from "../models/predefined-text"
import { ColorScheme } from "../types/predefined-asset.types"
import { ProductDriver } from "../drivers/product.driver"
import { AvailableColourModes } from "../libs/products-render-config/types"
import _orderBy from "lodash/orderBy"
import { AppConfig } from "../app-config/app.config"

export class PredefinedTextsStore {
  private provider: StrapiPredefinedTextAssetsProvider

  @observable public predefinedTexts: PredefinedText[] = []
  @observable public isLoadingPredefinedTexts = false
  @observable public isLoadingSinglePredefinedText = false

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly appConfig: AppConfig
  ) {
    makeObservable(this)
    this.provider = new StrapiPredefinedTextAssetsProvider(appConfig.api.strapi)
  }

  @computed
  public get orderedPredefinedTexts(): PredefinedText[] {
    return _orderBy(this.predefinedTexts, ["order"], ["asc"])
  }

  public async loadPredefinedTexts(): Promise<void> {
    if (this.hasNoAssetsYet()) {
      this.setIsLoadingPredefinedTexts(true)

      try {
        const { productRenderPilot } = this.productDriver.state
        const product = productRenderPilot.getProduct()

        const predefinedTexts = await this.provider.getTextAssets(
          this.getCurrentColorScheme(),
          product
        )
        this.setPredefinedTexts(predefinedTexts)
      } catch (error) {
        console.error("Predefined texts couldn't be loaded!", error)
      } finally {
        this.setIsLoadingPredefinedTexts(false)
      }
    }
  }

  @action
  public setIsLoadingSinglePredefinedText(isLoading: boolean) {
    this.isLoadingSinglePredefinedText = isLoading
  }

  private hasNoAssetsYet(): boolean {
    return this.predefinedTexts.length === 0
  }

  private getCurrentColorScheme(): ColorScheme {
    const productRenderPilot = this.productDriver.state.productRenderPilot
    const colourPreset = productRenderPilot.getColoursPreset()

    if (colourPreset && colourPreset.mode === AvailableColourModes.MONOCHROME) {
      return ColorScheme.MONOCHROMATIC
    }

    return ColorScheme.COLOURFUL
  }

  @action
  private setPredefinedTexts(predefinedTexts: PredefinedText[]): void {
    this.predefinedTexts = predefinedTexts
  }

  @action
  private setIsLoadingPredefinedTexts(isLoading: boolean) {
    this.isLoadingPredefinedTexts = isLoading
  }
}
