import React from "react"
import { Colour } from "../../../../models/colour"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { ColorPicker } from "../ColorPicker/ColorPicker"
import { colorsListDemo } from "../../../../libs/colors/hex-colors.list"
import { AnalyticsSection } from "../../types"

const defaultColorsList = colorsListDemo.map((color) => {
  return {
    color: new Colour(color.color).getHex(),
    uid: color.uid,
    description: "muu",
    pantoneName: new Colour(color.color).getPantoneName(),
  }
})

export interface HexConfiguratorProps {
  selectedColor: Colour | undefined
  onColorSelect: (color: Colour) => void
  colorsList?: ColorListArray[]
  analyticsSection?: AnalyticsSection
}

export function HexConfigurator({
  selectedColor,
  onColorSelect,
  colorsList = defaultColorsList,
  analyticsSection,
}: HexConfiguratorProps) {
  return (
    <ColorPicker
      onColorSelect={onColorSelect}
      selectedColor={selectedColor ? selectedColor.getHex() : undefined}
      colorsList={colorsList}
      isMoreColorsAvailable={colorsList.length >= defaultColorsList.length}
      analyticsSection={analyticsSection}
    />
  )
}
