import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { EdgeType, LinePoints, ObjectEdge } from "../types"
import { isSafeOperatingZone } from "../../../../../../../types/asset.types"
import { GuidelinesController } from "./guidelines.controller"

export class SafeZoneGuidelinesController extends GuidelinesController {
  protected readonly edgeTypesToCompare: EdgeType[] = [
    "top",
    "bottom",
    "left",
    "right",
  ]
  protected readonly showAllGuidelinesOnMatch = true

  protected getObjectsToCompare(
    currentObject: PackhelpObject
  ): PackhelpObject[] {
    return this.canvas.getObjects().filter((object) => {
      const isProperType = isSafeOperatingZone(object)
      const isProperSpace =
        object.originSpaceArea === currentObject.originSpaceArea

      return isProperType && isProperSpace
    })
  }

  protected calculateLinePoints(
    _currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): LinePoints {
    return nextEdge.points
  }
}
