var BoxMaterial;
(function (BoxMaterial) {
    BoxMaterial["PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD"] = "whiteOneSideCardboard";
    BoxMaterial["PRINT_MATERIAL_WHITE_TWO_SIDES_CARDBOARD"] = "whiteTwoSidesCardboard";
    BoxMaterial["PRINT_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD"] = "whiteOneSideNaturalCardboard";
    BoxMaterial["PRINT_MATERIAL_WHITE_CARDBOARD"] = "whiteCardboard";
    BoxMaterial["PRINT_MATERIAL_COATED_CARDBOARD"] = "coatedCardboard";
    BoxMaterial["PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE"] = "coatedCardboardWhiteInside";
    BoxMaterial["PRINT_MATERIAL_NATURAL_CARDBOARD_WHITE_INSIDE"] = "cardboardNaturalWhiteInside";
    BoxMaterial["PRINT_MATERIAL_NATURAL_CARDBOARD"] = "naturalCardboard";
    BoxMaterial["PRINT_MATERIAL_KRAFT"] = "kraft";
    BoxMaterial["PRINT_MATERIAL_CARDSTOCK"] = "cardstockCardboard";
    BoxMaterial["PRINT_MATERIAL_WHITE"] = "cardboardNaturalWhite";
})(BoxMaterial || (BoxMaterial = {}));
export default BoxMaterial;
