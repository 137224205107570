import React from "react"
import { Logo } from "../../atoms/Logo/Logo"
import styles from "./Header.module.scss"
import { Skeleton } from "../../atoms/Skeleton/Skeleton"

export function HeaderLoader() {
  return (
    <header className={styles.header}>
      <Logo />

      <Skeleton width={252} height={48} borderRadius={666} />

      <div className={styles["right-side"]}>
        <Skeleton width={252} height={48} />
        <Skeleton width={120} height={48} />
      </div>
    </header>
  )
}
