import React from "react"
import { useTranslate } from "../../../../hooks/useTranslate"
import { Modal } from "../Modal/Modal"
import successIconUrl from "../../../../assets/_shape-icons/success.svg"
import { I18N } from "../../../../i18n"

const i18n = I18N.modals.registration

export interface ModalProps {
  onClose: () => void
  email?: string
}

export function ModalRegistrationSuccessful({ onClose, email }: ModalProps) {
  const t = useTranslate()

  return (
    <Modal
      title={t(i18n.title)}
      iconUrl={successIconUrl}
      onClose={onClose}
      withCancel={false}
      link={{ label: t(i18n.confirm), onClick: onClose }}
      center
    >
      <p>{t(i18n.emailSent)}:</p>
      <p>
        <b>{email}</b>
      </p>
    </Modal>
  )
}
