export function addThresholdFilter(fabric) {
  fabric.Image.filters.Threshold = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    /** @lends fabric.Image.filters.Contrast.prototype */
    {
      type: "Threshold",
      /**
       * Constructor
       * @memberOf fabric.Image.filters.Contrast.prototype
       * @param {Object} [options] Options object
       * @param {Number} [options.contrast=0] Value to contrast the image up (-255...255)
       */
      initialize: function (options) {
        this.threshold = options.threshold
      },

      applyTo: function (canvasEl) {
        const context = canvasEl.getContext("2d")
        const imageData = context.getImageData(
          0,
          0,
          canvasEl.width,
          canvasEl.height
        )
        const d = imageData.data
        const newColor = {
          r: 0,
          g: 0,
          b: 0,
          a: 0,
        }
        const lenght = d.length
        for (var i = 0; i < lenght; i += 4) {
          let r = d[i]
          let g = d[i + 1]
          let b = d[i + 2]
          let v =
            0.2126 * r + 0.7152 * g + 0.0722 * b >= this.threshold ? 255 : 0
          d[i] = d[i + 1] = d[i + 2] = v

          if (d[i] === 255 && d[i + 1] === 255 && d[i + 2] === 255) {
            d[i] = newColor.r
            d[i + 1] = newColor.g
            d[i + 2] = newColor.b
            d[i + 3] = newColor.a
          }
        }
        context.putImageData(imageData, 0, 0)
      },
      /**
       * Returns object representation of an instance
       * @return {Object} Object representation of an instance
       */
      toObject: function () {
        return fabric.util.object.extend(this.callSuper("toObject"), {
          contrast: this.contrast,
        })
      },
    }
  )

  fabric.Image.filters.Threshold.fromObject = function (object) {
    return new fabric.Image.filters.Threshold(object)
  }

  return fabric
}
