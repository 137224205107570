/**
 * This file should store all window.sttuff that editor exposes
 */

declare global {
  interface Window {
    rootStore: any
    renderEngine: any
  }
}

export function exposeApis() {
  /**
   * expose render engine for DTP.
   *
   * Previously DTP used:
   * `renderEngine.generateDielinePreview('outside', 'svg')`
   *
   * We removed it.
   * But we can still make it avaliable via:
   * rootStore.productDriver.generateDielinePreview("outside", "svb")
   *
   * And We keep on old API.
   * Because bro's don't break other bro's source codes 🤜 🤛
   */
  window.renderEngine = {
    generateDielinePreview:
      window.rootStore.productDriver.generateDielinePreview.bind(
        window.rootStore.productDriver
      ),
  }
}
