import { ImageUploaderConfig } from "./asset-service/uploader"

globalThis.__IS_SSR__ = false

// FilePicker Config Temp location   ------------

// Filestack Api Key
const apikey = "Abr9vScyUSdWkpJtvCJvwz" // PROD
// const apikey = "AvtrNPNMNQOew6lMeFUvNz" // DEV

let S3_FILEPICKER_BUCKET_NAME = "packhelp-files-dev"
const S3_FILEPICKER_BUCKET_REGION = "eu-west-1"

//TODO: check it
let envNameForS3 = `local-dev`
if (
  globalThis.zpkj &&
  globalThis.zpkj.env &&
  typeof globalThis.zpkj.env.Z_INSTANCE_NAME === "string"
) {
  if (globalThis.zpkj.env.Z_INSTANCE_NAME !== "") {
    envNameForS3 = globalThis.zpkj.env.Z_INSTANCE_NAME
  }

  if (globalThis.zpkj.env.Z_INSTANCE_ENV === "staging") {
    envNameForS3 = "staging"
  }

  if (globalThis.zpkj.env.Z_INSTANCE_ENV === "production") {
    envNameForS3 = "production"
    S3_FILEPICKER_BUCKET_NAME = "packhelp-files"
  }
}

const S3_FILEPICKER_PREFIX = `design-assets/${envNameForS3}`

export const imageUploadConf: ImageUploaderConfig = {
  apiKey: apikey,
  bucket: {
    name: S3_FILEPICKER_BUCKET_NAME,
    region: S3_FILEPICKER_BUCKET_REGION,
    prefix: S3_FILEPICKER_PREFIX,
    regionUri: "https://s3-eu-west-1.amazonaws.com",
    domain: `https://${S3_FILEPICKER_BUCKET_NAME}.s3-eu-west-1.amazonaws.com`,
  },
}
Object.freeze(imageUploadConf)
// FilePicker Config Temp location   ------------
