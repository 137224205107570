import { computed, action, observable, makeObservable } from "mobx"
import { CanvasPopoverPosition } from "../types/ui.types"
import ProductDriver from "../drivers/product.driver"
import { FormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { I18N } from "../ui/i18n"

export class ModelPopoverUiController {
  @observable public popoverPosition?: CanvasPopoverPosition

  private readonly productDriver: ProductDriver
  private readonly formatMessage: FormatMessage

  constructor(services: {
    productDriver: ProductDriver
    formatMessage: FormatMessage
  }) {
    this.productDriver = services.productDriver
    this.formatMessage = services.formatMessage

    document.addEventListener("mousemove", (e: MouseEvent | Touch) => {
      if (this.productDriver.state.activeViewType !== "model") {
        return
      }

      this.setPopoverPosition({ left: e.clientX + 15, top: e.clientY + 15 })
    })

    makeObservable(this)
  }

  @computed
  public get message(): string | undefined {
    const { isSelectingAssetPlacement, highlightedSpaceId } =
      this.productDriver.state

    if (!highlightedSpaceId) {
      return
    }

    const genericIntl = isSelectingAssetPlacement
      ? I18N.generic.placeElementOnTheWall
      : I18N.generic.enterTheWallView
    const spaceIntl =
      I18N.component.cameraControlsToolbar.space[highlightedSpaceId]

    const genericMessage = this.formatMessage({
      id: genericIntl,
    })

    if (!spaceIntl) {
      return genericMessage
    }

    return `${genericMessage}: ${this.formatMessage({ id: spaceIntl })}`
  }

  @action
  private setPopoverPosition(position: CanvasPopoverPosition): void {
    this.popoverPosition = position
  }
}
