import { SvgElement } from "./svg-element"
import { SvgLine } from "./svg-line"
import { SvgPath } from "./svg-path"

export function isSvgLine(element: SvgElement): element is SvgLine {
  return element instanceof SvgLine
}

export function isSvgPath(element: SvgElement): element is SvgPath {
  return element instanceof SvgPath
}
