import { useContext } from "react"
import { IntlContext } from "shared-libs/src/js/libs/others/i18n"

// Please also check before editing
// shared-libs/src/js/libs/others/i18n.tsx
// shared-libs/src/js/libs/AsyncI18nLocales.tsx

export const useFormatMessage = () => {
  const intl = useContext(IntlContext)
  return intl.formatMessage
}

export const useTranslate = () => {
  const intl = useContext(IntlContext)

  return function (translationId: string, values?: any) {
    return intl.formatMessage({ id: translationId }, values)
  }
}
