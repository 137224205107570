import { ReactNode } from "react"

export type Direction = "up" | "down"

export interface TranslatedKeys {
  calculateLabel?: string
  confirmLabel?: string
  customValueLabel?: string
  headerLabel?: string
  outOfStockLabel?: string
  placeholder?: string
  searchPlaceholder?: string
}

export interface PriceBreak {
  quantity: number
  pricePerPiece?: number
  priceTotal?: number
  deal?: number
  isCustomValue?: boolean
  isDynamicPricingAvailable?: boolean
  isLoadingDynamicPricing?: boolean
  currency?: string
  outOfStock?: boolean
}

export interface PriceDisplayOptions {
  withDeal?: boolean
  withPricePerPiece?: boolean
  withPrice?: boolean
  withTax?: boolean
  fixedPromo?: number
}

export type PriceFormatter = (
  price: number,
  currency: string,
  withTax?: boolean
) => JSX.Element

export enum QuantityItemType {
  Basic = "basic",
  Calculate = "calculate"
}

export interface CoreQuantityItem {
  quantity: string
  type?: QuantityItemType
  outOfStock?: boolean
}

export interface BasicQuantityItem extends CoreQuantityItem {
  price?: ReactNode
  promoPrice?: ReactNode
  piecePrice?: ReactNode
  discount?: string
  badge?: string
  badgeColor?: string
  type: typeof QuantityItemType.Basic
}

export interface CalculateQuantityItem extends CoreQuantityItem {
  isLoading?: boolean
  label: string
  loadingLabel?: string
  type: typeof QuantityItemType.Calculate
}

export type QuantityItem =
  | BasicQuantityItem
  | CalculateQuantityItem
  | CoreQuantityItem

export interface LabelProps {
  item: QuantityItem
  onItemAction: (item: QuantityItem) => void
  fixedPromo?: number
}
