export const config = {
  thickness: 1,
  rotation: {
    initial: -90,
  },
  folding: {
    target: {
      closed: 100,
      opened: 72,
    },
  },
  spaces: {
    back: {
      parent: "bottom_back_flap",
      folding: {
        axis: "x",
        angle: -90,
        step: 1,
      },
    },
    bottom_back_glue_flap: {
      parent: "bottom_back_flap",
      folding: {
        axis: "x",
        angle: 0,
        step: 1,
      },
    },
    top: {
      parent: "back",
      folding: {
        axis: "x",
        angle: -90,
        step: 10,
      },
    },
    front_inside: {
      parent: "top",
      folding: {
        axis: "x",
        angle: -90,
        step: 9,
      },
    },
    glue_flap: {
      parent: "back",
      folding: {
        axis: "y",
        angle: 90,
        step: 2,
      },
    },
    left: {
      parent: "back",
      folding: {
        axis: "y",
        angle: -90,
        step: 3,
      },
    },
    bottom_left_flap: {
      parent: "left",
      folding: {
        axis: "x",
        angle: 89,
        step: 6,
      },
    },
    left_inside: {
      parent: "left",
      folding: {
        axis: "x",
        angle: -90,
        step: 8,
      },
    },
    front: {
      parent: "left",
      folding: {
        axis: "y",
        angle: -90,
        step: 4,
      },
    },
    bottom_front_flap: {
      parent: "front",
      folding: {
        axis: "x",
        angle: 90,
        step: 7,
      },
    },
    bottom_front_glue_flap: {
      parent: "bottom_front_flap",
      folding: {
        axis: "x",
        angle: 0,
        step: 1,
      },
    },
    right: {
      parent: "front",
      folding: {
        axis: "y",
        angle: -90,
        step: 5,
      },
    },
    bottom_right_flap: {
      parent: "right",
      folding: {
        axis: "x",
        angle: 89,
        step: 6,
      },
    },
    right_inside: {
      parent: "right",
      folding: {
        axis: "x",
        angle: -90,
        step: 8,
      },
    },
  },
} as const
