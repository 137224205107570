import React, { ReactNode } from "react"
import Typography, { TType } from "../../../../atoms/Typography/Typography"

interface PickerValuePlaceholderProps {
  children: ReactNode
}
export const PickerValuePlaceholder = (props: PickerValuePlaceholderProps) => {
  const { children } = props

  return <Typography type={TType.Body15_350}>{children}</Typography>
}
