import { computed } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { ActiveObject } from "../../drivers/active-object.driver"
import { EditableObjectTypes } from "../../types/asset.types"

type EditorToolbar = "space" | "asset" | null

export class EditorToolbarController {
  constructor(private readonly productDriver: ProductDriver) {}

  @computed
  public get activeToolbar(): EditorToolbar {
    const {
      activeViewType,
      productRenderPilot,
      activeContext,
      isDesignPreviewMode,
    } = this.productDriver.state

    if (
      isDesignPreviewMode ||
      !productRenderPilot.isPrintActiveFor(activeContext)
    ) {
      return null
    }

    if (this.activeObject) {
      return "asset"
    }

    if (activeViewType === "space") {
      return "space"
    }

    return null
  }

  @computed
  public get activeObjectType(): EditableObjectTypes | undefined {
    return this.activeObject?.assetType
  }

  @computed
  public get activeObject(): ActiveObject | null {
    return this.productDriver.activeObjectDriver.activeObject
  }
}
