import { urls } from "../api-endpoint-urls"
import { SegmentTrackingParams } from "../../../analytics/segment/segment-analytics.service"
import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"

export const postUserFirstTouch = (payload) => ({
  method: "post",
  url: `${urls.API_USERS}/${urls.API_USERS_FIRST_TOUCH}`,
  data: payload,
})

export const addSegmentationData = (segmentationData) => ({
  method: "post",
  url: `${RegionConfig.getSegmentationEndpointForCurrentRegion()}/add_segment_data_v2`,
  data: segmentationData,
})

export const postTrackingData = <
  T extends { [key in SegmentTrackingParams]?: string | number }
>(
  payload: T
) => ({
  method: "post",
  url: `${urls.API_SEGMENT_PROXIES}/track`,
  data: payload,
})
