import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { Axis, positionAttributeMap } from "../types"

export function setPositionOnAxis(
  object: PackhelpObject,
  axis: Axis,
  position: number
): void {
  const attr = positionAttributeMap[axis]

  object.set({ [attr]: position })
}
