import { useContext } from "react"
import { IntlContext } from "../../libs/others/i18n"

export const useFormatMessage = () => {
  const intl = useContext(IntlContext)
  // @ts-ignore
  return intl.formatMessage
}

type TranslationValue = string | number // add more if necessary
export type TranslationValues = { [key: string]: TranslationValue }
export type TranslateFunction = (
  translationId: string,
  values?: TranslationValues | undefined,
  pluralNumber?: number | undefined
) => string

export const useTranslate = (): TranslateFunction => {
  const intl = useContext(IntlContext)
  return function (
    translationId: string,
    values?: TranslationValues,
    pluralNumber?: number
  ) {
    if (!translationId) {
      return ""
    }

    if (pluralNumber) {
      const pluralType = intl.formatPlural(pluralNumber, { style: "ordinal" })
      return intl.formatMessage(
        { id: `${translationId}.${pluralType}` },
        values
      )
    }
    return intl.formatMessage({ id: translationId }, values)
  }
}
