import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { LinePoints, ObjectEdge } from "../types"
import { isSafeZone } from "../../../../../../../types/asset.types"
import { GuidelinesController } from "./guidelines.controller"

export class SpaceGuidelinesController extends GuidelinesController {
  protected getObjectsToCompare(
    currentObject: PackhelpObject
  ): PackhelpObject[] {
    return this.canvas.getObjects().filter((object) => {
      const isProperType = isSafeZone(object)
      const isProperSpace =
        object.originSpaceArea === currentObject.originSpaceArea

      return isProperType && isProperSpace
    })
  }

  protected calculateLinePoints(
    _currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): LinePoints {
    if (nextEdge.axis === "x") {
      return {
        start: { x: 0, y: this.canvas.height! },
        end: { x: 0, y: 0 },
      }
    }

    return {
      start: { x: 0, y: 0 },
      end: { x: this.canvas.width!, y: 0 },
    }
  }
}
