import { INode } from "svgson"

export interface Config {
  width: number
  height: number
  flipHorizontal: boolean
  flipVertical: boolean
}

export interface Segment {
  start: Point
  end: Point
}

export enum ElementType {
  line = "line",
  path = "path",
}

export enum PathCommand {
  quadraticBezierCurveTo = "Q",
  lineTo = "L",
  moveTo = "M",
  closePath = "Z",
}

export interface Point {
  x: number
  y: number
}

export interface BoundingBox {
  start: Point
  end: Point
  center: Point
  width: number
  height: number
}

export interface PathSegment {
  command: string
  values: number[]
}

export interface Size {
  width: number
  height: number
}

export interface Element {
  toNode(): INode
}
