import React, { useState } from "react"
import { PickerColorPalette } from "dsl/src/organisms/PickerColorPalette/PickerColorPalette"
import PickerColor from "dsl/src/organisms/PickerColor/PickerColor"
import {
  ColorListArray,
  SideEditorColorObject,
} from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Picker, PickerProps } from "../Picker/Picker"
import { useTranslate } from "../../../hooks/useTranslate"
import { Colour } from "../../../../models/colour"
import { I18N } from "../../../i18n"
import { AnalyticsSection } from "../../types"

const i18n = I18N.component.colorPicker

export interface ColorPickerProps {
  colorsList: ColorListArray[]
  onColorSelect: (color: Colour) => void
  selectedColor?: string
  singleContent?: boolean
  isMoreColorsAvailable?: boolean
  analyticsSection?: AnalyticsSection
}

export function ColorPicker({
  colorsList,
  onColorSelect,
  selectedColor,
  isMoreColorsAvailable = true,
  analyticsSection,
}: ColorPickerProps) {
  const t = useTranslate()

  const [isMoreColorsVisible, setIsMoreColorsVisible] = useState<boolean>(false)

  const onLinkClick = () => {
    setIsMoreColorsVisible(!isMoreColorsVisible)
  }

  const onColorChange = (color: SideEditorColorObject | string) => {
    if (typeof color === "string") {
      onColorSelect(new Colour(color))
    } else {
      onColorSelect(new Colour(color.color))
    }
  }

  const link: PickerProps["link"] = isMoreColorsAvailable
    ? {
        label: isMoreColorsVisible ? t(i18n.lessColors) : t(i18n.moreColors),
        onClick: onLinkClick,
        analyticsSection: analyticsSection,
        analyticsType: "button",
        analyticsName: "more-colors-toggle",
        e2eTarget: "button",
        e2eTargetName: isMoreColorsVisible ? "less-colors" : "more-colors",
      }
    : undefined

  return (
    <Picker link={link}>
      {isMoreColorsVisible ? (
        <PickerColor color={selectedColor} onChange={onColorChange} />
      ) : (
        <PickerColorPalette
          selectedColor={selectedColor}
          onColorSelect={onColorChange}
          colorsList={colorsList}
          analyticsSection={analyticsSection}
        />
      )}
    </Picker>
  )
}
