import { observable, action, makeObservable } from "mobx"
import { ProductDesignStore } from "../../stores/product-design.store"
import { SaveProductDesignController } from "./save-product-design.controller"
import { UrlManipulatorProvider } from "../../services/manipulators/url.manipulator"
import { DesignApi } from "@ph/design-api"
import type { EcommerceApi } from "../../libs/api/ecommerce-api/types"
import { ProductStore } from "../../stores/product.store"
import { AuthController } from "../auth.controller"
import { SessionStore } from "../../stores/session.store"

export class ProductDesignUiController {
  @observable public isFinishConfirmationModalOpen = false
  @observable public isRestartConfirmationModalOpen = false
  @observable public isShareModalOpen = false

  constructor(
    private services: {
      saveProductDesignController: SaveProductDesignController
      designApi: DesignApi
      ecommerceApi: EcommerceApi
      uri: UrlManipulatorProvider
      authController?: AuthController
    },
    private stores: {
      productDesignStore: ProductDesignStore
      productStore: ProductStore
      sessionStore: SessionStore
    }
  ) {
    makeObservable(this)
  }

  @action
  public setIsFinishConfirmationModalOpen(isOpen: boolean): void {
    this.isFinishConfirmationModalOpen = isOpen
  }

  @action
  public setIsRestartConfirmationModalOpen(isOpen: boolean): void {
    this.isRestartConfirmationModalOpen = isOpen
  }

  @action
  public setIsShareModalOpen(isOpen: boolean): void {
    this.isShareModalOpen = isOpen
  }

  public async share() {
    const { productDesignStore } = this.stores
    const { saveProductDesignController } = this.services

    productDesignStore.setIsProductDesignSharing(true)
    productDesignStore.setIsProductDesignPublic(true)

    await saveProductDesignController.save({ force: true })

    productDesignStore.setIsProductDesignSharing(false)
  }

  public async lock() {
    const { productDesignStore } = this.stores
    const { designApi } = this.services

    try {
      await designApi.lock(this.designId)
    } catch (e: any) {
      productDesignStore.setLockDesignError(e)
    }
  }

  public async shareWithEmail(email: string) {
    const { productDesignStore } = this.stores
    const { designApi } = this.services

    try {
      productDesignStore.setIsSharedWithEmail(false)
      productDesignStore.setIsSharingWithEmail(true)
      await designApi.share(this.designId, email)
      productDesignStore.setIsSharingWithEmail(false)
      productDesignStore.setIsSharedWithEmail(true)
    } catch (e: any) {
      productDesignStore.setIsSharingWithEmail(false)
      productDesignStore.setIsSharedWithEmail(false)
      productDesignStore.setShareWithEmailError(e)
    }
  }

  public async duplicate() {
    const { productDesignStore } = this.stores
    const { designApi, ecommerceApi, uri } = this.services

    productDesignStore.setIsProductDesignDuplicating(true)

    try {
      const response = await designApi.duplicate(this.designId)
      await ecommerceApi.user.duplicateDesign({
        id: response.id,
        read_token: response.read_token,
        write_token: response.write_token,
        thumbnail: response.thumbnail,
        name: response.name,
        external_pim_sku: response.external_pim_sku,
        design_status: response.design_status,
      })
      uri.redirectToDesignDuplicate(response.id)
    } catch (e: any) {
      productDesignStore.setDuplicateDesignError(e)
    }
  }

  public async save(options?: {
    name?: string
    notifyOnSuccess?: boolean
    withPreview?: boolean
    forceLogin?: boolean
  }): Promise<void> {
    const { productDesignStore, sessionStore } = this.stores
    const { saveProductDesignController, authController } = this.services

    if (options?.forceLogin && !sessionStore.isLoggedIn && authController) {
      return authController.openLoginModal()
    }

    if (options?.name) {
      productDesignStore.setDesignName(options.name)
    }

    await saveProductDesignController.save({
      force: true,
      notifyOnSuccess: !!options?.notifyOnSuccess,
      withPreview: !!options?.withPreview,
    })
  }

  public async restart() {
    const { productDesignStore, productStore } = this.stores
    const { uri } = this.services

    productDesignStore.setDesignTouched(false)

    const quantity = productDesignStore.state.meta.quantity
    const sku = productStore.productSku

    uri.resetPathToBase(sku, quantity)
  }

  public async finish(): Promise<void> {
    const { productDesignStore } = this.stores
    const { uri } = this.services

    productDesignStore.setIsProductDesignLocking(true)

    await this.save({ withPreview: true })
    await this.lock()

    if (productDesignStore.state.lockDesignError) {
      return this.setIsFinishConfirmationModalOpen(true)
    }

    productDesignStore.setProductDesignStatus("locked")

    uri.redirectToOrders()
  }

  private get designId(): string | number {
    const { productDesignStore } = this.stores

    const { id } = productDesignStore.state.meta

    if (!id) {
      throw new Error("Design ID is not defined.")
    }

    return id
  }
}
