import { Colour } from "./colour"

export const PlantingTreesBadgeType = "planting_trees_badges"
export const ecoTypesToExcludeOnList = [PlantingTreesBadgeType]

export const MaskShapeType = "mask"
export const typesToExcludeOnList = [MaskShapeType]

/**
 * Old one. Just for analytics purposes
 */
const OLD_PLANTING_TREES_BADGE: ShapeConfigParams = {
  title: "Badge",
  id: "eco-badge",
  src: "https://editor.s3.eu-central-1.amazonaws.com/eco-assets/badge.svg",
  colourConfig: {
    colourParam: "fill",
    colour: {
      hex: "#000",
    },
  },
}

export const DEFAULT_COLOR_CONFIG: ShapeColorConfigParams = {
  colourParam: "fill",
  colour: {
    hex: "#000",
  },
}

export const ecoQrShapeTitles = {
  QR_CODE_VERTICAL: "QR Code Vertical",
  QR_CODE_HORIZONTAL: "QR Code Horizontal",
} as const

export function isEcoQrCode(shape: Shape): boolean {
  return Object.values<string>(ecoQrShapeTitles).includes(shape.title)
}

export function isPlantingTreesBadge(shape: Shape): boolean {
  if (shape.type === PlantingTreesBadgeType) {
    return true
  }

  return (
    shape.id === OLD_PLANTING_TREES_BADGE.id &&
    shape.title === OLD_PLANTING_TREES_BADGE.title
  )
}

export type ShapeColorConfigParams = {
  colourParam: "fill" | "none"
  colour?: {
    hex: string
    pantoneName?: string
  }
}

export type ShapeConfigParams = {
  title: string
  id: string
  src: string
  type?: string
  keepRatio?: boolean
  minHeightMm?: number
  colourConfig: ShapeColorConfigParams
}

export type ShapeColourConfig = {
  colourParam: "fill" | "none"
  colour: Colour
}

export type GroupedShapes = Record<string, Shape[]>

export class Shape {
  public readonly title: string
  public readonly id: string
  public readonly src: string
  public readonly colourConfig: ShapeColourConfig
  public readonly type?: string
  public readonly keepRatio: boolean
  public readonly minHeightMm: number

  constructor({
    title,
    id,
    src,
    colourConfig,
    type,
    keepRatio,
    minHeightMm,
  }: ShapeConfigParams) {
    this.title = title
    this.id = id
    this.src = src
    this.type = type
    this.colourConfig = this.envokeColour(colourConfig)
    this.keepRatio = !!keepRatio
    this.minHeightMm = minHeightMm || 0
  }

  public toSource(): ShapeConfigParams {
    return {
      title: this.title,
      id: this.id,
      src: this.src,
      type: this.type,
      keepRatio: this.keepRatio,
      minHeightMm: this.minHeightMm,
      colourConfig: {
        colour: this.colourConfig.colour.toSource(),
        colourParam: this.colourConfig.colourParam,
      },
    }
  }

  private envokeColour(
    colourConfig: ShapeColorConfigParams
  ): ShapeColourConfig {
    return {
      colourParam: colourConfig.colourParam,
      colour: new Colour(
        colourConfig.colour?.hex,
        colourConfig.colour?.pantoneName
      ),
    }
  }
}
