import cxBinder from "classnames/bind"
import React from "react"
import Card, { CardType } from "../../Card/Card"
import Typography, { TType } from "../../Typography/Typography"
import styles from "../Select.module.scss"
import { SelectDirection, MenuProps } from "../types"
import Item from "./Item"

const cx = cxBinder.bind(styles)

const renderItems = (props: any) => {
  const {
    isOpen,
    items,
    renderItem,
    highlightedIndex,
    selectedItem,
    getItemProps,
    inputValueBasedItemIndex
  } = props

  return (
    isOpen &&
    items.map((item: any, index: any) => {
      const isSelected = selectedItem && selectedItem["id"] === item["id"]
      const renderedItem = renderItem(item, isSelected)

      if (!renderedItem) {
        return null
      }

      const isActive = [highlightedIndex, inputValueBasedItemIndex].includes(
        index
      )

      return (
        <Item
          className={cx("item", {
            "is-active": isActive,
            "is-selected": isSelected,
            "is-disabled": item.disabled
          })}
          key={item.id}
          e2e-target="list-item"
          e2e-target-name={item.name}
          {...getItemProps({ item, index, isSelected })}
        >
          {renderedItem}
        </Item>
      )
    })
  )
}
const BaseMenu = (props: MenuProps) => {
  const {
    innerRef,
    open,
    autocomplete,
    renderSearch,
    searchRef,
    disabled,
    getInputProps,
    isOpen,
    searchPlaceholder,
    items = [],
    renderItem,
    highlightedIndex,
    selectedItem,
    getItemProps,
    disableNoResultsIndicator,
    inputValue,
    renderMenuHeader,
    renderMenuFooter,
    direction,
    withMenuFluid,
    withMobileMenuFluid,
    autoWidth,
    menuRef,
    itemsListWrapperId,
    e2eTarget,
    inputValueBasedItemIndex,
    hideMenu,
    ...other
  } = props

  return (
    <div
      ref={innerRef}
      className={cx({
        "menu-outer-wrap": true,
        "menu-outer-wrap--auto-width": autoWidth,
        "menu-outer-wrap--fluid": withMenuFluid,
        "menu-outer-wrap--mobile-fluid": withMobileMenuFluid,
        "menu-hidden": hideMenu
      })}
      e2e-target={e2eTarget}
    >
      <div
        ref={menuRef}
        className={cx("menu-wrap", {
          "is-opened": open,
          "menu-wrap--up": direction == SelectDirection.up
        })}
      >
        <Card withShadow type={CardType.Round} fluid>
          <div id={itemsListWrapperId} className={styles.menu}>
            <ul {...other}>
              {renderMenuHeader && renderMenuHeader()}
              {renderItems(props)}
              {isOpen && items.length === 0 && !disableNoResultsIndicator && (
                <li className={cx(styles.item, styles["is-active"])}>
                  <Typography type={TType.Body15_350}>
                    No results matched "{inputValue}"
                  </Typography>
                </li>
              )}
              {renderMenuFooter && renderMenuFooter()}
            </ul>
          </div>
        </Card>
      </div>
    </div>
  )
}

const Menu = React.forwardRef((props: any, ref) => (
  <BaseMenu innerRef={ref} {...props} />
))

export { Menu, Menu as default }
