import React, { ReactNode, RefObject, useState } from "react"
import cxBinder from "classnames/bind"
import { Direction, QuantityItem } from "../../types"
import { PickerOptionsSearch } from "./PickerOptionsSearch"
import { PickerOptionsList } from "./PickerOptionsList"
import styles from "./PickerOptionsDropdown.module.scss"

const cx = cxBinder.bind(styles)

interface PickerOptionsDropdownProps {
  consise?: boolean
  direction?: Direction
  value: QuantityItem | undefined
  searchPlaceholder?: string
  options: QuantityItem[]
  match?: QuantityItem
  isOpen: boolean
  footer?: ReactNode
  searchValue?: string
  pickerRef?: RefObject<HTMLDivElement>
  listRef?: RefObject<HTMLUListElement>
  searchRef?: RefObject<HTMLInputElement>
  scrollRef?: RefObject<HTMLDivElement>
  onSelect: (item: QuantityItem) => void
  onItemAction?: (item: QuantityItem) => void
  onSearchChange?: (value?: number) => void
  onSearchConfirm?: () => void
}
export const PickerOptionsDropdown = (props: PickerOptionsDropdownProps) => {
  const {
    consise,
    direction = "down",
    value,
    options,
    isOpen,
    searchPlaceholder,
    footer,
    searchValue,
    match,
    listRef,
    pickerRef,
    searchRef,
    scrollRef,
    onSelect,
    onItemAction,
    onSearchChange,
    onSearchConfirm
  } = props

  const [isSearchFocused, setSearchFocused] = useState(false)
  const handleSearchFocus = () => setSearchFocused(true)
  const handleSearchBlur = () => setSearchFocused(false)

  const style =
    pickerRef && pickerRef.current
      ? { top: pickerRef.current.getBoundingClientRect().height }
      : undefined

  return (
    <div
      className={cx("dropdown", `dropdown--${direction}`, {
        "dropdown--hidden": !isOpen,
        "dropdown--consise": consise
      })}
      style={style}
    >
      {onSearchChange && (
        <PickerOptionsSearch
          ref={searchRef}
          value={searchValue}
          onChange={onSearchChange}
          onConfirm={onSearchConfirm}
          placeholder={searchPlaceholder}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
        />
      )}
      <PickerOptionsList
        consise={consise}
        ref={listRef}
        scrollRef={scrollRef}
        value={value}
        match={match}
        isMatchingEnabled={isSearchFocused}
        options={options}
        onSelect={onSelect}
        onItemAction={onItemAction}
      />
      {footer && <div className={styles["footer"]}>{footer}</div>}
    </div>
  )
}
