import { BaseController } from "./base.controller"
import { getGroup } from "../../editable-group-controller/helpers"

export class RemovalController extends BaseController {
  public remove() {
    const group = getGroup(this.canvasObject)

    if (group && group.groupController) {
      group.groupController.removeObject(this.canvasObject.id)
    }

    this.canvas.remove(this.canvasObject)
    this.canvasObject.maskController?.dispose()
  }
}
