type RoutingTaxState =
  | { isTaxDataProvided: true; taxCountryISO: string }
  | { isTaxDataProvided: false }

function getTaxDataFromUrl(): RoutingTaxState {
  const urlParams = new URLSearchParams(window.location.search)

  const taxCountryIso = urlParams.get("tci")

  if (typeof taxCountryIso === "string") {
    // DUCT TAPE for bugs that will happen in the future
    // for bad data
    // if(taxCountryIso === 'EU'){
    // throw or
    //   { isTaxDataProvided: true, taxCountryISO: RANDOM }
    // }

    return { isTaxDataProvided: true, taxCountryISO: taxCountryIso }
  }
  const taxRegion = urlParams.get("trn")

  if (typeof taxRegion === "string") {
    return { isTaxDataProvided: true, taxCountryISO: taxRegion }
  }

  return { isTaxDataProvided: false }
}

export function getRoutingConfiguration() {
  return {
    taxConfiguration: getTaxDataFromUrl(),
  }
}
