import React from "react"
import styles from "./NavItem.module.scss"
import { NavItem, NavItemProps } from "./NavItem"
import arrowIconUrl from "../../../assets/_icons/type-arrow-top-right.svg"
import { SvgIcon } from "../SvgIcon/SvgIcon"

interface NavLinkProps extends NavItemProps {
  iconUrl?: string
  iconActiveUrl?: string
  label: string
  href: string
}

export function NavLink({
  iconUrl,
  iconActiveUrl,
  badge,
  label,
  href,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: NavLinkProps) {
  return (
    <NavItem badge={badge}>
      <a
        className={styles.link}
        href={href}
        target="_blank"
        analytics-section={analyticsSection}
        analytics-type={analyticsType}
        analytics-name={analyticsName}
        analytics-id={analyticsId}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {iconUrl && (
          <div className={styles.icon}>
            <SvgIcon url={iconUrl} />
            {!!iconActiveUrl && <SvgIcon url={iconActiveUrl} />}
          </div>
        )}
        {label}
        <div className={styles.arrow}>
          <SvgIcon url={arrowIconUrl} size="xs" />
        </div>
      </a>
    </NavItem>
  )
}
