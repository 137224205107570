export const i18nId = Object.freeze({
  Delivery: "generic.delivery",
  DeliveryDescription: "generic.delivery.description",
  Save: "generic.save",
  Search: "generic.search",
  ShippingDestination: "generic.shipping-destination",
  ShippingDestinationPlaceholder:
    "form.get-a-quote.what-are-you-looking-for-panel.order-destination.unselected",
  ZipCode: "generic.postcode",
  ZipCodeValidationError:
    "order-flow-details.form.companyPostcode.validation-error",
  GenericError: "modals.shipping-destination.errors.generic",
  BlockingLineItemsError:
    "modals.shipping-destination.errors.blocking-line-items",
} as const)
