import type { Alpha2CountryCode } from "@ph/api-studio"
import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import { getUserCountry } from "../helpers/get-user-country"
import { countriesI18n } from "../others/i18n-countries"

export interface TranslatedCountry {
  id: Alpha2CountryCode
  name: string
}

interface ShippingCountriesConfig {
  allowPartiallySupportedCountriesForLocaleEn?: boolean
}

export const getTranslatedShippingCountries = (
  config: ShippingCountriesConfig = {}
): TranslatedCountry[] => {
  const countries = getAllowedShippingCountries(config)

  return translateShippingCountries(countries)
}

const getAllowedShippingCountries = (
  config: ShippingCountriesConfig
): Alpha2CountryCode[] => {
  const countries = RegionConfig.getKeyForCurrentRegion(
    "allowedShippingCountries"
  ) as Alpha2CountryCode[]

  const withPartiallySupportedCountries =
    shouldAttachPartiallySupportedCountries(
      config.allowPartiallySupportedCountriesForLocaleEn
    )

  if (withPartiallySupportedCountries) {
    return [
      ...countries,
      ...RegionConfig.getKeyForCurrentRegion("partiallySupportedCountries"),
    ]
  }

  return countries
}

const shouldAttachByUserCountry = (): boolean => {
  const userCountry = getUserCountry()

  if (!userCountry) {
    return false
  }

  return RegionConfig.isCurrentCountryPartiallySupported(userCountry)
}

const shouldAttachByUserLocale = (
  allowPartiallySupportedCountriesForLocaleEn: boolean
): boolean => {
  if (!allowPartiallySupportedCountriesForLocaleEn) {
    return false
  }

  return RegionConfig.getCurrentLocaleIso639() === "en"
}

const shouldAttachPartiallySupportedCountries = (
  allowPartiallySupportedCountriesForLocaleEn = false
): boolean => {
  return (
    shouldAttachByUserCountry() ||
    shouldAttachByUserLocale(allowPartiallySupportedCountriesForLocaleEn)
  )
}

const translateShippingCountries = (
  countries: Alpha2CountryCode[]
): TranslatedCountry[] => {
  const locale = RegionConfig.getCurrentLocaleIso639()

  return countries
    .map((country) => ({
      name: countriesI18n.getName(country, locale),
      id: country,
    }))
    .sort((country1, country2) =>
      new String(country1.name).localeCompare(country2.name, locale)
    )
}
