import { ObjectController } from "./object.controller"
import { PackhelpEditableShape } from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { isAssetShape } from "../../../../../../types/asset.types"

export class ShapeObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableShape,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (!isAssetShape(canvasObject)) {
      throw new Error("Type not supported")
    }
  }

  public isColorModificationAvailable(): boolean {
    if (this.canvasObject.isFsc) {
      return false
    }

    return super.isColorModificationAvailable()
  }

  public isMovingLayerUpDownAvailable(): boolean {
    if (this.canvasObject.isFsc) {
      return false
    }

    return super.isMovingLayerUpDownAvailable()
  }

  public isDuplicatingAvailable(): boolean {
    if (this.canvasObject.isFsc) {
      return false
    }

    return super.isDuplicatingAvailable()
  }

  public isGroupable(): boolean {
    if (this.canvasObject.isFsc) {
      return false
    }

    return super.isGroupable()
  }
}
