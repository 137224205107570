import React from "react"
import { observer } from "mobx-react-lite"
import { formatPriceWithCurrency } from "shared-libs/src/js/shared-components/formatted-currency/format-price-with-currency"
import styles from "./ProductInfo.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { useCurrency } from "../../../hooks/useCurrency"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { Tooltip } from "../../atoms/Tooltip/Tooltip"
import { Skeleton } from "../../atoms/Skeleton/Skeleton"
import { prepareImgixUrl } from "../../../../libs/helpers/prepare-imgix-url"
import { ConditionalWrapper } from "../../atoms/ConditionalWrapper/ConditionalWrapper"

export const ProductInfo = observer(function () {
  const t = useTranslate()
  const currency = useCurrency()

  const [containerSet] = useContainerSet((c) => [
    c.bootstrapClassicRootStore,
    c.ecommerce,
    c.ui,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { bootstrapClassicRootStore, ui, ecommerce, envUtil } = containerSet
  const { product } = bootstrapClassicRootStore.productStore

  if (!product.id) {
    return null
  }

  const isProductConfiguratorEnabled =
    envUtil.appConfig.api.ecommerce.features.ecommerce.productConfigurator
  const image = product.getMainProductImage()
  const price = ecommerce.productPricingStore?.totalPrice.value
  const quantity = ecommerce.ecommerceStore?.visibleQuantity
  const isLoadingPricing = ecommerce.ecommerceStore?.isLoadingPricing

  const onClick = () => {
    ui.sidebarStore.selectTab("configuration")
  }

  const imageUrl = image?.optimized?.cdnUrl

  return (
    <ConditionalWrapper
      condition={isProductConfiguratorEnabled}
      wrapper={(children) => {
        return (
          <Tooltip text={t(I18N.product.configuration.see)}>{children}</Tooltip>
        )
      }}
    >
      <button
        className={styles["product-info"]}
        onClick={onClick}
        analytics-section="top-right-corner"
        analytics-type="product-link"
        analytics-name="product-details"
        e2e-target="button"
        e2e-target-name="product-details"
      >
        {imageUrl && (
          <img
            src={prepareImgixUrl(imageUrl, { auto: "format", h: 92, q: 75 })}
          />
        )}

        <div className={styles.description}>
          <span e2e-target="text" e2e-target-name="product-name">
            {product.getTranslatedName()}
          </span>

          {!!quantity && isProductConfiguratorEnabled && (
            <span
              e2e-target="text"
              className={styles.quantity}
              e2e-target-name="quantity"
            >
              {t(I18N.generic.piecesNumber, { quantity })}
            </span>
          )}
        </div>

        {ecommerce.available && isProductConfiguratorEnabled && (
          <>
            {isLoadingPricing ? (
              <div
                className={styles.price}
                e2e-target="loader"
                e2e-target-name="price"
              >
                <Skeleton width={66} height={8} />
              </div>
            ) : (
              <>
                {!!price && !!currency && (
                  <div
                    className={styles.price}
                    e2e-target="text"
                    e2e-target-name="price"
                  >
                    {formatPriceWithCurrency(price.toString(), currency)}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </button>
    </ConditionalWrapper>
  )
})
