import { action, computed, observable, makeObservable } from "mobx"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { SubTab } from "../../types/ui.types"

export abstract class AbstractTabController {
  @observable public _activeSubTab?: SubTab

  protected constructor(services: { ee: AllEditorEventsEmitter }) {
    makeObservable(this)

    services.ee.on(eventTree.tab.tabChanged, () => this.setActiveSubTab())
  }

  public abstract get subTabs(): SubTab[]

  @action
  public setActiveSubTab(subTab?: SubTab): void {
    this._activeSubTab = subTab
  }

  @computed
  public get activeSubTab(): SubTab | undefined {
    const fallbackSubTab = this.subTabs[0]

    return this._activeSubTab || fallbackSubTab
  }

  public get isSubTabSwitcherVisible(): boolean {
    return this.subTabs.length > 1
  }
}
