import type { Alpha2CountryCode, Country } from "@ph/api-studio"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import { Loader, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import OutliningPanel, {
  PanelBackground,
} from "dsl/src/components/OutliningPanel/OutliningPanel"
import React, { useState } from "react"
import { useTranslate } from "../../../../shared-components/i18n/useTranslate"
import { sendShippingDestinationSavedEvent } from "../utils/segment"
import { i18nId } from "../utils/translations"
import type {
  ShippingDestinationError,
  ShippingDestinationErrorType,
} from "../utils/types"
import { validateZipCode } from "../utils/zip-code.helpers"
import { FormFieldShippingCountry } from "./FormFieldShippingCountry"
import { FormFieldShippingZipCode } from "./FormFieldShippingZipCode"
import styles from "./ShippingDestinationForm.module.scss"

interface ShippingDestinationFormProps {
  deliveryEstimatesPanel?: React.ReactNode
  error?: ShippingDestinationError
  isSaving?: boolean
  onSave: (countryIso: Alpha2CountryCode, zipCode: string) => void
  shippingCountry: Country
  shippingZipCode?: string
}

export const ShippingDestinationForm = ({
  deliveryEstimatesPanel,
  error,
  isSaving,
  onSave,
  shippingCountry,
  shippingZipCode = "",
}: ShippingDestinationFormProps) => {
  const [countryIso, setCountryIso] = useState<Alpha2CountryCode>(
    shippingCountry.iso
  )
  const [zipCode, setZipCode] = useState<string>(shippingZipCode)
  const [shouldDisplayValidationError, setShouldDisplayValidationError] =
    useState(false)
  const translate = useTranslate()

  const isZipCodeValid = validateZipCode(zipCode, countryIso)
  const isCountryTouched = countryIso !== shippingCountry.iso
  const isZipCodeTouched = zipCode !== shippingZipCode
  const isTouched = isCountryTouched || isZipCodeTouched
  const isButtonDisabled = !countryIso || !zipCode || !isTouched || isSaving

  const handleCountryChange = (newCountryIso: Alpha2CountryCode) => {
    setShouldDisplayValidationError(false)

    if (countryIso) {
      setZipCode("")
    }

    setCountryIso(newCountryIso)
  }

  const handleButtonClick = () => {
    setShouldDisplayValidationError(true)

    if (!isZipCodeValid) {
      return
    }

    sendShippingDestinationSavedEvent()
    onSave(countryIso, zipCode)

    setShouldDisplayValidationError(false)
  }

  const getErrorMessage = (errorType: ShippingDestinationErrorType) => {
    if (errorType === "blocking-line-items") {
      return translate(i18nId.BlockingLineItemsError)
    }

    return translate(i18nId.GenericError)
  }

  return (
    <>
      {error && (
        <div
          className={styles.error}
          e2e-target-name="shipping-destination-error"
        >
          <OutliningPanel background={PanelBackground.Red}>
            <Typography type={TType.Body15_350}>
              {getErrorMessage(error.type)}
              {error.list && (
                <ul className={styles.error_list}>
                  {error.list.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
            </Typography>
          </OutliningPanel>
        </div>
      )}
      <div className={styles.title}>
        <Typography type={TType.Body15_350} className={styles.grey}>
          {translate(i18nId.DeliveryDescription)}
        </Typography>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.country}>
          <FormFieldShippingCountry
            countryIso={countryIso}
            handleCountryChange={handleCountryChange}
          />
        </div>
        <div className={styles.postcode}>
          <FormFieldShippingZipCode
            handleZipCodeChange={setZipCode}
            shouldDisplayError={shouldDisplayValidationError && !isZipCodeValid}
            zipCode={zipCode}
          />
        </div>
      </div>
      <div className={styles.action}>
        <Button
          size={ButtonSizes.medium}
          disabled={isButtonDisabled}
          onClick={handleButtonClick}
          fluid
        >
          {isSaving ? (
            <Loader type={LoaderTypes.circular} />
          ) : (
            translate(i18nId.Save)
          )}
        </Button>
      </div>
      {deliveryEstimatesPanel}
    </>
  )
}
