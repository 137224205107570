import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { FontList } from "../../FontList/FontList"
import { FontFamilyDefinition } from "../../../../../libs/services/fonts-loader-service/fonts-loader.service"

export const TabFonts = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { fontsTabController } = containerSet.ui
  const { selectedFontFamily } = fontsTabController

  const onClick = (definition: FontFamilyDefinition) => {
    fontsTabController.setFontFamily(definition)
  }

  return (
    <Tab title={t(I18N.tabs.fonts)} e2eTargetName="fonts">
      <FontList
        onClick={onClick}
        selectedFontFamily={selectedFontFamily}
        analyticsSection="left-tab-fonts"
      />
    </Tab>
  )
})
