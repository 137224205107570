import type { ApiSessionContainer } from "./api-session.container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { DesignerModeStore } from "../stores/designer-mode.store"
import type { DesignerModeControllable } from "../controllers/designer-mode/designer-mode-controllable.interface"
import type { UtilEnvContainer } from "./util.container"

async function importDeps() {
  const { DesignerModeStore } = await import("../stores/designer-mode.store")
  const { DesignerModeControllerFactory } = await import(
    "../controllers/designer-mode/designer-mode-controller.factory"
  )

  return { DesignerModeStore, DesignerModeControllerFactory }
}

export async function provideDesignerModeMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer
): Promise<DesignerModeMaybeContainer> {
  const { apiService, directusApiService } = apiSessionContainer
  const { uri, ee, appConfig } = utilEnvContainer
  const { assetStore, productDriver } = designAndProduct

  /**
   * DesignerModeControllable.load() should also be run for ecommerce mode during dtp test flow.
   *
   * We need it to load a template onto a product without publishing a template or making pricing migration.
   * This is a temporary solution until DTP Auto provides an endpoint to generate an artwork from design data.
   */
  if (uri.isDesignerMode() || uri.isDtpTestEcommerceMode()) {
    const { DesignerModeStore, DesignerModeControllerFactory } =
      await importDeps()

    const designerModeStore = new DesignerModeStore()
    const designerModeControllerFactory = new DesignerModeControllerFactory(
      appConfig.api,
      uri,
      productDriver,
      designerModeStore,
      assetStore,
      ee,
      apiService,
      directusApiService
    )
    const designerModeController = designerModeControllerFactory.getController()

    return {
      available: true,
      designerModeStore,
      designerModeController,
    }
  }

  return {
    available: false,
    designerModeStore: undefined,
    designerModeController: undefined,
  }
}

export type DesignerModeMaybeContainer =
  | {
      available: true
      designerModeStore: DesignerModeStore
      designerModeController: DesignerModeControllable
    }
  | {
      available: false
      designerModeStore: undefined
      designerModeController: undefined
    }
