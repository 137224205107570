import React from "react"
import styles from "./Button.module.scss"
import { ButtonLinkProps, ButtonPlainProps } from "./types"
import { Loader, LoaderTypes } from "../Loader/Loader"

export const ButtonInner = (
  props: Partial<ButtonPlainProps | ButtonLinkProps>
) => {
  const { children, icon, isLoading, isIconOnLeft } = props

  return (
    <>
      {icon ? (
        <>
          {isIconOnLeft ? (
            <div className={styles["button-wrapper"]}>
              <span className={styles["left-icon-wrapper"]}>{icon}</span>
              <span>{children}</span>
            </div>
          ) : (
            <>
              <span className={styles["label"]}>{children}</span>
              <span className={styles["icon-wrapper"]}>{icon}</span>
            </>
          )}
        </>
      ) : (
        <>
          {isLoading && (
            <span className={styles["loader-icon"]}>
              <Loader type={LoaderTypes.circular} key="button-loader" />
            </span>
          )}
          {children}
        </>
      )}
    </>
  )
}
