import { ProductDesignStore } from "../../stores/product-design.store"
import {
  AllEditorEventsEmitter,
  EditorEventNames,
  eventTree,
} from "../../events/editor.events"
import { EditorMode } from "../../libs/products-render-config/types"
import ProductDriver from "../../drivers/product.driver"
import { Design, DesignMeta } from "../../types/design.types"
import { toJS } from "mobx"
import { DbyModeStore } from "../../stores/dby-mode.store"
import { ProductDesignStateToPayloadTransformer } from "../../services/transformers/product-design/product-design-state-to-payload.transformer"
import { Debug } from "../../services/logger"
import { DesignApi } from "@ph/design-api"
import { EcommerceApi } from "../../libs/api/ecommerce-api/types"

const debug = Debug("ph:editor:controllers:product-design-save")

const SAVE_INTERVAL_MS = 60000 // 60 seconds

type SaveOptions = {
  force: boolean
  notifyOnSuccess?: boolean
  withPreview?: boolean
  allowEmpty?: boolean
}

type SaveRequestOptions = SaveOptions & {
  abortController: AbortController
}

export class SaveProductDesignController {
  private isEventListenersInitialized = false
  private currentRequestAbortController = new AbortController()

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly productDesignStore: ProductDesignStore,
    private readonly dbyModeStore: DbyModeStore,
    private readonly designApi: DesignApi,
    private readonly ecommerceApi: EcommerceApi,
    private readonly ee: AllEditorEventsEmitter,
    private readonly editorMode: EditorMode,
    private readonly isAutoSaveEnabled = true
  ) {
    this.initEventListeners()
    this.initAutoSave()
  }

  public async save(
    options: SaveOptions = {
      force: false,
      notifyOnSuccess: false,
      withPreview: false,
      allowEmpty: false,
    }
  ) {
    if (this.productDesignStore.isDesignReadOnly) {
      this.ee.emit(
        eventTree.pd.saveSkippedReadOnly,
        this.productDesignStore.state.meta
      )

      return
    }

    if (!this.isValidForSave(options)) {
      this.ee.emit(eventTree.pd.saveSkippedNotValid)

      return
    }

    // Forced save should abort any previous saving request
    this.currentRequestAbortController.abort()
    this.currentRequestAbortController = new AbortController()

    await this.doSave({
      ...options,
      abortController: this.currentRequestAbortController,
    })
  }

  private async doSave(options: SaveRequestOptions): Promise<void> {
    try {
      this.productDesignStore.setProductDesignSaving(true)

      const meta = await this.prepareAndSave(options)
      this.productDesignStore.setProductDesignSaving(false)

      this.ee.emit(eventTree.pd.saved, meta)

      if (options.notifyOnSuccess) {
        this.ee.emit(eventTree.notification.designSaved)
      }
    } catch (e) {
      const wasRequestAborted = options.abortController.signal.aborted
      const isOtherRequestPending =
        !this.currentRequestAbortController.signal.aborted

      // Request is aborted intentionally, we don't need to capture it to Sentry
      if (wasRequestAborted && isOtherRequestPending) {
        return
      }

      console.error(e)

      // We need to unblock "Save" button in case of any error
      this.productDesignStore.setProductDesignSaving(false)

      if (window.Sentry) {
        window.Sentry.captureException(e)
      }
    }
  }

  private isValidForSave(options: SaveOptions): boolean {
    if (this.editorMode === "designer") {
      return false
    }

    // Autosave should not override current saving request
    if (!options.force && this.productDesignStore.state.isProductDesignSaving) {
      return false
    }

    if (this.editorMode === "editor") {
      return options.force || this.productDesignStore.state.isDesignTouched
    }

    if (this.editorMode === "dby") {
      return options.allowEmpty || !!this.dbyModeStore.uploadedFile
    }

    return true
  }

  private async prepareAndSave(
    options: SaveRequestOptions
  ): Promise<DesignMeta> {
    const meta = toJS(this.productDesignStore.state.meta)

    debug(`Prepare and save for editorMode: ${this.editorMode}`)

    if (this.editorMode === "editor") {
      const renderEngineSnapshot =
        await this.productDriver.generateRenderEngineSnapshot(options)

      if (renderEngineSnapshot.isVersionGenerated) {
        const newMeta = await this.createOrUpdateDesign(
          {
            data: this.productDesignStore.setEditorDesignData(
              renderEngineSnapshot.exportedData
            ),
            meta,
          },
          options.abortController
        )
        this.productDesignStore.setProductDesignId(newMeta.id)

        return newMeta
      }

      return meta
    }

    if (this.editorMode === "dby") {
      const dbyDesignData = await this.dbyModeStore.getDbyDesignData(
        options.allowEmpty
      )
      this.productDesignStore.setDesignTouched(false)
      const newMeta = await this.createOrUpdateDesign(
        {
          data: dbyDesignData,
          meta,
        },
        options.abortController
      )
      this.productDesignStore.setProductDesignId(newMeta.id)

      return newMeta
    }

    throw new Error(`Mode is not supported`)
  }

  private async createOrUpdateDesign(
    design: Design,
    abortController: AbortController
  ): Promise<DesignMeta> {
    if (this.productDesignStore.isDesignReadOnly) {
      throw new Error("Can't update design when in cart or public")
    }

    const dto = new ProductDesignStateToPayloadTransformer(
      this.productDriver.productStore,
      design
    ).transform()

    const { meta } = design
    let response
    let event: EditorEventNames

    const requestOptions = {
      abortController: abortController,
    }

    if (!meta.id) {
      response = await this.designApi.create(dto, requestOptions)
      this.designApi.setDesignToken(response.write_token)
      await this.ecommerceApi.user.assignDesign(response)

      event = eventTree.pd.created
    } else {
      response = await this.designApi.update(meta.id, dto, requestOptions)
      await this.ecommerceApi.user.updateDesign(response)
      event = eventTree.pd.created
    }

    const newMeta = Object.assign({}, meta, response)
    this.ee.emit(event, newMeta)

    return newMeta
  }

  private initEventListeners() {
    if (this.isEventListenersInitialized || this.editorMode === "designer") {
      return
    }

    this.ee.on(eventTree.pd.saveTriggered, (options) => this.save(options))
    this.isEventListenersInitialized = true
  }

  private initAutoSave() {
    const { isDraftDesignInCart } = this.productDesignStore.state

    const { isAfterPurchaseEdit } = this.productDriver.state.productRenderPilot
    if (
      this.editorMode !== "editor" ||
      !this.isAutoSaveEnabled ||
      isDraftDesignInCart ||
      isAfterPurchaseEdit
    ) {
      return
    }

    setTimeout(async () => {
      if (
        this.productDesignStore.state.isDesignTouched &&
        !isDraftDesignInCart &&
        !isAfterPurchaseEdit
      ) {
        await this.save()
      }

      this.initAutoSave()
    }, SAVE_INTERVAL_MS)
  }
}
