import ProductDriver from "../drivers/product.driver"
import {
  PackhelpEditableGroup,
  PackhelpEditableObject,
} from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { PasteFromClipboardController } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/paste-from-clipboard.controller"
import VirtualDielineEditor from "../render-engine/modules/vd-editor/virtual-dieline-editor"
import { CanvasObjectControllable } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"

export class ObjectClipboardStore {
  private clipboardObject?: PackhelpEditableObject | PackhelpEditableGroup

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly ee: AllEditorEventsEmitter
  ) {
    this.ee.on(eventTree.activeObject.copy, this.copyToClipboard.bind(this))
    this.ee.on(eventTree.activeObject.paste, this.pasteFromClipboard.bind(this))
  }

  private async pasteFromClipboard() {
    const { activeSpace, activeViewType } = this.productDriver.state

    if (!this.clipboardObject || activeViewType === "model") {
      return
    }

    const spaceId = activeSpace || this.clipboardObject.originSpaceArea
    const vdEditor = this.getVirtualDielineEditor()
    const pasteFromClipboardController = new PasteFromClipboardController(
      vdEditor
    )
    const pastedObject = await pasteFromClipboardController.paste(
      this.clipboardObject,
      spaceId
    )

    vdEditor.assetsModule.setActiveObject(pastedObject)
  }

  private async copyToClipboard() {
    if (
      !this.activeObjectController ||
      !this.activeObjectController.isDuplicatingAvailable()
    ) {
      return
    }

    this.clipboardObject = await this.activeObjectController.duplicate({
      withRender: false,
    })
  }

  private get activeObjectController(): CanvasObjectControllable | null {
    return this.productDriver.activeObjectDriver.activeObjectController
  }

  private getVirtualDielineEditor(): VirtualDielineEditor {
    const { renderEngine, activeContext } = this.productDriver.state

    if (!renderEngine) {
      throw new Error("Render engine is not ready")
    }

    return renderEngine.getVirtualDielineEditor(activeContext)
  }
}
