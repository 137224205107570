import ApiService from "../services/api/api-service"
import { getApiTokenFromCookie } from "../helpers/api-token.helpers"
import {
  appendSegmentSession,
  createSegmentSession,
} from "./segment/segment-analytics.service"
import { FirstTouchService } from "../services/user-first-touch-service"
import { postUserFirstTouch } from "../services/api/endpoints/analytics-endpoints"
import { getPartnerstackData } from "../services/partnerstack-service"
import { postPartnerstackPartnerKey } from "../services/api/endpoints/partners-endpoints"
import { AttributionService } from "./attribution-service"
import { identifyUser } from "./segment/segment-analytics.methods"

type User = {
  id: number
  email?: string
  spree_api_key: string
}

export class AnalyticsInitializer {
  private readonly api: ApiService

  constructor(token: string = getApiTokenFromCookie()) {
    this.api = new ApiService(null, token)
  }

  public async init(user: User): Promise<void> {
    this.initAttributionData()

    await Promise.all([
      this.initSegmentSession(user),
      this.registerEventFirstTouch(),
      this.registerPartnerstackPartnerKey(),
    ])
  }

  private initAttributionData(): void {
    new AttributionService().initAttributionData()
  }

  private async initSegmentSession(user: User): Promise<void> {
    if (user.email) {
      await appendSegmentSession(user.spree_api_key)
      identifyUser({ userId: user.id })
    } else {
      await createSegmentSession(user.spree_api_key)
    }
  }

  private async registerEventFirstTouch(): Promise<void> {
    const fts = new FirstTouchService()
    const payload = await fts.getUserFirstTouchData()

    await this.api.call(postUserFirstTouch(payload))
  }

  private async registerPartnerstackPartnerKey(): Promise<void> {
    const payload = getPartnerstackData()

    if (!payload.partnerstack_data.partner_key) {
      return
    }

    await this.api.call(postPartnerstackPartnerKey(payload))
  }
}
