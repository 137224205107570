import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { PantoneColorsDatabase } from "../../../../libs/colors/pantone-colors.database"
import { PANTONE_COLORS_DB_DEFAULT_FAVORITE } from "../../../../libs/colors/pantone-custom-palettes"

import _uniqBy from "lodash/uniqBy"
import _inRange from "lodash/inRange"
import { PantoneColorsPreset } from "../../../../libs/products-render-config/types"

export type PantoneColorGroupName =
  | "Defaults"
  | "Darks"
  | "Lights"
  | "Reds"
  | "Oranges"
  | "Yellows"
  | "Greens"
  | "Cyans"
  | "Blues"
  | "Violets"
  | "Magentas"

export type PantoneColorGroups = Record<
  PantoneColorGroupName,
  PantonePickerColorObject[]
>

class PantoneColorsGroupingProvider {
  readonly db = new PantoneColorsDatabase()
  private _colors: PantonePickerColorObject[]
  get colors() {
    return this._colors
  }
  private _groups: PantoneColorGroups
  get groups() {
    return this._groups
  }

  constructor(
    _colors?: PantonePickerColorObject[],
    _defaultGroupPantoneNames?: string[]
  ) {
    this._colors = _colors || this.db.getByPreset(PantoneColorsPreset.ALL)
    const defaultGroupPantoneNames: string[] =
      _defaultGroupPantoneNames || PANTONE_COLORS_DB_DEFAULT_FAVORITE

    const initGroups: PantoneColorGroups = {
      Defaults: [],
      Darks: [],
      Lights: [],
      Reds: [],
      Oranges: [],
      Yellows: [],
      Greens: [],
      Cyans: [],
      Blues: [],
      Violets: [],
      Magentas: [],
    }

    // classify all colours
    const groups = this.colors.reduce((prev, color) => {
      const groupNames = this.classify(color)
      groupNames.forEach((groupName) => {
        prev[groupName].push(color)
      })
      return prev
    }, initGroups)

    // sort colours in groups from lightest to darkest
    const lightestToDarkest = (
      a: PantonePickerColorObject,
      b: PantonePickerColorObject
    ) => b.v - a.v
    groups.Darks.sort(lightestToDarkest)
    groups.Lights.sort(lightestToDarkest)
    groups.Reds.sort(lightestToDarkest)
    groups.Oranges.sort(lightestToDarkest)
    groups.Yellows.sort(lightestToDarkest)
    groups.Greens.sort(lightestToDarkest)
    groups.Cyans.sort(lightestToDarkest)
    groups.Blues.sort(lightestToDarkest)
    groups.Violets.sort(lightestToDarkest)
    groups.Magentas.sort(lightestToDarkest)

    groups.Defaults = this.getDefaultColourObjects(
      this.colors,
      defaultGroupPantoneNames
    )

    this._groups = groups
  }

  private getDefaultColourObjects(
    allColors: PantonePickerColorObject[],
    defaultPantoneNames: string[]
  ) {
    const uppercaseDefaultNames = defaultPantoneNames.map((n) =>
      n.toUpperCase()
    )
    return allColors.filter((color: PantonePickerColorObject) => {
      return uppercaseDefaultNames.includes(color.pantoneName.toUpperCase())
    })
  }

  private classify(color: PantonePickerColorObject): PantoneColorGroupName[] {
    let groups: PantoneColorGroupName[] = []

    // light greish colours
    if (color.s === 0 && color.v > 0.5) groups.push("Lights")
    if (_inRange(color.s, 0, 0.2) && color.v > 0.85) groups.push("Lights")

    // dark greyish colours
    if (color.s === 0 && color.v < 0.5) groups.push("Darks")
    if (_inRange(color.s, 0, 0.2) && color.v < 0.6) groups.push("Darks")
    if (color.s < 0.5 && color.v < 0.35) groups.push("Darks")

    groups = _uniqBy(groups, (name) => {
      return name
    })

    /* My color estimation in 0 - 360 deg scale:
      "Reds" // 0-20~25
      "Oranges" // 20-50~53
      "Yellows" // 50 - 60~65
      "Greens" // 63 - 160~175
      "Cyans" // 170 - 190~200
      "Blues" // 186 - 245~255
      "Violetes" // 245 - 290~300
      "Magentas" // 295 - 335~340
      "Reds" (again) // ~335-360 
    */

    // if it's at least a bit saturated and not too bright, not too light
    // if (color.s > 0.1 && inRange(color.v, 0.1, 0.9)) {
    if (color.s > 0.1) {
      const hue: number = color.h
      if (_inRange(hue, 0.0, 0.069444)) groups.push("Reds")
      if (_inRange(hue, 0.055555, 0.147222)) groups.push("Oranges")
      if (_inRange(hue, 0.138888, 0.180555)) groups.push("Yellows")
      if (_inRange(hue, 0.175, 0.486111)) groups.push("Greens")
      if (_inRange(hue, 0.472222, 0.555555)) groups.push("Cyans")
      if (_inRange(hue, 0.516666, 0.708333)) groups.push("Blues")
      if (_inRange(hue, 0.680555, 0.833333)) groups.push("Violets")
      if (_inRange(hue, 0.819444, 0.944444)) groups.push("Magentas")
      if (_inRange(hue, 0.930555, 1.0)) groups.push("Reds")
    }

    return groups
  }

  private escapeRegExp(text: string | number) {
    if (typeof text === "number") return text
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
  }

  searchByPantoneName(query: string) {
    const regExp = new RegExp(`^.*(${this.escapeRegExp(query)}).*`, "i")
    const found = this.colors.filter((pantone) => {
      const pantoneNameWithoutSpaces = pantone.pantoneName.replace(/ /g, "")

      return (
        pantone.pantoneName.match(regExp) ||
        pantoneNameWithoutSpaces.match(regExp)
      )
    })

    return found
  }
}

export default PantoneColorsGroupingProvider
