import VirtualDielineEditor from "../../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { DesignVersion } from "../../../types/design.version"
import { calcObjectAbsoluteValues } from "../../../render-engine/modules/vd-editor/modules/assets-module/editable-group-controller/helpers"
import { VirtualDielineTemplateDataLessObject } from "../../../types/asset.types"
import { AssetsStore } from "../../../stores/assets.store"
import { DesignTemplateData } from "../../../types/design.types"
import { VirtualDielineExporter } from "../../../render-engine/modules/vd-editor/services/virtual-dieline.exporter"

export class TemplateExporter {
  public constructor(
    private readonly vdEditors: VirtualDielineEditor[],
    private readonly assetsStore?: AssetsStore
  ) {}

  public async export(): Promise<DesignTemplateData> {
    const vdExport = {}

    for (const vdEditor of this.vdEditors) {
      vdExport[vdEditor.editContext] = await this.exportVirtualDieline(vdEditor)
    }

    return {
      virtualDielines: vdExport,
      designDataFormatVersion: DesignVersion.latest,
      files: this.assetsStore?.serialize() || [],
    }
  }

  private async exportVirtualDieline(
    vdEditor: VirtualDielineEditor
  ): Promise<VirtualDielineTemplateDataLessObject> {
    const vdExporter = new VirtualDielineExporter(vdEditor, {
      unpackGroups: false,
    })

    const { version, objects } = await vdExporter.export()
    const spaces = {}

    vdEditor.virtualDieline.getObjects().forEach((space) => {
      const { left, top } = calcObjectAbsoluteValues(space)

      spaces[space.id] = {
        top: top,
        left: left,
        width: space.getScaledWidth(),
        height: space.getScaledHeight(),
        rotation: space.rotation,
      }
    })

    return {
      version: version,
      objects: objects,
      spaces: spaces,
    }
  }
}
