import fabric from "../../../../../../../libs/vendors/Fabric"

interface IdentifiableObject extends fabric.Object {
  id: string | undefined
}

export const placeholderMargin = 18

export function loadSvgFromString(svg: string): Promise<IdentifiableObject> {
  return new Promise((resolve) => {
    fabric.loadSVGFromString(
      svg,
      (objects, options) => {
        const groupedSvg = fabric.util.groupSVGElements(
          objects,
          Object.assign({}, options)
        ) as IdentifiableObject

        resolve(groupedSvg)
      },
      undefined,
      //@ts-ignore
      {
        crossOrigin: "Anonymous",
      }
    )
  })
}

export enum Colors {
  white = "#FFFFFF",
  lightGrey = "#F8F8F8",
  blue = "#3353D8",
  black = "#000000",
}

export const defaultTextSettings = {
  selectable: false,
  hasControls: false,
  fontSize: 10,
  fontFamily: "ABCFavoritVariable",
  textAlign: "center",
  originX: "center",
}

export const defaultBackgroundSettings = {
  selectable: false,
  hasControls: false,
  originX: "center",
  originY: "center",
  radius: 75,
  fill: Colors.lightGrey,
  strokeDashArray: [3, 3],
  stroke: Colors.black,
  shadow: new fabric.Shadow({
    color: "rgba(47,47,47, 0.5)",
    blur: 5,
    offsetX: 0,
    offsetY: 0,
  }),
}
