import { EcoShapesStore } from "../../stores/eco-shapes.store"
import ProductDriver from "../../drivers/product.driver"
import { ShapesManager } from "../../services/managers/shapes.manager"
import { ProductDesignStore } from "../../stores/product-design.store"
import { EditContext, SpaceId } from "../../libs/products-render-config/types"
import { isEcoQrCode, Shape } from "../../models/shape"
import { ShapesStore } from "../../stores/shapes.store"
import { AllEditorEventsEmitter } from "../../events/editor.events"
import { AssetsController, ProcessParams } from "./assets.controller"

export class ShapesController extends AssetsController {
  private readonly shapesManager: ShapesManager
  private readonly ecoShapesStore: EcoShapesStore
  private readonly shapesStore: ShapesStore
  private readonly productDesignStore: ProductDesignStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
      shapesManager: ShapesManager
    },
    stores: {
      ecoShapesStore: EcoShapesStore
      shapesStore: ShapesStore
      productDesignStore: ProductDesignStore
    }
  ) {
    super(services)

    this.shapesManager = services.shapesManager
    this.ecoShapesStore = stores.ecoShapesStore
    this.shapesStore = stores.shapesStore
    this.productDesignStore = stores.productDesignStore
  }

  public async addShape(shape: Shape): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      this.shapesStore.setIsLoadingSingleShape(true)
      await this.addShapeOnCanvas(shape, editContext, spaceId)
      this.shapesStore.setIsLoadingSingleShape(false)
    })
  }

  public async addEcoShape(shape: Shape): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      this.ecoShapesStore.setIsLoadingSingleEcoShape(true)
      const ecoShape = await this.prepareEcoShape(shape)
      await this.addShapeOnCanvas(ecoShape, editContext, spaceId)
      this.ecoShapesStore.setIsLoadingSingleEcoShape(false)
    })
  }

  private async prepareEcoShape(shape: Shape): Promise<Shape> {
    if (isEcoQrCode(shape)) {
      return await this.shapesManager.generateEcoQrShape(shape, {
        designId: await this.getDesignId(),
      })
    }

    return shape
  }

  private async addShapeOnCanvas(
    shape: Shape,
    editContext: EditContext,
    spaceId: SpaceId
  ) {
    const vdEditor = this.productDriver.getVdEditor(editContext)

    await vdEditor.assetsModule.addShape(shape, {
      spaceId,
      shouldSelect: this.shouldSelectAsset,
      shouldApplyClippingMask: true,
    })

    this.touchDesign()
  }

  private async getDesignId(): Promise<string | number | undefined> {
    const editorMode =
      this.productDriver.state.productRenderPilot.getEditorMode()

    if (editorMode === "designer") {
      return
    }

    return this.productDesignStore.forceDesignId()
  }
}
