import * as shared from "../shared/shared-consts"

const Region = {
  trustpilotId: shared.TRUSTPILOT.trustpilotId,
  trustpilotBusinessId: shared.TRUSTPILOT.trustpilotBusinessId,
  trustpilotInvitationTemplateId:
    shared.TRUSTPILOT.trustpilotInvitationTemplateId,

  googleapisKey: shared.GOOGLEAPIS_KEY,
  mainRegion: true,
  hasVat: true,
  languageTag: "es-ES",
  iterableLocale: "es-ES",
  locale: "es",
  phoneCountryCode: "+34",
  defaultShippingCountryCode: "ES",
  mainDomain: shared.PL_MAIN_DOMAIN,

  secondaryAnalyticsCode: "UA-71580330-11",
  productRegion: "es",
  countryCode: "es",
  emailAddress: "hello@packhelp.es",
  emailSenderName: "Packhelp.es",
  phoneNumber: "21 123 2906",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.es",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",
  frontendTermsOfServiceRoute: "/terminos_de_servicio/",
  frontendPrivacyPolicyRoute: "/politica_de_privacidad/",
  domain: {
    basename: "packhelp",
    tld: "es",
  },
  externalLinks: {
    qrForm: "/quote-request",
    affiliates: "/afiliado-de-packhelp/",
    fscCertification: "/certificacion-fsc/",
    helpPlantTrees: "/plantando-arboles/",
    ecoProperties: "/guia-de-propiedades-ecologicas/",
    sustainability: "/sostenibilidad/",
    designShowcase: "/patrones-y-estampados-de-packaging/",
    holidays: "/navidad/",
    inspirationsHandbook: "/inspirations-handbook/",
    mobileAppLandingPageUrl: "/aplicacion-packhelp-para-moviles/",
    brief: "/packhelp-brief-es/",
    artworkTipsDielines: "/consejos-para-disenar/",
    help: "/ayuda-preguntas-frecuentes/",
    editorHelp: "/ayuda/temas/editor-de-cajas/",
    thankYou: "/gracias/",
    designGuidelines: "/pomoc/tematy/editor-de-cajas/",
    about: "/quienes-somos/",
    career: "/carrera-profesional/",
    shop: "/tienda/",
    services: "/services/",
    industries: "/i/",
    inspirations: "/ideas/",
    impressum: "/impressum/",
    returnPolicy: "/ayuda/tema/puedo-devolver-las-cajas-que-he-comprado/",
    blog: "/blog/",
    contact: "/contactar-con-packhelp/",
    privacyPolicy: "/politica_de_privacidad/",
    termsOfService: "/terminos_de_servicio/",
    termsOfServicePlus: "/terms-of-services-plus/",
    accountSignOut: "/sign_out/",
    accountSignIn: "/sign_in/",
    accountRegister: "/registration",
    accountOrders: "/account/orders",
    accountOrderDetails: "/account/orders/:orderNumber",
    accountSettings: "/account/settings",
    accountSupportReforestation: "/account/support-reforestation",
    accountDesigns: "/account/boxes",
    accountWallet: "/account/packhelp-wallet",
    accountCart: "/cart/", // old checkout key - to be removed
    samples: "/sample-packs",
    customOffer: "/offer/:token/review",
    payment: "/order/:orderId/payment",
    paymentSummary: "/order/:orderId/payment/summary",
    quoteRequest: "account/quote_requests/:qrNumber",
    cart: "/cart/",
    bundles: "/sets-de-packaging",
    orderDetails: "/order",
    crossSell: "/cart/recommended-products-and-services/s",
    fullAndEcoColorHelpSite:
      "/ayuda/tema/cual-es-la-diferencia-entre-full-color-y-eco-color/",
    safeZonesHelpSite: "/ayuda/tema/mantente-alejado-de-los-bordes/",
    samplePacksLink:
      "/box/design?sku=box-eu--samples--samples--samples--samples--samples&dielineMode=false",
    termsAcceptedSite: "/terminos-aceptados-con-exito/",
    packhelpWallet: "/wallet-packhelp/",
    ecoBadge: "/insignia-eco-de-packhelp/",
    leadership: "/el-motor-de-packhelp/",
    press: "/prensa/",
    urlWhitelistedToBeFetchedFromUrl: shared.urlWhitelistedToBeFetchedFromUrl,
    products: {
      "mailer-box-plain": "/caja-postal-sin-impresion/",
      "mailer-box-eco": "/caja-postal-eco/",
      "mailer-box-eco-white": "/caja-postal-eco-blanca/",
      "mailer-box-full-color": "/caja-postal-full-color/",
      "mailer-box-eco-color": "/caja-postal-eco-color/",
      "delivery-mailer-box": "/cajas-para-e-commerce/",
      "delivery-mailer-box-plain": "/cajas-para-e-commerce-sin-impresion/",
      "shipping-box-plain": "/cajas-para-envios-sin-impresion/",
      "shipping-box": "/cajas-para-envios-eco/",
      "product-box": "/caja-para-productos-classic/",
      "product-box-two-pieces": "/caja-para-productos-de-dos-piezas/",
      "poly-mailer": "/poli-sobres-clasicas/",
      "paper-mailer": "/sobre-kraft/",
      "paper-bag": "/bolsas-de-papel-asa-trenzada/",
      "rigid-envelope": "/sobres-de-carton-personalizados/",
      "product-box-wine-box": "/cajas-para-botellas-personalizadas/",
      "tissue-paper": "/papel-de-seda-personalizado/",
      "packing-paper": "/papel-vegetal/",
      "packing-tape": "/cintas-de-embalaje/",
      "sleeved-mailer-box": "/cajas-con-faja-de-carton/",
      "kraft-tape": "/cintas-de-embalaje-kraft/",
      "bio-poly-mailer": "/poli-sobres-compostables/",
      "paper-can": "/latas-de-papel/",
      "mailer-box-white-ink": "/caja-postal-blanco-en-kraft/",
      "mailer-box-full-color-double-sided": "/caja-postal-con-impresion-doble/",
      "rigid-box": "/caja-rigida/",
      "rigid-box-two-pieces": "/caja-con-tapa/",
      "special-box": "/caja-especial-de-carton/",
      "magnetic-box": "/caja-con-cierre-magnetico/",
      "carrier-box": "/caja-de-carton-con-asa/",
      "rigid-drawer-box": "/caja-con-cierre-deslizante/",
      "cake-box": "/caja-para-tarta/",
      "bio-poly-mailer-plain": "/poli-sobres-compostables-preimpresos/",
      "pre-printed-mailer-box-eco": "/caja-postal-eco-preimpresa/",
      "pre-printed-mailer-box-full-colour": "/caja-postal-preimpresa/",
      "doypack-bag": "/bolsa-stand-up/",
      "square-label": "/bedruckte-etiketten/",
      "tote-bag": "/bolsa-de-tela-personalizada/",
      "printed-card": "/tarjetas-de-agradecimiento/",
      "pre-printed-kraft-tape": "/cinta-de-embalaje-kraft-preimpresa/",
      "recycled-poly-mailer": "/sobre-de-plastico-reciclado-para-envios/",
    },
    categories: {
      "mailer-boxes": "/cajas-postales/",
      "custom-shipping-boxes": "/cajas-envios/",
      "primary-packaging": "/packaging-para-productos/",
      "eco-friendly-packaging": "/packaging-ecologico/",
      "luxury-packaging": "/cajas-rigidas/",
    },
    fb_url: shared.EU_MAIN_DOMAIN,
    fb_sitename: "Packhelp",
    fb_image: "/share_image_1200x630.png",
    share_img: "/share_image_120x120.png", // Editor
    boxEditorPath: "/box/design",
    productEditor: "/editor/product/design",
    exampleDesign: "79485?language=es&templateMode=true",
    productQuotationSystem: "/quote",
    pqsSuccessPage: "/quote/success",
    plus: {
      catalogue: "/plus/catalogo/",
      successPage: "/plus/success/",
      items: "/packhelp-plus/items",
      quotes: "/packhelp-plus/quotes",
      quoteShow: "/packhelp-plus/quotes/:token",
      orders: "/packhelp-plus/orders",
      orderShow: "/packhelp-plus/orders/:orderNumber/details",
      orderPlaced: "/packhelp-plus/orders/:orderNumber/placed",
      orderItems: "/packhelp-plus/orders/:orderNumber/items",
      orderPaymentSummary: "/packhelp-plus/orders/:orderNumber/payment-summary",
      "doypack-bag": "/plus/catalogo/bolsa-stand-up/",
      personalDetails: "/packhelp-plus/personal-details",
      "tote-bag": "/plus/catalogo/bolsa-tote/",
      "product-boxes": "plus/catalogo/tipo-de-packaging/cajas-plegables/",
      "mailer-boxes": "plus/catalogo/tipo-de-packaging/cajas-postales/",
    },
    pro: {
      features: "pro/features/",
      suppliers: "pro/for-suppliers/",
      termsOfService: "pro/terms-conditions/",
    },
    industriesListing: "/i/",
    whiteLabel: "/enterprise/",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  ...shared.SHARED_ECOMMERCE_PROPERTIES.eu,
  ...shared.SHARED_CURRENCY_PROPERTIES.eur,
  allowedPaymentMethods: [
    "paypal",
    "check",
    "stripe",
    "p24",
    "ideal",
    "bancontact",
    "eps",
    "giropay",
    "card",
  ],

  remindMeOnDesktop: {
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=ed3951f71d&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_ed3951f71d",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_EU,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-11",
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "39198",
    conversionCode: "859901857",
  },

  facebookAppId: "1660833110841347",
  supportsPlus: true,
}

export default Region
