import fabric from "../../../../../../../libs/vendors/Fabric"
import { SpaceId } from "../../../../../../../libs/products-render-config/types"
import {
  PackhelpEditableLogoPlaceholderSlot,
  LogoPlaceholderState,
} from "../../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { EditableObjectTypes } from "../../../../../../../types/asset.types"
import { ScaleHelper } from "../../helpers/scale.helper"
import { createDesignerModePlaceholder } from "./designer-mode.placeholder"
import { createUserInteractivePlaceholder } from "./user-interactive.placeholder"
import { createUserNonInteractivePlaceholder } from "./user-non-interactive.placeholder"
import { createLoaderPlaceholder } from "./loader.placeholder"
import { CanvasObjectControllerFactory } from "../../canvas-object-controller/canvas-object-controller.factory"
import { LogoPlaceholderSlotObjectController } from "../../canvas-object-controller/logo-placeholder-slot-object.controller"
import { UrlManipulatorProvider } from "../../../../../../../services/manipulators/url.manipulator"
import { Creator } from "../creator"

function getPlaceholderCreateFunction(state?: LogoPlaceholderState) {
  switch (state) {
    case "interactive":
      return createUserInteractivePlaceholder
    case "nonInteractive":
      return createUserNonInteractivePlaceholder
    case "loading":
      return createLoaderPlaceholder
    case "designer":
      return createDesignerModePlaceholder
    default:
      return createUserInteractivePlaceholder
  }
}

const isCanvasDetached = (canvas?: fabric.Canvas): boolean => {
  return !canvas || canvas.getObjects().length === 0
}

const ANIMATION_TIME_INTERVAL = 10

export class LogoPlaceholderSlotCreator extends Creator {
  public async create(
    spaceId: SpaceId,
    state?: LogoPlaceholderState
  ): Promise<PackhelpEditableLogoPlaceholderSlot> {
    const object: PackhelpEditableLogoPlaceholderSlot =
      // @ts-ignore
      new fabric.LogoPlaceholderSlot([], {
        lockScalingY: false,
        lockScalingX: false,
        lockUniScaling: true,
        originSpaceArea: spaceId,
        id: uuidv4(),
        assetType: EditableObjectTypes.assetLogoPlaceholderSlot,
        enabled: true,
        width: 150,
        height: 150,
        top: 0,
        left: 0,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        state,
      })

    this.scaleObject(object, spaceId)
    this.alignObject(object)
    this.rotateObject(object, spaceId)
    await this.setObjectSpaceClipping(object, spaceId)

    const controller = new CanvasObjectControllerFactory(
      this.vdEditor
    ).getController(object) as LogoPlaceholderSlotObjectController

    controller.attachEventListeners()

    return object
  }

  private scaleObject(
    object: PackhelpEditableLogoPlaceholderSlot,
    spaceId: SpaceId
  ): void {
    const MANUALLY_ADJUSTED_SIZE = 512

    ScaleHelper.scaleObjectToSpace(
      this.vdEditor,
      object,
      {
        width: MANUALLY_ADJUSTED_SIZE,
        height: MANUALLY_ADJUSTED_SIZE,
      },
      spaceId
    )
  }
}

// @ts-ignore
fabric.LogoPlaceholderSlot = fabric.util.createClass(fabric.Group, {
  type: "logoPlaceholderSlot",
  async: true,
  placeholderReady: undefined as Promise<void> | undefined,
  isEventListenersAttached: false,

  initialize: async function (objects, options) {
    this.callSuper("initialize", objects, options)

    this.set({
      lockScalingY: options.lockScalingY,
      lockScalingX: options.lockScalingX,
      lockUniScaling: options.lockUniScaling,
      originSpaceArea: options.originSpaceArea,
      id: options.id,
      assetType: EditableObjectTypes.assetLogoPlaceholderSlot,
      enabled: options.enabled,
      width: options.width,
      height: options.height,
      top: parseFloat(options.top),
      left: parseFloat(options.left),
      scaleX: options.scaleX,
      scaleY: options.scaleY,
      angle: options.angle,
      subTargetCheck: true,
      visible: options.enabled,
    })

    if (options.enabled) {
      if (
        new UrlManipulatorProvider(globalThis.appConfig).getMode() ===
        "designer"
      ) {
        await this.addPlaceholder("designer", options.width)
      } else {
        await this.addPlaceholder(options.state || "interactive", options.width)
        if (options.state === "loading") {
          this.startLoadingAnimation()
        }
      }

      this.canvas?.renderAll()
      this.canvas?.requestRenderAll()
    }
  },

  interceptVisibilityChange(key, value): boolean | undefined {
    let interceptedVisibility: boolean | undefined = undefined

    if (typeof key === "object") {
      interceptedVisibility = key.visible
    } else if (key === "visible") {
      if (typeof value === "function") {
        interceptedVisibility = value(this.get(key))
      } else {
        interceptedVisibility = value
      }
    }

    return interceptedVisibility
  },

  onVisibilityChange(isVisible: boolean) {
    this.maskController?.setVisibility(isVisible)
  },

  /**
   * TODO: Maybe 'set' should be decorated on fabric level so that all objects can benefit.
   * Could be emitting event when setting properties.
   * Once fabric version is updated, it could all be more visible to change.
   */
  set(key, value) {
    const interceptedVisibility = this.interceptVisibilityChange(key, value)
    if (
      this.visible !== interceptedVisibility &&
      typeof interceptedVisibility === "boolean"
    ) {
      this.onVisibilityChange(interceptedVisibility)
    }
    // @ts-ignore
    return fabric.CommonMethods.set.call(this, key, value)
  },

  toObject: function (propertiesToInclude?: string[]) {
    const returnValue = fabric.util.object.extend(
      // propertiesToInclude should fix a problem with missing custom attributes in export
      this.callSuper("toObject", propertiesToInclude),
      // TODO: check if we really need this additional mapping below
      {
        lockScalingY: this.get("lockScalingY"),
        lockScalingX: this.get("lockScalingX"),
        lockUniScaling: this.get("lockUniScaling"),
        originSpaceArea: this.get("originSpaceArea"),
        id: this.get("id"),
        assetType: EditableObjectTypes.assetLogoPlaceholderSlot,
        enabled: this.get("enabled"),
        width: this.get("width"),
        height: this.get("height"),
        top: this.get("top"),
        left: this.get("left"),
        scaleX: this.get("scaleX"),
        scaleY: this.get("scaleY"),
        angle: this.get("angle"),
        subTargetCheck: this.get("subTargetCheck"),
      }
    )

    return returnValue
  },

  toDatalessObject: function (propertiesToInclude?: string[]) {
    const returnValue = fabric.util.object.extend(
      this.callSuper("toDatalessObject", propertiesToInclude),
      {
        objects: [],
      }
    )

    return returnValue
  },

  removePlaceholder: function () {
    const placeholder = this.item(0)
    this.remove(placeholder)
    this.isEventListenersAttached = false
  },

  // Enabled does not necessarily mean visible. Example - neighboring sides in edit mode.
  enable: async function (
    isVisible = true,
    state: LogoPlaceholderState = "nonInteractive"
  ) {
    this.clearAnimation()

    if (this.enabled) {
      return
    }

    await this.addPlaceholder(state, this.width)

    this.set({
      enabled: true,
      visible: isVisible,
    })

    if (state === "loading") {
      this.startLoadingAnimation()
    }
  },

  disable: function () {
    this.clearAnimation()

    if (!this.enabled) {
      return
    }

    this.removePlaceholder()
    this.set({
      enabled: false,
      visible: false,
    })
  },

  startLoadingAnimation: function () {
    const placeholder = this.item(0)
    const spinner = placeholder?.item(2)

    this.setAnimation(() => {
      if (!spinner || isCanvasDetached(spinner.canvas)) {
        return this.clearAnimation()
      }

      spinner.angle = spinner.angle! + 1
      this.canvas?.requestRenderAll()
    })
  },

  setLoading: async function () {
    this.clearAnimation()

    if (!this.enabled) {
      return
    }

    this.removePlaceholder()
    await this.addPlaceholder("loading", this.width)

    this.startLoadingAnimation()

    this.canvas?.requestRenderAll()
  },

  addPlaceholder: async function (state: LogoPlaceholderState, width: number) {
    const creationPromise = new Promise(async (resolve) => {
      const newPlaceholder = await getPlaceholderCreateFunction(state)(width)
      this.add(newPlaceholder)
      resolve(newPlaceholder)
    })

    this.placeholderReady = creationPromise
    return creationPromise
  },

  setNonInteractive: async function () {
    this.clearAnimation()

    if (!this.enabled) {
      return
    }

    this.removePlaceholder()
    const newPlaceholder = await createUserNonInteractivePlaceholder(this.width)
    this.add(newPlaceholder)

    this.canvas?.requestRenderAll()
  },

  setInteractive: async function () {
    this.clearAnimation()

    if (!this.enabled) {
      return
    }

    this.removePlaceholder()
    const newPlaceholder = await createUserInteractivePlaceholder(this.width)

    this.add(newPlaceholder)

    this.canvas?.requestRenderAll()
  },

  setAnimation: function (
    animation: () => void,
    timeInterval: number = ANIMATION_TIME_INTERVAL
  ) {
    this.animationInterval = setInterval(() => {
      if (isCanvasDetached(this.canvas)) {
        return this.clearAnimation()
      }

      animation()
    }, timeInterval)
  },

  clearAnimation: function () {
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }
  },

  attachEventListeners: function (callbacks: {
    onPlusClicked: () => void
    onTrashClicked: () => void
  }) {
    if (this.isEventListenersAttached) {
      return
    }

    this.placeholderReady?.then(() => {
      const placeholder = this.item(0)
      const placeholderObjects = placeholder?.getObjects()

      placeholderObjects?.forEach((object) => {
        if (object.id === "logo-placeholder-trash-button") {
          object.on("mousedown", (e) => {
            e.e.preventDefault()
            callbacks.onTrashClicked()
          })
        }

        if (object.id === "logo-placeholder-plus-button") {
          object.on("mousedown", (e) => {
            e.e.preventDefault()
            callbacks.onPlusClicked()
          })
        }
      })
    })

    this.isEventListenersAttached = true
  },

  _render: function (ctx) {
    this.callSuper("_render", ctx)
  },
})

// @ts-ignore
fabric.LogoPlaceholderSlot.fromObject = function (object, callback) {
  fabric.Object._fromObject("LogoPlaceholderSlot", object, callback, "objects")
}
