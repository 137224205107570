import { Session } from "../types/session.types"
import { getApiTokenFromCookie } from "shared-libs/src/js/libs/helpers/api-token.helpers"
import { EcommerceApiEnvConfig } from "../app-config/types"
import { UserDto } from "../libs/api/ecommerce-api/types"
import { EcommerceApiFactory } from "../libs/api/ecommerce-api/ecommerce-api.factory"
import { AnalyticsInitializer } from "shared-libs/src/js/libs/analytics/analytics-initializer"

export class SessionService {
  constructor(private readonly ecommerceConfig: EcommerceApiEnvConfig) {}

  public async getSession(): Promise<Session> {
    if (this.isUserDataFetchable) {
      return this.getSessionFromApi()
    }

    return {
      token: getApiTokenFromCookie(),
      user: {},
    }
  }

  private async getSessionFromApi(): Promise<Session> {
    const user = await this.getUserFromApi()
    const token = user.spree_api_key

    new AnalyticsInitializer(token).init(user)

    return {
      token,
      user,
    }
  }

  private async getUserFromApi(): Promise<UserDto> {
    const ecommerceApi = new EcommerceApiFactory(
      this.ecommerceConfig,
      getApiTokenFromCookie()
    ).call()

    return ecommerceApi.user.me()
  }

  private get isUserDataFetchable(): boolean {
    return this.ecommerceConfig.features.user.data
  }
}
