import SegmentAnalytics from "./segment-analytics"
import { SegmentAnalyticsApi } from "./segment-analytics.api"
import { Ga4Service } from "../google/ga4-service"
import { AttributionService } from "../attribution-service"

interface IdentifyUserParams {
  userId: number
  traits?: object
  options?: IdentifyOptions
  callback?: () => any
}

interface IdentifyOptions {
  integrations?: object
}

export function identifyUser(params: IdentifyUserParams) {
  const { userId, traits, callback } = params
  let options = params.options || {}
  SegmentAnalytics.runWhenReady(() => {
    disableIterableIntegration(options)
    SegmentAnalyticsApi().identify(userId, traits, options, callback)
  })
}

function disableIterableIntegration(options: IdentifyOptions) {
  if (typeof options.integrations === "undefined") {
    options.integrations = {}
  }
  options.integrations["Iterable"] = false
}

interface TrackPageParams {
  category?: string
  name?: string
  properties?: object
  options?: object
  callback?: () => any
}

export function trackPage(params: TrackPageParams) {
  const { category, name, properties, options, callback } = params
  SegmentAnalytics.runWhenReady(() => {
    SegmentAnalyticsApi().page(category, name, properties, options, callback)
  })
}

interface TrackEventParams {
  event: string
  properties?: object
  options?: object
  callback?: () => any
}

export async function trackEvent(params: TrackEventParams) {
  const { event, options, callback } = params
  const properties = params.properties || {}

  const attributionService = new AttributionService()

  // GA4 section - start
  const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

  if (gtag) {
    await Ga4Service.decorateObjectWithGtagData(properties)
  }
  // GA4 section - end

  Object.assign(properties, {
    attribution_gclid: attributionService.getGclid(),
    attribution_country: attributionService.getCountry(),
    event_origin: "client",
  })

  SegmentAnalytics.runWhenReady(() => {
    SegmentAnalyticsApi().track(event, properties, options, callback)
  })
}
