import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Dropzone } from "../../atoms/Dropzone/Dropzone"
import styles from "./GlobalDropzone.module.scss"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"

const i18n = I18N.component.assetsUploader

export const GlobalDropzone = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { globalDropzoneController } = ui
  const { isDropzoneVisible } = globalDropzoneController

  const onDrop = (files: File[]) => {
    globalDropzoneController.upload(files)
  }

  const onDragLeave = () => {
    globalDropzoneController.setIsDropzoneVisible(false)
  }

  if (!isDropzoneVisible) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Dropzone
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        multiple={true}
        label={t(i18n.uploadGlobal)}
      />
    </div>
  )
})
