import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { ToolbarCheckbox } from "../../../../molecules/ToolbarCheckbox/ToolbarCheckbox"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import { Popover } from "../../../../molecules/Popover/Popover"
import { ToolbarSection } from "../../Toolbar/ToolbarSection"
import { SvgIcon } from "../../../../atoms/SvgIcon/SvgIcon"
import infoIconUrl from "../../../../../assets/_icons/info.svg"

const i18n = I18N.toolbar.spaceClipping

export const SpaceClippingSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.assets,
  ])

  if (!containerSet) {
    return null
  }

  const { activeObjectDriver } =
    containerSet.designAndProductDriver.productDriver

  const { isSpaceClippingTogglingAvailable } = activeObjectDriver
  const { isSpaceClippingDisabled } = activeObjectDriver.activeObjectComputable

  if (!isSpaceClippingTogglingAvailable) {
    return null
  }

  const onToggle = () => {
    activeObjectDriver.toggleSpaceClipping()
  }

  return (
    <ToolbarSection spacing="small">
      <ToolbarCheckbox
        checked={!isSpaceClippingDisabled}
        onChange={onToggle}
        label={t(i18n.toggler)}
        analyticsName="space-clipping"
        analyticsId={isSpaceClippingDisabled ? "disable" : "enable"}
      />

      <Popover text={t(i18n.info)} zIndex={6000}>
        <SvgIcon url={infoIconUrl} size="s" />
      </Popover>
    </ToolbarSection>
  )
})
