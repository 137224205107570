/** @format */
// product sku def
import SkuFinish from "./paperMailerSkuDefinitions/SkuFinish";
import SkuMaterial from "./paperMailerSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./paperMailerSkuDefinitions/SkuPrintMode";
import SkuModel from "./paperMailerSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./paperMailerPartsDefinitions/PartFinish";
import PartMaterial from "./paperMailerPartsDefinitions/PartMaterial";
import PartPrintMode from "./paperMailerPartsDefinitions/PartPrintMode";
import PartModel from "./paperMailerPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var PAPER_MAILER_CONSTS = {
    SKU_BASE: "paper-mailer-",
    PRODUCT_TYPE: "paperMailer",
    DEFAULT_LINE_ITEM_RANGES: [
        100, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500,
    ],
};
export default Object.assign({}, PAPER_MAILER_CONSTS, SkuFinish, SkuMaterial, SkuPrintMode, SkuModel, PartFinish, PartMaterial, PartPrintMode, PartModel, Product, ProductVariant);
