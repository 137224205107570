import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { ToolbarCheckbox } from "../../../../molecules/ToolbarCheckbox/ToolbarCheckbox"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import Colour from "../../../../../../models/colour"
import { ShapeConfigurator } from "../../../configurators/ShapeConfigurator/ShapeConfigurator"
import { Shape } from "../../../../../../models/shape"
import { ToolbarSection } from "../../Toolbar/ToolbarSection"

const i18n = I18N.toolbar.mask

export const MaskSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { maskToolbarController } = ui
  const {
    isToolbarAvailable,
    isMaskApplied,
    isColorPickerAvailable,
    selectedColor,
    availableColors,
    isShapePickerAvailable,
    selectedShape,
    availableShapes,
  } = maskToolbarController

  if (!isToolbarAvailable) {
    return null
  }

  const onToggle = () => {
    maskToolbarController.toggleMask()
  }

  const onColorChange = (color: Colour) => {
    maskToolbarController.changeMaskColor(color)
  }

  const onShapeChange = (shape: Shape) => {
    maskToolbarController.changeMaskShape(shape)
  }

  return (
    <ToolbarSection>
      <ToolbarCheckbox
        checked={isMaskApplied}
        onChange={onToggle}
        label={t(i18n.toggle)}
        analyticsName="mask"
        analyticsId={isMaskApplied ? "enable" : "disable"}
      />

      {isColorPickerAvailable && (
        <ColorConfigurator
          onColorSelect={onColorChange}
          selectedColor={selectedColor}
          colorsList={availableColors}
          analyticsSection="design-toolbar"
          analyticsName="mask-color"
        />
      )}

      {isShapePickerAvailable && (
        <ShapeConfigurator
          selectedShape={selectedShape}
          shapes={availableShapes}
          onShapeSelect={onShapeChange}
          analyticsSection="design-toolbar"
          analyticsName="mask-shape"
        />
      )}
    </ToolbarSection>
  )
})
