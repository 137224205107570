import { EcommerceApi } from "./types"
import { RestEcommerceApi } from "./rest-api/rest.ecommerce-api"
import { EcommerceApiEnvConfig } from "../../../app-config/types"

export class EcommerceApiFactory {
  constructor(
    private readonly config: EcommerceApiEnvConfig,
    private readonly sessionToken: string
  ) {}

  public call(): EcommerceApi {
    if (["api-customers", "injected"].includes(this.config.mode)) {
      return this.initInjectedApi()
    }

    return this.initRestApi()
  }

  private initInjectedApi(): EcommerceApi {
    if (!this.config.service) {
      throw new Error("API service is not injected")
    }

    return this.config.service
  }

  private initRestApi(): RestEcommerceApi {
    if (!this.config.baseUrl) {
      throw new Error("Ecommerce base url is not configured")
    }

    return new RestEcommerceApi({
      baseUrl: this.config.baseUrl,
      token: this.sessionToken,
    })
  }
}
