import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import styles from "./TextSection.module.scss"
import { FontSelect } from "../../../../molecules/FontSelect/FontSelect"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import Colour from "../../../../../../models/colour"
import { Select } from "dsl/src/atoms/Select/Select"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import {
  TextAlign,
  FontSizeDefinition,
  FontStyle,
  FontWeight,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { Tooltip } from "../../../../atoms/Tooltip/Tooltip"
import { IconListItem } from "../../../../atoms/IconList/IconListItem"
import italicIconUrl from "../../../../../assets/_icons/text-italic.svg"
import boldIconUrl from "../../../../../assets/_icons/text-bold.svg"
import centerIconUrl from "../../../../../assets/_icons/text-center.svg"
import rightIconUrl from "../../../../../assets/_icons/text-right.svg"
import leftIconUrl from "../../../../../assets/_icons/text-left.svg"
import { IconList } from "../../../../atoms/IconList/IconList"
import { ToolbarSection } from "../../Toolbar/ToolbarSection"

const i18n = I18N.components.editorToolText

export const TextSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { textToolbarController } = ui
  const {
    isToolbarAvailable,
    isFontsTabOpen,
    selectedFontFamily,
    isColorPickerAvailable,
    selectedColor,
    availableFontSizes,
    selectedFontSize,
    selectedFontStyle,
    selectedFontWeight,
    isFontStyleAvailable,
    isFontWeightAvailable,
    selectedTextAlign,
  } = textToolbarController

  if (!isToolbarAvailable) {
    return null
  }

  const onFontSelectClick = () => {
    textToolbarController.toggleFontsTab()
  }

  const onColorChange = (color: Colour) => {
    textToolbarController.setColor(color)
  }

  const onFontSizeChange = (definition: FontSizeDefinition) => {
    textToolbarController.setFontSize(definition)
  }

  const onTextOptionsClick = () => {
    textToolbarController.toggleTextOptionsTab()
  }

  const toggleFontStyle = () => {
    textToolbarController.toggleFontStyle()
  }

  const toggleFontWeight = () => {
    textToolbarController.toggleFontWeight()
  }

  const setTextAlign = (textAlign: TextAlign) => {
    textToolbarController.setTextAlign(textAlign)
  }

  return (
    <>
      <ToolbarSection spacing="small">
        <div className={styles["font-family"]}>
          <FontSelect
            onClick={onFontSelectClick}
            selectedFontFamily={selectedFontFamily}
            open={isFontsTabOpen}
            analyticsSection="design-toolbar"
          />
        </div>

        <div className={styles["font-size"]}>
          <Select
            items={availableFontSizes}
            size={SelectSizes.small}
            selectedItem={selectedFontSize}
            handleSelectedItemChange={onFontSizeChange}
            withMenuFluid
            analyticsSection="design-toolbar"
            analyticsType="select"
            analyticsName="font-size"
          />
        </div>
      </ToolbarSection>

      <ToolbarSection>
        <div className={styles["icon-list"]}>
          <IconList>
            <IconListItem
              iconUrl={leftIconUrl}
              label={t(i18n.label.alignLeft)}
              active={selectedTextAlign === TextAlign.alignLeft}
              onClick={() => setTextAlign(TextAlign.alignLeft)}
              analyticsSection="design-toolbar"
              analyticsType="icon"
              analyticsName="align-left"
            />
            <IconListItem
              iconUrl={centerIconUrl}
              label={t(i18n.label.alignCenter)}
              active={selectedTextAlign === TextAlign.alignCenter}
              onClick={() => setTextAlign(TextAlign.alignCenter)}
              analyticsSection="design-toolbar"
              analyticsType="icon"
              analyticsName="align-center"
            />
            <IconListItem
              iconUrl={rightIconUrl}
              label={t(i18n.label.alignRight)}
              active={selectedTextAlign === TextAlign.alignRight}
              onClick={() => setTextAlign(TextAlign.alignRight)}
              analyticsSection="design-toolbar"
              analyticsType="icon"
              analyticsName="align-right"
            />
          </IconList>
        </div>
      </ToolbarSection>

      <ToolbarSection>
        <div className={styles["icon-list"]}>
          <IconList>
            <IconListItem
              iconUrl={italicIconUrl}
              label={t(i18n.label.fontStyle)}
              active={selectedFontStyle === FontStyle.italic}
              disabled={!isFontStyleAvailable}
              onClick={toggleFontStyle}
              analyticsSection="design-toolbar"
              analyticsType="icon"
              analyticsName="font-style"
            />
            <IconListItem
              iconUrl={boldIconUrl}
              label={t(i18n.label.fontWeight)}
              active={selectedFontWeight === FontWeight.bold}
              disabled={!isFontWeightAvailable}
              onClick={toggleFontWeight}
              analyticsSection="design-toolbar"
              analyticsType="icon"
              analyticsName="font-weight"
            />
          </IconList>
        </div>
      </ToolbarSection>

      {isColorPickerAvailable && (
        <ToolbarSection>
          <ColorConfigurator
            onColorSelect={onColorChange}
            selectedColor={selectedColor}
            analyticsSection="design-toolbar"
            analyticsName="text-color"
          />
        </ToolbarSection>
      )}

      <ToolbarSection>
        <Tooltip text={t(i18n.label.moreOptions)}>
          <button className={styles.button} onClick={onTextOptionsClick}>
            {t(i18n.label.effects)}
          </button>
        </Tooltip>
      </ToolbarSection>
    </>
  )
})
