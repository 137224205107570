import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { v4 as uuidv4 } from "uuid"
import {
  EditableObjectTypes,
  ImageAssetMetaT,
} from "../../../../../../types/asset.types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { ClipPathModes } from "../../../services/clip-path.generator"
import { AlignmentHelper } from "../helpers/alignment.helper"
import { SpaceClippingHelper } from "../helpers/space-clipping.helper"
import {
  PackhelpEditableImage,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { ImageAsset } from "../../../../../../models/image-asset"
import { ImageFilterApplier } from "../../filters-module/image-filter.applier"
import FabricAssetLoader from "../../../../../../libs/services/loaders/fabric-asset.loader"
import { ObjectDpiCalculator } from "../../../../../../libs/calculators/object-dpi.calculator"
import { ColorHelper } from "../helpers/color.helper"
import { ObjectCleanerService } from "../../../../../../libs/services/object-cleaner.service"
import { CANVAS_DIM } from "../../../../../types"
import { Creator } from "./creator"

type ImageOptions = {
  assetImageMeta: ImageAssetMetaT
  maxWidth?: number
  maxHeight?: number
}

interface CanvasImageOptions {
  templateId?: number | null
}

export class ImageCreator extends Creator {
  public constructor(
    vdEditor: VirtualDielineEditor,
    private readonly spaceId: SpaceId
  ) {
    super(vdEditor)
  }

  public async create(
    imageAsset: ImageAsset,
    options: CanvasImageOptions = {}
  ): Promise<PackhelpEditableImage> {
    const object = await this.processImageAsset(imageAsset, options)

    object.set({
      id: uuidv4(),
      lockUniScaling: true,
      originSpaceArea: this.spaceId,
      isSpaceClippingDisabled: this.vdEditor.isSpaceClippingTogglingAvailable(),
    })

    if (options.templateId) {
      object.set({ templateId: options.templateId })
    }

    this.scaleObject(object)
    this.alignObject(object)
    this.rotateObject(object, this.spaceId)
    await this.setObjectSpaceClipping(object, this.spaceId)

    return object
  }

  private scaleObject(object: PackhelpObject) {
    const currentSideObjectDims =
      this.vdEditor.dielineNavigator.getSpaceBoundingRectWithRotation(
        this.spaceId
      )

    if (currentSideObjectDims.width < object.getScaledWidth()) {
      object.scaleToWidth(currentSideObjectDims.width - 20) // why 20?
    }

    if (currentSideObjectDims.height < object.getScaledHeight()) {
      object.scaleToHeight(currentSideObjectDims.height - 20) // why 20?
    }

    const maxWidth = object.maxWidth
    if (maxWidth && object.getScaledWidth() > maxWidth) {
      object.scaleToWidth(maxWidth)
    }
  }

  private async processImageAsset(
    imageAsset: ImageAsset,
    options: CanvasImageOptions
  ): Promise<PackhelpEditableImage> {
    const { metaInfo } = imageAsset.original
    const opts = this.getImageOptionsByImageType(imageAsset)

    const url = metaInfo.isSvg
      ? imageAsset.getOriginalImagePath()
      : imageAsset.getScaledImagePath()

    let object = await FabricAssetLoader.loadAsset(url, opts, metaInfo.mimeType)

    if (metaInfo.isSvg) {
      /**
       * A new way of dealing with SVG images is enabled for images
       * uploaded in the designer mode only.
       *
       * After some time, if everything goes well we enable it in the client mode.
       */
      if (
        this.vdEditor.productRenderPilot.getEditorMode() === "designer" ||
        !!options.templateId
      ) {
        ObjectCleanerService.clearSvgObject(object)
        object.assetType = EditableObjectTypes.assetObject
        ColorHelper.applyDefaultColor(this.vdEditor, object)

        return object
      }

      // TODO: Remove this part after enabling SVG objects in the client mode.
      const IMAGE_SIZE = CANVAS_DIM * 2 // scale for better quality, multiplied by 2 for retina

      if (object.width > object.height) {
        object.scaleToWidth(IMAGE_SIZE)
      } else {
        object.scaleToHeight(IMAGE_SIZE)
      }

      object = await this.vdEditor.assetsModule.loadImage(
        object.toDataURL({ format: "webp", quality: 0.8 }),
        {
          assetImageMeta: imageAsset.toSource(),
        }
      )
      // TODO END
    }

    object.assetType = EditableObjectTypes.assetImage
    await this.applyImageFilters(object)

    return object
  }

  private async applyImageFilters(image: PackhelpEditableImage): Promise<void> {
    const imageFilterApplier = new ImageFilterApplier(this.vdEditor, image)

    await imageFilterApplier.apply()
  }

  private getImageOptionsByImageType(imageAsset: ImageAsset): ImageOptions {
    /**
     * For calculating sizes for images, we WERE using (when there were only mailerboxes):
     *  1. product outside dimensions: to calculate image to product ratio
     *    (so the quality is good on the artwork)
     *  2. FRONT space dimensions: to calculate the virtual object to virtual dieline ratio
     *    (so the object fits well on the virtual dieline)
     *
     * These two dimensions (FRONT space artwork width and product outside width)
     * are very close to each other. Space is always a bit smaller than the product width, though.
     *
     * For example (F33):
     *  - outside width: 118mm
     *  - front space width: 112.5mm
     *
     * Product outside dimension for polymailers is MUCH bigger than the space / edit zone.
     * Edit zone dimensions are equal to space dimensions on the artwork.
     *
     * For example (Y100):
     *  - outside dimension: 45x55cm
     *  - edit zone / space: 30x30cm
     *
     * Now we calculate based on the width of the edit zone (in artwork pixels) we're adding image onto.
     *
     * Images added to canvas are a bit bigger,
     * because of lack of the difference between
     *  - width of the FRONT space and
     *  - outside width of the product.
     *
     * Size of the image is more precise now.
     * Checked with DTP: DPI is more than 300, which is good, but dunno why it's higher.
     * I didn't ask how much more, my bad :( @degregar
     */

    const assetMeta = imageAsset.original.metaInfo
    const originalImageConfig = imageAsset.getOriginImageConfig()

    const virtualDielineSpace =
      this.vdEditor.dielineNavigator.getVirtualDielineSpace(this.spaceId)

    const editZoneDimensions =
      this.vdEditor.productRenderPilot.getSpaceDimensions(
        this.vdEditor.editContext,
        this.spaceId
      )

    const editZoneSizeApx = {
      widthApx: editZoneDimensions.widthPx,
      heightApx: editZoneDimensions.heightPx,
    }

    const calculatedMaxDimensions = ObjectDpiCalculator.getImageMaxSizeDpx(
      virtualDielineSpace,
      ObjectDpiCalculator.sizeToPx(originalImageConfig),
      editZoneSizeApx
    )

    const opts: ImageOptions = {
      assetImageMeta: imageAsset.toSource(),
    }

    if (assetMeta.isRaster) {
      opts.maxWidth = calculatedMaxDimensions.widthVdpx
      opts.maxHeight = calculatedMaxDimensions.heightVdpx
    }

    return opts
  }
}
