import React from "react"
import { observer } from "mobx-react-lite"
import { Toolbar } from "../Toolbar/Toolbar"
import { SpaceClippingSection } from "./partials/SpaceClippingSection"
import { MaskSection } from "./partials/MaskSection"
import { TextSection } from "./partials/TextSection"
import { RasterImageSection } from "./partials/RasterImageSection"
import { SvgImageSection } from "./partials/SvgImageSection"
import { ShapeSection } from "./partials/ShapeSection"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const ToolbarAsset = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { activeObjectType } = containerSet.ui.editorToolbarController

  return (
    <Toolbar e2eTargetName={activeObjectType}>
      <TextSection />
      <RasterImageSection />
      <SvgImageSection />
      <ShapeSection />
      <SpaceClippingSection />
      <MaskSection />
    </Toolbar>
  )
})
