import React, { PropsWithChildren } from "react"
import styles from "./FormGroup.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export interface FormRowProps {
  label?: string
  inline?: boolean
  vertical?: boolean
}

export function FormGroup({
  children,
  label,
  inline = false,
  vertical = false,
}: PropsWithChildren<FormRowProps>) {
  return (
    <div className={cx("wrapper", { inline })}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={cx("content", { vertical })}>{children}</div>
    </div>
  )
}
