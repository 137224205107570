var Product;
(function (Product) {
    Product["WOOD_WOOL"] = "woodWool";
    Product["SELF_ADHESIVE_ENVELOPE"] = "selfAdhesiveEnvelope";
    Product["STICKER_CIRCLE"] = "stickerCircle";
    Product["STICKER_RECTANGLE"] = "stickerRectangle";
    Product["THANK_YOU_CARD"] = "thankYouCard";
    Product["PAPER_BUBBLE_WRAP"] = "paperBubbleWrap";
})(Product || (Product = {}));
export default Product;
