import { computed } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import BackgroundsDriver from "../../drivers/backgrounds.driver"
import Colour from "../../models/colour"
import { UIConfig } from "../../libs/products-render-config/types"

export class SpaceToolbarController {
  constructor(private readonly productDriver: ProductDriver) {}

  @computed
  public get isToolbarVisible(): boolean {
    return (
      this.isBackgroundColorPickerVisible ||
      this.isPatternToggleVisible ||
      this.isBackgroundImageToggleVisible
    )
  }

  @computed
  public get isBackgroundColorPickerVisible(): boolean {
    const { productRenderPilot } = this.productDriver.state

    if (!this.features.backgroundColor || !productRenderPilot.is3DProduct()) {
      return false
    }

    return true
  }

  @computed
  public get selectedBackgroundColor(): Colour | undefined {
    return this.backgroundsDriver.editedSpaceColor
  }

  public async setBackgroundColor(color: Colour): Promise<void> {
    await this.backgroundsDriver.paintProductCurrentSpace(color)
  }

  @computed
  public get isPatternToggleVisible(): boolean {
    const { activeContext, productRenderPilot } = this.productDriver.state

    if (
      !this.backgroundsDriver.isGlobalPatternActive(activeContext) ||
      !productRenderPilot.isBackgroundAssetToggleAvailableForSpace(
        activeContext
      )
    ) {
      return false
    }

    return true
  }

  @computed
  public get isPatternVisible(): boolean {
    return this.backgroundsDriver.isPatternVisibleOnEditedSpace
  }

  public async togglePattern(): Promise<void> {
    const { activeSpace } = this.productDriver.state

    if (!activeSpace) {
      return
    }

    await this.backgroundsDriver.togglePatternVisibilityOnSpace(activeSpace)
  }

  @computed
  public get isBackgroundImageToggleVisible(): boolean {
    const { activeContext, productRenderPilot } = this.productDriver.state

    if (
      !this.backgroundsDriver.isGlobalBackgroundImageActive(activeContext) ||
      !productRenderPilot.isBackgroundAssetToggleAvailableForSpace(
        activeContext
      )
    ) {
      return false
    }

    return true
  }

  @computed
  public get isBackgroundImageVisible(): boolean {
    return this.backgroundsDriver.isBackgroundImageVisibleOnEditedSpace
  }

  public async toggleBackgroundImage(): Promise<void> {
    const { activeSpace } = this.productDriver.state

    if (!activeSpace) {
      return
    }

    await this.backgroundsDriver.toggleBackgroundImageVisibilityOnSpace(
      activeSpace
    )
  }

  @computed
  private get backgroundsDriver(): BackgroundsDriver {
    return this.productDriver.backgroundsDriver
  }

  @computed
  private get features(): UIConfig["features"] {
    return this.productDriver.state.productRenderPilot.uiConfig.features
  }
}
