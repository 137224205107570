// object - your object (THREE.Object3D or derived)
// point - the point of rotation (THREE.Vector3)
// axis - the axis of rotation (normalized THREE.Vector3)
// theta - radian value of rotation
export function rotateAboutPoint(
  object: THREE.Object3D,
  point: THREE.Vector3,
  axis: THREE.Vector3,
  theta: number
): void {
  object.position.sub(point) // remove the offset
  object.position.applyAxisAngle(axis, theta) // rotate the POSITION
  object.position.add(point) // re-add the offset

  object.rotateOnAxis(axis, theta) // rotate the OBJECT
}
