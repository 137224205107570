import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./Sidebar.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../hooks/useTranslate"
import cxBinder from "classnames/bind"
import { SidebarTab } from "../../../../libs/products-render-config/types"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"

const cx = cxBinder.bind(styles)

export const Sidebar = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.ui, c.assets])

  if (!containerSet) {
    return null
  }

  const { ui, assets } = containerSet
  const { sidebarStore } = ui
  const { logoPlaceholdersController } = assets

  const onClick = (tab: SidebarTab) => {
    if (tab === "logoPlaceholder") {
      return logoPlaceholdersController.addLogoPlaceholderSlot()
    }

    sidebarStore.toggleTab(tab)
  }

  return (
    <nav className={styles.sidebar}>
      {sidebarStore.availableItems.map((item) => {
        const active =
          sidebarStore.isTabSelected(item.tab) ||
          sidebarStore.isSubTabSelected(item.tab)

        return (
          <button
            key={item.tab}
            className={cx("item", {
              active,
            })}
            onClick={() => onClick(item.tab)}
            analytics-section="left-sidebar"
            analytics-type="tab"
            analytics-name={item.analyticsName}
            e2e-target="sidebar"
            e2e-target-name={item.tab}
          >
            <div className={styles.icon}>
              <SvgIcon url={item.iconUrl} />
              {item.iconActiveUrl && <SvgIcon url={item.iconActiveUrl} />}
            </div>

            {t(item.nameIntl)}
          </button>
        )
      })}
    </nav>
  )
})
