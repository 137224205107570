import React, { ReactNode, RefObject, useEffect, useState } from "react"
import { Button } from "../../../../atoms/Button"
import { CardType } from "../../../../atoms/Card/Card"
import {
  Modal,
  ModalTopSpacingType
} from "../../../../organisms/Modal/modal/Modal"
import { ModalTemplateFlex } from "../../../../organisms/Modal/modal/templates/ModalTemplateFlex"
import { QuantityItem } from "../../types"
import { PickerOptionsList } from "./PickerOptionsList"
import { PickerOptionsSearch } from "./PickerOptionsSearch"
import styles from "./PickerOptionsModal.module.scss"

interface PickerOptionsModalProps {
  value: QuantityItem | undefined
  match?: QuantityItem
  isOpen: boolean
  headerLabel?: string
  searchValue?: string
  searchPlaceholder?: string
  options: QuantityItem[]
  footer?: ReactNode
  confirmLabel?: string
  listRef?: RefObject<HTMLUListElement>
  searchRef?: RefObject<HTMLInputElement>
  scrollRef?: RefObject<HTMLDivElement>
  onSelect: (value: QuantityItem) => void
  onItemAction?: (item: QuantityItem) => void
  onClose: () => void
  onSearchChange?: (value?: number) => void
  onSearchConfirm?: (item?: QuantityItem) => void
}
export const PickerOptionsModal = (props: PickerOptionsModalProps) => {
  const {
    value,
    match,
    isOpen,
    searchValue,
    headerLabel = "",
    confirmLabel = "",
    searchPlaceholder,
    options,
    footer,
    listRef,
    searchRef,
    scrollRef,
    onSelect,
    onItemAction,
    onClose,
    onSearchChange,
    onSearchConfirm
  } = props

  const [isSearchFocused, setSearchFocused] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)

  const handleSearchFocus = () => setSearchFocused(true)
  const handleSearchBlur = () => setSearchFocused(false)

  const handleSelect = (value: QuantityItem, immediate = false) => {
    setSelectedValue(value)

    if (immediate) {
      onSelect(value)
    }
  }

  const handleConfirm = () => {
    if (selectedValue) {
      onSelect(selectedValue)
    }
  }

  useEffect(() => {
    if (match) {
      setSelectedValue(match)
    } else {
      setSelectedValue(value)
    }
  }, [value, match, isOpen])

  return (
    <Modal
      open={isOpen}
      cardProps={{
        fluid: true,
        type: CardType.RoundedTop,
        mobileType: CardType.RoundedTop
      }}
      topSpacing={ModalTopSpacingType.Medium}
      onCloseAction={onClose}
    >
      <ModalTemplateFlex
        header={headerLabel}
        onCloseAction={onClose}
        footer={
          <>
            {footer}
            <div className={styles["confirm-button"]}>
              <Button
                onClick={handleConfirm}
                fluid
                disabled={!selectedValue}
                e2eTarget="quantity-confirm-button"
              >
                {confirmLabel}
              </Button>
            </div>
          </>
        }
      >
        {onSearchChange && (
          <PickerOptionsSearch
            ref={searchRef}
            value={searchValue}
            onChange={onSearchChange}
            onConfirm={onSearchConfirm}
            placeholder={searchPlaceholder}
            onBlur={handleSearchBlur}
            onFocus={handleSearchFocus}
            isMobile
          />
        )}
        <PickerOptionsList
          ref={listRef}
          scrollRef={scrollRef}
          value={selectedValue}
          match={match}
          options={options}
          onSelect={handleSelect}
          onItemAction={onItemAction}
          isMatchingEnabled={isSearchFocused}
          isMobile
        />
      </ModalTemplateFlex>
    </Modal>
  )
}
