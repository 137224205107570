import { fabric } from "fabric"
import { addThresholdFilter } from "./filters/add-threshold-filter"
import { addObjects } from "./objects/add-objects"
import { overrideFabricOptions } from "./overrides/override-fabric-options"
import { addDrawClipPathOnCache } from "./overrides/add-draw-clip-path-on-cache"
import { overrideFabricGroup } from "./overrides/override-fabric-group"

overrideFabricOptions(fabric)
overrideFabricGroup(fabric)
addThresholdFilter(fabric)
addObjects(fabric)
addDrawClipPathOnCache(fabric)

export default fabric
