import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import saveIconUrl from "../../../../assets/_shape-icons/save.svg"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Modal } from "../Modal/Modal"

const i18n = I18N.modals.bndl

export const ModalFinishDesignConfirmation = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDesignUiController, productDesignStore } =
    containerSet.designAndProductDriver
  const { isProductDesignLocking } = productDesignStore.state
  const { isFinishConfirmationModalOpen } = productDesignUiController

  if (!isFinishConfirmationModalOpen) {
    return null
  }

  const onConfirm = () => {
    productDesignUiController.finish()
  }

  const onClose = () => {
    productDesignUiController.setIsFinishConfirmationModalOpen(false)
  }

  return (
    <Modal
      title={t(i18n.title)}
      iconUrl={saveIconUrl}
      onClose={onClose}
      link={{
        label: t(i18n.confirm),
        onClick: onConfirm,
        loading: isProductDesignLocking,
      }}
      center
    >
      {t(i18n.description)}
    </Modal>
  )
})
