import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { ProductQuantity } from "shared-libs/src/js/shared-components/product-quantity/ProductQuantity"
import { Skeleton } from "../../atoms/Skeleton/Skeleton"
import { AnalyticsSection } from "../../types"

export interface QuantityPickerProps {
  analyticsSection?: AnalyticsSection
}

export const QuantityPicker = observer(function ({
  analyticsSection,
}: QuantityPickerProps) {
  const [containerSet] = useContainerSet((c) => [c.ecommerce])

  if (!containerSet?.ecommerce.available) {
    return null
  }

  const { quantityPickerUiController } = containerSet.ecommerce
  const {
    isLoading,
    isDisabled,
    visibleQuantity,
    piecesPerUnit,
    priceBreaks,
    minVisibleQuantity,
    maxVisibleQuantity,
    displayOptions,
  } = quantityPickerUiController

  const setQuantity = (visibleQuantity: number): void => {
    return quantityPickerUiController.setQuantity(visibleQuantity)
  }

  const calculatePriceBreak = (visibleQuantity: number) => {
    return quantityPickerUiController.calculatePriceBreak(visibleQuantity)
  }

  if (isLoading) {
    return <Skeleton height={48} />
  }

  return (
    <ProductQuantity
      minQuantity={minVisibleQuantity}
      maxQuantity={maxVisibleQuantity}
      quantity={visibleQuantity}
      step={piecesPerUnit}
      priceBreaks={priceBreaks}
      setQuantity={setQuantity}
      priceDisplayOptions={displayOptions}
      disabled={isDisabled}
      getPriceBreak={calculatePriceBreak}
      analyticsSection={analyticsSection}
      analyticsType="select"
      analyticsName="quantity"
    />
  )
})
