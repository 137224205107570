import { addLocaleData } from "react-intl"

// TODO: This is a polyfill and we should load it conditionally
import "intl"

/**
 * This functions is async because:
 * - this will unblock the CPU thread
 * - We may want to add some more async loading stuff here very soon
 */
async function processLoadedMessageAndGetJson(module) {
  const { json, momentLocale } = module.default()
  addLocaleData(momentLocale)
  return json
}
/**
 * Mikołaj: To my best knowledge, we should keep those imports as dumb strings without any
 * params or anything, so that webpack can process them withoout issues
 */
export async function loadCzLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-cz")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadDeLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-de")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadEnLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-en")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadEsLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-es")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadFrLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-fr")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadItLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-it")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadPlLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-pl")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadPtLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-pt")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadSvLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-sv")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadRoLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-ro")
  return await processLoadedMessageAndGetJson(module)
}

export async function loadNlLocale() {
  const module = await import("shared-libs/src/js/libs/others/async-i18/add-nl")
  return await processLoadedMessageAndGetJson(module)
}
