import React from "react"
import Loader, { LoaderTypes } from "../../../../../atoms/Loader/Loader"
import Typography, { TType } from "../../../../../atoms/Typography/Typography"
import { CalculateQuantityItem, LabelProps } from "../../../types"
import styles from "./CalculateLabel.module.scss"

export const CalculateLabel = (props: LabelProps) => {
  const { item } = props
  const { isLoading, label, loadingLabel } = item as CalculateQuantityItem

  return isLoading ? (
    <div className={styles["wrapper"]}>
      <Typography type={TType.Body15_350}>{loadingLabel}</Typography>
      <Loader type={LoaderTypes.circular} />
    </div>
  ) : (
    <Typography
      className={styles["label--underline"]}
      type={TType.Body15_350}
      e2e-target-name="selected-item-label"
    >
      {label}
    </Typography>
  )
}
