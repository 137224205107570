import { ObjectDpiCalculator } from "../../../../libs/calculators/object-dpi.calculator"
import React from "react"
import _round from "lodash/round"

function convertDimension(valueCm: number, displayUnit: string) {
  if (displayUnit === "in") {
    return ObjectDpiCalculator.cmToInch(valueCm)
  }

  return valueCm
}

export function Dimensions({
  dimensionsCm,
  displayUnit,
  roundPrecision = 1,
}: {
  dimensionsCm: number[]
  displayUnit: string
  roundPrecision?: number
}) {
  return (
    <>
      {dimensionsCm
        .map((dimension) =>
          _round(convertDimension(dimension, displayUnit), roundPrecision)
        )
        .join(" x ")}{" "}
      {displayUnit}
    </>
  )
}
