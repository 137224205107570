import React from "react"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../i18n"
import Select from "dsl/src/atoms/Select/Select"
import { useTranslate } from "../../../hooks/useTranslate"
import { FontFamilyDefinition } from "../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import { AnalyticsSection } from "../../types"

const i18n = I18N.components.editorToolText

export interface FontSelectProps {
  selectedFontFamily?: string
  onClick?: () => void
  onChange?: (definition: FontFamilyDefinition) => void
  open?: boolean
  analyticsSection?: AnalyticsSection
}

export function FontSelect({
  selectedFontFamily,
  onClick,
  onChange,
  open = false,
  analyticsSection,
}: FontSelectProps) {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet

  const { fontFamiliesList } = ui.fontsConfigStore
  const selectItems = fontFamiliesList.map((fontFamily) => ({
    ...fontFamily,
    name: fontFamily.config.name,
  }))

  return (
    <Select
      items={selectItems}
      placeholder={t(i18n.label.selectFont)}
      selectedItem={selectItems.find((item) => item.id === selectedFontFamily)}
      onClick={onClick}
      handleSelectedItemChange={onChange}
      hideMenu
      size={SelectSizes.small}
      isOpen={open}
      analyticsSection={analyticsSection}
      analyticsType="select"
      analyticsName="font-family"
      e2eTarget="select"
      e2eTargetName="font-family"
    />
  )
}
