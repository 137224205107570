import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Notification as NotificationComponent } from "../../atoms/Notification/Notification"
import styles from "./NotificationsBar.module.scss"
import { Notification } from "../../../../types/ui.types"

export const NotificationsBar = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { notificationStore } = containerSet.ui
  const { notifications } = notificationStore

  if (notifications.length === 0) {
    return null
  }

  const onClose = (notification: Notification) => {
    notificationStore.hideNotification(notification)
  }

  return (
    <div className={styles.wrapper}>
      {notifications.map((notification) => {
        return (
          <NotificationComponent
            key={notification.message}
            type={notification.type}
            onClose={
              notification.closeable ? () => onClose(notification) : undefined
            }
            iconUrl={notification.iconUrl}
          >
            {notification.message}
            {notification.link && (
              <>
                {" "}
                <a href={notification.link.url}>{notification.link.label}</a>
              </>
            )}
          </NotificationComponent>
        )
      })}
    </div>
  )
})
