import React from "react"
import styles from "./ContextNavItem.module.scss"
import { AnalyticsProps, TestsProps } from "../../types"

interface ContextNavItemProps extends AnalyticsProps, TestsProps {
  label: string
  onClick: () => void
}

export function ContextNavItem({
  label,
  onClick,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget = "button",
  e2eTargetName,
}: ContextNavItemProps) {
  return (
    <button
      className={styles.item}
      onClick={onClick}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {label}
    </button>
  )
}
