import React, { ReactNode, useMemo } from "react"
import { EditorRootCont } from "./_editor-app-hooks"
import { getMainEditorAppContainer } from "../_containers/_editor-app.container"

export function EditorSnowAppWrapper({ children }: { children: ReactNode }) {
  const store = useMemo(() => getMainEditorAppContainer(), [])

  return (
    <EditorRootCont.Provider value={store}>{children}</EditorRootCont.Provider>
  )
}
