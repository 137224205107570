import { DesignApi } from "@ph/design-api"
import { PackhelpApi as PqsApi } from "@ph/pqs-api"
import { GetContainerFormat } from "iti"
import { ListingApiService } from "shared-libs/src/js/libs/services/listing-services/api/listing-api-service"
import { DirectusApi } from "../libs/api/directus-api/directus-api"
import { EcommerceApiFactory } from "../libs/api/ecommerce-api/ecommerce-api.factory"
import { PackhelpApi } from "../libs/api/ph-api/packhelp-api"
import { SessionService } from "../services/session.service"
import { eventTree } from "../events/editor.events"
import { SessionStore } from "../stores/session.store"
import type { UtilEnvContainer } from "./util.container"

export async function provideApiSessionContainer(
  utilEnvContainer: UtilEnvContainer
) {
  const { uri, appConfig, ee } = utilEnvContainer

  const sessionService = new SessionService(appConfig.api.ecommerce)
  const sessionStore = await SessionStore.init(
    sessionService,
    ee,
    uri.handleRoute()
  )

  const axiosConfig = {
    baseURL: appConfig.api.base.baseUrl,
    headers: { "X-Packhelp-Region": appConfig.locale.region },
  }

  const apiService = new PackhelpApi({
    axios: axiosConfig,
    token: sessionStore.token,
  })
  const directusApiService = new DirectusApi(
    appConfig.api.assets,
    uri.getDirectusCmsToken()
  )
  const designApi = new DesignApi({
    baseUrl: appConfig.api.design.baseUrl,
    accessToken: sessionStore.token,
    designToken: uri.getDesignToken(),
    region: appConfig.locale.region,
  })
  const ecommerceApi = new EcommerceApiFactory(
    appConfig.api.ecommerce,
    sessionStore.token
  ).call()
  const pqsApi = new PqsApi({
    axios: axiosConfig,
  })

  const apiUrl = new URL("listings", appConfig.api.pim.baseUrl).toString()
  const token = appConfig.api.pim.token
  const listingApiService = new ListingApiService(apiUrl, token)

  ee.on(eventTree.session.receivedToken, (token) => {
    apiService.updateToken(token)
    designApi.setAccessToken(token)
    ecommerceApi.setToken(token)
  })

  return {
    sessionService,
    sessionStore,
    apiService,
    directusApiService,
    listingApiService,
    designApi,
    ecommerceApi,
    pqsApi,
    user: sessionStore.user,
  }
}

export type ApiSessionContainer = GetContainerFormat<
  typeof provideApiSessionContainer
>
