import React, { useState } from "react"

import SelectBase from "./SelectBase"
import { SelectBaseProps, SelectItemData } from "./types"
import { Search } from "../../components/SelectQty/partials/Search"

export interface SelectWithSearchProps extends SelectBaseProps {
  items: SelectItemData[]
  searchPlaceholder: string
  isError?: boolean
  onClose?: () => void
  valueWhenDisabled?: string
  autoWidth?: boolean
}

const SelectWithSearch = (props: SelectWithSearchProps) => {
  const {
    handleSelectedItemChange,
    selectedItem,
    searchPlaceholder,
    isError,
    isOpen,
    onClose,
    disabled,
    autoComplete,
    id,
    valueWhenDisabled,
    autoWidth,
    ...other
  } = props

  const [inputValue, setInputValue] = useState(
    selectedItem ? selectedItem.name : ""
  )

  const [isInputTouched, setIsInputTouched] = useState(false)
  const [sortedItems, setSortedItems] = useState<SelectItemData[]>([])
  const [isDownShiftOpen, setIsDownshiftOpen] = useState(false)

  const toggleIsDownshiftOpen = () => {
    if (disabled !== true) {
      setIsDownshiftOpen(!isDownShiftOpen)
    }
  }

  const handleInputValueChange = (value: string) => {
    const isAutocompleted = value.length - inputValue.length > 1
    if (disabled !== true) {
      if (isAutocompleted) {
        const selectedItem = other.items.find(({ name }) => name === value)
        selectedItem && handleSelectedItemChange(selectedItem)
      }
      !isOpen && onClose && onClose()
      setInputValue(value)
    }
  }

  const handleSortedItems = (items: SelectItemData[]) => {
    const itemsHaveChanged = sortedItems.length != items.length
    itemsHaveChanged && setSortedItems(items)
  }

  return (
    <SelectBase
      onSortItems={handleSortedItems}
      shouldSort={isInputTouched}
      selectedItem={selectedItem}
      isOpen={isDownShiftOpen}
      handleIsOpenChange={toggleIsDownshiftOpen}
      inputValue={inputValue}
      handleInputValueChange={handleInputValueChange}
      handleSelectedItemChange={handleSelectedItemChange}
      searchSelect={isInputTouched}
      autoWidth={autoWidth}
      renderSearch={(
        searchRef,
        disabled,
        getInputProps,
        toggleMenu,
        onClick,
        isOpen
      ) => {
        const inputProps = getInputProps()

        const extendedInputProps = {
          ...inputProps,
          onKeyPress: (event: KeyboardEvent) => {
            if (event.key === "Enter") {
              handleSelectedItemChange(sortedItems[0] || selectedItem)
              toggleMenu && toggleMenu()
            }
          },
          onFocus: () => {
            toggleMenu && toggleMenu()
          },
          onClick: () => {
            setIsInputTouched(true)
            setInputValue("")
          },
          autoComplete,
          id,
          value: disabled ? valueWhenDisabled : inputProps.value
        }

        return (
          <Search
            disabled={disabled}
            searchRef={searchRef}
            isValid={true}
            inputProps={extendedInputProps}
            menuOpen={isOpen}
            selectedItem={selectedItem}
            onOutsideClick={onClick}
            placeholder={searchPlaceholder}
            isError={isError}
          />
        )
      }}
      disabled={disabled}
      {...other}
    />
  )
}

export { SelectWithSearch, SelectWithSearch as default }
