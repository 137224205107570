import {
  PackhelpGroupType,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { degreesToRadians } from "../../../../../../../libs/maths/angle-calculators"
import { MaskPosition, MaskSize } from "../types"

export class MaskParentCalculator {
  constructor(private readonly maskParent: PackhelpObject) {}

  public calcPosition(): MaskPosition {
    const group = this.maskParent.group

    if (group && group.type === PackhelpGroupType.activeSelection) {
      return this.calcPositionForGroup(group)
    }

    return {
      left: this.maskParent.left!,
      top: this.maskParent.top!,
    }
  }

  public calcSize(): MaskSize {
    const group = this.maskParent.group

    if (group && group.type === PackhelpGroupType.activeSelection) {
      return this.calcSizeForGroup(group)
    }

    return {
      width: this.maskParent.getScaledWidth(),
      height: this.maskParent.getScaledHeight(),
    }
  }

  public calcRotation(): number {
    const group = this.maskParent.group

    if (group && group.type === PackhelpGroupType.activeSelection) {
      return this.calcRotationForGroup(group)
    }

    return this.maskParent.angle!
  }

  private calcPositionForGroup(group: fabric.Object): MaskPosition {
    const left =
      group.left! +
      group.getScaledWidth() / 2 +
      this.maskParent.left! * group.scaleX!
    const top =
      group.top! +
      group.getScaledHeight() / 2 +
      this.maskParent.top! * group.scaleY!

    if (group.angle) {
      const objectOriginPoint = new fabric.Point(left, top)
      const center = new fabric.Point(group.left!, group.top!)
      const rads = degreesToRadians(group.angle!)
      const newPosition = fabric.util.rotatePoint(
        objectOriginPoint,
        center,
        rads
      )

      return {
        left: newPosition.x,
        top: newPosition.y,
      }
    }

    return {
      left: left,
      top: top,
    }
  }

  private calcSizeForGroup(group: fabric.Object): MaskSize {
    return {
      width: this.maskParent.getScaledWidth() * group.scaleX!,
      height: this.maskParent.getScaledHeight() * group.scaleY!,
    }
  }

  private calcRotationForGroup(group: fabric.Object): number {
    return this.maskParent.angle! + group.angle!
  }
}
