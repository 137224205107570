import VirtualDielineEditor from "../../../../../virtual-dieline-editor"
import {
  PackhelpEditableObject,
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../../object-extensions/packhelp-objects"
import { MaskParentCalculator } from "../../calculators/mask-parent.calculator"
import {
  isAssetGroup,
  isGroup,
} from "../../../../../../../../types/asset.types"
import { MaskCalculator } from "../../calculators/mask.calculator"
import { AssetClippingMaskCalculator } from "../../calculators/asset-clipping-mask.calculator"
import { ClippingHelper } from "../../../helpers/clipping.helper"

export class AssetsController {
  private readonly clippingMaskCalculator: AssetClippingMaskCalculator
  private maskParentCalculator: MaskParentCalculator
  private maskCalculator?: MaskCalculator

  private tempObjectMask?: PackhelpMaskObject
  private clippingMask?: PackhelpMaskObject

  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private maskParent: PackhelpEditableObject
  ) {
    this.clippingMaskCalculator = new AssetClippingMaskCalculator()
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)
  }

  public async initTempObjectMask(
    tempObjectMask: PackhelpMaskObject
  ): Promise<void> {
    this.tempObjectMask = tempObjectMask
    this.maskCalculator = new MaskCalculator(this.maskParent, tempObjectMask)
  }

  public async initClippingMask(
    clippingMask: PackhelpMaskObject
  ): Promise<void> {
    this.clippingMask = await this.cloneMaskObject(clippingMask)
  }

  public async applyClippingMask(): Promise<void> {
    await Promise.all(
      this.assets.map(this.applyClippingMaskForSingleAsset.bind(this))
    )
  }

  public async applyClippingMaskForSingleAsset(
    object: PackhelpObject
  ): Promise<void> {
    if (isAssetGroup(object)) {
      await Promise.all(
        object.getObjects().map(this.applyClippingMaskForSingleAsset.bind(this))
      )

      return
    }

    if (!this.clippingMask) {
      return
    }

    const clippingMask = this.getClippingMaskFromObject(object)

    if (clippingMask) {
      return
    }

    if (!object.clipPath) {
      object.clipPath = ClippingHelper.buildClipPathGroup()
    }

    if (isGroup(object.clipPath)) {
      const clipPath = await this.cloneMaskObject(this.clippingMask)
      object.clipPath.add(clipPath)

      this.setClippingMaskScaleForSingleAsset(object)
      this.setClippingMaskRotationForSingleAsset(object)
      this.setClippingMaskPositionForSingleAsset(object)
    }
  }

  public setClippingMasksPosition() {
    this.assets.forEach(this.setClippingMaskPositionForSingleAsset.bind(this))
  }

  public setClippingMaskPositionForSingleAsset(object: PackhelpObject) {
    if (isAssetGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.setClippingMaskPositionForSingleAsset(groupObject)
      }

      object.dirty = true

      return
    }

    if (!this.tempObjectMask) {
      return
    }

    const position = this.clippingMaskCalculator.calcPosition(
      this.tempObjectMask,
      this.getCurrentRotation()
    )

    this.getClippingMaskFromObject(object)?.set(position)
  }

  public setClippingMasksRotation() {
    this.assets.forEach(this.setClippingMaskRotationForSingleAsset.bind(this))
  }

  public setClippingMaskRotationForSingleAsset(object: PackhelpObject) {
    if (isAssetGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.setClippingMaskRotationForSingleAsset(groupObject)
      }

      return
    }

    const maskParentRotation = this.maskParentCalculator.calcRotation()
    this.getClippingMaskFromObject(object)?.rotate(maskParentRotation)
  }

  public setClippingMasksScale() {
    this.assets.forEach(this.setClippingMaskScaleForSingleAsset.bind(this))
  }

  public setClippingMaskScaleForSingleAsset(object: PackhelpObject) {
    if (isAssetGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.setClippingMaskScaleForSingleAsset(groupObject)
      }

      return
    }

    if (!this.tempObjectMask || !this.maskCalculator) {
      return
    }

    const maskScaleAndRadius = {
      scaleX: this.tempObjectMask.scaleX!,
      scaleY: this.tempObjectMask.scaleY!,
      ...this.maskCalculator.calcBorderRadius(),
    }

    this.getClippingMaskFromObject(object)?.set(maskScaleAndRadius)
  }

  public setVisibility(isVisible: boolean) {
    const maskVisibility = {
      visible: isVisible,
    }

    this.assets.forEach((object) => {
      if (isAssetGroup(object)) {
        for (const groupObject of object.getObjects()) {
          this.getClippingMaskFromObject(groupObject)?.set(maskVisibility)
        }
      } else {
        this.getClippingMaskFromObject(object)?.set(maskVisibility)
      }
    })
  }

  public changeParent(newParent: PackhelpEditableObject) {
    this.maskParent = newParent
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)

    if (this.tempObjectMask) {
      this.maskCalculator = new MaskCalculator(newParent, this.tempObjectMask)
    }

    const maskParentParams = {
      maskParentId: newParent.id,
    }

    this.assets.forEach((object) => {
      if (isAssetGroup(object)) {
        for (const groupObject of object.getObjects()) {
          this.getClippingMaskFromObject(groupObject)?.set(maskParentParams)
        }
      } else {
        this.getClippingMaskFromObject(object)?.set(maskParentParams)
      }
    })
  }

  public clear() {
    this.assets.forEach((object) => {
      if (isAssetGroup(object)) {
        for (const groupObject of object.getObjects()) {
          this.removeClippingMaskFromSingleAsset(groupObject)
        }
      } else {
        this.removeClippingMaskFromSingleAsset(object)
      }
    })

    this.tempObjectMask = undefined
    this.maskCalculator = undefined
    this.clippingMask = undefined
  }

  private removeClippingMaskFromSingleAsset(object: PackhelpObject) {
    const clippingMask = this.getClippingMaskFromObject(object)

    if (!clippingMask || !object.clipPath || !isGroup(object.clipPath)) {
      return
    }

    object.clipPath.remove(clippingMask)
  }

  private get assets(): PackhelpEditableObject[] {
    return this.vdEditor.assetsModule
      .getEditableObjects()
      .filter(
        (object) =>
          object.originSpaceArea === this.maskParent.originSpaceArea &&
          object.id !== this.maskParent.id &&
          object.maskParentId !== this.maskParent.id
      )
  }

  private getClippingMaskFromObject(
    object: PackhelpObject
  ): PackhelpObject | undefined {
    if (!object.clipPath || !isGroup(object.clipPath)) {
      return
    }

    return object.clipPath
      .getObjects()
      .find((o) => o.maskParentId === this.maskParent.id)
  }

  private async cloneMaskObject(
    object: PackhelpMaskObject
  ): Promise<PackhelpMaskObject> {
    return new Promise((resolve) => {
      object.clone(
        async (clonedObject) => {
          clonedObject.clipPath = undefined
          clonedObject.maskParentId = this.maskParent.id
          clonedObject.strokeWidth = 0

          resolve(clonedObject)
        },
        ["assetObjectMeta", "keepRatio", "borderRadius"]
      )
    })
  }

  private getCurrentRotation(): number {
    return this.vdEditor.dielineNavigator.getCurrentRotation()
  }
}
