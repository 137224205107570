import React from "react"
import { PrintingOptionBox } from "../../PrintingOptionBox/PrintingOptionBox"
import styles from "./SceneOverlay.module.scss"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export function SceneOverlay() {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { cameraControlsUiController } = containerSet.ui

  const onClose = () => {
    cameraControlsUiController.changeToDefaultEditContext()
  }

  return (
    <div className={styles.wrapper}>
      <PrintingOptionBox
        analyticsSection="main-canvas-area"
        onClose={onClose}
      />
    </div>
  )
}
