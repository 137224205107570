import { FeatureFlags } from "@ph/feature-flags"
import { GetContainerFormat } from "iti"
import { AppConfig } from "../app-config/app.config"
import {
  AllowedEditorFeatureFlagNames,
  dynamicFeatureFlags,
  featureFlagDefaults,
} from "../services/feature-flags/feature-flag-client"
import {
  Debug,
  DebugLogger,
  ErrorLogger,
  InfoLogger,
  Logger,
} from "../services/logger"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import { ee } from "../events/editor.events"
import { CommonFeatureFlagsService } from "../services/feature-flags/common-feature-flags.service"

export async function provideUtilEnvContainer() {
  const appConfig = new AppConfig(globalThis.ph)
  globalThis.appConfig = appConfig

  const uri = new UrlManipulatorProvider(appConfig)

  const featureFlagStore = new FeatureFlags<AllowedEditorFeatureFlagNames>({
    featureFlagDefaults: featureFlagDefaults,
    dynamicFeatureFlags: dynamicFeatureFlags,
    configCatKey: appConfig.integrations.configCat?.key,
    preloadFlags: true,
  })

  const commonFeatureFlagsService = await CommonFeatureFlagsService.init()

  return {
    appConfig,
    logger: { DebugLogger, InfoLogger, ErrorLogger, Debug, Logger },
    ee,
    uri,
    featureFlagStore,
    commonFeatureFlagsService,
  }
}

export type UtilEnvContainer = GetContainerFormat<
  typeof provideUtilEnvContainer
>
