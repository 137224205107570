import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import { CloseButton } from "./CloseButton"
import { ModalHeaderProps } from "../utils/modal.types"

import styles from "../Modal.module.scss"

const cx = cxBinder.bind(styles)

export const ModalHeader = ({
  children,
  onCloseAction,
  paddingBottom = 24,
  withBorder = true
}: PropsWithChildren<ModalHeaderProps>) => (
  <div
    className={cx("modal-header", {
      "modal-header--empty": !children,
      "modal-header--no-border": !withBorder
    })}
    style={{ paddingBottom: `${paddingBottom}px` }}
  >
    {children && (
      <div className={styles["modal-header__content"]}>{children}</div>
    )}
    {onCloseAction && <CloseButton onCloseAction={onCloseAction} />}
  </div>
)
