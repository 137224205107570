import VirtualDielineEditor from "../../../../../virtual-dieline-editor"
import {
  VirtualDielineObject,
  VirtualDielineTemplateDataLessObject,
} from "../../../../../../../../types/asset.types"
import {
  PackhelpEditableObject,
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../../object-extensions/packhelp-objects"
import { MaskBuilder } from "../../../mask-controller/mask.builder"
import { RotationHelper } from "../../../helpers/rotation.helper"
import fabric from "../../../../../../../../libs/vendors/Fabric"
import { MaskType } from "../../../mask-controller/types"

type Offset = {
  x: number
  y: number
}

export class ClippingMaskResizer {
  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly vdTemplateData: VirtualDielineTemplateDataLessObject
  ) {}

  public async resize(clippingMask: PackhelpMaskObject): Promise<void> {
    const maskParentTemplateData = this.vdTemplateData.objects.find(
      (templateObject) => templateObject.id === clippingMask.maskParentId
    )
    const maskParent = this.vdEditor.getCanvasObjectById(
      clippingMask.maskParentId
    ) as PackhelpEditableObject

    if (!maskParent || !maskParentTemplateData) {
      clippingMask.group?.remove(clippingMask)

      return
    }

    await this.setClipPath(clippingMask, maskParent)
    this.scale(clippingMask, maskParent, maskParentTemplateData)
    this.move(clippingMask, maskParent)
    this.updateMaskConfig(clippingMask, maskParent)
  }

  private async setClipPath(
    clippingMask: PackhelpMaskObject,
    maskParent: PackhelpObject
  ): Promise<void> {
    const maskBuilder = new MaskBuilder(
      maskParent as PackhelpEditableObject,
      this.vdEditor
    )

    clippingMask.set({
      clipPath: await maskBuilder.buildGlobalClippingMaskClipPath(),
    })
  }

  private scale(
    clippingMask: PackhelpMaskObject,
    maskParent: PackhelpObject,
    maskParentTemplateData: VirtualDielineObject
  ) {
    const widthRatio =
      maskParent.getScaledWidth() /
      (maskParentTemplateData.width! * maskParentTemplateData.scaleX!)
    const heightRatio =
      maskParent.getScaledHeight() /
      (maskParentTemplateData.height! * maskParentTemplateData.scaleY!)

    clippingMask.set({
      scaleX: clippingMask.scaleX! * widthRatio,
      scaleY: clippingMask.scaleY! * heightRatio,
    })
  }

  private move(clippingMask: PackhelpMaskObject, maskParent: PackhelpObject) {
    const maskParentCenterPoint = maskParent.getCenterPoint()

    const newMaskPosition = RotationHelper.rotatePoint(
      new fabric.Point(
        maskParentCenterPoint.x - clippingMask.getScaledWidth() / 2,
        maskParentCenterPoint.y - clippingMask.getScaledHeight() / 2
      ),
      maskParentCenterPoint,
      maskParent.angle || 0
    )

    const groupOffset = this.getGroupOffset(clippingMask)

    clippingMask.set({
      left: newMaskPosition.x - groupOffset.x,
      top: newMaskPosition.y - groupOffset.y,
    })
  }

  private updateMaskConfig(
    clippingMask: PackhelpMaskObject,
    maskParent: PackhelpEditableObject
  ) {
    if (maskParent.maskConfig?.type !== MaskType.clipping) {
      return
    }

    maskParent.maskConfig.size = {
      width: clippingMask.getScaledWidth(),
      height: clippingMask.getScaledHeight(),
    }
  }

  private getGroupOffset(clippingMask: PackhelpMaskObject): Offset {
    if (!clippingMask.group) {
      return {
        x: 0,
        y: 0,
      }
    }

    return {
      x: clippingMask.group.getScaledWidth() / 2,
      y: clippingMask.group.getScaledHeight() / 2,
    }
  }
}
