export default {
    // Base sku for Extension products family
    SKU_BASE_SERVICE: "service-",
    SKU_BASE_ASSET: "asset-",
    SKU_BASE_OTHER: "other-",
    // Extension products variants
    EXTENSION_PRODUCT_SERVICE_SHIPMENT: "service-shipment",
    EXTENSION_PRODUCT_SERVICE_CO_PACKING: "service-co-packing",
    EXTENSION_PRODUCT_SERVICE_FULFILMENT: "service-fulfilment",
    EXTENSION_PRODUCT_SERVICE_PROOF: "service-proof",
    EXTENSION_PRODUCT_SERVICE_SUBSCRIPTION: "service-subscription",
    EXTENSION_PRODUCT_SERVICE_TAXES: "service-taxes",
    EXTENSION_PRODUCT_SERVICE_DESIGN: "service-design",
    EXTENSION_PRODUCT_ASSET_DIELINE: "asset-dieline",
    EXTENSION_PRODUCT_ASSET_PRINTING_MATRIX: "asset-printing-matrix",
    EXTENSION_PRODUCT_OTHER: "other",
    EXTENSION_PRODUCT_DIGITAL_DESIGN: "digital-design",
    // Extension products types
    EXTENSION_PRODUCT_TYPE_SERVICE: "service",
    EXTENSION_PRODUCT_TYPE_ASSET: "asset",
    EXTENSION_PRODUCT_TYPE_OTHER: "other",
    // SKU Materials
    SKU_MATERIAL_CUSTOM: "custom",
    // SKU Finish
    SKU_FINISH_CUSTOM: "custom",
    // SKU Color modes
    SKU_PRINT_COLOR_MODE_CUSTOM: "custom",
};
