import VirtualDielineEditor from "../virtual-dieline-editor"
import { DielineNavigator } from "../modules/dieline-navigator/dieline-navigator"
import { BackgroundsModule } from "../modules/backgrounds.module"
import AssetsModule from "../modules/assets.module"
import EventEmitter from "eventemitter3"
import { TypedEmitter } from "../../../../events/typed.event-emitter"
import { SpaceHighlightModule } from "../modules/space-highlight-module"
import { SafeZonesModule } from "../modules/safe-zones-module"
import { GlueStripModule } from "../modules/glue-strip-module"
import { NoPrintModule } from "../modules/no-print-module/no-print.module"
import { PackhelpCanvas } from "../object-extensions/packhelp-objects"
import fabric from "../../../../libs/vendors/Fabric"

export type VdEditorExportServiceConfig = {
  enableRetinaScaling: boolean
}

export class VdEditorExportService {
  constructor(
    private mainVdEditor: VirtualDielineEditor,
    private config: VdEditorExportServiceConfig = { enableRetinaScaling: true }
  ) {}

  public createServiceVdEditor(): VirtualDielineEditor {
    const dimensions = this.mainVdEditor.getCanvasDimensions()
    const vdEditor = new VirtualDielineEditor(
      dimensions,
      this.mainVdEditor.editContext,
      // We have to inject new instances of emitters to prevent our main emitters from catching events
      // emitted by this temporary Virtual Dieline
      new EventEmitter(),
      new TypedEmitter()
    )

    const element = document.createElement("canvas")
    element.setAttribute("width", dimensions.width + "")
    element.setAttribute("height", dimensions.height + "")

    vdEditor.fabricCanvas = new fabric.StaticCanvas(element, {
      preserveObjectStacking: true,
      ...this.config,
    }) as PackhelpCanvas
    vdEditor.virtualDieline = this.mainVdEditor.virtualDieline
    vdEditor.assetsModule = new AssetsModule(vdEditor)
    vdEditor.backgroundsModule = new BackgroundsModule(vdEditor)
    vdEditor.dielineNavigator = new DielineNavigator(
      vdEditor,
      vdEditor.backgroundsModule,
      vdEditor.assetsModule,
      this.mainVdEditor.productRenderPilot,
      vdEditor.editContext
    )
    vdEditor.productRenderPilot = this.mainVdEditor.productRenderPilot
    vdEditor.spaceHighlightModule = new SpaceHighlightModule(vdEditor)
    vdEditor.safeZonesModule = new SafeZonesModule(vdEditor)
    vdEditor.glueStripModule = new GlueStripModule(vdEditor)
    vdEditor.noPrintModule = new NoPrintModule(vdEditor)
    vdEditor.texture = this.mainVdEditor.texture

    return vdEditor
  }
}
