export type Axis = "x" | "y"

export interface Point {
  x: number
  y: number
}

export interface LinePoints {
  start: Point
  end: Point
}

export type EdgeType =
  | "top"
  | "hcenter"
  | "bottom"
  | "left"
  | "vcenter"
  | "right"

export interface ObjectEdge {
  type: EdgeType
  axis: Axis
  offset: number
  points: LinePoints
}

export interface BoundingRect {
  width: number
  height: number
  top: number
  left: number
}

export interface SmartGuidelineEvent {
  name: string
  fn: (...args) => void
}

export const positionAttributeMap: Record<Axis, string> = {
  x: "left",
  y: "top",
}

export interface SnappingCache {
  axis: Axis
  top: number
  left: number
  scale: number
}
