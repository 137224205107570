import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Button, ButtonModes } from "dsl/src/atoms/Button"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"

export const HeaderQrButton = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.ecommerce])

  if (!containerSet) {
    return null
  }

  const { ecommerce } = containerSet

  if (!ecommerce.available) {
    return null
  }

  const { productPricingStore, ecommerceStore } = ecommerce
  const { totalPrice } = productPricingStore
  const { isQrLocked, isCartFlowAvailable } = ecommerceStore
  const hasPrice = totalPrice.value > 0

  const onClick = () => {
    ecommerce.ecommerceController.redirectToQuoteRequestForm()
  }

  return (
    <Button
      disabled={isQrLocked}
      mode={isCartFlowAvailable ? ButtonModes.secondary : ButtonModes.primary}
      onClick={onClick}
      e2eTargetName="get-a-quote"
      analyticsSection="top-right-corner"
      analyticsType="button"
      analyticsName={hasPrice ? "contact-sales" : "get-a-quote"}
    >
      {hasPrice ? (
        <>{t(I18N.generic.contactSales)}</>
      ) : (
        <>{t(I18N.generic.getQuote)}</>
      )}
    </Button>
  )
})
