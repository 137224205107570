import { observable, action, makeObservable } from "mobx"
import { SessionService } from "../services/session.service"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { setApiTokenCookie } from "shared-libs/src/js/libs/helpers/api-token.helpers"
import { User } from "../types/session.types"

export class SessionStore {
  @observable public isSessionTokenSet: boolean = false
  @observable public isLoadingUser: boolean = true
  @observable public user: User = {}

  public token!: string

  public static async init(
    sessionService: SessionService,
    ee: AllEditorEventsEmitter,
    forcedToken?: string
  ) {
    const store = new SessionStore(sessionService, ee, forcedToken)

    await store.loadUser()

    return store
  }

  constructor(
    private readonly sessionService: SessionService,
    private readonly ee: AllEditorEventsEmitter,
    forcedToken?: string
  ) {
    makeObservable(this)

    if (forcedToken) {
      this.setToken(forcedToken)
    }
  }

  public get isLoggedIn(): boolean {
    return !!this.user.email
  }

  public setToken(token: string) {
    this.token = token
    setApiTokenCookie(token)

    this.ee.emit(eventTree.session.receivedToken, token)
  }

  @action
  public setUser(user: User) {
    this.user = user

    this.ee.emit(eventTree.session.receivedUser, user)
  }

  @action
  private async loadUser(): Promise<void> {
    this.setLoadingUser(true)

    const { token, user } = await this.sessionService.getSession()

    this.setUser(user)
    this.setToken(token)

    this.setLoadingUser(false)
  }

  @action
  private setLoadingUser(isLoading: boolean) {
    this.isLoadingUser = isLoading
  }
}
