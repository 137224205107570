import { CANVAS_DIM } from "../../../../../types"
import fabric from "../../../../../../libs/vendors/Fabric"
import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import { RotationHelper } from "./rotation.helper"

export class ClippingHelper {
  public static buildClipPathGroup(): fabric.Group {
    return new fabric.Group([], {
      left: 0,
      top: 0,
      width: CANVAS_DIM,
      height: CANVAS_DIM,
      inverted: true,
      strokeWidth: 0,
      absolutePositioned: true,
    })
  }

  public static resizeClipPathBy(
    clipPathObject: PackhelpObject,
    numberOfPixels: number
  ) {
    const positionOffset = -numberOfPixels / 2
    const { width, height, top, left } = clipPathObject
    const rotation = clipPathObject.rotation || 0
    const scaleX = (width! + numberOfPixels) / width!
    const scaleY = (height! + numberOfPixels) / height!
    const positionMultiplier =
      RotationHelper.getPositionMultiplierForSpaceRotation(rotation)

    clipPathObject.set({
      scaleX,
      scaleY,
      top: top! + positionMultiplier.top * positionOffset,
      left: left! + positionMultiplier.left * positionOffset,
    })
  }
}
