import _flatten from "lodash/flatten"
import _uniq from "lodash/uniq"
import { EditableObjectTypes } from "../../../types/asset.types"
import { EditorDesignData } from "../../../types/design.types"

export class FontsFinder {
  constructor(private readonly designData: EditorDesignData) {}

  findFonts(): string[] {
    const { virtualDielines } = this.designData
    return _uniq(
      _flatten(
        Object.keys(virtualDielines).map((editContext) => {
          const virtualDieline = virtualDielines[editContext]
          return virtualDieline.objects
            .filter((object) => {
              return Object.values(EditableObjectTypes).includes(
                object.assetType
              )
            })
            .map((object) => {
              return object.fontFamily
            })
        })
      )
    )
  }
}
