import LocalDebug from "debug"

type LogFn = (...args) => void

enum LogLevel {
  // Fatal = "fatal",
  Error = "error",
  // Warning = "warning",
  // Log = "log",
  Info = "info",
  Debug = "debug",
  // Critical = "critical",
}

export enum SentrySeverity {
  Fatal = "fatal",
  Error = "error",
  Warning = "warning",
  Log = "log",
  Info = "info",
  Debug = "debug",
  Critical = "critical",
}

type SentryBreadcrumbType = "default" | "debug" | "error" | "navigation"
export interface SentryBreadcrumbHint {
  [key: string]: any
}
export interface SentryBreadcrumb {
  type?: SentryBreadcrumbType
  level?: SentrySeverity
  event_id?: string
  category?: string
  message?: string
  data?: SentryBreadcrumbHint
  timestamp?: number
}

const MAX_BREADCRUMB_HINT_BYTES = 5000

function getSentryLevel(level: LogLevel): SentrySeverity | undefined {
  if (!window.Sentry) {
    return undefined
  }
  const sentryLevels: Record<LogLevel, SentrySeverity> = {
    [LogLevel.Error]: SentrySeverity.Error,
    [LogLevel.Info]: SentrySeverity.Info,
    [LogLevel.Debug]: SentrySeverity.Debug,
  }
  return sentryLevels[level]
}

function sanitize(arg: any): SentryBreadcrumbHint | undefined {
  if (!arg) return undefined
  let data: SentryBreadcrumbHint
  if (Array.isArray(arg)) {
    if (!arg.length) return undefined
    // if it's array need to map it to an object
    // also take only first element to avoid sentry api 413 error (exceeding breadcrumb size limit)
    data = arg[0]
  } else {
    // if it's another object or a primitive
    data = arg
  }

  if (data) {
    try {
      const bytes = Buffer.from(JSON.stringify(data)).length
      if (bytes > MAX_BREADCRUMB_HINT_BYTES) {
        // to avoid exceeding breadcrumbs size limit (413 sentry error)
        // return just the id of the logged object or not add it at all
        if (!data.id) return undefined
        data = {
          id: data.id,
        }
      }
    } catch (_e) {
      return undefined
    }
  }

  // note: as of now Sentry sanitizes data itself with regex
  // so it's not necessary as of now to sanitize data from user_name, token etc.
  return data
}

function logSentry(
  message: string,
  level: LogLevel,
  data?: SentryBreadcrumbHint,
  category: string = "log",
  type: SentryBreadcrumbType = "default"
) {
  const breadcrumb: SentryBreadcrumb = {
    type,
    category: category,
    message: message,
    data: data,
    level: getSentryLevel(level),
  }
  if (window.Sentry) {
    window.Sentry.addBreadcrumb(breadcrumb)
  }
}

function getSentryParams(
  domain: string,
  ...args
): { message: string; data: SentryBreadcrumbHint | undefined } {
  // get message vs object from the log args
  let message: string | undefined
  let data: any = undefined
  if (args.length > 0 && typeof args[0] === "string") {
    ;[message, ...data] = args
  } else if (args.length > 0) {
    data = [...args]
  }
  // construct message for sentry
  let sentryMessage = domain
  if (message) {
    sentryMessage = `${domain}: ${message}`
  }
  return {
    message: sentryMessage,
    data: sanitize(data),
  }
}

const log = (level: LogLevel, domain: string): LogFn => {
  const debug = LocalDebug(domain)
  return (...args) => {
    debug(...args)
    const { message, data } = getSentryParams(domain, ...args)
    const breadcrumbTypes: Record<LogLevel, SentryBreadcrumbType> = {
      [LogLevel.Debug]: "debug",
      [LogLevel.Error]: "error",
      [LogLevel.Info]: "default",
    }
    logSentry(message, level, data, domain, breadcrumbTypes[level])
  }
}

export function DebugLogger(domain: string): LogFn {
  return log(LogLevel.Debug, domain)
}

export function InfoLogger(domain: string): LogFn {
  return log(LogLevel.Info, domain)
}

export function ErrorLogger(domain: string): LogFn {
  return log(LogLevel.Error, domain)
}

export const Debug = DebugLogger

export function Logger(domain: string) {
  return {
    info: InfoLogger(domain),
    debug: DebugLogger(domain),
    error: ErrorLogger(domain),
    captureException: (e: Error) => {
      window.Sentry.captureException(e)
    },
  }
}
