import { appendRedirectParam } from "shared-libs/src/js/libs/services/auth-routing"
import { Debug } from "../logger"
import { CrossSellVisitLocations } from "shared-libs/src/js/shared-views/cross-selling/modal-cross-sell/components/cross-selling-ncp-products/utils/cross-selling-ncp-products.segment"
import {
  DESIGNER_MODE_FAKE_SKU,
  EditorMode,
} from "../../libs/products-render-config/types"
import _compact from "lodash/compact"
import { AppConfig } from "../../app-config/app.config"
import { SkuCleaner } from "shared-libs/src/js/libs/services/sku-cleaner"
import { VariantCustomization } from "@ph/product-api"
import _omitBy from "lodash/omitBy"
import _isEmpty from "lodash/isEmpty"

let debug = Debug("ph:editor:routing")

const defaultPath =
  "product/design?sku=box-eu--mailer-box--112--cardboard-white-one-side--print-eco-color--foil-none#"

enum UrlManipulatorConsts {
  BaseEditorPathName = "product",
  EditorPathFragment = "editor",
}

interface UrlRedirectOptions {
  withPaid?: boolean
}

interface DesignUrlParams {
  designId: string | number
  mode?: EditorMode
  isDuplicate?: boolean
  prevModeDesignId?: string | number
}

interface SkuUrlParams {
  sku: string
  quantity: number
  mode?: EditorMode
  prevModeDesignId?: string | number
}

export class UrlManipulatorProvider {
  public deployedOnRootPath: boolean = true
  private readonly basename: string = ""
  private readonly signInUri: string = ""

  constructor(private readonly appConfig: AppConfig) {
    if (this.isEditorBindToLandingHost()) {
      this.basename = [
        this.getBasePath(),
        UrlManipulatorConsts.EditorPathFragment,
      ].join("/")

      this.deployedOnRootPath = false
    } else {
      this.deployedOnRootPath = true
    }

    this.signInUri = this.appConfig.locale.getUrl("signIn") || ""

    if (this.deployedOnRootPath) {
      debug("I am deployed on root path: './' ")
    } else {
      debug(`I am deployed on a webroot path: '${this.basename}'`)
    }
  }

  private isPathBasedRegion(): boolean {
    return _compact(this.getBasePath().split("/")).length > 0
  }

  private getBasePath(): string {
    const baseUrl = this.appConfig.locale.getUrl("baseUrl")

    if (!baseUrl) {
      throw new Error("Base URL is not defined")
    }

    const { pathname } = new URL(baseUrl)

    if (pathname === "/") {
      return ""
    }

    return pathname
  }

  private isEditorBindToLandingHost() {
    return (
      window.location.pathname.indexOf(
        UrlManipulatorConsts.EditorPathFragment
      ) !== -1
    )
  }

  private getAppBasePath() {
    if (this.deployedOnRootPath) {
      return window.location.origin
    } else {
      return `${window.location.origin}${this.basename}`
    }
  }

  public getSignInUrl(): string {
    return appendRedirectParam(this.signInUri, window.location.href)
  }

  public getDesignIdParam() {
    const path = window.location.pathname.split("/")
    const i = path.findIndex((l) => l === "design")
    return path[i + 1]
  }

  public getForcedTab(): string | null {
    const uri = new URL(window.location.href)

    return uri.searchParams.get("tab")
  }

  public redirectToDesignDuplicate(designId: string) {
    window.location.href = this.getDesignUrl({ designId, isDuplicate: true })
  }

  public redirectToDesign(params: DesignUrlParams) {
    window.location.href = this.getDesignUrl(params)
  }

  public redirectToSku(params: SkuUrlParams) {
    window.location.href = this.getSkuUrl(params)
  }

  public redirectToOrders(): void {
    const url = this.appConfig.locale.getUrl("accountOrders")

    if (!url) {
      return
    }

    window.location.href = url
  }

  public redirectToCart(): void {
    const url = this.appConfig.locale.getUrl("cart")

    if (!url) {
      return
    }

    window.location.href = url
  }

  public redirectToHomepage() {
    const url = this.appConfig.locale.getUrl("baseUrl")

    if (!url) {
      return
    }

    window.location.href = url
  }

  public redirectToCmsAsset(): void {
    window.location.href = this.getCmsCurrentAssetUrl()
  }

  public resetPathToBase(sku: string, quantity: number) {
    const url = new URL(window.location.href)
    const designId = this.getDesignIdParam()

    if (designId) {
      const changedUrl = url
        .toString()
        .split("?")[0]
        .replace(designId, `?sku=${sku}&quantity=${quantity}`)
      window.location.replace(changedUrl)
    } else {
      window.location.reload()
    }
  }

  public redirectWithSku(sku: string) {
    const url = new URL(window.location.href)
    url.searchParams.append("source", "editor")
    const designId = this.getDesignIdParam()

    if (designId) {
      window.location.href = url
        .toString()
        .split("?")[0]
        .replace(designId, `?sku=${sku}&prev-design-id=${designId}`)
    } else {
      url.searchParams.set("sku", sku)
      url.searchParams.delete("mode")
      window.location.href = url.toString()
    }
  }

  public getPreviousDesignId(): string | null {
    return this.getParam("prev-design-id")
  }

  public withPreviousDesignId(): boolean {
    return this.getPreviousDesignId() !== null
  }

  public getPreviousModeDesignId(): string | null {
    return this.getParam("prev-mode-design-id")
  }

  public isDesignDuplicate(): boolean {
    return !!this.getParam("duplicate")
  }

  public getSource(): string | null {
    return this.getParam("source")
  }

  private getDesignLaterUrl(url: URL, sku: string, quantity: number): string {
    const designId = this.getDesignIdParam()

    const changedUri = designId
      ? new URL(url.toString().replace(designId, ""))
      : url

    changedUri.searchParams.set("sku", sku)
    changedUri.searchParams.set("mode", "dby")
    changedUri.searchParams.set("quantity", quantity.toString())
    changedUri.searchParams.set("design-later", "true")
    changedUri.searchParams.set("source", "editor")

    return changedUri.href
  }

  public redirectToDesignLaterUrl(sku: string, quantity: number) {
    const changedUri = new URL(window.location.href)

    window.location.href = this.getDesignLaterUrl(changedUri, sku, quantity)
  }

  public getCartUrl(opt: UrlRedirectOptions): string {
    const cartUrl = this.appConfig.locale.getUrl("cart")

    if (!cartUrl) {
      throw new Error("Cart url is not defined")
    }

    return this.decorateRedirectUrl(cartUrl, opt)
  }

  public getCrossSellUrl(opt: UrlRedirectOptions): string | undefined {
    const crossSellUrl = this.appConfig.locale.getUrl("crossSell")

    if (!crossSellUrl) {
      return undefined
    }

    return this.decorateRedirectUrl(crossSellUrl, opt)
  }

  public getProjectsUrl(): string | undefined {
    return this.appConfig.locale.getUrl("accountProjects")
  }

  public getHelpUrl(): string | undefined {
    return this.appConfig.locale.getUrl("help")
  }

  public getFscCertificationUrl(): string | undefined {
    return this.appConfig.locale.getUrl("fsc")
  }

  private decorateRedirectUrl(url: string, opt: UrlRedirectOptions): string {
    let redirectUrl = new URL(url)

    redirectUrl.searchParams.set("editor", "v2")
    redirectUrl.searchParams.set("source", CrossSellVisitLocations.editor)
    if (opt.withPaid === true) {
      redirectUrl.searchParams.set("paid", "1")
    }

    return redirectUrl.href
  }

  public handleRoute(): string | undefined {
    let wpPathname = window.location.pathname
    if (!this.deployedOnRootPath) {
      wpPathname = wpPathname.replace(this.basename, "")
    }

    // Query interceptor
    const uri = new URL(window.location.href)

    let token: string | undefined

    // 1. Extract and use token if present
    if (uri.searchParams.has("token")) {
      debug("found token in a URL, setting it into the system")

      token = uri.searchParams.get("token") as string
      this.callbackRoute(token)
      uri.searchParams.delete("token")
      window.history.pushState(null, "null", uri.href)
    }

    //
    const isWrongPath = wpPathname.split("/").length === 1
    // TODO
    // is query string is just single param it is wrong
    if (isWrongPath) {
      window.history.pushState(
        null,
        "null",
        `${this.getAppBasePath()}/${defaultPath}`
      )
    }

    return token
  }

  private callbackRoute(token: string) {
    if (token == null) {
      throw new Error("wrong callback no token provided, go home")
    }
  }

  public setRouteToBasePath(): void {
    window.history.pushState(null, "null", this.getAppBasePath())
  }

  public setRouteToDesign({ designId }: { designId: string }) {
    const newUrl = this.getDesignUrl({
      designId,
    })
    debug(`changing url to a new path: ${newUrl}`)
    window.history.pushState(null, "null", newUrl)
  }

  public getQtyFromUrl() {
    const uri = new URL(window.location.href)
    const qtyParam = uri.searchParams.get("quantity") as string

    if (qtyParam == null) {
      return null
    }

    const parsedQty = parseInt(qtyParam, 10)

    if (typeof parsedQty === "number") {
      debug(`got qty from URL params: ${parsedQty}`)

      if (parsedQty < 0) {
        window.Sentry.captureException(new Error(`parsedQty is lower than 0`))
      }
    }

    return parsedQty
  }

  public getSkuFromUrl(): string | undefined {
    const uri = new URL(window.location.href)
    const sku = uri.searchParams.get("sku")

    if (sku) {
      return new SkuCleaner(sku).call()
    }

    if (this.isDesignerMode()) {
      return DESIGNER_MODE_FAKE_SKU
    }
  }

  public getCustomizationFromUrl(): VariantCustomization | undefined {
    const url = new URL(window.location.href)

    const size = _omitBy(
      {
        width: Number(url.searchParams.get("customSize[width]")),
        height: Number(url.searchParams.get("customSize[height]")),
        length: Number(url.searchParams.get("customSize[length]")),
      },
      (v) => isNaN(v) || v === 0
    ) as VariantCustomization["size"]

    const customization = _omitBy(
      {
        size,
      },
      _isEmpty
    )

    if (Object.keys(customization).length === 0) {
      return
    }

    return customization
  }

  public hasModeInParams(): boolean {
    const uri = new URL(window.location.href)
    return uri.searchParams.has("mode")
  }

  public getMode(): EditorMode {
    const uri = new URL(window.location.href)

    if (uri.searchParams.has("mode")) {
      const mode = uri.searchParams.get("mode")

      if (mode === "designer") {
        return "designer"
      }

      if (mode === "dby") {
        return "dby"
      }
    }

    return "editor"
  }

  public isDesignerMode(): boolean {
    return this.getMode() === "designer"
  }

  public isEditorMode(): boolean {
    return this.getMode() === "editor"
  }

  public isDbyMode(): boolean {
    return this.getMode() === "dby"
  }

  public isDtpPreviewMode(): boolean {
    const uri = new URL(window.location.href)

    if (uri.searchParams.has("preview-mode")) {
      const previewMode = uri.searchParams.get("preview-mode")
      return previewMode === "dtp"
    }

    return false
  }

  public isDevToolsMode(): boolean {
    const url = new URL(window.location.href)

    if (url.searchParams.has("dev-tools")) {
      const devToolsMode = url.searchParams.get("dev-tools")
      return devToolsMode === "true"
    }

    return false
  }

  public isDtpTestEcommerceMode(): boolean {
    const uri = new URL(window.location.href)
    const isDtpFlow = uri.searchParams.get("dtp-test-flow") === "true"

    return this.isEditorMode() && isDtpFlow
  }

  public getParam(paramName: string): string | null {
    const uri = new URL(window.location.href)

    return uri.searchParams.get(paramName)
  }

  public getDirectusCmsToken(): string | null {
    if (!this.getParam("sku")) {
      return null
    }

    return this.getCmsToken()
  }

  public getCmsToken(): string | null {
    return this.getParam("cmsToken")
  }

  public getDesignToken(): string | null {
    return this.getParam("designToken")
  }

  public getCmsUrl(): string {
    if (this.getPredefinedTextId()) {
      return this.appConfig.api.strapi.baseUrl
    }

    return new URL(this.appConfig.api.assets.baseUrl).origin
  }

  public getCmsCurrentAssetUrl(): string {
    const predefinedTextUrl = this.getCmsPredefinedTextUrl()
    if (predefinedTextUrl) {
      return predefinedTextUrl
    }

    const templateUrl = this.getCmsTemplateUrl()
    if (templateUrl) {
      return templateUrl
    }

    return this.getCmsUrl()
  }

  public getCmsPredefinedTextUrl(): string | undefined {
    const predefinedTextId = this.getPredefinedTextId()

    if (!predefinedTextId) {
      return
    }

    return `${this.getCmsUrl()}/ph-cockpit/plugins/content-manager/collectionType/application::predefined-text.predefined-text/${predefinedTextId}`
  }

  public getCmsTemplateUrl(): string | undefined {
    const templateId = this.getTemplateId()

    if (!templateId) {
      return
    }

    return `${this.getCmsUrl()}/admin/content/template/${templateId}`
  }

  public getPredefinedTextId(): number | null {
    return this.getAssetId("predefinedTextId")
  }

  public getTemplateId(): number | null {
    return this.getAssetId("templateId")
  }

  public getCandidateId(): number | null {
    return this.getAssetId("candidateId")
  }

  private getAssetId(
    paramName: "predefinedTextId" | "templateId" | "candidateId"
  ): number | null {
    const templateId = this.getParam(paramName)

    return Number(templateId) || null
  }

  /**
   * checks if `forced_logo_uploader` url param is present
   */
  public hasForcedLogoUploaderInUrl(): boolean {
    const uri = new URL(window.location.href)
    const withForcedPersonalization = uri.searchParams.get(
      "forced_logo_uploader"
    )
    return withForcedPersonalization != null
  }

  public hasFscInUrl(): boolean {
    const uri = new URL(window.location.href)

    return !!uri.searchParams.get("fsc")
  }

  public hasDesignLaterInUrl(): boolean {
    const uri = new URL(window.location.href)

    return !!uri.searchParams.get("design-later")
  }

  public getDesignUrl({
    designId,
    mode,
    isDuplicate,
    prevModeDesignId,
  }: DesignUrlParams) {
    const designToken = this.getDesignToken()
    const url = new URL(`${this.getAppBasePath()}/product/design/${designId}`)

    url.searchParams.append("source", "editor")

    if (mode) {
      url.searchParams.append("mode", mode)
    }

    if (designToken) {
      url.searchParams.append("designToken", designToken)
    }

    if (isDuplicate) {
      url.searchParams.append("duplicate", isDuplicate.toString())
    }

    const redirectUrl = this.getParam("redirectUrl")

    if (redirectUrl) {
      url.searchParams.set("redirectUrl", redirectUrl)
    }

    if (prevModeDesignId) {
      url.searchParams.append(
        "prev-mode-design-id",
        prevModeDesignId.toString()
      )
    }

    return url.toString()
  }

  public getSkuUrl({
    sku,
    quantity,
    mode = "editor",
    prevModeDesignId,
  }: SkuUrlParams): string {
    const url = new URL(`${this.getAppBasePath()}/product/design`)
    const redirectUrl = this.getParam("redirectUrl")

    url.searchParams.append("sku", sku)
    url.searchParams.append("quantity", quantity.toString())
    url.searchParams.append("source", "editor")

    if (redirectUrl) {
      url.searchParams.append("redirectUrl", redirectUrl)
    }

    if (mode !== "editor") {
      url.searchParams.append("mode", mode)
    }

    if (prevModeDesignId) {
      url.searchParams.append(
        "prev-mode-design-id",
        prevModeDesignId.toString()
      )
    }

    return url.toString()
  }

  public getTemplateFamilyIdFromUrl(): number | null {
    return this.getAssetIdFromUrl("template-family")
  }

  public getPatternIdFromUrl(): number | null {
    return this.getAssetIdFromUrl("pattern")
  }

  private getAssetIdFromUrl(
    type: "template-family" | "pattern"
  ): number | null {
    const uri = new URL(window.location.href)
    const assetId = Number(uri.searchParams.get(`${type}-id`))

    if (isNaN(assetId)) {
      return null
    }

    return assetId
  }

  /**
   * Legacy. Will be removed when we update design showcase.
   */
  public getPatternVariantIdFromUrl(): number | null {
    const uri = new URL(window.location.href)
    const patternId = Number(uri.searchParams.get("pattern_id"))

    if (isNaN(patternId)) {
      return null
    }

    return patternId
  }
}
