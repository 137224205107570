import React, { PropsWithChildren } from "react"
import styles from "./NavItem.module.scss"
import { AnalyticsProps, TestsProps } from "../../types"

export interface NavItemProps extends AnalyticsProps, TestsProps {
  badge?: React.ReactNode
}

export function NavItem({ badge, children }: PropsWithChildren<NavItemProps>) {
  return (
    <div className={styles.item}>
      {children}
      {badge}
    </div>
  )
}
