import React from "react"
import styles from "./Tab.module.scss"
import { Skeleton } from "../../../atoms/Skeleton/Skeleton"
import cxBinder from "classnames/bind"
import { SkeletonTileList } from "../../../atoms/Skeleton/SkeletonTileList"

const cx = cxBinder.bind(styles)

export function TabLoader() {
  return (
    <div className={cx("wrapper", "normal")}>
      <div className={styles.tab}>
        <h2 className={styles.title}>
          <Skeleton width={180} height={20} />
        </h2>
        <SkeletonTileList repeats={12} />
      </div>
    </div>
  )
}
