import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import cxBinder from "classnames/bind"
import styles from "./DbyScene.module.scss"

const cx = cxBinder.bind(styles)

export const DbyScene = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { dbyTabController, editorModeController } = containerSet.ui
  const { previewUrl } = dbyTabController

  return (
    <div className={cx("wrapper", { bg: !previewUrl })}>
      {previewUrl && (
        <div
          className={styles.preview}
          e2e-target="image"
          e2e-target-name="dieline"
        >
          <img src={previewUrl} alt="Dieline" />
        </div>
      )}
    </div>
  )
})
