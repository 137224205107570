import React from "react"
import { Checkbox } from "dsl/src/atoms/Checkbox/Checkbox"
import styles from "./CheckInfoBox.module.scss"
import FormControlLabel from "dsl/src/atoms/FormControlLabel/FormControlLabel"
import { AnalyticsProps, TestsProps } from "../../types"

export interface CheckInfoBoxProps extends AnalyticsProps, TestsProps {
  label: string
  description?: string
  checked: boolean
  onChange: () => void
}

export function CheckInfoBox({
  label,
  description,
  checked,
  onChange,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: CheckInfoBoxProps) {
  return (
    <div className={styles["check-info-box"]}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} />}
        analyticsSection={analyticsSection}
        analyticsType={analyticsType}
        analyticsName={analyticsName}
        analyticsId={analyticsId}
        e2eTarget={e2eTarget}
        e2eTargetName={e2eTargetName}
      >
        <div className={styles.label}>{label}</div>
      </FormControlLabel>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  )
}
