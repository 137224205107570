import fabric from "../../vendors/Fabric"
import { getInfo } from "@ph/editor-assets"
import { prepareAssetUrl } from "../../helpers/asset-url-preparator"
import { Debug } from "../../../services/logger"

const debug = Debug("ph:editor:services:fabric-assets-loader")
const CROSS_ORIGIN_PARAMS_CONST = { crossOrigin: "Anonymous" }

class FabricAssetLoader {
  public static async loadAsset(
    url: string,
    assetOptions: object = {},
    mimeType: string = "image/png",
    isPackhelp: boolean = true
    //@ts-ignore
  ): Promise<fabric.PackhelpImage | fabric.Object> {
    if (!url) {
      throw new Error("Can't load image from empty url")
    }

    if (FabricAssetLoader.isMimeTypeSvg(mimeType)) {
      return FabricAssetLoader.loadAssetSvg(url, assetOptions)
    }

    return FabricAssetLoader.loadAssetBitmap(url, assetOptions, isPackhelp)
  }

  public static async loadAssetSvg(
    url: string,
    assetOptions: object
  ): Promise<fabric.Object> {
    return new Promise((resolve) => {
      fabric.loadSVGFromURL(
        prepareAssetUrl(url),
        (objects, options) => {
          const groupedSvg = fabric.util.groupSVGElements(
            objects,
            Object.assign({}, options, assetOptions)
          )
          groupedSvg.set(assetOptions)
          resolve(groupedSvg)
        },
        undefined,
        CROSS_ORIGIN_PARAMS_CONST
      )
    })
  }

  public static async loadAssetBitmap(
    url: string,
    assetOptions = {},
    isPackhelp: boolean
  ): Promise<fabric.Image> {
    const load = (preparedUrl: string): Promise<fabric.Image> => {
      return new Promise((resolve, reject) => {
        const imageObject = new Image()
        imageObject.crossOrigin = CROSS_ORIGIN_PARAMS_CONST.crossOrigin
        imageObject.onload = function () {
          if (isPackhelp) {
            resolve(
              //@ts-ignore
              new fabric.PackhelpImage(
                imageObject,
                Object.assign({}, assetOptions, CROSS_ORIGIN_PARAMS_CONST)
              )
            )
          } else {
            resolve(
              new fabric.Image(
                imageObject,
                Object.assign({}, assetOptions, CROSS_ORIGIN_PARAMS_CONST)
              )
            )
          }
        }
        imageObject.onerror = reject
        imageObject.src = prepareAssetUrl(preparedUrl)
      })
    }

    return load(url).catch(() => {
      /**
       * In case of error we should retry the request with a cache buster.
       */
      const preparedUrl = new URL(url)
      preparedUrl.searchParams.append("cb", String(Date.now()))

      return load(preparedUrl.toString()).catch(() => {
        debug("Error loading image", { url })

        throw new Error("Can't load image with provided url")
      })
    })
  }

  private static isMimeTypeSvg(mimeType: string): boolean {
    return getInfo(mimeType).isSvg
  }
}

export default FabricAssetLoader
