import React from "react"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { Modal } from "../../Modal/Modal"
import { ModalSection } from "../../ModalSection/ModalSection"
import { I18N } from "../../../../../i18n"

const i18n = I18N.modals.shareDesign

export interface ShareDesignInfoProps {
  onClose: () => void
  onClick: () => void
  loading: boolean
}

export function ModalShareDesignInfo({
  onClose,
  onClick,
  loading,
}: ShareDesignInfoProps) {
  const t = useTranslate()

  return (
    <Modal
      onClose={onClose}
      title={t(i18n.title)}
      link={{
        label: t(i18n.share),
        onClick: onClick,
        loading,
      }}
    >
      <ModalSection>
        <p>{t(i18n.info1)}</p>
        <p>{t(i18n.info2)}</p>
      </ModalSection>
    </Modal>
  )
}
