import React from "react"
import disabledIconUrl from "../../../assets/_icons/disabled.svg"
import { Tile, TileProps } from "./Tile"
import styles from "./TileEmpty.module.scss"
import { SvgIcon } from "../SvgIcon/SvgIcon"

export interface TileEmptyProps extends TileProps {
  label: string
}

export function TileEmpty({ label, ...tileProps }: TileEmptyProps) {
  return (
    <Tile {...tileProps}>
      <div className={styles.content}>
        <SvgIcon url={disabledIconUrl} />
        {label}
      </div>
    </Tile>
  )
}
