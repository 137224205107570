import React, { PropsWithChildren } from "react"
import styles from "./Toolbar.module.scss"

export interface ToolbarProps {
  e2eTarget?: string
  e2eTargetName?: string
}

export function Toolbar({
  children,
  e2eTarget = "toolbar",
  e2eTargetName,
}: PropsWithChildren<ToolbarProps>) {
  return (
    <div
      className={styles.wrapper}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {children}
    </div>
  )
}
