import React from "react"
import { Search as SearchBase } from "../../../atoms/Select/partials/Search"
import styles from "../SelectQty.module.scss"
import baseStyles from "../../../atoms/Select/Select.module.scss"
import Badge, { BadgeColorTypes } from "../../../atoms/Badge/Badge"
import { getPrice } from "./Item"
import { SelectItemData } from "../../../atoms/Select/types"
import Button from "../../../atoms/Button/Button"
import { ButtonSizes } from "../../../atoms/Button"

interface SearchProps {
  disabled: boolean | undefined
  searchRef: any
  isValid: boolean
  item?: SelectItemData
  itemRenderProp?: (item: SelectItemData) => React.ReactNode
  inputProps: any
  menuOpen?: boolean
  selectedItem?: any
  onOutsideClick?: () => void
  short?: boolean
  buttonContent?: React.ReactNode
  placeholder?: string
  isError?: boolean
  hidePricingDetails?: boolean
}

type PriceType = {
  item?: any
  renderProp?: (item: SelectItemData) => React.ReactNode
}

const SearchPrice = ({ item, renderProp }: PriceType) => {
  return (
    <div className={baseStyles.price}>
      {renderProp ? renderProp(item) : getPrice(item)}
    </div>
  )
}

type SearchBadgeType = {
  tag: string
  tagColor?: BadgeColorTypes
}

const SearchBadge = ({ tag, tagColor }: SearchBadgeType) => {
  return (
    <div className={baseStyles.badge}>
      <Badge badgeColor={tagColor || BadgeColorTypes.WhiteFour}>{tag}</Badge>
    </div>
  )
}

const Search = (props: SearchProps) => {
  const {
    searchRef,
    disabled,
    inputProps,
    menuOpen,
    selectedItem,
    itemRenderProp,
    onOutsideClick,
    buttonContent,
    placeholder,
    short,
    isError,
    hidePricingDetails
  } = props

  const shouldShowPrice =
    !hidePricingDetails &&
    selectedItem &&
    selectedItem.data &&
    !selectedItem.data.isCustomValue

  const shouldShowBadge =
    selectedItem &&
    selectedItem.tag &&
    ((selectedItem.data && selectedItem.data.isCustomValue) ||
      !hidePricingDetails)

  return (
    <>
      <div className={styles["search-wrap"]}>
        <div className={styles["search-text-input"]}>
          <SearchBase
            ref={searchRef}
            disabled={disabled}
            searchPlaceholder={placeholder}
            {...inputProps}
            error={isError}
          >
            {buttonContent && menuOpen && (
              <div className={styles["actions-wrap"]}>
                <Button size={ButtonSizes.small} type="submit">
                  {buttonContent}
                </Button>
              </div>
            )}
          </SearchBase>
        </div>
      </div>
      {!menuOpen && selectedItem && !short && (
        <div
          className={styles["selected-item-details"]}
          onClick={onOutsideClick}
        >
          {shouldShowBadge && (
            <SearchBadge
              tag={selectedItem.tag}
              tagColor={selectedItem.tagColor}
            />
          )}
          {shouldShowPrice && (
            <SearchPrice item={selectedItem} renderProp={itemRenderProp} />
          )}
        </div>
      )}
    </>
  )
}

export { Search, Search as default }
