import { SvgElement } from "./svg-elements/svg-element"

export class ElementsSorter {
  public constructor(private readonly elements: SvgElement[]) {}

  public call(): SvgElement[] {
    const sortedElements = [this.elements[0]]

    for (let i = 0; i < this.elements.length - 1; i++) {
      const currentElement = sortedElements[sortedElements.length - 1]
      const nextElement = this.findNextElement(
        currentElement,
        this.elements.filter((element) => !sortedElements.includes(element))
      )

      if (!nextElement) {
        throw new Error(`
          No next element found for ${currentElement.getId()}. 
          Space (${currentElement.getSpace()}) elements do not form a closed path.
        `)
      }

      sortedElements.push(nextElement)
    }

    return sortedElements
  }

  private findNextElement(
    currentElement: SvgElement,
    elements: SvgElement[]
  ): SvgElement | undefined {
    const currentSegment = currentElement.getSegment()

    return elements.find((element) => {
      if (currentElement.getId() === element.getId()) {
        return false
      }

      const segment = element.getSegment()

      if (
        currentSegment.end.x === segment.start.x &&
        currentSegment.end.y === segment.start.y
      ) {
        return true
      }

      if (
        currentSegment.end.x === segment.end.x &&
        currentSegment.end.y === segment.end.y
      ) {
        element.reverse()

        return true
      }

      return false
    })
  }
}
