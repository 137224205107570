import { observable, action, computed, toJS, makeObservable } from "mobx"
import { Debug } from "../services/logger"
import { InputFile } from "../services/asset-service/uploader"
import {
  AssetSource,
  AssetSection,
  ImageAssetMetaT,
  SerializedAssetWithMeta,
} from "../types/asset.types"
import { eventTree, AllEditorEventsEmitter } from "../events/editor.events"
import { ImageAssetItemStore } from "./image-asset-item.store"
import { LogoApi } from "../libs/api/ecommerce-api/rest-api/endpoints/logo-api"

// Controllers
let debug = Debug("ph:asset-store")

export interface AssetsStoreConfig {
  softDelete: boolean
}

/**
 * After merge todo:
 *
 * change order, so private go down
 */

/**
 * Asset store contains two types of assets:
 *
 * - valid - normal jpg/svg etc
 * - invalid - that have some type of error
 *
 * We serialize to JSON only valid assets. Invalid
 * assets are transient, like form input.
 *
 * We also serialize/deserialize softDeleted assets
 *
 * UI should use `assets` getter that will return
 * normal and errored asset but will will filter out
 * soft-deleted assets
 *
 */
export class AssetsStore {
  @observable public allAssets: ImageAssetItemStore[] = []
  @observable public logo: ImageAssetItemStore | null = null
  @observable public isSettingLogo = false
  @observable public isSetAsLogo = false
  @observable public isLoadingSingleImage = false

  private readonly logoApi?: LogoApi
  private readonly ee: AllEditorEventsEmitter
  private readonly config: AssetsStoreConfig

  constructor({
    logoApi,
    ee,
    config = {
      softDelete: true,
    },
  }: {
    logoApi?: LogoApi
    ee: AllEditorEventsEmitter
    config: AssetsStoreConfig
  }) {
    this.logoApi = logoApi
    this.ee = ee
    this.config = config

    this.ee.on(eventTree.session.receivedUser, this.loadUserLogo.bind(this))

    makeObservable(this)
  }

  /**
   * Assets for UI consumption. Without soft-deleted
   */
  @computed
  public get availableAssets(): ImageAssetItemStore[] {
    return this.allAssets.filter(
      (a) => a.meta != null && a.meta.isSoftDeleted === false
    )
  }

  @computed
  public get failedAssets(): ImageAssetItemStore[] {
    return this.availableAssets.filter((asset) => asset.error)
  }

  @computed
  public get successAssets(): ImageAssetItemStore[] {
    return this.availableAssets.filter((asset) => !asset.error)
  }

  @computed
  public get successUserAssets(): ImageAssetItemStore[] {
    return this.successAssets.filter((asset) => !asset.meta.source)
  }

  @computed
  public get successTemplateAssets(): ImageAssetItemStore[] {
    return this.successAssets.filter(
      (asset) => asset.meta.source === "template"
    )
  }

  @computed
  public get successBackgroundImageAssets(): ImageAssetItemStore[] {
    return this.successAssets.filter(
      (asset) => !asset.isUploading && asset.isInSection("background-image")
    )
  }

  @computed
  public get failedBackgroundImageAssets(): ImageAssetItemStore[] {
    return this.failedAssets.filter((asset) =>
      asset.isInSection("background-image")
    )
  }

  public async addLogo(file: InputFile) {
    this.setIsSetAsLogo(false)
    this.setIsSettingLogo(true)
    this.ee.emit(eventTree.graphics.logoUploadStarted)

    const imageAsset = new ImageAssetItemStore()
    this.setLogo(imageAsset)
    this.addAsset(imageAsset)
    await imageAsset.createFromFile(file)

    if (imageAsset.error || imageAsset.isSoftDeleted()) {
      this.setIsSettingLogo(false)
      this.setIsSetAsLogo(false)

      this.ee.emit(eventTree.graphics.logoUploadFailed)
      return
    }

    this.ee.emit(
      eventTree.graphics.logoUploadFinished,
      imageAsset.getImageAsset()
    )

    await this.setAssetAsLogo(imageAsset)
  }

  public async addAssetFromFile(
    file: InputFile,
    options: { sections?: AssetSection[] } = {}
  ) {
    const imageAsset = new ImageAssetItemStore()

    if (options.sections) {
      imageAsset.setSections(options.sections)
    }

    this.addAsset(imageAsset)

    await imageAsset.createFromFile(file, () => {
      if (imageAsset.error) {
        return
      }

      // Log and notify
      const graphic = imageAsset.getSource()
      debug(`Added an asset: ${graphic.original.originalFilename}`)
      this.ee.emit(eventTree.graphics.assetUploaded, imageAsset.getImageAsset())
    })

    return imageAsset
  }

  public addSerializedTemplateAsset(
    serializedAsset: SerializedAssetWithMeta
  ): void {
    this.addAssetFromSerialized({
      ...serializedAsset,
      meta: {
        ...serializedAsset.meta,
        source: "template",
      },
    })
  }

  private addAssetFromSerialized(
    serializedAsset: SerializedAssetWithMeta
  ): void {
    this.addAsset(new ImageAssetItemStore(serializedAsset))
  }

  @action
  private addAsset(asset: ImageAssetItemStore): void {
    this.allAssets.push(asset)
  }

  @action
  public async setAssetAsLogo(imageAssetStore: ImageAssetItemStore) {
    try {
      this.setLogo(imageAssetStore)
      this.setIsSettingLogo(true)

      await this.logoApi?.upsert(imageAssetStore.getSource())

      // add logo
      this.setIsSettingLogo(false)
      this.setIsSetAsLogo(true)

      // Log and notify
      const imageAsset = imageAssetStore.getImageAsset()
      debug(
        `Added an asset: ${imageAsset.toSource().original.originalFilename}`
      )
      this.ee.emit(eventTree.graphics.logoSet, imageAsset)
    } catch (e) {
      this.setIsSettingLogo(false)
      this.setIsSetAsLogo(false)
    }
  }

  @action
  public async removeAssetAsLogo() {
    if (!this.logo) {
      return
    }

    this.setIsSettingLogo(true)
    this.setLogo(null)

    try {
      await this.logoApi?.delete()

      this.setIsSettingLogo(false)
      this.setIsSetAsLogo(false)
      this.ee.emit(eventTree.graphics.logoRemoved)
    } catch (e) {
      this.setIsSettingLogo(false)
      this.setIsSetAsLogo(false)
    }
  }

  @action
  private async loadUserLogo() {
    const logo = await this.logoApi?.get()

    if (!logo) {
      return
    }

    const imageAsset = new ImageAssetItemStore(logo)
    this.setLogo(imageAsset)
    this.allAssets.push(imageAsset)
  }

  @action
  public deserialize(assets: SerializedAssetWithMeta[]) {
    if (!assets.length && this.logo) {
      this.allAssets = [this.logo]

      return
    }

    this.allAssets = assets.map((assetMeta) => {
      if (this.logo && this.isAssetLogoByMd5(assetMeta)) {
        return this.logo
      }

      const asset = new ImageAssetItemStore(assetMeta)

      if (!this.logo && asset.isInSection("logo")) {
        this.logo = asset
      }

      return asset
    })
  }

  /**
   * Soft delete the asset
   * @param assetToRemove
   */
  @action
  public removeAsset(assetToRemove: ImageAssetItemStore) {
    if (assetToRemove === this.logo) {
      this.removeAssetAsLogo()
    }

    if (this.config.softDelete) {
      assetToRemove.softDelete()
    } else {
      this.allAssets = this.allAssets.filter((asset) => asset !== assetToRemove)
    }
  }

  @action
  public removeAssetsBySource(assetSource: AssetSource) {
    this.allAssets = this.allAssets.filter(
      (asset) => asset.source !== assetSource
    )
  }

  @action
  public removeTemplateAssets() {
    this.removeAssetsBySource("template")
  }

  /**
   * Serialize VALID. We omit assets:
   * - with errors (upload, mime-type issues, transform etc.)
   * - pending transfer
   *
   * ATTENTION we serialize soft-deleted assets too!
   */
  public serialize(): SerializedAssetWithMeta[] {
    const validAssets = this.allAssets.filter(
      (asset) => asset.error == null && asset.hasTransformed === true
    )
    const serializedAssets = toJS(
      validAssets.map((asset) => asset.serializeAsset())
    )
    return serializedAssets
  }

  @action
  public setIsLoadingSingleImage(isLoading: boolean): void {
    this.isLoadingSingleImage = isLoading
  }

  @action
  private setLogo(logo: ImageAssetItemStore | null) {
    for (const asset of this.allAssets) {
      asset.removeSection("logo")
    }

    logo?.addSection("logo")

    this.logo = logo
  }

  @action
  private setIsSettingLogo(isSetting: boolean) {
    this.isSettingLogo = isSetting
  }

  @action
  setIsSetAsLogo(isSet: boolean) {
    this.isSetAsLogo = isSet
  }

  private isAssetLogoByMd5(assetsMeta: ImageAssetMetaT) {
    return (
      this.logo &&
      this.logo.data &&
      assetsMeta.original.md5 === this.logo.data.original.md5 &&
      assetsMeta.original.filestackHandle ===
        this.logo.data.original.filestackHandle
    )
  }
}
