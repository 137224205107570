import { computed, makeObservable, observable } from "mobx"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import ProductDriver from "../../drivers/product.driver"
import { Fonts } from "../../libs/services/fonts-loader-service/fonts-loader.config"
import { FontFamilyDefinition } from "../../libs/services/fonts-loader-service/fonts-loader.service"
import { PackhelpEditableText } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { isAssetText } from "../../types/asset.types"
import { TextObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object.controller"
import { isTextObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"

export class FontsTabController {
  @observable public selectedFontFamily: Fonts = Fonts.Lato

  private readonly productDriver: ProductDriver
  private readonly ee: AllEditorEventsEmitter

  constructor(services: {
    productDriver: ProductDriver
    ee: AllEditorEventsEmitter
  }) {
    this.productDriver = services.productDriver
    this.ee = services.ee

    makeObservable(this)

    this.ee.on(eventTree.activeObject.selected, this.refreshState.bind(this))
  }

  public setFontFamily(definition: FontFamilyDefinition) {
    this.activeObjectController?.setStyles({
      fontFamily: definition.id as Fonts,
    })
    this.refreshState()

    this.ee.emit(eventTree.activeObject.fontChanged)
  }

  public refreshState() {
    if (!this.activeObject) {
      return
    }

    this.selectedFontFamily = this.activeObject.fontFamily
  }

  @computed
  private get activeObject(): PackhelpEditableText | null {
    const { activeObject } = this.productDriver.activeObjectDriver

    if (activeObject && isAssetText(activeObject)) {
      return activeObject
    }

    return null
  }

  @computed
  private get activeObjectController(): TextObjectController | null {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (
      activeObjectController &&
      isTextObjectController(activeObjectController)
    ) {
      return activeObjectController
    }

    return null
  }
}
