import React from "react"
import addIconUrl from "../../../assets/_icons/plus.svg"
import closeIconUrl from "../../../assets/_icons/close.svg"
import removeIconUrl from "../../../assets/_icons/minus.svg"
import styles from "./OptionBox.module.scss"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import cxBinder from "classnames/bind"
import { getFormattedPrice } from "shared-libs/src/js/shared-views/sustainability/planting-trees/reforestation-calculator/reforestation-calculator.utils"
import { useCurrency } from "../../../hooks/useCurrency"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"
import { CloseButton } from "../../atoms/CloseButton/CloseButton"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"

const cx = cxBinder.bind(styles)

export interface OptionBoxProps extends AnalyticsProps, TestsProps {
  title: string
  description: string
  onClick: () => void
  onClose?: () => void
  active?: boolean
  imageUrl?: string
  price?: number
  loading?: boolean
}

export function OptionBox({
  title,
  description,
  onClick,
  onClose,
  active = false,
  imageUrl,
  price,
  loading = false,
  analyticsSection,
  analyticsType = "cta-link",
  analyticsName,
  analyticsId,
  e2eTarget = "option-box",
  e2eTargetName,
}: OptionBoxProps) {
  const t = useTranslate()
  const currency = useCurrency()

  return (
    <div
      className={cx("wrapper", { active })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{description}</p>

      {loading ? (
        <Loader
          type={LoaderTypes.circular}
          color={LoaderColors.gray}
          e2eTarget="loader"
        />
      ) : (
        <button
          className={styles.button}
          onClick={onClick}
          analytics-section={analyticsSection}
          analytics-name={analyticsName}
          analytics-type={analyticsType}
          analytics-id={analyticsId}
          e2e-target="button"
          e2e-target-name="cta"
        >
          {active ? (
            <>
              <SvgIcon url={removeIconUrl} />
              <span>{t(I18N.generic.remove)}</span>
            </>
          ) : (
            <>
              <SvgIcon url={addIconUrl} />
              <span>{t(I18N.generic.add)}</span>
              {!!price && !!currency && (
                <div className={styles.price}>
                  {price > 0 && <span>{" +"} </span>}
                  <span e2e-target="text" e2e-target-name="price">
                    {getFormattedPrice(price, currency)}
                  </span>
                </div>
              )}
            </>
          )}
        </button>
      )}

      {imageUrl && <img src={imageUrl} alt="" />}

      {!!onClose && (
        <div className={styles.close}>
          <CloseButton onClick={onClose} size="xs" />
        </div>
      )}
    </div>
  )
}
