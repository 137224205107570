import React from "react"
import { observer } from "mobx-react-lite"
import { Switcher } from "../../atoms/Switcher/Switcher"
import { SwitcherItem } from "../../atoms/Switcher/SwitcherItem"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./CameraControls.module.scss"
import {
  EditContext,
  SpaceId,
  ViewType,
} from "../../../../libs/products-render-config/types"
import { SwitcherLabel } from "../../atoms/Switcher/SwitcherLabel"
import cursorIconUrl from "../../../assets/_icons/cursor.svg"
import handIconUrl from "../../../assets/_icons/hand.svg"

const i18n = I18N.component.cameraControlsToolbar

export const CameraControls = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { cameraControlsUiController } = containerSet.ui
  const {
    isAvailable,
    isEditContextSwitcherAvailable,
    editContexts,
    activeContext,
    isSpaceSwitcherAvailable,
    spaces,
    activeSpace,
    isViewTypeSwitcherAvailable,
    viewTypes,
    activeViewType,
    isSpaceNameAvailable,
    isSpaceZoomAvailable,
    isSpaceZoomActive,
    isDielineZoomAvailable,
    dielineZoomPercentage,
    isDielineZoomOutAvailable,
    isDielineZoomInAvailable,
    isDragModeAvailable,
    isDragModeActive,
  } = cameraControlsUiController

  if (!isAvailable) {
    return null
  }

  const onEditContextClick = (editContext: EditContext) => {
    cameraControlsUiController.changeEditContext(editContext)
  }

  const onSpaceClick = (space: SpaceId) => {
    cameraControlsUiController.changeSpace(space)
  }

  const onViewTypeClick = (viewType: ViewType) => {
    cameraControlsUiController.changeViewType(viewType)
  }

  const onSpaceZoomClick = (isActive: boolean) => {
    cameraControlsUiController.setIsSpaceZoomActive(isActive)
  }

  const onDielineZoomClick = (value: number) => {
    cameraControlsUiController.zoomDieline(value)
  }

  const onDragModeClick = () => {
    cameraControlsUiController.toggleDragMode()
  }

  return (
    <div className={styles.wrapper}>
      {isEditContextSwitcherAvailable && (
        <Switcher>
          {editContexts.map((editContext) => {
            return (
              <SwitcherItem
                key={editContext}
                label={t(i18n.context[editContext])}
                onClick={() => onEditContextClick(editContext)}
                active={editContext === activeContext}
                analyticsSection="main-canvas-area"
                analyticsType="view-toggle"
                analyticsName={editContext}
                e2eTarget="view-toggle"
                e2eTargetName={editContext}
              />
            )
          })}
        </Switcher>
      )}

      {isSpaceSwitcherAvailable && (
        <Switcher>
          {spaces.map((space) => {
            return (
              <SwitcherItem
                key={space}
                label={t(i18n.space[space])}
                onClick={() => onSpaceClick(space)}
                active={space === activeSpace}
                analyticsSection="main-canvas-area"
                analyticsType="view-toggle"
                analyticsName={space}
                e2eTarget="view-toggle"
                e2eTargetName={space}
              />
            )
          })}
        </Switcher>
      )}

      {isViewTypeSwitcherAvailable && (
        <Switcher>
          {viewTypes.map((viewType) => {
            return (
              <SwitcherItem
                key={viewType}
                label={t(i18n.viewType[viewType])}
                onClick={() => onViewTypeClick(viewType)}
                active={viewType === activeViewType}
                analyticsSection="main-canvas-area"
                analyticsType="view-toggle"
                analyticsName={viewType}
                e2eTarget="view-toggle"
                e2eTargetName={viewType}
              />
            )
          })}
        </Switcher>
      )}

      {isSpaceZoomAvailable && (
        <Switcher>
          <SwitcherItem
            label={t(i18n.zoom.in)}
            onClick={() => onSpaceZoomClick(true)}
            active={isSpaceZoomActive}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="zoom-in"
            e2eTarget="view-toggle"
            e2eTargetName="zoom-in"
          />
          <SwitcherItem
            label={t(i18n.zoom.out)}
            onClick={() => onSpaceZoomClick(false)}
            active={!isSpaceZoomActive}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="zoom-out"
            e2eTarget="view-toggle"
            e2eTargetName="zoom-out"
          />
        </Switcher>
      )}

      {isDielineZoomAvailable && (
        <Switcher>
          <SwitcherItem
            label="-"
            onClick={() => onDielineZoomClick(-0.5)}
            highlighted={true}
            disabled={!isDielineZoomOutAvailable}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="zoom-out"
            e2eTarget="view-toggle"
            e2eTargetName="zoom-out"
          />
          <SwitcherLabel label={dielineZoomPercentage} />
          <SwitcherItem
            label="+"
            onClick={() => onDielineZoomClick(0.5)}
            highlighted={true}
            disabled={!isDielineZoomInAvailable}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="zoom-in"
            e2eTarget="view-toggle"
            e2eTargetName="zoom-in"
          />
        </Switcher>
      )}

      {isDragModeAvailable && (
        <Switcher>
          <SwitcherItem
            iconUrl={cursorIconUrl}
            onClick={() => onDragModeClick()}
            active={!isDragModeActive}
            highlighted={true}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="pointer-tool"
            e2eTarget="view-toggle"
            e2eTargetName="pointer-tool"
          />
          <SwitcherItem
            iconUrl={handIconUrl}
            onClick={() => onDragModeClick()}
            active={isDragModeActive}
            highlighted={true}
            analyticsSection="main-canvas-area"
            analyticsType="view-toggle"
            analyticsName="hand-tool"
            e2eTarget="view-toggle"
            e2eTargetName="hand-tool"
          />
        </Switcher>
      )}

      {isSpaceNameAvailable && activeSpace && (
        <div className={styles.space}>
          {activeSpace === "default" ? (
            <>{t(i18n.context[activeContext])}</>
          ) : (
            <>
              {t(i18n.space[activeSpace])} ({t(i18n.context[activeContext])})
            </>
          )}
        </div>
      )}
    </div>
  )
})
