import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { ToolbarSection } from "../../Toolbar/ToolbarSection"
import { MonochromeSlider } from "../../../../molecules/MonochromeSlider/MonochromeSlider"
import { ToolbarCheckbox } from "../../../../molecules/ToolbarCheckbox/ToolbarCheckbox"
import { I18N } from "../../../../../i18n"
import { ImageSection } from "./ImageSection"

const i18n = I18N.toolbar.image

export const RasterImageSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { rasterImageToolbarController } = containerSet.ui
  const {
    isToolbarAvailable,
    isThresholdAvailable,
    selectedThreshold,
    isInverted,
    isBackgroundToggleAvailable,
    isBackgroundRemoved,
  } = rasterImageToolbarController

  if (!isToolbarAvailable) {
    return null
  }

  const onThresholdChange = (threshold: number) => {
    rasterImageToolbarController.setThreshold(threshold)
  }

  const onInverseToggle = () => {
    rasterImageToolbarController.toggleMonochrome()
  }

  const onBackgroundToggle = () => {
    rasterImageToolbarController.toggleBackground()
  }

  return (
    <>
      {isThresholdAvailable && (
        <>
          <ToolbarSection>
            <MonochromeSlider
              value={selectedThreshold}
              onChange={onThresholdChange}
            />
          </ToolbarSection>
          <ToolbarSection>
            <ToolbarCheckbox
              onChange={onInverseToggle}
              label={t(i18n.inverse)}
              checked={isInverted}
              analyticsName="inverse"
              analyticsId={isInverted ? "enable" : "disable"}
            />
          </ToolbarSection>
        </>
      )}

      {isBackgroundToggleAvailable && (
        <ToolbarCheckbox
          onChange={onBackgroundToggle}
          label={t(i18n.removeWhiteBackground)}
          checked={isBackgroundRemoved}
          analyticsName="remove-white-background"
          analyticsId={isBackgroundRemoved ? "enable" : "disable"}
        />
      )}

      <ImageSection controller={rasterImageToolbarController} />
    </>
  )
})
