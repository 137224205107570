import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import Basil from "shared-libs/src/js/libs/storage/basil.js"
import { apiTokenCookieKeyBasedOnServerInstance } from "./cookie.helpers"

const domain = RegionConfig.getDomainHostnameWithDot()

const apiToken = apiTokenCookieKeyBasedOnServerInstance()

export const sessionRepoOptions = {
  key: apiToken.replace(/\./g, "-"),
  domain: domain,
}

export const setApiTokenCookie = (newToken: string) => {
  Basil.cookie.set(apiToken, newToken, {
    domain: domain,
  })
}

export const getApiTokenFromCookie = () => {
  return Basil.cookie.get(sessionRepoOptions.key)
}

export const apiTokenFromCookie = getApiTokenFromCookie()

export const SPREE_API_KEY_NAME = "spree_api_key"

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const getApiTokenFromCookieWithFallback = async (
  timeLeft = 10000,
  waitTime = 10
): Promise<string> => {
  /*
   * We need to initialise api studio with a user token.
   * If user enters product page directly in incognito, he'll end up on endless configurator loading
   * due to the lack of token. The request for creating an anonymous user is still in progress
   * and the cookie is not set when the product configurator logic is initialised.
   * https://github.com/packhelp/packhelp/pull/15041
   */
  const apiTokenFromCookie: string | null = getApiTokenFromCookie()

  if (!apiTokenFromCookie) {
    // wait until anonymous user is created and cookie is set

    waitTime = Math.ceil(waitTime * 1.1)
    timeLeft -= waitTime

    if (timeLeft <= 0) {
      throw new Error("Api token from cookie is unavailable!")
    }

    await wait(waitTime)
    return await getApiTokenFromCookieWithFallback(timeLeft, waitTime)
  }

  return apiTokenFromCookie
}
