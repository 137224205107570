var SkuFinish;
(function (SkuFinish) {
    SkuFinish["SKU_FINISH_NONE"] = "none";
    SkuFinish["SKU_FINISH_DESIGN_BW_THANK_YOU"] = "design-bw-thank-you";
    SkuFinish["SKU_FINISH_DESIGN_COLOR_HELLO"] = "design-color-hello";
    SkuFinish["SKU_FINISH_DESIGN_BW_HELLO"] = "design-bw-hello";
    SkuFinish["SKU_FINISH_DESIGN_COLOR_OPEN_ME"] = "design-color-open-me";
    SkuFinish["SKU_FINISH_DESIGN_COLOR_THANK_YOU"] = "design-color-thank-you";
    SkuFinish["SKU_FINISH_BROWN"] = "brown";
    SkuFinish["SKU_FINISH_WHITE"] = "white";
})(SkuFinish || (SkuFinish = {}));
export default SkuFinish;
