import { action, computed, makeObservable, observable } from "mobx"
import { ChangeProductEditorMode } from "./change-product-editor.controller"
import { ChangeProductDbyMode } from "./change-product-dby.controller"
import ProductDriver from "../../drivers/product.driver"
import { ProductDesignStore } from "../../stores/product-design.store"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { DbyModeStore } from "../../stores/dby-mode.store"
import { ChangeProductControllable } from "./change-product.interface"
import { VariantCustomization } from "@ph/product-api"
import { DielineStore } from "../../stores/dieline.store"
import {
  EcommerceController,
  MissingShippingDestinationError,
} from "../ecommerce.controller"
import { UrlManipulatorProvider } from "../../services/manipulators/url.manipulator"
import { SidebarTab } from "../../libs/products-render-config/types"
import { EcommerceStore } from "../../stores/ecommerce.store"

type ConfirmationModalType = "mode"

export class ChangeProductController {
  @observable public confirmationModalType?: ConfirmationModalType

  private readonly modeController: ChangeProductControllable
  private onConfirm?: () => void

  private readonly productDriver: ProductDriver
  private readonly ee: AllEditorEventsEmitter
  private readonly uri: UrlManipulatorProvider
  private readonly productDesignStore: ProductDesignStore
  private readonly dielineStore: DielineStore
  private readonly dbyModeStore: DbyModeStore

  private ecommerceController?: EcommerceController
  private ecommerceStore?: EcommerceStore

  constructor(
    private readonly editorMode: "editor" | "dby" | "designer",
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
      uri: UrlManipulatorProvider
    },
    stores: {
      productDesignStore: ProductDesignStore
      dielineStore: DielineStore
      dbyModeStore: DbyModeStore
    }
  ) {
    makeObservable(this)

    this.productDriver = services.productDriver
    this.ee = services.ee
    this.uri = services.uri
    this.productDesignStore = stores.productDesignStore
    this.dielineStore = stores.dielineStore
    this.dbyModeStore = stores.dbyModeStore

    this.modeController =
      this.editorMode === "dby"
        ? new ChangeProductDbyMode(services, stores)
        : new ChangeProductEditorMode(services, stores)

    this.ee.on(eventTree.tab.tabChanged, (_newTab, oldTab) =>
      this.onTabChanged(oldTab)
    )
  }

  public setEcommerceDependencies(
    ecommerceController: EcommerceController,
    ecommerceStore: EcommerceStore
  ): void {
    this.ecommerceController = ecommerceController
    this.ecommerceStore = ecommerceStore
  }

  @action
  public async changeProduct(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    const { productStore } = this.productDriver
    const { product } = productStore
    const newEditorMode = product.getVariantEditorMode(sku)

    if (this.editorMode !== "dby" && newEditorMode === "dby") {
      this.openConfirmationModal("mode", () => {
        const doRedirect = () => this.uri.redirectWithSku(sku)

        this.ee.once(eventTree.pd.saved, doRedirect)
        this.ee.once(eventTree.pd.saveSkippedNotValid, doRedirect)
        this.ee.emit(eventTree.pd.saveTriggered)
      })

      return
    }

    return this.changeSku(sku, customization)
  }

  @action
  public openConfirmationModal(
    type: ConfirmationModalType,
    onConfirm: () => void
  ): void {
    this.confirmationModalType = type
    this.onConfirm = onConfirm
  }

  @action
  public closeConfirmationModal(): void {
    this.confirmationModalType = undefined
    this.onConfirm = undefined
  }

  @action
  public async confirmChange(): Promise<void> {
    if (!this.onConfirm) {
      return
    }

    this.onConfirm()
    this.closeConfirmationModal()
  }

  public onCloseClick = async (): Promise<void> => {
    this.ee.emit(eventTree.activeTab.close)
  }

  @computed
  public get isSelectingDisabled(): boolean {
    const { isProductChanging, isRendererLoading } = this.productDriver.state
    const isDynamicVariantLoading =
      !!this.ecommerceStore?.isDynamicVariantLoading

    return isProductChanging || isRendererLoading || isDynamicVariantLoading
  }

  private async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    const { isProductChanging } = this.productDriver.state

    if (isProductChanging) {
      return
    }

    try {
      await this.ecommerceController?.loadDynamicVariant(sku, customization)
    } catch (e) {
      if (e instanceof MissingShippingDestinationError) {
        this.ecommerceController?.showShippingDestinationModal(() =>
          this.changeSku(sku, customization)
        )
      }

      return
    }

    await this.modeController.changeSku(sku, customization)
  }

  private async apply(): Promise<void> {
    if (!this.modeController.originalDesign) {
      return
    }

    this.modeController.clearOriginalDesign()
    this.ee.emit(eventTree.productDriver.productChangeApplied)
  }

  private async onTabChanged(oldTab?: SidebarTab): Promise<void> {
    if (oldTab === "size" || oldTab === "configuration") {
      await this.apply()
    }
  }
}
