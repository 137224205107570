import { ModelConfig, RendererConfig } from "../3d-renderer/types"
import { ThreeDimRenderer } from "../3d-renderer/three-dim-renderer"
import EventEmitter from "eventemitter3"
import { ThumbnailGeneratorConfig } from "../../types"

export class ThreeDimThumbnailGenerator {
  constructor(
    private canvases: Record<string, HTMLCanvasElement>,
    private modelConfig: ModelConfig,
    private config: ThumbnailGeneratorConfig & Partial<RendererConfig>
  ) {}

  public async generate(): Promise<string> {
    const renderer = await this.initRenderer()
    const thumbnail = renderer.toDataURL(this.config)

    renderer.stopAnimate()
    renderer.dispose()

    return thumbnail
  }

  private initRenderer(): Promise<ThreeDimRenderer> {
    return new ThreeDimRenderer(
      this.createMountPoint(),
      new EventEmitter(),
      this.canvases,
      {
        ...this.config,
        size: {
          width: this.config.width,
          height: this.config.height,
        },
        mode: "thumbnail",
      }
    ).init(this.modelConfig)
  }

  private createMountPoint(): HTMLDivElement {
    const mountPoint = document.createElement("div")

    mountPoint.setAttribute("width", this.config.width.toString())
    mountPoint.setAttribute("height", this.config.height.toString())

    return mountPoint
  }
}
