import { action, computed, observable, makeObservable } from "mobx"
import { ClipPathModes } from "../render-engine/modules/vd-editor/services/clip-path.generator"
import {
  PackhelpActiveSelection,
  PackhelpCanvas,
  PackhelpEditableGroup,
  PackhelpEditableImage,
  PackhelpEditableObject,
  PackhelpGroupType,
} from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import ProductDriver from "./product.driver"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import {
  ActiveObjectEvent,
  GraphicAssetEvents,
} from "../events/partials/domain.events"
import { CanvasObjectControllerFactory } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controller.factory"
import { CanvasObjectControllable } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/canvas-object-controllable.interface"
import VirtualDielineEditor from "../render-engine/modules/vd-editor/virtual-dieline-editor"
import { ActiveSelectionController } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/active-selection.controller"
import { fabric } from "fabric"
import {
  isInteractiveCanvas,
  isActiveSelection,
  isAssetGroup,
  isAssetImage,
} from "../types/asset.types"
import {
  isImageObjectController,
  MovementDirection,
} from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import EventEmitter from "eventemitter3"

export type ActiveObject =
  | PackhelpEditableObject
  | PackhelpEditableGroup
  | PackhelpEditableImage
  | PackhelpActiveSelection

class ActiveObjectDriver {
  @observable public activeObjectType?: string | null = null
  @observable public activeObject: ActiveObject | null = null
  @observable public activeObjectController: CanvasObjectControllable | null =
    null
  public activeObjectComputable = observable({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    isOverscaled: false,
    isMinScaleLimitReached: false,
    isSafeZoneTouched: false,
    isSpaceEdgeCrossed: false,
    clipMode: ClipPathModes,
    isSpaceClippingDisabled: true,
    boundingTop: 0,
    boundingLeft: 0,
    boundingWidth: 0,
    boundingHeight: 0,
  })

  private readonly eventEmitter: EventEmitter

  constructor(
    private readonly productDriver: ProductDriver,
    private readonly ee: AllEditorEventsEmitter
  ) {
    this.eventEmitter = productDriver.eventEmitter

    makeObservable(this)

    this.attachEventListeners()
  }

  @computed
  public get isSpaceClippingTogglingAvailable(): boolean {
    if (!this.activeObject || !this.activeObjectController) {
      return false
    }

    return (
      !isActiveSelection(this.activeObject) &&
      !this.activeObjectController.hasGroup() &&
      this.productDriver.getVdEditor().isSpaceClippingTogglingAvailable()
    )
  }

  public async toggleSpaceClipping(): Promise<void> {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    await this.activeObjectController.toggleSpaceClipping()
    this.refreshComputableState()
  }

  @action
  public removeObject(withGroup = false) {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    if (withGroup && this.activeObjectController.hasGroup()) {
      this.setActiveObject(this.activeObjectController.getGroup()!)
    }

    if (isActiveSelection(this.activeObject)) {
      for (const object of this.activeObject.getObjects()) {
        this.ee.emit(eventTree.productDriver.objectRemoved, object)
      }
    } else {
      this.ee.emit(eventTree.productDriver.objectRemoved, this.activeObject)
    }

    this.activeObjectController.remove()

    this.activeObject = null
    this.activeObjectController = null
    this.activeObjectType = null

    this.ee.emit(eventTree.keyboard.clearScope, "active-object")
  }

  @action
  public async duplicateObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController ||
      !this.activeObjectController.isDuplicatingAvailable()
    ) {
      return
    }

    const clonedObject = await this.activeObjectController.duplicate()
    this.canvas.setActiveObject(clonedObject)
    this.setActiveObject(clonedObject)
    this.activeObjectController.alignVertical()
    this.refreshComputableState()
  }

  @action
  public async groupObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController ||
      this.activeObjectType !== PackhelpGroupType.activeSelection
    ) {
      return
    }

    const controller = this.activeObjectController as ActiveSelectionController
    const group = await controller.group()

    this.canvas.setActiveObject(group)
    this.canvas.requestRenderAll()
    this.setActiveObject(group)
  }

  @action
  public async ungroupObject() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      !this.activeObject ||
      !this.activeObjectController
    ) {
      return
    }

    if (this.activeObjectController.hasGroup()) {
      this.setActiveObject(this.activeObjectController.getGroup()!)
    }

    if (
      !isAssetGroup(this.activeObject) ||
      !this.activeObject.groupController
    ) {
      return
    }

    const newSelection = new fabric.ActiveSelection([], {
      canvas: this.canvas,
    }) as unknown as PackhelpActiveSelection

    const objects = this.activeObject.groupController.getObjects()
    this.activeObject.groupController.ungroup()

    for (const object of objects) {
      newSelection.addWithUpdate(object)
    }

    this.canvas.setActiveObject(newSelection)
    this.canvas.requestRenderAll()
    this.setActiveObject(newSelection)
  }

  public toggleUniScaling() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.setStyles({
      lockUniScaling: !this.activeObject.lockUniScaling,
    })
  }

  @action
  public toggleImageBackground() {
    if (
      !this.activeObjectController ||
      !isImageObjectController(this.activeObjectController)
    ) {
      return
    }

    this.activeObjectController.toggleImageBackground()

    this.refreshComputableState()
  }

  @action
  public alignObjectHorizontal() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.alignHorizontal()
    this.refreshComputableState()
  }

  @action
  public alignObjectVertical() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.alignVertical()
    this.refreshComputableState()
  }

  @action
  public moveObjectLayerUp() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.moveLayerUp()
    this.refreshComputableState()
  }

  @action
  public moveObjectLayerDown() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObjectController.moveLayerDown()
    this.refreshComputableState()
  }

  private moveObject(direction: MovementDirection, step = 1) {
    if (!this.activeObjectController) {
      return
    }

    this.activeObjectController.move(direction, step)
    this.refreshComputableState()
  }

  @action
  private setActiveObject(object: ActiveObject) {
    if (!object) {
      return this.clearActiveObject()
    }

    if (object.maskParentId) {
      const canvas = object.canvas as PackhelpCanvas

      object = canvas
        .getObjects()
        .find((o) => o.id === object.maskParentId) as PackhelpEditableObject

      if (!object) {
        throw new Error("Can't find Parent object")
      }
    }

    this.activeObjectType = object.assetType || object.type
    this.activeObject = object

    const factory = new CanvasObjectControllerFactory(this.vdEditor)
    this.activeObjectController = factory.getController(object)

    this.refreshComputableState()

    this.ee.emit(eventTree.keyboard.setScope, "active-object")
    this.ee.emit(eventTree.activeObject.selected)
  }

  @action
  private clearActiveObject() {
    if (this.activeObject === null) {
      return
    }

    this.activeObject = null
    this.activeObjectController = null
    this.activeObjectType = null
    this.refreshComputableState()

    this.eventEmitter.emit(ActiveObjectEvent.cleared)
    this.ee.emit(eventTree.keyboard.clearScope, "active-object")
    this.ee.emit(eventTree.activeObject.deselected)
  }

  @action
  private refreshComputableState() {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.activeObject.setCoords()
    const objectBoundingBoxPosition = this.activeObject.getBoundingRect()
    this.activeObjectComputable.boundingTop = objectBoundingBoxPosition.top
    this.activeObjectComputable.boundingLeft = objectBoundingBoxPosition.left
    this.activeObjectComputable.boundingHeight =
      objectBoundingBoxPosition.height
    this.activeObjectComputable.boundingWidth = objectBoundingBoxPosition.width
    this.activeObjectComputable.height = this.activeObject.getScaledHeight()
    this.activeObjectComputable.width = this.activeObject.getScaledWidth()
    this.activeObjectComputable.isOverscaled = !!this.activeObject.isOverscaled
    this.activeObjectComputable.isMinScaleLimitReached =
      !!this.activeObject.isMinScaleLimitReached
    this.activeObjectComputable.isSafeZoneTouched =
      !!this.activeObject.isSafeZoneTouched
    this.activeObjectComputable.isSpaceEdgeCrossed =
      !!this.activeObject.isSpaceEdgeCrossed
    this.activeObjectComputable.isSpaceClippingDisabled =
      !!this.activeObject.isSpaceClippingDisabled
  }

  private attachEventListeners() {
    this.eventEmitter.on(
      ActiveObjectEvent.selected,
      this.setActiveObject.bind(this)
    )
    this.eventEmitter.on(
      ActiveObjectEvent.deselected,
      this.clearActiveObject.bind(this)
    )
    this.eventEmitter.on(ActiveObjectEvent.modified, () => {
      this.refreshComputableState()
      this.productDriver.setDesignTouched(true)
    })
    this.eventEmitter.on(
      "vdEditorResized",
      this.refreshComputableState.bind(this)
    )

    this.eventEmitter.on(ActiveObjectEvent.resized, () => {
      if (this.activeObject && isAssetImage(this.activeObject)) {
        this.ee.emit(
          GraphicAssetEvents.assetResized,
          this.activeObject.assetImageMeta,
          !!this.activeObject.isOverscaled
        )
      }
    })

    this.ee.on(eventTree.activeObject.delete, this.removeObject.bind(this))
    this.ee.on(eventTree.activeObject.moveUp, this.moveObject.bind(this, "up"))
    this.ee.on(
      eventTree.activeObject.moveDown,
      this.moveObject.bind(this, "down")
    )
    this.ee.on(
      eventTree.activeObject.moveLeft,
      this.moveObject.bind(this, "left")
    )
    this.ee.on(
      eventTree.activeObject.moveRight,
      this.moveObject.bind(this, "right")
    )
    this.ee.on(eventTree.activeObject.deselect, () => {
      this.vdEditor.assetsModule.clearActiveObject()
    })
  }

  private get vdEditor(): VirtualDielineEditor {
    const { renderEngine, activeContext } = this.productDriver.state

    if (!renderEngine) {
      throw new Error("Render engine is not ready")
    }

    return renderEngine.getVirtualDielineEditor(activeContext)
  }

  private get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }
}

export default ActiveObjectDriver
