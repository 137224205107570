import {
  PackhelpCanvas,
  PackhelpEditableObject,
  PackhelpMaskObject,
} from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { MaskConfig, MaskConfigExport, MaskType } from "./types"
import {
  getDefaultColorByMaskType,
  getDefaultMaskConfig,
  getDefaultMaskSize,
} from "./helpers"
import controllers from "./controllers"
import { MaskControllable } from "./controllers/mask-controllable.interface"
import { Shape } from "../../../../../../models/shape"
import { MaskTypeNotSupportedError } from "./errors"
import { Colour } from "../../../../../../models/colour"
import { ShapeHelper } from "../helpers/shape.helper"
import { JsonToFabricTransformer } from "../transformers/json-to-fabric.transformer"
import { isInteractiveCanvas } from "../../../../../../types/asset.types"

export class MaskController {
  private readonly config: MaskConfig
  private readonly controllers: MaskControllable[]
  private currentController: MaskControllable

  constructor(
    private maskParent: PackhelpEditableObject,
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    this.config = this.buildDefaultConfig()

    this.controllers = controllers.map((klass) => {
      return new klass(this.maskParent, this.virtualDielineEditor)
    })

    this.currentController = this.findCurrentController()
  }

  public changeParent(newParent: PackhelpEditableObject) {
    this.maskParent.maskConfig = undefined
    this.maskParent = newParent
    this.maskParent.maskConfig = this.currentController.getConfigExport()
    this.controllers.forEach((controller) => controller.changeParent(newParent))
  }

  public getType(): MaskType {
    return this.currentController.getType()
  }

  public getMask(): PackhelpMaskObject | undefined {
    return this.currentController.getMask()
  }

  public getConfig(): MaskConfig {
    return this.currentController.getConfig()
  }

  public getShape(): Shape {
    return new Shape(this.config.shape.assetObjectMeta)
  }

  public getColor(): Colour {
    return this.config.color
  }

  public async init(config: Partial<MaskConfig> = {}) {
    if (config.initialMargin !== undefined) {
      config.size = getDefaultMaskSize(this.maskParent, config.initialMargin)
    }

    this.updateConfig(config)
    this.setCurrentController()

    this.maskParent.set({
      maskController: this,
    })

    await this.currentController.init(this.config)
  }

  public async reinit() {
    this.currentController.clear()
    this.setCurrentController()
    await this.currentController.init(this.config)
  }

  public async load(configExport: MaskConfigExport) {
    const config = await this.buildConfigFromExport(configExport)
    this.updateConfig(config)
    this.setCurrentController()

    this.maskParent.set({
      maskController: this,
    })

    return await this.currentController.load(config)
  }

  public async changeShape(shape: Shape) {
    this.updateConfig({
      size: getDefaultMaskSize(this.maskParent, this.config.initialMargin),
      shape: await this.changeShapeToPackhelpObject(shape),
    })

    await this.reinit()
  }

  public async changeColor(color: Colour) {
    const currentControllerConfig = this.currentController.getConfig()

    this.updateConfig({
      size: currentControllerConfig.size,
      color: color,
    })

    await this.reinit()
  }

  public clear() {
    this.currentController.clear()
  }

  public dispose() {
    this.currentController.dispose()
  }

  public updatePosition() {
    this.currentController.updatePosition()
  }

  public updateScaleAndPosition() {
    this.currentController.updateScaleAndPosition()
  }

  public updateRotationAndPosition() {
    this.currentController.updateRotationAndPosition()
  }

  public selectMaskParent() {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    this.canvas.setActiveObject(this.maskParent)
  }

  private async buildConfigFromExport(
    configExport: MaskConfigExport
  ): Promise<MaskConfig> {
    if (configExport.type === MaskType.covering) {
      const mask = this.virtualDielineEditor.getCanvasObjectById(
        configExport.maskObjectId
      ) as PackhelpMaskObject

      return {
        shape: mask,
        color: new Colour(mask.fill as string, mask.fillPantoneName),
        size: {
          width: mask.getScaledWidth(),
          height: mask.getScaledHeight(),
        },
        assetClipping: configExport.assetClipping,
        minimalMargin: configExport.minimalMargin,
        isEditingDisabled: configExport.isEditingDisabled,
      }
    }

    const mask =
      await JsonToFabricTransformer.transformSingleObject<PackhelpMaskObject>(
        configExport.shape
      )

    return {
      shape: mask,
      color: new Colour(),
      size: configExport.size,
      assetClipping: configExport.assetClipping,
      minimalMargin: configExport.minimalMargin,
      isEditingDisabled: configExport.isEditingDisabled,
    }
  }

  private buildDefaultConfig(): MaskConfig {
    const config = getDefaultMaskConfig(this.maskParent)

    if (!this.isBackgroundClippingMaskAvailable()) {
      config.color = getDefaultColorByMaskType(MaskType.covering)
    }

    return config
  }

  private updateConfig(config: Partial<MaskConfig>) {
    Object.assign(this.config, config)
  }

  private isBackgroundClippingMaskAvailable(): boolean {
    return this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()
  }

  private setCurrentController() {
    this.currentController = this.findCurrentController()
  }

  private findCurrentController(): MaskControllable {
    const controller = this.controllers.find(
      (controller: MaskControllable) => controller.getType() === this.findType()
    )

    if (!controller) {
      throw new MaskTypeNotSupportedError(
        `Controller with type ${this.findType()} does not exist`
      )
    }

    return controller
  }

  private findType(): MaskType {
    if (
      this.config.color.isTransparent() &&
      (this.isBackgroundClippingMaskAvailable() || !!this.config.assetClipping)
    ) {
      return MaskType.clipping
    }

    return MaskType.covering
  }

  private async changeShapeToPackhelpObject(
    shape: Shape
  ): Promise<PackhelpMaskObject> {
    return (await ShapeHelper.shapeToPackhelpObject(
      shape
    )) as PackhelpMaskObject
  }

  public setVisibility(isVisible: boolean) {
    this.currentController.setMaskVisibility(isVisible)
  }

  private get canvas(): PackhelpCanvas {
    return this.virtualDielineEditor.fabricCanvas
  }
}
