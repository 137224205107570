import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import { AddLogoButton } from "../../AddLogoButton/AddLogoButton"
import styles from "./ThreeDimensionalScene.module.scss"
import ProductDriver from "../../../../../drivers/product.driver"
import { ModelPopover } from "../../ModelPopover/ModelPopover"

interface ThreeDimensionalScene {
  productDriver: ProductDriver
  onSceneMount: (mountPoint: HTMLDivElement) => void
}

export const ThreeDimensionalScene = observer(function ({
  productDriver,
  onSceneMount,
}: ThreeDimensionalScene) {
  const { activeContext } = productDriver.state
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref?.current) {
      onSceneMount(ref.current)
    }
  }, [])

  return (
    <>
      <div
        className={styles.wrapper}
        ref={ref}
        e2e-target="renderer-container"
        e2e-target-name={activeContext}
      />
      <AddLogoButton />
      <ModelPopover />
    </>
  )
})
