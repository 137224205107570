import { SocialAuthEnvConfig } from "../types"

export class SocialAuthConfig {
  private readonly facebook?: SocialAuthEnvConfig["facebook"]
  private readonly google?: SocialAuthEnvConfig["google"]

  constructor(config: SocialAuthEnvConfig = {}) {
    this.facebook = config.facebook
    this.google = config.google
  }
}
