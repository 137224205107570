import THREE from "../../../libs/vendors/THREE"
import {
  AvailableColourModes,
  CameraSettings,
  EditContext,
  ModelContext,
  SpaceId,
  TextureDefinition,
} from "../../../libs/products-render-config/types"

export function isMesh(object: THREE.Object3D): object is THREE.Mesh {
  return object instanceof THREE.Mesh && object.isMesh
}

export function isGroup(object: THREE.Object3D): object is THREE.Group {
  return object instanceof THREE.Group && object.isGroup
}

export function isObjModelConfig(
  config: ModelConfig
): config is ObjModelConfig {
  return config["objFilePath"]
}

export function isSvgModelConfig(
  config: ModelConfig
): config is SvgModelConfig {
  return config["svg"]
}

export interface ModelSettings {
  blendOpacity?: number
  colorMode?: AvailableColourModes
  cameraSettings?: Record<string, CameraSettings | undefined>
  anisotropy?: number
}

export interface ObjModelConfig {
  objFilePath: string
  textureDefinitions: TextureDefinition[]
  context: ModelContext
  settings: ModelSettings
}

export interface SvgModelConfig {
  svg: string
  textureDefinitions: TextureDefinition[]
  context: ModelContext
  settings: ModelSettings
}

export type ModelConfig = ObjModelConfig | SvgModelConfig

export interface ObjModelTexture {
  type: string
  texture: THREE.Texture | THREE.CubeTexture
}

export interface RendererSize {
  width: number
  height: number
}

export interface RendererConfig {
  mode: "default" | "screenshot" | "thumbnail"
  size: {
    width: number
    height: number
  }
}

export interface ThreeDimRendererEvent {
  object: {
    addEventListener: (...args) => void
    removeEventListener: (...args) => void
  }
  name: string
  fn: (...args) => void
}

export interface ObjModelHighlightDefinition {
  editContext?: EditContext
  x: number
  y: number
}

export interface SvgModelHighlightDefinition {
  editContext?: EditContext
  spaceId: SpaceId
}

export type ModelHighlightDefinition =
  | ObjModelHighlightDefinition
  | SvgModelHighlightDefinition
