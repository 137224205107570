export const locales = {
  LOCALE_PL: "pl-PL",
  LOCALE_EN: "en-EN",
  LOCALE_US: "en-US",
  LOCALE_DE: "de-DE",
  LOCALE_CZ: "cs-CZ",
  LOCALE_FR: "fr-FR",
  LOCALE_IT: "it-IT",
  LOCALE_ES: "es-ES",
  LOCALE_RO: "ro-RO",
  LOCALE_NL: "nl-NL",
  LOCALE_SE: "sv-SE",
  LOCALE_DEFAULT: "pl-PL",

  NOT_USED_IN_THIS_LANG: "#not_in_this_lang",
} as const
