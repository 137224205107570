import {
  PackhelpCanvas,
  PackhelpEditableObject,
} from "../../../object-extensions/packhelp-objects"
import { isEditableObject } from "../../../../../../types/asset.types"

export const getLayerIndex = (
  object: PackhelpEditableObject,
  canvas: PackhelpCanvas
): number => {
  return canvas.getObjects().findIndex((obj) => obj.id === object.id)
}

export const getEditableObjects = (
  canvas: PackhelpCanvas
): PackhelpEditableObject[] => {
  return canvas.getObjects().filter((object) => {
    return isEditableObject(object)
  }) as PackhelpEditableObject[]
}
