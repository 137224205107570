import React from "react"
import { observer } from "mobx-react-lite"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { TabSection } from "../../Tab/TabSection"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { ImageAssetItemStore } from "../../../../../../stores/image-asset-item.store"
import removeIconUrl from "../../../../../assets/_icons/trash.svg"
import { TileDropzone } from "../../../../atoms/TileDropzone/TileDropzone"
import { TileEmpty } from "../../../../atoms/Tile/TileEmpty"
import { Dropdown } from "../../../../atoms/Dropdown/Dropdown"
import { DropdownButton } from "../../../../atoms/Dropdown/DropdownButton"
import { UploadError } from "../../../../molecules/UploadError/UploadError"

const i18n = I18N.component.backgroundImage

export const BackgroundImageSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ui } = containerSet
  const { assetStore } = designAndProductDriver
  const { successBackgroundImageAssets, failedBackgroundImageAssets } =
    assetStore
  const { backgroundTabController } = ui
  const { selectedBackgroundImage, isBackgroundImageUploading } =
    backgroundTabController

  const onImageClick = (image: ImageAssetItemStore) => {
    backgroundTabController.setBackgroundImage(image)
  }

  const onClearClick = () => {
    backgroundTabController.clearBackgroundImage()
  }

  const onImageDrop = (files: File[]) => {
    backgroundTabController.uploadAndSetBackgroundColor(files[0])
  }

  const onRemoveImageClick = (image: ImageAssetItemStore) => {
    backgroundTabController.removeImage(image)
  }

  return (
    <TabSection title={t(i18n.title)}>
      <TileList>
        <TileEmpty
          onClick={onClearClick}
          active={!selectedBackgroundImage}
          label={t(i18n.noImageLabel)}
          analyticsSection="left-tab-background"
          analyticsType="thumbnail"
          analyticsName="no-background-image"
        />

        <TileDropzone
          label={t(i18n.addImage)}
          onDrop={onImageDrop}
          loading={isBackgroundImageUploading}
          analyticsSection="left-tab-background"
          analyticsType="thumbnail"
          analyticsName="add-background-image"
        />
      </TileList>

      {failedBackgroundImageAssets.map((image) => {
        return <UploadError filename={image.name} error={image.error!} />
      })}

      <TileList>
        {successBackgroundImageAssets.map((image) => {
          return (
            <Tile
              key={image.name}
              onClick={() => onImageClick(image)}
              active={image === selectedBackgroundImage}
              contextMenu={
                <Dropdown>
                  <DropdownButton
                    label={t(i18n.remove.file)}
                    iconUrl={removeIconUrl}
                    onClick={() => onRemoveImageClick(image)}
                    analyticsSection="left-tab-background"
                    analyticsType="context-menu"
                    analyticsName="remove"
                    analyticsId="background-image"
                  />
                </Dropdown>
              }
              analyticsSection="left-tab-background"
              analyticsType="thumbnail"
              analyticsName="background-image"
            >
              <figure>
                <img src={image.previewUri} alt={image.name} />
              </figure>
            </Tile>
          )
        })}
      </TileList>
    </TabSection>
  )
})
