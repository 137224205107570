var SkuProductModel;
(function (SkuProductModel) {
    SkuProductModel["SKU_MODEL_PRE_PRINTED_MAILER_BOX_ECO"] = "pre-printed-mailer-box-eco";
    SkuProductModel["SKU_MODEL_PRE_PRINTED_MAILER_BOX_FULL_COLOUR"] = "pre-printed-mailer-box-full-colour";
    SkuProductModel["SKU_MODEL_MAILER_BOX"] = "mailer-box";
    SkuProductModel["SKU_MODEL_MAILER_BOX_WHITE_INK"] = "mailer-box-white-ink";
    SkuProductModel["SKU_MODEL_DELIVERY_MAILER_BOX"] = "delivery-mailer-box";
    SkuProductModel["SKU_MODEL_DELIVERY_MAILER_BOX_PLAIN"] = "delivery-mailer-box-plain";
    SkuProductModel["SKU_MODEL_MAILER_BOX_PLAIN"] = "mailer-box-plain";
    SkuProductModel["SKU_MODEL_SLEEVED_MAILER_BOX"] = "sleeved-mailer-box";
    SkuProductModel["SKU_MODEL_PRODUCT_BOX"] = "product-box";
    SkuProductModel["SKU_MODEL_PRODUCT_BOX_TWO_PIECES"] = "product-box-two-pieces";
    SkuProductModel["SKU_MODEL_PRODUCT_BOX_WINE_BOX"] = "product-box-wine-box";
    SkuProductModel["SKU_MODEL_SHIPPING_BOX"] = "shipping-box";
    SkuProductModel["SKU_MODEL_SHIPPING_BOX_PLAIN"] = "shipping-box-plain";
    SkuProductModel["SKU_MODEL_SHIPPING_BOX_PLAIN_V2"] = "shipping-box-plain-v-2";
    SkuProductModel["SKU_MODEL_SAMPLE_PACK"] = "samples";
})(SkuProductModel || (SkuProductModel = {}));
export default SkuProductModel;
