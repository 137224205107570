var NcpMaterial;
(function (NcpMaterial) {
    NcpMaterial["PRINT_MATERIAL_PINE_SPRUCE"] = "pineSpruce";
    NcpMaterial["PRINT_MATERIAL_FOIL_LDPE"] = "foilLdpe";
    NcpMaterial["PRINT_MATERIAL_PAPER_GLOSS"] = "paperGloss";
    NcpMaterial["PRINT_MATERIAL_PAPER_LINEN"] = "paperLinen";
    NcpMaterial["PRINT_MATERIAL_BROWN"] = "brown";
    NcpMaterial["PRINT_MATERIAL_WHITE"] = "white";
})(NcpMaterial || (NcpMaterial = {}));
export default NcpMaterial;
