import THREE from "../../../../libs/vendors/THREE"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { CameraSettings } from "../../../../libs/products-render-config/types"

const CAMERA_F112 = {
  x: -569.9311456718135,
  y: 308.63843667581494,
  z: 499.4751077414836,
}

const SIZE_F23 = {
  x: 93.99679946899414,
  y: 52.50529885292053,
  z: 94.57389831542969,
  minZoom: 180,
}

const SIZE_F112 = {
  x: 521.6600036621094,
  y: 184.11970725655556,
  z: 421.5080108642578,
  minZoom: 250,
}

//TODO: rewrite it
export class CameraPositionCalculator {
  constructor(
    private object: THREE.Object3D | THREE.Group,
    private mesh: THREE.Mesh,
    private cameraSettings?: CameraSettings
  ) {}

  public setCameraPosition(orbitControls: OrbitControls) {
    this.setObjectPosition()

    if (this.cameraSettings) {
      const { cameraPosition, lookAtPoint } = this.cameraSettings

      orbitControls.object.position.set(
        cameraPosition.x,
        cameraPosition.y,
        cameraPosition.z
      )
      orbitControls.target = new THREE.Vector3(
        lookAtPoint.x,
        lookAtPoint.y,
        lookAtPoint.z
      )
    } else {
      const cameraPosition = this.calcCameraRelativeToObject()
      orbitControls.object.position.copy(cameraPosition)
      orbitControls.object.lookAt(orbitControls.target)
    }
  }

  private getObjectSize(): THREE.Vector3 {
    const box = new THREE.Box3().setFromObject(this.mesh)
    return box.getSize(new THREE.Vector3())
  }

  private setObjectPosition(): void {
    const size = this.getObjectSize()
    this.object.position.set(0, (size.y / 2) * -1, 0)
    this.object.updateMatrix()
  }

  private calcCameraRelativeToObject(): THREE.Vector3 {
    const size = this.getObjectSize()
    // Why these sizes?
    const sizeRatioX = (SIZE_F112.x - SIZE_F23.x) / size.x
    const sizeRatioY = (SIZE_F112.y - SIZE_F23.y) / size.y
    const sizeRatioZ = (SIZE_F112.z - SIZE_F23.z) / size.z
    return new THREE.Vector3(
      CAMERA_F112.x / sizeRatioX,
      CAMERA_F112.y / sizeRatioY,
      CAMERA_F112.z / sizeRatioZ
    )
  }
}
