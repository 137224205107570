import {
  EditContext,
  ModelContext,
  SpaceId,
  RenderConfig,
  EditableSpaceConfig,
  ViewMode,
  NonEditableSpaceConfig,
  CameraSettings,
} from "../types"

export class RenderConfigReader {
  public constructor(private readonly config: RenderConfig) {}

  public getContextEditableSpaceIds(editContext: EditContext): SpaceId[] {
    return this.getContextEditableSpaceConfigs(editContext).map(
      ({ spaceId }) => spaceId
    )
  }

  public getContextEditableSpaceConfigs(
    editContext: EditContext
  ): EditableSpaceConfig[] {
    const spaces = this.getContextSpaceConfigs(editContext)

    return spaces.filter((space) => space.editable !== false)
  }

  public getDefaultSpace(editContext: EditContext): SpaceId {
    const allSpaces = this.getContextSpaceConfigs(editContext)
    const defaultSpace = allSpaces.find((obj) => !!obj.isDefault)

    if (!defaultSpace) {
      return allSpaces[0].spaceId
    }

    return defaultSpace.spaceId
  }

  public getSpaceConfig(
    context: EditContext,
    spaceId: SpaceId
  ): EditableSpaceConfig {
    let space: EditableSpaceConfig | NonEditableSpaceConfig | undefined =
      this.getContextSpaceConfigs(context).find(
        (obj) => obj.spaceId === spaceId
      )

    if (!space) {
      this.getContextSpaceConfigs(context).forEach((obj) => {
        const childSpace = obj.children?.find(
          (child) => child.spaceId === spaceId
        )
        if (childSpace) {
          space = childSpace
        }
      })
    }

    if (!space) {
      throw new Error(
        `Can't find provided space: ${spaceId} for context: ${context}`
      )
    }

    return {
      ...space,
      children: space.children || [],
    }
  }

  public getAvailableEditContexts(): EditContext[] {
    return this.config.availableEditContexts
  }

  public getDefaultEditContext(): EditContext {
    return this.getAvailableEditContexts()[0]
  }

  public getThumbCameraSettings(
    modelContext: ModelContext
  ): CameraSettings | undefined {
    return this.config.thumbCameraSettings?.[modelContext]
  }

  public getCameraSettings(
    modelContext: ModelContext
  ): CameraSettings | undefined {
    const config = this.config.cameraSettings || {}

    return config[modelContext] || Object.values(config)[0]
  }

  public hasObjModel(modelContext: ModelContext): boolean {
    return !!this.getObjModelPath(modelContext)
  }

  public getObjModelPath(modelContext: ModelContext): string | undefined {
    return this.config.objModelsPaths?.[modelContext]
  }

  public getMaterialPath(): string | undefined {
    return this.config.objMaterialPath
  }

  public getAvailableViewModes(): ViewMode[] {
    return this.config.availableViewModes
  }

  public getContextSpaceConfigs(
    editContext: EditContext
  ): EditableSpaceConfig[] {
    return this.config.availableEditContextsMap[editContext] || []
  }
}
