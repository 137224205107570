import React from "react"
import { CheckCircle } from "../../../../assets/_svgr/UI/CheckCircle"
import styles from "./PickerOptionIndicator.module.scss"

interface PickerOptionIndicatorProps {
  value: boolean
}
export const PickerOptionIndicator = (props: PickerOptionIndicatorProps) => {
  const { value } = props

  return (
    <div className={styles["indicator"]}>
      {value && <CheckCircle className={styles["icon"]} />}
    </div>
  )
}
