import React from "react"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { ListingSection } from "./partials/ListingSection"
import { CategoriesSection } from "./partials/CategoriesSection"

export function TabProduct() {
  const t = useTranslate()

  return (
    <Tab title={t(I18N.tabs.product)} size="wide" e2eTargetName="product">
      <CategoriesSection />
      <ListingSection />
    </Tab>
  )
}
