import {
  EditContext,
  ModelContext,
  SpaceId,
  ViewType,
} from "../../libs/products-render-config/types"
import { Shape } from "../../models/shape"
import { CameraAngle } from "../../libs/products-render-config/types"
import { PackhelpObject } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import Colour from "../../models/colour"

// Events
export enum ProductDriverEvents {
  changedDielinePosition = "ph.productDriver.changedDielinePosition",
  renderEngineInitiated = "ph.productDriver.renderEngine.initiated",
  renderEngineDeregistered = "ph.productDriver.renderEngine.deregistered",
  renderEngineRefreshed = "ph.productDriver.renderEngine.refreshed",
  spaceSelectedForEdit = "ph.productDriver.spaceSelectedForEdit",
  spaceSelectedForAsset = "ph.productDriver.spaceSelectedForAsset",
  spaceHighlighted = "ph.productDriver.spaceHighlighted",
  escapeEditModeEnded = "ph.productDriver.escapeEditModeEnded",
  showSpaceStarted = "ph.productDriver.showSpaceStarted",
  showSpaceEnded = "ph.productDriver.showSpaceEnded",
  showDielineStarted = "ph.productDriver.showDielineStarted",
  showDielineEnded = "ph.productDriver.showDielineEnded",
  backgroundLayersRefreshed = "ph.productDriver.backgroundLayersRefreshed",
  productChanged = "ph.productDriver.product.changed",
  productChangeApplied = "ph.productDriver.product.applied",
  dbyEngineInitiated = "ph.productDriver.dbyEngine.initiated",
  backgroundChanged = "ph.productDriver.background.changed",
  objectRemoved = "ph.productDriver.object.removed",
  touchDesign = "ph.productDriver.touchDesign",
  designTouched = "ph.productDriver.designTouched",
  viewChanged = "ph.productDriver.viewChanged",
  modeChanged = "ph.productDriver.modeChanged",
  maskToggled = "ph.productDriver.mask.toggled",
  maskModified = "ph.productDriver.mask.modified",
  zoomDieline = "ph.productDriver.dieline.zoom",
}

export type ProductDriverTypes = {
  [ProductDriverEvents.changedDielinePosition]: ({
    activeSpace,
    activeContext,
  }: {
    activeSpace: SpaceId | null
    activeContext: EditContext
  }) => void
  [ProductDriverEvents.renderEngineInitiated]: () => void
  [ProductDriverEvents.dbyEngineInitiated]: () => void
  [ProductDriverEvents.renderEngineDeregistered]: () => void
  [ProductDriverEvents.renderEngineRefreshed]: () => void
  [ProductDriverEvents.objectRemoved]: (object: PackhelpObject) => void
  [ProductDriverEvents.touchDesign]: () => void
  [ProductDriverEvents.designTouched]: () => void
  [ProductDriverEvents.viewChanged]: ({
    space,
    context,
    viewType,
    previousSpace,
  }: {
    space: CameraAngle
    context: EditContext
    viewType: ViewType
    previousSpace?: CameraAngle
  }) => void
  [ProductDriverEvents.modeChanged]: ({
    modelContext,
  }: {
    modelContext: ModelContext
  }) => void
  [ProductDriverEvents.maskToggled]: (
    assetType: PackhelpObject,
    maskApplied: boolean
  ) => void
  [ProductDriverEvents.maskModified]: (
    assetType: PackhelpObject,
    shape: Shape,
    color: Colour
  ) => void
  [ProductDriverEvents.spaceSelectedForEdit]: ({
    spaceId,
    editContext,
  }: {
    spaceId: SpaceId
    editContext: EditContext
  }) => void
  [ProductDriverEvents.spaceSelectedForAsset]: ({
    spaceId,
    editContext,
  }: {
    spaceId: SpaceId
    editContext: EditContext
  }) => void
  [ProductDriverEvents.spaceHighlighted]: (space, context) => void
  [ProductDriverEvents.escapeEditModeEnded]: () => void
  [ProductDriverEvents.showSpaceStarted]: () => void
  [ProductDriverEvents.showSpaceEnded]: (spaceId: SpaceId) => void
  [ProductDriverEvents.showDielineStarted]: () => void
  [ProductDriverEvents.showDielineEnded]: () => void
  [ProductDriverEvents.backgroundLayersRefreshed]: () => void
  [ProductDriverEvents.productChanged]: () => void
  [ProductDriverEvents.productChangeApplied]: () => void
  [ProductDriverEvents.backgroundChanged]: () => void
  [ProductDriverEvents.zoomDieline]: (zoom: number) => void
}
