import { Axis, EdgeType, ObjectEdge, SnappingCache } from "../types"
import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { RotationHelper } from "../../helpers/rotation.helper"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { getBoundingRect } from "../helpers/get-bounding-rect"

export class ScaleSnapper {
  private cache?: SnappingCache

  public clear(): void {
    this.cache = undefined
  }

  public snap(
    object: PackhelpObject,
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): void {
    const { axis } = nextEdge

    if (this.cache) {
      return this.snapFromCache(object, axis)
    }

    const newScale = this.calculateNewScale(object, currentEdge, nextEdge)

    const scaledWidth = object.width! * object.scaleX!
    const scaledHeight = object.height! * object.scaleY!
    const newScaledWidth = object.width! * newScale
    const newScaledHeight = object.height! * newScale

    const angle = object.angle!
    const centerPoint = object.getCenterPoint()
    const startPointNoRotation = RotationHelper.rotatePoint(
      new fabric.Point(object.left!, object.top!),
      centerPoint,
      -angle
    )

    const newLeftNoRotation =
      startPointNoRotation.x - (newScaledWidth - scaledWidth) / 2
    const newTopNoRotation =
      startPointNoRotation.y - (newScaledHeight - scaledHeight) / 2

    const { x: newLeft, y: newTop } = RotationHelper.rotatePoint(
      new fabric.Point(newLeftNoRotation, newTopNoRotation),
      centerPoint,
      angle
    )

    object.set({
      top: newTop,
      left: newLeft,
      scaleX: newScale,
      scaleY: newScale,
    })

    this.cache = {
      axis,
      top: newTop,
      left: newLeft,
      scale: newScale,
    }
  }

  private snapFromCache(object: PackhelpObject, axis: Axis): void {
    if (!this.cache || this.cache.axis !== axis) {
      return
    }

    const { scale, top, left } = this.cache

    object.set({
      top,
      left,
      scaleX: scale,
      scaleY: scale,
    })
  }

  private calculateNewScale(
    object: PackhelpObject,
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): number {
    const { axis } = nextEdge
    const currentStart = currentEdge.points.start[axis]
    const nextStart = nextEdge.points.start[axis]
    const diff = Math.abs(nextStart - currentStart)
    const boundingRect = getBoundingRect(object)
    const multiplier = this.getMultiplier(
      currentEdge.type,
      currentStart,
      nextStart
    )

    if (axis === "x") {
      const newBoundingWidth = boundingRect.width + 2 * diff * multiplier
      const newRatio = newBoundingWidth / boundingRect.width

      return object.scaleX! * newRatio
    }

    const newBoundingHeight = boundingRect.height + 2 * diff * multiplier
    const newRatio = newBoundingHeight / boundingRect.height

    return object.scaleY! * newRatio
  }

  private getMultiplier(
    edgeType: EdgeType,
    currentStart: number,
    nextStart: number
  ): number {
    if (["right", "bottom"].includes(edgeType) && currentStart < nextStart) {
      return 1
    }

    if (["left", "top"].includes(edgeType) && currentStart > nextStart) {
      return 1
    }

    return -1
  }
}
