import React from "react"
import { observer } from "mobx-react-lite"
import { TabSection } from "../../Tab/TabSection"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { I18N } from "../../../../../i18n"
import { Category } from "shared-libs/src/js/libs/services/listing-services/utils/listing-services.types"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { OptionPickerList } from "../../../../atoms/OptionPicker/OptionPickerList"
import { OptionPicker } from "../../../../atoms/OptionPicker/OptionPicker"
import { SkeletonTileList } from "../../../../atoms/Skeleton/SkeletonTileList"
import { Skeleton } from "../../../../atoms/Skeleton/Skeleton"
import { SkeletonOptionPickerList } from "../../../../atoms/Skeleton/SkeletonOptionPickerList"

const i18n = I18N.editorTools.productSwitcher

export const CategoriesSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.productSwitcher])

  if (!containerSet) {
    return null
  }

  const { productSwitcher } = containerSet

  if (!productSwitcher.available) {
    return null
  }

  const { listingStore } = productSwitcher
  const { selectedCategory, categories } = listingStore

  const onCategoryClick = (category: Category) => {
    listingStore.setSelectedCategory(category)
  }

  if (categories.length === 0) {
    return (
      <>
        <TabSection>
          <SkeletonOptionPickerList repeats={7} />
        </TabSection>
        <TabSection>
          <SkeletonTileList repeats={12} cols={3} />
        </TabSection>
      </>
    )
  }

  return (
    <TabSection title={t(i18n.category)}>
      <OptionPickerList>
        {listingStore.categories.map((category) => {
          const { slug, title, name } = category

          return (
            <OptionPicker
              key={slug}
              label={slug === "all" ? t(title) : title}
              onClick={() => onCategoryClick(category)}
              active={selectedCategory.id === category.id}
              analyticsSection="left-tab-product"
              analyticsType="option-picker"
              analyticsName="category"
              analyticsId={name}
            />
          )
        })}
      </OptionPickerList>
    </TabSection>
  )
})
