import { AxiosInstance } from "axios"
import { DirectusShape } from "../types"

export class DirectusShapeApi {
  constructor(private ax: AxiosInstance) {}

  public async getShapes(): Promise<DirectusShape[]> {
    const response = await this.ax.get<{ data: DirectusShape[] }>("shapes", {
      params: {
        limit: -1,
      },
    })

    return response.data.data
  }
}
