export type ColourSettings = {
  hexColour: string
  pantoneName?: string
}

class Colour {
  private readonly hex
  private readonly pantoneName

  constructor(hex?: string, pantoneName?: string) {
    this.hex = hex
    this.pantoneName = pantoneName
  }

  getColor(): string {
    return this.hex
  }

  getRgb(): string {
    return this.hex
  }

  getHex(): string {
    return this.hex
  }

  isTransparent(): boolean {
    return !this.getHex() && !this.getPantoneName()
  }

  isColorPantone(): boolean {
    return !!this.getPantoneName()
  }

  getPantoneName(): string {
    return this.pantoneName
  }

  toSource() {
    return {
      hex: this.hex,
      pantoneName: this.pantoneName,
    }
  }

  copy() {
    const { hex, pantoneName } = this.toSource()
    return new Colour(hex, pantoneName)
  }

  getSettings(): ColourSettings {
    return {
      hexColour: this.getHex(),
      pantoneName: this.getPantoneName(),
    }
  }
}

const randomNum = () => {
  return Math.floor(Math.random() * 256)
}

const randomRGB = () => {
  var red = randomNum()
  var green = randomNum()
  var blue = randomNum()
  return `rgb(${red},${green},${blue})`
}

export { Colour, randomRGB }
export default Colour
