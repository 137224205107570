import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../Tab/TabSection"
import { FormGroup } from "../../../molecules/FormGroup/FormGroup"
import { Slider } from "dsl/src/atoms/Slider/Slider"
import { TextEffectsSection } from "./partials/TextEffectsSection"

const i18n = I18N.components.editorToolText

export const TabTextOptions = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { textOptionsTabController } = ui
  const { lineHeight, lineHeightConfig, letterSpacing, letterSpacingConfig } =
    textOptionsTabController

  const onLineHeightChange = (lineHeight: number) => {
    textOptionsTabController.setLineHeight(lineHeight)
  }

  const onLetterSpacingChange = (letterSpacing: number) => {
    textOptionsTabController.setLetterSpacing(letterSpacing)
  }

  return (
    <Tab title={t(I18N.tabs.textOptions)} e2eTargetName="text-options">
      <TabSection>
        <FormGroup label={t(i18n.label.fontLineHeight)} inline>
          <Slider
            value={lineHeight}
            step={lineHeightConfig.step}
            min={lineHeightConfig.min}
            max={lineHeightConfig.max}
            onChange={onLineHeightChange}
          />
        </FormGroup>
        <FormGroup label={t(i18n.label.fontLetterSpacing)} inline>
          <Slider
            value={letterSpacing}
            step={letterSpacingConfig.step}
            min={letterSpacingConfig.min}
            max={letterSpacingConfig.max}
            onChange={onLetterSpacingChange}
          />
        </FormGroup>
      </TabSection>

      <TextEffectsSection />
    </Tab>
  )
})
