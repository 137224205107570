export const DefaultItemsPerPage = 22
export const MinPageNumber = 1

export enum UrlParamsSlug {
  Category = "category",
  Page = "pg", // 'page' and 'p' are forbidden in WP
}

export const UrlDelimiter = "/"

export const NavigationItemDropdown = "dropdown"
export const NavigationItemSimpleLink = "simple-link"
export const NavigationItemTitleWithImage = "tile-with-image"
export const NavigationItemHeaderWithCta = "header-with-cta"
