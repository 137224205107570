import { IntegrationsEnvConfig } from "../types"

export class IntegrationsConfig {
  public readonly rudderstack?: IntegrationsEnvConfig["rudderstack"]
  public readonly sentry?: IntegrationsEnvConfig["sentry"]
  public readonly configCat?: IntegrationsEnvConfig["configCat"]

  constructor(config: IntegrationsEnvConfig = {}) {
    this.rudderstack = config.rudderstack
    this.sentry = config.sentry
    this.configCat = config.configCat
  }
}
