import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { FontFamilyPreview } from "./FontFamilyPreview"
import {
  LOADABLE_FONTS_CONFIGS,
  Fonts,
} from "../../../../libs/services/fonts-loader-service/fonts-loader.config"
import { FontFamilyDefinition } from "../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { useHighlightFont } from "../../../hooks/useHighlightFont"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./FontList.module.scss"
import { AnalyticsSection } from "../../types"

interface FontListProps {
  onClick: (fontFamilyDefinition: FontFamilyDefinition) => void
  selectedFontFamily: Fonts
  analyticsSection?: AnalyticsSection
}

export const FontList = observer(function ({
  onClick,
  selectedFontFamily,
  analyticsSection,
}: FontListProps) {
  const { highlightedFontStore } = useHighlightFont({
    onClick,
    selectedFontFamily,
  })

  const selectedRef = useRef<HTMLDivElement>(null)

  const scrollToOnHighlight = (domElement: HTMLElement) => {
    domElement.scrollIntoView({
      behavior: "auto",
      block: "nearest",
    })
  }

  useEffect(() => {
    if (!selectedRef.current) {
      return
    }

    const scrollableContainer = selectedRef.current.parentElement?.parentElement

    if (!scrollableContainer) {
      return
    }

    scrollableContainer.scrollTop = selectedRef.current.offsetTop
  }, [])

  const [uiContainer] = useContainer().ui

  if (!uiContainer) {
    return null
  }

  const { fontFamiliesList } = uiContainer.fontsConfigStore

  return (
    <div className={styles.wrapper}>
      {fontFamiliesList.map((definition) => {
        const isActive = selectedFontFamily === definition.name
        const isHighlighted =
          highlightedFontStore.highlightedFont === definition

        return (
          <div
            style={{
              fontFamily: definition.id,
            }}
            key={definition.id}
          >
            <FontFamilyPreview
              innerRef={isActive ? selectedRef : undefined}
              label={LOADABLE_FONTS_CONFIGS[definition.id].name}
              active={isActive}
              highlighted={isHighlighted}
              onMouseEnter={() =>
                highlightedFontStore.setHighlightedFont(definition)
              }
              onClick={() => {
                onClick(definition)
              }}
              onHighlight={scrollToOnHighlight}
              analyticsSection={analyticsSection}
            />
          </div>
        )
      })}
    </div>
  )
})
