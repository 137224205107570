import { isServerProduction } from "../../../configs/region-config/interfaces/js/env-config"

declare global {
  interface Window {
    Sentry: any
  }
}

class SentryWrapper {
  private sentry: any
  private isServerProduction: boolean

  constructor() {
    this.isServerProduction = isServerProduction()

    if (typeof window === "undefined") return
    this.sentry = window.Sentry
  }

  public captureException = (exception, safeMode = false) => {
    if (this.sentry) {
      this.sentry.captureException(exception)
    }

    if (!this.isServerProduction && !safeMode) {
      throw exception
    }
  }

  public captureMessage = (exception) => {
    if (this.sentry) {
      this.sentry.captureMessage(exception)
      return
    }

    if (!this.isServerProduction) {
      throw exception
    }
  }
}

export const Sentry = new SentryWrapper()
