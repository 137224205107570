/** @format */
// product sku def
import SkuFinish from "./paperTubeSkuDefinitions/SkuFinish";
import SkuMaterial from "./paperTubeSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./paperTubeSkuDefinitions/SkuPrintMode";
import SkuModel from "./paperTubeSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./paperTubePartsDefinitions/PartFinish";
import PartMaterial from "./paperTubePartsDefinitions/PartMaterial";
import PartPrintMode from "./paperTubePartsDefinitions/PartPrintMode";
import PartModel from "./paperTubePartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var PAPER_TUBE_CONSTS = {
    SKU_BASE: "paper-tube-",
    PRODUCT_TYPE: "paperCan",
    DEFAULT_LINE_ITEM_RANGES: [
        30, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500,
    ],
};
export default Object.assign({}, PAPER_TUBE_CONSTS, SkuFinish, SkuMaterial, SkuPrintMode, SkuModel, PartFinish, PartMaterial, PartPrintMode, PartModel, Product, ProductVariant);
