import React, {
  forwardRef,
  useImperativeHandle,
  PropsWithChildren,
} from "react"
import { useDropzone } from "react-dropzone"
import uploadIconUrl from "../../../assets/_icons/upload.svg"
import errorIconUrl from "../../../assets/_icons/error.svg"
import styles from "./Dropzone.module.scss"
import cxBinder from "classnames/bind"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

const i18n = I18N.component.assetsUploader

const cx = cxBinder.bind(styles)

export interface DropzoneRef {
  open: () => void
}

export interface DropzoneProps extends AnalyticsProps, TestsProps {
  onDrop: (files: File[]) => void
  onDragLeave?: () => void
  state?: "initial" | "uploading" | "error"
  multiple?: boolean
  label?: string
}

type DropzonePropsWithChildren = PropsWithChildren<DropzoneProps>

export const Dropzone = forwardRef<React.ReactNode, DropzonePropsWithChildren>(
  function (
    {
      children,
      onDrop,
      onDragLeave,
      state = "initial",
      multiple = false,
      label,
      analyticsSection,
      analyticsType = "dropzone",
      analyticsName,
      analyticsId,
      e2eTarget = "dropzone",
      e2eTargetName,
    }: DropzonePropsWithChildren,
    ref
  ) {
    const disabled = !!children

    const t = useTranslate()
    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      onDragLeave,
      noClick: disabled,
      noDrag: disabled,
      multiple,
    })

    useImperativeHandle(ref, () => ({
      open,
    }))

    return (
      <div
        className={cx("dropzone", {
          disabled,
        })}
        {...getRootProps()}
        analytics-section={analyticsSection}
        analytics-type={analyticsType}
        analytics-name={analyticsName}
        analytics-id={analyticsId}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <input {...getInputProps()} />
        {children ? (
          <div className={styles.preview}>{children}</div>
        ) : (
          <div
            className={cx("state", {
              [state]: true,
            })}
          >
            {state === "initial" && (
              <>
                <SvgIcon url={uploadIconUrl} />
                <span>{label || t(i18n.uploadFile)}</span>
              </>
            )}
            {state === "uploading" && (
              <>
                <div className={styles.loader}>
                  <Loader
                    type={LoaderTypes.circular}
                    color={LoaderColors.gray}
                    e2eTarget="loader"
                    e2eTargetName="upload"
                  />
                </div>
                <span>{t(i18n.uploading)}</span>
              </>
            )}
            {state === "error" && (
              <>
                <SvgIcon url={errorIconUrl} />
                <span>{t(i18n.error)}</span>
              </>
            )}
          </div>
        )}
      </div>
    )
  }
)
