export var PolyMailerProductVariant;
(function (PolyMailerProductVariant) {
    PolyMailerProductVariant["POLY_MAILER_VARIANT_STANDARD"] = "polyMailer";
    PolyMailerProductVariant["POLY_MAILER_PIM_VARIANT"] = "classic-poly-mailer--286";
})(PolyMailerProductVariant || (PolyMailerProductVariant = {}));
export var PolyMailerBioProductVariant;
(function (PolyMailerBioProductVariant) {
    PolyMailerBioProductVariant["POLY_MAILER_BIO_VARIANT_STANDARD"] = "bioPolyMailer";
    PolyMailerBioProductVariant["POLY_MAILER_BIO_PLAIN_VARIANT_STANDARD"] = "bioPolyMailerPlain";
    PolyMailerBioProductVariant["POLY_MAILER_BIO_PIM_VARIANT"] = "bio-poly-mailer--284";
})(PolyMailerBioProductVariant || (PolyMailerBioProductVariant = {}));
export var PolyMailerRecycledProductVariant;
(function (PolyMailerRecycledProductVariant) {
    PolyMailerRecycledProductVariant["POLY_MAILER_RECYCLED_VARIANT_STANDARD"] = "recycledPolyMailer";
    PolyMailerRecycledProductVariant["POLY_MAILER_RECYCLED_PIM_VARIANT"] = "recycled-poly-mailer--287";
})(PolyMailerRecycledProductVariant || (PolyMailerRecycledProductVariant = {}));
export default Object.assign({}, PolyMailerProductVariant, PolyMailerBioProductVariant, PolyMailerRecycledProductVariant);
