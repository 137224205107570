import _cloneDeep from "lodash/cloneDeep"
import { BoundingBox, Point, Size } from "./types"
import { BoundingBoxCalculator } from "./bounding-box.calculator"
import { SvgPath } from "./svg-elements/svg-path"

export class Resizer {
  public constructor(private readonly elements: SvgPath[]) {}

  public call(viewBox: Size): { elements: SvgPath[]; size: Size } {
    const elements = _cloneDeep(this.elements)
    const boundingBox = this.getBoundingBox()
    const { center } = boundingBox

    const newSize = this.calculateNewSize(viewBox, boundingBox)
    const ratio = this.calculateRatio(viewBox, boundingBox)
    const offset = this.calculateOffset(boundingBox, newSize)

    for (const element of elements) {
      element.resize(center, ratio, offset)
    }

    return {
      elements,
      size: newSize,
    }
  }

  private calculateRatio(viewBox: Size, boundingBox: Size): number {
    if (boundingBox.width >= boundingBox.height) {
      return viewBox.width / boundingBox.width
    }

    return viewBox.height / boundingBox.height
  }

  private calculateOffset(boundingBox: BoundingBox, newSize: Size): Point {
    return {
      x:
        newSize.width / 2 -
        boundingBox.start.x -
        (boundingBox.end.x - boundingBox.start.x) / 2,
      y:
        newSize.height / 2 -
        boundingBox.start.y -
        (boundingBox.end.y - boundingBox.start.y) / 2,
    }
  }

  private calculateNewSize(viewBox: Size, boundingBox: Size): Size {
    if (boundingBox.width >= boundingBox.height) {
      const newHeight = (viewBox.width / boundingBox.width) * boundingBox.height

      return {
        width: viewBox.width,
        height: Math.ceil(newHeight),
      }
    }

    const newWidth = (viewBox.height / boundingBox.height) * boundingBox.width
    return {
      height: viewBox.height,
      width: Math.ceil(newWidth),
    }
  }

  private getBoundingBox(): BoundingBox {
    return new BoundingBoxCalculator(this.elements).call()
  }
}
