import React from "react"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { Error } from "../Error/Error"

const i18n = I18N.errors["500"]

export function Error500() {
  const t = useTranslate()

  const onClick = () => {
    window.location.reload()
  }

  return (
    <Error
      title={t(i18n.title)}
      description={t(i18n.description)}
      link={{ label: t(i18n.button), onClick: onClick }}
    />
  )
}
