import { DielineGenerator } from "./dieline.generator"
import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"
import { ProductManager } from "@ph/product-api"
import { DbyDielineType } from "../../../libs/products-render-config/types"

export class DbyDielineUrlProvider {
  constructor(
    private readonly dielineGenerator: DielineGenerator,
    private readonly productRegion: string
  ) {}

  public async get(
    productRenderPilot: ProductRenderPilot,
    type: DbyDielineType
  ): Promise<string | undefined> {
    const predefinedDieline = productRenderPilot.getPredefinedDbyDielineUrl(
      type,
      this.productRegion
    )

    if (predefinedDieline) {
      return predefinedDieline
    }

    if (type !== "pdf") {
      return
    }

    return this.generateDieline(productRenderPilot.getProduct())
  }

  private async generateDieline(
    product: ProductManager
  ): Promise<string | undefined> {
    return this.dielineGenerator.generate(product, "pdf")
  }
}
