import React from "react"
import { ArrowDown } from "../../../assets/_svgr/UI/ArrowDown"
import styles from "../Select.module.scss"

const ControllerButton = (props: { onClick?: () => void }) => {
  return (
    <div
      onClick={props.onClick && props.onClick}
      className={styles["controller-button"]}
    >
      <ArrowDown className={styles["controller-button-img"]} />
    </div>
  )
}

export { ControllerButton, ControllerButton as default }
