import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import { ModalFooterProps } from "../utils/modal.types"

import styles from "../Modal.module.scss"

const cx = cxBinder.bind(styles)

export const ModalFooter = ({
  children,
  sticky = false
}: PropsWithChildren<ModalFooterProps>) => (
  <div
    className={cx("modal-footer", {
      "modal-footer--sticky": sticky
    })}
  >
    {children}
  </div>
)
