import type {
  PriceBreak,
  PriceDisplayOptions,
  PriceFormatter,
  QuantityItem,
  TranslatedKeys
} from "../types"
import { createQuantityItemForSearchValue } from "../utils/create-quantity-item-for-search-value"

const getStepForSearchValue = (
  searchValue: string,
  step: number,
  maxQuantityForStandardOffer?: number
): number => {
  if (
    maxQuantityForStandardOffer &&
    Number(searchValue) > maxQuantityForStandardOffer
  ) {
    return 10
  }

  return step
}

const normalizeSearchValue = (
  searchValue: string,
  step: number,
  minQuantity: number,
  maxQuantity: number,
  minQuantityForCustomOffer?: number,
  maxQuantityForStandardOffer?: number
): number => {
  const quantity = Math.round(Number(searchValue) / step) * step

  if (!quantity || Number.isNaN(quantity)) {
    return 0
  }

  if (quantity < minQuantity) {
    return minQuantity
  }

  if (quantity > maxQuantity) {
    return maxQuantity
  }

  if (maxQuantityForStandardOffer && quantity > maxQuantityForStandardOffer) {
    if (!minQuantityForCustomOffer) {
      return maxQuantityForStandardOffer
    }

    if (quantity < minQuantityForCustomOffer) {
      return minQuantityForCustomOffer
    }
  }

  return quantity
}

interface ListSearchParams {
  exactSearch?: boolean
  getPriceBreak: (quantity: number) => PriceBreak
  maxQuantity: number
  maxQuantityForStandardOffer?: number
  minQuantity: number
  minQuantityForCustomOffer?: number
  options: QuantityItem[]
  piecePriceFormatter?: PriceFormatter
  priceDisplayOptions?: PriceDisplayOptions
  priceFormatter?: PriceFormatter
  searchValue?: string
  step: number
  translations: TranslatedKeys
}
interface ListSearchReturn {
  match?: QuantityItem
  options: QuantityItem[]
}

export const useListSearch = (params: ListSearchParams): ListSearchReturn => {
  const {
    exactSearch,
    getPriceBreak,
    maxQuantity,
    maxQuantityForStandardOffer,
    minQuantity,
    minQuantityForCustomOffer,
    options,
    piecePriceFormatter,
    priceDisplayOptions,
    priceFormatter,
    searchValue,
    step,
    translations
  } = params

  if (!searchValue) {
    return { options }
  }

  const exactOption = options.find(({ quantity }) => quantity === searchValue)

  if (exactOption) {
    return {
      match: exactOption,
      options
    }
  }

  const stepForSearchValue = getStepForSearchValue(
    searchValue,
    step,
    maxQuantityForStandardOffer
  )

  const normalizedValue = normalizeSearchValue(
    searchValue,
    stepForSearchValue,
    minQuantity,
    maxQuantity,
    minQuantityForCustomOffer,
    maxQuantityForStandardOffer
  )

  if (!normalizedValue) {
    return { options }
  }

  const equalities = options.map(({ quantity }) => {
    const diff = Number(quantity) - normalizedValue
    if (diff < 0) {
      return -1
    }
    if (diff === 0) {
      return 0
    }
    if (diff > 0) {
      return 1
    }
  })

  const indexes = {
    smaller: equalities.lastIndexOf(-1),
    exact: equalities.indexOf(0),
    bigger: equalities.indexOf(1)
  }

  if (exactSearch) {
    return {
      match: indexes.exact !== -1 ? options[indexes.exact] : undefined,
      options
    }
  }

  if (indexes.exact === -1) {
    const match = createQuantityItemForSearchValue({
      getPriceBreak,
      piecePriceFormatter,
      priceDisplayOptions,
      priceFormatter,
      quantity: normalizedValue,
      translations
    })

    const optionsAbove = options.slice(0, indexes.smaller + 1)

    const optionsBelow =
      indexes.bigger !== -1 ? options.slice(indexes.bigger) : []

    const extendedOptions = [...optionsAbove, match, ...optionsBelow]

    return { match, options: extendedOptions }
  }

  const match = options[indexes.exact]

  return { match, options }
}
