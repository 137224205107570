import { VirtualDielineMigration } from "./virtual-dieline-migration.interface"
import { DesignVersion } from "../../../../../types/design.version"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  isAssetLogoPlaceholderSlot,
  isAssetMask,
  isEditableObject,
  isGroup,
} from "../../../../../types/asset.types"
import {
  backgroundLayerDefinitions,
  MaskConfigExport,
  MaskType,
} from "../../modules/assets-module/mask-controller/types"
import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import _compact from "lodash/compact"
import { assetParamsToIncludeInClonedObject } from "../../modules/assets-module/types"

export default class V226 implements VirtualDielineMigration {
  readonly designVersion = DesignVersion.v226

  public async migrate(vdEditor: VirtualDielineEditor): Promise<void> {
    this.migrateCoveringMasks(vdEditor)
    this.migrateClippingMasks(vdEditor)
    this.fixLogoPlaceholderSpaceClipping(vdEditor)
  }

  private fixLogoPlaceholderSpaceClipping(vdEditor: VirtualDielineEditor) {
    for (const object of vdEditor.assetsModule.getEditableObjects()) {
      if (
        isAssetLogoPlaceholderSlot(object) &&
        object.clipPath &&
        isGroup(object.clipPath)
      ) {
        for (const clipPathObject of object.clipPath.getObjects()) {
          if (!clipPathObject.id) {
            object.clipPath.remove(clipPathObject)
          }
        }
      }
    }
  }

  private migrateCoveringMasks(vdEditor: VirtualDielineEditor) {
    for (const object of vdEditor.assetsModule.getEditableObjects()) {
      if (isAssetMask(object)) {
        object.maskType = MaskType.covering
        this.migrateMask(vdEditor, object, MaskType.covering)
      }
    }
  }

  private migrateClippingMasks(vdEditor: VirtualDielineEditor) {
    const backgroundLayers = this.getBackgroundLayers(vdEditor)
    const masks: PackhelpMaskObject[] = []

    for (const backgroundLayer of backgroundLayers) {
      if (!backgroundLayer.clipPath || !isGroup(backgroundLayer.clipPath)) {
        continue
      }

      for (const object of backgroundLayer.clipPath.getObjects()) {
        if (isAssetMask(object)) {
          masks.push(object)
        }
      }
    }

    for (const mask of masks) {
      this.migrateMask(vdEditor, mask, MaskType.clipping)
    }
  }

  private migrateMask(
    vdEditor: VirtualDielineEditor,
    mask: PackhelpMaskObject,
    type: MaskType
  ) {
    const maskParent = vdEditor.getCanvasObjectById(mask.maskParentId)

    if (!maskParent) {
      vdEditor.fabricCanvas.remove(mask)
      return
    }

    if (!isEditableObject(maskParent) || !!maskParent.maskConfig) {
      return
    }

    maskParent.maskConfig = this.buildMaskConfigExport(mask, type)
  }

  private buildMaskConfigExport(
    mask: PackhelpMaskObject,
    type: MaskType
  ): MaskConfigExport {
    if (type === MaskType.covering) {
      return {
        type: MaskType.covering,
        assetClipping: false,
        maskObjectId: mask.id,
      }
    }

    const strokeWidth = mask.strokeWidth || 0

    return {
      type: MaskType.clipping,
      shape: {
        ...mask.toJSON(assetParamsToIncludeInClonedObject),
        version: mask.version,
      },
      assetClipping: false,
      size: {
        width: mask.getScaledWidth() - strokeWidth,
        height: mask.getScaledHeight() - strokeWidth,
      },
    }
  }

  private getBackgroundLayers(
    vdEditor: VirtualDielineEditor
  ): PackhelpObject[] {
    return _compact(
      backgroundLayerDefinitions.map((definition) =>
        vdEditor.getCanvasObjectById(definition.globalId)
      )
    )
  }
}
