import React from "react"
import styles from "./FullScreenLoader.module.scss"
import { LogoLoader } from "../LogoLoader/LogoLoader"

interface FullScreenLoaderProps {
  label?: string
}

export function FullScreenLoader({ label }: FullScreenLoaderProps) {
  return (
    <div className={styles.wrapper} e2e-target="loader" e2e-target-name="page">
      <LogoLoader label={label} />
    </div>
  )
}
