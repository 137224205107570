import { PackhelpEditableObject } from "../../../../object-extensions/packhelp-objects"
import {
  isSimpleSnapping,
  Snapping,
} from "../../../../object-extensions/snapping"

export class SnappingController {
  constructor(private object: PackhelpEditableObject) {}

  public snapToCenterOfYaxisOfSpace(): void {
    const snapping = this.getCurrentSnappingOrCreate()

    if (isSimpleSnapping(snapping.relatedTo)) {
      snapping.relatedTo.origin.y = "center"
    }

    this.object.snapping = snapping
  }

  public snapToCenterOfXaxisOfSpace(): void {
    const snapping = this.getCurrentSnappingOrCreate()

    if (isSimpleSnapping(snapping.relatedTo)) {
      snapping.relatedTo.origin.x = "center"
    }

    this.object.snapping = snapping
  }

  private getCurrentSnappingOrCreate(): Snapping {
    const spaceName = this.object.originSpaceArea

    if (!this.object.snapping) {
      const snapping: Snapping = {
        origin: { x: "center", y: "center" },
        relatedTo: {
          type: "space",
          spaceName,
          origin: { x: "none", y: "none" },
        },
      }

      return snapping
    }

    return this.object.snapping
  }

  public reset(): void {
    this.object.snapping = undefined
  }
}
