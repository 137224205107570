import {
  IndexConfigFragments,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"

export class LayerHelper {
  public static getBottomLayerObjects(
    objects: PackhelpObject[]
  ): PackhelpObject[] {
    return objects.filter(
      (obj) => obj.indexConfig?.fragment === IndexConfigFragments.BOTTOM
    )
  }

  public static getMiddleLayerObjects(
    objects: PackhelpObject[]
  ): PackhelpObject[] {
    return objects.filter(
      (obj) =>
        !obj.indexConfig ||
        obj.indexConfig.fragment === IndexConfigFragments.MIDDLE
    )
  }

  public static getTopLayerObjects(
    objects: PackhelpObject[]
  ): PackhelpObject[] {
    return objects.filter(
      (obj) => obj.indexConfig?.fragment === IndexConfigFragments.TOP
    )
  }

  public static compareLayers(a: PackhelpObject, b: PackhelpObject): number {
    if (!a.indexConfig && !b.indexConfig) {
      return 0
    }

    const aIndex = a.indexConfig?.index || Number.NEGATIVE_INFINITY
    const bIndex = b.indexConfig?.index || Number.NEGATIVE_INFINITY

    if (aIndex < bIndex) {
      return -1
    }

    if (aIndex > bIndex) {
      return 1
    }

    return 0
  }
}
