import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Nav } from "../../../atoms/Nav/Nav"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import shareIconUrl from "../../../../assets/_icons/share.svg"
import shareFillIconUrl from "../../../../assets/_icons/share-fill.svg"
import saveIconUrl from "../../../../assets/_icons/save.svg"
import saveFillIconUrl from "../../../../assets/_icons/save-fill.svg"
import restartIconUrl from "../../../../assets/_icons/restart.svg"
import restartFillIconUrl from "../../../../assets/_icons/restart-fill.svg"
import projectsIconUrl from "../../../../assets/_icons/document.svg"
import projectsFillIconUrl from "../../../../assets/_icons/document-fill.svg"
import helpIconUrl from "../../../../assets/_icons/help.svg"
import helpFillIconUrl from "../../../../assets/_icons/help-fill.svg"
import duplicateIconUrl from "../../../../assets/_icons/duplicate.svg"
import duplicateFillIconUrl from "../../../../assets/_icons/duplicate-fill.svg"
import Badge, { BadgeColorTypes } from "dsl/src/atoms/Badge/Badge"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { NavButton } from "../../../atoms/Nav/NavButton"
import { NavLink } from "../../../atoms/Nav/NavLink"

export const MenuNav = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const { productDesignUiController, productDesignStore } =
    designAndProductDriver
  const { uri, appConfig } = envUtil
  const {
    isDesignCreated,
    isSaveDisabled,
    isDesignSaved,
    state: { isProductDesignSaving, isProductDesignDuplicating },
  } = productDesignStore
  const projectsUrl = uri.getProjectsUrl()
  const helpUrl = uri.getHelpUrl()
  const manualSave = appConfig.api.ecommerce.features.ecommerce.manualSave

  const onShareClick = () => {
    productDesignUiController.setIsShareModalOpen(true)
  }

  const onDuplicateClick = () => {
    productDesignUiController.duplicate()
  }

  const onSaveClick = () => {
    productDesignUiController.save({
      notifyOnSuccess: true,
      forceLogin: true,
    })
  }

  const onRestartClick = () => {
    productDesignUiController.setIsRestartConfirmationModalOpen(true)
  }

  return (
    <Nav>
      <NavButton
        iconUrl={shareIconUrl}
        iconActiveUrl={shareFillIconUrl}
        label={t(I18N.menu.share)}
        onClick={onShareClick}
        analyticsSection="menu"
        analyticsType="icon"
        analyticsName="share"
      />
      {manualSave && (
        <NavButton
          iconUrl={saveIconUrl}
          iconActiveUrl={saveFillIconUrl}
          label={t(I18N.menu.save)}
          onClick={onSaveClick}
          disabled={isSaveDisabled}
          badge={
            isProductDesignSaving ? (
              <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
            ) : isDesignSaved ? (
              <Badge badgeColor={BadgeColorTypes.OliveLight} noMargin>
                {t(I18N.generic.allChangesSaved)}
              </Badge>
            ) : undefined
          }
          analyticsSection="menu"
          analyticsType="icon"
          analyticsName="save"
        />
      )}
      <NavButton
        iconUrl={restartIconUrl}
        iconActiveUrl={restartFillIconUrl}
        label={t(I18N.menu.restart)}
        onClick={onRestartClick}
        analyticsSection="menu"
        analyticsType="icon"
        analyticsName="start-again"
      />
      {isDesignCreated && (
        <NavButton
          iconUrl={duplicateIconUrl}
          iconActiveUrl={duplicateFillIconUrl}
          label={t(I18N.menu.duplicate)}
          onClick={onDuplicateClick}
          badge={
            isProductDesignDuplicating ? (
              <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
            ) : undefined
          }
          analyticsSection="menu"
          analyticsType="icon"
          analyticsName="duplicate"
        />
      )}
      {!!projectsUrl && (
        <NavLink
          iconUrl={projectsIconUrl}
          iconActiveUrl={projectsFillIconUrl}
          label={t(I18N.menu.myProjects)}
          href={projectsUrl}
          analyticsSection="menu"
          analyticsType="icon"
          analyticsName="my-projects"
        />
      )}
      {!!helpUrl && (
        <NavLink
          iconUrl={helpIconUrl}
          iconActiveUrl={helpFillIconUrl}
          label={t(I18N.menu.help)}
          href={helpUrl}
          analyticsSection="menu"
          analyticsType="icon"
          analyticsName="help"
        />
      )}
    </Nav>
  )
})
