import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import { I18N } from "../../../../../i18n"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { EffectType } from "../../../../../../controllers/text-effects/types"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"
import { Slider } from "dsl/src/atoms/Slider/Slider"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import Colour from "../../../../../../models/colour"

const i18n = I18N.components.editorToolText

export const TextEffectsSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { textOptionsTabController } = ui
  const { isEffectsSectionAvailable, textEffectsController } =
    textOptionsTabController

  if (!isEffectsSectionAvailable || !textEffectsController) {
    return null
  }

  const { tabs, type, options, uiConfig } = textEffectsController

  const onTypeChange = (type: EffectType) => {
    textEffectsController.setType(type)
  }

  const onStyleChange = (type: string, value: number) => {
    textEffectsController.apply({
      [type]: value,
    })
  }

  const onColorChange = (color: Colour) => {
    textEffectsController.apply({
      color,
    })
  }

  return (
    <>
      <TabSection title={t(i18n.label.effects)}>
        <TileList cols={3}>
          {tabs.map((tab) => {
            return (
              <Tile
                onClick={() => onTypeChange(tab.type)}
                active={type === tab.type}
                analyticsSection="left-tab-text-options"
                analyticsType="thumbnail"
                analyticsName="text-effect"
                analyticsId={tab.type}
              >
                <figure>
                  <img src={tab.imageUrl} alt={tab.type} />
                </figure>
              </Tile>
            )
          })}
        </TileList>
      </TabSection>

      {type !== "none" && (
        <TabSection>
          {uiConfig.color && (
            <FormGroup label={t(i18n.label.selectedColour)} inline>
              <ColorConfigurator
                selectedColor={options.color}
                onColorSelect={onColorChange}
                analyticsSection="left-tab-text-options"
                analyticsName="text-effect-color"
              />
            </FormGroup>
          )}

          {["offsetX", "offsetY", "thickness"].map((type) => {
            if (!uiConfig[type]) {
              return null
            }

            return (
              <FormGroup label={t(i18n.label[type])} inline>
                <Slider
                  value={options[type]}
                  step={uiConfig[type].step}
                  min={uiConfig[type].min}
                  max={uiConfig[type].max}
                  onChange={(value) => onStyleChange(type, value)}
                />
              </FormGroup>
            )
          })}
        </TabSection>
      )}
    </>
  )
})
