import {
  PackhelpCanvas,
  PackhelpEditableObject,
  PackhelpMaskObject,
} from "../../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { MaskConfig, MaskConfigExport, MaskEvent, MaskType } from "../types"
import { getDefaultMaskConfig } from "../helpers"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { MaskBuilder } from "../mask.builder"
import { MaskCalculator } from "../calculators/mask.calculator"
import { ClipPathModes } from "../../../../services/clip-path.generator"
import { MaskControllable } from "./mask-controllable.interface"
import { MaskParentCalculator } from "../calculators/mask-parent.calculator"
import { degreesToRadians } from "../../../../../../../libs/maths/angle-calculators"
import {
  ActiveObjectEvent,
  CanvasObjectEvent,
} from "../../../../../../../events/partials/domain.events"
import { MaskNotReadyError, NotValidOperationError } from "../errors"
import { getLayerIndex } from "../../canvas-object-controller/helpers"
import { SpaceClippingHelper } from "../../helpers/space-clipping.helper"
import { isInteractiveCanvas } from "../../../../../../../types/asset.types"

export abstract class BaseMaskController implements MaskControllable {
  protected config: MaskConfig
  protected maskBuilder: MaskBuilder
  protected mask?: PackhelpMaskObject
  protected maskCalculator?: MaskCalculator
  protected maskParentCalculator: MaskParentCalculator

  protected maskMarginW = 0
  protected maskMarginH = 0

  private isMaskEventsAttached = false
  protected maskEventMap: MaskEvent[]
  protected maskParentEventMap: MaskEvent[]

  constructor(
    protected maskParent: PackhelpEditableObject,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    this.config = getDefaultMaskConfig(this.maskParent)

    this.maskBuilder = new MaskBuilder(
      this.maskParent,
      this.virtualDielineEditor
    )

    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)

    this.maskEventMap = this.buildMaskEventMap()
    this.maskParentEventMap = this.buildMaskParentEventMap()
  }

  public abstract init(config: Partial<MaskConfig>): Promise<void>
  public abstract load(config: MaskConfig): Promise<void>
  public abstract getType(): MaskType
  public abstract getConfigExport(): MaskConfigExport | undefined

  public getConfig(): MaskConfig {
    return this.config
  }

  public getMask(): PackhelpMaskObject | undefined {
    return this.mask
  }

  public clear() {
    this.clearMask()
  }

  public dispose() {
    this.clear()
    this.maskParent.maskController = undefined
    this.maskParent.maskConfig = undefined
  }

  public updatePosition() {
    this.setMaskPosition()
  }

  public updateScaleAndPosition() {
    this.setMaskScaleAndPosition()
  }

  public updateRotationAndPosition() {
    this.setMaskRotationAndPosition()
  }

  public changeParent(newParent: PackhelpEditableObject) {
    const isMaskEventsAttachedInitially = this.isMaskEventsAttached

    if (isMaskEventsAttachedInitially) {
      this.detachMaskEvents()
    }

    this.maskParent = newParent

    this.maskBuilder = new MaskBuilder(
      this.maskParent,
      this.virtualDielineEditor
    )
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)

    if (isMaskEventsAttachedInitially) {
      this.attachMaskEvents()
    }

    if (this.mask) {
      this.mask.set({ maskParentId: newParent.id })
      this.maskCalculator = new MaskCalculator(this.maskParent, this.mask)

      const maskParentIndex = getLayerIndex(this.maskParent, this.canvas)
      this.canvas.moveTo(this.mask, maskParentIndex)
    }

    // TODO: asset clipping as well? possibly different originSpaceArea
  }

  protected async applyMask(mask: PackhelpMaskObject) {
    if (mask.canvas) {
      throw new NotValidOperationError("Mask is already applied!")
    }

    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      this.maskParent.originSpaceArea,
      mask,
      ClipPathModes.FillMode
    )

    this.virtualDielineEditor.addOnCanvas(mask, true)

    const maskParentIndex = getLayerIndex(this.maskParent, this.canvas)

    this.canvas.moveTo(mask, maskParentIndex)
  }

  protected limitMaskScale() {
    if (!this.mask || !this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskSize = this.maskCalculator.calcSize()
    const maskParentSize = this.maskParentCalculator.calcSize()

    const minScale = this.maskCalculator.calcScale(
      {
        offsetW: maskSize.width - maskParentSize.width,
        offsetH: maskSize.height - maskParentSize.height,
      },
      this.config.minimalMargin
    )

    const shouldLimitScaleX = this.mask.scaleX! < minScale.scaleX
    const shouldLimitScaleY = this.mask.scaleY! < minScale.scaleY

    if (shouldLimitScaleX) {
      this.mask.set({
        scaleX: minScale.scaleX,
      })
    }

    if (shouldLimitScaleY) {
      this.mask.set({
        scaleY: minScale.scaleY,
      })
    }

    if (shouldLimitScaleX || shouldLimitScaleY) {
      this.setMaskPosition()
    }
  }

  protected recalculateConfig() {
    if (!this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskSize = this.maskCalculator.calcSize()

    this.updateConfig({
      size: {
        width: maskSize.width,
        height: maskSize.height,
      },
    })
  }

  protected recalculateMargins() {
    if (!this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskSize = this.maskCalculator.calcSize()
    const maskParentSize = this.maskParentCalculator.calcSize()

    this.maskMarginW = maskSize.width - maskParentSize.width
    this.maskMarginH = maskSize.height - maskParentSize.height
  }

  protected clearMask() {
    this.detachMaskEvents()

    this.canvas.remove(this.mask!)
    this.mask = undefined
  }

  protected setMaskScaleAndPosition() {
    this.setMaskScale()
    this.setMaskPosition()
  }

  protected setMaskRotationAndPosition() {
    this.setMaskRotation()
    this.setMaskPosition()
  }

  protected setMaskRotation() {
    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskParentRotation = this.maskParentCalculator.calcRotation()
    this.mask.rotate(maskParentRotation)
  }

  protected setMaskScale() {
    if (!this.mask || !this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskScale = this.maskCalculator.calcScale(
      {
        offsetW: this.maskMarginW,
        offsetH: this.maskMarginH,
      },
      this.config.minimalMargin
    )

    this.mask.set({
      scaleX: maskScale.scaleX,
      scaleY: maskScale.scaleY,
    })

    this.recalculateConfig()
    this.setMaskBorderRadius()
  }

  protected setMaskBorderRadius() {
    if (!this.mask || !this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskBorderRadius = this.maskCalculator.calcBorderRadius()

    this.mask.set({
      rx: maskBorderRadius.rx,
      ry: maskBorderRadius.ry,
    })
  }

  protected setMaskParentPosition() {
    if (!this.mask || !this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskOffset = this.maskCalculator.calcMaskParentAndMaskSizeDiff()

    const newPosition = {
      left: this.mask.left! - maskOffset.offsetW,
      top: this.mask.top! - maskOffset.offsetH,
    }

    if (this.maskParent.angle) {
      const objectOriginPoint = new fabric.Point(
        newPosition.left,
        newPosition.top
      )
      const center = new fabric.Point(this.mask.left!, this.mask.top!)
      const rads = degreesToRadians(this.maskParent.angle!)
      const { x, y } = fabric.util.rotatePoint(objectOriginPoint, center, rads)

      newPosition.left = x
      newPosition.top = y
    }

    this.maskParent.set({
      left: newPosition.left,
      top: newPosition.top,
    })

    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  protected setMaskPosition() {
    if (!this.mask || !this.maskCalculator) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    const maskOffset = this.maskCalculator.calcMaskParentAndMaskSizeDiff()
    const { left, top } = this.maskCalculator.calcPosition(maskOffset)

    this.mask.set({
      left: left,
      top: top,
    })
    this.mask.setCoords()
  }

  protected setMaskSelection() {
    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    if (
      !isInteractiveCanvas(this.canvas) ||
      this.canvas.getActiveObject() === this.mask
    ) {
      return
    }

    this.showMaskBorder()
    this.setMaskPosition()
  }

  protected removeMaskSelection() {
    if (
      !isInteractiveCanvas(this.canvas) ||
      this.canvas.getActiveObject() === this.maskParent
    ) {
      return
    }

    this.hideMaskBorder()
    this.setMaskPosition()
  }

  protected showMaskBorder() {
    if (this.config.isEditingDisabled) {
      return
    }

    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    this.mask.set({
      strokeWidth: 0.5,
    })
  }

  protected hideMaskBorder() {
    if (this.config.isEditingDisabled) {
      return
    }

    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    this.mask.set({
      strokeWidth: 0,
    })
  }

  public setMaskVisibility(isVisible: boolean) {
    if (!this.mask) {
      return
    }

    const isSelectable =
      isVisible && isInteractiveCanvas(this.canvas) && this.canvas.selection

    this.mask.set({
      visible: isVisible,
      selectable: isSelectable,
      evented: isSelectable,
    })
  }

  protected attachMaskEvents() {
    if (this.isMaskEventsAttached) {
      return
    }

    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    for (const event of this.maskEventMap) {
      this.mask.on(event.name, event.fn)
    }

    for (const event of this.maskParentEventMap) {
      this.maskParent.on(event.name, event.fn)
    }

    this.isMaskEventsAttached = true
  }

  protected detachMaskEvents() {
    if (!this.isMaskEventsAttached) {
      return
    }

    if (!this.mask) {
      throw new MaskNotReadyError("Call init() or load() first!")
    }

    for (const event of this.maskEventMap) {
      this.mask.off(event.name, event.fn)
    }

    for (const event of this.maskParentEventMap) {
      this.maskParent.off(event.name, event.fn)
    }

    this.isMaskEventsAttached = false
  }

  protected buildMaskEventMap(): MaskEvent[] {
    return [
      {
        name: CanvasObjectEvent.scaling,
        fn: this.limitMaskScale.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.recalculateConfig.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.recalculateMargins.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.setMaskBorderRadius.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.setMaskParentPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.deselected,
        fn: this.removeMaskSelection.bind(this),
      },
    ]
  }

  protected buildMaskParentEventMap(): MaskEvent[] {
    return [
      {
        name: CanvasObjectEvent.changed,
        fn: this.setMaskScaleAndPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.selected,
        fn: this.setMaskSelection.bind(this),
      },
      {
        name: CanvasObjectEvent.deselected,
        fn: this.removeMaskSelection.bind(this),
      },
      {
        name: CanvasObjectEvent.moving,
        fn: this.setMaskPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.rotating,
        fn: this.setMaskRotationAndPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.scaling,
        fn: this.setMaskScaleAndPosition.bind(this),
      },
      {
        name: CanvasObjectEvent.removed,
        fn: this.clearMask.bind(this),
      },
      {
        name: CanvasObjectEvent.moved,
        fn: this.setMaskPosition.bind(this),
      },
    ]
  }

  protected updateConfig(config: Partial<MaskConfig>) {
    Object.assign(this.config, config)
  }

  protected get canvas(): PackhelpCanvas {
    return this.virtualDielineEditor.fabricCanvas
  }

  protected rerender() {
    this.canvas.renderAll()
  }
}
