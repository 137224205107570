import { AxiosRequestConfig, AxiosInstance } from "axios"
import _clone from "lodash/clone"
import { AxiosService } from "./axios-client"
import { ProductPricingApi } from "./endpoints/product-pricing.api"
import { PatternApi } from "./endpoints/pattern.api"
import { TemplatesApi } from "./endpoints/templates.api"
import { CartApi } from "./endpoints/cart.api"
import { CertificatesApi } from "./endpoints/certificates/certificates.api"
import { QrCodesApi } from "./endpoints/qr-codes.api"

export type ApiConfig = {
  token: string
  axios?: AxiosRequestConfig
}

export class PackhelpApi {
  public ax: AxiosInstance
  public token: string
  public productPricing: ProductPricingApi
  public cart: CartApi
  public pattern: PatternApi
  public templates: TemplatesApi
  public certificates: CertificatesApi
  public qrCodesApi: QrCodesApi
  private readonly savedConfig: ApiConfig

  constructor(config: ApiConfig) {
    this.savedConfig = config
    this.token = config.token

    const instance = new AxiosService(config)

    this.ax = instance.ax

    // Areas
    this.productPricing = new ProductPricingApi(this)
    this.cart = new CartApi(this)
    this.pattern = new PatternApi(this)
    this.templates = new TemplatesApi(this)
    this.certificates = new CertificatesApi(this)
    this.qrCodesApi = new QrCodesApi(this)

    return this
  }

  public updateToken(nt: string) {
    this.token = nt
    const config = _clone(this.savedConfig)
    config.token = nt
    const instance = new AxiosService(config)
    this.ax = instance.ax
    // Areas
    this.productPricing = new ProductPricingApi(this)
  }

  public withAuthToken() {
    if (!this.token) {
      throw new Error("Auth token is not defined")
    }

    return {
      headers: {
        "X-Spree-Token": this.token,
      },
    }
  }
}
