import { DbyDesign, EditorDesign } from "../../types/design.types"
import { ProductDesignStore } from "../../stores/product-design.store"
import { AssetsStore } from "../../stores/assets.store"
import { DbyModeStore } from "../../stores/dby-mode.store"
import { ProductDesignDtoToStateTransformer } from "../../services/transformers/product-design/product-design-dto-to-state.transformer"
import axios from "axios"
import { EditorMode } from "../../libs/products-render-config/types"
import { DesignApi } from "@ph/design-api"

export class LoadProductDesignController {
  constructor(
    private readonly designApi: DesignApi,
    private readonly productDesignStore: ProductDesignStore,
    private readonly dbyStore: DbyModeStore,
    private readonly assetStore: AssetsStore,
    private readonly editorMode: EditorMode
  ) {}

  public initEmptyDesign() {
    this.productDesignStore.setProductDesignSaving(false)
    this.productDesignStore.setProductDesignLoading(false)
  }

  public async loadDesignById(id: string): Promise<EditorDesign | DbyDesign> {
    try {
      const dto = await this.designApi.get(id)
      const { meta, data } = new ProductDesignDtoToStateTransformer(
        dto
      ).transform()

      this.productDesignStore.setProductDesignMeta(meta)

      // We have to deserialize here, because setDesignAttributes is buggy
      // Will not refactor until tests are there
      // TODO: Write tests and debug

      if (data.type === "dby" && this.editorMode === "dby") {
        this.dbyStore.setUploadedFile(data.files[0])
        this.productDesignStore.setProductDesignLoading(false)

        return {
          meta,
          data,
        }
      }

      this.assetStore.deserialize(data.files)
      const updatedData = this.productDesignStore.setEditorDesignData(data)
      this.productDesignStore.setProductDesignLoading(false)

      return {
        meta,
        data: updatedData,
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      const status = error.response?.status

      if (status === 404) {
        this.productDesignStore.set404(true)

        throw new Error("Design is not found!")
      }

      if (status === 401 || status === 403) {
        this.productDesignStore.setUnauthorized(true)

        throw new Error("User is not authorized to this design!")
      }

      this.productDesignStore.setUnauthorized(true)
      this.productDesignStore.setProductDesignLoading(false)

      throw new Error(
        "User is anonymous! Or other design load error has occurred. Maybe a broken design file."
      )
    }
  }
}
