import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import { ModalLayoutProps } from "../utils/modal.types"

import styles from "../Modal.module.scss"

const cx = cxBinder.bind(styles)

export const ModalLayout = ({
  children,
  fullHeight = false,
  fullWidth = false,
  noPadding = false,
  largePaddingLR = false,
  smPadding = false,
  size = "unset",
  customStyles
}: PropsWithChildren<ModalLayoutProps>) => {
  return (
    <div
      className={cx("layout", {
        "layout--xs": size === "xs",
        "layout--sm": size === "sm",
        "layout--md": size === "md",
        "layout--lg": size === "lg",
        "layout--xl": size === "xl",
        "layout--no-padding": noPadding,
        "layout--sm-padding": smPadding,
        "layout--large-padding-lr": largePaddingLR,
        "layout--full-height": fullHeight,
        "layout--full-width": fullWidth
      })}
      style={customStyles}
    >
      {children}
    </div>
  )
}
