import React from "react"
import styles from "./SectionLoader.module.scss"
import { LogoLoader } from "../LogoLoader/LogoLoader"
import { TestsProps } from "../../../types"

interface SectionLoaderProps extends TestsProps {
  label?: string
}

export function SectionLoader({
  label,
  e2eTarget = "loader",
  e2eTargetName,
}: SectionLoaderProps) {
  return (
    <div
      className={styles.wrapper}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <LogoLoader label={label} />
    </div>
  )
}
