var SkuMaterial;
(function (SkuMaterial) {
    SkuMaterial["SKU_MATERIAL_PAPER_NATURAL"] = "paper-natural";
    SkuMaterial["SKU_MATERIAL_PAPER_WHITE"] = "paper-white";
    SkuMaterial["SKU_MATERIAL_WHITE"] = "white";
    SkuMaterial["SKU_MATERIAL_KRAFT"] = "kraft";
    SkuMaterial["SKU_MATERIAL_BLACK"] = "black";
    SkuMaterial["SKU_MATERIAL_ORGANIC_COTTON"] = "organic-cotton";
    SkuMaterial["SKU_MATERIAL_RECYCLED_COTTON"] = "recycled-cotton";
})(SkuMaterial || (SkuMaterial = {}));
export default SkuMaterial;
