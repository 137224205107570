var SkuPrintMode;
(function (SkuPrintMode) {
    SkuPrintMode["SKU_PRINT_COLOR_MODE_COLOR_BUDGET"] = "print-color-budget";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_COLOR_HD"] = "print-color-hd";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_COLOR_ECO"] = "print-eco-color";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_COLOR_ECO_WITH_PRINT_INSIDE"] = "print-eco-color-with-print-inside";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_MONOCHROME"] = "print-monochrome";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_MONOCHROME_WITH_PRINT_INSIDE"] = "print-monochrome-with-print-inside";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_MONOCHROME_WHITE"] = "print-monochrome-white";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE"] = "print-monochrome-white-with-print-inside";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_NO_PRINT"] = "no-print";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_INDIGO_COLOR"] = "print-indigo-color";
    SkuPrintMode["SKU_PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE"] = "print-indigo-color-with-inside";
})(SkuPrintMode || (SkuPrintMode = {}));
export default SkuPrintMode;
