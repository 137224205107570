import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import { TabSection } from "../Tab/TabSection"
import arrowRightIconUrl from "../../../../assets/_icons/type-arrow-right.svg"
import styles from "./TabSkipDesign.module.scss"
import { SvgIcon } from "../../../atoms/SvgIcon/SvgIcon"

export const TabSkipDesign = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ecommerce])

  if (!containerSet || !containerSet.ecommerce.available) {
    return null
  }

  const { ecommerceController } = containerSet.ecommerce

  const onClick = () => {
    ecommerceController.addToCart()
  }

  return (
    <Tab e2eTargetName="skip-design">
      <TabSection>
        <div className={styles.icon}>
          <SvgIcon url={arrowRightIconUrl} size="xl" />
        </div>
        <h2 className={styles.title}>{t(I18N.tabs.skipDesign)}</h2>
        <p className={styles.description}>
          {t(I18N.components.skipDesign.description)}
        </p>
      </TabSection>

      <TabSection>
        <Button
          onClick={onClick}
          size={ButtonSizes.large}
          analyticsSection="left-tab-skip-design"
          analyticsType="button"
          analyticsName="add-to-cart"
          e2eTargetName="add-to-cart"
        >
          {t(I18N.generic.addToCart)}
        </Button>
      </TabSection>
    </Tab>
  )
})
