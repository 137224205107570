import React from "react"
import { I18N } from "../../../i18n"
import { Notification } from "../../atoms/Notification/Notification"
import { useTranslate } from "../../../hooks/useTranslate"
import { ContextNav } from "../../atoms/ContextNav/ContextNav"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import { LinkAsButton } from "dsl/src/atoms/Link/Link"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import styles from "./ReadOnlyNotification.module.scss"

const i18n = I18N.components.readOnly

export function ReadOnlyNotification() {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, envUtil } = containerSet
  const {
    productDesignUiController,
    productDesignStore: {
      state: { isProductDesignDuplicating },
    },
  } = designAndProductDriver
  const { uri } = envUtil

  const onDuplicateClick = () => {
    productDesignUiController.duplicate()
  }

  const onCartClick = () => {
    uri.redirectToCart()
  }

  return (
    <div className={styles.wrapper}>
      <Notification type="warning">
        {t(i18n.info1)} {t(i18n.info2)}
        <ContextNav>
          <Button
            size={ButtonSizes.small}
            onClick={onDuplicateClick}
            disabled={isProductDesignDuplicating}
          >
            {t(i18n.duplicateDesign)}
          </Button>
          <LinkAsButton onClick={onCartClick}>{t(i18n.goToCart)}</LinkAsButton>
        </ContextNav>
      </Notification>
    </div>
  )
}
