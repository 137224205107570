import { DesignMeta, EcommerceDesignItem } from "../../types/design.types"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import { ProductManager } from "@ph/product-api"

// Events
export enum ProductDesignEvents {
  created = "ph.product-design.created",
  updated = "ph.product-design.updated",
  saved = "ph.product-design.saved",
  saveTriggered = "ph.product-design.save-triggered",
  saveSkippedReadOnly = "ph.product-design.save-skipped-read-only",
  saveSkippedNotValid = "ph.product-design.save-skipped-not-valid",
  skuChangeStarted = "ph.product-design.sku-change-started",
  skuChangeEnded = "ph.product-design.sku-change-ended",
  qtyChanged = "ph.product-design.product.quantity.changed",
  customQtyTyped = "ph.product-design.product.quantity.custom-typed",
  metaLoaded = "ph.product-design.product.meta.loaded",
}

const pd = ProductDesignEvents

export type ProductDesignTypes = {
  [pd.created]: (meta: DesignMeta) => void
  [pd.updated]: (meta: DesignMeta) => void
  [pd.saved]: (meta: DesignMeta) => void
  [pd.saveTriggered]: (options?: {
    force: boolean
    notifyOnSuccess?: boolean
    withPreview?: boolean
    allowEmpty?: boolean
  }) => void
  [pd.saveSkippedReadOnly]: (meta: DesignMeta) => void
  [pd.saveSkippedNotValid]: () => void
  [pd.skuChangeStarted]: () => void
  [pd.skuChangeEnded]: (
    sku: string,
    productRenderPilot: ProductRenderPilot
  ) => void
  [pd.qtyChanged]: (
    quantity: number,
    product: ProductManager,
    designItems: EcommerceDesignItem[]
  ) => void
  [pd.customQtyTyped]: (quantity: number, price: number) => void
  [pd.metaLoaded]: () => void
}
