import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Button } from "dsl/src/atoms/Button"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"

export const HeaderFinishDesignButton = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDesignUiController } = containerSet.designAndProductDriver

  const onClick = () => {
    productDesignUiController.setIsFinishConfirmationModalOpen(true)
  }

  return (
    <Button
      onClick={onClick}
      e2eTargetName="finish-design"
      analyticsSection="top-right-corner"
      analyticsType="button"
      analyticsName="finish-design"
    >
      {t(I18N.modals.bndl.finishDesign)}
    </Button>
  )
})
