import React from "react"
import { OptionPickerList } from "../../../../atoms/OptionPicker/OptionPickerList"
import { findProductPropertyBySku } from "../../../../../../libs/products-render-config/helpers"
import { OptionPicker } from "../../../../atoms/OptionPicker/OptionPicker"
import { Popover } from "../../../../molecules/Popover/Popover"
import { ConditionalWrapper } from "../../../../atoms/ConditionalWrapper/ConditionalWrapper"
import { PropertyGroup } from "../../../../../../types/ui.types"
import { I18N } from "../../../../../i18n"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"

export interface PropertyGroupSectionProps {
  group: PropertyGroup
  onPropertyClick: (sku: string) => void
  currentSku: string
  isAnyActionDisabled?: boolean
}

export function PropertyGroupSection({
  group,
  onPropertyClick,
  currentSku,
  isAnyActionDisabled = false,
}: PropertyGroupSectionProps) {
  const t = useTranslate()

  const currentProperty = findProductPropertyBySku(group.properties, currentSku)

  return (
    <FormGroup label={group.title}>
      <OptionPickerList>
        {group.properties.map((property) => {
          const isActive = currentProperty?.id === property.id
          const isDisabled = isActive || isAnyActionDisabled
          const title = `${property.title} ${property.isDbyOnly ? "*" : ""}`

          return (
            <ConditionalWrapper
              key={property.id}
              condition={!!property.isDbyOnly}
              wrapper={(children) => (
                <Popover text={t(I18N.product.configuration.dbyOnly)}>
                  {children}
                </Popover>
              )}
            >
              <OptionPicker
                key={property.id}
                label={title}
                onClick={() => onPropertyClick(property.sku)}
                active={isActive}
                disabled={isDisabled}
                analyticsSection="left-tab-configuration"
                analyticsType="option-picker"
                analyticsName={group.type}
                analyticsId={property.name}
              />
            </ConditionalWrapper>
          )
        })}
      </OptionPickerList>
    </FormGroup>
  )
}
