import { VirtualDielineMigration } from "./virtual-dieline-migration.interface"
import { DesignVersion } from "../../../../../types/design.version"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { PackhelpObject } from "../../object-extensions/packhelp-objects"
import {
  isCircle,
  isEditableObject,
  isGroup,
} from "../../../../../types/asset.types"
import fabric from "../../../../../libs/vendors/Fabric"
import { MaskType } from "../../modules/assets-module/mask-controller/types"

export default class V300 implements VirtualDielineMigration {
  readonly designVersion = DesignVersion.v300

  public async migrate(vdEditor: VirtualDielineEditor): Promise<void> {
    this.migrateRadiansToDegrees(vdEditor.fabricCanvas.getObjects())
  }

  /**
   * Fabric v5 breaking change:
   * fabric.Circle - radians has been changed to degrees
   *
   * http://fabricjs.com/v5-breaking-changes
   */
  private migrateRadiansToDegrees(objects: PackhelpObject[]) {
    const FABRIC_VERSION = 5

    for (const object of objects) {
      const version = object.version

      if (!version) {
        continue
      }

      if (isCircle(object)) {
        const isLowerFabricVersion =
          parseInt(version.slice(0, 1), 10) < FABRIC_VERSION

        if (isLowerFabricVersion) {
          object.startAngle = fabric.util.radiansToDegrees(object.startAngle!)
          object.endAngle = fabric.util.radiansToDegrees(object.endAngle!)
          object.group?.set({ dirty: true })
        }
      }

      if (
        isEditableObject(object) &&
        object.maskConfig?.type === MaskType.clipping
      ) {
        this.migrateRadiansToDegrees([object.maskConfig.shape])
      }

      if (isGroup(object)) {
        this.migrateRadiansToDegrees(object.getObjects())
      }

      if (object.clipPath) {
        this.migrateRadiansToDegrees([object.clipPath as PackhelpObject])
      }
    }
  }
}
