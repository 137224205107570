import { QuantityItem } from "../types"

interface SearchConfirmParams {
  match: QuantityItem | undefined
  setSearchValue?: (value: string) => void
  onSelect?: (item: QuantityItem) => void
  withClear?: boolean
}
export const useSearchConfirm = (params: SearchConfirmParams) => {
  const { match, withClear, setSearchValue, onSelect } = params

  const onSearchConfirm = (value?: QuantityItem) => {
    if (match && match.outOfStock) {
      return
    }
    if (withClear && setSearchValue) {
      setSearchValue("")
    }
    if (onSelect && match) {
      onSelect(match)
    }
  }

  return {
    onSearchConfirm
  }
}
