import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./CanvasNotificationPopover.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Notification } from "../../atoms/Notification/Notification"

export const CanvasNotificationPopover = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { popoverPosition, notifications } =
    containerSet.ui.canvasNotificationUiController

  if (!popoverPosition || !notifications) {
    return null
  }

  return (
    <div className={styles.wrapper} style={popoverPosition}>
      {notifications.map((notification) => {
        return (
          <Notification
            key={notification.message}
            type={notification.type}
            size="small"
          >
            {notification.message}
          </Notification>
        )
      })}
    </div>
  )
})
