function roundToDecimals(value: number, decimals = 2): number {
  return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

/**
 * FOR RAILS:
 * rails is really stupid with sending floats.
 * Converts to strings willy-nilly. Must make it more digestible :/
 */
export function stringToRoundedNumber(
  value: unknown,
  decimals = 2
): number | undefined {
  let vNumber = Number(value)
  if (isNaN(vNumber) || vNumber < 0) return

  return roundToDecimals(vNumber, decimals)
}
