import type { ApiSessionContainer } from "./api-session.container"
import { GetContainerFormat } from "iti"
import { ShapesStore } from "../stores/shapes.store"
import { EcoShapesStore } from "../stores/eco-shapes.store"
import { ShapesController } from "../controllers/assets/shapes.controller"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import { ShapesManager } from "../services/managers/shapes.manager"
import { PredefinedTextsStore } from "../stores/predefined-texts.store"
import { UtilEnvContainer } from "./util.container"
import { AppConfig } from "../app-config/app.config"
import { EcommerceApi, QrCodeApi } from "../libs/api/ecommerce-api/types"
import { TextsController } from "../controllers/assets/texts.controller"
import { ImagesController } from "../controllers/assets/images.controller"
import { LogoPlaceholdersController } from "../controllers/assets/logo-placeholders.controller"

function getDynamicQrConfig(
  appConfig: AppConfig,
  ecommerceApi: EcommerceApi
):
  | {
      api: QrCodeApi
      url: string
    }
  | undefined {
  const { dynamicQrCode } = appConfig.api.ecommerce.features.ecommerce
  const dynamicQrUrl = appConfig.locale.getUrl("ecoProperties")

  if (!dynamicQrCode || !dynamicQrUrl) {
    return
  }

  return {
    api: ecommerceApi.qrCode,
    url: dynamicQrUrl,
  }
}

export async function provideAssetsContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer
) {
  const { ee, appConfig } = utilEnvContainer
  const { directusApiService, ecommerceApi } = apiSessionContainer
  const { productDriver, productDesignStore, assetStore } = designAndProduct

  const shapesManager = new ShapesManager(
    directusApiService,
    getDynamicQrConfig(appConfig, ecommerceApi)
  )
  const shapesStore = new ShapesStore(shapesManager)
  const ecoShapesStore = new EcoShapesStore(shapesManager, productDriver)
  const shapesController = new ShapesController(
    {
      productDriver,
      shapesManager,
      ee,
    },
    {
      ecoShapesStore,
      shapesStore,

      productDesignStore,
    }
  )

  const predefinedTextsStore = new PredefinedTextsStore(
    productDriver,
    appConfig
  )
  const textsController = new TextsController(
    {
      productDriver,
      ee,
    },
    {
      predefinedTextsStore,
    }
  )

  const imagesController = new ImagesController(
    {
      productDriver,
      ee,
    },
    { assetStore }
  )

  const logoPlaceholdersController = new LogoPlaceholdersController({
    productDriver,
    ee,
  })

  return {
    shapesStore,
    ecoShapesStore,
    shapesController,
    predefinedTextsStore,
    textsController,
    imagesController,
    logoPlaceholdersController,
  }
}

export type AssetsContainer = GetContainerFormat<typeof provideAssetsContainer>
