import { getUiConfig } from "./predefined-text-ui-config"
import {
  EditContext,
  EditorMode,
  SpaceId,
  SpaceDimensions,
  TextureDefinition,
} from "../types"
import { ProductRenderPilot } from "../product-render-pilot"
import { PredefinedText } from "./configs/predefined-text"
import { AvailableProperty, ProductManager } from "@ph/product-api"
import { DesignVersion } from "../../../types/design.version"

export class PredefinedTextRenderPilot extends ProductRenderPilot {
  constructor(
    product: ProductManager,
    designVersion: DesignVersion,
    editorMode: EditorMode
  ) {
    super(product, designVersion, editorMode, PredefinedText, getUiConfig())
  }

  public setAfterPurchaseEdit(isAfterPurchaseEdit: boolean): void {}

  public getVirtualDielineTexture(
    editContext: EditContext,
    isSpaceZoomActive: boolean
  ): TextureDefinition {
    const texture =
      this.renderConfig.texturesConfig.models["predefined-text"]?.variants[
        "predefined-text"
      ]?.materials[0]?.texturesPaths?.virtualDielineTexture

    if (!texture) {
      throw new Error("Virtual dieline texture is not defined in render config")
    }

    return texture
  }

  public getSceneBackgroundTextureUrl(editContext: EditContext): undefined {
    return
  }

  public isPrintActiveFor(editContext: EditContext): boolean {
    return true
  }

  public isPrintAvailableFor(editContext: EditContext): boolean {
    return [EditContext.FRONT].includes(editContext)
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext): boolean {
    return false
  }

  public getAvailablePrintingModes(): AvailableProperty[] {
    return []
  }

  public getAvailableFinishes(): AvailableProperty[] {
    return []
  }

  public getAvailableMaterials(): AvailableProperty[] {
    return []
  }

  public getSpaceDimensions(
    editContext: EditContext,
    spaceId: SpaceId
  ): SpaceDimensions {
    return {
      widthCm: 30,
      heightCm: 30,
      widthPx: 3000,
      heightPx: 3000,
    }
  }

  public isEditContextDisabled(editContext: EditContext): boolean {
    return false
  }
}
