import { AssetsController, ProcessParams } from "./assets.controller"

export class LogoPlaceholdersController extends AssetsController {
  public async addLogoPlaceholderSlot(): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      const vdEditor = this.productDriver.getVdEditor(editContext)

      await vdEditor.assetsModule.addLogoPlaceholderSlot(
        spaceId,
        this.shouldSelectAsset
      )

      this.touchDesign()
    })
  }
}
