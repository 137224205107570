import zc from "../../constants"
import { urls } from "./api-endpoint-urls"
import RegionConfig from "../../../../configs/region-config/interfaces/js/region-config"
import { apiClientSetup } from "./api-client-setup"
import {
  apiTokenFromCookie,
  sessionRepoOptions,
} from "../../helpers/api-token.helpers"
import Basil from "shared-libs/src/js/libs/storage/basil.js"
import { Ga4Service } from "../../analytics/google/ga4-service"

type callParams = {
  method: string
  url: string
  data?: object
  config?: object
}

export default class ApiService {
  public client: any

  constructor(client, token) {
    this.client = client
    if (!client) {
      this.client = apiClientSetup(
        `${RegionConfig.getOriginForRestApi()}${zc.API_URL}`,
        (err) => {
          console.error("api error", err)
        }
      )
    }

    this.setToken(token)
    return this
  }

  setToken = (token: string) => {
    if (typeof token === "string") {
      this.client.defaults.headers.common[urls.API_TOKEN_HEADER] = token
    } else {
      if (process.env.NODE_ENV !== "test") {
        console.warn("you are trying to set empty token")
      }
    }
  }

  call = async ({ method, url, data, config }: callParams) => {
    const methodName = method.toLowerCase()

    if (!["get", "post", "put", "patch", "delete"].includes(methodName)) {
      throw "ApiService.call received wrong method"
    }

    // Adding Google Analytics cookie data to custom headers, that is parsed
    // on our backend. We need to do this, because regional domains are using
    // main domain packhelp.com API

    this.client.defaults.headers.common =
      await Ga4Service.decorateHeadersWithGtagData(
        this.client.defaults.headers.common
      )

    // @ts-ignore
    const response = await this.client[methodName](url, data, config)

    return response.data
  }
}
export const apiService = new ApiService(null, apiTokenFromCookie)
export const apiServiceWithCurrentToken = () =>
  new ApiService(null, Basil.cookie.get(sessionRepoOptions.key))
