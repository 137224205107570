import React, { useEffect, useRef, useContext, createContext } from "react"
import cxBinder from "classnames/bind"

import Card, { CardType, HeightType } from "../../../atoms/Card/Card"
import Link from "../../../atoms/Link/Link"
import Button, { ButtonAsLink } from "../../../atoms/Button/Button"
import { ButtonPlainProps, ButtonLinkProps } from "../../../atoms/Button/types"
import { LinkProps } from "../../../atoms/Link/LinkBase"
import { useIsElementUnderneathAnother } from "./hooks"

import { CloseIcon } from "../../../assets/_svgr/icons/Close"

import styles from "./LegacyModal.module.scss"

const cx = cxBinder.bind(styles)

export enum SizeEnumType {
  default = "default",
  big = "big",
  medium = "medium",
  large = "large",
  base = "base",
  mobile = "mobile",
  bare = "bare"
}

export enum SpacingType {
  space4 = "space4",
  space5 = "space5",
  space6 = "space6",
  space7 = "space7",
  space8 = "space8"
}

export enum Alignment {
  left = "left",
  right = "right"
}

export const MODAL_OPEN_BODY_CLASSNAME = styles["body-modal-opened"]
export const wrapperClassname = cx("wrapper")

const ModalContext = createContext({
  contentRef: { current: null } as React.RefObject<HTMLDivElement>
})

export interface LegacyModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean
  closable?: boolean
  closableWithOutsideClick?: boolean
  onCloseAction?: (event?: React.MouseEvent<HTMLElement>) => void
  centerHeader?: boolean
  header?: React.ReactNode
  footer?: React.ReactChild
  size?: SizeEnumType
  fullHeight?: boolean
  marginlessFullHeight?: boolean
  spacing?: SpacingType
  bare?: boolean
  wideSides?: boolean
  e2eTarget?: string
  e2eTargetName?: string
  align?: string
  containerClassName?: string
  noContentSpacing?: boolean
}

export const bodyModalLock = (disable: boolean = false) => {
  if (typeof document != "undefined" && typeof document.body != "undefined") {
    if (disable) {
      document.body.classList.add(MODAL_OPEN_BODY_CLASSNAME)
    } else {
      document.body.classList.remove(MODAL_OPEN_BODY_CLASSNAME)
    }
  }
}

/**
 * @deprecated
 * This component is deprecated and will be replaced once all the instances are updated.
 * If you're creating a new modal,
 * use [new dsl instance]{@link dsl/src/organism/Modal/modal/} instead!
 */
const LegacyModalWrapper = (props: Partial<LegacyModalProps>) => {
  const {
    open,
    children,
    size = SizeEnumType.default,
    bare,
    fullHeight,
    align,
    containerClassName,
    ...other
  } = props

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    bodyModalLock(open)
    return () => bodyModalLock(false)
  }, [open])

  return open ? (
    <ModalContext.Provider value={{ contentRef }}>
      <div
        className={cx(wrapperClassname, {
          "wrapper--mobile": size === SizeEnumType.mobile
        })}
        {...other}
      >
        <div
          className={cx("inner", {
            "inner--mobile": size === SizeEnumType.mobile,
            "inner--medium": size === SizeEnumType.medium,
            "inner--large": size === SizeEnumType.large,
            "inner--big": size === SizeEnumType.big,
            "inner--base": size === SizeEnumType.base,
            "inner--bare": bare,
            "full-height": fullHeight,
            align__right: align === Alignment.right,
            align__left: align === Alignment.left
          })}
        >
          <Card
            type={CardType.Round}
            fluid
            {...(fullHeight && { heightType: HeightType.High })}
          >
            {children}
          </Card>
        </div>
      </div>
    </ModalContext.Provider>
  ) : null
}

interface LegacyButtonModalFooterProps
  extends React.HTMLAttributes<HTMLDivElement> {
  confirm?: {
    props?: ButtonPlainProps | ButtonLinkProps
    content: React.ReactNode
    onClick: (event?: React.MouseEvent<HTMLElement>) => void
  }
  cancel?: {
    props?: LinkProps
    content: React.ReactNode
    onClick: (event?: React.MouseEvent<HTMLElement>) => void
  }
}

/**
 * @deprecated
 * This component is deprecated and will be replaced once all the instances are updated.
 * If you're creating a new modal,
 * use [new dsl instance]{@link dsl/src/organism/Modal/modal/} instead!
 */
const LegacyButtonModalFooter = (props: LegacyButtonModalFooterProps) => {
  const { cancel, confirm } = props
  const { contentRef } = useContext(ModalContext)
  const footerRef = useRef<HTMLDivElement>(null)

  const { isUnderneath: contentBelowFooter } = useIsElementUnderneathAnother(
    contentRef,
    footerRef
  )

  const confirmButton = () => {
    return (
      confirm &&
      (confirm.props && confirm.props.hasOwnProperty("href") ? (
        <ButtonAsLink
          fluidOnMobile
          {...(confirm.props as ButtonLinkProps)}
          e2eTarget="confirm-button"
        >
          {confirm.content}
        </ButtonAsLink>
      ) : (
        <Button
          e2eTargetName="confirm"
          e2eTarget="button"
          fluidOnMobile
          {...(confirm.props as ButtonPlainProps)}
          onClick={(event: React.MouseEvent<Element>) => {
            event.preventDefault()
            confirm.onClick && confirm.onClick()
          }}
        >
          {confirm.content}
        </Button>
      ))
    )
  }

  return (
    <div
      ref={footerRef}
      className={cx({
        footer: true,
        "footer--with-shadow": contentBelowFooter,
        "footer--with-buttons": true
      })}
    >
      {cancel && (
        <div
          className={cx("col", "col--left", {
            ["col--span-all"]: !Boolean(confirm)
          })}
        >
          <Link
            href="#"
            e2eTargetName="cancel-button"
            {...cancel.props}
            onClick={(event: React.MouseEvent<Element>) => {
              if (cancel.props && !cancel.props.href) {
                event.preventDefault()
              }
              cancel.onClick && cancel.onClick()
            }}
          >
            {cancel.content}
          </Link>
        </div>
      )}
      {confirm && (
        <div
          className={cx("col", "col--right", {
            ["col--span-all"]: !Boolean(cancel)
          })}
        >
          {confirmButton()}
        </div>
      )}
    </div>
  )
}

/**
 * @deprecated
 * This component is deprecated and will be replaced once all the instances are updated.
 * If you're creating a new modal,
 * use [new dsl instance]{@link dsl/src/organism/Modal/modal/} instead!
 */
const LegacyBasicModalFooter = (
  props: React.HTMLAttributes<HTMLDivElement>
) => {
  return <div className={styles.footer}>{props.children}</div>
}

/**
 * @deprecated
 * This component is deprecated and will be replaced once all the instances are updated.
 * If you're creating a new modal,
 * use [new dsl instance]{@link dsl/src/organism/Modal/modal/} instead!
 */
const LegacyModal = (props: Partial<LegacyModalProps>) => {
  const {
    closable,
    closableWithOutsideClick,
    children,
    header,
    centerHeader,
    onCloseAction,
    spacing,
    fullHeight,
    noContentSpacing,
    marginlessFullHeight,
    footer,
    e2eTarget = "modal",
    wideSides,
    containerClassName
  } = props

  const { contentRef } = useContext(ModalContext)

  useEffect(() => {
    const handleClick = (e: any) => {
      if (
        (contentRef &&
          contentRef.current &&
          contentRef.current.contains(e.target)) ||
        !closable
      ) {
        return
      }
      closableWithOutsideClick && onCloseAction && onCloseAction()
    }

    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [closable])

  const renderModal = () => (
    <div
      ref={contentRef}
      className={cx(
        "base",
        {
          "base--full-height": fullHeight,
          "base--wide-sides": wideSides
        },
        containerClassName
      )}
      e2e-target={e2eTarget}
      e2e-target-name={props.e2eTargetName}
    >
      {header && (
        <div
          className={cx("header", {
            "header--center": centerHeader
          })}
        >
          {header}
        </div>
      )}
      {closable && (
        <button
          className={styles.close}
          onClick={onCloseAction}
          e2e-target="button"
          e2e-target-name="close"
        >
          <CloseIcon className={styles.icon} />
        </button>
      )}

      <div
        className={cx("content", {
          "content--padding-xsmall": spacing === SpacingType.space4,
          "content--padding-small": spacing === SpacingType.space5,
          "content--padding-medium": spacing === SpacingType.space6,
          "content--padding-big": spacing === SpacingType.space7,
          "content--padding-large": spacing === SpacingType.space8,
          "content--no-margin": noContentSpacing
        })}
      >
        {children}
      </div>
      {footer}
    </div>
  )

  return fullHeight ? (
    <div
      className={cx("full-height-wrapper", {
        "full-height--with-margins": !marginlessFullHeight,
        "full-height--without-margins": marginlessFullHeight
      })}
    >
      {renderModal()}
    </div>
  ) : (
    renderModal()
  )
}

export {
  LegacyModal,
  LegacyModalWrapper,
  LegacyButtonModalFooter,
  LegacyBasicModalFooter,
  LegacyModal as default
}
