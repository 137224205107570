// @ts-nocheck

import _reduce from "lodash/reduce"
import _isObject from "lodash/isObject"
import _concat from "lodash/concat"

// Turns { a: { b: 'str1', c: 'str2' }, d: 123 } to ['str1, 'str2, 123]
export function objectLeafsList(obj, acc = []) {
  return _reduce(
    obj,
    function (leafArray, node) {
      if (!_isObject(node)) {
        return _concat(leafArray, node)
      }
      return objectLeafsList(node, leafArray)
    },
    acc
  )
}
