import { action, makeObservable, observable, autorun } from "mobx"
import { FormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { Notification } from "../types/ui.types"
import { I18N } from "../ui/i18n"
import { AssetsDriver } from "../drivers/assets.driver"
import checkIconUrl from "../ui/assets/_icons/check.svg"

const i18n = I18N.notifications

export class NotificationStore {
  @observable public notifications: Notification[] = []

  private readonly formatMessage: FormatMessage
  private lowQualityNotification?: Notification

  constructor(services: {
    assetsDriver: AssetsDriver
    ee: AllEditorEventsEmitter
    formatMessage: FormatMessage
  }) {
    this.formatMessage = services.formatMessage

    makeObservable(this)

    services.ee.on(
      eventTree.notification.designSaved,
      this.showDesignSaveSuccess.bind(this)
    )

    autorun(() => {
      if (services.assetsDriver.isAnyAssetOverscaled) {
        this.showLowQualityError()
      } else {
        this.hideLowQualityError()
      }
    })
  }

  @action
  public showNotification(notification: Notification): void {
    this.notifications.unshift(notification)

    if (notification.displaySeconds) {
      setTimeout(
        this.hideNotification.bind(this, notification),
        notification.displaySeconds * 1000
      )
    }
  }

  @action
  public hideNotification(notification: Notification): void {
    this.notifications = this.notifications.filter(
      ({ message }) => message !== notification.message
    )
  }

  public showDuplicationSuccess(): void {
    const i18n = I18N.notifications.designDuplication

    this.showNotification({
      type: "success",
      message: this.formatMessage({ id: i18n.success }),
      displaySeconds: 5,
      iconUrl: checkIconUrl,
    })
  }

  public showConfigurationChangedWarning(previousDesignUrl: string): void {
    const i18n = I18N.notifications.configurationChanged

    this.showNotification({
      type: "warning",
      message: this.formatMessage({ id: i18n.message }),
      link: {
        label: `${this.formatMessage({
          id: i18n.goBackLink,
        })} ${this.formatMessage({
          id: i18n.goBackTo,
        })}`,
        url: previousDesignUrl,
      },
      displaySeconds: 10,
      closeable: true,
    })
  }

  private showLowQualityError(): void {
    if (this.lowQualityNotification) {
      return
    }

    this.lowQualityNotification = {
      message: this.formatMessage({ id: i18n.lowQuality.message }),
      type: "error",
    }

    this.showNotification(this.lowQualityNotification)
  }

  private hideLowQualityError(): void {
    if (!this.lowQualityNotification) {
      return
    }

    this.hideNotification(this.lowQualityNotification)
    this.lowQualityNotification = undefined
  }

  private showDesignSaveSuccess(): void {
    this.showNotification({
      message: this.formatMessage({ id: i18n.saveDesign.success }),
      type: "success",
      displaySeconds: 3,
      iconUrl: checkIconUrl,
    })
  }
}
