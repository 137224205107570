import { IEvent } from "fabric/fabric-impl"
import _throttle from "lodash/throttle"
import {
  PackhelpCanvas,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import {
  ActiveObjectEvent,
  CanvasEvent,
} from "../../../../../events/partials/domain.events"
import { Intersection } from "../../../collision-detection/intersection"
import { SafeZoneCreator } from "./safe-zone.creator"
import {
  EditableSpaceConfig,
  SpaceId,
} from "../../../../../libs/products-render-config/types"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import {
  isSafeOperatingZone,
  isSafeZone,
} from "../../../../../types/asset.types"

export class SafeZonesModule {
  private isSafeZoneVisible = false
  private readonly throttledProcess: (event: any) => void

  constructor(private readonly vdEditor: VirtualDielineEditor) {
    this.throttledProcess = _throttle(this.process, 100)
  }

  public async createSafeZones(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingSafeZones()

    const safeZoneCreator = new SafeZoneCreator(
      this.vdEditor.editContext,
      this.vdEditor.dielineNavigator,
      this.vdEditor.productRenderPilot
    )

    for (let index = 0; index < spaceConfigs.length; index++) {
      const config = spaceConfigs[index]

      this.vdEditor.addOnCanvas(
        await safeZoneCreator.createSafeZoneForDisplay(config.spaceId, index)
      )

      this.vdEditor.addOnCanvas(
        await safeZoneCreator.createSafeOperatingZoneForCalculation(
          config.spaceId,
          index
        )
      )

      this.canvas.requestRenderAll()
    }
  }

  public switchOnEvents() {
    this.canvas.on(CanvasEvent.selectionCreated, this.throttledProcess)
    this.canvas.on(CanvasEvent.objectMoving, this.throttledProcess)
    this.canvas.on(CanvasEvent.objectScaling, this.throttledProcess)
    this.canvas.on(CanvasEvent.objectRotating, this.throttledProcess)
    this.canvas.on(CanvasEvent.textChanged, this.throttledProcess)
    this.canvas.on(CanvasEvent.objectModified, this.throttledProcess)
    this.canvas.on(CanvasEvent.objectRestyled, this.throttledProcess)
    this.canvas.on(CanvasEvent.selectionCreated, this.throttledProcess)
    this.canvas.on(CanvasEvent.selectionUpdated, this.throttledProcess)
    this.canvas.on(CanvasEvent.selectionCleared, this.hideSafeZone)
  }

  public switchOffEvents() {
    this.canvas.off(CanvasEvent.selectionCreated, this.throttledProcess)
    this.canvas.off(CanvasEvent.objectMoving, this.throttledProcess)
    this.canvas.off(CanvasEvent.objectScaling, this.throttledProcess)
    this.canvas.off(CanvasEvent.objectRotating, this.throttledProcess)
    this.canvas.off(CanvasEvent.textChanged, this.throttledProcess)
    this.canvas.off(CanvasEvent.objectModified, this.throttledProcess)
    this.canvas.off(CanvasEvent.objectRestyled, this.throttledProcess)
    this.canvas.off(CanvasEvent.selectionCreated, this.throttledProcess)
    this.canvas.off(CanvasEvent.selectionUpdated, this.throttledProcess)
    this.canvas.off(CanvasEvent.selectionCleared, this.hideSafeZone)
  }

  private process = (event: IEvent) => {
    const object = (event.target || event.selected?.[0]) as PackhelpObject

    if (!object || !object.canvas) {
      this.setActiveSpaceSafeZoneVisibility(false)

      return
    }

    if (object.isSpaceClippingDisabled) {
      this.processSpaceEdges(object)
    } else {
      this.processSafeZones(object)
    }
  }

  private hideSafeZone = () => {
    this.setActiveSpaceSafeZoneVisibility(false)
  }

  private processSpaceEdges(object: PackhelpObject): void {
    this.hideSafeZone()

    const isSpaceEdgeCrossed = this.isSpaceEdgeCrossed(object)

    if (
      !!object.isSpaceEdgeCrossed === isSpaceEdgeCrossed &&
      !!object.isSafeZoneTouched === false
    ) {
      return
    }

    object.set({
      isSpaceEdgeCrossed,
      isSafeZoneTouched: false,
    })

    this.vdEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  private processSafeZones(object: PackhelpObject): void {
    const isSafeZoneTouched = this.isSafeZoneTouched(object)
    this.setActiveSpaceSafeZoneVisibility(isSafeZoneTouched)

    if (
      !!object.isSafeZoneTouched === isSafeZoneTouched &&
      !!object.isSpaceEdgeCrossed === false
    ) {
      return
    }

    object.set({
      isSafeZoneTouched,
      isSpaceEdgeCrossed: false,
    })

    this.vdEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  private isSpaceEdgeCrossed(object: PackhelpObject): boolean {
    const safeZone = this.getActiveSpaceSafeZone()

    if (!this.activeSpaceId || !safeZone) {
      return false
    }

    const spaceBoundingRect = safeZone.getBoundingRect()
    const objectBoundingRect = object.getBoundingRect()

    const isLeftEdgeCrossed = objectBoundingRect.left <= spaceBoundingRect.left
    const isTopEdgeCrossed = objectBoundingRect.top <= spaceBoundingRect.top
    const isRightEdgeCrossed =
      objectBoundingRect.left + objectBoundingRect.width >=
      spaceBoundingRect.left + spaceBoundingRect.width
    const isBottomEdgeCrossed =
      objectBoundingRect.top + objectBoundingRect.height >=
      spaceBoundingRect.top + spaceBoundingRect.height

    return (
      isLeftEdgeCrossed ||
      isTopEdgeCrossed ||
      isRightEdgeCrossed ||
      isBottomEdgeCrossed
    )
  }

  private isSafeZoneTouched(object: PackhelpObject): boolean {
    if (object.maskParentId) {
      return false
    }

    object.setCoords()

    const safeOperatingZone = this.getActiveSpaceSafeOperatingZone()

    return (
      !!safeOperatingZone && Intersection.intersect(object, safeOperatingZone)
    )
  }

  private setActiveSpaceSafeZoneVisibility(isVisible: boolean) {
    if (this.isSafeZoneVisible === isVisible) {
      return
    }

    this.vdEditor.eventEmitter.emit("safeZonesToggled", isVisible)

    const safeZone = this.getActiveSpaceSafeZone()

    safeZone?.set({
      opacity: isVisible ? 1 : 0,
    })

    this.isSafeZoneVisible = isVisible
  }

  private getActiveSpaceSafeZone(): PackhelpObject | undefined {
    return this.getSafeZones().find(
      (obj) => obj.originSpaceArea === this.activeSpaceId
    )
  }

  private getActiveSpaceSafeOperatingZone(): PackhelpObject | undefined {
    return this.getSafeOperatingZones().find(
      (obj) => obj.originSpaceArea === this.activeSpaceId
    )
  }

  private getSafeZones(): PackhelpObject[] {
    return this.canvas.getObjects().filter(isSafeZone)
  }

  private getSafeOperatingZones(): PackhelpObject[] {
    return this.canvas.getObjects().filter(isSafeOperatingZone)
  }

  private clearExistingSafeZones() {
    for (const safeZone of this.getSafeZones()) {
      this.canvas.remove(safeZone)
    }

    for (const safeOperatingZone of this.getSafeOperatingZones()) {
      this.canvas.remove(safeOperatingZone)
    }
  }

  private get activeSpaceId(): SpaceId | null {
    return this.dielineNavigator.getActiveSpaceId()
  }

  private get dielineNavigator(): DielineNavigator {
    return this.vdEditor.dielineNavigator
  }

  private get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }
}
