import { ApiEnvConfig } from "../types"
import _merge from "lodash/merge"

const defaultEcommerceConfig: Partial<ApiEnvConfig["ecommerce"]> = {
  mode: "rest",
  features: {
    auth: false,
    user: {
      data: false,
      logo: false,
    },
    ecommerce: {
      basic: false,
      dynamicQrCode: false,
      manualSave: false,
      fsc: false,
      trees: false,
      patterns: false,
      templates: false,
      productSwitcher: false,
      productConfigurator: false,
      customSizes: false,
      dynamicPrices: false,
      quoteRequests: false,
      designLater: false,
    },
  },
}

export class ApiConfig {
  public readonly base: ApiEnvConfig["base"]
  public readonly design: ApiEnvConfig["design"]
  public readonly ecommerce: ApiEnvConfig["ecommerce"]
  public readonly strapi: ApiEnvConfig["strapi"]
  public readonly assets: ApiEnvConfig["assets"]
  public readonly pim: ApiEnvConfig["pim"]

  constructor(config: ApiEnvConfig) {
    this.base = config.base
    this.design = config.design
    this.ecommerce = _merge(defaultEcommerceConfig, config.ecommerce)
    this.strapi = config.strapi
    this.assets = config.assets
    this.pim = config.pim
  }
}
