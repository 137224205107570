import React from "react"
import { TileList } from "../../../atoms/Tile/TileList"
import { Tile } from "../../../atoms/Tile/Tile"
import { LinkProps, TabSection } from "../Tab/TabSection"
import { ElementsTabElement } from "../../../../../types/ui.types"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { Dropdown } from "../../../atoms/Dropdown/Dropdown"
import Badge from "dsl/src/atoms/Badge/Badge"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { DropdownButton } from "../../../atoms/Dropdown/DropdownButton"
import { AnalyticsSection } from "../../../types"

interface ElementsSectionProps<T> {
  id: string
  title: string
  elements: ElementsTabElement<T>[]
  onElementClick: (asset: T) => void
  onExpandClick?: () => void
  expanded?: boolean
  initialElementsCount?: number
  analyticsSection?: AnalyticsSection
}

export function ElementsSection<T>({
  id,
  title,
  elements,
  onElementClick,
  onExpandClick,
  expanded = false,
  initialElementsCount = 3,
  analyticsSection,
}: ElementsSectionProps<T>) {
  const t = useTranslate()

  if (elements.length === 0) {
    return null
  }

  const isHeaderVisible = !expanded
  const isLinkVisible =
    isHeaderVisible && onExpandClick && elements.length > initialElementsCount

  const sectionTitle = isHeaderVisible ? title : undefined
  const link: LinkProps | undefined = isLinkVisible
    ? {
        label: t(I18N.generic.seeAll),
        onClick: onExpandClick,
        analyticsSection: analyticsSection,
        analyticsType: "button",
        analyticsName: "see-all",
        analyticsId: id,
        e2eTarget: "button",
        e2eTargetName: "expand",
      }
    : undefined

  return (
    <TabSection title={sectionTitle} link={link} e2eTargetName={id}>
      <TileList cols={3}>
        {elements.map((element, index) => {
          if (!expanded && index >= initialElementsCount) {
            return null
          }

          const isDisabled = element.loading
          const isDropdownVisible = element.actions?.length && !isDisabled

          return (
            <Tile
              onClick={() => onElementClick(element.asset)}
              disabled={isDisabled}
              key={element.id}
              contextMenu={
                isDropdownVisible ? (
                  <Dropdown>
                    {element.actions!.map((action) => {
                      const label = t(action.titleIntl)

                      return (
                        <DropdownButton
                          key={label}
                          label={label}
                          iconUrl={action.iconUrl}
                          onClick={action.onClick}
                          analyticsSection={analyticsSection}
                          analyticsType="context-menu"
                          analyticsName="user-image"
                          analyticsId={action.type}
                        />
                      )
                    })}
                  </Dropdown>
                ) : undefined
              }
              badge={
                element.badge ? (
                  <Badge>{t(element.badge.labelIntl)}</Badge>
                ) : undefined
              }
              analyticsSection={analyticsSection}
              analyticsType="thumbnail"
              analyticsName={element.type}
              analyticsId={element.title}
              e2eTarget="button"
              e2eTargetName={id}
            >
              {element.loading ? (
                <Loader
                  type={LoaderTypes.circular}
                  color={LoaderColors.gray}
                  e2eTarget="loader"
                  e2eTargetName="upload"
                />
              ) : (
                <figure>
                  <img
                    src={element.previewUrl}
                    alt={element.title}
                    loading="lazy"
                  />
                </figure>
              )}
            </Tile>
          )
        })}
      </TileList>
    </TabSection>
  )
}
