import React, { PropsWithChildren } from "react"
import styles from "./OptionPickerList.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export interface OptionPickerList {
  type?: "horizontal" | "vertical"
}

export function OptionPickerList({
  children,
  type = "horizontal",
}: PropsWithChildren<OptionPickerList>) {
  return <div className={cx("wrapper", type)}>{children}</div>
}
