export enum QuotingAppsI18nId {
  AddToQuote = "pqs.form.add-to-quote",
  AdditionalNotesLabel = "generic.additional-notes",
  AdditionalNotesPlaceholder = "generic.additional-notes.placeholder",
  AdditionalNotesSubtitle = "pqs.form.additional-notes.subtitle",
  ContactUs = "generic.contact-us",
  DesignUploadLabel = "product-configurator-app.design-upload.label",
  DesignUploadHint = "product-configurator-app.design-upload.hint",
  DesignDownloadLabel = "product-configurator-app.design-download.label",
  DesignDownloadHint = "product-configurator-app.design-download.hint",
  DesignDownloadDieline = "product-configurator-app.design-download.dieline",
  DesignDownloadGuidelines = "product-configurator-app.design-download.guidelines",
  DesignUploadFormats = "product-configurator-app.design-upload.supported-formats",
  Or = "generic.or",
  Done = "generic.done",
  Net = "generic.net",
  Tip = "generic.tip",
  Optional = "form.optional",
  PerPiece = "box-configurator.addons-picker.price-piece-text",
  PriceDealSave = "generic.save-money",
  PriceEstimationHeader = "pqs.form.price-estimation.header",
  PriceEstimationNote = "pqs.form.price-estimation.note",
  PriceNeedsVerification = "pqs.form.price-needs-verification",
  QuantityPieces = "box-editor.bottom.section-values.quantity",
  QuoteLabel = "generic.quote",
  ReadyToProceed = "pqs.form.ready-to-proceed",
  ShippingIncluded = "generic.shipping-included",
  StillNotSure = "generic.still-not-sure",
  WhatIsPlus = "plus-journey.what-is-plus",
  WhatIsPlusTooltipContent = "plus-journey.what-is-plus.tooltip.content",
  WhatIsPlusTooltipFeat1 = "plus-journey.what-is-plus.tooltip.feature-1",
  WhatIsPlusTooltipFeat2 = "plus-journey.what-is-plus.tooltip.feature-2",
  WhatIsPlusTooltipFeat3 = "plus-journey.what-is-plus.tooltip.feature-3",
  WhatIsPlusTooltipFeat4 = "plus-journey.what-is-plus.tooltip.feature-4",
  WhatIsPlusTooltipFeat5 = "plus-journey.what-is-plus.tooltip.feature-5",
  WhatIsPlusTooltipHeader = "plus-journey.what-is-plus.tooltip.header",
  WhiteOnKraftPrintDetailsLessCoverage = "product-configurator-app.white-on-kraft-details.less-coverage",
  WhiteOnKraftPrintDetailsLessSaturatedDesc = "product-configurator-app.white-on-kraft-details.difference.less-saturated.desc",
  WhiteOnKraftPrintDetailsLessSaturatedHeader = "product-configurator-app.white-on-kraft-details.difference.less-saturated.header",
  WhiteOnKraftPrintDetailsMoreSaturatedDesc = "product-configurator-app.white-on-kraft-details.difference.more-saturated.desc",
  WhiteOnKraftPrintDetailsMoreSaturatedHeader = "product-configurator-app.white-on-kraft-details.difference.more-saturated.header",
  WhiteOnKraftPrintDetailsPlus = "product-configurator-app.white-on-kraft-details.print-technology.plus",
  WhiteOnKraftPrintDetailsSeeTheDifference = "product-configurator-app.white-on-kraft-details.see-the-difference",
  WhiteOnKraftPrintDetailsStudio = "product-configurator-app.white-on-kraft-details.print-technology.studio",
  FoilTipInfo = "product-configurator-app.foil-tip-info",
  Specification = "custom-order.offer-review.specification",
  ScrollForMore = "generic.scroll-for-more",
  Outside = "product-configurator-app.option-picker.outside",
  Inside = "product-configurator-app.option-picker.inside",
  SizeLabel = "form.request-a-quote.package-size.label",
  QuantityLabel = "order-flow-summary.title.quantity",
  OutsideDimensions = "box-configurator.box-dimensions.outside",
  SleeveWidth = "generic.dimensions.sleeveWidth",
  Remove = "cart.item.buttons.remove",
}
