import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { ImageSection } from "./ImageSection"

export const ShapeSection = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { shapeToolbarController } = containerSet.ui
  const { isToolbarAvailable } = shapeToolbarController

  if (!isToolbarAvailable) {
    return null
  }

  return <ImageSection controller={shapeToolbarController} />
})
