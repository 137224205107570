import React from "react"
import Typography, { TType } from "../Typography/Typography"
import SelectBase from "./SelectBase"
import { SelectProps } from "./types"

const Select = (props: SelectProps) => {
  const {
    size,
    items,
    renderItem,
    withMenuFluid,
    autoWidth,
    e2eTarget = "select",
    ...other
  } = props
  return (
    <SelectBase
      renderItem={
        renderItem
          ? renderItem
          : (item: any) => {
              return (
                <Typography
                  type={TType.Body15_350}
                  e2e-target="list-item"
                  e2e-target-name={item.name}
                >
                  {item.name}
                </Typography>
              )
            }
      }
      items={items}
      size={size}
      withMenuFluid={withMenuFluid}
      autoWidth={autoWidth}
      e2e-target={e2eTarget}
      {...other}
    />
  )
}

export { Select, Select as default }
