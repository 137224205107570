export type ParsedAnalyticsOptionsParamsType = {
  business_unit: string
  location: string
  product_region: string
}

// Get data for Analytics:
// - BussinesUnit: plus/studio etc.
// - Location: homepage/shop etc.
// - ProductRegion: en/de etc. - language
export const getParsedAnalyticsOptionsParams =
  (): ParsedAnalyticsOptionsParamsType => {
    if (!window.zpkj || !window.zpkj.analyticsOptionsParams)
      return {
        business_unit: "",
        location: "",
        product_region: "",
      }

    return JSON.parse(window.zpkj.analyticsOptionsParams)
  }
