import React, { PropsWithChildren } from "react"
import styles from "./ModalSection.module.scss"

export interface ModalSectionProps {
  title?: string
}

export function ModalSection({
  title,
  children,
}: PropsWithChildren<ModalSectionProps>) {
  return (
    <div className={styles.wrapper}>
      {title && <h4 className={styles.title}>{title}</h4>}
      <div className={styles.content}>{children}</div>
    </div>
  )
}
