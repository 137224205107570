import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import RegionConfig, {
  Locale,
  PackhelpRegion,
} from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import { ListingType } from "../../../../shared-views/listings/utils/listing.types"
import { DefaultItemsPerPage, MinPageNumber } from "../utils/consts"
import {
  ListingApiResponse,
  ListingDataPayload,
} from "../utils/listing-services.types"

export class ListingApiService {
  protected readonly ax: AxiosInstance

  constructor(
    protected readonly apiUrl: string,
    protected readonly token?: string
  ) {
    this.ax = this.getAxiosInstance()
  }

  public async getDataForParameters(
    payload: ListingDataPayload
  ): Promise<ListingApiResponse> {
    const response = await this.ax.get("/listing", { params: payload })

    return response.data
  }

  public async getAllData(params: {
    locale: Locale
    region: PackhelpRegion
    listingType: ListingType
  }): Promise<ListingApiResponse> {
    const {
      locale = RegionConfig.getCurrentLocaleIso639(),
      region = RegionConfig.getCurrentRegion(),
      listingType,
    } = params
    const requestParams: ListingDataPayload = {
      filters: {},
      category: null,
      languageId: locale,
      page: MinPageNumber,
      itemsPerPage: DefaultItemsPerPage,
      listingType: listingType,
      region: region!,
    }

    return await this.getDataForParameters(requestParams)
  }

  public async getAvailableQueryStringParams(): Promise<string[]> {
    const region = RegionConfig.getCurrentRegion() as PackhelpRegion
    const locale = RegionConfig.getCurrentLocaleIso639()

    return this.ax
      .get(`/filters?region=${region}&lang=${locale}`)
      .then((response) => response.data.data.map((filter) => filter.slug))
  }

  private getAxiosInstance(): AxiosInstance {
    const params: AxiosRequestConfig = {
      baseURL: this.apiUrl,
    }

    if (this.token) {
      params.headers = {
        Authorization: `Bearer ${this.token}`,
      }
    }

    return axios.create(params)
  }
}
