import { Debug } from "./logger"
let debug = Debug("ph:editor:env2")

export function getS3Config() {
  const S3 = {
    editorAssets: "",
  }

  if (process && process.env) {
    const env = process.env

    // Asset URL
    const assetUrl = env.REACT_APP_PH_EDITOR_ASSETS_URL
    if (typeof assetUrl === "string" && assetUrl.length > 10) {
      S3.editorAssets = assetUrl
    } else {
      throw new Error("PH_EDITOR_ASSETS_URI not defined")
    }

    // ENV 2 URL
  } else {
    throw new Error("No env defind")
  }

  return S3
}

debug("Env Config: ", getS3Config())
