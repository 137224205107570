import React from "react"
import { ArrowDown } from "../../../../assets/_svgr/UI/ArrowDown"
import cxBinder from "classnames/bind"
import styles from "./PickerValueOptionsToggle.module.scss"

const cx = cxBinder.bind(styles)

interface PickerValueOptionsToggleProps {
  isOpen: boolean
  isDisabled?: boolean
  onClick: () => void
}
export const PickerValueOptionsToggle = (
  props: PickerValueOptionsToggleProps
) => {
  const { isOpen, isDisabled, onClick } = props

  const handleClick = () => {
    onClick()
  }

  return (
    <button
      className={styles["button"]}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <ArrowDown
        className={cx("icon", {
          "icon--active": isOpen,
          "icon--disabled": isDisabled
        })}
      />
    </button>
  )
}
