import THREE from "../../../../libs/vendors/THREE"

export class PivotManager {
  private readonly pivots: Record<string, THREE.Group> = {}

  public getPivot(id: string): THREE.Group {
    if (!this.pivots[id]) {
      this.pivots[id] = new THREE.Group()
    }

    return this.pivots[id]
  }

  public addToPivot(id: string, mesh: THREE.Mesh): void {
    const pivot = this.getPivot(id)

    pivot.add(mesh)
  }
}
