import React, { PropsWithChildren } from "react"
import styles from "./Tooltip.module.scss"

interface TooltipProps {
  text: string
}

export function Tooltip({ children, text }: PropsWithChildren<TooltipProps>) {
  return (
    <div className={styles["tooltip-wrapper"]}>
      {children}
      <div className={styles.tooltip}>{text}</div>
    </div>
  )
}
