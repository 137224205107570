import { SvgElement } from "./svg-element"
import { PathCommand, PathSegment, Point, Segment, Size } from "../types"
import _chunk from "lodash/chunk"
import { BezierCurve } from "../bezier-curve"
import { isSvgLine, isSvgPath } from "./type-guards"
import _compact from "lodash/compact"

export class SvgPath extends SvgElement {
  public getSegment(): Segment {
    const path = this.getPathElements()

    return {
      start: {
        x: parseFloat(path[1]),
        y: parseFloat(path[2]),
      },
      end: {
        x: parseFloat(path[path.length - 2]),
        y: parseFloat(path[path.length - 1]),
      },
    }
  }

  public flip(
    viewBox: Size,
    config: { horizontal: boolean; vertical: boolean }
  ) {
    const segments = this.getPathSegments()
    const definition: string[] = []

    for (const segment of segments) {
      for (let i = 0; i < segment.values.length; i++) {
        if (i % 2 === 0 && config.horizontal) {
          segment.values[i] = viewBox.width - segment.values[i]
        }

        if (i % 2 === 1 && config.vertical) {
          segment.values[i] = viewBox.height - segment.values[i]
        }
      }

      definition.push(`${segment.command} ${segment.values.join(" ")}`)
    }

    this.node.attributes.d = definition.join(" ").trim()
  }

  public add(element: SvgElement) {
    const definition = _compact(
      this.getDefinition().replace(/M|Z/g, "").trim().split(" L ")
    )

    if (isSvgLine(element)) {
      const segment = element.getSegment()
      definition.push(`${segment.start.x} ${segment.start.y}`)
    }

    if (isSvgPath(element)) {
      definition.push(element.getDefinition().replace("M", "").trim())
    }

    this.node.attributes.d = `M ${definition.join(" L ")} Z`
  }

  public resize(center: Point, ratio: number, offset: Point) {
    const path = this.getPathElements()

    const points = _chunk(
      path.map(parseFloat).filter((value) => !isNaN(value)),
      2
    )

    for (const point of points) {
      const [x, y] = point

      const diffX = Math.abs(center.x - x) * (ratio - 1)
      const diffY = Math.abs(center.y - y) * (ratio - 1)
      let xMultiplier = 1
      let yMultiplier = 1

      if (x <= center.x && y <= center.y) {
        xMultiplier = -1
        yMultiplier = -1
      } else if (x >= center.x && y <= center.y) {
        yMultiplier = -1
      } else if (x <= center.x && y >= center.y) {
        xMultiplier = -1
      }

      point[0] += diffX * xMultiplier + offset.x
      point[1] += diffY * yMultiplier + offset.y
    }

    const flatPoints = points.flat()

    for (let i = 0; i <= path.length; i++) {
      if (!isNaN(parseFloat(path[i]))) {
        path[i] = flatPoints.shift()!.toString()
      }
    }

    this.node.attributes.d = path.join(" ")
  }

  public getDefinition(): string {
    return this.node.attributes.d
  }

  public reverse() {
    const path = this.getPathElements()

    const x1 = path[1]
    const y1 = path[2]
    const x2 = path[path.length - 2]
    const y2 = path[path.length - 1]

    path[1] = x2
    path[path.length - 2] = x1
    path[2] = y2
    path[path.length - 1] = y1

    this.node.attributes.d = path.join(" ")
  }

  public getExtremePoints(): Point[] {
    const start = { x: Infinity, y: Infinity }
    const end = { x: -Infinity, y: -Infinity }

    const pathSegments = this.getPathSegments()

    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i]
      const points = this.getSegmentPoints(segment, pathSegments[i - 1])

      for (const point of points) {
        start.x = Math.min(start.x, point.x)
        start.y = Math.min(start.y, point.y)

        end.x = Math.max(end.x, point.x)
        end.y = Math.max(end.y, point.y)
      }
    }

    return [start, end]
  }

  private getSegmentPoints(
    segment: PathSegment,
    previousSegment: PathSegment
  ): Point[] {
    const mapValuesToPoints = (values: number[]) => {
      return _chunk(values, 2).map((coords) => ({
        x: coords[0],
        y: coords[1],
      }))
    }

    if (segment.command === PathCommand.quadraticBezierCurveTo) {
      const extremePoint = new BezierCurve([
        {
          x: previousSegment.values[previousSegment.values.length - 2],
          y: previousSegment.values[previousSegment.values.length - 1],
        },
        {
          x: segment.values[0],
          y: segment.values[1],
        },
        {
          x: segment.values[2],
          y: segment.values[3],
        },
      ]).getPointAt(0.5)

      return mapValuesToPoints([
        extremePoint.x,
        extremePoint.y,
        segment.values[segment.values.length - 2],
        segment.values[segment.values.length - 1],
      ])
    }

    return mapValuesToPoints(segment.values)
  }

  private getPathSegments(): PathSegment[] {
    const path = this.getPathElements()
    const pathSegments: PathSegment[] = []

    for (const pathElement of path) {
      const numericValue = parseFloat(pathElement)

      if (isNaN(numericValue)) {
        pathSegments.push({
          command: pathElement,
          values: [],
        })
      } else {
        pathSegments[pathSegments.length - 1].values.push(numericValue)
      }
    }

    return pathSegments
  }

  private getPathElements(): string[] {
    return this.getDefinition().split(" ")
  }
}
