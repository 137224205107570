import _compact from "lodash/compact"
import type { EditorMode } from "../libs/products-render-config/types"
import { FontsFinder } from "../libs/services/fonts-loader-service/fonts-finder"

import { Debug } from "../services/logger"
import { ee, eventTree } from "../events/editor.events"
import ProductDriver from "../drivers/product.driver"
import { CanvasController } from "../controllers/canvas.controller"

import type { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import type { EcommerceMaybeContainer } from "../_containers/ecommerce-maybe.container"
import type { AssetsStore } from "./assets.store"
import type { Cart } from "./cart.store"
import type { ProductDesignStore } from "./product-design.store"
import type { RootStore } from "./root.store"
import type {
  EditorDesign,
  DbyDesign,
  DesignItemType,
} from "../types/design.types"
import type { FontsConfigStore } from "./fonts-config.store"
import { PatternsStore } from "./patterns.store"
import { PatternMaybeContainer } from "../_containers/asset-pattern-maybe.container"
import { FscCertificateStore } from "./fsc-certificate.store"
import { FscCertificateMaybeContainer } from "../_containers/fsc-certificate-maybe.container"
import { LoadProductDesignController } from "../controllers/product-design/load-product-design.controller"
import { TemplatesMaybeContainer } from "../_containers/templates-maybe.container"
import { AppConfig } from "../app-config/app.config"
import { TemplatesStore } from "./templates.store"
import { User } from "../types/session.types"

// Controllers
let debug = Debug("ph:root.store-starter")

export class RootStoreStarter {
  private patternsStore?: PatternsStore
  private fscCertificateStore?: FscCertificateStore
  private templatesStore?: TemplatesStore

  constructor(
    private readonly appConfig: AppConfig,
    private readonly editorMode: EditorMode,

    private readonly uri: UrlManipulatorProvider,

    // Design and Product
    private readonly assetStore: AssetsStore,
    private readonly productDriver: ProductDriver,
    private readonly productDesignStore: ProductDesignStore,
    private readonly loadProductDesignController: LoadProductDesignController,
    private readonly fontsConfigStore: FontsConfigStore,
    private readonly ecommerceContainer: EcommerceMaybeContainer,
    patternsContainer: PatternMaybeContainer,
    fscCertificateContainer: FscCertificateMaybeContainer,
    templatesContainer: TemplatesMaybeContainer,
    private rootStore: RootStore
  ) {
    this.templatesStore = templatesContainer.templatesStore

    if (fscCertificateContainer.available) {
      this.fscCertificateStore = fscCertificateContainer.fscCertificateStore
    }

    if (patternsContainer.available) {
      this.patternsStore = patternsContainer.patternsStore
    }
  }

  public launch({ user, cart }: { user: User; cart?: Cart }) {
    this.initSubscribers()
    // TODO: unify with auth controller
    ee.emit(eventTree.session.receivedUser, user)

    this.loadDesignDependencies(cart)
    this.loadAsyncEditorDependencies()

    if (cart) {
      ee.emit(eventTree.cart.cartReceived, cart)
      this.productDesignStore.updateDesignInCartState(cart)
    }

    this.uri.handleRoute()

    if (this.editorMode === "dby") {
      this.productDriver.initDby()
    }

    ee.emit(eventTree.ui.MainUISceneWithSKUViewed, {
      url: window.location.href,
    })
  }

  private redirectToCart() {
    let url = this.uri.getCrossSellUrl({
      withPaid: this.ecommerceContainer.taxStore?.taxConfig.hasTax === true,
    })

    if (!url) {
      url = this.uri.getCartUrl({})
    }

    window.location.href = url
  }

  private initSubscribers() {
    new CanvasController(ee, this.productDriver)

    ee.on(eventTree.pd.created, (productDesign) => {
      if (!productDesign.id) {
        return
      }

      this.uri.setRouteToDesign({
        designId: productDesign.id.toString(),
      })
    })

    // Listen to auth events and make decisions
    ee.on(eventTree.cart.productAddedProcessed, () => this.redirectToCart())
  }

  private async loadDesignDependencies(cart?: Cart): Promise<void> {
    const isDesignLoaded = !!this.productDesignStore.state.meta.id

    if (isDesignLoaded) {
      return
    }

    const designId = this.uri.getDesignIdParam()

    if (designId) {
      await this.loadDesignDependenciesByDesignId(designId)
    } else {
      this.loadProductDesignController.initEmptyDesign()
    }

    if (cart) {
      this.productDesignStore.updateDesignInCartState(cart)
      this.productDesignStore.updateDraftDesignInCartState(cart)
    }
  }

  private async loadDesignDependenciesByDesignId(designId: string) {
    debug(`loading via routing a design with an id: ${designId}`)

    try {
      const design = await this.loadProductDesignController.loadDesignById(
        designId
      )

      const { meta, data } = design

      if (data.type === "editor") {
        await this.fontsConfigStore.preloadDesignFonts(new FontsFinder(data))
      }

      this.ecommerceContainer.plantingTreesStore?.countPriceAndAmount(
        this.productDriver.productStore.product,
        meta.quantity
      )

      this.setActiveDesignItems(design)
      this.templatesStore?.setIsTemplateSafeToSet(false)
    } catch (error) {
      console.log(error)
    }
  }

  private setActiveDesignItems(design: EditorDesign | DbyDesign) {
    const getDesignItemByVariantType = (variantType: DesignItemType) => {
      return design.meta.designItems.find(
        (designItem) => designItem.variantType === variantType
      )
    }

    const patternDesignItem = getDesignItemByVariantType("pattern")
    if (patternDesignItem && design.data.type === "editor") {
      this.patternsStore?.setActivePatternIdByDesignItemAttributes({
        designItem: patternDesignItem,
        virtualDielines: design.data.virtualDielines,
      })
    }

    const templateDesignItem = getDesignItemByVariantType("design-template")
    if (templateDesignItem) {
      this.templatesStore?.setSelectedTemplateIdByVariantId(
        templateDesignItem.variantId
      )
    }

    const fscDesignItem = getDesignItemByVariantType("service-fsc-certificate")
    if (fscDesignItem) {
      this.fscCertificateStore?.setIsSelected(true)
    }

    this.productDesignStore.setDesignItems(
      _compact([
        this.patternsStore?.activePattern,
        this.templatesStore?.selectedTemplate,
        this.fscCertificateStore?.isSelected &&
          this.fscCertificateStore.fscCertificate,
      ])
    )
  }

  private loadAsyncEditorDependencies() {
    const { productRenderPilot } = this.productDriver.state
    const { text, elements } = productRenderPilot.uiConfig.tabs

    if (text) {
      this.rootStore.textsController.preloadPredefinedTexts()
    }

    if (elements) {
      this.rootStore.shapesStore.loadShapes()
      this.rootStore.ecoShapesStore.loadEcoShapes()
    }

    this.fscCertificateStore?.loadShapes()
  }
}
