import fabric from "editor/src/libs/vendors/Fabric"
import { MaskPosition, MaskScale } from "../types"
import { MaskCalculator } from "./mask.calculator"
import {
  PackhelpMaskObject,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import { MaskParentCalculator } from "./mask-parent.calculator"
import { CANVAS_DIM } from "../../../../../../types"

export class TempLayerClippingMaskCalculator {
  private maskCalculator: MaskCalculator
  private maskParentCalculator: MaskParentCalculator

  constructor(
    private readonly maskParent: PackhelpObject,
    private readonly objectMask: PackhelpMaskObject,
    private readonly tempClippingMask: fabric.Object
  ) {
    this.maskCalculator = new MaskCalculator(this.maskParent, this.objectMask)
    this.maskParentCalculator = new MaskParentCalculator(this.maskParent)
  }

  public calcPosition(): MaskPosition {
    return {
      left: this.objectMask.left! - CANVAS_DIM / 2,
      top: this.objectMask.top! - CANVAS_DIM / 2,
    }
  }

  public calcScale(): MaskScale {
    return {
      scaleX: this.objectMask.getScaledWidth() / this.tempClippingMask.width!,
      scaleY: this.objectMask.getScaledHeight() / this.tempClippingMask.height!,
    }
  }
}
