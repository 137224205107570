export enum EnumModalName {
  MODAL_LOGIN = "modalLogin",
  MODAL_LOGOUT = "modalLogout",
  MODAL_REGISTER = "modalRegister",
  MODAL_FORGOTTEN_PASSWORD = "modalForgottenPassword",
  MODAL_CHECK_CONFIRMATION_EMAIL = "modalCheckConfirmationEmail",
  MODAL_SET_PASSWORD = "modalSetPassword",
  MODAL_SUCCESS = "modalSuccess",
  MODAL_DELETE_SUCCESS = "modalDeleteSuccess",
  MODAL_GUIDELINES = "modalGuidelines",
  MODAL_CONVERT_OLD_PROJECT = "modalConvertOldProject",
  MODAL_CONFIRMATION = "modalConfirmation",
  MODAL_CONFIRMATION_REMOVE = "modalConfirmationRemove",
  MODAL_QRCODE = "modalQrCode",
  MODAL_CHANGE_PRINT_QUALITY = "modalChangePrintQuality",
  MODAL_BEST_PRACTICES = "modalBestPractices",
  MODAL_GDPR_CONSENTS = "modalGDPRConsents",
  MODAL_SHARE_BOX_DESIGN = "modalShareBoxDesign",
  MODAL_DELETE_BOX_DESIGN = "deleteBoxDesign",
  MODAL_COPY_PRODUCT_DESIGN = "copyBoxDesign",
  MODAL_SAVE_BOX_DESIGN = "saveBoxDesign",
  MODAL_DUPLICATE_PRODUCT_DESIGN = "modalDuplicateProductDesign",
  MODAL_PASSWORD_CHANGED = "modalPasswordActive",
  MODAL_LINK_SENT = "modalLinkSent",
  MODAL_ORDER_CONSENT = "modalOrderConsent",
  MODAL_EDIT_OR_ADD_USER_ADDRESS = "modalEditOrAddUserAddress",
  MODAL_DELETE_USER_ADDRESS = "modalDeleteUserAddress",
}

export const modals = {
  // Modal Component Names
  // TODO: replace constants everywhere in the code.
  // Right now these are left as they are to avoid havoc in code.
  MODAL_LOGIN: EnumModalName.MODAL_LOGIN,
  MODAL_LOGOUT: EnumModalName.MODAL_LOGOUT,
  MODAL_REGISTER: EnumModalName.MODAL_REGISTER,
  MODAL_FORGOTTEN_PASSWORD: EnumModalName.MODAL_FORGOTTEN_PASSWORD,
  MODAL_CHECK_CONFIRMATION_EMAIL: EnumModalName.MODAL_CHECK_CONFIRMATION_EMAIL,
  MODAL_SET_PASSWORD: EnumModalName.MODAL_SET_PASSWORD,
  MODAL_SUCCESS: EnumModalName.MODAL_SUCCESS,
  MODAL_DELETE_SUCCESS: EnumModalName.MODAL_DELETE_SUCCESS,
  MODAL_GUIDELINES: EnumModalName.MODAL_GUIDELINES,
  MODAL_CONVERT_OLD_PROJECT: EnumModalName.MODAL_CONVERT_OLD_PROJECT,
  MODAL_CONFIRMATION: EnumModalName.MODAL_CONFIRMATION,
  MODAL_CONFIRMATION_REMOVE: EnumModalName.MODAL_CONFIRMATION_REMOVE,
  MODAL_QRCODE: EnumModalName.MODAL_QRCODE,
  MODAL_CHANGE_PRINT_QUALITY: EnumModalName.MODAL_CHANGE_PRINT_QUALITY,
  MODAL_BEST_PRACTICES: EnumModalName.MODAL_BEST_PRACTICES,
  MODAL_GDPR_CONSENTS: EnumModalName.MODAL_GDPR_CONSENTS,
  MODAL_SHARE_BOX_DESIGN: EnumModalName.MODAL_SHARE_BOX_DESIGN,
  MODAL_DELETE_BOX_DESIGN: EnumModalName.MODAL_DELETE_BOX_DESIGN,
  MODAL_COPY_PRODUCT_DESIGN: EnumModalName.MODAL_COPY_PRODUCT_DESIGN,
  MODAL_SAVE_BOX_DESIGN: EnumModalName.MODAL_SAVE_BOX_DESIGN,
  MODAL_DUPLICATE_PRODUCT_DESIGN: EnumModalName.MODAL_DUPLICATE_PRODUCT_DESIGN,
  MODAL_PASSWORD_CHANGED: EnumModalName.MODAL_PASSWORD_CHANGED,
  MODAL_LINK_SENT: EnumModalName.MODAL_LINK_SENT,
  MODAL_ORDER_CONSENT: EnumModalName.MODAL_ORDER_CONSENT,
  MODAL_USER_ADDRESS: EnumModalName.MODAL_EDIT_OR_ADD_USER_ADDRESS,
  MODAL_DELETE_USER_ADDRESS: EnumModalName.MODAL_DELETE_USER_ADDRESS,
}
