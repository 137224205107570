import React from "react"
import PickerColorComponent from "./PickerColorComponent"
import { PickerColorColorDataObject } from "./PickerColorTypes"

interface ColorPickerProps {
  color?: string
  hex?: string
  onChange: (color: string) => void
}

interface ColorPickerState {
  color?: string
}

class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  constructor(props: ColorPickerProps) {
    super(props)
    this.state = {
      color: props.hex
    }
  }

  UNSAFE_componentWillMount() {
    this.setState({
      color: this.props.color
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: ColorPickerProps) {
    const { color } = nextProps
    if (color !== this.props.color) {
      this.setState({
        color
      })
    }
  }

  onChangeFromPicker = (color: PickerColorColorDataObject) => {
    this.setState({
      color: color.hex
    })

    this.props.onChange(color.hex)
  }

  render() {
    return (
      <div e2e-target="color-picker" e2e-target-name="full-hex">
        <PickerColorComponent
          color={this.state.color}
          onChange={this.onChangeFromPicker}
        />
      </div>
    )
  }
}

export default ColorPicker
