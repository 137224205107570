import React, { FunctionComponent } from "react"
import cxBinder from "classnames/bind"
import styles from "./OutliningPanel.module.scss"
const cx = cxBinder.bind(styles)

export enum Decoration {
  NoDecoration = "no-decoration",
  Orange = "decoration-orange",
  Blue = "decoration-blue",
  Green = "decoration-green",
  DarkBlue = "decoration-blue-dark",
  Pink = "decoration-pink",
  LightPink = "light-pink"
}

export enum PanelBackground {
  Blue = "blue",
  White = "white",
  Grey = "grey",
  Alabaster = "alabaster",
  Red = "red"
}

export enum PanelPaddingType {
  Regular = "regular",
  Slim = "slim",
  None = "none",
  Thick = "thick"
}

export enum PanelBorderRadiusSize {
  Default = "default",
  Medium = "8px"
}

export interface OutliningPanelProps extends React.HTMLProps<HTMLDivElement> {
  background?: PanelBackground
  decoration?: Decoration
  paddingType?: PanelPaddingType
  borderRadiusSize?: PanelBorderRadiusSize
  overflowVisible?: boolean
}

const OutliningPanel: FunctionComponent<OutliningPanelProps> = (props) => {
  const {
    children,
    background,
    decoration,
    paddingType: paddingType = PanelPaddingType.Regular,
    borderRadiusSize,
    overflowVisible
  } = props
  return (
    <div
      e2e-target="outlining-panel"
      className={cx("wrapper", {
        "wrapper--padding-thick": paddingType === PanelPaddingType.Thick,
        "wrapper--padding-slim": paddingType === PanelPaddingType.Slim,
        "wrapper--padding-none": paddingType === PanelPaddingType.None,
        "wrapper--blue": background === PanelBackground.Blue,
        "wrapper--white": background === PanelBackground.White,
        "wrapper--overflow-visible": overflowVisible,
        "wrapper--grey": background === PanelBackground.Grey,
        "wrapper--alabaster": background === PanelBackground.Alabaster,
        "wrapper--red": background === PanelBackground.Red,
        "wrapper--radius-8": borderRadiusSize === PanelBorderRadiusSize.Medium
      })}
    >
      {decoration ? (
        <div
          className={cx("decoration", {
            "decoration--orange": decoration === Decoration.Orange,
            "decoration--blue": decoration === Decoration.Blue,
            "decoration--green": decoration === Decoration.Green,
            "decoration--blue-dark": decoration === Decoration.DarkBlue,
            "decoration--pink": decoration === Decoration.Pink,
            "decoration--light-pink": decoration === Decoration.LightPink
          })}
          e2e-target="outline-panel"
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export { OutliningPanel, OutliningPanel as default }
