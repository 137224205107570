import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import { trackEvent } from "../../../../libs/analytics/segment/segment-analytics.methods"
import { getParsedAnalyticsOptionsParams } from "../../../../libs/helpers/analytics.helpers"

export enum EventMessage {
  ShippingDestinationSaved = "ProductShipment Location Saved",
}

export const trackSegment = async (message: EventMessage, properties) =>
  trackEvent({ event: message, properties })

export const sendShippingDestinationSavedEvent = (): void => {
  const { location } = getParsedAnalyticsOptionsParams()

  const properties = {
    location,
    product_region: RegionConfig.getKeyForCurrentRegion("productRegion"),
    section: "navigation",
  }

  trackSegment(EventMessage.ShippingDestinationSaved, properties)
}
