import React from "react"
import { QuantityItem } from "../../types"
import { PickerValuePlaceholder } from "./PickerValuePlaceholder"
import { PickerValue } from "./PickerValue"

interface PickerValuePresenterProps {
  value?: QuantityItem
  consise?: boolean
  placeholder?: string
  fixedPromo?: number
}

export const PickerValuePresenter = (props: PickerValuePresenterProps) => {
  const { value, consise, placeholder, fixedPromo } = props

  return (
    <>
      {value ? (
        <PickerValue value={value} consise={consise} fixedPromo={fixedPromo} />
      ) : (
        <PickerValuePlaceholder>{placeholder}</PickerValuePlaceholder>
      )}
    </>
  )
}
