import { action, computed, observable, makeObservable } from "mobx"
import { I18N } from "../../ui/i18n"
import { AssetsStore } from "../../stores/assets.store"
import { ImageAssetItemStore } from "../../stores/image-asset-item.store"
import { ImagesController } from "../assets/images.controller"
import { TemplatesStore } from "../../stores/templates.store"
import { ElementsTabElement, ElementsTabSection } from "../../types/ui.types"
import { EcoShapesStore } from "../../stores/eco-shapes.store"
import { ShapesStore } from "../../stores/shapes.store"
import { Shape } from "../../models/shape"
import { ShapesController } from "../assets/shapes.controller"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"

const i18nAssets = I18N.editorTools.assets

export class ElementsTabController {
  @observable expandedSection?: ElementsTabSection

  private readonly imagesController: ImagesController
  private readonly shapesController: ShapesController
  private readonly assetStore: AssetsStore
  private readonly shapesStore: ShapesStore
  private readonly ecoShapesStore: EcoShapesStore
  private readonly templatesStore?: TemplatesStore

  constructor(
    services: {
      imagesController: ImagesController
      shapesController: ShapesController
      ee: AllEditorEventsEmitter
    },
    stores: {
      assetStore: AssetsStore
      shapesStore: ShapesStore
      ecoShapesStore: EcoShapesStore
      templatesStore?: TemplatesStore
    }
  ) {
    this.imagesController = services.imagesController
    this.shapesController = services.shapesController
    this.assetStore = stores.assetStore
    this.shapesStore = stores.shapesStore
    this.ecoShapesStore = stores.ecoShapesStore
    this.templatesStore = stores.templatesStore

    makeObservable(this)

    services.ee.on(eventTree.tab.tabChanged, () => this.setExpandedSection())
  }

  @action
  public setExpandedSection(section?: ElementsTabSection): void {
    this.expandedSection = section
  }

  public isSectionExpanded(section: ElementsTabSection): boolean {
    return this.expandedSection?.id === section.id
  }

  @computed
  public get sections(): ElementsTabSection[] {
    return [
      this.templateAssetsSection,
      ...this.ecoShapeSections,
      ...this.shapeSections,
    ]
  }

  private get templateAssetsSection(): ElementsTabSection<ImageAssetItemStore> {
    return {
      id: "template-images",
      titleIntl: i18nAssets.templateLibrary,
      elements: this.mapImages(
        this.assetStore.successTemplateAssets,
        "template-image"
      ),
      onElementClick: (asset: ImageAssetItemStore) => {
        this.imagesController.addImage(asset.getImageAsset(), {
          templateId: this.templatesStore?.selectedTemplateId,
        })
      },
    }
  }

  @computed
  private get ecoShapeSections(): ElementsTabSection<Shape>[] {
    const { ecoShapes } = this.ecoShapesStore

    return Object.keys(ecoShapes).map((type) => {
      const shapesInType = ecoShapes[type]

      return {
        id: `eco-${type}`,
        titleIntl: i18nAssets.eco[type],
        elements: this.mapShapes(shapesInType),
        onElementClick: (shape: Shape) => {
          this.shapesController.addShape(shape)
        },
      }
    })
  }

  @computed
  private get shapeSections(): ElementsTabSection<Shape>[] {
    const { categories, shapes } = this.shapesStore
    const sections: ElementsTabSection<Shape>[] = []

    categories.forEach((type) => {
      const shapesInType = shapes[type.name]

      if (!!shapesInType?.length) {
        sections.push({
          id: `shapes-${type.id}`,
          titleIntl: type.translationKey || type.name,
          elements: this.mapShapes(shapesInType),
          onElementClick: (shape: Shape) => {
            this.shapesController.addShape(shape)
          },
        })
      }
    })

    return sections
  }

  private mapImages(
    images: ImageAssetItemStore[],
    type: string
  ): ElementsTabElement<ImageAssetItemStore>[] {
    return images.map((image) => ({
      id: image.id,
      title: image.name,
      previewUrl: image.previewUri,
      asset: image,
      type,
    }))
  }

  private mapShapes(shapes: Shape[]): ElementsTabElement<Shape>[] {
    return shapes.map((shape) => ({
      id: shape.id,
      title: shape.title,
      previewUrl: shape.src,
      asset: shape,
      type: "shape",
    }))
  }
}
