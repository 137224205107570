import { action, observable, makeObservable } from "mobx"
import { ReplicablePatternUiController } from "./replicable-pattern-ui.controller"
import { DbyModeStore } from "../stores/dby-mode.store"
import { DbyModeController } from "./dby-mode.controller"
import { AssetsStore } from "../stores/assets.store"
import { SidebarStore } from "../stores/sidebar.store"
import ProductDriver from "../drivers/product.driver"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { GlobalDropzoneConfig } from "../libs/products-render-config/types"
import { ProductDesignStore } from "../stores/product-design.store"

export class GlobalDropzoneController {
  @observable public isDropzoneVisible = false

  private readonly productDriver: ProductDriver
  private readonly replicablePatternUiController?: ReplicablePatternUiController
  private readonly dbyModeController: DbyModeController
  private readonly dbyModeStore: DbyModeStore
  private readonly assetStore: AssetsStore
  private readonly sidebarStore: SidebarStore
  private readonly productDesignStore: ProductDesignStore

  constructor(
    services: {
      productDriver: ProductDriver
      replicablePatternUiController?: ReplicablePatternUiController
      dbyModeController: DbyModeController
    },
    stores: {
      dbyModeStore: DbyModeStore
      assetStore: AssetsStore
      sidebarStore: SidebarStore
      productDesignStore: ProductDesignStore
    }
  ) {
    this.productDriver = services.productDriver
    this.replicablePatternUiController = services.replicablePatternUiController
    this.dbyModeController = services.dbyModeController
    this.dbyModeStore = stores.dbyModeStore
    this.assetStore = stores.assetStore
    this.sidebarStore = stores.sidebarStore
    this.productDesignStore = stores.productDesignStore

    makeObservable(this)

    this.initListeners()
  }

  @action
  public setIsDropzoneVisible(isVisible: boolean): void {
    this.isDropzoneVisible = isVisible
  }

  public async upload(files: File[]): Promise<void> {
    this.setIsDropzoneVisible(false)

    if (this.productRenderPilot.isDbyMode()) {
      return this.uploadDieline(files[0])
    }

    if (this.dropzoneMode === "replicablePattern") {
      return this.uploadReplicablePatternArtwork(files[0])
    }

    if (this.sidebarStore.isTabSelected("logo")) {
      return this.uploadLogo(files[0])
    }

    if (this.dropzoneMode === "images") {
      return this.uploadImages(files)
    }
  }

  private async uploadDieline(file: File): Promise<void> {
    await this.dbyModeController.uploadFile(file)
  }

  private async uploadLogo(file: File): Promise<void> {
    await this.assetStore.addLogo(file)
  }

  private async uploadImages(files: File[]): Promise<void> {
    files.map((file) => this.assetStore.addAssetFromFile(file))

    this.sidebarStore.selectTab("upload")
  }

  private async uploadReplicablePatternArtwork(file: File): Promise<void> {
    this.replicablePatternUiController?.selectArtwork(file)
    this.replicablePatternUiController?.selectMode("image")

    this.sidebarStore.selectTab("replicablePatterns")
  }

  private initListeners(): void {
    if (!this.productDesignStore.isEditMode) {
      return
    }

    document.addEventListener("dragstart", this.onFileDrag.bind(this), false)
    document.addEventListener("dragover", this.onFileDrag.bind(this), false)
    document.addEventListener("dragenter", this.onFileDrag.bind(this), false)
  }

  private onFileDrag(e: DragEvent): void {
    e.preventDefault()

    const transfer = e.dataTransfer
    const dataUrl = transfer?.getData("URL") || transfer?.getData("text")

    this.setIsDropzoneVisible(!dataUrl)
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  private get dropzoneMode(): GlobalDropzoneConfig["mode"] {
    return this.productRenderPilot.uiConfig.globalDropzone.mode
  }
}
