export enum LiveChatAction {
  hideIcon = "hide",
  showIcon = "show",
  expand = "expand",
}

export type LiveChatActionType = LiveChatAction

const changeChatWidgetVisibility = (displayOption: "hidden" | "visible") => {
  const chatWidget = document.getElementById(
    "hubspot-messages-iframe-container"
  )
  if (!chatWidget) return null

  chatWidget.style.visibility = displayOption
}

const action = (action: LiveChatActionType) => {
  switch (action) {
    case LiveChatAction.expand:
      return window.HubSpotConversations.widget.open()
    case LiveChatAction.hideIcon:
      return changeChatWidgetVisibility("hidden")
    case LiveChatAction.showIcon:
      return changeChatWidgetVisibility("visible")
    default:
      return window.HubSpotConversations.widget.open()
  }
}

export const waitForWidget = (actionType?: LiveChatActionType) =>
  new Promise<any>((resolve, reject) => {
    let rejectTimeout: number

    const interval = setInterval(() => {
      const status =
        window.HubSpotConversations &&
        window.HubSpotConversations.widget.status()
      const isWidgetLoaded = status && status.loaded

      if (isWidgetLoaded) {
        clearInterval(interval)
        clearTimeout(rejectTimeout)
        resolve(actionType ? action(actionType) : isWidgetLoaded)
      }
    }, 500)

    rejectTimeout = setTimeout(() => {
      clearInterval(interval)
      reject(new Error("Hubspot chat not loaded"))
    }, 3000)
  })

export const waitForHubspot = () =>
  new Promise<any>((resolve, reject) => {
    let rejectTimeout: number

    const interval = setInterval(() => {
      const isHubspot = window.HubSpotConversations
      if (isHubspot) {
        clearInterval(interval)
        clearTimeout(rejectTimeout)
        resolve(isHubspot)
      }
    }, 500)

    rejectTimeout = setTimeout(() => {
      clearInterval(interval)
      reject(new Error("Hubspot chat not loaded"))
    }, 3000)
  })

export const chatAction = async (actionType: LiveChatActionType) => {
  const isHubspotLoaded =
    window.HubSpotConversations || (await waitForHubspot().catch(() => false))
  if (!isHubspotLoaded) return null
  if (actionType === LiveChatAction.showIcon) return action(actionType)

  await waitForWidget(actionType).catch(() => null)
}

export const addChatWithUsTriggers = () => {
  const triggerDomElements = document.querySelectorAll(
    '[data-role="live-chat-trigger"]'
  )
  if (triggerDomElements.length === 0) return

  triggerDomElements.forEach((element) => {
    element.addEventListener("click", async (event) => {
      event.preventDefault()
      await chatAction(LiveChatAction.expand)
    })
  })
}
