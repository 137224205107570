import { computed, autorun } from "mobx"
import { I18N } from "../ui/i18n"
import ProductDriver from "../drivers/product.driver"
import { ShapesStore } from "../stores/shapes.store"
import { EcoShapesStore } from "../stores/eco-shapes.store"
import { PredefinedTextsStore } from "../stores/predefined-texts.store"
import { TemplatesStore } from "../stores/templates.store"
import { BackgroundImageStore } from "../stores/background-image.store"
import { DesignerModeStore } from "../stores/designer-mode.store"
import { PatternsStore } from "../stores/patterns.store"
import { FscCertificateStore } from "../stores/fsc-certificate.store"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { EcommerceStore } from "../stores/ecommerce.store"
import { CartStore } from "../stores/cart.store"
import { EditorMode, ViewType } from "../libs/products-render-config/types"
import { ProductDesignStore } from "../stores/product-design.store"
import { AssetsStore } from "../stores/assets.store"

const i18n = I18N.component.loader

export class CanvasLoaderUiController {
  private readonly productDriver: ProductDriver
  private readonly shapesStore: ShapesStore
  private readonly ecoShapesStore: EcoShapesStore
  private readonly predefinedTextsStore: PredefinedTextsStore
  private readonly templatesStore?: TemplatesStore
  private readonly patternsStore?: PatternsStore
  private readonly backgroundImageStore?: BackgroundImageStore
  private readonly designerModeStore?: DesignerModeStore
  private readonly fscCertificateStore?: FscCertificateStore
  private readonly ecommerceStore?: EcommerceStore
  private readonly cartStore?: CartStore
  private readonly productDesignStore: ProductDesignStore
  private readonly assetStore: AssetsStore

  constructor(
    services: { productDriver: ProductDriver; ee: AllEditorEventsEmitter },
    stores: {
      shapesStore: ShapesStore
      ecoShapesStore: EcoShapesStore
      predefinedTextsStore: PredefinedTextsStore
      templatesStore?: TemplatesStore
      patternsStore?: PatternsStore
      backgroundImageStore?: BackgroundImageStore
      designerModeStore?: DesignerModeStore
      fscCertificateStore?: FscCertificateStore
      ecommerceStore?: EcommerceStore
      cartStore?: CartStore
      productDesignStore: ProductDesignStore
      assetStore: AssetsStore
    }
  ) {
    this.productDriver = services.productDriver
    this.shapesStore = stores.shapesStore
    this.ecoShapesStore = stores.ecoShapesStore
    this.predefinedTextsStore = stores.predefinedTextsStore
    this.templatesStore = stores.templatesStore
    this.patternsStore = stores.patternsStore
    this.backgroundImageStore = stores.backgroundImageStore
    this.designerModeStore = stores.designerModeStore
    this.fscCertificateStore = stores.fscCertificateStore
    this.ecommerceStore = stores.ecommerceStore
    this.cartStore = stores.cartStore
    this.productDesignStore = stores.productDesignStore
    this.assetStore = stores.assetStore

    autorun(() => {
      if (!this.isVisible) {
        services.ee.emit(eventTree.ui.mainUISceneLoadedFull)
      }
    })
  }

  @computed
  public get isVisible(): boolean {
    return (
      this.isLoadingPattern ||
      this.isLoadingTemplate ||
      this.isLoadingText ||
      this.isLoadingElement ||
      this.isLoadingImage ||
      this.isLoadingModel ||
      this.isLoadingVirtualDieline ||
      this.isLoadingProduct ||
      this.isLoadingDynamicVariant ||
      this.isLoadingDesignerMode ||
      this.isAddingToCart ||
      this.isLoadingRenderer ||
      this.isDuplicatingDesign
    )
  }

  @computed
  public get labelIntl(): string {
    if (this.isLoadingPattern) {
      return i18n.loadingPattern
    }

    if (this.isLoadingTemplate) {
      return i18n.loadingTemplate
    }

    if (this.isLoadingText) {
      return i18n.loadingText
    }

    if (this.isLoadingElement) {
      return i18n.loadingElement
    }

    if (this.isLoadingImage) {
      return i18n.loadingImage
    }

    if (this.isLoadingDynamicVariant) {
      return i18n.calculatingPrice
    }

    if (this.isLoadingProduct) {
      return i18n.loadingProduct
    }

    if (this.isLoadingVirtualDieline) {
      return i18n.loadingDieline
    }

    if (this.isLoadingModel) {
      return i18n.loadingModel
    }

    if (this.isLoadingRenderer) {
      if (this.productDriver.state.activeViewType === ViewType.MODEL) {
        return i18n.loadingModel
      }

      return i18n.loadingDieline
    }

    if (this.isAddingToCart) {
      return i18n.addingToCart
    }

    if (this.isDuplicatingDesign) {
      return i18n.duplicatingDesign
    }

    return i18n.generic
  }

  @computed
  private get isLoadingPattern(): boolean {
    return !!this.patternsStore?.isSinglePatternLoading
  }

  @computed
  private get isLoadingTemplate(): boolean {
    return !!this.templatesStore?.isLoadingSingleTemplate
  }

  @computed
  private get isLoadingText(): boolean {
    return this.predefinedTextsStore.isLoadingSinglePredefinedText
  }

  @computed
  private get isLoadingElement(): boolean {
    const isLoadingEcoShape = this.ecoShapesStore.isLoadingSingleEcoShape
    const isLoadingShape = this.shapesStore.isLoadingSingleShape
    const isLoadingSingleFscShape =
      !!this.fscCertificateStore?.isLoadingSingleShape
    const isLoadingImage = this.assetStore.isLoadingSingleImage

    return (
      isLoadingEcoShape ||
      isLoadingShape ||
      isLoadingSingleFscShape ||
      isLoadingImage
    )
  }

  @computed
  private get isLoadingImage(): boolean {
    return !!this.backgroundImageStore?.isLoading
  }

  @computed
  private get isLoadingDynamicVariant(): boolean {
    return !!this.ecommerceStore?.isDynamicVariantLoading
  }

  @computed
  private get isLoadingProduct(): boolean {
    return this.productDriver.state.isProductChanging
  }

  @computed
  private get isLoadingRenderer(): boolean {
    return (
      this.productDriver.state.isRendererLoading && this.editorMode !== "dby"
    )
  }

  @computed
  private get isLoadingVirtualDieline(): boolean {
    return this.productDriver.state.isVirtualDielineLoading
  }

  @computed
  private get isLoadingModel(): boolean {
    return this.productDriver.state.isLoading3DModel
  }

  @computed
  private get isLoadingDesignerMode(): boolean {
    return !!this.designerModeStore?.isLoading
  }

  @computed
  private get isAddingToCart(): boolean {
    return (
      !!this.cartStore?.isTriggeredAddingToCart ||
      !!this.cartStore?.isAddingToCart
    )
  }

  @computed
  private get isDuplicatingDesign(): boolean {
    return this.productDesignStore.state.isProductDesignDuplicating
  }

  @computed
  private get editorMode(): EditorMode {
    return this.productDriver.state.productRenderPilot.getEditorMode()
  }
}
