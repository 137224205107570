import React from "react"
import styles from "./AssetPlacementCta.module.scss"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"
import arrowDownIconUrl from "../../../assets/_icons/type-arrow-down.svg"

export function AssetPlacementCta() {
  const t = useTranslate()

  return (
    <div className={styles.wrapper}>
      {t(I18N.generic.chooseElementPosition)}

      <SvgIcon url={arrowDownIconUrl} size="l" />
    </div>
  )
}
