export const config = {
  thickness: 1,
  rotation: {
    initial: 0,
  },
  folding: {
    target: {
      closed: 100,
      opened: 1,
    },
  },
  spaces: {
    glue_flap: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: 90,
        step: 1,
      },
    },
    front: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: -90,
        step: 2,
      },
    },
    top: {
      parent: "front",
      folding: {
        axis: "y",
        angle: -90,
        step: 3,
      },
    },
    back: {
      parent: "top",
      folding: {
        axis: "y",
        angle: -90,
        step: 4,
      },
    },
  },
} as const
