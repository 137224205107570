import { PackhelpCanvas } from "../../../object-extensions/packhelp-objects"
import { CanvasEvent } from "../../../../../../events/partials/domain.events"

interface Event {
  name: string
  fn: (...args) => void
}

interface Position {
  x: number
  y: number
}

export class DragModeController {
  private readonly events: Event[]
  private isDragging = false
  private isListenersAttached = false
  private lastCursorPosition: Position | null = null

  constructor(private readonly canvas: PackhelpCanvas) {
    this.events = this.buildEventMap()
  }

  public start(): void {
    this.setCursor("grab")

    this.attachListeners()
  }

  public stop(): void {
    this.setCursor("default")

    this.detachListeners()
  }

  private attachListeners(): void {
    if (this.isListenersAttached) {
      return
    }

    for (const event of this.events) {
      this.canvas.on(event.name, event.fn)
    }

    this.isListenersAttached = true
  }

  private detachListeners(): void {
    if (!this.isListenersAttached) {
      return
    }

    for (const event of this.events) {
      this.canvas.off(event.name, event.fn)
    }

    this.isListenersAttached = false
  }

  private handleMouseUp(): void {
    this.setCursor("grab")

    this.isDragging = false
  }

  private handleMouseDown(): void {
    this.setCursor("grabbing")

    this.isDragging = true
    this.lastCursorPosition = null
  }

  private handleMouseMove(e: { pointer: Position }): void {
    if (!this.isDragging) {
      return
    }

    const cursorPosition = e.pointer
    const delta = this.lastCursorPosition
      ? {
          x: cursorPosition.x - this.lastCursorPosition.x,

          y: cursorPosition.y - this.lastCursorPosition.y,
        }
      : { x: 0, y: 0 }

    this.canvas.relativePan(delta)
    this.lastCursorPosition = cursorPosition
  }

  private buildEventMap(): Event[] {
    return [
      {
        name: CanvasEvent.mouseUp,
        fn: this.handleMouseUp.bind(this),
      },
      {
        name: CanvasEvent.mouseDown,
        fn: this.handleMouseDown.bind(this),
      },
      {
        name: CanvasEvent.mouseMove,
        fn: this.handleMouseMove.bind(this),
      },
    ]
  }

  private setCursor(cursor: string): void {
    this.canvas.defaultCursor = cursor
    this.canvas.setCursor(cursor)
  }
}
