/** @format */
// sku part
import SkuFinish from "./boxSkuDefinitions/SkuFinish";
import SkuMaterial from "./boxSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./boxSkuDefinitions/SkuPrintMode";
import SkuModel from "./boxSkuDefinitions/SkuModel";
// product sku parts
import Finish from "./boxPartsDefinitions/Finish";
import Material from "./boxPartsDefinitions/Material";
import PrintMode from "./boxPartsDefinitions/PrintMode";
import Model from "./boxPartsDefinitions/Model";
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var BOX_CONSTS = {
    SKU_BASE: "box-",
    PRODUCT_TYPE: "box",
    //Box siblings pairs
    MAILER_BOX_ECO_COLOR_AND_FULL_COLOR_GROUP: "mailer-box-eco-color-and-full-color-group",
    MAILER_BOX_ECO_AND_ECO_WHITE_GROUP: "mailer-box-eco-and-eco-white-group",
    // Materials translation definitions
    MATERIAL_ID_KRAFT: "kraft",
    MATERIAL_ID_ARKTIKA: "arktika",
    MATERIAL_ID_CHROMOCARD: "chromocard",
    MATERIAL_CARDBOARD_WAVE_E: "cardboard-wave-e",
    MATERIAL_CARDBOARD_WAVE_B: "cardboard-wave-b",
    MATERIAL_CARDBOARD_WAVE_BE: "cardboard-wave-be",
    // SKU Print qualities
    SKU_PRINT_QUALITY_STANDARD: "print-standard",
    SKU_PRINT_QUALITY_HD_PLUS: "print-hdplus",
};
export default Object.assign({}, BOX_CONSTS, SkuFinish, SkuMaterial, SkuPrintMode, SkuModel, Finish, Material, PrintMode, Model, Product, ProductVariant);
