import React from "react"
import { SvgIcon, SvgIconProps } from "../SvgIcon/SvgIcon"
import closeIconUrl from "../../../assets/_icons/close.svg"
import styles from "./CloseButton.module.scss"

export interface CloseButtonProps {
  onClick: () => void
  size?: SvgIconProps["size"]
  e2eTarget?: string
  e2eTargetName?: string
}

export function CloseButton({
  onClick,
  size,
  e2eTarget = "button",
  e2eTargetName,
}: CloseButtonProps) {
  return (
    <button
      className={styles.button}
      onClick={onClick}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <SvgIcon url={closeIconUrl} size={size} />
    </button>
  )
}
