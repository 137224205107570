import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Typography.module.scss"
import { typography } from "../../theme/typography"

const cx = cxBinder.bind(styles)

export type AllowedTypographyType = keyof typeof TType

export const TType = Object.freeze({
  Header64_500: typography.classname("header", 64, 500),
  Header56_400: typography.classname("header", 56, 400),
  Header48_400: typography.classname("header", 48, 400),
  Header40_500: typography.classname("header", 40, 500),
  Header40_400: typography.classname("header", 40, 400),
  Header32_400: typography.classname("header", 32, 400),
  Header24_400: typography.classname("header", 24, 400),
  Header20_500: typography.classname("header", 20, 500),
  Header20_400: typography.classname("header", 20, 400),
  Header17_500: typography.classname("header", 17, 500),
  Header17_400: typography.classname("header", 17, 400),
  Header15_500: typography.classname("header", 15, 500),
  Header13_400: typography.classname("header", 13, 400),
  Header13_500: typography.classname("header", 13, 500),
  Body32_350: typography.classname("body", 32, 350),
  Body24_400: typography.classname("body", 24, 400),
  Body24_350: typography.classname("body", 24, 350),
  Body20_350: typography.classname("body", 20, 350),
  Body17_350: typography.classname("body", 17, 350),
  Body15_350: typography.classname("body", 15, 350),
  Body13_350: typography.classname("body", 13, 350),
  Body10_350: typography.classname("body", 10, 350),
  Body15_500UC: typography.classname("body", 15, 500, true),
  Body13_400UC: typography.classname("body", 13, 400, true),
  Body10_400UC: typography.classname("body", 10, 400, true)
})

const defaultTags = new Map<string, string>([
  [TType.Header64_500, "h1"],
  [TType.Header56_400, "h2"],
  [TType.Header48_400, "h2"],
  [TType.Header40_400, "h2"],
  [TType.Header32_400, "h3"],
  [TType.Header24_400, "h3"],
  [TType.Header20_500, "h3"],
  [TType.Header20_400, "h3"],
  [TType.Header17_500, "h4"],
  [TType.Header17_400, "h4"],
  [TType.Header15_500, "h6"],
  [TType.Header13_500, "h6"],
  [TType.Body32_350, "span"],
  [TType.Body24_350, "span"],
  [TType.Body20_350, "span"],
  [TType.Body17_350, "span"],
  [TType.Body15_350, "span"],
  [TType.Body13_350, "span"],
  [TType.Body10_350, "span"],
  [TType.Body15_500UC, "span"],
  [TType.Body13_400UC, "span"],
  [TType.Body10_400UC, "span"]
])

export interface TypographyProps extends React.HTMLProps<HTMLElement> {
  type: string
  htmlElement?: string
  className?: string
  e2eTarget?: string
  e2eTargetName?: string
  children?: React.ReactNode
}

const Typography = (props: TypographyProps) => {
  const {
    children,
    htmlElement,
    type,
    className,
    e2eTarget = "text",
    e2eTargetName,
    ...other
  } = props
  const childProps = {
    className: cx({
      [type]: true,
      "zas-component": true
    }),
    "e2e-target": e2eTarget,
    "e2e-target-name": e2eTargetName,
    ...other
  }
  if (className) {
    childProps.className = `${childProps.className} ${className}`
  }

  let tagName = htmlElement || defaultTags.get(type) || "span"

  return React.createElement(tagName, childProps, children)
}

export { Typography, Typography as default }
