import React from "react"
import { SectionLoader } from "../../dsl/molecules/loaders/SectionLoader/SectionLoader"
import { I18N } from "../../i18n"
import { useTranslate } from "../../hooks/useTranslate"

const i18n = I18N.component.loader

export function SceneLoader() {
  const t = useTranslate()

  return <SectionLoader label={t(i18n.generic)} />
}
