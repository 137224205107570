var ProductModel;
(function (ProductModel) {
    ProductModel["NCP_MODEL_WOOD_WOOL"] = "woodWool";
    ProductModel["NCP_MODEL_SELF_ADHESIVE_ENVELOPE"] = "selfAdhesiveEnvelope";
    ProductModel["NCP_MODEL_STICKER_CIRCLE"] = "stickerCircle";
    ProductModel["NCP_MODEL_STICKER_RECTANGLE"] = "stickerRectangle";
    ProductModel["NCP_MODEL_THANK_YOU_CARD"] = "thankYouCard";
    ProductModel["NCP_MODEL_PAPER_BUBBLE_WRAP"] = "paperBubbleWrap";
})(ProductModel || (ProductModel = {}));
export default ProductModel;
