import React from "react"
import styles from "./Proscenium.module.scss"
import { HeaderLoader } from "../../dsl/organisms/Header/HeaderLoader"
import { AsideLoader } from "../../dsl/organisms/Aside/AsideLoader"
import { SceneLoader } from "../Scene/SceneLoader"

export function ProsceniumLoader() {
  return (
    <div
      className={styles.proscenium}
      e2e-target="loader"
      e2e-target-name="page"
    >
      <div className={styles.header}>
        <HeaderLoader />
      </div>

      <div className={styles.aside}>
        <AsideLoader />
      </div>

      <div className={styles.scene}>
        <SceneLoader />
      </div>
    </div>
  )
}
