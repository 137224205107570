import React from "react"
import { observer } from "mobx-react-lite"
import menuIconUrl from "../../../../assets/_icons/menu.svg"
import shareIconUrl from "../../../../assets/_icons/share.svg"
import shareFillIconUrl from "../../../../assets/_icons/share-fill.svg"
import saveIconUrl from "../../../../assets/_icons/save.svg"
import saveFillIconUrl from "../../../../assets/_icons/save-fill.svg"
import checkIconUrl from "../../../../assets/_icons/check.svg"
import { IconList } from "../../../atoms/IconList/IconList"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { IconListItem } from "../../../atoms/IconList/IconListItem"

const i18n = I18N.menu

export const HeaderShortcutMenu = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
    c.envUtil,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, ui, envUtil } = containerSet
  const { productDesignUiController, productDesignStore } =
    designAndProductDriver
  const { appConfig } = envUtil
  const {
    isSaveDisabled,
    isDesignSaved,
    state: { isProductDesignSaving },
  } = productDesignStore
  const manualSave = appConfig.api.ecommerce.features.ecommerce.manualSave

  const onMenuClick = () => {
    ui.appUiController.toggleMenu()
  }

  const onShareClick = () => {
    productDesignUiController.setIsShareModalOpen(true)
  }

  const onSaveClick = () => {
    productDesignUiController.save({
      notifyOnSuccess: true,
      forceLogin: true,
    })
  }

  return (
    <IconList>
      <IconListItem
        iconUrl={menuIconUrl}
        label={t(i18n.menu)}
        onClick={onMenuClick}
        analyticsSection="top-navigation-bar"
        analyticsType="icon"
        analyticsName="menu"
      />
      <IconListItem
        iconUrl={shareIconUrl}
        iconActiveUrl={shareFillIconUrl}
        label={t(i18n.share)}
        onClick={onShareClick}
        analyticsSection="top-navigation-bar"
        analyticsType="icon"
        analyticsName="share"
      />
      {manualSave && (
        <IconListItem
          iconUrl={saveIconUrl}
          iconActiveUrl={saveFillIconUrl}
          stateIconUrl={isDesignSaved ? checkIconUrl : undefined}
          disabled={isSaveDisabled}
          label={isDesignSaved ? t(I18N.generic.allChangesSaved) : t(i18n.save)}
          onClick={onSaveClick}
          analyticsSection="top-navigation-bar"
          analyticsType="icon"
          analyticsName="save"
        />
      )}
      {isProductDesignSaving && (
        <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
      )}
    </IconList>
  )
})
