import { SidebarTab } from "../../../../libs/products-render-config/types"
import { TabLogo } from "./TabLogo/TabLogo"
import { TabText } from "./TabText/TabText"
import { TabElements } from "./TabElements/TabElements"
import { TabPatternReplicator } from "./TabPatternReplicator/TabPatternReplicator"
import { TabTemplatesPatterns } from "./TabTemplatesPatterns/TabTemplatesPatterns"
import { TabBackground } from "./TabBackground/TabBackground"
import { TabCertificates } from "./TabCertificates/TabCertificates"
import { TabFonts } from "./TabFonts/TabFonts"
import { TabTextOptions } from "./TabTextOptions/TabTextOptions"
import { TabConfiguration } from "./TabConfiguration/TabConfiguration"
import { TabSize } from "./TabSize/TabSize"
import { TabDevTools } from "./TabDevTools/TabDevTools"
import { TabProduct } from "./TabProduct/TabProduct"
import { TabDby } from "./TabDby/TabDby"
import { TabUpload } from "./TabUpload/TabUpload"

export interface TabDefinition {
  name: SidebarTab
  Component: React.ElementType
}

export const tabs: TabDefinition[] = [
  { name: "logo", Component: TabLogo },
  { name: "text", Component: TabText },
  { name: "elements", Component: TabElements },
  {
    name: "replicablePatterns",
    Component: TabPatternReplicator,
  },
  {
    name: "templatesAndPatterns",
    Component: TabTemplatesPatterns,
  },
  { name: "background", Component: TabBackground },
  { name: "certificates", Component: TabCertificates },
  { name: "fonts", Component: TabFonts },
  { name: "textOptions", Component: TabTextOptions },
  { name: "configuration", Component: TabConfiguration },
  { name: "size", Component: TabSize },
  { name: "devTools", Component: TabDevTools },
  { name: "product", Component: TabProduct },
  { name: "dby", Component: TabDby },
  { name: "upload", Component: TabUpload },
]
