import { KeyboardControlsService } from "../libs/services/keyboard-controls.service"
import { GetContainerFormat } from "iti"
import { SidebarStore } from "../stores/sidebar.store"
import { SidebarController } from "../controllers/sidebar.controller"
import { FontsConfigStore } from "../stores/fonts-config.store"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { UtilEnvContainer } from "./util.container"
import { EditorModeController } from "../controllers/editor-mode.controller"
import { EcommerceMaybeContainer } from "./ecommerce-maybe.container"
import { TemplatesMaybeContainer } from "./templates-maybe.container"
import { PatternMaybeContainer } from "./asset-pattern-maybe.container"
import { FscCertificateMaybeContainer } from "./fsc-certificate-maybe.container"
import { AppUiController } from "../controllers/app-ui.controller"
import { ToolingMaybeContainer } from "./tooling.container"
import { ElementsTabController } from "../controllers/tabs/elements-tab.controller"
import { TemplatesPatternsTabController } from "../controllers/tabs/templates-patterns-tab.controller"
import { BackgroundTabController } from "../controllers/tabs/background-tab.controller"
import { BackgroundImageContainer } from "./background-image.container"
import { AssetsContainer } from "./assets.container"
import { TextOptionsTabController } from "../controllers/tabs/text-options-tab.controller"
import { ConfigurationTabController } from "../controllers/tabs/configuration-tab.controller"
import { SizeTabController } from "../controllers/tabs/size-tab.controller"
import { GlobalDropzoneController } from "../controllers/global-dropzone.controller"
import { ReplicablePatternMaybeContainer } from "./replicable-pattern-maybe.container"
import { DbyModeContainer } from "./dby-mode.container"
import { EditorToolbarController } from "../controllers/toolbars/editor-toolbar.controller"
import { SpaceToolbarController } from "../controllers/toolbars/space-toolbar.controller"
import { MaskToolbarController } from "../controllers/toolbars/mask-toolbar.controller"
import { TextToolbarController } from "../controllers/toolbars/text-toolbar.controller"
import { FontsTabController } from "../controllers/tabs/fonts-tab.controller"
import { RasterImageToolbarController } from "../controllers/toolbars/raster-image-toolbar.controller"
import { SvgImageToolbarController } from "../controllers/toolbars/svg-image-toolbar.controller"
import { ShapeToolbarController } from "../controllers/toolbars/shape-toolbar.controller"
import { CameraControlsUiController } from "../controllers/camera-controls-ui.controller"
import { PrintingModeUiController } from "../controllers/printing-mode-ui.controller"
import { NotificationStore } from "../stores/notification.store"
import { CanvasNotificationUiController } from "../controllers/canvas-notification-ui.controller"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { CanvasContextMenuUiController } from "../controllers/canvas-context-menu-ui.controller"
import { CurtainUiController } from "../controllers/curtain-ui.controller"
import { CanvasLoaderUiController } from "../controllers/canvas-loader-ui.controller"
import { DesignerModeMaybeContainer } from "./designer-mode-maybe.container"
import { DbyTabController } from "../controllers/tabs/dby-tab.controller"
import { UploadTabController } from "../controllers/tabs/upload-tab.controller"
import { AsideController } from "../controllers/aside.controller"
import { ModelPopoverUiController } from "../controllers/model-popover-ui.controller"

export async function provideUiContainer(
  utilEnvContainer: UtilEnvContainer,
  bootstrapClassicRootStore: BootstrapClassicRootStoreContainer,
  designAndProduct: DesignAndProductDriverContainer,
  ecommerceMaybeContainer: EcommerceMaybeContainer,
  templatesContainer: TemplatesMaybeContainer,
  patternsContainer: PatternMaybeContainer,
  fscContainer: FscCertificateMaybeContainer,
  toolingContainer: ToolingMaybeContainer,
  backgroundImageContainer: BackgroundImageContainer,
  assetsContainer: AssetsContainer,
  replicablePatternMaybeContainer: ReplicablePatternMaybeContainer,
  dbyModeContainer: DbyModeContainer,
  designerModeContainer: DesignerModeMaybeContainer
) {
  const { uri, appConfig, ee } = utilEnvContainer
  const { productRenderPilot } = bootstrapClassicRootStore
  const {
    productDriver,
    productDesignStore,
    assetStore,
    changeProductController,
    dielineStore,
    saveProductDesignController,
  } = designAndProduct
  const { productStore } = productDriver
  const { cart, ecommerceStore, cartStore, productPricingStore } =
    ecommerceMaybeContainer
  const { templatesStore, templatesController } = templatesContainer
  const { patternsStore, patternsController } = patternsContainer
  const { backgroundImageUiController, backgroundImageStore } =
    backgroundImageContainer
  const {
    imagesController,
    shapesController,
    shapesStore,
    ecoShapesStore,
    predefinedTextsStore,
  } = assetsContainer
  const { replicablePatternUiController } = replicablePatternMaybeContainer
  const { dbyModeController, dbyModeStore } = dbyModeContainer
  const { fscCertificateStore } = fscContainer
  const { designerModeStore } = designerModeContainer
  const formatMessage = await getFormatMessage()

  const notificationStore = new NotificationStore({
    ee,
    assetsDriver: productDriver.assetsDriver,
    formatMessage,
  })

  const previousDesignId = uri.getPreviousDesignId()

  if (previousDesignId) {
    notificationStore.showConfigurationChangedWarning(
      uri.getDesignUrl({ designId: previousDesignId })
    )
  }

  if (uri.isDesignDuplicate()) {
    notificationStore.showDuplicationSuccess()
  }

  const keyboardControls = new KeyboardControlsService()
  const sidebarStore = new SidebarStore(
    { productDriver, uri },
    appConfig.api.ecommerce.features.ecommerce
  )
  const sidebarController = new SidebarController(
    sidebarStore,
    productDriver,
    productRenderPilot.uiConfig,
    uri
  )
  const fontsConfigStore = new FontsConfigStore(ee)

  const editorModeController = new EditorModeController(
    {
      productDriver,
      uri,
      cart,
      saveProductDesignController,
    },
    {
      productDesignStore,
      ecommerceStore,
    }
  )

  const appUiController = new AppUiController()

  const elementsTabController = new ElementsTabController(
    {
      imagesController,
      shapesController,
      ee,
    },
    {
      assetStore,
      shapesStore,
      ecoShapesStore,
      templatesStore,
    }
  )

  const templatesPatternsTabController = new TemplatesPatternsTabController(
    {
      templatesController,
      patternsController,
      ee,
    },
    {
      templatesStore,
      patternsStore,
    }
  )

  const backgroundTabController = new BackgroundTabController(
    {
      productDriver,
      ee,
      backgroundImageUiController,
    },
    { backgroundImageStore, assetStore }
  )

  const textOptionsTabController = new TextOptionsTabController(
    {
      productDriver,
      ee,
    },
    {}
  )

  const configurationTabController = new ConfigurationTabController(
    {
      productDriver,
      changeProductController,
    },
    {
      productDesignStore,
      sidebarStore,
    }
  )

  const sizeTabController = new SizeTabController(
    {
      productDriver,
      changeProductController,
    },
    {
      sidebarStore,
      ecommerceStore,
    }
  )

  const fontsTabController = new FontsTabController({ productDriver, ee })

  const globalDropzoneController = new GlobalDropzoneController(
    {
      productDriver,
      replicablePatternUiController,
      dbyModeController,
    },
    {
      dbyModeStore,
      assetStore,
      sidebarStore,
      productDesignStore,
    }
  )

  const editorToolbarController = new EditorToolbarController(productDriver)
  const spaceToolbarController = new SpaceToolbarController(productDriver)

  const maskToolbarController = new MaskToolbarController(
    { productDriver, ee },
    { shapesStore }
  )

  const textToolbarController = new TextToolbarController(
    { productDriver, ee },
    { sidebarStore }
  )

  const rasterImageToolbarController = new RasterImageToolbarController({
    productDriver,
    ee,
  })
  const svgImageToolbarController = new SvgImageToolbarController({
    productDriver,
    ee,
  })
  const shapeToolbarController = new ShapeToolbarController({
    productDriver,
    ee,
  })

  const cameraControlsUiController = new CameraControlsUiController(
    { productDriver, uri },
    { productDesignStore }
  )

  const printingModeUiController = new PrintingModeUiController(
    { productDriver },
    { ecommerceStore, productPricingStore }
  )

  const canvasNotificationUiController = new CanvasNotificationUiController({
    productDriver,
    formatMessage,
  })

  const canvasContextMenuUiController = new CanvasContextMenuUiController({
    productDriver,
  })

  const curtainUiController = new CurtainUiController({ cartStore })
  const canvasLoaderUiController = new CanvasLoaderUiController(
    { productDriver, ee },
    {
      shapesStore,
      ecoShapesStore,
      predefinedTextsStore,
      templatesStore,
      patternsStore,
      backgroundImageStore,
      designerModeStore,
      fscCertificateStore,
      ecommerceStore,
      cartStore,
      productDesignStore,
      assetStore,
    }
  )

  const dbyTabController = new DbyTabController(
    { dbyModeController },
    { dbyModeStore, dielineStore, productStore }
  )

  const uploadTabController = new UploadTabController(
    {
      imagesController,
      backgroundImageUiController,
    },
    {
      assetStore,
    }
  )

  const asideController = new AsideController(
    {
      editorModeController,
    },
    { sidebarStore }
  )

  const modelPopoverUiController = new ModelPopoverUiController({
    productDriver,
    formatMessage,
  })

  return {
    keyboardControls,
    sidebarStore,
    sidebarController,
    fontsConfigStore,
    editorModeController,
    appUiController,
    elementsTabController,
    templatesPatternsTabController,
    backgroundTabController,
    textOptionsTabController,
    configurationTabController,
    sizeTabController,
    fontsTabController,
    globalDropzoneController,
    editorToolbarController,
    spaceToolbarController,
    maskToolbarController,
    textToolbarController,
    rasterImageToolbarController,
    svgImageToolbarController,
    shapeToolbarController,
    cameraControlsUiController,
    printingModeUiController,
    notificationStore,
    canvasNotificationUiController,
    canvasContextMenuUiController,
    curtainUiController,
    canvasLoaderUiController,
    dbyTabController,
    uploadTabController,
    asideController,
    modelPopoverUiController,
  }
}

export type UiContainer = GetContainerFormat<typeof provideUiContainer>
