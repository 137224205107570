import React from "react"
import { observer } from "mobx-react-lite"
import imageUrl from "../../../assets/_images/box.png"
import styles from "./BackToModelButton.module.scss"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import arrowIconUrl from "../../../assets/_icons/type-arrow-left.svg"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { ViewType } from "../../../../libs/products-render-config/types"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"

const i18n = I18N.component.cameraControlsToolbar

export const BackToModelButton = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDriver } = containerSet.designAndProductDriver
  const { activeSpace, activeContext, productRenderPilot } = productDriver.state

  if (!activeSpace || !productRenderPilot.isModelViewAvailable()) {
    return null
  }

  const onClick = () => {
    productDriver.setView(ViewType.MODEL, activeContext, null)
  }

  return (
    <div className={styles.wrapper}>
      <img src={imageUrl} alt="" />
      <div className={styles.button}>
        <Button
          onClick={onClick}
          size={ButtonSizes.small}
          icon={<SvgIcon url={arrowIconUrl} size="xs" />}
          isIconOnLeft={true}
          fluid
          analyticsSection="main-canvas-area"
          analyticsType="button"
          analyticsName="back-to-3d"
          e2eTarget="button"
          e2eTargetName="back-to-3d"
        >
          {t(i18n.backToModel)}
        </Button>
      </div>
    </div>
  )
})
