import { fabric } from "fabric"
import ProductDriver from "../drivers/product.driver"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { EditZoneControlsColorTheme } from "../libs/products-render-config/types"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"

export class CanvasController {
  constructor(
    private readonly allEditorEventEmitter: AllEditorEventsEmitter,
    private readonly productDriver: ProductDriver
  ) {
    this.initEditorEventListeners()
    this.setCanvasControlsTheme()
  }

  private initEditorEventListeners() {
    this.allEditorEventEmitter.on(
      eventTree.pd.skuChangeEnded,
      this.onSkuChanged.bind(this)
    )
    this.allEditorEventEmitter.on(
      eventTree.productDriver.renderEngineInitiated,
      this.onRenderEngineReady.bind(this)
    )
  }

  private onSkuChanged(sku: string, productRenderPilot: ProductRenderPilot) {
    this.setCanvasControlsTheme(productRenderPilot)
  }

  private onRenderEngineReady() {
    this.setCanvasControlsTheme()
  }

  private setCanvasControlsTheme(productRenderPilot?: ProductRenderPilot) {
    const pilot =
      productRenderPilot || this.productDriver.state.productRenderPilot
    const theme = pilot.uiConfig.editZone.controlsTheme

    const options: Record<
      EditZoneControlsColorTheme,
      Partial<fabric.Object>
    > = {
      dark: {
        borderColor: "black",
        cornerColor: "black",
        cornerStrokeColor: "white",
      },
      light: {
        borderColor: "white",
        cornerColor: "white",
        cornerStrokeColor: "black",
      },
    }

    Object.assign(fabric.Object.prototype, options[theme])
  }
}
