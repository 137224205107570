import Basil from "shared-libs/src/js/libs/storage/basil.js"
import Debug from "debug"
import { getServerInstanceName } from "./env-config"
const debug = Debug("ph:shared-libs:region-config")

// Also check
// shared-libs/src/js/libs/helpers/cookie.helpers.ts
// Can't import from cookie.helpers.ts because of an import loop

const REGION_COOKIE_NAME = "z-region-cookie"

function cookieKeyWithServerInstance(key: string) {
  const name = getServerInstanceName()
  return `${name}-${key}`
}

/**
 * map OUR weird names to a correct country ISO
 */
const mapOfErrors = Object.freeze({
  UK: "GB",
  EN: "GB",
  CS: "CZ",
  EL: "GR",
})

function fixCountryISO(maybeCountryISO: string) {
  maybeCountryISO = maybeCountryISO.toUpperCase()
  let fixedISO = mapOfErrors[maybeCountryISO as keyof typeof mapOfErrors]
  if (fixedISO != null) {
    debug(`fixed iso from ${maybeCountryISO} to ${fixedISO}`)
    return fixedISO
  }
  return maybeCountryISO
}

export function getCountryFromCookie(): string | undefined {
  let maybeCountryISO = Basil.cookie.get(
    cookieKeyWithServerInstance(REGION_COOKIE_NAME)
  )
  debug(`got maybe country ISO from cookie: ${maybeCountryISO}`)
  if (typeof maybeCountryISO === "string") {
    maybeCountryISO = maybeCountryISO.toUpperCase()
    maybeCountryISO = fixCountryISO(maybeCountryISO)
    return maybeCountryISO
  }
  return undefined
}
