import React, { PropsWithChildren } from "react"
import styles from "./TileList.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

interface TileListProps {
  cols?: 1 | 2 | 3
}

export function TileList({
  children,
  cols = 2,
}: PropsWithChildren<TileListProps>) {
  return <div className={cx("list", `cols-${cols}`)}>{children}</div>
}
