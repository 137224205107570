import { INode } from "svgson"
import _cloneDeep from "lodash/cloneDeep"
import { Point, Segment } from "../types"
import { uniqueID } from "../../../../services/asset-service/utils"

export abstract class SvgElement {
  protected readonly id: string
  protected readonly node: INode

  public abstract getSegment(): Segment
  public abstract reverse()
  public abstract getExtremePoints(): Point[]

  public constructor(node: INode) {
    this.node = _cloneDeep(node)
    this.id = node.attributes["id"] || uniqueID()
  }

  public toNode(): INode {
    return this.node
  }

  public getSpace(): string {
    return this.node.attributes["data-space"]
  }

  public getId(): string {
    return this.id
  }
}
