import React from "react"
import { observer } from "mobx-react-lite"
import imageUrl from "../../../assets/_images/option-box--double-sided-printing.png"
import { OptionBox } from "../../molecules/OptionBox/OptionBox"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { AnalyticsSection } from "../../types"

export interface PrintingOptionBoxProps {
  analyticsSection?: AnalyticsSection
  onClose?: () => void
}

export const PrintingOptionBox = observer(function ({
  analyticsSection,
  onClose,
}: PrintingOptionBoxProps) {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { printingModeUiController } = containerSet.ui
  const { additionallyPaidEditContext, isPrintActive, priceDiff } =
    printingModeUiController

  if (!additionallyPaidEditContext) {
    return null
  }

  const onToggle = () => {
    printingModeUiController.togglePrintingMode()
  }

  const i18n = I18N.component.printingMode[additionallyPaidEditContext]

  return (
    <OptionBox
      title={t(i18n.title)}
      description={t(i18n.description)}
      onClick={onToggle}
      active={isPrintActive}
      imageUrl={imageUrl}
      price={priceDiff}
      analyticsSection={analyticsSection}
      analyticsName="double-sided-printing"
      e2eTargetName="double-sided-printing"
      onClose={onClose}
    />
  )
})
