import { configure, observable, action, makeObservable } from "mobx"
import { ShapesStore } from "./shapes.store"
import { PredefinedTextsStore } from "./predefined-texts.store"
import { AllowedEditorFeatureFlagNames } from "../services/feature-flags/feature-flag-client"
import { FeatureFlags } from "@ph/feature-flags"
import { PatternController } from "../controllers/patterns.controller"
import { DesignerModeStore } from "./designer-mode.store"
import { DesignerModeControllable } from "../controllers/designer-mode/designer-mode-controllable.interface"
import { EcoShapesStore } from "./eco-shapes.store"
import { ShapesController } from "../controllers/assets/shapes.controller"
import type { DesignerModeMaybeContainer } from "../_containers/designer-mode-maybe.container"
import type { EditorMode } from "../libs/products-render-config/types"
import type { SessionService } from "../services/session.service"
import type { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import type ProductDriver from "../drivers/product.driver"
import type { ProductDesignStore } from "./product-design.store"
import type { AssetsStore } from "./assets.store"
import type { SessionStore } from "./session.store"
import { AssetsContainer } from "../_containers/assets.container"
import { DesignAndProductDriverContainer } from "../_containers/design-and-product-driver.container"
import { UtilEnvContainer } from "../_containers/util.container"
import { TextsController } from "../controllers/assets/texts.controller"

// don't allow state modifications outside actions
configure({ enforceActions: "always" })

export class RootStore {
  // ECOMMERCE DOMAIN
  public patternsController?: PatternController
  public ecoShapesStore: EcoShapesStore
  public shapesController: ShapesController

  // DESIGNER MODE DOMAIN
  public designerModeStore?: DesignerModeStore
  public designerModeController?: DesignerModeControllable
  // DESIGNER MODE DOMAIN END

  public shapesStore: ShapesStore
  public predefinedTextsStore: PredefinedTextsStore
  public textsController: TextsController
  public featureFlagsStore: FeatureFlags<AllowedEditorFeatureFlagNames>

  // Design and Product
  public readonly assetStore: AssetsStore
  public readonly productDriver: ProductDriver
  public readonly productDesignStore: ProductDesignStore

  constructor(
    public readonly sessionService: SessionService,
    public readonly sessionStore: SessionStore,
    public readonly editorMode: EditorMode,
    public readonly uri: UrlManipulatorProvider,
    designAndProductDriverContainer: DesignAndProductDriverContainer,
    designerModeContainer: DesignerModeMaybeContainer,
    assetsContainer: AssetsContainer,
    utilEnvContainer: UtilEnvContainer
  ) {
    makeObservable(this)

    // PRODUCT DRIVER NEEDED
    this.shapesStore = assetsContainer.shapesStore
    this.ecoShapesStore = assetsContainer.ecoShapesStore
    this.shapesController = assetsContainer.shapesController

    this.assetStore = designAndProductDriverContainer.assetStore
    this.productDriver = designAndProductDriverContainer.productDriver
    this.productDesignStore = designAndProductDriverContainer.productDesignStore

    this.predefinedTextsStore = assetsContainer.predefinedTextsStore
    this.textsController = assetsContainer.textsController

    if (designerModeContainer.available) {
      this.designerModeStore = designerModeContainer.designerModeStore
      this.designerModeController = designerModeContainer.designerModeController
    }

    // DOMAIN ENDED

    this.featureFlagsStore = utilEnvContainer.featureFlagStore
    globalThis.rootStore = this
  }
}
