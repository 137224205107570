export const i18nId = Object.freeze({
  Button: "generic.done",
  CalculatePrice: "product-configurator-app.calculate",
  GetAQuote: "box-configurator.z-box-configurator.get-a-quote",
  Label: "box-configurator.z-quantity-picker-wrapper.choose-amount.short",
  OutOfStock: "generic.out-of-stock",
  Placeholder: "generic.quantity-placeholder",
  SearchPlaceholder: "box-configurator.select-quantity.placeholder",
  SearchPlaceholderBundle:
    "box-configurator.select-quantity.placeholder-bundle",
  VatIncluded: "generic.vat-included",
  VatNotIncluded: "generic.vat-not-included",
  QuantityItemsUpliftedPlaceholder: "generic.choose-other-value",
  CustomQuantityLabel: "generic.custom-quantity",
  QuantityChangingDisabledInfo: "generic.quantity-changing-disabled-info",
} as const)
