export const I18N = {
  global: {
    loading: "global.loading-text",
  },
  generic: {
    cancel: "generic.cancel",
    allChangesSaved: "generic.all-changes-saved",
    projectName: "generic.project-name",
    notAvailableForSelectedSize: "generic.not-available-for-selected-size",
    save: "box-editor.top.actions.label.save",
    saving: "box-editor.top.actions.label.saving",
    getQuote: "box-configurator.z-box-configurator.get-a-quote",
    getQuoteMinQty:
      "box-configurator.z-box-configurator.get-a-quote-minimum-quantity",
    contactSales: "generic.contact-sales",
    dimensions: {
      width: "generic.dimensions.width",
      height: "generic.dimensions.height",
      length: "generic.dimensions.length",
    },
    min: "box-configurator.box-dimensions.min",
    max: "box-configurator.box-dimensions.max",
    addToCart: "generic.add-to-cart",
    saveAndContinue: "generic.save-and-continue",
    piecesNumber: "box-editor.bottom.section-values.quantity",
    loggedAs: "generic.logged-as",
    seeAll: "generic.see-all",
    add: "generic.add",
    remove: "generic.remove",
    quantity: "generic.quantity",
    variant: "generic.variant",
    apply: "generic.apply",
    customSize: "generic.sizes.custom-size",
    step: "generic.step",
    chooseElementPosition: "generic.choose-element-position",
    placeElementOnTheWall: "generic.place-element-on-the-wall",
    enterTheWallView: "generic.enter-the-wall-view",
    dielineOnly: "generic.dieline-only",
  },
  editorMode: {
    editor: "component.editor-mode.editor",
    dieline: "component.editor-mode.dieline",
    skipDesign: "component.editor-mode.skip-design",
  },
  menu: {
    menu: "component.editor-menu-item.menu",
    restart: "component.editor-menu-item.restart",
    save: "component.editor-menu-item.save-project",
    share: "component.editor-menu-item.share",
    guidelines: "component.editor-menu-item.guidelines",
    howto: "component.editor-menu-item.how-to-use",
    more: "component.editor-menu-item.more",
    myProjects: "component.editor-menu-item.my-projects",
    help: "component.editor-menu-item.help",
    signIn: "component.editor-menu-item.sign-in",
    duplicate: "component.editor-menu-item.duplicate",
  },
  confirmations: {
    closeWindow: {
      savingInProgress:
        "box-editor.window-confirm.confirm-losing-data--saving-data",
      unsavedData:
        "box-editor.window-confirm.confirm-losing-data--unsaved-data",
    },
  },
  notifications: {
    configurationChanged: {
      message: "notifications.configuration-changed.message",
      goBackLink: "notifications.configuration-changed.go-back-i",
      goBackTo: "notifications.configuration-changed.go-back-ii",
    },
    designDuplication: {
      success: "notifications.design-duplication.success",
    },
    saveDesign: {
      success: "component.save-design.success",
    },
    lowQuality: {
      message: "component.notification.low-quality",
    },
  },
  toolbar: {
    space: {
      togglePattern: "component.hide-on-space.pattern",
      toggleBgImage: "component.hide-on-space.background-fill",
      bgColor: "component.editor-tool-background.title.background-fill-color",
    },
    mask: {
      toggle: "component.editor-tool.checkbox.toggle-asset-mask",
    },
    image: {
      threshold: "component.editor-tools-assets.threshold",
      inverse: "component.editor-tools-assets.inverse",
      keepProportions: "component.editor-tool-shapes.checkbox.keep-proportions",
      removeWhiteBackground:
        "component.editor-tool-shapes.checkbox.remove-white-background",
    },
    spaceClipping: {
      toggler: "editor-tools.space-clipping.toggler",
      info: "editor-tools.space-clipping.info",
    },
  },
  tabs: {
    logo: "components.universal-editor.tab-name.logo",
    logoPlaceholder: "components.universal-editor.tab-name.logo-placeholder",
    templates: "components.universal-editor.tab-name.templates",
    patterns: "components.universal-editor.tab-name.patterns",
    replicablePatterns:
      "components.universal-editor.tab-name.replicable-pattern",
    configuration: "components.universal-editor.tab-name.product-configuration",
    product: "components.universal-editor.tab-name.product-switcher",
    background: "components.universal-editor.tab-name.background",
    text: "components.universal-editor.tab-name.text",
    certificates: "components.universal-editor.tab-name.certificates",
    elements: "components.universal-editor.tab-name.elements",
    devTools: "components.universal-editor.tab-name.dev-tools",
    fonts: "components.universal-editor.tab-name.fonts",
    textOptions: "components.universal-editor.tab-name.text-options",
    size: "components.universal-editor.tab-name.size",
    dby: "components.universal-editor.tab-name.dby",
    skipDesign: "components.universal-editor.tab-name.skip-design",
    upload: "components.universal-editor.tab-name.upload",
  },
  product: {
    configuration: {
      see: "component.editor-product-configuration.see",
      dbyOnly: "component.editor-product-configuration.dby-only",
    },
  },
  editorTools: {
    assets: {
      uploadImage: "editor-tools.assets.upload-image",
      library: "editor-tools.assets.library",
      uploadedImages: "editor-tools.assets.uploaded-images",
      templateLibrary: "editor-tools.assets.template-library",
      eco: {
        symbols: "editor-tools.eco.symbols",
        properties: "editor-tools.eco.properties",
      },
      allElements: "editor-tools.assets.all-elements",
    },
    eco: {
      plantTrees: "editor-tools.eco.plant-trees",
      supportForest: "editor-tools.eco.support-forest",
      addBadge: "editor-tools.eco.add-badge",
      learnMore: "editor-tools.eco.learn-more",
    },
    text: {
      addText: "components.two-dimensional-editor.text-tool.add-text",
      fontStyle: "components.editor-tool-text.label.font-style",
      fontWeight: "components.editor-tool-text.label.font-weight",
    },
    templates: {
      templates: "components.two-dimensional-editor.tab-name.templates",
      noTemplates: "editor-tools.no-templates",
      doubleSided: "editor-tool.template.type.double-sided",
      loadingTemplate: "editor-tool.loading-template",
      free: "component.currency-label.free",
    },
    patterns: {
      artwork: "editor-tools.patterns.artwork",
      text: "editor-tools.patterns.text",
      yourText: "editor-tools.patterns.your-text",
      fontOptions: "editor-tools.patterns.font-options",
      adjust: "editor-tools.patterns.adjust-fill-and-size",
      uploadNew: "editor-tools.patterns.upload-new",
      remove: "editor-tools.patterns.remove",
      straight: "editor-tools.patterns.straight",
      angled: "editor-tools.patterns.angled",
      regular: "editor-tools.patterns.regular",
      shifted: "editor-tools.patterns.shifted",
      patternPosition: "editor-tools.patterns.pattern-position",
      layout: "editor-tools.patterns.layout",
      artworkSize: "editor-tools.patterns.artwork-fill",
      artworkThreshold: "component.editor-tools-assets.threshold",
      horizontalSpacing: "editor-tools.patterns.horizontal-spacing",
      verticalSpacing: "editor-tools.patterns.vertical-spacing",
      layoutOptions: "editor-tools.patterns.layout-options",
      backToPatterns: "editor-tools.patterns.back-to-patterns",
      noPattern: "editor-tools.patterns.no-pattern",
      patternColor: "component.editor-tool-material-and-color.title.pattern",
      categories: {
        artisticShapes: "editor-assets.patterns.categories.artistic-shapes",
        lines: "editor-assets.patterns.categories.lines",
        other: "editor-assets.patterns.categories.other",
        texts: "editor-assets.patterns.categories.texts",
        clothesAndShoes: "editor-assets.patterns.categories.clothes-and-shoes",
        christmas: "editor-assets.patterns.categories.christmas",
        foodAndDrinks: "editor-assets.patterns.categories.food-and-drinks",
        nature: "editor-assets.patterns.categories.nature",
        geometricShapes: "editor-assets.patterns.categories.geometric-shapes",
        music: "editor-assets.patterns.categories.music",
      },
      colors: "component.pattern-configurator.colours",
      resetToDefault: "component.patterns-configurator.header.reset-to-default",
    },
    logoPlaceholder: {
      logoPlaceholder: "editor-tools.logo-placeholder.logo-placeholder",
      description: "editor-tools.logo-placeholder.description",
      addLogoAndEdit: "editor-tools.logo-placeholder.add-logo-and-edit",
      editComposition: "editor-tools.logo-placeholder.edit-composition",
      addLogo: "editor-tools.logo-placeholder.add-logo",
      header: "editor-tools.logo-placeholder.header",
      subheader: "editor-tools.logo-placeholder.subheader",
      loading: "components.curtain.loading-states.generic-loading",
    },
    fsc: {
      title: "editor-tools.fsc.title",
      shapesSection: {
        title: "editor-tools.fsc.shapes-section.title",
      },
      addFscFormBlock: {
        title: "editor-tools.fsc.checkbox-section.title",
        description: "editor-tools.fsc.checkbox-section.description",
        learnMore: "editor-tools.fsc.checkbox-section.learn-more",
        addToDesign: "editor-tools.fsc.checkbox-section.add-to-design",
      },
      spaceUnavailable: "editor-tools.fsc.space-unavailable",
      alreadyAdded: "modals.fsc-action-confirmation.change-shape.description",
    },
    changeSize: {
      changeSize: "editor-tools.change-size.title",
      chooseShape: "editor-tools.change-size.choose-shape",
      cancel: "editor-tools.change-size.cancel",
      apply: "editor-tools.change-size.apply",
      customSize: {
        title: "editor-tools.change-size.custom-size.title",
        button: "editor-tools.change-size.custom-size.button",
        info: "editor-tools.change-size.custom-size.info",
      },
    },
    productSwitcher: {
      category: "site-search.filters.category.title",
      noResults: "listings.page.empty-page.title",
      clearFilters: "listings.filters.clear-all",
      viewProductPage: "generic.editor.view-product-page",
    },
  },
  components: {
    readOnly: {
      info1: "editor.messages.cart-preview-info",
      info2: "editor.messages.cart-preview-action",
      goToCart: "editor.messages.cart-preview-go-to",
      duplicateDesign: "editor.messages.cart-preview-action-copy",
    },
    skipDesign: {
      description: "components.editor-skip-design.description",
    },
    editorToolText: {
      label: {
        moreOptions: "components.editor-tool-text.label.more-options",
        fontLineHeight: "components.editor-tool-text.label.font-line-height",
        fontLetterSpacing:
          "components.editor-tool-text.label.font-letter-spacing",
        effects: "components.editor-tool-text.label.effects",
        none: "components.editor-tool-text.label.none",
        shadow: "components.editor-tool-text.label.shadow",
        stroke: "components.editor-tool-text.label.stroke",
        offsetX: "components.editor-tool-text.label.offsetX",
        offsetY: "components.editor-tool-text.label.offsetY",
        colour: "components.editor-tool-text.label.colour",
        thickness: "components.editor-tool-text.label.thickness",
        selectedColour: "components.editor-tool-text.label.selected-colour",
        defaultColours: "components.editor-tool-text.label.default-colours",
        hideSwatch: "components.editor-tool-text.label.hide-swatch",
        selectFont: "components.editor-tool-text.label.select-font",
        fontStyle: "components.editor-tool-text.label.font-style",
        fontWeight: "components.editor-tool-text.label.font-weight",
        alignLeft: "components.editor-tool-text.label.align-left",
        alignCenter: "components.editor-tool-text.label.align-center",
        alignRight: "components.editor-tool-text.label.align-right",
      },
    },
    floatingToolbar: {
      imageQuality: "components.floating-toolbar.image-quality",
      imageQualityInfo: "components.floating-toolbar.image-quality-info",
      placementInfo: "components.floating-toolbar.placement-info",
      spaceClippingDisabledInfo:
        "components.floating-toolbar.space-clipping-disabled-info",
      sizeInfo: "components.floating-toolbar.size-info",
      minScaleLimitInfo: {
        group: "components.floating-toolbar.min-scale-limit-info.group",
        text: "components.floating-toolbar.min-scale-limit-info.text",
        shape: "components.floating-toolbar.min-scale-limit-info.shape",
      },
    },
    canvasContextMenu: {
      group: "components.editor-header-toolbar.label.group",
      ungroup: "components.editor-header-toolbar.label.ungroup",
      vertical: "components.editor-header-toolbar.label.align-vertical",
      horizontal: "components.editor-header-toolbar.label.align-horizontal",
      front: "components.editor-header-toolbar.label.bring-front",
      back: "components.editor-header-toolbar.label.bring-back",
      duplicate: "components.editor-header-toolbar.label.duplicate",
      remove: "components.editor-header-toolbar.label.remove",
      removeGroup: "components.editor-header-toolbar.label.remove-group",
      removeItem: "components.editor-header-toolbar.label.remove-item",
      moreOptions: "components.editor-tool-text.label.more-options",
    },
  },
  component: {
    dby: {
      link: {
        ai: "components.dby.link.ai",
        pdf: "components.dby.link.pdf",
      },
      step1: {
        title: "components.dby.steps.1.title",
      },
      step2: {
        title: "components.dby.steps.2.title",
      },
      step3: {
        title: "components.dby.steps.3.title",
      },
    },
    loader: {
      generic: "components.curtain.loading-states.generic-loading",
      loadingDesign: "components.curtain.loading-states.loading-design",
      savingDesign: "components.curtain.loading-states.saving-design",
      addingToCart: "components.curtain.loading-states.adding-to-cart",
      resizing: "components.renderer.loaders.resizing",
      restarting: "components.renderer.loaders.reseting",
      duplicatingDesign: "components.renderer.loaders.duplicating-design",
      loadingTemplate: "components.curtain.loading-states.loading-template",
      loadingPattern: "components.curtain.loading-states.loading-pattern",
      loadingElement: "components.curtain.loading-states.loading-element",
      loadingText: "components.curtain.loading-states.loading-text",
      loadingImage: "components.curtain.loading-states.loading-image",
      loadingProduct: "components.curtain.loading-states.loading-product",
      calculatingPrice: "components.curtain.loading-states.calculating-price",
      loadingDieline: "components.curtain.loading-states.loading-dieline",
      loadingModel: "components.curtain.loading-states.loading-model",
    },
    footer: {
      toggleView: "editor.footer.toggle-view",
      side: "editor.footer.side",
      threeDim: "editor.footer.3d",
      toggler: {
        close: "component.scene-footer-3d.toggler.close",
        open: "component.scene-footer-3d.toggler.open",
      },
    },
    navbar: {
      defaultDesignName: "editor.messages.design-name-untitled",
      designLater: "generic.design-later-and-add-to-cart",
    },
    assetsUploader: {
      uploadFile: "editor.upload.add-file",
      uploadImage: "component.editor-tool.assets.uploader.upload-image",
      uploadDieline: "component.editor-tool.assets.uploader.upload-dieline",
      uploadGlobal: "components.global-dropzone.drop-prompt",
      setAsLogo: "component.editor-tool.assets-uploader.set-as-logo",
      remove: "component.editor-tool.assets-uploader.remove",
      reuploadFile: "component.editor-tool.assets-uploader.reupload-file",
      removeFile: "component.editor-tool.assets-uploader.remove-file",
      uploading: "editor.upload.uploading",
      error: "editor.upload.error",
      reuploadLogo: "editor-tools.personalize.upload-button",
      removeLogo: "editor-tools.personalize.remove-button",
      logo: "component.assets-uploader.labels.logo",
    },
    cameraControlsToolbar: {
      overview: "component.editor-tool.camera-controls.overview",
      flatView: "component.editor-tool.camera-controls.flat-view",
      limitedSpacesWarning:
        "component.editor-tool.camera-controls.limitedSpacesWarning",
      backToModel: "component.editor-tool.camera-controls.back-to-3d",
      viewType: {
        dieline: "component.editor-tool.camera-controls.2d-view",
        model: "component.editor-tool.camera-controls.3d-view",
      },
      context: {
        outside: "component.editor-tool.camera-controls.outside",
        inside: "component.editor-tool.camera-controls.inside",
        sleeve: "component.editor-tool.camera-controls.sleeve",
        front: "sides.front",
        back: "sides.back",
      },
      space: {
        front: "sides.front",
        back: "sides.back",
        bottom: "sides.bottom",
        top: "sides.top",
        left: "sides.left",
        right: "sides.right",
        lid: "sides.lid",
        front_inside: "sides.front-inside",
        default: "sides.default",
        top_front: "sides.top-front",
        top_back: "sides.top-back",
        bottom_front: "sides.bottom-front",
        bottom_back: "sides.bottom-back",
      },
      zoom: {
        in: "component.zoom.zoom-in",
        out: "component.zoom.zoom-out",
      },
    },
    printingMode: {
      inside: {
        title: "component.editor-tool.print-inside.title",
        piece: "component.editor-tool.print-inside.piece",
        description: "component.editor-tool.print-inside.description",
      },
      back: {
        title: "component.editor-tool.print-back.title",
        piece: "component.editor-tool.print-inside.piece",
        description: "component.editor-tool.print-back.description",
      },
    },
    colorPicker: {
      background:
        "component.editor-tool-background.title.background-fill-color",
      pantone: {
        search: "component.editor-tool.color-picker.pantone.search",
        noResults: "component.editor-tool.color-picker.pantone.noResults",
      },
      lessColors: "component.colour-picker.less-colours",
      moreColors: "component.colour-picker.more-colours",
    },
    dbyUploader: {
      link: {
        ai: "editor.dby-upload.link.ai",
        pdf: "editor.dby-upload.link.pdf",
      },
      step1: {
        title: "editor.dby-upload.steps.1.title",
      },
      step2: {
        title: "editor.dby-upload.steps.2.title",
      },
      step3: {
        title: "editor.dby-upload.steps.3.title",
      },
      uploaded: "editor.dby-upload.uploaded",
    },
    backgroundImage: {
      title: "component.editor-tool-background.title.background-image",
      noImageLabel:
        "component.editor-tool-background.background-image.no-image",
      addImage: "component.editor-tool-background.background-image.add-image",
      addNewImage:
        "component.editor-tool-background.background-image.add-new-image",
      uploading: "editor.upload.uploading",
      remove: {
        file: "component.editor-tool.assets-uploader.remove-file",
        outside:
          "component.editor-tool.assets-uploader.remove-background-image-outside",
        inside:
          "component.editor-tool.assets-uploader.remove-background-image-inside",
        front:
          "component.editor-tool.assets-uploader.remove-background-image-front",
        back: "component.editor-tool.assets-uploader.remove-background-image-back",
      },
    },
  },
  modals: {
    changeMode: {
      title: "modals.change-mode.title",
      message: "modals.change-mode.message",
      confirm: "modals.change-mode.confirm",
    },
    bndl: {
      title: "modals.bndl.title",
      description: "modals.bndl.description",
      confirm: "modals.bndl.confirm",
      finishDesign: "modals.bndl.finish-design",
    },
    restart: {
      title: "modal.editor.discard-changes.header",
      description: "modal.editor.discard-changes.description",
      confirm: "modal.editor.discard-changes.button",
    },
    changeTemplate: {
      applyTemplate: {
        areYouSure: "modals.change-template.are-you-sure.apply",
        changesLost: "modals.change-template.design-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.apply",
      },
      changeTemplate: {
        areYouSure: "modals.change-template.are-you-sure.apply",
        changesLost: "modals.change-template.template-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.change",
      },
      removeTemplate: {
        areYouSure: "modals.change-template.are-you-sure.apply",
        changesLost: "modals.change-template.template-changes-lost",
        elementsRemoved: "modals.change-template.elements-removed",
        confirm: "modals.change-template.confirm.remove",
      },
    },
    shareDesign: {
      title: "modals.share-box-design.title",
      share: "modals.share-box-design.share-c2a",
      send: "modals.share-box-design.send-design",
      info1: "modals.share-box-design.share-status.not-shared",
      info2: "modals.share-box-design.share-status.not-shared-explainer",
      linkShare: {
        title: "modals.share-box-design.via-link",
        description: "modals.share-box-design.share-status.shared",
      },
      emailShare: {
        title: "modals.share-box-design.via-email",
        description: "modals.share-box-design.email-explainer",
        success: "modals.share-box-design.email-share-success",
      },
    },
    registration: {
      title: "modals.registration.title",
      confirm: "modals.registration.confirm",
      emailSent: "modals.registration.email-sent",
    },
  },
  assetError: {
    unsupportedFormatDby:
      "component.editor-tool.assets-uploader.error.unsupported-file-format-dby",
    unsupportedFormat:
      "component.editor-tool.assets-uploader.error.unsupported-file-format",
    tranformationFailed:
      "component.editor-tool.assets-uploader.error.asset-transformation-failed",
    emptyData: "component.editor-tool.assets-uploader.error.empty-data",
    noPreview: "component.editor-tool.assets-uploader.error.no-preview",
    invalidConfig:
      "component.editor-tool.assets-uploader.error.invalid-s3-config",
    uploadFailed: "component.editor-tool.assets-uploader.error.upload-failed",
  },
  errors: {
    500: {
      title: "best-practices-modal.fetch-error",
      description: "component.error.please-reload",
      button: "modals.error-view.reload-button",
    },
    unauthorized: {
      title: "component.unauthorized.header",
      description: "component.unauthorized.description",
      button: "component.unauthorized.go-to-home-page",
    },
    sizeNotSupported: {
      title: "component.device-unsupported.mobile-unavailable.header",
      description:
        "component.device-unsupported.mobile-unavailable.switch-to-desktop",
      button: "component.device-unsupported.mobile-unavailable.go-to-homepage",
    },
  },
}
