import { observable, computed, action, makeObservable } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { ProductDesignStore } from "../../stores/product-design.store"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { TemplateLoader } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-loader/template.loader"
import { TemplateExporter } from "../designer-mode/template/template-exporter"
import { DesignTemplateData } from "../../types/design.types"
import VirtualDielineEditor from "../../render-engine/modules/vd-editor/virtual-dieline-editor"
import {
  CameraAngle,
  EditContext,
} from "../../libs/products-render-config/types"
import {
  ChangeProductControllable,
  OriginalDesignData,
} from "./change-product.interface"
import { VirtualDielineLoader } from "../../render-engine/modules/dieline-manager/virtual-dieline.loader"
import { DielineStore } from "../../stores/dieline.store"
import { VariantCustomization } from "@ph/product-api"

export class ChangeProductEditorMode implements ChangeProductControllable {
  @observable public originalDesign?: OriginalDesignData

  constructor(
    private readonly services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    private readonly stores: {
      productDesignStore: ProductDesignStore
      dielineStore: DielineStore
    }
  ) {
    makeObservable(this)
  }

  public isChangeRisky(): boolean {
    return this.services.productDriver.getVdEditors().some((vdEditor) => {
      return !!vdEditor.assetsModule.getEditableObjects().length
    })
  }

  @action
  public async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    if (this.services.productDriver.state.isProductChanging) {
      return
    }

    this.services.productDriver.setIsProductChanging(true)

    if (!this.originalDesign) {
      await this.cacheOriginalDesign()
    }

    return new Promise(async (resolve) => {
      this.services.ee.once(
        eventTree.productDriver.renderEngineRefreshed,
        async () => {
          await this.onRenderEngineRefreshed()
          resolve()
        }
      )
      await this.services.productDriver.changeSku(sku, customization)
    })
  }

  private async onRenderEngineRefreshed(): Promise<void> {
    await this.services.productDriver.reloadModel()
    await this.reloadVirtualDielines()

    if (this.originalDesign) {
      await this.clearCanvases()
      await this.loadDesignTemplate(this.originalDesign.data!)
    }

    await this.services.productDriver.refreshView()

    this.services.ee.once(
      eventTree.productDriver.backgroundLayersRefreshed,
      () => this.services.productDriver.setIsProductChanging(false)
    )
    this.services.ee.emit(eventTree.productDriver.productChanged)
  }

  private async loadDesignTemplate(
    designTemplateExport: DesignTemplateData
  ): Promise<void> {
    const templateLoader = new TemplateLoader(
      this.vdEditors,
      this.services.productDriver.state.productRenderPilot
    )

    await templateLoader.load(designTemplateExport)
  }

  private async getDesignTemplateExport(): Promise<DesignTemplateData> {
    const templateExporter = new TemplateExporter(this.vdEditors)

    return templateExporter.export()
  }

  private async reloadVirtualDielines(): Promise<void> {
    const { productRenderPilot } = this.services.productDriver.state

    await Promise.all(
      this.vdEditors.map(async (vdEditor) => {
        const vdLoader = new VirtualDielineLoader(
          productRenderPilot,
          vdEditor.getCanvasDimensions().width
        )
        const { editContext } = vdEditor

        const dielineSvg = await this.stores.dielineStore.getVirtualDielineSvg(
          productRenderPilot.getProduct(),
          editContext
        )

        if (!dielineSvg) {
          throw new Error(`No virtual dieline for ${editContext}`)
        }

        await vdEditor.setVirtualDieline(
          await vdLoader.load(dielineSvg, editContext)
        )
        await vdEditor.prepareEditing()
      })
    )
  }

  private async clearCanvases(): Promise<void> {
    await Promise.all(
      this.vdEditors.map(async (vdEditor) => {
        vdEditor.backgroundsModule.removeGlobalPattern()
        await vdEditor.backgroundsModule.removeBackgroundImage()
        await vdEditor.backgroundsModule.applyColorOnProduct(null)
        vdEditor.clearEditableObjects()
      })
    )
  }

  public async cacheOriginalDesign(): Promise<void> {
    const { productSku, customization } =
      this.services.productDriver.productStore
    this.setOriginalDesign({
      sku: productSku,
      customization,
      data: await this.getDesignTemplateExport(),
    })
  }

  @action
  private setOriginalDesign(originalDesign: OriginalDesignData): void {
    this.originalDesign = originalDesign
  }

  @action
  public clearOriginalDesign(): void {
    this.originalDesign = undefined
  }

  @computed
  private get activeContext(): EditContext {
    return this.services.productDriver.state.activeContext
  }

  @computed
  private get activeSpace(): CameraAngle {
    return this.services.productDriver.state.activeSpace
  }

  private get vdEditors(): VirtualDielineEditor[] {
    return this.services.productDriver.getVdEditors()
  }

  public async cancel(): Promise<void> {
    if (!this.originalDesign) {
      return
    }

    const { sku, customization } = this.originalDesign

    await this.changeSku(sku, customization)
    this.clearOriginalDesign()
  }
}
