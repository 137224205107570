import THREE from "../../../../libs/vendors/THREE"
import { SvgSpaceConfig } from "../types"
import { OffsetCalculator } from "./offset.calculator"

export class RotationPointCalculator {
  constructor(
    private readonly object: THREE.Mesh,
    private readonly config: SvgSpaceConfig["folding"]
  ) {}

  public call(): THREE.Vector3 {
    const rotationPoint = this.calculateRotationBoundingPoint()
    const offset = new OffsetCalculator(this.object, this.config).call()

    return new THREE.Vector3(
      rotationPoint.x + offset.x,
      rotationPoint.y + offset.y,
      rotationPoint.z + offset.z
    )
  }

  private calculateRotationBoundingPoint(): THREE.Vector3 {
    const rotationPoint = this.boundingBox[this.config.boundingPoint]

    if (rotationPoint) {
      return rotationPoint
    }

    if (
      (this.config.angle > 0 && this.config.axis.y === 1) ||
      (this.config.angle < 0 && this.config.axis.x === 1)
    ) {
      return this.boundingBox.max
    }

    return this.boundingBox.min
  }

  private get boundingBox(): THREE.Box3 {
    return this.object.geometry.boundingBox!
  }
}
