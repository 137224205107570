export const config = {
  thickness: 1,
  rotation: {
    initial: 90,
  },
  folding: {
    target: {
      closed: 100,
      opened: 100,
    },
  },
  spaces: {
    front: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: -90,
        step: 6,
      },
    },
    front_inside: {
      parent: "front",
      folding: {
        axis: "x",
        angle: -180,
        step: 4,
      },
    },
    front_inside_glue_flap: {
      parent: "front_inside",
      folding: {
        axis: "x",
        angle: 90,
        step: 5,
        boundingPoint: "max",
      },
    },
    back: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: 90,
        step: 6,
      },
    },
    back_inside: {
      parent: "back",
      folding: {
        axis: "x",
        angle: 180,
        step: 4,
      },
    },
    back_inside_glue_flap: {
      parent: "back_inside",
      folding: {
        axis: "x",
        angle: -90,
        step: 5,
        boundingPoint: "min",
      },
    },
    left: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: 90,
        step: 6,
      },
    },
    left_left_flap: {
      parent: "left",
      folding: {
        axis: "x",
        angle: -90,
        step: 1,
      },
    },
    left_right_flap: {
      parent: "left",
      folding: {
        axis: "x",
        angle: 90,
        step: 1,
      },
    },
    left_inside: {
      parent: "left",
      folding: {
        axis: "y",
        angle: 180,
        step: 2,
      },
    },
    left_inside_glue_flap: {
      parent: "left_inside",
      folding: {
        axis: "y",
        angle: -90,
        step: 3,
        boundingPoint: "max",
      },
    },
    right: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: -90,
        step: 6,
      },
    },
    right_left_flap: {
      parent: "right",
      folding: {
        axis: "x",
        angle: -90,
        step: 1,
      },
    },
    right_right_flap: {
      parent: "right",
      folding: {
        axis: "x",
        angle: 90,
        step: 1,
      },
    },
    right_inside: {
      parent: "right",
      folding: {
        axis: "y",
        angle: -180,
        step: 2,
      },
    },
    bottom_inside: {
      parent: "right_inside",
      folding: {
        axis: "y",
        angle: 90,
        step: 3,
        boundingPoint: "min",
      },
    },
  },
} as const
