import TextField from "dsl/src/atoms/TextField/TextField"
import { FormField } from "dsl/src/molecules/FormField/FormField"
import React from "react"
import { useTranslate } from "../../../../shared-components/i18n/useTranslate"
import { i18nId } from "../utils/translations"
import { sanitizeZipCode } from "../utils/zip-code.helpers"

interface FormFieldShippingZipCodeProps {
  handleZipCodeChange: (zipCode: string) => void
  shouldDisplayError: boolean
  zipCode: string
}

export const FormFieldShippingZipCode = (
  props: FormFieldShippingZipCodeProps
) => {
  const { handleZipCodeChange, shouldDisplayError, zipCode } = props
  const translate = useTranslate()

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const code = sanitizeZipCode(event.currentTarget.value)
    handleZipCodeChange(code)
  }

  return (
    <FormField
      label={translate(i18nId.ZipCode)}
      field={
        <TextField
          medium
          value={zipCode}
          onChange={handleChange}
          type="text"
          maxLength={9}
          error={shouldDisplayError}
          e2eTarget="zip-code-field"
        />
      }
      error={shouldDisplayError && translate(i18nId.ZipCodeValidationError)}
    />
  )
}
