import * as locales from "./others/async-18n-injection.js"
import React, { useEffect, useState } from "react"

import { InjectIntlContext } from "./others/i18n"
import { IntlProvider } from "react-intl"
import RegionConfig, {
  Locale,
} from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import { translateLocaleKeyToRegionConfigKey } from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import _get from "lodash/get"
import { isNode } from "./utils"

export enum Lang {
  csCZ = "cs-CZ",
  deDE = "de-DE",
  enUS = "en-US",
  esES = "es-ES",
  frFR = "fr-FR",
  itIT = "it-IT",
  plPL = "pl-PL",
  ptPT = "pt-PT",
  svSE = "sv-SE",
  roRO = "ro-RO",
  nlNL = "nl-NL",
}

export const langToLocale = new Map<Locale, Lang>([
  ["cz", Lang.csCZ],
  ["cs", Lang.csCZ],
  ["de", Lang.deDE],
  ["en", Lang.enUS],
  ["es", Lang.esES],
  ["fr", Lang.frFR],
  ["it", Lang.itIT],
  ["pl", Lang.plPL],
  ["pt", Lang.ptPT],
  ["sv", Lang.svSE],
  ["ro", Lang.roRO],
  ["nl", Lang.nlNL],
])

export const languageMap = {
  [Lang.csCZ]: locales.loadCzLocale,
  [Lang.deDE]: locales.loadDeLocale,
  [Lang.enUS]: locales.loadEnLocale,
  [Lang.esES]: locales.loadEsLocale,
  [Lang.frFR]: locales.loadFrLocale,
  [Lang.itIT]: locales.loadItLocale,
  [Lang.plPL]: locales.loadPlLocale,
  [Lang.svSE]: locales.loadSvLocale,
  [Lang.ptPT]: locales.loadPtLocale,
  [Lang.roRO]: locales.loadRoLocale,
  [Lang.nlNL]: locales.loadNlLocale,
}

let languageTag = ""
let intlMessages = {}

// Sometimes we force to load different locale on domain region
export const getLanguageTag = (): Lang => {
  if (isNode()) {
    return RegionConfig.getKeyForCurrentRegion("languageTag")
  }

  const localeFromUrl = new URLSearchParams(window.location.search).get(
    "locale"
  )
  const localeFromEnv = _get(globalThis, "ph.locale.lang")

  // This is temporary fix to bypass region config initialization for editor. It should be removed after locale refactoring
  if (localeFromEnv === "pt") {
    return Lang.ptPT
  }

  const forcedLocale = localeFromUrl || localeFromEnv

  if (forcedLocale) {
    // in case of fire - eg. not existing region, it's fallback to domain locale
    return RegionConfig.getKeyForRegion(
      translateLocaleKeyToRegionConfigKey(forcedLocale),
      "languageTag"
    )
  } else {
    return RegionConfig.getKeyForCurrentRegion("languageTag")
  }
}

export const loadIntlMessages = new Promise((resolve, reject) => {
  languageTag = getLanguageTag()
  const messageLoader = languageMap[languageTag]
  messageLoader()
    .then(resolve)
    .catch((e) => {
      console.error(e)
      throw new Error("failed to load a language")
    })
})

if (!isNode()) {
  ;(window as any).__NEXT_PRELOADREADY = async function () {
    return await loadIntlMessages
  }
}

export const getFormatMessage = async () => {
  const messages = await loadIntlMessages
  const intlProvider = new IntlProvider({
    locale: getLanguageTag(),
    messages,
  })
  const { intl } = intlProvider.getChildContext()
  return intl.formatMessage
}

export const loadEnglishMessages = new Promise((resolve, reject) => {
  const messageLoader = languageMap[Lang.enUS]
  messageLoader()
    .then(resolve)
    .catch((e) => {
      throw new Error("failed to load a language")
    })
})

type AsyncIntContainerType = {
  (props: AsyncIntContainerWithPrefetchedMessagesProps): JSX.Element
  (props: AsyncIntContainerWithMessageSelfFetchProps): JSX.Element
}

const hasMessages = (
  props:
    | AsyncIntContainerWithPrefetchedMessagesProps
    | AsyncIntContainerWithMessageSelfFetchProps
): props is AsyncIntContainerWithPrefetchedMessagesProps => "messages" in props

export const AsyncIntContainer: AsyncIntContainerType = (
  props:
    | AsyncIntContainerWithPrefetchedMessagesProps
    | AsyncIntContainerWithMessageSelfFetchProps
) => {
  return hasMessages(props) ? (
    <AsyncIntContainerWithMessageSelfFetch
      languageTag={props.languageTag}
      messages={props.messages}
    >
      {props.children}
    </AsyncIntContainerWithMessageSelfFetch>
  ) : (
    <AsyncIntContainerWithMessageSelfFetch>
      {props.children}
    </AsyncIntContainerWithMessageSelfFetch>
  )
}

type AsyncIntContainerWithMessageSelfFetchProps = {
  children: React.ReactNode
  messages?: any
  languageTag?: Lang
}
export const AsyncIntContainerWithMessageSelfFetch = (
  props: AsyncIntContainerWithMessageSelfFetchProps
) => {
  const [isLocaleLoaded, setLocaleLoadingState] = useState(false)
  useEffect(() => {
    let isSubscribed = true

    loadIntlMessages
      .then((jsonMessages) => {
        intlMessages = jsonMessages as any
        if (isSubscribed) {
          setLocaleLoadingState(true)
        }
      })
      .catch((e) => {
        console.error(e)
        throw new Error("failed to load a language")
      })

    return () => {
      isSubscribed = false
    }
  }, [])
  return hasMessages(props) ? (
    <IntlProvider
      locale={props.languageTag}
      messages={props.messages}
      onError={(e) => {
        // ASTRO HACK
        // console.log("silence")
      }}
    >
      <InjectIntlContext>{props.children}</InjectIntlContext>
    </IntlProvider>
  ) : isLocaleLoaded ? (
    <IntlProvider locale={languageTag} messages={intlMessages}>
      <InjectIntlContext>{props.children}</InjectIntlContext>
    </IntlProvider>
  ) : null
}

type AsyncIntContainerWithPrefetchedMessagesProps = {
  messages: any
  languageTag: Lang
  children: React.ReactNode
}
export const AsyncIntContainerWithPrefetchedMessages = (
  props: AsyncIntContainerWithPrefetchedMessagesProps
) => {
  console.log("AsyncIntContainerWithPrefetchedMessages")
  return (
    <IntlProvider locale={props.languageTag} messages={props.messages}>
      <InjectIntlContext>{props.children}</InjectIntlContext>
    </IntlProvider>
  )
}
