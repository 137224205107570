import { EventEmitter } from "eventemitter3"
import { Debug } from "../services/logger"
import type StrictEE from "strict-event-emitter-types"

export type EESet<Set> = StrictEE<TypedEmitter, Set>

export class TypedEmitter extends EventEmitter {
  // @ts-ignore
  emit(type, ...args) {
    // do not remove the magic `ph:` prefix. This will disable debugger
    Debug(`ph: mb ~~~~~> ${type}`)(args[0])
    super.emit(type, ...args)
  }
}
