import { AxiosInstance } from "axios"
import { DirectusShape } from "../types"

interface GetEcoShapesOptions {
  sku?: string
  type?: string | string[]
}

export class DirectusEcoShapeApi {
  constructor(private ax: AxiosInstance) {}

  public getEcoShapesDownloadUrl(options?: GetEcoShapesOptions): string {
    return this.ax.getUri({
      url: `${this.ax.defaults.baseURL}/eco-shapes`,
      params: {
        ...options,
        format: "zip",
        limit: -1,
      },
    })
  }

  public async getEcoShapes(
    options: GetEcoShapesOptions
  ): Promise<DirectusShape[]> {
    const response = await this.ax.get<{ data: DirectusShape[] }>(
      "eco-shapes",
      {
        params: {
          ...options,
          type: options?.type,
          limit: -1,
        },
      }
    )

    return response.data.data
  }
}
