import DeRegion from "./de"

const Region = {
  ...DeRegion,
  countryCode: "ch",
  mainRegion: false,
  languageTag: "de-DE",
  iterableLocale: "de-DE",
  locale: "de",
  phoneCountryCode: "+41",
  defaultShippingCountryCode: "CH",
  additionalCustoms: 40,
  supportsPlus: false,
}

export default Region
