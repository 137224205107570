import { parseSync, stringify, INode } from "svgson"
import { SpacesExtractor } from "./spaces.extractor"
import { ElementsSorter } from "./elements.sorter"
import { Config } from "./types"
import { Resizer } from "./resizer"
import { SvgPath } from "./svg-elements/svg-path"
import { SvgElement } from "./svg-elements/svg-element"

const defaultConfig = {
  width: 1024,
  height: 1024,
  flipHorizontal: false,
  flipVertical: false,
}

export class DielineSvgConverter {
  public constructor(private readonly svg: string) {}

  public call(customConfig: Partial<Config> = {}): string {
    const config = {
      ...defaultConfig,
      ...customConfig,
    }
    const svgObject = this.parseSvg()
    const spaces = new SpacesExtractor(svgObject).call()

    if (Object.keys(spaces).length === 0) {
      return this.svg
    }

    this.processSvg(svgObject, spaces, config)

    return stringify(svgObject)
  }

  private processSvg(
    svgObject: INode,
    spaces: Record<string, SvgElement[]>,
    config: Config
  ): INode {
    const svgPaths: SvgPath[] = []

    for (const [space, elements] of Object.entries(spaces)) {
      svgPaths.push(this.buildSpacePath(space, elements))
    }

    const { elements: resizedPaths, size: newSize } = new Resizer(
      svgPaths
    ).call(config)

    svgObject.children = resizedPaths.map((element) => {
      element.flip(newSize, {
        horizontal: config.flipHorizontal,
        vertical: config.flipVertical,
      })

      return element.toNode()
    })

    svgObject.attributes = {
      ...svgObject.attributes,
      width: newSize.width.toString(),
      height: newSize.height.toString(),
      viewBox: `0 0 ${newSize.width} ${newSize.height}`,
    }

    return svgObject
  }

  private buildSpacePath(space: string, elements: SvgElement[]): SvgPath {
    const sortedElements = new ElementsSorter(elements).call()

    const newElement = new SvgPath({
      name: "path",
      type: "element",
      value: "",
      attributes: {
        d: "",
        fill: "none",
        id: space,
        stroke: "black",
      },
      children: [],
    })

    for (const element of sortedElements) {
      newElement.add(element)
    }

    return newElement
  }

  private parseSvg(): INode {
    const parsedSvg = parseSync(this.svg)
    let children: INode[] = []

    for (const child of parsedSvg.children) {
      if (child.name === "g") {
        children = [...children, ...child.children]
      } else {
        children.push(child)
      }
    }

    return {
      ...parsedSvg,
      children,
    }
  }
}
