var BoxPrintMode;
(function (BoxPrintMode) {
    BoxPrintMode["PRINT_MODE_COLOR_BUDGET"] = "printColorBudget";
    BoxPrintMode["PRINT_MODE_COLOR_HD"] = "printColorHD";
    BoxPrintMode["PRINT_MODE_COLOR_ECO"] = "printColorECO";
    BoxPrintMode["PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE"] = "printColorECOWithPrintInside";
    BoxPrintMode["PRINT_MODE_MONOCHROME"] = "monochrome";
    BoxPrintMode["PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE"] = "monochromeWithPrintInside";
    BoxPrintMode["PRINT_MODE_MONOCHROME_WHITE"] = "monochromeWhite";
    BoxPrintMode["PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE"] = "monochromeWhiteWithPrintInside";
    BoxPrintMode["PRINT_COLOR_MODE_NO_PRINT"] = "noPrint";
    BoxPrintMode["PRINT_COLOR_MODE_INDIGO_COLOR"] = "printIndigoColor";
    BoxPrintMode["PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE"] = "printIndigoColorWithInside";
})(BoxPrintMode || (BoxPrintMode = {}));
export default BoxPrintMode;
