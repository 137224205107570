import { EditContext, ModelContext, TextureDefinition } from "../types"
import { ProductManager } from "@ph/product-api"

export class TexturesController {
  public constructor(
    private readonly product: ProductManager,
    private readonly renderConfig
  ) {}

  public getTexturesConfig(model, variant) {
    const texturesConfig =
      this.renderConfig.texturesConfig.models?.[model]?.variants?.[variant]

    if (!texturesConfig) {
      throw new Error(
        `No textures config for model ${model} and variant ${variant}`
      )
    }

    return texturesConfig
  }

  public getVirtualDielineTexture(
    editContext: EditContext,
    isSpaceZoomActive: boolean
  ): TextureDefinition | undefined {
    const currentModel = this.product.variantManager.getName()
    const currentVariant = this.product.variantManager.getVariant()
    const currentMaterial = this.product.variantManager.getMaterial()

    const variantSpecificTextures = this.getTexturesConfig(
      currentModel,
      currentVariant
    )

    let materialSpecificTexture = variantSpecificTextures.materials.find(
      (material) => material.id === currentMaterial
    )

    if (!materialSpecificTexture) {
      materialSpecificTexture = variantSpecificTextures.materials[0]
    }

    const { virtualDielineTexture, virtualDielineZoomTexture } =
      materialSpecificTexture.texturesPaths

    if (isSpaceZoomActive && virtualDielineZoomTexture?.[editContext]) {
      return virtualDielineZoomTexture[editContext]
    }

    if (virtualDielineTexture[editContext]) {
      return virtualDielineTexture[editContext]
    }

    if (isSpaceZoomActive && virtualDielineZoomTexture) {
      return virtualDielineZoomTexture
    }

    return virtualDielineTexture
  }

  public getSceneBackgroundTextureUrl(
    editContext: EditContext
  ): string | undefined {
    const scenesDecorations = this.renderConfig.scenesDecorations

    if (!scenesDecorations) {
      return
    }

    const sceneDecoration = scenesDecorations[editContext]

    if (!sceneDecoration) {
      return
    }

    const currentMaterial = this.product.variantManager.getMaterial()

    const backgroundTexture = sceneDecoration.backgroundTextures.find(
      (texture) => texture.materialId === currentMaterial
    )

    if (!backgroundTexture) {
      return
    }

    return backgroundTexture.textureUrl
  }

  public getObjModelTextures(modelContext: ModelContext): TextureDefinition[] {
    const currentModel = this.product.variantManager.getName()
    const currentVariant = this.product.variantManager.getVariant()
    const currentMaterial = this.product.variantManager.getMaterial()
    const currentFinish = this.product.variantManager.getFinish()
    const currentVariantTextures = this.getTexturesConfig(
      currentModel,
      currentVariant
    )

    const textures = [...this.renderConfig.texturesConfig.global]

    const materialSpecificTextures = currentVariantTextures.materials.find(
      (material) => material.id === currentMaterial
    )

    if (materialSpecificTextures) {
      const allRendererTextures =
        materialSpecificTextures.texturesPaths.rendererTextures
      const rendererTextures =
        allRendererTextures[currentFinish] || allRendererTextures

      const modelContextTextures = rendererTextures.modelContexts[modelContext]

      if (Array.isArray(modelContextTextures)) {
        textures.push(...modelContextTextures)
      }
    }

    const finishSpecificTextures = currentVariantTextures.finishes.find(
      (finish) => finish.id === currentFinish
    )

    if (finishSpecificTextures) {
      const rendererTextures =
        finishSpecificTextures.texturesPaths.rendererTextures

      const globalTextures: any[] = rendererTextures.global

      if (Array.isArray(globalTextures)) {
        textures.push(...globalTextures)
      }

      let modelContextTextures: any[] = []

      if (rendererTextures.modelContexts) {
        modelContextTextures = rendererTextures.modelContexts[modelContext]
      }

      if (Array.isArray(modelContextTextures)) {
        textures.push(...modelContextTextures)
      }
    }

    return textures
  }
}
