import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import { getEnvVariable } from "../../../configs/region-config/interfaces/js/env-config"

export interface ProductApiConfig {
  baseUrl: string
  lang: string
  region: string
  token: string
}

export const getProductApiConfig = (): ProductApiConfig => {
  const baseUrl = getEnvVariable("DIRECTUS_PIM_API_URL")
  const lang = RegionConfig.getKeyForCurrentRegion("locale")
  const region = RegionConfig.getKeyForCurrentRegion("productRegion")
  const token = getEnvVariable("DIRECTUS_PIM_API_PUBLIC_TOKEN")

  return {
    baseUrl,
    lang,
    region,
    token,
  }
}
