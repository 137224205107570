import { ORIGIN_URL, SPACES_MAP } from "./common"
import { EditContext, ViewMode } from "../../types"

const path = "render-assets/bio-poly-mailers/Y60"

const PredefinedText = {
  virtualDielinesPaths: {
    [EditContext.FRONT]: ORIGIN_URL + path + "/Y60_virtual_dieline.svg",
  },
  texturesConfig: {
    global: [],
    models: {
      "predefined-text": {
        variants: {
          "predefined-text": {
            materials: [
              {
                id: "predefined-text",
                texturesPaths: {
                  virtualDielineTexture: {
                    path:
                      ORIGIN_URL +
                      "render-assets/poly-mailers/texture_white_2048px.png",
                    type: "plain_texture",
                  },
                },
              },
            ],
            colorModes: [],
            finishes: [],
          },
        },
      },
    },
  },
  availableViewModes: [ViewMode.TWO_DIMENSIONAL],
  availableEditContexts: [EditContext.FRONT],
  availableEditContextsMap: SPACES_MAP,
}

export { PredefinedText }
