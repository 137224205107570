import { computed, makeObservable, observable } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { PackhelpEditableText } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { TextObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object.controller"
import { isAssetText } from "../../types/asset.types"
import { isTextObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import { SidebarStore } from "../../stores/sidebar.store"
import { Fonts } from "../../libs/services/fonts-loader-service/fonts-loader.config"
import { AvailableColourModes } from "../../libs/products-render-config/types"
import Colour from "../../models/colour"
import { FontSizeCalculator } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/calculators/font-size.calculator"
import {
  TextAlign,
  FontSettings,
  FontSizeDefinition,
  FontStyle,
  FontWeight,
} from "../../libs/services/fonts-loader-service/fonts-loader.service"

export class TextToolbarController {
  @observable public selectedFontFamily?: Fonts
  @observable public selectedColor?: Colour
  @observable public selectedFontSize?: FontSizeDefinition
  @observable public selectedFontStyle?: FontStyle
  @observable public selectedFontWeight?: FontWeight
  @observable public selectedTextAlign?: TextAlign
  @observable public isFontStyleAvailable = false
  @observable public isFontWeightAvailable = false

  private readonly productDriver: ProductDriver
  private readonly sidebarStore: SidebarStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    stores: {
      sidebarStore: SidebarStore
    }
  ) {
    this.productDriver = services.productDriver

    this.sidebarStore = stores.sidebarStore

    makeObservable(this)

    services.ee.on(
      eventTree.activeObject.selected,
      this.refreshState.bind(this)
    )

    services.ee.on(
      eventTree.activeObject.fontChanged,
      this.refreshState.bind(this)
    )
  }

  public toggleFontsTab(): void {
    this.sidebarStore.toggleTab("fonts")
  }

  public toggleTextOptionsTab(): void {
    this.sidebarStore.toggleTab("textOptions")
  }

  @computed
  public get isFontsTabOpen(): boolean {
    return this.sidebarStore.selectedTab === "fonts"
  }

  @computed
  public get isToolbarAvailable(): boolean {
    return !!this.activeObject
  }

  @computed
  public get isColorPickerAvailable(): boolean {
    const { productRenderPilot } = this.productDriver.state

    return productRenderPilot.getColorMode() !== AvailableColourModes.MONOCHROME
  }

  public setColor(color: Colour): void {
    this.activeObjectController?.setStyles({ fill: color })
    this.refreshState()
  }

  @computed
  public get availableFontSizes(): FontSizeDefinition[] {
    return this.fontSizeCalculator.generateFontSizeList()
  }

  public setFontSize(definition: FontSizeDefinition): void {
    this.activeObjectController?.setStyles({ fontSize: definition.id })
    this.refreshState()
  }

  public toggleFontStyle(): void {
    this.activeObjectController?.setStyles({
      fontStyle:
        this.selectedFontStyle === FontStyle.normal
          ? FontStyle.italic
          : FontStyle.normal,
    })
    this.refreshState()
  }

  public setTextAlign(textAlign: TextAlign): void {
    this.activeObjectController?.setStyles({
      textAlign: textAlign,
    })
    this.refreshState()
  }

  public toggleFontWeight(): void {
    this.activeObjectController?.setStyles({
      fontWeight:
        this.selectedFontWeight === FontWeight.normal
          ? FontWeight.bold
          : FontWeight.normal,
    })
    this.refreshState()
  }

  private refreshState(): void {
    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.selectedFontFamily = this.activeObject.fontFamily
    this.selectedColor = this.activeObjectController.getFill()
    this.selectedFontSize = this.activeObjectController.getFontSizeDefinition()
    this.selectedFontStyle = this.activeObject.fontStyle
    this.selectedFontWeight = this.activeObject.fontWeight
    this.selectedTextAlign = this.activeObject.textAlign
    this.isFontStyleAvailable =
      this.activeObjectController.isFontPropertyAvailable(
        FontSettings.fontStyle
      )
    this.isFontWeightAvailable =
      this.activeObjectController.isFontPropertyAvailable(
        FontSettings.fontWeight
      )
  }

  @computed
  private get fontSizeCalculator(): FontSizeCalculator {
    const { productRenderPilot, activeContext } = this.productDriver.state

    const dielineNavigator = this.productDriver.getDielineNavigator()
    const spaceId = productRenderPilot.getDefaultSpace(activeContext)
    const space = dielineNavigator.getVirtualDielineSpace(spaceId)

    const fontSizeCalculator = new FontSizeCalculator(
      productRenderPilot,
      activeContext,
      space
    )

    return fontSizeCalculator
  }

  @computed
  private get activeObject(): PackhelpEditableText | null {
    const { activeObject } = this.productDriver.activeObjectDriver

    if (activeObject && isAssetText(activeObject)) {
      return activeObject
    }

    return null
  }

  @computed
  private get activeObjectController(): TextObjectController | null {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (
      activeObjectController &&
      isTextObjectController(activeObjectController)
    ) {
      return activeObjectController
    }

    return null
  }
}
