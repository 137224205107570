import { computed, action, observable, makeObservable } from "mobx"
import { ProductManager } from "@ph/product-api"
import { ProductPricingStore } from "./product-pricing.store"
import { ProductStore } from "./product.store"
import { ProductDesignStore } from "./product-design.store"
import { CurrencyStore } from "./currency.store"
import { DbyModeStore } from "./dby-mode.store"
import { AppConfig } from "../app-config/app.config"
import { QuoteRequestFormUrlProvider } from "../services/quote-request-form-url.provider"
import ProductDriver from "../drivers/product.driver"

export class EcommerceStore {
  @observable public isDynamicVariantLoading = false
  @observable public dynamicVariantError?: string
  @observable public isSkippingDesign = false

  @observable private dynamicVariantSku?: string

  private readonly appConfig: AppConfig
  private readonly productDriver: ProductDriver
  private readonly productStore: ProductStore
  private readonly productPricingStore: ProductPricingStore
  private readonly productDesignStore: ProductDesignStore
  private readonly currencyStore: CurrencyStore
  private readonly dbyModeStore: DbyModeStore

  constructor(
    services: {
      appConfig: AppConfig
      productDriver: ProductDriver
    },
    stores: {
      productStore: ProductStore
      productPricingStore: ProductPricingStore
      productDesignStore: ProductDesignStore
      currencyStore: CurrencyStore
      dbyModeStore: DbyModeStore
    },
    public readonly isAfterPurchaseEdit: boolean
  ) {
    makeObservable(this)

    this.appConfig = services.appConfig
    this.productDriver = services.productDriver
    this.productStore = stores.productStore
    this.productPricingStore = stores.productPricingStore
    this.productDesignStore = stores.productDesignStore
    this.currencyStore = stores.currencyStore
    this.dbyModeStore = stores.dbyModeStore
  }

  public setSelectedQuantity(quantity: number): void {
    this.productDesignStore.setProductQuantity(quantity)
  }

  @computed
  public get selectedQuantity(): number {
    return this.productDesignStore.state.meta.quantity
  }

  @computed
  public get visibleQuantity(): number {
    return (
      this.selectedQuantity * this.product.variantManager.getPiecesPerUnit()
    )
  }

  @computed
  public get ecommerceSku(): string {
    return this.dynamicVariantSku || this.productStore.productSku
  }

  @action
  public setDynamicVariantSku(sku?: string): void {
    this.dynamicVariantSku = sku
  }

  @action
  public setIsDynamicVariantLoading(isLoading: boolean): void {
    this.isDynamicVariantLoading = isLoading
  }

  @action
  public setDynamicVariantError(error?: string): void {
    this.dynamicVariantError = error
  }

  @action
  public toggleIsSkippingDesign(): void {
    this.isSkippingDesign = !this.isSkippingDesign
  }

  @computed
  public get isLoadingPricing(): boolean {
    return (
      this.productPricingStore.isLoadingPricing || this.isDynamicVariantLoading
    )
  }

  @computed
  public get isEcommerceActionLocked(): boolean {
    const { isLoadingPricing } = this
    const { isProductDesignSaving } = this.productDesignStore.state
    const { isRendererLoading } = this.productDriver.state

    return isRendererLoading || isProductDesignSaving || isLoadingPricing
  }

  @computed
  public get isAddingToCardLocked(): boolean {
    if (this.isEcommerceActionLocked) {
      return true
    }

    if (this.isSkippingDesign) {
      return false
    }

    const {
      isDesignInCart,
      meta: { dataReadOnly: isDataReadOnly },
    } = this.productDesignStore.state
    const {
      assetsDriver,
      state: { productRenderPilot },
    } = this.productDriver
    const isReadOnly = isDesignInCart || isDataReadOnly
    const { isAnyAssetOverscaled } = assetsDriver
    const isDbyMode = productRenderPilot.isDbyMode()
    const isDbyModeFileNotUploaded = !this.dbyModeStore.uploadedFile
    const isDbyModeFileUploadFailed = !!this.dbyModeStore.uploadError

    return (
      (!isReadOnly && isAnyAssetOverscaled) ||
      (isDbyMode && (isDbyModeFileNotUploaded || isDbyModeFileUploadFailed))
    )
  }

  @computed
  public get isQrLocked(): boolean {
    return this.isEcommerceActionLocked
  }

  @computed
  public get isCartFlowAvailable(): boolean {
    if (this.productPricingStore.isLoadingPricing) {
      return true
    }

    if (this.isApiCustomersMode) {
      return true
    }

    const { value, type } = this.productPricingStore.totalPrice

    return !!value && type === "price"
  }

  @computed
  public get isQrFlowAvailable(): boolean {
    if (!this.appConfig.api.ecommerce.features.ecommerce.quoteRequests) {
      return false
    }

    if (!this.isCartFlowAvailable) {
      return true
    }

    const dynamicPricingConfig = this.product.getDynamicPricingConfig()

    const inRange = (
      value: number,
      range: { min: number; max: number }
    ): boolean => {
      return value >= range.min && value <= range.max
    }

    if (
      dynamicPricingConfig &&
      inRange(this.selectedQuantity, dynamicPricingConfig)
    ) {
      return true
    }

    const qrConfig = this.product.getQrConfig()

    if (!qrConfig) {
      return false
    }

    return inRange(this.selectedQuantity, qrConfig)
  }

  @computed
  public get isDesignLaterAvailable(): boolean {
    return (
      this.isCartFlowAvailable &&
      !this.productDesignStore.state.isDraftDesignInCart &&
      !this.isAfterPurchaseEdit &&
      this.appConfig.api.ecommerce.features.ecommerce.designLater
    )
  }

  public get quoteRequestFormUrl(): string | undefined {
    const designId = this.productDesignStore.state.meta.id
    const { totalPrice } = this.productPricingStore
    const estimation =
      totalPrice.type === "qr"
        ? undefined
        : {
            id: totalPrice.id,
            value: totalPrice.value,
            currency: this.currencyStore.currency,
            shippingIncluded: !totalPrice.shippingPriceExcluded,
            taxRate: totalPrice.taxRate,
          }

    return new QuoteRequestFormUrlProvider(this.appConfig, this.product).call({
      quantity: this.selectedQuantity,
      designId,
      estimation,
    })
  }

  public get productPageUrl(): string | undefined {
    return this.appConfig.locale.getUrl(
      "products",
      this.product.variantManager.getVariantDashed()
    )
  }

  private get product(): ProductManager {
    return this.productStore.product
  }

  @computed
  public get isApiCustomersMode(): boolean {
    return this.appConfig.api.ecommerce.mode === "api-customers"
  }
}
