export const urls = {
  API_TOKEN_HEADER: "X-Spree-Token",
  API_USER_CURRENT: "me",
  API_RFM: "rfm",
  API_USER: "user",
  API_USERS: "users",
  API_USERS_V2: "v2/users",
  API_USERS_FIRST_TOUCH: "register_first_touch",
  API_USERS_PARTNERSTACK_KEY: "register_partnerstack_key",
  API_USERS_SIGNIN: "sign_in",
  API_USERS_REGISTER: "sign_up",
  API_USERS_CREATE_ANONYMOUS: "anonymous",
  API_USER_WALLET: "v1/wallet",
  API_ORDERS: "orders",
  API_ORDERS_v2: "v2/orders",
  API_ORDER_ITEMS: "line_items",
  API_ORDER_ITEMS_V2: "v2/line_items",
  API_ORDER_EMPTY_CART: "empty",
  API_ORDERS_CURRENT_USER: "mine",
  API_ORDERS_MANAGER: "managers/current",
  API_EXPRES_DELIVERY: "prices/express_delivery",
  API_PROMO_ADD: "apply_coupon_code",
  API_PROMO: "coupon_code",
  API_PRICES: "prices/list",
  API_CHECKOUTS: "checkouts",
  API_CHECKOUTS_NEXT_STAGE: "next",
  API_BOX_DESIGNS_CURRENT_USER: "mine",
  API_PRODUCTS: "products",
  API_BOX_DESIGNS: "box_designs",
  API_BOX_DESIGNS_DUPLICATE: "duplicate",
  API_BOX_DESIGNS_SHARE: "share",
  API_BOX_DESIGNS_CONVERT: "convert_from_v1",
  API_BOX_DESIGNS_REMIND_ON_DESKTOP: "remind_on_desktop",
  API_OFFERS: "offers",
  API_RESET_PASSWORD: "user_passwords/recover",
  API_SET_NEW_PASSWORD: "user_passwords/reset",
  API_SET_FIRST_PASSWORD: "user_confirmations/confirm",
  API_PAYMENT_GATEWAY: "gateway",
  API_TRANSFER_PAYMENTS: "check_payments",
  API_VIES_VALIDATION: "addresses/validate_vat",
  API_REQUEST_A_QUOTE: "quote_requests",
  API_REQUEST_A_QUOTE_V2: "v2/quote_requests",
  API_INSTANT_QUOTE: "v2/instant_quotes",
  API_REQUEST_PACKHELP_PRO: "packhelp_pro_requests",
  API_SEGMENT_DATA: "segment_data",
  API_SEGMENT_PROXIES: "v1/segment_proxies",
  API_VENDORS: "vendors",
  API_SUPPLIERS: "suppliers",
  API_VARIANTS_SERVICES: "services",
  API_VARIANTS_ASSETS: "assets",
  API_VARIANTS_OTHER: "other",
  API_VARIANTS_IS_CUSTOM: "is_custom",
  API_OFFER_LINE_ITEMS: "offer_line_items",
  API_OFFER_LINE_ITEMS_V2: "v2/offer_line_items",
  API_DIELINES_V2: "v2/dielines",
  API_PRODUCT_STRUCTURE: "/product_structure",
  API_SAMPLE_PACK_ORDER: "/custom_sample_pack",
  API_CLIENT_APPROVAL: "/client_approval_filesets",
  API_IP: "ip",
  API_QA_COMMENTS: "qa_comments",
  API_QA_STATE_CHANGES: "qa_state_changes",
  API_INVOICES: "invoices",
  API_EMAILS: "emails",
  API_EMAILS_WITH_CONSENTS: "v1/emails/save_with_consents",
  API_EMAILS_GLOBAL_CONSENTS_NEEDED: "v1/emails/global_consents_needed",
  API_V1: "v1",
  API_PRODUCTION_LIST: "production_list",
  API_PRODUCTION_FILES: "production_files",
  API_DOWNLOAD: "download",
  API_SHIPMENTS: "shipments",
  API_REFERRALS: "v1/referrals",
  API_ACQUISITION: "v1/acquisition_attributions",
  API_SUSTAINABILITY: "v1/sustainability",
  API_EXPERT_CHECK: "v2/expert_checks",
  API_ORDERS_CONSENTS: "v2/orders/consents",
  API_HS_CODES: "hs_codes",
  API_TOGGLE_QUANTITY_OPTION_VISIBILITY:
    "v2/quantity_options/:id/toggle_visibility",
  API_SELECT_QUANTITY_OPTION: "v2/quantity_options/:id/select_for_order",
  API_REMOVE_FROM_ORDER: "v2/line_items/:id/remove_from_order",
  API_LINE_ITEMS_BULK_CREATE: "v2/orders/:id/line_items/bulk_create",
}
