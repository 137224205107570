import React from "react"
import { CalculateLabel } from "./labels/CalculateLabel"
import { BasicLabel } from "./labels/BasicLabel"
import { QuantityItem, QuantityItemType } from "../../types"

const labelComponentsMap = {
  [QuantityItemType.Basic]: BasicLabel,
  [QuantityItemType.Calculate]: CalculateLabel
}

const noop = () => undefined

interface PickerOptionLabelProps {
  item: QuantityItem
  shouldHideLabel?: boolean
  onItemAction?: (item: QuantityItem) => void
  fixedPromo?: number
}

export const PickerOptionLabel = (props: PickerOptionLabelProps) => {
  const {
    item,
    onItemAction = noop,
    shouldHideLabel = false,
    fixedPromo
  } = props

  const LabelComponent = item.type && labelComponentsMap[item.type]

  if (!LabelComponent || shouldHideLabel) {
    return null
  }

  return (
    <LabelComponent
      item={item}
      onItemAction={onItemAction}
      fixedPromo={fixedPromo}
    />
  )
}
