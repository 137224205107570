import { EffectControllable } from "./effect-controllable.interface"
import { EffectOptions, EffectUiConfig } from "../types"

export class NoneEffectController implements EffectControllable {
  public getOptions(): EffectOptions {
    return {}
  }

  public getUiConfig(): EffectUiConfig {
    return {}
  }

  public init() {}

  public apply(options: EffectOptions) {}

  public clear() {}
}
