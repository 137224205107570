import { ObjectController } from "./object.controller"
import { TextObjectController } from "./text-object.controller"
import { ImageObjectController } from "./image-object.controller"
import { CanvasObjectControllable } from "./canvas-object-controllable.interface"
import { ActiveSelectionController } from "./active-selection.controller"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import {
  isActiveSelection,
  isAssetImage,
  isAssetText,
  isAssetLogoPlaceholderSlot,
  isAssetShape,
  isAssetSvgImage,
} from "../../../../../../types/asset.types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { LogoPlaceholderSlotObjectController } from "./logo-placeholder-slot-object.controller"
import { ShapeObjectController } from "./shape-object.controller"
import { SvgImageObjectController } from "./svg-image-object.controller"

export class CanvasObjectControllerFactory {
  constructor(protected readonly virtualDielineEditor: VirtualDielineEditor) {}

  public getController(
    canvasObject: PackhelpEditableObject
  ): CanvasObjectControllable {
    if (isActiveSelection(canvasObject)) {
      return new ActiveSelectionController(
        canvasObject,
        this.virtualDielineEditor
      )
    }

    if (isAssetText(canvasObject)) {
      return new TextObjectController(canvasObject, this.virtualDielineEditor)
    }

    if (isAssetImage(canvasObject)) {
      return new ImageObjectController(canvasObject, this.virtualDielineEditor)
    }

    if (isAssetSvgImage(canvasObject)) {
      return new SvgImageObjectController(
        canvasObject,
        this.virtualDielineEditor
      )
    }

    if (isAssetShape(canvasObject)) {
      return new ShapeObjectController(canvasObject, this.virtualDielineEditor)
    }

    if (isAssetLogoPlaceholderSlot(canvasObject)) {
      return new LogoPlaceholderSlotObjectController(
        canvasObject,
        this.virtualDielineEditor
      )
    }

    return new ObjectController(canvasObject, this.virtualDielineEditor)
  }
}
