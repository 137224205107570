import React from "react"
import {
  EditContext,
  ViewType,
} from "../../../../../../libs/products-render-config/types"
import styles from "./TwoDimensionalSceneMountpoint.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

interface TwoDimensionalSceneMountpointProps {
  editContext: EditContext
  viewType: ViewType
  getRef: (el) => void
  active?: boolean
}

export function TwoDimensionalSceneMountpoint({
  editContext,
  viewType,
  getRef,
  active = false,
}: TwoDimensionalSceneMountpointProps) {
  return (
    <div
      className={cx("wrapper", viewType, { active })}
      id={`two-dimensional-scene-${editContext}`}
      ref={getRef}
      e2e-target="canvas-container"
      e2e-target-name={editContext}
    />
  )
}
