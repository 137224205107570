import { SidebarTab } from "../../libs/products-render-config/types"

export enum TabEvents {
  tabChanged = "ph.tab.changed",
}

export type TabEventsTypes = {
  [TabEvents.tabChanged]: (
    newTab: SidebarTab | undefined,
    oldTab: SidebarTab | undefined
  ) => void
}
