import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { CanvasObjectControllerFactory } from "./canvas-object-controller.factory"
import { isActiveSelection } from "../../../../../../types/asset.types"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { ColorHelper } from "../helpers/color.helper"

export class PasteFromClipboardController {
  private readonly canvasObjectControllerFactory: CanvasObjectControllerFactory

  constructor(private readonly vdEditor: VirtualDielineEditor) {
    this.canvasObjectControllerFactory = new CanvasObjectControllerFactory(
      this.vdEditor
    )
  }

  public async paste(
    clipboardObject: PackhelpEditableObject,
    newSpaceId: SpaceId
  ): Promise<PackhelpEditableObject> {
    const duplicatedObject = await this.duplicateObject(
      clipboardObject,
      newSpaceId
    )

    this.alignCenter(duplicatedObject)

    if (isActiveSelection(duplicatedObject)) {
      await Promise.all(
        duplicatedObject.getObjects().map(async (object) => {
          await this.refresh(object)
        })
      )
    } else {
      await this.refresh(duplicatedObject)
    }

    return duplicatedObject
  }

  private alignCenter(object: PackhelpEditableObject) {
    const objectController =
      this.canvasObjectControllerFactory.getController(object)

    objectController.alignVertical()
    objectController.alignHorizontal()
  }

  private async refresh(object: PackhelpEditableObject) {
    this.vdEditor.assetsModule.setObjectVisibility(object, true)
    ColorHelper.applyDefaultColor(this.vdEditor, object)

    if (object.maskController) {
      object.maskController.updateRotationAndPosition()
    }
  }

  private async duplicateObject(
    object: PackhelpEditableObject,
    newSpaceId: SpaceId
  ): Promise<PackhelpEditableObject> {
    const objectController =
      this.canvasObjectControllerFactory.getController(object)

    return await objectController.duplicate({
      withRender: true,
      newSpaceId: newSpaceId,
    })
  }
}
