import { PackhelpApi } from "../packhelp-api"
import { Pricing } from "./product-pricing.types"

export interface GetAllOptions {
  region?: string
  product?: string
  productName?: string
  productSize?: string
  taxCountryIso?: string
}

export class ProductPricingApi {
  constructor(private api: PackhelpApi) {}

  public async getByProductVariant(
    productVariant: string,
    taxCountryIso?: string
  ) {
    return this.getAll({
      product: productVariant,
      taxCountryIso: taxCountryIso,
    })
  }

  public async getAll(options: GetAllOptions = {}): Promise<Pricing> {
    const data = await this.api.ax.get("prices/list", {
      params: {
        region: options.region,
        product: options.product,
        product_size: options.productSize,
        boxModel: options.productName,
        tax_country_iso: options.taxCountryIso,
        token: this.api.token,
        cache_buster: "v3",
      },
    })

    return data.data
  }
}
