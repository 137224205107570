import React from "react"
import { useMatchQuery } from "dsl/src/common/hooks"
import { observer } from "mobx-react-lite"
import { shippingDestinationModalMountPoint } from "../../../../_mount-points"
import { ShippingDestinationModal as BaseShippingDestinationModal } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/components/ShippingDestinationModal"
import { ShippingDestinationForm } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/components/ShippingDestinationForm"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const ModalShippingDestination = observer(() => {
  const isMobile = useMatchQuery()

  const [containerSet] = useContainerSet((c) => [c.ecommerce])

  if (!containerSet) {
    return null
  }

  const { ecommerce } = containerSet

  if (!shippingDestinationModalMountPoint || !ecommerce.available) {
    return null
  }

  const { shippingDestinationStore, ecommerceController } = ecommerce
  const { isModalOpen, shippingCountry, shippingZipCode, isSaving, error } =
    shippingDestinationStore

  return (
    <BaseShippingDestinationModal
      isMobile={isMobile}
      isModalOpen={isModalOpen}
      onClose={() => ecommerceController.closeShippingDestinationModal()}
    >
      <ShippingDestinationForm
        shippingCountry={shippingCountry}
        shippingZipCode={shippingZipCode}
        onSave={ecommerceController.saveShippingDestination.bind(
          ecommerceController
        )}
        isSaving={isSaving}
        error={error}
      />
    </BaseShippingDestinationModal>
  )
})
