import THREE from "../../../../libs/vendors/THREE"
import {
  ModelContext,
  TextureDefinition,
} from "../../../../libs/products-render-config/types"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { ModelConfig, RendererConfig } from "../types"

export abstract class ModelStrategy {
  constructor(
    protected readonly vdCanvases: Record<string, HTMLCanvasElement>,
    protected readonly mode: RendererConfig["mode"]
  ) {}

  public abstract init(config: ModelConfig): Promise<THREE.Group>
  public abstract fitModelToScreen(orbitControls: OrbitControls): void
  public abstract setModelTextures(
    textureDefinitions: TextureDefinition[]
  ): Promise<void>
  public abstract touchTextures(): void
  public abstract dispose(): void
  public abstract getModel(): THREE.Group | undefined
  public abstract isFoldingSupported(): boolean
  public abstract getTargetFoldingPercentage(modelContext: ModelContext): number
  public abstract getFoldingPercentage(): number
  public abstract setFoldingPercentage(percentage: number): void
  public abstract getFoldingStep(): number
  public abstract isFogRequired(): boolean
}
