/** @format */
// sku part
import SkuFinish from "./ncpSkuDefinitions/SkuFinish";
import SkuMaterial from "./ncpSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./ncpSkuDefinitions/SkuPrintMode";
import SkuModel from "./ncpSkuDefinitions/SkuModel";
// product sku parts
import Finish from "./ncpPartsDefinitions/Finish";
import Material from "./ncpPartsDefinitions/Material";
import PrintMode from "./ncpPartsDefinitions/PrintMode";
import Model from "./ncpPartsDefinitions/Model";
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var NCP_CONSTS = {
    SKU_BASE: "ncp-",
    PRODUCT_TYPE: "ncp",
    DEFAULT_LINE_ITEM_RANGES: [1, 100],
};
export default Object.assign({}, NCP_CONSTS, SkuFinish, SkuMaterial, SkuPrintMode, SkuModel, Finish, Material, PrintMode, Model, Product, ProductVariant);
