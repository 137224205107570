import React from "react"
import plusIconUrl from "../../../assets/_icons/plus.svg"
import { Tile } from "../Tile/Tile"
import { useDropzone } from "react-dropzone"
import styles from "./TileDropzone.module.scss"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

export interface TileDropzoneProps extends AnalyticsProps, TestsProps {
  label: string
  onDrop: (files: File[]) => void
  loading?: boolean
}

export function TileDropzone({
  label,
  onDrop,
  loading = false,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: TileDropzoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  })

  return (
    <Tile
      {...getRootProps()}
      analyticsSection={analyticsSection}
      analyticsType={analyticsType}
      analyticsName={analyticsName}
      analyticsId={analyticsId}
      e2eTarget={e2eTarget}
      e2eTargetName={e2eTargetName}
    >
      <input {...getInputProps()} e2e-target="upload-dropzone-input" />

      {loading ? (
        <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
      ) : (
        <div className={styles.content}>
          <SvgIcon url={plusIconUrl} />
          {label}
        </div>
      )}
    </Tile>
  )
}
