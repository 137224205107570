import React, { createRef, useEffect } from "react"
import checkIconUrl from "../../../assets/_icons/check.svg"
import cxBinder from "classnames/bind"
import styles from "./FontFamilyPreview.module.scss"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"
import { AnalyticsSection } from "../../types"
const cx = cxBinder.bind(styles)

export interface FontFamilyPreviewProps {
  label: string
  onClick: () => void
  active: boolean
  highlighted: boolean
  innerRef?: React.RefObject<HTMLDivElement>
  onMouseEnter?: () => void
  onHighlight?: (domElement: HTMLElement) => void
  analyticsSection?: AnalyticsSection
}

export function FontFamilyPreview({
  label,
  onClick,
  active,
  highlighted,
  innerRef,
  onMouseEnter,
  onHighlight,
  analyticsSection,
}: FontFamilyPreviewProps) {
  const ref = innerRef || createRef()

  useEffect(() => {
    if (highlighted && !!ref.current && !!onHighlight) {
      onHighlight(ref.current)
    }
  }, [highlighted])

  return (
    <div
      ref={ref}
      className={cx("wrapper", { highlighted })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      analytics-section={analyticsSection}
      analytics-type="option-picker"
      analytics-name="font-family"
      analytics-id={label}
    >
      {label}
      {active && <SvgIcon url={checkIconUrl} />}
    </div>
  )
}
