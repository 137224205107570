import React from "react"
import styles from "./NavItem.module.scss"
import { NavItem, NavItemProps } from "./NavItem"
import { SvgIcon } from "../SvgIcon/SvgIcon"

interface NavButtonProps extends NavItemProps {
  iconUrl?: string
  iconActiveUrl?: string
  disabled?: boolean
  label: string
  onClick: () => void
}

export function NavButton({
  iconUrl,
  iconActiveUrl,
  badge,
  disabled = false,
  label,
  onClick,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: NavButtonProps) {
  return (
    <NavItem badge={badge}>
      <button
        disabled={disabled}
        className={styles.button}
        onClick={onClick}
        analytics-section={analyticsSection}
        analytics-type={analyticsType}
        analytics-name={analyticsName}
        analytics-id={analyticsId}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        {iconUrl && (
          <div className={styles.icon}>
            <SvgIcon url={iconUrl} />
            {iconActiveUrl && <SvgIcon url={iconActiveUrl} />}
          </div>
        )}
        {label}
      </button>
    </NavItem>
  )
}
