import FiltersModule from "../filters.module"
import {
  AvailableColourModes,
  MonochromeColours,
} from "../../../../../libs/products-render-config/types"
import {
  DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE,
  PackhelpEditableImage,
  PackhelpImage,
} from "../../object-extensions/packhelp-objects"
import { Colour } from "../../../../../models/colour"
import { AssetColorController } from "../assets-module/asset-color.controller"
import VirtualDielineEditor from "../../virtual-dieline-editor"

export class ImageFilterApplier {
  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly image: PackhelpEditableImage
  ) {}

  public apply(): PackhelpImage {
    if (this.isMonoColor()) {
      return this.applyForMonoColor()
    }

    if (this.image.assetImageMeta.original.metaInfo.isPdf) {
      return this.applyForPdf()
    }

    return this.applyDefault()
  }

  public remove(): PackhelpImage {
    this.image.filters = []
    this.image.applyFilters()

    return this.image
  }

  private applyForMonoColor(): PackhelpImage {
    this.image.filters = []
    FiltersModule.setInvertFilter(this.image)
    FiltersModule.setRemoveColorFilter(
      this.image,
      DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE,
      new Colour(MonochromeColours.BLACK)
    )

    const colorController = new AssetColorController(this.vdEditor)
    const color = colorController.getColorForcedByColorMode()

    if (color) {
      FiltersModule.setTintFilter(this.image, color)
      this.image.set({
        fillPantoneName: color.getPantoneName(),
      })
    }

    return this.image
  }

  private applyForPdf(): PackhelpImage {
    this.image.filters = []
    FiltersModule.setRemoveColorFilter(
      this.image,
      DEFAULT_REMOVE_WHITE_COLOR_FILTER_DISTANCE,
      new Colour(MonochromeColours.WHITE)
    )

    const texture = this.vdEditor.getTexture()

    if (texture) {
      FiltersModule.setBlendImageFilter(this.image, texture)
    }

    return this.image
  }

  private applyDefault(): PackhelpImage {
    return this.image
  }

  private isMonoColor(): boolean {
    const colorMode = this.vdEditor.productRenderPilot.getColorMode()

    const isMonochrome = colorMode === AvailableColourModes.MONOCHROME
    const isMonoPantone = colorMode === AvailableColourModes.MONO_PANTONE

    return isMonochrome || isMonoPantone
  }
}
