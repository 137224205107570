import React, { PropsWithChildren } from "react"

export interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children) => React.ReactNode
}

export function ConditionalWrapper({
  children,
  condition,
  wrapper,
}: PropsWithChildren<ConditionalWrapperProps>) {
  if (!condition) {
    return <>{children}</>
  }

  return <>{wrapper(children)}</>
}
