import VirtualDielineEditor from "../../../../../virtual-dieline-editor"
import { MaskLoader } from "../../../mask-controller/mask.loader"
import { PackhelpObject } from "../../../../../object-extensions/packhelp-objects"
import {
  isAssetMask,
  isGroup,
  VirtualDielineTemplateDataLessObject,
} from "../../../../../../../../types/asset.types"
import { backgroundLayerDefinitions } from "../../../mask-controller/types"
import { ClippingMaskResizer } from "../resizers/clipping-mask.resizer"

export class TemplateMaskLoader {
  constructor(
    private readonly vdEditor: VirtualDielineEditor,
    private readonly vdTemplateData: VirtualDielineTemplateDataLessObject
  ) {}

  public async load(): Promise<void> {
    await this.prepareBackgroundLayers()
    await this.loadControllers()
  }

  private async loadControllers(): Promise<void> {
    const maskLoader = new MaskLoader(this.vdEditor)
    await maskLoader.loadMasks()
  }

  private async prepareBackgroundLayers(): Promise<void> {
    for (const backgroundLayerDefinition of backgroundLayerDefinitions) {
      const backgroundLayer = this.vdEditor.getCanvasObjectById(
        backgroundLayerDefinition.globalId
      )

      if (backgroundLayer) {
        await this.prepareClippingMasks(backgroundLayer)
      }
    }
  }

  private async prepareClippingMasks(
    backgroundLayer: PackhelpObject
  ): Promise<void> {
    const clippingMaskResizer = new ClippingMaskResizer(
      this.vdEditor,
      this.vdTemplateData
    )

    if (backgroundLayer.clipPath && isGroup(backgroundLayer.clipPath)) {
      for (const clipPathObject of backgroundLayer.clipPath.getObjects()) {
        if (!isAssetMask(clipPathObject)) {
          continue
        }

        await clippingMaskResizer.resize(clipPathObject)
      }
    }
  }
}
