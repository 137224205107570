import { AxiosInstance } from "axios"
import { DesignMetaPayload, UserDto } from "../../types"
import { parseResponse } from "../helpers/parse-response"

export class UserApi {
  constructor(private readonly api: AxiosInstance) {}

  public async me(): Promise<UserDto> {
    try {
      return parseResponse<UserDto>(await this.api.get("/users/me"))
    } catch (e) {
      return parseResponse<UserDto>(await this.api.post("/users/anonymous"))
    }
  }

  public async assignDesign(payload: DesignMetaPayload): Promise<void> {
    await this.api.post("/users/me/designs", payload, {
      headers: {
        "Design-Token": payload.write_token,
      },
    })
  }

  public async duplicateDesign(): Promise<void> {
    return Promise.resolve()
  }

  public async updateDesign(): Promise<void> {
    return Promise.resolve()
  }
}
