import { computed } from "mobx"
import { PackhelpEditableShape } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { isAssetShape } from "../../types/asset.types"
import { isShapeObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import { ImageToolbarController } from "./image-toolbar.controller"
import { ShapeObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/shape-object.controller"

export class ShapeToolbarController extends ImageToolbarController {
  @computed
  protected get activeObject(): PackhelpEditableShape | null {
    const { activeObject } = this.productDriver.activeObjectDriver

    if (activeObject && isAssetShape(activeObject)) {
      return activeObject
    }

    return null
  }

  @computed
  protected get activeObjectController(): ShapeObjectController | null {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (
      activeObjectController &&
      isShapeObjectController(activeObjectController)
    ) {
      return activeObjectController
    }

    return null
  }
}
