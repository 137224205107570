import React from "react"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import styles from "./ModelPopover.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const ModelPopover = observer(() => {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { popoverPosition, message } = containerSet.ui.modelPopoverUiController

  if (!popoverPosition || !message) {
    return null
  }

  return (
    <div className={styles.wrapper} style={toJS(popoverPosition)}>
      {message}
    </div>
  )
})
