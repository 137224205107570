import BoxProductConsts from "../../source/boxProductSpec/generalConfig/consts/boxConsts";
import PolyMailerProductConsts from "../../source/polyMailerProductSpec/generalConfig/consts/polyMailerConsts";
import PaperMailerProductConsts from "../../source/paperMailerProductSpec/generalConfig/consts/paperMailerConsts";
import PaperBagProductConsts from "../../source/bagProductSpec/generalConfig/consts/bagConsts";
import EnvelopeProductConsts from "../../source/envelopeProductSpec/generalConfig/consts/envelopeConsts";
import TapeProductConsts from "../../source/tapeProductSpec/generalConfig/consts/tapeConsts";
import TissuePaperProductConsts from "../../source/tissuePaperProductSpec/generalConfig/consts/tissuePaperConsts";
import SamplePackProductConsts from "../../source/samplePackProductSpec/generalConfig/consts/samplePackConsts";
import PaperTubeProductConsts from "../../source/paperTubeProductSpec/generalConfig/consts/paperTubeConsts";
import ExtensionProductConsts from "../../source/extensionProductSpec/generalConfig/extensionProductConsts";
import NcpProductConsts from "../../source/ncpProductSpec/generalConfig/consts/ncpConsts";
import LabelProductConsts from "../../source/labelProductSpec/generalConfig/consts/labelConsts";
import CardProductConsts from "../../source/cardProductSpec/generalConfig/consts/cardConsts";
import { DesignItemProductConsts } from "../../source/designItemProductSpec/generalConfig/designItemProductConsts";
import { ComingSoonBoxProductConsts } from "../../interfaces/js/ComingSoonProducts/BoxProducts/BoxProduct";
var ProductsConsts = {
    SharedConsts: {
        SKU_DELIMITER: "--",
        ZPKJ_SPREE_ORDER_CUSTOM: "custom",
        ZPKJ_SPREE_ORDER_SAMPLE_PACK: "sample_pack",
        ZPKJ_SPREE_ORDER_PLUS: "plus",
        ZPKJ_SPREE_ORDER_STUDIO: "studio",
    },
    ComingSoonBoxProductConsts: ComingSoonBoxProductConsts,
    BoxProductConsts: BoxProductConsts,
    PolyMailerProductConsts: PolyMailerProductConsts,
    PaperBagProductConsts: PaperBagProductConsts,
    EnvelopeProductConsts: EnvelopeProductConsts,
    TapeProductConsts: TapeProductConsts,
    TissuePaperProductConsts: TissuePaperProductConsts,
    SamplePackProductConsts: SamplePackProductConsts,
    PaperTubeProductConsts: PaperTubeProductConsts,
    ExtensionProductConsts: ExtensionProductConsts,
    DesignItemProductConsts: DesignItemProductConsts,
    PaperMailerProductConsts: PaperMailerProductConsts,
    NcpProductConsts: NcpProductConsts,
    LabelProductConsts: LabelProductConsts,
    CardProductConsts: CardProductConsts,
};
export default ProductsConsts;
