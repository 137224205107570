import React from "react"
import cxBinder from "classnames/bind"
import styles from "./TextField.module.scss"
const cx = cxBinder.bind(styles)

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
type CustomSpan = Omit<React.HTMLAttributes<HTMLSpanElement>, "prefix">
export interface AffixProps extends CustomSpan {
  suffix?: boolean
  prefix?: boolean
  preventStyling?: boolean
  disabled?: boolean | undefined
  onClick?: () => any
}

const Affix = (props: AffixProps) => {
  const { disabled, children, suffix, prefix, preventStyling, onClick } = props

  return (
    <span
      className={cx("affix", {
        suffix,
        prefix,
        disabled,
        plain: preventStyling,
        clickable: typeof onClick === "function"
      })}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export { Affix, Affix as default }
