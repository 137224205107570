import { AxiosInstance } from "axios"
import { QrCodeDto, QrCodePayload } from "../../types"
import { parseResponse } from "../helpers/parse-response"

export class QrCodeApi {
  constructor(private readonly api: AxiosInstance) {}

  public async create(payload: QrCodePayload): Promise<QrCodeDto> {
    const response = await this.api.post("/qr_codes", payload)

    return parseResponse<QrCodeDto>(response)
  }
}
