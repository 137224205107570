import { DielineMeasurements, DielinesApi } from "@ph/pqs-api"
import { ProductManager } from "@ph/product-api"

export class DielineGenerator {
  constructor(private readonly dielinesApi: DielinesApi) {}

  public async generate(
    product: ProductManager,
    format: "svg" | "pdf"
  ): Promise<string | undefined> {
    const pssRepresentation = product.variantManager.getPssRepresentation()

    if (!pssRepresentation) {
      return
    }

    const response = await this.dielinesApi.generate(
      pssRepresentation,
      this.getMeasurements(product),
      format
    )

    return response.data.url
  }

  private getMeasurements(product: ProductManager): DielineMeasurements {
    const dimensions = product.dimensionsManager.getDimensions().outside

    return {
      width: dimensions.width,
      height: dimensions.height,
      depth: dimensions.length ? dimensions.length : undefined,
    }
  }
}
