import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { TempLayers } from "../../../types/render-engine.types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { SpaceClippingHelper } from "../../assets-module/helpers/space-clipping.helper"
import { ClipPathModes } from "../../../services/clip-path.generator"

export class TempBackgroundImageCreator {
  constructor(
    private readonly space: PackhelpObject,
    private readonly backgroundObject: PackhelpObject,
    private readonly virtualDielineEditor: VirtualDielineEditor
  ) {}

  public async create(): Promise<PackhelpObject> {
    const tempBackgroundObject = await this.cloneObject(this.backgroundObject)

    tempBackgroundObject.set({
      id: TempLayers.TEMP_BACKGROUND_IMAGE,
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index: IndexConfigFragmentBottom.TEMP_BACKGROUND_IMAGE,
      },
      opacity: 1,
      visible: true,
      selectable: false,
      evented: false,
      clipPath: undefined,
    })

    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      this.space.originSpaceArea!,
      tempBackgroundObject,
      ClipPathModes.FillMode
    )

    return tempBackgroundObject
  }

  private async cloneObject(object: PackhelpObject): Promise<PackhelpObject> {
    return new Promise((resolve) => {
      object.clone(resolve, ["assetType", "assetImageMeta", "maskParentId"])
    })
  }
}
