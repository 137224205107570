import React, { SVGProps } from "react"

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 10"
    width={16}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.069.2 6.73 7.538l-4.799-4.8L.8 3.869 6.73 9.8l8.47-8.47L14.07.2Z"
      fill="#fff"
    />
  </svg>
)
