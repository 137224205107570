import React, {
  PropsWithChildren,
  useRef,
  useState,
  useEffect,
  createContext,
} from "react"

export const ModalContext = createContext<HTMLDivElement | null>(null)

export function ModalProvider({ children }: PropsWithChildren<{}>) {
  const modalRef = useRef(null)
  const [context, setContext] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setContext(modalRef.current)
  }, [])

  return (
    <>
      <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  )
}
