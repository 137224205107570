import { LocaleEnvConfig } from "../types"

const defaultUnit = "cm"

export class LocaleConfig {
  public readonly region: LocaleEnvConfig["region"]
  public readonly productRegion: LocaleEnvConfig["productRegion"]
  public readonly country: LocaleEnvConfig["country"]
  public readonly salesChannelId: LocaleEnvConfig["salesChannelId"]
  public readonly lang: LocaleEnvConfig["lang"]
  public readonly currency: LocaleEnvConfig["currency"]
  public readonly forcedTax: LocaleEnvConfig["forcedTax"]
  public readonly displayUnit: LocaleEnvConfig["displayUnit"]
  private readonly urls: LocaleEnvConfig["urls"]

  constructor(config: LocaleEnvConfig) {
    this.region = config.region
    this.productRegion = config.productRegion
    this.salesChannelId = config.salesChannelId
    this.country = config.country
    this.lang = config.lang
    this.currency = config.currency.toUpperCase()
    this.forcedTax = config.forcedTax
    this.displayUnit = config.displayUnit || defaultUnit
    this.urls = config.urls
  }

  public getUrl(type: string, subtype?: string): string | undefined {
    const url = this.urls[type]

    if (typeof url === "object") {
      return url[subtype]
    }

    return url
  }
}
