import React from "react"
import { Logo } from "../../atoms/Logo/Logo"
import styles from "./Header.module.scss"

export function SimpleHeader() {
  return (
    <header className={styles.header}>
      <Logo />
    </header>
  )
}
