import { I18N } from "../../ui/i18n"
import { EditorMode } from "../../libs/products-render-config/types"

export enum AssetError {
  emptyData = "Data is empty and cannot be processed",
  noPreviewImage = "No preview for an image",
  invalidData = "Unsupported file or file format",
  invalidDbyData = "Unsupported file or file format",
  uploadFailed = "Upload failed",
  assetTransformation = "Asset transformation failed",
  invalidS3Config = "S3 config is not valid",
}

export const getTranslationKeyForAssetError = (error: AssetError) => {
  const errorMap = new Map([
    [AssetError.invalidData, I18N.assetError.unsupportedFormat],
    [AssetError.invalidDbyData, I18N.assetError.unsupportedFormatDby],
    [AssetError.assetTransformation, I18N.assetError.tranformationFailed],
    [AssetError.emptyData, I18N.assetError.emptyData],
    [AssetError.noPreviewImage, I18N.assetError.noPreview],
    [AssetError.invalidS3Config, I18N.assetError.invalidConfig],
  ])

  return errorMap.get(error) || I18N.assetError.uploadFailed
}

export class RuntimeImageAssetError extends Error {
  constructor(message) {
    super(message)
    this.name = "RuntimeImageAssetError"
  }
}
