/** @format */
// product sku def
import SkuFinish from "./bagSkuDefinitions/SkuFinish";
import SkuMaterial from "./bagSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./bagSkuDefinitions/SkuPrintMode";
import SkuModel from "./bagSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./bagPartsDefinitions/PartFinish";
import PartMaterial from "./bagPartsDefinitions/PartMaterial";
import PartPrintMode from "./bagPartsDefinitions/PartPrintMode";
import PartModel from "./bagPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var BAGS_CONSTS = {
    SKU_BASE: "bag-",
    PRODUCT_TYPE: "bag",
    DEFAULT_LINE_ITEM_RANGES: [
        30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500,
    ],
};
export default Object.assign({}, BAGS_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
