import Colour from "../../models/colour"
import fabric from "../vendors/Fabric"

export const DESIGNER_MODE_FAKE_SKU = "designer-mode-fake-sku"

export enum AvailablePatternColourModes {
  MONOCHROME = "monochrome",
  COLOR = "color",
}

export enum Side {
  OUTSIDE = "outside",
  INSIDE = "inside",
}

// https://docs.google.com/spreadsheets/d/1o9-MhkANpa9-Db3UkGf4L7jo_u3F0brD5tYvtCy8SSk/edit#gid=1825959657
export enum EditContext {
  OUTSIDE = "outside",
  INSIDE = "inside",
  FRONT = "front",
  BACK = "back",
  SLEEVE = "sleeve",
}

export const VirtualDielineRotation: Record<EditContext, number> = {
  [EditContext.OUTSIDE]: 0,
  [EditContext.INSIDE]: 180,
  [EditContext.FRONT]: 0,
  [EditContext.BACK]: 0,
  [EditContext.SLEEVE]: 0,
}

export enum ModelContext {
  OPENED = "opened",
  CLOSED = "closed",
}

export enum SpaceId {
  BACK = "back",
  FRONT = "front",
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  LID = "lid",
  FRONT_INSIDE = "front_inside",
  DEFAULT = "default",
  TOP_FRONT = "top_front",
  TOP_BACK = "top_back",
  BOTTOM_FRONT = "bottom_front",
  BOTTOM_BACK = "bottom_back",

  TOP_LEFT_FLAP = "top_left_flap",
  TOP_RIGHT_FLAP = "top_right_flap",
  TOP_FRONT_FLAP = "top_front_flap",
  TOP_BACK_FLAP = "top_back_flap",
  FRONT_LEFT_FLAP = "front_left_flap",
  FRONT_RIGHT_FLAP = "front_right_flap",
  FRONT_INSIDE_LEFT_FLAP = "front_inside_left_flap",
  FRONT_INSIDE_RIGHT_FLAP = "front_inside_right_flap",
  BACK_LEFT_FLAP = "back_left_flap",
  BACK_RIGHT_FLAP = "back_right_flap",
  LEFT_FOLDLINE = "left_foldline",
  RIGHT_FOLDLINE = "right_foldline",
  BOTTOM_LEFT_FLAP = "bottom_left_flap",
  BOTTOM_RIGHT_FLAP = "bottom_right_flap",
  BOTTOM_FRONT_FLAP = "bottom_front_flap",
  BOTTOM_BACK_FLAP = "bottom_back_flap",
  GLUE_FLAP = "glue_flap",
  LEFT_INSIDE = "left_inside",
  RIGHT_INSIDE = "right_inside",
}

export enum ViewType {
  DBY = "dby",
  MODEL = "model",
  DIELINE = "dieline",
  SPACE = "space",
}

export enum ViewMode {
  TWO_DIMENSIONAL = "2D",
  THREE_DIMENSIONAL = "3D",
}

export enum MonochromeColours {
  BLACK = "#000",
  WHITE = "#fff",
  SINGLE = "single",
}

export enum AvailableColourModes {
  MONOCHROME = "monochrome",
  FULL_COLOR = "full_colour",
  ECO_COLOR = "eco_colour",
  MONO_PANTONE = "mono_pantone",
}

export type ColoursPreset = {
  mode: AvailableColourModes
  colourMonochrome?: MonochromeColours
  colorPantoneDefault?: Colour
  colourSettings: {
    blendFactor: number
  }
}

export enum PantoneColorsPreset {
  ALL = "all",
  STANDARD = "standard",
  METALLIC = "metallic",
  COVERING_BLACK_FOIL = "covering_black_foil",
  COVERING_GREY_FOIL = "covering_grey_foil",
  TISSUE_BACKGROUND = "tissue_background",
  PATTERN_REPLICATOR_PALETTE = "pattern_replicator_palette",
}

export enum HexColorsPreset {
  ALL = "all",
  TISSUE_BACKGROUND = "tissue_background",
}

export type ProductVariant = {
  sku: string
}

export type CameraAngle = SpaceId | null

export type EditContextIntl =
  | "editContext.outside"
  | "editContext.inside"
  | "editContext.front"
  | "editContext.back"
  | "editContext.sleeve"

export type EditorMode = "designer" | "editor" | "dby"

export type EditorPreviewMode = "dtp"

export type SpaceDimensions = {
  widthCm: number
  heightCm: number
  widthPx: number
  heightPx: number
}

export type NonEditableSpaceConfig = {
  spaceId: SpaceId
  context: string
  children: NonEditableSpaceConfig[]
  disabled?: boolean
}

export type EditableSpaceConfig = {
  spaceId: SpaceId
  children: NonEditableSpaceConfig[]
  context?: EditContext
  isDefault?: boolean
  editable?: boolean
  rotation?: number
}

export type CameraSettings = {
  cameraPosition: { x: number; y: number; z: number }
  lookAtPoint: { x: number; y: number; z: number }
}

export type FontSizeConfig = {
  dtp: {
    min: number
  }
  editor: {
    min: number
    max: number
  }
}

export interface ThumbnailConfig {
  /**
   * Product PNG image. Like a whole bag of polymailer
   */
  backgroundTextureEnabled: boolean

  /**
   * Edit zone texture. Like white paper or kraft
   */
  editZoneBackgroundTextureEnabled: boolean
}

export interface ZoomConfig {
  available: boolean
  activeByDefault: boolean
  notificationIntl?: string
}

export type ReplicablePatternConfig = {
  maxArtworkCoverage: number
  safeZoneSizeMm: number
  availableLayouts: {
    alternating: boolean
    grid: boolean
  }
  maxRowsCount: number
  maxColsCount: number
  minRowsCount: number
  minColsCount: number
}

export type FscConfig = {
  availableSpaces: {
    [editContext in EditContext]?: SpaceId[]
  }
}

export type GlobalDropzoneConfig = {
  mode: "images" | "replicablePattern"
}

export type SidebarTab =
  | "product"
  | "configuration"
  | "size"
  | "logo"
  | "logoPlaceholder"
  | "templatesAndPatterns"
  | "replicablePatterns"
  | "text"
  | "textOptions"
  | "fonts"
  | "background"
  | "elements"
  | "certificates"
  | "devTools"
  | "dby"
  | "upload"

export type UIConfig = {
  ecoBadgeDisabled?: boolean
  features: {
    mask: boolean
    textEffects: boolean
    templates: boolean
    patterns: boolean
    replicablePatterns: boolean
    backgroundImage: boolean
    backgroundColor: boolean
    plantingTrees: boolean
    fsc: boolean
  }
  tabs: Record<SidebarTab, boolean>
  fontSize: FontSizeConfig
  editZone: {
    rulers: EditZoneRulersConfig
    controlsTheme: EditZoneControlsColorTheme
    shadow?: fabric.IShadowOptions
    safeZoneSizeMm:
      | number
      | {
          top: number
          left: number
          right: number
          bottom: number
        }
    paddingZoneSizeMm?: number
    glueStripWidthMm?: number
    zoom: ZoomConfig
  }
  thumbnail: ThumbnailConfig
  replicablePattern?: ReplicablePatternConfig
  fsc?: FscConfig
  globalDropzone: GlobalDropzoneConfig
  bleedSizeMm: number
}

export type TextureConfig =
  | {
      path: string
      type: string
    }
  | {
      paths: string[]
      type: string
    }

export type TexturesPathsConfig = {
  virtualDielineTexture?: TextureConfig | Record<string, TextureConfig>
  virtualDielineZoomTexture?: TextureConfig | Record<string, TextureConfig>
  rendererTextures?: {
    modelContexts: Record<string, TextureConfig[]>
  }
}

export type TexturesConfig = {
  global: {
    path: string
    type: string
  }[]
  models: {
    [key: string]: {
      variants: {
        [key: string]: {
          materials: {
            id: string
            texturesPaths: TexturesPathsConfig
          }[]
          colorModes: {
            id: string
            texturesPaths: TexturesPathsConfig
          }[]
          finishes: {
            id: string
            texturesPaths: TexturesPathsConfig
          }[]
        }
      }
    }
  }
}

export type RenderConfig = {
  virtualDielinesPaths?: { [key in EditContext]?: string }
  texturesConfig: TexturesConfig
  objModelsPaths?: { [key in ModelContext]?: string }
  objMaterialPath?: string
  availableEditContexts: EditContext[]
  availableEditContextsMap: { [key in EditContext]?: EditableSpaceConfig[] }
  availableViewModes: ViewMode[]
  thumbCameraSettings?: { [key in ModelContext]?: CameraSettings }
  cameraSettings?: { [key in ModelContext]?: CameraSettings }
  scenesDecorations?: {
    front?: SpaceSceneDecoration
    back?: SpaceSceneDecoration
  }
}

export type SpaceSceneDecoration = {
  backgroundTextures: {
    materialId: any
    textureUrl: string
  }[]
  editZone: {
    zoom: number
  }
}

export type TextureDefinition = {
  paths?: string[]
  path?: string
  type: string
}

export type EditZoneRulersConfig = {
  dimensions: boolean
  lines: "dashed" | "none"
}

export type EditZoneControlsColorTheme = "light" | "dark"

export type LabelShape = "square" | "rectangle" | "circle"

export type RotationMap = Record<string, number>

export type DbyDielineType = "ai" | "pdf"
