import { PackhelpApi } from "../packhelp-api"
import { RailsPatternPayload } from "./pattern.api.types"

export interface PatternsReqOptions {
  region?: string
  taxCountryIso?: string
}

export class PatternApi {
  constructor(private api: PackhelpApi) {}

  public async getPatterns(
    options: PatternsReqOptions = {}
  ): Promise<RailsPatternPayload> {
    const payload = await this.api.ax.get(`v2/products/patterns`, {
      params: {
        region: options.region,
        tax_country_iso: options.taxCountryIso,
      },
      ...this.api.withAuthToken(),
    })
    return payload.data
  }
}
