import {
  PackhelpCanvas,
  PackhelpEditableObject,
} from "../../../../object-extensions/packhelp-objects"
import { getGroup } from "../../editable-group-controller/helpers"

export class BaseController {
  protected readonly canvas: PackhelpCanvas

  constructor(protected readonly canvasObject: PackhelpEditableObject) {
    this.canvas = this.getCurrentObject().canvas as PackhelpCanvas
  }

  protected getCurrentObject(): PackhelpEditableObject {
    const group = getGroup(this.canvasObject)

    if (group) {
      return group
    }

    return this.canvasObject
  }

  protected rerender() {
    this.canvas.renderAll()
  }
}
