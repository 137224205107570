import { IEvent } from "fabric/fabric-impl"

export interface GroupEvent {
  name: string
  fn: (e: IEvent) => void
}

export enum TempEditableGroupObject {
  BORDER = "temp_editable_group_border",
  ASSET = "temp_editable_group_asset",
}
