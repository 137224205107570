import { makeRoot } from "iti"
import { provideApiSessionContainer } from "./api-session.container"
import { providePatternMaybeContainer } from "./asset-pattern-maybe.container"
import { provideDesignAndProductDriverContainer } from "./design-and-product-driver.container"
import { provideDesignerModeMaybeContainer } from "./designer-mode-maybe.container"
import { provideEcommerceMaybeContainer } from "./ecommerce-maybe.container"
import { provideReplicablePatternMaybeContainer } from "./replicable-pattern-maybe.container"
import { provideBootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import { provideClassicRootStoreContainer } from "./root-store.container"
import { provideUtilEnvContainer } from "./util.container"
import { provideUiContainer } from "./ui.container"
import { provideFscCertificateMaybeContainer } from "./fsc-certificate-maybe.container"
import { provideAssetsContainer } from "./assets.container"
import { provideDbyModeContainer } from "./dby-mode.container"
import { provideToolingMaybeContainer } from "./tooling.container"
import { provideTemplatesMaybeContainer } from "./templates-maybe.container"
import { provideLogoContainer } from "./logo.container"
import { provideBackgroundImageContainer } from "./background-image.container"
import { provideAnalyticsMaybeContainer } from "./analytics-maybe.container"
import { provideAuthMaybeContainer } from "./auth-maybe.container"
import { provideProductSwitcherMaybeContainer } from "./product-switcher-maybe.container"

export type EditorAppContainer = ReturnType<typeof getMainEditorAppContainer>
export function getMainEditorAppContainer() {
  const node = makeRoot()
    .add(() => ({
      envUtil: provideUtilEnvContainer(),
    }))
    .add((ctx) => ({
      apiSession: async () => provideApiSessionContainer(await ctx.envUtil),
    }))
    .add((ctx) => ({
      auth: async () =>
        provideAuthMaybeContainer(await ctx.envUtil, await ctx.apiSession),
      dbyMode: async () => provideDbyModeContainer(await ctx.envUtil),
      bootstrapClassicRootStore: async () =>
        provideBootstrapClassicRootStoreContainer(
          await ctx.envUtil,
          await ctx.apiSession
        ),
    }))
    .add((ctx) => ({
      designAndProductDriver: async () =>
        provideDesignAndProductDriverContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.bootstrapClassicRootStore,
          await ctx.dbyMode,
          await ctx.auth
        ),
    }))
    .add((ctx) => ({
      ecommerce: async () =>
        provideEcommerceMaybeContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.designAndProductDriver,
          await ctx.bootstrapClassicRootStore,
          await ctx.auth,
          await ctx.dbyMode
        ),

      backgroundImage: async () =>
        provideBackgroundImageContainer(await ctx.designAndProductDriver),
    }))
    .add((ctx) => ({
      designerMode: async () =>
        provideDesignerModeMaybeContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.designAndProductDriver
        ),
      patterns: async () =>
        providePatternMaybeContainer(
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver,
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.ecommerce
        ),
      replicablePatterns: async () =>
        provideReplicablePatternMaybeContainer(
          await ctx.envUtil,
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver
        ),
      fscCertificate: async () =>
        provideFscCertificateMaybeContainer(
          await ctx.envUtil,
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver,
          await ctx.apiSession,
          await ctx.ecommerce
        ),
      productSwitcher: async () =>
        provideProductSwitcherMaybeContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver
        ),
    }))
    .add((ctx) => ({
      templates: async () =>
        provideTemplatesMaybeContainer(
          await ctx.envUtil,
          await ctx.designAndProductDriver,
          await ctx.apiSession,
          await ctx.ecommerce
        ),
    }))
    .add(async (ctx) => ({
      analytics: await provideAnalyticsMaybeContainer(
        await ctx.envUtil,
        await ctx.apiSession,
        await ctx.designAndProductDriver,
        await ctx.ecommerce
      ),
    }))
    .add((ctx) => ({
      tooling: async () => {
        return provideToolingMaybeContainer(
          await ctx.designAndProductDriver,
          await ctx.envUtil,
          await ctx.patterns,
          await ctx.templates
        )
      },
    }))
    .add((ctx) => ({
      assets: async () =>
        provideAssetsContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.designAndProductDriver
        ),
    }))
    .add((ctx) => ({
      ui: async () =>
        provideUiContainer(
          await ctx.envUtil,
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver,
          await ctx.ecommerce,
          await ctx.templates,
          await ctx.patterns,
          await ctx.fscCertificate,
          await ctx.tooling,
          await ctx.backgroundImage,
          await ctx.assets,
          await ctx.replicablePatterns,
          await ctx.dbyMode,
          await ctx.designerMode
        ),
    }))
    .add((ctx) => ({
      logo: async () =>
        provideLogoContainer(
          await ctx.envUtil,
          await ctx.designAndProductDriver,
          await ctx.templates,
          await ctx.ui
        ),
    }))
    .add((ctx) => ({
      classicRootStore: async () =>
        provideClassicRootStoreContainer(
          await ctx.envUtil,
          await ctx.apiSession,
          await ctx.bootstrapClassicRootStore,
          await ctx.designAndProductDriver,
          await ctx.designerMode,
          await ctx.ecommerce,
          await ctx.assets,
          await ctx.replicablePatterns,
          await ctx.patterns,
          await ctx.fscCertificate,
          await ctx.ui,
          await ctx.templates
        ),
    }))

  return node
}
