import EventEmitter from "eventemitter3"
import { VirtualDielineInitializer } from "../modules/vd-editor/services/virtual-dieline.initializer"
import { RenderEngine } from "../render-engine"
import { ThreeDimRenderer } from "../modules/3d-renderer/three-dim-renderer"
import { ModelContext } from "../../libs/products-render-config/types"
import { CANVAS_DIM } from "../types"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import { DielineStore } from "../../stores/dieline.store"
import VirtualDielineEditor from "../modules/vd-editor/virtual-dieline-editor"
import { ModelConfigProvider } from "../modules/3d-renderer/model-config.provider"

export class RenderEngineBuilder {
  public constructor(
    private readonly dielineStore: DielineStore,
    private readonly modelConfigProvider: ModelConfigProvider,
    private readonly eventEmitter: EventEmitter
  ) {}

  public async init(
    productRenderPilot: ProductRenderPilot,
    mountPoints: {
      virtualDielines: Record<string, HTMLDivElement>
      threeDimensionalRenderer?: HTMLDivElement
    },
    modelContext: ModelContext
  ): Promise<RenderEngine> {
    const vdEditors = await this.createVirtualDielines(
      productRenderPilot,
      mountPoints.virtualDielines
    )

    if (mountPoints.threeDimensionalRenderer) {
      const threeDimRenderer = await this.createThreeDimRenderer(
        productRenderPilot,
        mountPoints.threeDimensionalRenderer,
        modelContext,
        vdEditors
      )

      return new RenderEngine(
        vdEditors,
        this.modelConfigProvider,
        productRenderPilot,
        threeDimRenderer
      )
    }

    return new RenderEngine(
      vdEditors,
      this.modelConfigProvider,
      productRenderPilot
    )
  }

  private async createThreeDimRenderer(
    productRenderPilot: ProductRenderPilot,
    mountPoint: HTMLDivElement,
    modelContext: ModelContext,
    vdEditors: VirtualDielineEditor[]
  ): Promise<ThreeDimRenderer> {
    const modelConfig = await this.modelConfigProvider.call(
      productRenderPilot,
      modelContext
    )

    const threeDimRenderer = new ThreeDimRenderer(
      mountPoint,
      this.eventEmitter,
      Object.fromEntries(
        vdEditors.map((vdEditor) => [
          vdEditor.editContext,
          vdEditor.getCanvasTexture(),
        ])
      )
    )

    return threeDimRenderer.init(modelConfig)
  }

  private async createVirtualDielines(
    productRenderPilot: ProductRenderPilot,
    mountPoints: Record<string, HTMLDivElement>
  ) {
    const editContexts = productRenderPilot.getAvailableEditContexts()

    const virtualDielineInitializer = new VirtualDielineInitializer(
      productRenderPilot,
      { width: CANVAS_DIM, height: CANVAS_DIM },
      this.eventEmitter
    )

    return Promise.all(
      editContexts.map(async (editContext) => {
        const dielineSvg = await this.dielineStore.getVirtualDielineSvg(
          productRenderPilot.getProduct(),
          editContext
        )

        if (!dielineSvg) {
          throw new Error(`No virtual dieline for ${editContext}`)
        }

        return virtualDielineInitializer.init(
          dielineSvg,
          editContext,
          mountPoints[editContext]
        )
      })
    )
  }
}
