import * as yup from "yup"
import Colour from "./colour"
import {
  AvailableColourModes,
  ColoursPreset,
  MonochromeColours,
} from "../libs/products-render-config/types"
import { SizePx } from "../libs/calculators/object-dpi.calculator"
import { DesignItem, EcommerceDesignItem } from "../types/design.types"
import { VirtualDielinesData } from "../types/asset.types"

export enum PatternColourKeys {
  "phpatterngroup4" = "phpatterngroup4",
  "phpatterngroup3" = "phpatterngroup3",
  "phpatterngroup2" = "phpatterngroup2",
  "phpatterngroup1" = "phpatterngroup1",
}

const DEFAULT_PRESET_MONO_PANTONE: ColoursMap = {
  [PatternColourKeys.phpatterngroup1]: {
    param: "fill",
    colour: new Colour("#ff585d", "178 C").toSource(),
  },
  [PatternColourKeys.phpatterngroup2]: {
    param: "fill",
    colour: new Colour("#ff585d", "178 C").toSource(),
  },
  [PatternColourKeys.phpatterngroup3]: {
    param: "fill",
    colour: new Colour("#ff585d", "178 C").toSource(),
  },
  [PatternColourKeys.phpatterngroup4]: {
    param: "fill",
    colour: new Colour("#ff585d", "178 C").toSource(),
  },
}

const DEFAULT_PRESET_FULL_COLOR: ColoursMap = {
  [PatternColourKeys.phpatterngroup1]: {
    param: "fill",
    colour: new Colour("#7C3C21").toSource(),
  },
  [PatternColourKeys.phpatterngroup2]: {
    param: "fill",
    colour: new Colour("#EC823A").toSource(),
  },
  [PatternColourKeys.phpatterngroup3]: {
    param: "fill",
    colour: new Colour("#F9C49A").toSource(),
  },
  [PatternColourKeys.phpatterngroup4]: {
    param: "fill",
    colour: new Colour("#000000").toSource(),
  },
}

const DEFAULT_BLACK = {
  param: "fill",
  colour: new Colour("#000000").toSource(),
}
const DEFAULT_WHITE = {
  param: "fill",
  colour: new Colour("#ffffff").toSource(),
}
const DEFAULT_PRESET_MONOCHROME_BLACK: ColoursMap = {
  [PatternColourKeys.phpatterngroup1]: DEFAULT_BLACK,
  [PatternColourKeys.phpatterngroup2]: DEFAULT_BLACK,
  [PatternColourKeys.phpatterngroup3]: DEFAULT_BLACK,
  [PatternColourKeys.phpatterngroup4]: DEFAULT_BLACK,
}
const DEFAULT_PRESET_MONOCHROME_WHITE: ColoursMap = {
  [PatternColourKeys.phpatterngroup1]: DEFAULT_WHITE,
  [PatternColourKeys.phpatterngroup2]: DEFAULT_WHITE,
  [PatternColourKeys.phpatterngroup3]: DEFAULT_WHITE,
  [PatternColourKeys.phpatterngroup4]: DEFAULT_WHITE,
}

function getDefaultColorsMap(preset: ColoursPreset): ColoursMap {
  if (preset.mode === AvailableColourModes.MONO_PANTONE) {
    return DEFAULT_PRESET_MONO_PANTONE
  }

  if (
    preset.mode === AvailableColourModes.MONOCHROME &&
    preset.colourMonochrome === MonochromeColours.BLACK
  ) {
    return DEFAULT_PRESET_MONOCHROME_BLACK
  }

  if (
    preset.mode === AvailableColourModes.MONOCHROME &&
    preset.colourMonochrome === MonochromeColours.WHITE
  ) {
    return DEFAULT_PRESET_MONOCHROME_WHITE
  }

  return DEFAULT_PRESET_FULL_COLOR
}

export type Size = {
  width: number
  height: number
}

export type PatternColoursSourceMap = {
  [key in keyof typeof PatternColourKeys]: Colour
}

export type ColourDef = {
  pantoneName?: string
  hex: string
}

export interface PatternColor {
  param: string
  colour: Colour
}

export type PatternColours = {
  [key in PatternColourKeys]?: PatternColor
}

type PatternConfig = {
  minimalSize: number
  coloursMap: PatternColours
}

type ColoursMap = {
  [key in PatternColourKeys]?: {
    param: string
    colour: ColourDef
  }
}

export type PatternDesignItemAttributes = {
  designItem: DesignItem
  virtualDielines: VirtualDielinesData
}

export type PatternSourceObject = {
  url: string
  id: number
  cmsId?: number
  name: string
  netPrice: number
  grossPrice?: number
  taxRate?: number
  sku: string

  pattern_model: string
  category: string
  categories: string[]
  tags: string[]
  order: number
  config: {
    minimalSize: number
    coloursMap: ColoursMap
  }
}

export type PatternShape = {
  url: string
  id: number
  cmsId?: number
  name: string
  sku: string

  pattern_model: string
  category: string

  netPrice: number
  grossPrice?: number
  taxRate?: number

  /**
   * UI categories
   */
  categories: string[]
  tags: string[]

  /**
   * For manual sorting of a pattern in UI
   * Like a z-index
   */
  order: number
  config: PatternConfig
}

export interface PatternCategory {
  name: string
  titleIntl: string
  patterns: Pattern[]
}

const patternSourceObjectShape = yup.object().shape({
  url: yup.string().required(),
  id: yup.number().required(),
  name: yup.string().required(),

  netPrice: yup.number().required(),
  grossPrice: yup.number(),
  taxRate: yup.number(),

  pattern_model: yup.string().required(),
  category: yup.string().required(),
  categories: yup.array(yup.string()),
  tags: yup.array(yup.string()),
  order: yup.number(),
  config: yup
    .object()
    .shape({
      minimalSize: yup.number().required(),
      coloursMap: yup.mixed().required(),
    })
    .required(),
})

/**
 * Validate Pattern config. This is precursor for `add-pattern-cli.sh`
 * This will protect us from adding broken patterns
 */
function validatePatternSchema(
  s: PatternSourceObject | any
): PatternSourceObject {
  const z = s as any as PatternSourceObject
  return z

  // if (patternSourceObjectShape.isValidSync(s)) {
  //   const z = (s as any) as PatternSourceObject
  //   return z
  // } else {
  //   console.error("invalid shape", s)
  //   console.error(patternSourceObjectShape.validateSync(s))
  //   throw new Error(
  //     `Provided Shape is not valid. ${s.id} ${s.name} ${s.pattern_model} ${s.url}`
  //   )
  // }
}

export class Pattern implements EcommerceDesignItem {
  public readonly variantType = "pattern"
  public readonly patternConfig: PatternShape

  constructor(patternSource: PatternSourceObject) {
    validatePatternSchema(patternSource)
    this.patternConfig = this.buildConfig(patternSource)
  }

  public get cmsId(): number | undefined {
    return this.patternConfig.cmsId
  }

  public get id(): number {
    return this.patternConfig.id
  }

  // Note that we use rails variant as a pattern identifier
  public get variantId(): number {
    return this.id
  }

  public getNetPrice(): number {
    return this.patternConfig.netPrice
  }

  public getGrossPrice(): number | undefined {
    return this.patternConfig.grossPrice
  }

  public get taxRate(): number | undefined {
    return this.patternConfig.taxRate
  }

  public get name(): string {
    return this.patternConfig.name
  }

  public get category(): string {
    return this.patternConfig.category
  }

  public get modelName(): string {
    return this.patternConfig.pattern_model
  }

  public get categories(): string[] {
    return this.patternConfig.categories
  }

  public get tags(): string[] {
    return this.patternConfig.tags
  }

  public get order(): number {
    return this.patternConfig.order
  }

  /**
   * These magic number are fixed values, worked out with Tomek Gajda from DTP.
   * This is the smallest number possible.
   * If these number were smaller, details of the pattern would be too small in print.
   */
  public get size(): SizePx {
    return {
      heightPx: 1280,
      widthPx: 1280,
    }
  }

  /**
   * Append cache buster for bucket CORS
   */
  public get url(): string {
    const urlWithCacheBuster = new URL(this.patternConfig.url)
    if (!urlWithCacheBuster.searchParams.has("cb")) {
      urlWithCacheBuster.searchParams.append("cb", "v1")
    }

    return urlWithCacheBuster.toString()
  }

  public get colorsConfig(): PatternColours {
    return this.patternConfig.config.coloursMap
  }

  public get colorIds(): string[] {
    const coloursMap = this.colorsConfig

    return Object.keys(coloursMap)
  }

  public cloneWithNewColoursMap(
    coloursMap: PatternColours,
    preset: ColoursPreset
  ): Pattern {
    const sourceState = this.toSource()
    let serializedColoursMap = {}
    const previousColoursMap = sourceState.config.coloursMap

    if (preset.mode === AvailableColourModes.MONO_PANTONE) {
      const colorConfig = coloursMap.phpatterngroup1

      serializedColoursMap = {
        [PatternColourKeys.phpatterngroup1]: colorConfig,
        [PatternColourKeys.phpatterngroup2]: colorConfig,
        [PatternColourKeys.phpatterngroup3]: colorConfig,
        [PatternColourKeys.phpatterngroup4]: colorConfig,
      }
    } else {
      for (let key in previousColoursMap) {
        if (coloursMap[key]) {
          serializedColoursMap[key] = {
            param: coloursMap[key].param,
            colour: coloursMap[key].colour.toSource(),
          }
        } else {
          serializedColoursMap[key] = {
            param: previousColoursMap[key].param,
            colour: previousColoursMap[key].colour,
          }
        }
      }
    }

    sourceState.config.coloursMap = serializedColoursMap

    return new Pattern(sourceState)
  }

  public copy(): Pattern {
    return new Pattern(this.toSource())
  }

  public cloneWithDefaultColours(preset: ColoursPreset): Pattern {
    const coloursMap = this.colorsConfig
    const newColoursMapConfig = {}
    const defaultColorsMap = getDefaultColorsMap(preset)
    for (const key in coloursMap) {
      newColoursMapConfig[key] = {
        param: defaultColorsMap[key].param,
        colour: new Colour(defaultColorsMap[key].colour.hex),
      }
    }
    Object.assign(this.patternConfig.config.coloursMap, newColoursMapConfig)
    return this.copy()
  }

  public cloneWithColourPreset(preset: ColoursPreset): Pattern {
    if (
      preset.mode === AvailableColourModes.ECO_COLOR ||
      preset.mode === AvailableColourModes.FULL_COLOR
    ) {
      return this.copy()
    }

    let color = new Colour("#222223", "Neutral Black C")

    if (
      preset.mode === AvailableColourModes.MONOCHROME &&
      preset.colourMonochrome
    ) {
      color = new Colour(preset.colourMonochrome)
    }

    if (
      preset.mode === AvailableColourModes.MONO_PANTONE &&
      preset.colorPantoneDefault
    ) {
      color = preset.colorPantoneDefault
    }

    const coloursMap = this.colorsConfig
    const newColoursMapConfig = {}

    Object.keys(coloursMap).map((key) => {
      newColoursMapConfig[key] = {
        param: coloursMap[key].param,
        colour: color,
      }
    })

    Object.assign(this.patternConfig.config.coloursMap, newColoursMapConfig)

    return this.copy()
  }

  public getEditableColorConfigs(preset: ColoursPreset): PatternColours {
    const configs = {}

    this.getEditableColorGroups(preset).forEach((group) => {
      const config = this.colorsConfig[group]

      if (config) {
        configs[group] = config
      }
    })

    return configs
  }

  public getEditableColorGroups(preset: ColoursPreset): PatternColourKeys[] {
    if (preset.mode === AvailableColourModes.MONO_PANTONE) {
      return [PatternColourKeys.phpatterngroup1]
    }

    if (preset.mode === AvailableColourModes.MONOCHROME) {
      return []
    }

    return Object.keys(this.colorsConfig) as PatternColourKeys[]
  }

  public toSource(): PatternSourceObject {
    const patternConfig = this.patternConfig
    const { coloursMap, minimalSize } = patternConfig.config
    return {
      ...patternConfig,
      config: {
        minimalSize: minimalSize,
        coloursMap: this.colorIds.reduce(
          this.toSourceColoursMap(coloursMap),
          {}
        ),
      },
    }
  }

  private buildConfig(patternConfig: PatternSourceObject): PatternShape {
    const { coloursMap, minimalSize } = patternConfig.config
    return {
      ...patternConfig,
      config: {
        minimalSize: minimalSize,
        coloursMap: Object.keys(coloursMap).reduce(
          this.invokeColoursMap(coloursMap),
          {}
        ),
      },
    }
  }

  private invokeColoursMap = (coloursMap) => {
    return (obj, colourMapKey) => {
      return Object.assign(obj, {
        [colourMapKey]: {
          param: coloursMap[colourMapKey].param,
          colour: new Colour(
            coloursMap[colourMapKey].colour.hex,
            coloursMap[colourMapKey].colour.pantoneName
          ),
        },
      })
    }
  }

  private toSourceColoursMap = (coloursMap) => {
    return (obj, colourMapKey) => {
      return Object.assign(obj, {
        [colourMapKey]: {
          param: coloursMap[colourMapKey].param,
          colour: coloursMap[colourMapKey].colour.toSource(),
        },
      })
    }
  }
}
