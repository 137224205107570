import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { TabSection } from "../Tab/TabSection"
import { SubTabSwitcher } from "../../../molecules/SubTabSwitcher/SubTabSwitcher"
import { SubTab } from "../../../../../types/ui.types"
import { BackgroundColorSection } from "./partials/BackgroundColorSection"
import { BackgroundImageSection } from "./partials/BackgroundImageSection"
import { PrintingOptionBox } from "../../PrintingOptionBox/PrintingOptionBox"

export const TabBackground = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { backgroundTabController } = ui
  const {
    subTabs,
    isSubTabSwitcherVisible,
    activeSubTab,
    isBackgroundColorAvailable,
    isBackgroundImageAvailable,
    isPrintAdditionallyPaid,
  } = backgroundTabController

  const onSubTabClick = (subTab: SubTab) => {
    backgroundTabController.setActiveSubTab(subTab)
  }

  return (
    <Tab title={t(I18N.tabs.background)} e2eTargetName="background">
      {isSubTabSwitcherVisible && (
        <TabSection>
          <SubTabSwitcher
            subTabs={subTabs}
            onClick={onSubTabClick}
            activeSubTab={activeSubTab}
            analyticsSection="left-tab-background"
          />
        </TabSection>
      )}

      {isPrintAdditionallyPaid && (
        <TabSection>
          <PrintingOptionBox analyticsSection="left-tab-background" />
        </TabSection>
      )}

      {isBackgroundImageAvailable && <BackgroundImageSection />}
      {isBackgroundColorAvailable && <BackgroundColorSection />}
    </Tab>
  )
})
