export const colors = {
  COLORS_DEFAULT_SET: [
    {
      uid: 1,
      color: "#b6b9af",
    },
    {
      uid: 2,
      color: "#ffa645",
    },
    {
      uid: 3,
      color: "#ea81a0",
    },
    {
      uid: 4,
      color: "#4ca0b1",
    },
    {
      uid: 5,
      color: "#60c6af",
    },
    {
      uid: 6,
      color: "#acd48a",
    },
    {
      uid: 7,
      color: "#000000",
    },
    {
      uid: 8,
      color: "#ffffff",
    },
    {
      uid: 9,
      color: "#5e6965",
    },
    {
      uid: 10,
      color: "#dca81f",
    },
    {
      uid: 12,
      color: "#d53b2e",
    },
    {
      uid: 13,
      color: "#1553a0",
    },
    {
      uid: 14,
      color: "#14694a",
    },
    {
      uid: 15,
      color: "#318029",
    },
  ],
  COLORS_MAX_NUMBER: 32,
}
