import { ObjectEdge } from "../types"

export function inRange(
  currentEdge: ObjectEdge,
  nextEdge: ObjectEdge,
  radius: number
): boolean {
  if (currentEdge.axis !== nextEdge.axis) {
    return false
  }

  const currentPosition = currentEdge.points.start[currentEdge.axis]
  const nextPosition = nextEdge.points.start[nextEdge.axis]

  return Math.abs(currentPosition - nextPosition) < radius
}
