import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Label } from "../../../atoms/Label/Label"
import { I18N } from "../../../../i18n"
import { InputUnderline } from "../../../atoms/InputUnderline/InputUnderline"
import { Loader, LoaderColors, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { useTranslate } from "../../../../hooks/useTranslate"

export const MenuProjectName = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.designerMode,
  ])

  if (!containerSet) {
    return null
  }

  const { designerMode, designAndProductDriver } = containerSet

  if (designerMode.available) {
    const { designerModeController, designerModeStore } = designerMode

    return (
      <div>
        <Label>{t(I18N.generic.projectName)}</Label>
        {designerModeStore.isLoading ? (
          <Loader type={LoaderTypes.circular} color={LoaderColors.gray} />
        ) : (
          <>{designerModeController.getName()}</>
        )}
      </div>
    )
  }

  const { productDesignStore } = designAndProductDriver
  const { name } = productDesignStore.state.meta
  const { isDesignReadOnly } = productDesignStore

  const onChange = (name: string) => {
    productDesignStore.setDesignName(name)
  }

  return (
    <div>
      <Label>{t(I18N.generic.projectName)}</Label>
      <InputUnderline
        e2eTargetName="design-name"
        value={name}
        onChange={(e) => onChange(e.currentTarget.value)}
        disabled={isDesignReadOnly}
      />
    </div>
  )
})
