import { computed, observable, makeObservable } from "mobx"
import { PackhelpEditableImage } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { isAssetImage } from "../../types/asset.types"
import { isImageObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/types"
import { ImageObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/image-object.controller"
import { AvailableColourModes } from "../../libs/products-render-config/types"
import { ImageToolbarController } from "./image-toolbar.controller"
import ProductDriver from "../../drivers/product.driver"
import { AllEditorEventsEmitter } from "../../events/editor.events"

export class RasterImageToolbarController extends ImageToolbarController {
  @observable public selectedThreshold = 1
  @observable public isInverted = false
  @observable public isBackgroundRemoved = false

  constructor(services: {
    productDriver: ProductDriver
    ee: AllEditorEventsEmitter
  }) {
    super(services)

    makeObservable(this)
  }

  @computed
  public get isThresholdAvailable(): boolean {
    const { productRenderPilot } = this.productDriver.state

    const colorMode = productRenderPilot.getColorMode()
    const isMonochrome = colorMode === AvailableColourModes.MONOCHROME
    const isMonoPantone = colorMode === AvailableColourModes.MONO_PANTONE

    return isMonochrome || isMonoPantone
  }

  public setThreshold(threshold: number): void {
    this.activeObjectController?.setThreshold(threshold)
    this.refreshState()
  }

  public toggleMonochrome(): void {
    this.activeObjectController?.toggleMonochrome()
    this.refreshState()
  }

  public get isBackgroundToggleAvailable(): boolean {
    return (
      this.productDriver.state.productRenderPilot.getEditorMode() === "designer"
    )
  }

  public toggleBackground(): void {
    this.activeObjectController?.toggleImageBackground()
    this.refreshState()
  }

  protected refreshState(): void {
    super.refreshState()

    if (!this.activeObject || !this.activeObjectController) {
      return
    }

    this.selectedThreshold = this.activeObject.getImageThreshold()
    this.isInverted = this.activeObject.isImageInverted()
    this.isBackgroundRemoved = this.activeObject.isBackgroundRemoved()
  }

  @computed
  protected get activeObject(): PackhelpEditableImage | null {
    const { activeObject } = this.productDriver.activeObjectDriver

    if (activeObject && isAssetImage(activeObject)) {
      return activeObject
    }

    return null
  }

  @computed
  protected get activeObjectController(): ImageObjectController | null {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (
      activeObjectController &&
      isImageObjectController(activeObjectController)
    ) {
      return activeObjectController
    }

    return null
  }
}
