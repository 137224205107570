import React, { PropsWithChildren } from "react"
import styles from "./Notification.module.scss"
import cxBinder from "classnames/bind"
import { NotificationType } from "../../../../types/ui.types"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import closeIconUrl from "../../../assets/_icons/close.svg"
import { CloseButton } from "../CloseButton/CloseButton"

const cx = cxBinder.bind(styles)

export interface NotificationProps {
  iconUrl?: string
  type?: NotificationType
  size?: "normal" | "small"
  onClose?: () => void
}

export function Notification({
  children,
  iconUrl,
  type = "info",
  size = "normal",
  onClose,
}: PropsWithChildren<NotificationProps>) {
  return (
    <div
      className={cx("wrapper", type, size, {
        ["with-close"]: !!onClose,
      })}
    >
      {iconUrl && <SvgIcon url={iconUrl} />}
      <div className={styles.content}>{children}</div>
      {!!onClose && (
        <div className={styles.close}>
          <CloseButton onClick={onClose} size="xs" />
        </div>
      )}
    </div>
  )
}
