import { SidebarTab, UIConfig } from "../libs/products-render-config/types"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import { SidebarStore } from "../stores/sidebar.store"
import ProductDriver from "../drivers/product.driver"

export class SidebarController {
  constructor(
    private sidebarStore: SidebarStore,
    private productDriver: ProductDriver,
    private uiConfig: UIConfig,
    private uri: UrlManipulatorProvider
  ) {
    this.selectInitialTab()
  }

  private getInitialTab(): SidebarTab | undefined {
    if (this.uri.isDesignerMode() || this.uri.isDtpTestEcommerceMode()) {
      return
    }

    if (this.uri.hasForcedLogoUploaderInUrl()) {
      if (this.uiConfig.tabs.logo) {
        return "logo"
      }

      if (this.uiConfig.tabs.replicablePatterns) {
        return "replicablePatterns"
      }
    }

    const forcedTab = this.uri.getForcedTab()

    if (forcedTab) {
      return forcedTab as SidebarTab
    }

    if (this.productDriver.state.productRenderPilot.isDbyMode()) {
      return "dby"
    }

    if (!this.uri.getSkuFromUrl() && !this.uri.getDesignIdParam()) {
      return "product"
    }

    const isIn3dView = !this.productDriver.state.activeSpace
    const is3dProduct = this.productDriver.state.renderEngine?.has3D

    if (is3dProduct && !isIn3dView) {
      return
    }

    if (this.uiConfig.tabs.templatesAndPatterns) {
      return "templatesAndPatterns"
    }

    if (this.uiConfig.tabs.logo) {
      return "logo"
    }

    if (this.uiConfig.tabs.configuration) {
      return "configuration"
    }
  }

  private selectInitialTab(): void {
    const initialTab = this.getInitialTab()

    if (!initialTab) {
      return
    }

    this.sidebarStore.selectTab(initialTab)
  }
}
