import { computed } from "mobx"
import { CanvasPopoverPosition, Notification } from "../types/ui.types"
import ProductDriver from "../drivers/product.driver"
import { FontSizeCalculator } from "../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/calculators/font-size.calculator"
import { FormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { I18N } from "../ui/i18n"
import { EditableObjectTypes } from "../types/asset.types"
import { PackhelpGroupType } from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"

const i18n = I18N.components.floatingToolbar

export class CanvasNotificationUiController {
  private readonly productDriver: ProductDriver
  private readonly formatMessage: FormatMessage

  constructor(services: {
    productDriver: ProductDriver
    formatMessage: FormatMessage
  }) {
    this.productDriver = services.productDriver
    this.formatMessage = services.formatMessage
  }

  @computed
  public get popoverPosition(): CanvasPopoverPosition | undefined {
    const { activeObject, activeObjectComputable } =
      this.productDriver.activeObjectDriver

    if (!activeObject) {
      return
    }

    const vdEditor = this.productDriver.getVdEditor()
    const diff = vdEditor.getCanvasToSceneDiff()

    const { boundingTop, boundingLeft, boundingHeight } = activeObjectComputable

    return {
      left: boundingLeft - diff.width / 2,
      top: boundingTop - diff.height / 2 + boundingHeight,
    }
  }

  @computed
  public get notifications(): Notification[] {
    const { activeObjectComputable } = this.productDriver.activeObjectDriver
    const {
      isOverscaled,
      isSafeZoneTouched,
      isSpaceEdgeCrossed,
      isMinScaleLimitReached,
    } = activeObjectComputable

    const notifications: Notification[] = []

    if (isOverscaled) {
      notifications.push({
        type: "error",
        message: `${this.formatMessage({
          id: i18n.imageQualityInfo,
        })} ${this.formatMessage({ id: i18n.imageQuality })}`,
      })
    }

    if (isMinScaleLimitReached && this.minScaleLimitMessage) {
      notifications.push({
        type: "warning",
        message: this.minScaleLimitMessage,
      })
    }

    if (isSafeZoneTouched) {
      notifications.push({
        type: "warning",
        message: this.formatMessage({ id: i18n.placementInfo }),
      })
    }

    if (isSpaceEdgeCrossed) {
      notifications.push({
        type: "info",
        message: this.formatMessage({ id: i18n.spaceClippingDisabledInfo }),
      })
    }

    return notifications
  }

  @computed
  private get fontSizeCalculator(): FontSizeCalculator {
    const { productRenderPilot, activeContext } = this.productDriver.state

    const dielineNavigator = this.productDriver.getDielineNavigator()
    const spaceId = productRenderPilot.getDefaultSpace(activeContext)
    const space = dielineNavigator.getVirtualDielineSpace(spaceId)

    const fontSizeCalculator = new FontSizeCalculator(
      productRenderPilot,
      activeContext,
      space
    )

    return fontSizeCalculator
  }

  @computed
  private get minScaleLimitMessage(): string | undefined {
    const { activeObjectType } = this.productDriver.activeObjectDriver
    const minFontSize = this.fontSizeCalculator.calcEditorMin()

    switch (activeObjectType) {
      case EditableObjectTypes.assetGroup:
      case PackhelpGroupType.activeSelection:
        return this.formatMessage(
          { id: i18n.minScaleLimitInfo.group },
          { minFontSize }
        )
      case EditableObjectTypes.assetText:
        return this.formatMessage(
          { id: i18n.minScaleLimitInfo.text },
          { minFontSize }
        )
      case EditableObjectTypes.assetObject:
        return this.formatMessage({ id: i18n.minScaleLimitInfo.shape })
    }
  }
}
