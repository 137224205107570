import React from "react"
import { observer } from "mobx-react-lite"
import { GlobalDropzone } from "../../dsl/organisms/GlobalDropzone/GlobalDropzone"
import styles from "./Proscenium.module.scss"
import { Header } from "../../dsl/organisms/Header/Header"
import { Aside } from "../../dsl/organisms/Aside/Aside"
import { Scene } from "../Scene/Scene"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { SceneOverlay } from "../../dsl/organisms/scenes/SceneOverlay/SceneOverlay"
import { CanvasLoader } from "../../dsl/organisms/scenes/CanvasLoader/CanvasLoader"
import { SceneUi } from "../Scene/SceneUi"
import cxBinder from "classnames/bind"
import { ReadOnlyNotification } from "../../dsl/organisms/ReadOnlyNotification/ReadOnlyNotification"

const cx = cxBinder.bind(styles)

export const Proscenium = observer(function () {
  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
  ])

  if (!containerSet) {
    return null
  }

  const { productDriver, productDesignStore } =
    containerSet.designAndProductDriver
  const { printingModeUiController, editorModeController } = containerSet.ui
  const { isSceneDisabled } = printingModeUiController
  const { isDesignReadOnly, isSkippingDesign } = editorModeController
  const { isSelectingAssetPlacement } = productDriver.state

  return (
    <div className={cx("proscenium", { "read-only": isDesignReadOnly })}>
      <div
        className={cx("header", { disabled: isSelectingAssetPlacement })}
        e2e-target="layout"
        e2e-target-name="header"
      >
        <Header />
      </div>

      {!isDesignReadOnly && (
        <div className={cx("aside", { disabled: isSelectingAssetPlacement })}>
          <Aside />
        </div>
      )}

      <div className={cx("scene", { hidden: isSkippingDesign })}>
        <Scene
          productDriver={productDriver}
          loading={productDesignStore.state.isProductDesignLoading}
        />
        <SceneUi isSelectingAssetPlacement={isSelectingAssetPlacement} />
        <CanvasLoader />
        {isDesignReadOnly && <ReadOnlyNotification />}
      </div>

      <div
        className={cx({ hidden: isSkippingDesign })}
        e2e-target="layout"
        e2e-target-name="overlay"
      >
        {isSceneDisabled && <SceneOverlay />}
        <GlobalDropzone />
      </div>
    </div>
  )
})
