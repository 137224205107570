import { PackhelpRegion } from "./region-config"

export enum SupportedCurrency {
  PLN = "PLN",
  EUR = "EUR",
  USD = "USD",
  GBP = "GBP",
  RON = "RON",
  SEK = "SEK",
}

export type SupportedLocales = string // TODO - specify

export type EuShippingCountry = Exclude<
  Country,
  | PartiallySupportedCountry
  | NonEuShippingCountry
  | ProblematicCountry
  | Country.Poland
  | OtherEuropeanCountry
>

export type ProblematicCountry = Country.Malta | Country.Cyprus

// <WIP> Below go Countries that are somehow specified in the system
export type OtherEuropeanCountry = Country.Andorra | Country.Monaco

export enum Country {
  UnitedStates = "US",
  Andorra = "AD",
  Australia = "AU",
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Canada = "CA",
  Croatia = "HR",
  Cyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Greece = "GR",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Latvia = "LV",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Monaco = "MC",
  Netherlands = "NL",
  Norway = "NO",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
  Switzerland = "CH",
  UnitedKingdom = "GB",
}

export const countriesWithAlwaysAppliedTax = [
  Country.Poland,
  Country.UnitedKingdom,
] as const
export type countryWithAlwaysAppliedTax =
  (typeof countriesWithAlwaysAppliedTax)[number]
export const regionsWithAlwaysAppliedTax: PackhelpRegion[] = ["pl", "gb"]

const nonEuShippingCountries = [Country.Norway, Country.Switzerland] as const

export type NonEuShippingCountry = (typeof nonEuShippingCountries)[number]

export const isNonEuShippingCountry = (
  country: any
): country is NonEuShippingCountry => nonEuShippingCountries.includes(country)

export const partiallySupportedCountries = [
  Country.UnitedStates,
  Country.Canada,
  Country.Australia,
] as const

export type PartiallySupportedCountry =
  (typeof partiallySupportedCountries)[number]

export const isPartiallySupportedCountry = (
  country: any
): country is PartiallySupportedCountry =>
  partiallySupportedCountries.includes(country)

type AllowedShipments = {
  "shipping-method-courier": string[]
}

type DeliveryTime = {
  compartmentDeliveryTime: string
  avarageDeliveryTime: string
  samplePackAverageDeliveryTime: string
}

export type ShipmentsConfiguration = {
  allowedShipments: AllowedShipments
  deliveryTime: DeliveryTime
}

type ProductPricingValues = {
  shipmentsConfig: ShipmentsConfiguration
}

export type ZpkjObjectType = {
  actions?: Object
  dynamicShopSettings?: Object
  algoliaHelpPostsIndexName?: string
  endpoint?: Object
  env?: {
    Z_INSTANCE_HTTPS?: string
    Z_INSTANCE_NAME?: string
    Z_INSTANCE_PORT?: string
    Z_INSTANCE_ENV?: string
    HUBSPOT_KEY?: string
    HUBSPOT_CHAT_ENABLED?: boolean
    SEGMENT_CODE_ID?: string
    REACT_APP_Z_RUDDERSTACK_CODE?: string
    REACT_APP_Z_RUDDERSTACK_DATA_PLANE_URL?: string
  }
  analyticsOptionsParams?: string
  isMobile?: boolean
  locale?: {
    deliveryTime?: string
    allowedShipments?: []
    alwaysShowAddedTax?: boolean
    emailAddress?: string
    languageTag?: string
    forcedLocale?: string
    locale?: string
    env?: string
  }
  productPricingValues?: ProductPricingValues
  sharedStoresServiceProvider?: Object
  stores?: Object
  translations?: Object
  constants?: {
    EXPORTED_PRICING: {
      products: []
      shipments: []
    }
  }
}
