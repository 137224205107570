// Strapi

import { VirtualDielineObject } from "./asset.types"

export interface PredefinedTextAssetDto {
  objects: VirtualDielineObject[]
  canvasDimensions: {
    width: number
    height: number
  }
}

export type PredefinedTextDto = {
  id: string
  name: string
  assetFile: StrapiFileResponseDto | null
  slug: string
  previews: StrapiImageResponseDto[]
  tags: Tag[]
  order: number
}

export type StrapiImageResponseDto = {
  id: string
  name: string
  alternativeText: null
  caption: null
  width: string
  height: string
  formats: Formats
  hash: string
  ext: string
  mime: string
  size: number
  url: string
  previewUrl: null
  provider: string
  provider_metadata: null
  created_at: Date
  updated_at: Date
  related: any[]
}

export type StrapiFileResponseDto = Pick<StrapiImageResponseDto, "url">

interface Formats {
  thumbnail: StrapiImageFormat
  small: StrapiImageFormat
}

export interface StrapiImageFormat {
  name: string
  hash: string
  ext: string
  mime: string
  width: number
  height: number
  size: number
  url: string
}

export interface Tag {
  tag: string
}

export enum ColorScheme {
  MONOCHROMATIC = "color-mono",
  COLOURFUL = "color-full",
}
