import React from "react"
import Typography, { TType } from "dsl/src/atoms/Typography/Typography"
import { SelectItemData } from "dsl/src/atoms/Select/types"
import styles from "./CountrySelectItem.module.scss"

const CountrySelectItem = (props: SelectItemData) => {
  return (
    <>
      <img
        src={`/img/images/eu_countries/${props.id.toLowerCase()}.svg`}
        alt=""
        className={styles["image"]}
      />
      <Typography type={TType.Body15_350} e2e-target-name={props.name}>
        {props.name}
      </Typography>
    </>
  )
}

export { CountrySelectItem }
