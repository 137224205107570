var SkuMaterial;
(function (SkuMaterial) {
    SkuMaterial["SKU_MATERIAL_PINE_SPRUCE"] = "pine-spruce";
    SkuMaterial["SKU_MATERIAL_FOIL_LDPE"] = "foil-ldpe";
    SkuMaterial["SKU_MATERIAL_PAPER_GLOSS"] = "paper-gloss";
    SkuMaterial["SKU_MATERIAL_PAPER_LINEN"] = "paper-linen";
    SkuMaterial["SKU_MATERIAL_BROWN"] = "brown";
    SkuMaterial["SKU_MATERIAL_WHITE"] = "white";
})(SkuMaterial || (SkuMaterial = {}));
export default SkuMaterial;
