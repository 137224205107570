import { ExtendedProduct } from "../../../configurators/_common/utils/box-configurator.types"
import { ProductManager } from "@ph/product-api"

export type ReturnedCountShape = {
  count: number
  price: number
}

export type TreesStateType = ReturnedCountShape & {
  isPlantingTrees: boolean
}

export type CountTreesToPlant = (params: {
  product: ExtendedProduct | ProductManager
  currency: string
  quantity: number
}) => ReturnedCountShape

export type GetFormattedPrice = (price: number, currency: string) => string

export enum Materials {
  Cardboard_Cardstock = "cardboard-cardstock", // 1.25
  Cardstock_Coated = "cardstock-coated", // 1.25

  Cardboard_White_One_Side = "cardboard-white-one-side", // 1.5
  Cardboard_Coated_White_Inside = "cardboard-coated-white-inside", // 1.5
  Cardboard_Natural = "cardboard-natural", // 1.5
  Cardboard_White = "cardboard-white", // 1.5

  Paper_Natural = "paper-natural", // 1.5
  Paper_White = "paper-white", // 1.5

  Paper_Kraft = "paper-kraft", // 1.25

  Tissue_Paper = "tissue", // 1.1

  Kraft = "kraft", // 1.5
}
