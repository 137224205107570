// @ts-nocheck

export enum serverInstanceTypes {
  PRODUCTION = "production",
  DEVELOPMENT = "dev",
  STAGING = "staging",
  LOCAL = "local",
}

export const getEnvVariable = (envVarName: string, defaultValue = null) => {
  if (
    typeof process !== "undefined" &&
    typeof process.env[envVarName] !== "undefined"
  ) {
    return process.env[envVarName]
  }
  if (
    typeof window !== "undefined" &&
    window &&
    window.zpkj &&
    window.zpkj.env &&
    typeof window.zpkj.env[envVarName] !== "undefined"
  ) {
    return window.zpkj.env[envVarName]
  } else {
    return defaultValue
  }
}

export const getServerInstance = () => {
  return getEnvVariable("Z_INSTANCE_ENV") // serverInstanceTypes
}

export const getServerInstanceName = () => {
  return isServerDevelopment()
    ? getEnvVariable("Z_INSTANCE_NAME")
    : getServerInstance()
}

export const isServerProduction = () => {
  return getServerInstance() === serverInstanceTypes.PRODUCTION
}

export const isServerStaging = () => {
  return getServerInstance() === serverInstanceTypes.STAGING
}

export const isServerDevelopment = () => {
  return getServerInstance() === serverInstanceTypes.DEVELOPMENT
}

export const isServerLocal = () => {
  return getServerInstance() === serverInstanceTypes.LOCAL
}
