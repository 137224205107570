import React from "react"
import { AuthMaybeContainer } from "../../../_containers/auth-maybe.container"
import { ModalRegistrationSuccessful } from "../../dsl/organisms/modals/ModalRegistrationSuccessful/ModalRegistrationSuccessful"
import styles from "./AuthApp.module.scss"

export const AuthApp = React.memo(
  ({ authContainer }: { authContainer: AuthMaybeContainer }) => {
    if (!authContainer.available) {
      return null
    }

    const { authController, AuthApp: BaseAuthApp } = authContainer

    return (
      <div className={styles.wrapper}>
        <BaseAuthApp
          authEventsEmitter={authContainer.authController.authEventEmitter}
          location="editor"
        />
        {authController.isSuccessfulRegistrationModalOpened && (
          <ModalRegistrationSuccessful
            onClose={() =>
              authController.setIsSucccessfulRegistrationModalOpened(false)
            }
            email={authController.registeredUserEmail}
          />
        )}
      </div>
    )
  }
)
