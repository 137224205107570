import SegmentAnalytics from "./segment-analytics"
import { postTrackingData } from "../../services/api/endpoints/analytics-endpoints"
import { ApiStudio, User, AnonymousUser } from "@ph/api-studio"
import RegionConfig from "shared-libs/src/configs/region-config/interfaces/js/region-config"
import { getApiTokenFromCookie } from "shared-libs/src/js/libs/helpers/api-token.helpers"
import { apiServiceWithCurrentToken } from "../../services/api/api-service"
import { Sentry } from "../../services/sentry-service"
import { identifyUser } from "./segment-analytics.methods"
import { Ga4Service } from "../google/ga4-service"
import { SegmentSessionPayload } from "@ph/api-studio/dist/endpoints/analytics.types"
import { AttributionService } from "../attribution-service"

export enum SegmentTrackingParams {
  anonymous = "anonymous_id",
  userEmail = "user_email",
  timestamp = "timestamp",
}

type TrackProperties = {
  event: string
  properties: {}
  integrations?: {}
  user_traits?: boolean
  user_email?: string
  products_order_id?: number
}

type TrackThroughBackendProps = (
  trackProperties: TrackProperties,
  callback?: () => void,
  userEmail?: string
) => Promise<void>

type ProductAddedUAEURConversionProps = (lineItemId: number) => Promise<void>

export const getSegmentAnonymousId = async () =>
  await SegmentAnalytics.anonymousId()

export const pushToGtm = (event: TrackProperties) => {
  // @ts-ignore
  if (!window.dataLayer) {
    // @ts-ignore
    window.dataLayer = []
  }
  const e = Object.assign({}, event)
  e.event = event.event
  // @ts-ignore
  window.dataLayer.push(e)
}

export const trackThroughBackend: TrackThroughBackendProps = async (
  trackProperties,
  callback,
  userEmail
) => {
  if (typeof window === "undefined") return

  const attributionService = new AttributionService()
  const anonymousId = await getSegmentAnonymousId()
  const timestamp = new Date().toISOString()

  try {
    // GA4 section - start
    const gtag = await Ga4Service.getGtagWhenReadyWithTimeout()

    if (gtag) {
      await Ga4Service.decorateObjectWithGtagData(trackProperties.properties)
    }
    // GA4 section - end

    Object.assign(trackProperties.properties, {
      // client | proxied  | server
      // not "proxied" because it doesn't work with GA4
      // it didn't work because of segment filter....
      attribution_gclid: attributionService.getGclid(),
      attribution_country: attributionService.getCountry(),
      event_origin: "proxied",
    })

    await apiServiceWithCurrentToken().call(
      postTrackingData({
        ...trackProperties,
        [SegmentTrackingParams.anonymous]: anonymousId,
        [SegmentTrackingParams.userEmail]: userEmail,
        [SegmentTrackingParams.timestamp]: timestamp,
      })
    )
    pushToGtm(trackProperties)
    callback && callback()
  } catch (e) {
    // in case Sentry is removed by adblock, at least log the error to the developer
    try {
      Sentry.captureException(e)
    } catch (e) {
      console.warn(e)
    }
  }
}

export const trackProductAddedUAEURConversion: ProductAddedUAEURConversionProps =
  async (lineItemId) => {
    try {
      const token = getApiTokenFromCookie()
      const baseUrl = RegionConfig.getOriginForRestApi()
      const api = new ApiStudio({
        token: token,
        axios: { baseURL: `${baseUrl}/api` },
      })

      await api.analytics.trackProductAddedUAEURConversionData(lineItemId)
    } catch (e) {
      // In case Sentry is blocked by AdBlock.
      try {
        Sentry.captureException(e)
      } catch (e) {
        console.warn(e)
      }
    }
  }

export enum SegmentIdentifyParams {
  anonymousId = "anonymous_id",
}

export const identifyUserThroughBackend = async () => {
  try {
    const anonymous_id = await getSegmentAnonymousId()
    const token = getApiTokenFromCookie()
    const baseUrl = RegionConfig.getOriginForRestApi()
    const api = new ApiStudio({
      token: token,
      axios: { baseURL: `${baseUrl}/api` },
    })

    await api.analytics.postIdentifyData({ anonymous_id })
  } catch (e) {
    // In case Sentry is blocked by AdBlock.
    try {
      Sentry.captureException(e)
    } catch (e) {
      console.warn(e)
    }
  }
}

export const createSegmentSession = async (token: string) => {
  try {
    const baseUrl = RegionConfig.getOriginForRestApi()
    const api = new ApiStudio({
      token: token,
      axios: { baseURL: `${baseUrl}/api` },
    })

    const payload = await getSegmentPayload()

    return await api.analytics.postCreateSegmentSessionData(payload)
  } catch (e) {
    // In case Sentry is blocked by AdBlock.
    try {
      Sentry.captureException(e)
    } catch (e) {
      console.warn(e)
    }
  }
}

export const appendSegmentSession = async (token: string) => {
  try {
    const baseUrl = RegionConfig.getOriginForRestApi()
    const api = new ApiStudio({
      token: token,
      axios: { baseURL: `${baseUrl}/api` },
    })

    const payload = await getSegmentPayload()

    await api.analytics.postAppendSegmentSessionData(payload)
  } catch (e) {
    // In case Sentry is blocked by AdBlock.
    try {
      Sentry.captureException(e)
    } catch (e) {
      console.warn(e)
    }
  }
}

async function getSegmentPayload(): Promise<SegmentSessionPayload> {
  const attributionService = new AttributionService()

  const params = {
    anonymous_id: await getSegmentAnonymousId(),
  }

  const attributionParams = {
    attribution_gclid: attributionService.getGclid(),
    attribution_country: attributionService.getCountry(),
  }

  await Ga4Service.decorateObjectWithGtagData(params, "ga")

  return { ...params, ...attributionParams }
}

export const initializeSegmentSession = async (user: AnonymousUser) => {
  await createSegmentSession(user.spree_api_key)
  identifyUserThroughBackend()
}

export const continueSegmentSession = async (user: User) => {
  appendSegmentSession(user.spree_api_key)
  if (user.email) {
    identifyUser({ userId: user.id })
  }
}

export const closeSegmentSession = async () => {
  const segmentApi = SegmentAnalytics.segmentApi()

  if (!segmentApi) {
    return
  }

  segmentApi.reset()
}
