import { AxiosInstance } from "axios"

export class DirectusAssetApi {
  constructor(private ax: AxiosInstance) {}

  public async upload(blob: Blob, name: string): Promise<{ id: string }> {
    const formData = new FormData()
    formData.append("file", blob, name)

    const baseUrl = new URL(this.ax.defaults.baseURL!).origin
    const response = await this.ax.post(`${baseUrl}/files`, formData)

    return response.data.data
  }

  public async getAssetByUrl<T>(url: string): Promise<T> {
    const response = await this.ax.get(url)

    return response.data
  }
}
