import React from "react"

export const ArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <path
        id="icons-ui-arrows-type-1-down-svg-0a"
        d="M12 19c-.43 0-.86-.168-1.188-.503L.492 7.937a1.748 1.748 0 010-2.433 1.654 1.654 0 012.377 0L12 14.848l9.13-9.344a1.654 1.654 0 012.377 0c.657.671.657 1.76 0 2.432l-10.319 10.56A1.656 1.656 0 0112 19z"
      ></path>
    </defs>
    <g fillRule="evenodd">
      <mask>
        <use xlinkHref="#icons-ui-arrows-type-1-down-svg-0a"></use>
      </mask>
      <use xlinkHref="#icons-ui-arrows-type-1-down-svg-0a"></use>
    </g>
  </svg>
)
