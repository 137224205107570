import React from "react"
import styles from "./PatternColorPalette.module.scss"
import {
  PatternColor,
  PatternColourKeys,
  PatternColours,
} from "../../../../models/pattern"
import { ColorConfigurator } from "../../organisms/configurators/ColorConfigurator/ColorConfigurator"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { ContextNav } from "../../atoms/ContextNav/ContextNav"
import { ContextNavItem } from "../../atoms/ContextNav/ContentNavItem"

const i18n = I18N.editorTools.patterns

export interface PatternColorPaletteProps {
  colorConfigs: PatternColours
  onChange: (key: PatternColourKeys, colorConfig: PatternColor) => void
  onReset?: () => void
}

export function PatternColorPalette({
  colorConfigs,
  onChange,
  onReset,
}: PatternColorPaletteProps) {
  const t = useTranslate()

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {t(i18n.colors)}
        <div className={styles.pickers}>
          {Object.entries(colorConfigs).map(([key, colorConfig]) => {
            return (
              <ColorConfigurator
                key={key}
                onColorSelect={(colour) =>
                  onChange(key as PatternColourKeys, {
                    param: colorConfig.param,
                    colour,
                  })
                }
                selectedColor={colorConfig.colour}
                analyticsSection="left-tab-templates"
                analyticsName="pattern-color"
              />
            )
          })}
        </div>
      </div>
      {onReset && (
        <ContextNav>
          <ContextNavItem label={t(i18n.resetToDefault)} onClick={onReset} />
        </ContextNav>
      )}
    </div>
  )
}
