export var WoodWoolVariant;
(function (WoodWoolVariant) {
    WoodWoolVariant["WOOD_WOOL_VARIANT"] = "woodWool";
})(WoodWoolVariant || (WoodWoolVariant = {}));
export var SelfAdhesiveEnvelopeVariant;
(function (SelfAdhesiveEnvelopeVariant) {
    SelfAdhesiveEnvelopeVariant["SELF_ADHESIVE_ENVELOPE_VARIANT"] = "selfAdhesiveEnvelope";
})(SelfAdhesiveEnvelopeVariant || (SelfAdhesiveEnvelopeVariant = {}));
export var StickerRectangleVariant;
(function (StickerRectangleVariant) {
    StickerRectangleVariant["STICKER_RECTANGLE_VARIANT"] = "stickerRectangle";
})(StickerRectangleVariant || (StickerRectangleVariant = {}));
export var StickerCircleVariant;
(function (StickerCircleVariant) {
    StickerCircleVariant["STICKER_CIRCLE_VARIANT"] = "stickerCircle";
})(StickerCircleVariant || (StickerCircleVariant = {}));
export var ThankYouCardVariant;
(function (ThankYouCardVariant) {
    ThankYouCardVariant["THANK_YOU_CARD_VARIANT"] = "thankYouCard";
})(ThankYouCardVariant || (ThankYouCardVariant = {}));
export var PaperBubbleWrapVariant;
(function (PaperBubbleWrapVariant) {
    PaperBubbleWrapVariant["PAPER_BUBBLE_WRAP_VARIANT"] = "paperBubbleWrap";
})(PaperBubbleWrapVariant || (PaperBubbleWrapVariant = {}));
export default Object.assign({}, WoodWoolVariant, SelfAdhesiveEnvelopeVariant, StickerCircleVariant, StickerRectangleVariant, ThankYouCardVariant, PaperBubbleWrapVariant);
