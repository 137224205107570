import { BrandingEnvConfig } from "../types"

export class BrandingConfig {
  public readonly logo?: BrandingEnvConfig["logo"]
  public readonly loader?: BrandingEnvConfig["loader"]

  constructor(config: BrandingEnvConfig = {}) {
    this.logo = config.logo
    this.loader = config.loader
  }
}
