import { Pattern } from "../../models/pattern"

export function paintSVGInDom(svgElement: SVGElement, pattern: Pattern) {
  const { colorsConfig, colorIds } = pattern

  colorIds.map((id) => {
    const currentColor = colorsConfig[id].colour.getHex()
    const currentElement = svgElement.querySelector(`#${id}`)

    if (!currentElement) {
      return
    }

    const domElements = Array.from(currentElement.children)

    domElements.forEach((element) => {
      element.setAttribute("fill", currentColor)
    })
  })
}
