import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../hooks/useTranslate"
import { I18N } from "../../i18n"

const i18n = I18N.confirmations.closeWindow

export const OnBeforeUnload = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ui,
  ])

  const editorModeController = containerSet?.ui.editorModeController
  const designAndProductDriver = containerSet?.designAndProductDriver
  const productDesignStore = designAndProductDriver?.productDesignStore
  const isProductDesignSaving = productDesignStore?.state.isProductDesignSaving
  const isDesignTouched = productDesignStore?.state.isDesignTouched

  const shouldPrompt = () => {
    if (!containerSet) {
      return
    }

    if (editorModeController?.isSkippingDesign) {
      return
    }

    /**
     * Important!!
     *
     * We need to check it again, because the value can change in the meantime.
     */
    if (productDesignStore?.state.isProductDesignSaving) {
      return t(i18n.savingInProgress)
    }

    if (
      productDesignStore?.state.isDesignTouched &&
      productDesignStore?.isEditMode
    ) {
      return t(i18n.unsavedData)
    }
  }

  useEffect(() => {
    window.onbeforeunload = shouldPrompt

    return () => {
      window.onbeforeunload = shouldPrompt
    }
  }, [isProductDesignSaving, isDesignTouched])

  return null
})
