import {
  ObjectDpiCalculator,
  SizeMm,
  SizePx,
} from "../../calculators/object-dpi.calculator"
import VirtualDielineEditor from "../../../render-engine/modules/vd-editor/virtual-dieline-editor"
import { VirtualDielineSpace } from "../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { ProductRenderPilot } from "../../products-render-config/product-render-pilot"
import { SpaceId } from "../../products-render-config/types"
import _floor from "lodash/floor"

const MIN_SIZE_MM = 50

export class PatternSizeCalculator {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public calculate(originalPatternSize: SizePx): number {
    const productDimensionsApx = ObjectDpiCalculator.mmToApx(
      this.productDimensionsMm
    )
    const maxImageSizeVdpx = ObjectDpiCalculator.getImageMaxSizeDpx(
      this.vdSpace,
      originalPatternSize,
      productDimensionsApx
    ).widthVdpx

    if (this.vdSpace.id !== SpaceId.DEFAULT) {
      return maxImageSizeVdpx
    }

    const patternSizeVdpx = _floor(
      Math.min(this.vdSpace.width / 4, maxImageSizeVdpx)
    )
    const patternSizeMm =
      (patternSizeVdpx / this.vdSpace.width) * this.productDimensionsMm.widthMm

    if (patternSizeMm >= MIN_SIZE_MM) {
      return patternSizeVdpx
    }

    const iterations = _floor(this.productDimensionsMm.widthMm / MIN_SIZE_MM)

    if (iterations === 0) {
      return _floor(patternSizeVdpx * (MIN_SIZE_MM / patternSizeMm))
    }

    return (
      this.vdSpace.width /
      _floor(this.productDimensionsMm.widthMm / MIN_SIZE_MM)
    )
  }

  private get productDimensionsMm(): SizeMm {
    if (this.vdSpace.id === SpaceId.DEFAULT) {
      const { widthCm, heightCm } = this.productRenderPilot.getSpaceDimensions(
        this.vdEditor.editContext,
        this.vdSpace.id
      )

      return {
        widthMm: widthCm * 10,
        heightMm: heightCm * 10,
      }
    }

    const product = this.productRenderPilot.getProduct()

    if (!product) {
      throw new Error("Product is not available!")
    }

    return ObjectDpiCalculator.sizeToMm(
      product.dimensionsManager.getDimensions().outside
    )
  }

  private get vdSpace(): VirtualDielineSpace {
    const is3D = this.productRenderPilot.is3DProduct()
    const availableSpaces = this.productRenderPilot.getAvailableSpaces(
      this.vdEditor.editContext
    )
    let spaceId = this.productRenderPilot.getDefaultSpace(
      this.vdEditor.editContext
    )

    /**
     * For calculating scale ratio for patterns, we are using FRONT space.
     * FRONT space has no fold line, so it's a bit bigger then TOP space.
     * It's not exactly the width of the box from outside, but it's close enough.
     *
     * I guess, in order to be more precise, we should calculate pattern size (in dpx)
     * based on the FRONT space width, not the outside width of the box.
     *
     * But if we change it, we have to make sure it will print well in DTP
     * (and whether the magic 1280 number is correct).
     *
     * For example (F33):
     * - outside width: 118mm
     * - width from render spec: 114.5mm
     * - front side width: 112.4mm
     * - top side width: 103.5mm
     *
     * We won't apply pattern in polymailers for now.
     *
     * We can't use TOP space, because the pattern will be smaller than it was.
     * You will see the pattern will move a bit, the change in size is almost invisible.
     */
    if (is3D && availableSpaces.includes(SpaceId.FRONT)) {
      spaceId = SpaceId.FRONT
    }

    return this.vdEditor.dielineNavigator.getVirtualDielineSpace(spaceId)
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.vdEditor.productRenderPilot
  }
}
