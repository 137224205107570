import { computed } from "mobx"
import { CartStore } from "../stores/cart.store"
import { I18N } from "../ui/i18n"

const i18n = I18N.component.loader

export class CurtainUiController {
  private readonly cartStore?: CartStore

  constructor(stores: { cartStore?: CartStore }) {
    this.cartStore = stores.cartStore
  }

  @computed
  public get isVisible(): boolean {
    return !!this.cartStore?.isInstantPurchase
  }

  @computed
  public get labelIntl(): string {
    return i18n.addingToCart
  }
}
