import VirtualDielineEditor from "../../../virtual-dieline-editor"
import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpGroup,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { BackgroundsLayers } from "../../backgrounds-module/types"

export class ReplicablePatternLayerController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async getSpace(spaceId: SpaceId): Promise<PackhelpObject> {
    const layer = await this.prepareLayer()
    const space = layer.getObjects().find((object) => object.id === spaceId)

    if (!space) {
      throw new Error("Space does not exist")
    }

    return space
  }

  public async prepareLayer(): Promise<PackhelpGroup> {
    let layer = this.findLayer()

    if (!layer) {
      layer = await this.initLayer()

      this.vdEditor.addOnCanvas(layer, true)
    }

    layer.set({
      evented: false,
      selectable: false,
      objectCaching: false,
    })

    return layer
  }

  private findLayer(): PackhelpGroup | undefined {
    return this.vdEditor.getCanvasObjectById(
      BackgroundsLayers.REPLICABLE_PATTERN
    ) as PackhelpGroup
  }

  private initLayer(): Promise<PackhelpGroup> {
    const virtualDieline = this.vdEditor.getProductVirtualDieline()

    return new Promise((resolve) => {
      virtualDieline.clone(
        (clonedVirtualDieline) => {
          clonedVirtualDieline.set({
            visible: true,
            id: BackgroundsLayers.REPLICABLE_PATTERN,
            indexConfig: {
              fragment: IndexConfigFragments.BOTTOM,
              index: IndexConfigFragmentBottom.REPLICABLE_PATTERN,
            },
          })
          resolve(clonedVirtualDieline)
        },
        ["id", "rotation"]
      )
    })
  }
}
