import React, { PropsWithChildren } from "react"
import styles from "./Tab.module.scss"
import backIconUrl from "../../../../assets/_icons/type-arrow-left.svg"
import cxBinder from "classnames/bind"
import { SvgIcon } from "../../../atoms/SvgIcon/SvgIcon"
import { TestsProps } from "../../../types"

const cx = cxBinder.bind(styles)

export interface TabProps extends TestsProps {
  title?: string
  backLink?: {
    label: string
    onClick: () => void
  }
  size?: "normal" | "wide"
}

export function Tab({
  children,
  title,
  backLink,
  e2eTarget = "tab",
  e2eTargetName,
  size = "normal",
}: PropsWithChildren<TabProps>) {
  return (
    <div
      className={cx("wrapper", size)}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div className={styles.body}>
        <div className={cx("tab")}>
          {backLink && (
            <button
              className={styles.back}
              onClick={backLink.onClick}
              e2e-target="button"
              e2e-target-name="back-to-previous-tab"
            >
              <SvgIcon url={backIconUrl} size="s" />
              {backLink.label}
            </button>
          )}
          {title && <h2 className={styles.title}>{title}</h2>}
          {children}
        </div>
      </div>
    </div>
  )
}
