import { Text } from "../../models/text"
import {
  AvailableColourModes,
  ColoursPreset,
} from "../../libs/products-render-config/types"
import { AllEditorEventsEmitter } from "../../events/editor.events"
import ProductDriver from "../../drivers/product.driver"
import { AssetsController, ProcessParams } from "./assets.controller"
import { PredefinedText } from "../../models/predefined-text"
import { PredefinedTextsStore } from "../../stores/predefined-texts.store"

export class TextsController extends AssetsController {
  private readonly predefinedTextsStore: PredefinedTextsStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    stores: {
      predefinedTextsStore: PredefinedTextsStore
    }
  ) {
    super(services)

    this.predefinedTextsStore = stores.predefinedTextsStore
  }

  public async preloadPredefinedTexts(): Promise<void> {
    await this.predefinedTextsStore.loadPredefinedTexts()
  }

  public async addText(text: Text): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      const vdEditor = this.productDriver.getVdEditor(editContext)

      await vdEditor.assetsModule.addText(spaceId, text, this.shouldSelectAsset)

      this.touchDesign()
    })
  }

  public async addPredefinedText(
    predefinedText: PredefinedText
  ): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      this.predefinedTextsStore.setIsLoadingSinglePredefinedText(true)
      const asset = await predefinedText.getAssetData()

      if (!asset) {
        console.error(
          "The predefined text shouldn't be published, because it has no asset file!"
        )

        this.predefinedTextsStore.setIsLoadingSinglePredefinedText(false)

        return
      }

      const vdEditor = this.productDriver.getVdEditor(editContext)

      await vdEditor.assetsModule.addPredefinedText(
        spaceId,
        { asset, predefinedText },
        this.shouldSelectAsset
      )

      this.touchDesign()

      this.predefinedTextsStore.setIsLoadingSinglePredefinedText(false)
    })
  }

  public isMonochromatic(): boolean {
    return [
      AvailableColourModes.MONOCHROME,
      AvailableColourModes.MONO_PANTONE,
    ].includes(this.getColoursPreset().mode)
  }

  public getPreviewUrl(text: PredefinedText) {
    const preview = text.previews[0]

    return preview?.formats.thumbnail.url
  }

  private getColoursPreset(): ColoursPreset {
    return this.productDriver.state.productRenderPilot.getColoursPreset()
  }
}
