import React from "react"
import styles from "./Sidebar.module.scss"
import { Skeleton } from "../../atoms/Skeleton/Skeleton"
import _times from "lodash/times"

export function SidebarLoader() {
  return (
    <nav className={styles.sidebar}>
      {_times(6, (index) => {
        return (
          <div key={index} className={styles.item}>
            <Skeleton width={20} height={20} borderRadius={666} />
            <Skeleton width={50} height={10} />
          </div>
        )
      })}
    </nav>
  )
}
