import React from "react"
import Typography, { TType } from "../../../../atoms/Typography/Typography"
import styles from "./PickerOptionValue.module.scss"

interface PickerOptionValueProps {
  value: string
}
export const PickerOptionValue = (props: PickerOptionValueProps) => {
  const { value } = props

  return (
    <Typography
      className={styles["value"]}
      type={TType.Body15_350}
      e2eTargetName="selected-item-value"
    >
      {value}
    </Typography>
  )
}
