import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../../i18n"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { Shape } from "../../../../../../models/shape"
import { Notification } from "../../../../atoms/Notification/Notification"

const i18n = I18N.editorTools.fsc

export const FscShapeList = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.fscCertificate])

  if (!containerSet) {
    return null
  }

  const { fscCertificate } = containerSet

  if (!fscCertificate.available) {
    return null
  }

  const { fscCertificateStore, fscCertificateUiController } = fscCertificate
  const {
    isShapeListVisible,
    isActiveSpaceAvailableForFsc,
    isReplacementWarningVisible,
  } = fscCertificateUiController
  const { isSelected, shapes } = fscCertificateStore

  const onShapeClick = (shape: Shape) => {
    fscCertificateUiController.addFscShape(shape)
  }

  if (!isSelected || !isShapeListVisible) {
    return null
  }

  if (!isActiveSpaceAvailableForFsc) {
    return (
      <Notification type="warning">{t(i18n.spaceUnavailable)}</Notification>
    )
  }

  return (
    <>
      {isReplacementWarningVisible && (
        <Notification type="warning">{t(i18n.alreadyAdded)}</Notification>
      )}

      <TileList cols={3}>
        {shapes.map((shape) => {
          return (
            <Tile
              key={shape.id}
              onClick={() => onShapeClick(shape)}
              analyticsSection="left-tab-certificates"
              analyticsType="thumbnail"
              analyticsName="shape"
              analyticsId={shape.title}
              e2eTarget="button"
              e2eTargetName="fsc-shape"
            >
              <figure>
                <img src={shape.src} alt={shape.title} />
              </figure>
            </Tile>
          )
        })}
      </TileList>
    </>
  )
})
