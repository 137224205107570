import React from "react"
import cxBinder from "classnames/bind"
import { PriceDisplayOptions, QuantityItem } from "../../types"
import { PickerValueOptionsToggle } from "./PickerValueOptionsToggle"
import { PickerValuePresenter } from "./PickerValuePresenter"
import styles from "./PickerValueContainer.module.scss"

const cx = cxBinder.bind(styles)

interface PickerValueContainerProps {
  consise?: boolean
  isDisabled?: boolean
  isOpen: boolean
  onToggleOpen: (value: boolean) => void
  placeholder?: string
  value: QuantityItem | undefined
  withPulsatingGlow: boolean
  priceDisplayOptions: PriceDisplayOptions
}
export const PickerValueContainer = (props: PickerValueContainerProps) => {
  const {
    consise,
    isDisabled,
    isOpen,
    onToggleOpen,
    placeholder,
    value,
    withPulsatingGlow,
    priceDisplayOptions
  } = props

  const { fixedPromo } = priceDisplayOptions

  const handleToggleOpen = () => {
    if (!isDisabled) {
      onToggleOpen(!isOpen)
    }
  }

  return (
    <>
      <div
        onClick={handleToggleOpen}
        className={cx("container", {
          "container--active": isOpen,
          "container--disabled": isDisabled,
          "container--with-glow": withPulsatingGlow
        })}
      >
        <div
          className={styles["value-wrapper"]}
          e2e-target-name="selected-item"
        >
          <PickerValuePresenter
            value={value}
            consise={consise}
            placeholder={placeholder}
            fixedPromo={fixedPromo}
          />
        </div>
        <PickerValueOptionsToggle
          isOpen={isOpen}
          onClick={handleToggleOpen}
          isDisabled={isDisabled}
        />
      </div>
    </>
  )
}
