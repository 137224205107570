import { BadgeColorTypes } from "../../../atoms/Badge/Badge"
import type {
  BasicQuantityItem,
  CalculateQuantityItem,
  PriceDisplayOptions,
  PriceFormatter
} from "../types"
import { QuantityItemType } from "../types"

const formatDeal = (deal: number): string | undefined => {
  const dealFormatted = (deal * 100).toFixed(0)
  return dealFormatted === "0" ? undefined : `${-(deal * 100).toFixed(0)}%`
}

interface CreateBasicQuantityItemParams
  extends Partial<Omit<BasicQuantityItem, "price" | "discount">> {
  quantity: string
  price?: number
  piecePrice?: number
  promoPrice?: number
  discount?: number
  currency?: string
  outOfStock?: boolean
}

export const createBasicQuantityItem = (
  params: CreateBasicQuantityItemParams,
  options: PriceDisplayOptions = {},
  priceFormatter?: PriceFormatter,
  piecePriceFormatter?: PriceFormatter
): BasicQuantityItem => {
  const {
    quantity,
    price,
    promoPrice,
    piecePrice,
    discount,
    badge,
    badgeColor = BadgeColorTypes.LightSquash,
    currency,
    outOfStock
  } = params
  const { withPrice, withPricePerPiece, withDeal, withTax } = options

  const formattedPrice =
    withPrice && price && currency && priceFormatter
      ? priceFormatter(price, currency, withTax)
      : undefined

  const formattedPiecePrice =
    withPricePerPiece && piecePrice && currency && piecePriceFormatter
      ? piecePriceFormatter(piecePrice, currency, withTax)
      : undefined

  const formattedPromoPrice =
    withPrice && promoPrice && currency && priceFormatter
      ? priceFormatter(promoPrice, currency, withTax)
      : undefined

  return {
    quantity,
    price: formattedPrice,
    piecePrice: formattedPiecePrice,
    promoPrice: formattedPromoPrice,
    discount: withDeal && discount ? formatDeal(discount) : undefined,
    badge,
    badgeColor,
    type: QuantityItemType.Basic,
    outOfStock
  }
}

interface CreateCalculateQuantityItemParams
  extends Partial<CalculateQuantityItem> {
  quantity: string
}

export const createCalculateQuantityItem = (
  params: CreateCalculateQuantityItemParams
): CalculateQuantityItem => {
  const { quantity, label = "", loadingLabel = "", isLoading = false } = params

  return {
    quantity,
    label,
    loadingLabel,
    isLoading,
    type: QuantityItemType.Calculate
  }
}
