import { computed } from "mobx"
import { findProductPropertyBySku } from "../libs/products-render-config/helpers"
import { ProductDriver } from "../drivers/product.driver"
import { ProductStore } from "../stores/product.store"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { EditContext } from "../libs/products-render-config/types"
import { AvailableProperty } from "@ph/product-api"
import { ProductPricingStore } from "../stores/product-pricing.store"
import { EcommerceStore } from "../stores/ecommerce.store"

export class PrintingModeUiController {
  private readonly productDriver: ProductDriver
  private readonly ecommerceStore?: EcommerceStore
  private readonly productPricingStore?: ProductPricingStore

  constructor(
    services: {
      productDriver: ProductDriver
    },
    stores: {
      ecommerceStore?: EcommerceStore
      productPricingStore?: ProductPricingStore
    }
  ) {
    this.productDriver = services.productDriver
    this.ecommerceStore = stores.ecommerceStore
    this.productPricingStore = stores.productPricingStore
  }

  @computed
  public get isSceneDisabled(): boolean {
    if (this.productRenderPilot.getEditorMode() !== "editor") {
      return false
    }

    const { activeContext, isDesignPreviewMode } = this.productDriver.state

    return (
      !isDesignPreviewMode &&
      this.productRenderPilot.isEditContextDisabled(activeContext)
    )
  }

  @computed
  public get additionallyPaidEditContext(): EditContext | undefined {
    return this.productRenderPilot.getAdditionallyPaidEditContext()
  }

  @computed
  public get isPrintActive(): boolean {
    if (!this.additionallyPaidEditContext) {
      return false
    }

    return this.productRenderPilot.isPrintActiveFor(
      this.additionallyPaidEditContext
    )
  }

  @computed
  public get priceDiff(): number | undefined {
    if (
      !this.productPricingStore ||
      !this.ecommerceStore ||
      !this.availablePrintingMode
    ) {
      return
    }

    const quantity = this.ecommerceStore?.selectedQuantity
    const currentProduct = this.productDriver.productStore.product
    const newProduct = currentProduct.reload(
      this.availablePrintingMode.sku,
      this.productDriver.productStore.customization
    )

    const currentPrice = this.productPricingStore.getUnitPrice(
      currentProduct,
      quantity
    ).value
    const newPrice = this.productPricingStore.getUnitPrice(
      newProduct,
      quantity
    ).value

    if (!currentPrice || !newPrice) {
      return
    }

    return (newPrice - currentPrice) * quantity
  }

  public async togglePrintingMode(): Promise<void> {
    if (!this.availablePrintingMode) {
      return
    }

    await this.productDriver.changeSku(
      this.availablePrintingMode.sku,
      this.productStore.customization
    )
  }

  @computed
  private get availablePrintingMode(): AvailableProperty | undefined {
    const modes = this.productRenderPilot.getAvailablePrintingModes()
    const currentMode = findProductPropertyBySku(
      modes,
      this.productStore.productSku
    )

    return modes.find((colorMode) => colorMode.id !== currentMode?.id)
  }

  private get productStore(): ProductStore {
    return this.productDriver.productStore
  }

  @computed
  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }
}
