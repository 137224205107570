import { BoundingBox, Point, Segment } from "./types"
import { SvgElement } from "./svg-elements/svg-element"

export class BoundingBoxCalculator {
  public constructor(private readonly elements: SvgElement[]) {}

  public call(): BoundingBox {
    const { start, end } = this.calculateExtremePoints()
    const { width, height } = this.calculateSize(start, end)
    const center = this.calculateCenterPoint(width, height, start)

    return {
      start,
      end,
      center,
      width,
      height,
    }
  }

  private calculateCenterPoint(
    width: number,
    height: number,
    start: Point
  ): Point {
    const x = start.x + width / 2
    const y = start.y + height / 2

    return {
      x,
      y,
    }
  }

  private calculateSize(
    start: Point,
    end: Point
  ): { width: number; height: number } {
    const width = end.x - start.x
    const height = end.y - start.y

    return { width, height }
  }

  private calculateExtremePoints(): Segment {
    const start = { x: Infinity, y: Infinity }
    const end = { x: -Infinity, y: -Infinity }

    for (const element of this.elements) {
      const extremePoints = element.getExtremePoints()

      for (const point of extremePoints) {
        start.x = Math.min(start.x, point.x)
        start.y = Math.min(start.y, point.y)

        end.x = Math.max(end.x, point.x)
        end.y = Math.max(end.y, point.y)
      }
    }

    return { start, end }
  }
}
