import React from "react"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import packhelpLogo from "../../../assets/packhelp-logo.svg"
import styles from "./Logo.module.scss"

export function Logo() {
  const [containerSet] = useContainerSet((c) => [c.envUtil])

  if (!containerSet) {
    return null
  }

  const { appConfig } = containerSet.envUtil
  const { logo } = appConfig.branding
  const url = appConfig.locale.getUrl("baseUrl")

  return (
    <a
      className={styles.logo}
      href={url}
      analytics-section="top-navigation-bar"
      analytics-type="icon"
      analytics-name="logo"
      e2e-target="link"
      e2e-target-name="logo"
    >
      <img src={logo || packhelpLogo} alt="" />
    </a>
  )
}
