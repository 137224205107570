import { isDataUrl } from "./check-if-base64"

export function prepareAssetUrl(url: string): string {
  if (isDataUrl(url) || !isCacheBusterRequired(url)) {
    return url
  }

  const preparedUrl = new URL(url)
  preparedUrl.searchParams.append(
    "cache_buster",
    Math.random().toString(36).substring(7)
  )

  return preparedUrl.toString()
}

/**
 * We should not add cache buster to directus cdn url.
 *
 * All other assets should be loaded as before (with cache buster),
 * as there are some problems in loading them without it.
 *
 * TODO: This need to be investigated in the near future.
 */
function isCacheBusterRequired(url: string): boolean {
  if (url.startsWith(globalThis.appConfig.api.assets.cdnBaseUrl)) {
    return false
  }

  return true
}
