import THREE from "../../../libs/vendors/THREE"
import { CameraSettings } from "../../products-render-config/types"

export function isMesh(object?: THREE.Object3D | null): object is THREE.Mesh {
  return object instanceof THREE.Mesh && object.isMesh
}

export function isGroup(object?: THREE.Object3D | null): object is THREE.Group {
  return object?.type === "Group"
}

export type BoundingPoint = "auto" | "max" | "min"

export interface SvgModelSettings {
  blendOpacity?: number
  cameraSettings?: Record<string, CameraSettings | undefined>
  anisotropy?: number
}

export interface SvgSpaceConfig {
  parent: string
  folding: {
    axis: THREE.Vector3
    angle: number
    step: number
    boundingPoint: BoundingPoint
    shifting: boolean
    hidden: boolean
  }
}

export interface SvgModelConfig {
  thickness: number
  rotation: Record<string, number>
  folding: {
    target: Record<string, number>
  }
  spaces: Record<string, SvgSpaceConfig>
}

export interface SerializedSvgSpaceConfig {
  parent?: string
  folding?: {
    axis?: string
    angle?: number
    step?: number
    boundingPoint?: string
    shifting?: boolean
    hidden?: boolean
  }
}

export interface SerializedSvgModelConfig {
  thickness?: number
  rotation?: Record<string, number>
  folding?: {
    target?: Record<string, number>
  }
  spaces?: Record<string, SerializedSvgSpaceConfig>
}

export interface GeometryConfig {
  thickness: number
}

export interface Size {
  width: number
  height: number
}

export interface Folding {
  steps: number
  percentage: number
  angles: Record<string, number>
}
