import { CanvasObjectControllable } from "./canvas-object-controllable.interface"
import { ImageObjectController } from "./image-object.controller"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { TextObjectController } from "./text-object.controller"
import { SvgImageObjectController } from "./svg-image-object.controller"
import { ShapeObjectController } from "./shape-object.controller"

export function isImageObjectController(
  controller: CanvasObjectControllable
): controller is ImageObjectController {
  return controller instanceof ImageObjectController
}

export function isSvgImageObjectController(
  controller: CanvasObjectControllable
): controller is SvgImageObjectController {
  return controller instanceof SvgImageObjectController
}

export function isTextObjectController(
  controller: CanvasObjectControllable
): controller is TextObjectController {
  return controller instanceof TextObjectController
}
export function isShapeObjectController(
  controller: CanvasObjectControllable
): controller is ShapeObjectController {
  return controller instanceof ShapeObjectController
}

export type MovementDirection = "up" | "down" | "left" | "right"

export type DuplicationOptions = {
  withRender: boolean
  newSpaceId?: SpaceId
}
