import {
  EditableObjectTypes,
  VirtualDielineObject,
} from "../../../../types/asset.types"
import { MaskType } from "../modules/assets-module/mask-controller/types"
import { PackhelpGroupType } from "../object-extensions/packhelp-objects"
import { TempEditableGroupObject } from "../modules/assets-module/editable-group-controller/types"
import { TempLayers } from "../types/render-engine.types"

export class VirtualDielineFilterer {
  public static filterForImport(
    objects: VirtualDielineObject[] = []
  ): VirtualDielineObject[] {
    let filteredObjects = this.filterInvalidObjects(objects)
    filteredObjects = this.filterUnpackedGroupObjects(filteredObjects)
    filteredObjects = this.clearEditModeSpecificAttributes(filteredObjects)

    return filteredObjects
  }

  public static filterForExport(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    let filteredObjects = this.filterInvalidObjects(objects)
    filteredObjects = this.clearEditModeSpecificAttributes(filteredObjects)

    return filteredObjects
  }

  public static getUngroupedObjects(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    const prefiltered = this.filterForExport(objects)

    return prefiltered.filter(
      (object) =>
        object.assetType !== undefined &&
        object.assetType !== EditableObjectTypes.assetGroup
    )
  }

  private static filterUnpackedGroupObjects(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    return objects.filter((object) => !object.groupId)
  }

  /**
   * We need to revert all edit mode specific attributes before export / import.
   *
   * Otherwise, if save is triggered when the object is in the edit mode some incorrect data may be exported.
   */
  private static clearEditModeSpecificAttributes(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    return objects.map((object) => {
      if (object.assetType === EditableObjectTypes.assetGroup) {
        for (const groupObject of object.objects!) {
          groupObject.opacity = 1
        }
      }

      if (!!object.maskParentId) {
        object.strokeWidth = 0
      }

      return object
    })
  }

  /**
   * In the past we have exported meh objects.
   * Recently, activeSelection type which fabric has
   * issues with, dtp might too.
   *
   * We also filterout objects like safe_zone_right
   *
   *  @see {VirtualDielineExporter.filterout}
   *
   */
  private static filterInvalidObjects(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    return objects.filter((obj) => {
      if (this.isTemporaryClippingMask(obj)) {
        return false
      }

      if (obj.assetType === EditableObjectTypes.assetGroup && obj.objects) {
        obj.objects = this.filterInvalidGroupObjects(obj.objects)
      }

      if (this.isActiveSelection(obj)) {
        console.error(
          "Exporting/importing an invalid object. PLEASE debug if you see this",
          obj
        )
        return false
      }

      if (!obj.id) {
        return true
      }

      if (this.isTemporaryObject(obj)) {
        return false
      }

      return true
    })
  }

  private static filterInvalidGroupObjects(
    objects: VirtualDielineObject[]
  ): VirtualDielineObject[] {
    return objects.filter((object) => {
      if (!object.assetType) {
        return false
      }

      if (this.isTemporaryGroupObject(object)) {
        return false
      }

      return true
    })
  }

  private static isTemporaryClippingMask(
    object: VirtualDielineObject
  ): boolean {
    return !!object.maskParentId && object.maskType === MaskType.clipping
  }

  private static isTemporaryObject(object: VirtualDielineObject): boolean {
    return Object.values(TempLayers).includes(object.id as TempLayers)
  }

  private static isTemporaryGroupObject(object: VirtualDielineObject): boolean {
    return Object.values(TempEditableGroupObject).includes(
      object.id as TempEditableGroupObject
    )
  }

  private static isActiveSelection(object: VirtualDielineObject): boolean {
    return object.type === PackhelpGroupType.activeSelection
  }
}
