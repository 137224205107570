import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { BackgroundImageUiController } from "../controllers/background-image-ui.controller"
import type { BackgroundImageStore } from "../stores/background-image.store"

async function importDeps() {
  const { BackgroundImageStore } = await import(
    "../stores/background-image.store"
  )
  const { BackgroundImageUiController } = await import(
    "../controllers/background-image-ui.controller"
  )

  return {
    BackgroundImageStore,
    BackgroundImageUiController,
  }
}

export async function provideBackgroundImageContainer(
  designAndProduct: DesignAndProductDriverContainer
): Promise<BackgroundImageContainer> {
  const { assetStore, productDriver } = designAndProduct

  const { BackgroundImageStore, BackgroundImageUiController } =
    await importDeps()

  const backgroundImageStore = new BackgroundImageStore()
  const backgroundImageUiController = new BackgroundImageUiController(
    backgroundImageStore,
    assetStore,
    productDriver
  )

  return {
    backgroundImageStore,
    backgroundImageUiController,
  }
}

export type BackgroundImageContainer = {
  backgroundImageStore: BackgroundImageStore
  backgroundImageUiController: BackgroundImageUiController
}
