import React from "react"
import styles from "./SwitcherLabel.module.scss"

interface SwitcherLabelProps {
  label: string
}

export function SwitcherLabel({ label }: SwitcherLabelProps) {
  return <div className={styles.label}>{label}</div>
}
