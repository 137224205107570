import _clone from "lodash/clone"
import _filter from "lodash/filter"
import _flattenDeep from "lodash/flattenDeep"
import _map from "lodash/map"

import { objectLeafsList } from "../../../js/libs/helpers/object-leafs-list"
import * as shared from "./shared/shared-consts"
import EuRegion from "./regions/eu"
import GbRegion from "./regions/gb"
import PlRegion from "./regions/pl"
import DeRegion from "./regions/de"
import NoRegion from "./regions/no"
import AtRegion from "./regions/at"
import ChRegion from "./regions/ch"
import CzRegion from "./regions/cz"
import FrRegion from "./regions/fr"
import ItRegion from "./regions/it"
import EsRegion from "./regions/es"
import NlRegion from "./regions/nl"
import RoRegion from "./regions/ro"
import SeRegion from "./regions/se"
import UsRegion from "./regions/us"

const GENERATED_CONFIG = {
  eu: EuRegion,
  no: NoRegion,
  gb: GbRegion,
  at: AtRegion,
  ch: ChRegion,
  de: DeRegion,
  pl: PlRegion,
  cz: CzRegion,
  fr: FrRegion,
  it: ItRegion,
  es: EsRegion,
  nl: NlRegion,
  ro: RoRegion,
  se: SeRegion,
  us: UsRegion,

  shared: {
    googleapisKey: shared.GOOGLEAPIS_KEY,
    gtagId: shared.GTAG_ID,

    productionTime: 21,

    links: {
      app: {
        emailConsent: "/api/v1/emails/save_with_consents",
        setAffiliationCookieUrl: "/set_affiliation",
      },
      landing: {},
    },

    retargetingConfig: {
      hybridRetargetingPixelId: "5b07e1854d506e90086463c0", // See: https://app.asana.com/0/649432849319259/704123722914866/f
    },

    availableCurrencies: ["EUR", "PLN", "GBP", "RON", "SEK", "USD"],

    pathBasedLocales: ["sv-se", "ro-ro", "nl-nl", "en-us"],

    countryToLocaleTable: {
      PL: "pl",
      AT: "de",
      DE: "de",
      CZ: "cz",
      FR: "fr",
      IT: "it",
      ES: "es",
      GB: "en",
      SE: "sv",
      RO: "ro",
      NL: "nl",
    },

    countryToRegionTable: {
      PL: "pl",
      AT: "de",
      DE: "de",
      CZ: "cz",
      FR: "fr",
      IT: "it",
      ES: "es",
      GB: "gb",
      SE: "se",
      RO: "ro",
      NL: "nl",
      US: "us",
    },

    localeToRegionTable: {
      en: "eu",
      at: "de",
      cs: "cz",
      cz: "cz",
      de: "de",
      es: "es",
      fr: "fr",
      it: "it",
      pl: "pl",
      gb: "gb",
      "sv-se": "se",
      "ro-ro": "ro",
      "nl-nl": "nl",
      "en-us": "us",

      // By Patryk Kabaj, during CH #24763.
      // Temporary solution to make Iterable work without
      // infecting Rails with RC's mess. The reason is that
      // when Iterable module calls RC methods it has a format
      // of 'se', 'ro', 'nl' but above you can see that these
      // are defined in a different format. We can hope
      // that since it maps to the same values it won't
      // change the behaviour in any way across the codebase.
      // I've grepped codebase and everyone looks up values by keys
      // and doesn't iterate over the localeToRegionTable keys or values.
      // Therefore, I assume this ugly patch a safe patch.
      // See conversation here:
      // https://packhelp.slack.com/archives/C01QPSF07EC/p1619165411015900
      sv: "se",
      se: "se",
      ro: "ro",
      nl: "nl",
      us: "us",
    },

    regionsToIso639LanguageCodes: {
      eu: "en",
      en: "en",
      pl: "pl",
      de: "de",
      cz: "cs",
      fr: "fr",
      it: "it",
      es: "es",
      gb: "en",
      se: "sv",
      ro: "ro",
      nl: "nl",
      no: "en",
      ch: "de",
      us: "en",
    },

    localesToIso639LanguageCodes: {
      en: "en",
      gb: "gb",
      de: "de",
      pl: "pl",
      fr: "fr",
      es: "es",
      it: "it",
      cs: "cz",
      "nl-nl": "nl",
      "ro-ro": "ro",
      "sv-se": "sv",
      "en-us": "en",
    },

    countriesToLocaleTable: {
      EU: "en",
      PL: "pl",
      AT: "de",
      DE: "de",
      CZ: "cz",
      FR: "fr",
      IT: "it",
      ES: "es",
      GB: "en",
      SE: "sv",
      RO: "ro",
      NL: "nl",
      US: "en",
    },

    europeanUnionCountries: [
      "Austria",
      "Belgium",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Ireland",
      "Italy",
      "Latvia",
      "Lithuania",
      "Luxembourg",
      "Malta",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
    ],

    currencyIbans: {
      EUR: {
        account_number: "PL87 1050 1025 1000 0090 8021 2658",
        swift_code: "INGBPLPW",
      },
      GBP: {
        account_number: "PL08 1050 1025 1000 0090 8024 3083",
        swift_code: "INGBPLPW",
      },
      PLN: {
        account_number: "14 1050 1025 1000 0090 8013 5305",
        swift_code: "INGBPLPW",
      },
      RON: {
        account_number: "PL54 1050 1025 1000 0090 8119 0333",
        swift_code: "INGBPLPW",
      },
      SEK: {
        account_number: "PL76 1050 1025 1000 0090 8119 0325",
        swift_code: "INGBPLPW",
      },
    },

    regionFlags: {
      eu: "europeanunion",
      pl: "pl",
      de: "de",
      cz: "cz",
      fr: "fr",
      it: "it",
      es: "es",
      gb: "uk",
      nl: "nl",
      se: "se",
      ro: "ro",
    },

    emailDefaultHeaderLogo:
      "https://s3-eu-west-1.amazonaws.com/zpkjbackend/custom-files/packhelp-mailing.png",

    digitalDesignAllowedCountries: shared.DIGITAL_DESIGN_ALLOWED_COUNTRIES,
  },
}

function generateDomain(type, rconfig, env) {
  let envPart = env === "production" ? "" : `${env}.`
  let prefixPart = type === "app" ? "app." : ""
  return `${prefixPart}${envPart}${rconfig.domain.basename}.${rconfig.domain.tld}`
}

function allDomainsList() {
  return _flattenDeep(
    _map(GENERATED_CONFIG, (rconfig) => {
      // Custom domains will override generated ones
      if (rconfig.customDomains) {
        return objectLeafsList(rconfig.customDomains)
      }
      // If no domain config then it's shared config, return empty
      if (!rconfig.domain) return []
      // Generate app & landing domains
      return _map(shared.ENVS, (env) => [
        generateDomain("landing", rconfig, env),
        generateDomain("app", rconfig, env),
      ])
    })
  )
}

function autolinkerDomains() {
  return _filter(allDomainsList(), (domain) => !domain.match("local"))
}

function externalUnitedStatesRegionPageAddress() {
  // External US Region Page Link
  // CH-31193
  return "https://us.packhelp.com"
}

/**
 * Core products in PIM have different names than the ones defined in product config.
 *
 * We need to temporary map them to use correct product page urls.
 *
 * Later we migrate those urls to PIM.
 */
function addPimNameToProductPageUrlMapping(config) {
  for (const [region, regionConfig] of Object.entries(config)) {
    if (region === "shared") {
      continue
    }

    const productLinks = regionConfig.externalLinks.products

    regionConfig.externalLinks.products = {
      ...productLinks,
      "eco-mailer-box": productLinks["mailer-box-eco"],
      "eco-white-mailer-box": productLinks["mailer-box-eco-white"],
      "full-color-mailer-box": productLinks["mailer-box-full-color"],
      "eco-color-mailer-box": productLinks["mailer-box-eco-color"],
      "e-commerce-delivery-box": productLinks["delivery-mailer-box"],
      "tuck-end-box-classic-product-box": productLinks["product-box"],
      "two-piece-product-box": productLinks["product-box-two-pieces"],
      "classic-poly-mailer": productLinks["poly-mailer"],
      "expandable-kraft-mailer": productLinks["paper-mailer"],
      "printed-paper-bag": productLinks["paper-bag"],
      "custom-cardboard-envelope": productLinks["rigid-envelope"],
      "wine-box": productLinks["product-box-wine-box"],
      "packaging-tape": productLinks["packing-tape"],
      "white-on-kraft-mailer-box": productLinks["mailer-box-white-ink"],
      "stand-up-pouch-with-label": productLinks["doypack-bag"],
      labels: productLinks["square-label"],
      "thank-you-card": productLinks["printed-card"],
    }
  }
}

export default function getRegionConfig() {
  const config = _clone(GENERATED_CONFIG)
  config["shared"]["allExistingDomains"] = allDomainsList()
  config["shared"]["autolinkerDomains"] = autolinkerDomains()
  config["shared"]["externalUnitedStatesPageLink"] =
    externalUnitedStatesRegionPageAddress()

  addPimNameToProductPageUrlMapping(config)

  return config
}
