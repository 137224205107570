import React, { PropsWithChildren } from "react"
import { observer } from "mobx-react-lite"
import { FullScreenLoader } from "../../dsl/molecules/loaders/FullScreenLoader/FullScreenLoader"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../hooks/useTranslate"

export const Curtain = observer(({ children }: PropsWithChildren<{}>) => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { curtainUiController } = containerSet.ui
  const { isVisible, labelIntl } = curtainUiController

  if (isVisible) {
    return <FullScreenLoader label={t(labelIntl)} />
  }

  return children
})
