import { PackhelpRegion } from "./region-config"

export type ForcedRegionConfigInitialSettings = {
  region?: PackhelpRegion
  appType?: "landing" | "app"
}

type ForcedRegionConfigInitialSettingName =
  keyof ForcedRegionConfigInitialSettings

/**
 * Forces specific settings into the session storage.
 * The set variables are then used during region config init.
 * Designed to be used in development mode
 * as the changes affect the env it was fired on
 * (e.g. checkout.local.packhelp.com or editor.local.packhelp.com)
 */
export const setForcedRegionConfigSetting = <
  T extends ForcedRegionConfigInitialSettingName = ForcedRegionConfigInitialSettingName
>(
  param: T,
  value: ForcedRegionConfigInitialSettings[T]
) => {
  sessionStorage.setItem(param, value || "")
}

export const getForcedRegionConfigSetting = (
  param: ForcedRegionConfigInitialSettingName
) => sessionStorage.getItem(param)
