import React from "react"
import { observer } from "mobx-react-lite"
import { Switcher } from "../../../atoms/Switcher/Switcher"
import { SwitcherItem } from "../../../atoms/Switcher/SwitcherItem"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.editorMode

export const HeaderEditorModeSwitcher = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
    c.ui,
  ])

  if (!containerSet) {
    return null
  }

  const { editorModeController } = containerSet.ui
  const {
    activeMode,
    isEditorModeAvailable,
    isSkippingDesignAvailable,
    loadingMode,
  } = editorModeController

  const onEditorClick = () => {
    editorModeController.switchToEditorMode()
  }

  const onDielineClick = () => {
    editorModeController.switchToDbyMode()
  }

  const onSkipDesignClick = () => {
    editorModeController.toggleIsSkippingDesign()
  }

  return (
    <Switcher>
      {isEditorModeAvailable && (
        <SwitcherItem
          label={t(i18n.editor)}
          active={activeMode === "editor"}
          onClick={onEditorClick}
          loading={loadingMode === "editor"}
          analyticsSection="top-navigation-bar"
          analyticsType="tab"
          analyticsName="editor"
          e2eTarget="edit-mode-switcher"
          e2eTargetName="editor"
        />
      )}

      <SwitcherItem
        label={t(i18n.dieline)}
        active={activeMode === "dby"}
        onClick={onDielineClick}
        loading={loadingMode === "dby"}
        analyticsSection="top-navigation-bar"
        analyticsType="tab"
        analyticsName="dieline"
        e2eTarget="edit-mode-switcher"
        e2eTargetName="dieline"
      />

      {isSkippingDesignAvailable && (
        <SwitcherItem
          label={t(i18n.skipDesign)}
          active={activeMode === "skip-design"}
          onClick={onSkipDesignClick}
          analyticsSection="top-navigation-bar"
          analyticsType="tab"
          analyticsName="skip-design"
          e2eTarget="edit-mode-switcher"
          e2eTargetName="skip-design"
        />
      )}
    </Switcher>
  )
})
