import { BaseController } from "./base.controller"
import { PackhelpEditableObject } from "../../../../object-extensions/packhelp-objects"
import {
  CanvasEvent,
  CanvasEventObjectModifiedAction,
  CanvasObjectEvent,
} from "../../../../../../../events/partials/domain.events"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { AlignmentHelper } from "../../helpers/alignment.helper"
import { SnappingController } from "./snapping.controller"

export class AlignmentController extends BaseController {
  constructor(
    protected readonly canvasObject: PackhelpEditableObject,
    protected readonly virtualDielineEditor: VirtualDielineEditor,
    protected readonly snappingController: SnappingController
  ) {
    super(canvasObject)
  }

  public alignHorizontal() {
    const currentObject = this.getCurrentObject()
    AlignmentHelper.alignHorizontal(
      this.virtualDielineEditor.dielineNavigator,
      currentObject
    )

    this.snappingController.snapToCenterOfXaxisOfSpace()

    this.canvas.fire(CanvasEvent.objectModified, {
      target: currentObject,
      action: CanvasEventObjectModifiedAction.move,
    })
    currentObject.fire(CanvasObjectEvent.moved)

    this.canvas.renderAll()
  }

  public alignVertical() {
    const currentObject = this.getCurrentObject()
    AlignmentHelper.alignVertical(
      this.virtualDielineEditor.dielineNavigator,
      currentObject
    )

    this.snappingController.snapToCenterOfYaxisOfSpace()

    this.canvas.fire(CanvasEvent.objectModified, {
      target: currentObject,
      action: CanvasEventObjectModifiedAction.move,
    })
    currentObject.fire(CanvasObjectEvent.moved)
    this.canvas.renderAll()
  }
}
