import { Materials } from "./reforestation-calculator.types"
import { SupportedCurrency } from "shared-libs/src/configs/region-config/interfaces/js/region-config.types"

export const DEFAULT_MATERIAL_WASTE_MULTIPLIER = 1.25
export const DEFAULT_CURRENCY_MULTIPLIER = 1
export const DEFAULT_TREES_PER_MATERIAL_KG_MULTIPLIER = 0.024

export const MultiplicationFactorForMaterialWaste = new Map([
  [Materials.Cardboard_Cardstock, 1.25],
  [Materials.Cardstock_Coated, 1.25],

  [Materials.Cardboard_White_One_Side, 1.5],
  [Materials.Cardboard_Coated_White_Inside, 1.5],
  [Materials.Cardboard_Natural, 1.5],
  [Materials.Cardboard_White, 1.5],
  [Materials.Cardboard_White, 1.5],

  [Materials.Paper_Natural, 1.5],
  [Materials.Paper_White, 1.5],

  [Materials.Paper_Kraft, 1.25],

  [Materials.Tissue_Paper, 1.25],

  [Materials.Kraft, 1.5],
])

export const MultiplicationFactorForCurrency = new Map([
  [SupportedCurrency.PLN, 3.89],
  [SupportedCurrency.USD, 1],
  [SupportedCurrency.EUR, 1],
  [SupportedCurrency.GBP, 1],
])
