import { action, observable, makeObservable, computed } from "mobx"
import { ee, eventTree } from "../events/editor.events"
import { EditorMode, SidebarTab } from "../libs/products-render-config/types"
import productIconUrl from "../ui/assets/_icons/product.svg"
import productFillIconUrl from "../ui/assets/_icons/product-fill.svg"
import configurationIconUrl from "../ui/assets/_icons/configuration.svg"
import configurationFillIconUrl from "../ui/assets/_icons/configuration-fill.svg"
import logoIconUrl from "../ui/assets/_icons/heart.svg"
import logoFillIconUrl from "../ui/assets/_icons/heart-fill.svg"
import templatesIconUrl from "../ui/assets/_icons/star.svg"
import templatesFillIconUrl from "../ui/assets/_icons/star-fill.svg"
import patternsIconUrl from "../ui/assets/_icons/patterns.svg"
import textIconUrl from "../ui/assets/_icons/text.svg"
import textFillIconUrl from "../ui/assets/_icons/text-fill.svg"
import paletteIconUrl from "../ui/assets/_icons/palette.svg"
import elementsIconUrl from "../ui/assets/_icons/elements.svg"
import elementsFillIconUrl from "../ui/assets/_icons/elements-fill.svg"
import certificatesIconUrl from "../ui/assets/_icons/certificates.svg"
import certificatesFillIconUrl from "../ui/assets/_icons/certificates-fill.svg"
import devToolsIconUrl from "../ui/assets/_icons/dev-tools.svg"
import dbyIconUrl from "../ui/assets/_icons/dby.svg"
import uploadIconUrl from "../ui/assets/_icons/upload.svg"
import uploadFillIconUrl from "../ui/assets/_icons/upload-fill.svg"
import { I18N } from "../ui/i18n"
import ProductDriver from "../drivers/product.driver"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { EcommerceApiEnvConfig } from "../app-config/types"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"

const i18n = I18N.tabs

interface SidebarItem {
  tab: SidebarTab
  nameIntl: string
  iconUrl: string
  iconActiveUrl?: string
  analyticsName: string
}

export class SidebarStore {
  @observable public selectedTab?: SidebarTab

  private readonly productDriver: ProductDriver
  private readonly uri: UrlManipulatorProvider
  private previousTab?: SidebarTab

  constructor(
    services: {
      productDriver: ProductDriver
      uri: UrlManipulatorProvider
    },
    private readonly availableFeatures: EcommerceApiEnvConfig["features"]["ecommerce"]
  ) {
    this.productDriver = services.productDriver
    this.uri = services.uri

    makeObservable(this)

    ee.on(eventTree.logo.plusClicked, () => this.selectTab("logo"))
    ee.on(eventTree.activeTab.close, this.closeTab.bind(this))
    ee.on(eventTree.activeObject.selected, this.closeCanvasObjectTab.bind(this))
    ee.on(
      eventTree.activeObject.deselected,
      this.closeCanvasObjectTab.bind(this)
    )
  }

  @computed
  public get availableItems(): SidebarItem[] {
    return this.items.filter((item) => this.availableTabs.includes(item.tab))
  }

  public isTabAvailable(tab: SidebarTab): boolean {
    return this.availableTabs.includes(tab)
  }

  public selectTab(tab: SidebarTab) {
    if (!this.availableTabs.includes(tab)) {
      return
    }

    this.setSelectedTab(tab)
  }

  public isTabSelected(tab?: SidebarTab) {
    if (tab) {
      return this.selectedTab === tab
    }

    return !!this.selectedTab
  }

  public isSubTabSelected(tab?: SidebarTab): boolean {
    if (this.selectedTab === "size" && tab === "configuration") {
      return true
    }

    return false
  }

  public closeTab(force = false) {
    if (force) {
      return this.setSelectedTab()
    }

    if (this.selectedTab === "size") {
      return this.setSelectedTab("configuration")
    }

    if (this.selectedTab === "fonts" || this.selectedTab === "textOptions") {
      return this.setSelectedTab(this.previousTab)
    }

    this.setSelectedTab()
    this.previousTab = undefined
  }

  public toggleTab(tab: SidebarTab) {
    if (!this.isTabSelected(tab)) {
      this.selectTab(tab)
    } else {
      this.closeTab()
    }
  }

  @action
  private setSelectedTab(tab?: SidebarTab) {
    if (this.selectedTab === tab) {
      return
    }

    if (tab && this.selectedTab && !this.isCanvasObjectTab(this.selectedTab)) {
      this.previousTab = this.selectedTab
    }

    ee.emit(eventTree.tab.tabChanged, tab, this.selectedTab)

    this.selectedTab = tab

    if (tab) {
      ee.emit(eventTree.keyboard.setScope, "active-tab")
    } else {
      ee.emit(eventTree.keyboard.clearScope, "active-tab")
    }
  }

  private closeCanvasObjectTab(): void {
    if (this.selectedTab && this.isCanvasObjectTab(this.selectedTab)) {
      this.closeTab()
    }
  }

  private isCanvasObjectTab(tab: SidebarTab) {
    return tab && ["fonts", "textOptions"].includes(tab)
  }

  @computed
  private get availableTabs(): SidebarTab[] {
    const { tabs } = this.productRenderPilot.uiConfig

    return Object.keys(tabs).filter((tab) => {
      if (!tabs[tab]) {
        return false
      }

      if (tab === "configuration") {
        return this.availableFeatures.productConfigurator
      }

      if (tab === "templatesAndPatterns") {
        return (
          this.availableFeatures.templates || this.availableFeatures.patterns
        )
      }

      if (tab === "certificates") {
        return this.availableFeatures.fsc || this.availableFeatures.trees
      }

      if (tab === "product") {
        return this.availableFeatures.productSwitcher
      }

      if (tab === "devTools") {
        return this.uri.isDevToolsMode()
      }

      return true
    }) as SidebarTab[]
  }

  private get items(): SidebarItem[] {
    return [
      {
        tab: "product",
        nameIntl: i18n.product,
        iconUrl: productIconUrl,
        iconActiveUrl: productFillIconUrl,
        analyticsName: "product",
      },
      {
        tab: "configuration",
        nameIntl: i18n.configuration,
        iconUrl: configurationIconUrl,
        iconActiveUrl: configurationFillIconUrl,
        analyticsName: "configuration",
      },
      {
        tab: "dby",
        nameIntl: i18n.dby,
        iconUrl: dbyIconUrl,
        analyticsName: "add-design",
      },
      {
        tab: "logo",
        nameIntl: i18n.logo,
        iconUrl: logoIconUrl,
        iconActiveUrl: logoFillIconUrl,
        analyticsName: "logo",
      },
      {
        tab: "logoPlaceholder",
        nameIntl: i18n.logoPlaceholder,
        iconUrl: logoIconUrl,
        iconActiveUrl: logoFillIconUrl,
        analyticsName: "logo-placeholder",
      },
      {
        tab: "templatesAndPatterns",
        nameIntl: i18n.templates,
        iconUrl: templatesIconUrl,
        iconActiveUrl: templatesFillIconUrl,
        analyticsName: "templates",
      },
      {
        tab: "replicablePatterns",
        nameIntl: i18n.replicablePatterns,
        iconUrl: patternsIconUrl,
        analyticsName: "pattern",
      },
      {
        tab: "text",
        nameIntl: i18n.text,
        iconUrl: textIconUrl,
        iconActiveUrl: textFillIconUrl,
        analyticsName: "text",
      },
      {
        tab: "background",
        nameIntl: i18n.background,
        iconUrl: paletteIconUrl,
        analyticsName: "background",
      },
      {
        tab: "elements",
        nameIntl: i18n.elements,
        iconUrl: elementsIconUrl,
        iconActiveUrl: elementsFillIconUrl,
        analyticsName: "elements",
      },
      {
        tab: "upload",
        nameIntl: i18n.upload,
        iconUrl: uploadIconUrl,
        iconActiveUrl: uploadFillIconUrl,
        analyticsName: "upload",
      },
      {
        tab: "certificates",
        nameIntl: i18n.certificates,
        iconUrl: certificatesIconUrl,
        iconActiveUrl: certificatesFillIconUrl,
        analyticsName: "certificates",
      },
      {
        tab: "devTools",
        nameIntl: i18n.devTools,
        iconUrl: devToolsIconUrl,
        analyticsName: "dev-tools",
      },
    ]
  }

  @computed
  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  @computed
  private get editorMode(): EditorMode {
    return this.productRenderPilot.getEditorMode()
  }
}
