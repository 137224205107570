import THREE from "../../../../libs/vendors/THREE"
import { isMesh, ModelHighlightDefinition } from "../types"
import {
  EditContext,
  SpaceId,
} from "../../../../libs/products-render-config/types"
import { CANVAS_DIM } from "../../../types"

export class HighlightCalculator {
  constructor(
    private readonly mountPoint: HTMLElement,
    private readonly camera: THREE.PerspectiveCamera
  ) {}

  public call(
    model: THREE.Object3D,
    e: MouseEvent | Touch
  ): ModelHighlightDefinition {
    const mousePosition = this.getMousePosition(e.clientX, e.clientY)
    const intersection = this.getIntersection(model, mousePosition)

    const uv = intersection?.uv
    const object = intersection?.object

    if (!uv || !object || !isMesh(object)) {
      return {
        x: 0,
        y: 0,
      }
    }

    const material = object.material as THREE.MeshPhongMaterial

    if (Array.isArray(material)) {
      const materialIndex = intersection.face?.materialIndex || 0
      const editContext = material[materialIndex].name as EditContext

      return {
        spaceId: object.name as SpaceId,
        editContext: editContext as EditContext,
        x: uv.x * CANVAS_DIM,
        y: uv.y * CANVAS_DIM,
      }
    }

    material.map?.transformUv(uv)

    return {
      editContext: object.userData.editContext as EditContext,
      x: uv.x * CANVAS_DIM,
      y: uv.y * CANVAS_DIM,
    }
  }

  private getIntersection(
    model: THREE.Object3D,
    point: THREE.Vector2
  ): THREE.Intersection | undefined {
    const intersections = this.getIntersections(model, point)
    const intersection = intersections.find(
      (intersection) => !intersection.object.name.includes("flap")
    )

    if (intersection) {
      return intersection
    }

    return intersections[0]
  }

  private getIntersections(
    model: THREE.Object3D,
    point: THREE.Vector2
  ): THREE.Intersection[] {
    const raycaster = new THREE.Raycaster()
    const mouse = new THREE.Vector2(point.x * 2 - 1, -(point.y * 2) + 1)

    raycaster.setFromCamera(mouse, this.camera)

    return raycaster.intersectObject(model, true)
  }

  private getMousePosition(x: number, y: number): THREE.Vector2 {
    const rect = this.mountPoint.getBoundingClientRect()

    return new THREE.Vector2().fromArray([
      (x - rect.left) / rect.width,
      (y - rect.top) / rect.height,
    ])
  }
}
