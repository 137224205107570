import { EnvConfig, InvalidEnvConfig } from "./types"
import { IntegrationsConfig } from "./configs/integrations.config"
import { LocaleConfig } from "./configs/locale.config"
import { ApiConfig } from "./configs/api.config"
import { schema } from "./schema"
import { SocialAuthConfig } from "./configs/social-auth.config"
import _cloneDeep from "lodash/cloneDeep"
import { BrandingConfig } from "./configs/branding.config"

export class AppConfig {
  public readonly locale: LocaleConfig
  public readonly integrations: IntegrationsConfig
  public readonly api: ApiConfig
  public readonly socialAuth: SocialAuthConfig
  public readonly branding: BrandingConfig

  private readonly config: EnvConfig

  constructor(config: EnvConfig) {
    this.config = _cloneDeep(config)

    this.clear(this.config)
    this.validate(this.config)

    this.locale = new LocaleConfig(this.config.locale)
    this.integrations = new IntegrationsConfig(this.config.integrations)
    this.api = new ApiConfig(this.config.api)
    this.socialAuth = new SocialAuthConfig(this.config.socialAuth)
    this.branding = new BrandingConfig(this.config.branding)
  }

  private validate(config: EnvConfig) {
    const { error } = schema.validate(config)

    if (error) {
      throw new InvalidEnvConfig(error.message)
    }
  }

  private clear(config: EnvConfig) {
    for (const key of Object.keys(config)) {
      const value = config[key]

      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        typeof value.constructor !== "function"
      ) {
        this.clear(config[key])

        if (Object.keys(value).length === 0) {
          delete config[key]
        }
      }

      const isEmptyString = value === ""
      const isTemplateKey = typeof value === "string" && value.match(/{{.*}}/)

      if (isEmptyString || isTemplateKey) {
        delete config[key]
      }
    }
  }
}
