import React from "react"
import styles from "./TileLink.module.scss"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

interface TileButtonProps extends AnalyticsProps, TestsProps {
  label: string
  href: string
  iconUrl?: string
}

export function TileLink({
  label,
  iconUrl,
  href,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: TileButtonProps) {
  return (
    <a
      className={styles.link}
      href={href}
      target="_blank"
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {iconUrl && <SvgIcon url={iconUrl} size="xs" />}
      {label}
    </a>
  )
}
