import React, { useState } from "react"
import styles from "./BaseInput.module.scss"
import cxBinder from "classnames/bind"
import _memoize from "lodash/memoize"
import _debounce from "lodash/debounce"

const cx = cxBinder.bind(Object.assign({}, styles))

export interface BaseInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled?: boolean
  error?: boolean
  medium?: boolean
  handicapped?: boolean
  innerRef?: any
  searchPlaceholder?: string
  e2eTargetName?: string
  e2eTarget?: string
}

export const createOnKeyDownCallback = _memoize((setIsTyping) => () => {
  setIsTyping(true)
})

export const createOnKeyUpCallback = _memoize((setIsTyping) =>
  _debounce(() => {
    setIsTyping(false)
  }, 500)
)

const BaseInput = (props: BaseInputProps) => {
  const {
    error,
    medium,
    handicapped,
    searchPlaceholder,
    innerRef,
    placeholder,
    e2eTarget = "input",
    e2eTargetName,
    autocomplete,
    ...other
  }: any = props

  const [isTyping, setIsTyping] = useState<boolean>(false)

  const classNames = cx("base", {
    medium,
    error,
    handicapped,
    "base--is-typing": isTyping
  })

  if (innerRef) {
    other.ref = innerRef
  }
  return (
    <input
      className={classNames}
      {...other}
      placeholder={searchPlaceholder || placeholder}
      onKeyDown={createOnKeyDownCallback(setIsTyping)}
      onKeyUp={createOnKeyUpCallback(setIsTyping)}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    />
  )
}

export { BaseInput, BaseInput as default }
