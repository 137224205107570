import SegmentAnalytics from "../../../../libs/analytics/segment/segment-analytics"
import { trackThroughBackend } from "../../../../libs/analytics/segment/segment-analytics.service"
import LineItemModel from "../../../../libs/models/LineItem.model"

export enum CrossSellingSegmentEvents {
  CROSS_SELL_MODAL_VIEWED = "Cross Sell Modal Viewed",
  CROSS_SELL_MODAL_CLICKED = "Cross Sell Modal Button Clicked",
}

type CrossSellSegmentTypes = {
  eventData: {
    location: string
    button: string
  }
}

export const prepareCrossSellProductData = (newLineItem: LineItemModel) => {
  const product: any = newLineItem.getProduct()
  const order = newLineItem.order

  const productInfoFromProduct =
    SegmentAnalytics.getProductParamsFromProduct(product)

  return {
    checkout_id: order.number,
    currency: order.currency,
    discount: order.promotion_amount,
    order_id: order.id,
    promo_code: order.promo_code,
    price: newLineItem.getItemNettoPrice(),
    product_thumbnail: newLineItem.getThumbnail(),
    quantity: newLineItem.getQuantity(),
    value: newLineItem.getItemNettoPrice(),
    ...productInfoFromProduct,
  }
}

export const crossSellEventLocation = (
  isOutsideCart: boolean,
  productName?: string
) => {
  const object = isOutsideCart
    ? { location: "Editor/DBY", button: "Add to Cart" }
    : { location: "Cart", button: "Banner" }

  if (productName) {
    object["button"] = productName
  }

  return object
}

export const crossSellModalViewed = (lineItem: LineItemModel[], source) => {
  const segmentProductArray = []

  lineItem.forEach((item) => {
    segmentProductArray.push(prepareCrossSellProductData(item))
  })

  const segmentProductObject = Object.assign({}, segmentProductArray)

  trackThroughBackend({
    event: CrossSellingSegmentEvents.CROSS_SELL_MODAL_VIEWED,
    properties: {
      products: segmentProductObject,
      cross_location: source || "Cart",
    },
  })
}

export const crossSellModalButtonClicked = (
  item: LineItemModel,
  eventData: CrossSellSegmentTypes["eventData"]
) => {
  const segmentProductData = prepareCrossSellProductData(item)

  trackThroughBackend({
    event: CrossSellingSegmentEvents.CROSS_SELL_MODAL_CLICKED,
    properties: {
      products: segmentProductData,
      cross_button: eventData.button,
      cross_location: eventData.location,
    },
  })
}
