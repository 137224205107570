export function addDrawClipPathOnCache(fabric) {
  fabric.Object.prototype.drawClipPathOnCache = function (ctx) {
    const path = this.clipPath
    ctx.save()

    if (this.alphaModeClipping) {
      ctx.globalAlpha = 0.6
      ctx.globalCompositeOperation = "destination-out"
    } else if (path.inverted) {
      ctx.globalCompositeOperation = "destination-out"
    } else {
      ctx.globalCompositeOperation = "destination-in"
    }

    if (path.absolutePositioned) {
      const m = fabric.util.invertTransform(this.calcTransformMatrix())
      ctx.transform(m[0], m[1], m[2], m[3], m[4], m[5])
    }

    path.transform(ctx)
    ctx.scale(1 / path.zoomX, 1 / path.zoomY)
    ctx.drawImage(
      path._cacheCanvas,
      -path.cacheTranslationX,
      -path.cacheTranslationY
    )
    ctx.restore()
  }

  return fabric
}
