import React from "react"
import { LinkBase, LinkProps } from "./LinkBase"
import cxBinder from "classnames/bind"
import styles from "./Link.module.scss"

const cx = cxBinder.bind(styles)

export interface SimpleLinkProps extends LinkProps {
  className?: string
  underlined?: boolean
  disabled?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export interface ButtonLinkProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  underlined?: boolean
  disabled?: boolean
  active?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const Link = (props: SimpleLinkProps) => {
  const {
    children,
    className,
    underlined = true,
    disabled,
    e2eTarget = "link",
    e2eTargetName,
    ...other
  } = props

  const childProps = {
    className: cx({ base: true, simple: true, underlined, disabled }),
    "e2e-target": e2eTarget,
    "e2e-target-name": e2eTargetName,
    ...other
  }

  if (className) {
    childProps.className += ` ${className}`
  }

  return <LinkBase {...childProps}>{children}</LinkBase>
}

const LinkAsButton = (props: ButtonLinkProps) => {
  const {
    children,
    underlined = true,
    disabled,
    e2eTarget = "link",
    e2eTargetName,
    ...other
  } = props

  return (
    // @ts-ignore
    <button
      className={cx({
        base: true,
        simple: true,
        button: true,
        underlined,
        disabled
      })}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {children}
    </button>
  )
}

export { Link, LinkAsButton, Link as default }
