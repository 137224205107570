/** @format */
// product sku def
import SkuFinish from "./tissuePaperSkuDefinitions/SkuFinish";
import SkuMaterial from "./tissuePaperSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./tissuePaperSkuDefinitions/SkuPrintMode";
import SkuModel from "./tissuePaperSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./tissuePaperPartsDefinitions/PartFinish";
import PartMaterial from "./tissuePaperPartsDefinitions/PartMaterial";
import PartPrintMode from "./tissuePaperPartsDefinitions/PartPrintMode";
import PartModel from "./tissuePaperPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var TISSUE_PAPER_CONSTS = {
    SKU_BASE: "paper-",
    PRODUCT_TYPE: "tissuePaper",
    MATERIAL_ID_PAPER: "paper",
    DEFAULT_LINE_ITEM_RANGES: [240, 480, 720, 960, 1200, 1440, 1680, 2040],
};
export default Object.assign({}, TISSUE_PAPER_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
