import { AllEditorEventsEmitter } from "../../events/editor.events"
import ProductDriver from "../../drivers/product.driver"
import { AssetsController, ProcessParams } from "./assets.controller"
import { ImageAsset } from "../../models/image-asset"
import { AssetsStore } from "../../stores/assets.store"

export class ImagesController extends AssetsController {
  private readonly assetStore: AssetsStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    stores: {
      assetStore: AssetsStore
    }
  ) {
    super(services)

    this.assetStore = stores.assetStore
  }

  public async addImage(
    imageAsset: ImageAsset,
    options: {
      templateId?: number | null
    } = {}
  ): Promise<void> {
    await this.addAsset(async ({ editContext, spaceId }: ProcessParams) => {
      this.assetStore.setIsLoadingSingleImage(true)

      const vdEditor = this.productDriver.getVdEditor(editContext)

      await vdEditor.assetsModule.addImage(imageAsset, {
        spaceId,
        shouldSelect: this.shouldSelectAsset,
        templateId: options.templateId,
      })

      this.touchDesign()

      this.assetStore.setIsLoadingSingleImage(false)
    })
  }
}
