import fabric from "../../../libs/vendors/Fabric"
import { PackhelpObject } from "../vd-editor/object-extensions/packhelp-objects"
import { isObjectPath, isObjectRect } from "../../../types/asset.types"
import _compact from "lodash/compact"
import _isNil from "lodash/isNil"

const STEP: 1 | 2 | 3 | 5 | 10 = 10
const SUPPORTED_PATH_FUNCTIONS = ["M", "L"]

export class CollisionPointsCalculator {
  public static calculate(object: PackhelpObject): fabric.Point[] {
    if (isObjectRect(object)) {
      return CollisionPointsCalculator.calculateForRect(object)
    }

    if (isObjectPath(object)) {
      return CollisionPointsCalculator.calculateForPath(object)
    }

    // More to come :)
    // Circle, Polygon
    // Rotation support

    return object.getCoords(true, false)
  }

  private static calculateForPath(object: fabric.Path): fabric.Point[] {
    const path = object.path

    if (!path) {
      return object.getCoords(true, false)
    }

    const centerPoint = object.getCenterPoint()
    const { scaleX, scaleY } = object.getObjectScaling()

    return _compact(
      path.map((pathPart) => {
        const type = pathPart[0]
        const x = pathPart[1]
        const y = pathPart[2]

        // Curves are not supported at the moment
        if (!SUPPORTED_PATH_FUNCTIONS.includes(type)) {
          return
        }

        if (_isNil(x) || _isNil(y)) {
          return
        }

        const distanceX = x - centerPoint.x
        const distanceY = y - centerPoint.y
        const scaledDistanceDiffX = distanceX - distanceX * scaleX
        const scaledDistanceDiffY = distanceY - distanceY * scaleY

        return new fabric.Point(
          x - scaledDistanceDiffX,
          y - scaledDistanceDiffY
        )
      })
    )
  }

  private static calculateForRect(object: fabric.Rect): fabric.Point[] {
    // Rotation is not supported at the moment
    if (!isObjectRect(object) || object.angle || (!object.rx && !object.ry)) {
      return object.getCoords(true, false)
    }

    const width = object.getScaledWidth()
    const height = object.getScaledHeight()
    const left = object.left!
    const top = object.top!
    const rx = (object.rx! || object.ry!) * object.scaleX!
    const ry = (object.ry! || object.rx!) * object.scaleY!

    const topRightCornerPoints: fabric.Point[] = []
    const bottomRightCornerPoints: fabric.Point[] = []
    const bottomLeftCornerPoints: fabric.Point[] = []
    const topLeftCornerPoints: fabric.Point[] = []

    for (let angle = 0; angle <= 360; angle += STEP) {
      const { x, y } = CollisionPointsCalculator.getPointOnEllipse(
        rx,
        ry,
        angle
      )

      if (angle >= 0 && angle <= 90) {
        bottomRightCornerPoints.push(
          new fabric.Point(x + left + width - rx, y + top + height - ry)
        )
      }

      if (angle >= 90 && angle <= 180) {
        bottomLeftCornerPoints.push(
          new fabric.Point(x + left + rx, y + top + height - ry)
        )
      }

      if (angle >= 180 && angle <= 270) {
        topLeftCornerPoints.push(new fabric.Point(x + left + rx, y + top + ry))
      }

      if (angle >= 270 && angle <= 360) {
        topRightCornerPoints.push(
          new fabric.Point(x + left + width - rx, y + top + ry)
        )
      }
    }

    return [
      new fabric.Point(left + rx, top),
      new fabric.Point(left + width - rx, top),
      ...topRightCornerPoints,
      new fabric.Point(left + width, top + ry),
      new fabric.Point(left + width, top + height - ry),
      ...bottomRightCornerPoints,
      new fabric.Point(left + width - rx, top + height),
      new fabric.Point(left + rx, top + height),
      ...bottomLeftCornerPoints,
      new fabric.Point(left, top + height - ry),
      new fabric.Point(left, top + ry),
      ...topLeftCornerPoints,
    ]
  }

  private static getPointOnEllipse(
    rx: number,
    ry: number,
    angle: number
  ): { x: number; y: number } {
    const tan = Math.tan((angle / 360) * Math.PI)

    const x = (rx * (1 - tan ** 2)) / (1 + tan ** 2)
    const y = (ry * 2 * tan) / (1 + tan ** 2)

    return { x, y }
  }
}
