import scrollIntoView from "scroll-into-view-if-needed"
import { wrapperClassname as modalWrapperClassname } from "../../organisms/Modal/modal-legacy/LegacyModal"

export const isPhoneUserAgent = () => {
  const userAgent: string = navigator.userAgent

  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))

  return isAndroid() || isIos() || isOpera()
}

const isChildOf = (element: HTMLElement, parentClassname: string): boolean => {
  const parent = element.parentNode as HTMLElement
  // stop looking if nothing found
  return parent.tagName !== "BODY"
    ? (parent.classList && parent.classList.contains(parentClassname)) ||
        isChildOf(parent, parentClassname)
    : false
}

export const scrollToElement = (element: HTMLElement) => {
  const isInModal = isChildOf(element, modalWrapperClassname)

  const scroll = () => {
    scrollIntoView(element, {
      scrollMode: "if-needed",
      behavior: isInModal
        ? (actions) =>
            actions.forEach(({ el, top }) => {
              el.scrollTop = top
            })
        : "smooth"
    })
  }

  // wait for the keyboard to appear on mobile devices
  isPhoneUserAgent() ? setTimeout(scroll, 400) : scroll()
}
