import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import { CANVAS_DIM } from "../../../../../types"
import { Size } from "../../../types/render-engine.types"
import { ScaleHelper } from "../../assets-module/helpers/scale.helper"
import _ceil from "lodash/ceil"
import { RotationHelper } from "../../assets-module/helpers/rotation.helper"
import fabric from "../../../../../../libs/vendors/Fabric"
import { BackgroundLayerClippingHelper } from "../../assets-module/helpers/background-layer-clippinng.helper"

export class BackgroundImageAdjuster {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async adjust(backgroundImage: PackhelpObject) {
    this.scaleBackgroundImage(backgroundImage)
    this.centerBackgroundImage(backgroundImage)
    await this.refreshClipping(backgroundImage)

    backgroundImage.set({
      isOverscaled: ScaleHelper.isOverscaled(backgroundImage),
    })
  }

  private async refreshClipping(
    backgroundImage: PackhelpObject
  ): Promise<void> {
    await BackgroundLayerClippingHelper.refreshSpaceClipping(
      this.vdEditor,
      backgroundImage
    )
    await BackgroundLayerClippingHelper.addBackgroundPaddingClipPathIfNeeded(
      this.vdEditor,
      backgroundImage
    )
    await this.vdEditor.backgroundsModule.hideBackgroundLayerOnDisabledSpaces(
      backgroundImage
    )
  }

  private scaleBackgroundImage(backgroundImage: PackhelpObject) {
    const expectedSize = this.getExpectedSize()
    const bleedSize = this.getBleedSize(expectedSize)
    const paddingSize = this.getPaddingSize(expectedSize)

    const scale = Math.max(
      (expectedSize.width + 2 * bleedSize - 2 * paddingSize) /
        backgroundImage.width!,
      (expectedSize.height + 2 * bleedSize - 2 * paddingSize) /
        backgroundImage.height!
    )

    backgroundImage.set({
      scaleX: scale,
      scaleY: scale,
    })
  }

  private centerBackgroundImage(backgroundImage: PackhelpObject) {
    const availableSpaces = this.vdEditor.productRenderPilot.getAvailableSpaces(
      this.vdEditor.editContext
    )

    if (availableSpaces.length > 1) {
      const newPosition = RotationHelper.rotatePoint(
        new fabric.Point(
          (CANVAS_DIM - backgroundImage.getScaledWidth()) / 2,
          (CANVAS_DIM - backgroundImage.getScaledHeight()) / 2
        ),
        new fabric.Point(CANVAS_DIM / 2, CANVAS_DIM / 2),
        backgroundImage.angle!
      )

      backgroundImage.set({
        left: newPosition.x,
        top: newPosition.y,
      })
    } else {
      const space = this.vdEditor.dielineNavigator.getSpaceBoundingRect(
        availableSpaces[0]
      )

      const spaceCenterPoint = {
        x: space.left + space.width / 2,
        y: space.top + space.height / 2,
      }
      const backgroundImageCenterPoint = backgroundImage.getCenterPoint()

      const topDiff = spaceCenterPoint.y - backgroundImageCenterPoint.y
      const leftDiff = spaceCenterPoint.x - backgroundImageCenterPoint.x

      backgroundImage.set({
        left: backgroundImage.left! + leftDiff,
        top: backgroundImage.top! + topDiff,
      })
    }
  }

  /**
   * For products with many editable spaces on a dieline (3D product)
   * we need to stretch a background image to the canvas size.
   *
   * For products with only one editable space on a dieline (2D products)
   * we need to stretch a background image to the space size.
   */
  private getExpectedSize(): Size {
    const availableSpaces = this.vdEditor.productRenderPilot.getAvailableSpaces(
      this.vdEditor.editContext
    )

    if (availableSpaces.length > 1) {
      return {
        width: CANVAS_DIM,
        height: CANVAS_DIM,
      }
    }

    const space = this.vdEditor.dielineNavigator.getVirtualDielineSpace(
      availableSpaces[0]
    )
    const strokeWidth = space.strokeWidth!

    return {
      width: space.getScaledWidth() - strokeWidth,
      height: space.getScaledHeight() - strokeWidth,
    }
  }

  private getBleedSize(expectedSize: Size): number {
    const { productRenderPilot, editContext } = this.vdEditor
    const { bleedSizeMm } = productRenderPilot.uiConfig

    if (bleedSizeMm === 0) {
      return 0
    }

    const spaceId = productRenderPilot.getDefaultSpace(editContext)
    const artworkSpaceDimensions =
      this.vdEditor.productRenderPilot.getSpaceDimensions(
        this.vdEditor.editContext,
        spaceId
      )
    const bleedToArtworkSpaceRatio =
      bleedSizeMm / (artworkSpaceDimensions.widthCm * 10)

    return _ceil(expectedSize.width * bleedToArtworkSpaceRatio)
  }

  private getPaddingSize(expectedSize: Size): number {
    const { productRenderPilot, editContext } = this.vdEditor
    const availableSpaces = this.vdEditor.productRenderPilot.getAvailableSpaces(
      this.vdEditor.editContext
    )

    const paddingZoneSizeMm =
      this.vdEditor.productRenderPilot.uiConfig.editZone.paddingZoneSizeMm || 0

    if (paddingZoneSizeMm === 0 || availableSpaces.length > 1) {
      return 0
    }

    const backgroundImageSizePx = Math.max(
      expectedSize.width,
      expectedSize.height
    )

    const spaceId = productRenderPilot.getDefaultSpace(editContext)
    const artworkSpaceDimensions =
      this.vdEditor.productRenderPilot.getSpaceDimensions(
        this.vdEditor.editContext,
        spaceId
      )

    const paddingToArtworkSpaceRatio =
      paddingZoneSizeMm / (artworkSpaceDimensions.widthCm * 10)

    return _ceil(backgroundImageSizePx * paddingToArtworkSpaceRatio)
  }
}
