import { PredefinedTextRenderPilot } from "./predefined-text-render-config/predefined-text-render-pilot"
import { DESIGNER_MODE_FAKE_SKU, EditorMode } from "./types"
import { DesignVersion } from "../../types/design.version"
import { PimProductRenderPilot } from "./pim-product-render-config/pim-product-render-pilot"
import { ProductRenderPilot } from "./product-render-pilot"
import { ProductManager } from "@ph/product-api"

export async function ProductRenderPilotFactory(
  product: ProductManager,
  designVersion: DesignVersion,
  editorMode: EditorMode
): Promise<ProductRenderPilot> {
  const sku = product.variantManager.getSku()

  if (sku === DESIGNER_MODE_FAKE_SKU) {
    return new PredefinedTextRenderPilot(
      product,
      DesignVersion.latest,
      "designer"
    )
  }

  return new PimProductRenderPilot(product, designVersion, editorMode)
}
