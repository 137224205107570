import { VirtualDielinesData } from "../types/asset.types"
import { DesignVersion } from "../types/design.version"
import { CameraSettings } from "../libs/products-render-config/types"

export const CANVAS_DIM = 1024

export type VirtualDielineDesign = {
  virtualDielines: VirtualDielinesData
  designDataFormatVersion?: DesignVersion
}

export type RenderEngineExport = {
  virtualDielines: VirtualDielinesData
  thumbnail: string
}

export type RenderEngineExportConfig = {
  previews: boolean
}

export interface RendererMountPoints {
  rendererMountPoint: HTMLDivElement
  vdMountPoints: Record<string, HTMLDivElement>
}

export type ThumbnailGeneratorConfig = {
  width: number
  height: number
  mimeType: string
  quality: number
  cameraSettings?: CameraSettings
}
