import _defer from "lodash/defer"
import { breakpoint } from "dsl/src/theme/breakpoint"
import { addChatWithUsTriggers } from "../../../libs/services/live-chat/live-chat-services"

const getGlobal = function () {
  if (typeof globalThis !== "undefined") {
    return globalThis
  }
  throw new Error("Unable to locate global `this`")
}

const myGlobalThis = getGlobal()

function loadHubspotScript(hubspot_key: string) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement("script")
    script.async = !0
    script.defer = !0
    script.type = "text/javascript"
    script.id = "hs-script-loader"
    script.src = `//js-eu1.hs-scripts.com/${hubspot_key}.js`
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export function getHubspotKey() {
  if (!myGlobalThis || !myGlobalThis.zpkj || !myGlobalThis.zpkj.env) {
    return
  }

  const hubspot_key = myGlobalThis.zpkj.env.HUBSPOT_KEY
  const hubspot_chat_enabled = myGlobalThis.zpkj.env.HUBSPOT_CHAT_ENABLED

  if (!hubspot_key || !hubspot_chat_enabled || hubspot_chat_enabled === "false")
    return

  return hubspot_key
}

export function mountLiveChat(showOnMobile?: boolean) {
  const isMobile = window.innerWidth <= breakpoint.MOBILE["LG"]
  const hideChat = isMobile && !showOnMobile

  if (hideChat) {
    return
  }

  _defer(() => {
    const hubspot_key = getHubspotKey()
    if (!hubspot_key) return

    loadHubspotScript(hubspot_key).then(() => {
      addChatWithUsTriggers()
    })
  })
}
