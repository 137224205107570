import { Point } from "./types"

export class BezierCurve {
  public constructor(private readonly points: Point[]) {}

  /**
   * Supports quadratic bezier only
   */
  public getPointAt(t: number): Point {
    const x =
      Math.pow(1 - t, 2) * this.points[0].x +
      2 * (1 - t) * t * this.points[1].x +
      Math.pow(t, 2) * this.points[2].x

    const y =
      Math.pow(1 - t, 2) * this.points[0].y +
      2 * (1 - t) * t * this.points[1].y +
      Math.pow(t, 2) * this.points[2].y

    return {
      x,
      y,
    }
  }
}
