import React from "react"
import { TileList } from "../Tile/TileList"
import _times from "lodash/times"
import { Skeleton } from "./Skeleton"
import styles from "./SkeletonTileList.module.scss"

export interface SkeletonTileListProps {
  repeats: number
  cols?: 2 | 3
}

export function SkeletonTileList({ repeats, cols }: SkeletonTileListProps) {
  return (
    <TileList cols={cols}>
      {_times(repeats, () => (
        <div key={Math.random()} className={styles.item}>
          <Skeleton />
        </div>
      ))}
    </TileList>
  )
}
