import { observable, action, computed, makeObservable } from "mobx"
import {
  EditContext,
  EditorMode,
  SpaceId,
} from "../libs/products-render-config/types"
import {
  ActiveObjectEvent,
  CanvasEvent,
} from "../events/partials/domain.events"
import _debounce from "lodash/debounce"
import ProductDriver from "./product.driver"
import { PackhelpObject } from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import { ee, eventTree } from "../events/editor.events"
import { BackgroundsLayers } from "../render-engine/modules/vd-editor/modules/backgrounds-module/types"

export type OverscaledObjectsMap = {
  [key in EditContext]?: {
    [key in SpaceId | "global"]?: PackhelpObject[]
  }
}

export class AssetsDriver {
  @observable public overscaledObjectsMap: OverscaledObjectsMap = {}

  constructor(private readonly productDriver: ProductDriver) {
    makeObservable(this)

    this.initListeners()
  }

  public init() {
    this.findOverscaledImages()
  }

  @computed
  get isAnyAssetOverscaled(): boolean {
    for (const editContext of this.productDriver.state.productRenderPilot.getAvailableEditContexts()) {
      const contextMap = this.overscaledObjectsMap[editContext]

      if (!contextMap) {
        continue
      }

      const isOverscaled = !!Object.keys(contextMap).find((spaceId) => {
        return contextMap[spaceId].length > 0
      })

      if (isOverscaled) {
        return true
      }
    }

    return false
  }

  @computed
  get isBackgroundImageOverscaled(): boolean {
    for (const editContext of this.productDriver.state.productRenderPilot.getAvailableEditContexts()) {
      const contextMap = this.overscaledObjectsMap[editContext]

      if (!contextMap || !contextMap["global"]) {
        continue
      }

      const isOverscaled = !!contextMap["global"].find((object) => {
        return object.id === BackgroundsLayers.BACKGROUND_IMAGE
      })

      if (isOverscaled) {
        return true
      }
    }

    return false
  }

  @action
  private findOverscaledImages() {
    if (this.editorMode === "dby") {
      return
    }

    const { renderEngine, productRenderPilot } = this.productDriver.state

    if (!renderEngine) {
      console.warn(
        "Render engine is not ready, but the warning might be obsolete here"
      )
      return
    }

    const newMap = {}

    renderEngine.getVirtualDielineEditors().forEach((vdEditor) => {
      newMap[vdEditor.editContext] = {}
      const isPrintActive = productRenderPilot.isPrintActiveFor(
        vdEditor.editContext
      )

      if (!isPrintActive) {
        return
      }

      const currentContext = newMap[vdEditor.editContext]
      const objects = vdEditor.assetsModule.getOverscaledObjects()

      objects.forEach((obj) => {
        const space = obj.originSpaceArea || "global"

        if (currentContext[space]) {
          currentContext[space].push(obj)
        } else {
          currentContext[space] = [obj]
        }
      })
    })

    this.overscaledObjectsMap = newMap
  }

  private initListeners() {
    if (this.editorMode === "dby") {
      return
    }

    const debouncedFindOverscaledImages = _debounce(
      this.findOverscaledImages.bind(this),
      500,
      { leading: true, trailing: true }
    )

    this.productDriver.eventEmitter.on(
      ActiveObjectEvent.selected,
      debouncedFindOverscaledImages
    )
    this.productDriver.eventEmitter.on(
      ActiveObjectEvent.modified,
      debouncedFindOverscaledImages
    )
    this.productDriver.eventEmitter.on(
      CanvasEvent.objectRemoved,
      debouncedFindOverscaledImages
    )

    ee.on(eventTree.backgroundImages.applied, debouncedFindOverscaledImages)
    ee.on(eventTree.backgroundImages.removed, debouncedFindOverscaledImages)
    ee.on(eventTree.templates.selected, debouncedFindOverscaledImages)
    ee.on(eventTree.templates.removed, debouncedFindOverscaledImages)
    ee.on(eventTree.productDriver.productChanged, debouncedFindOverscaledImages)
  }

  private get editorMode(): EditorMode {
    return this.productDriver.state.productRenderPilot.getEditorMode()
  }
}
