import { VirtualDielineSpace } from "../../../../object-extensions/packhelp-objects"
import { ProductRenderPilot } from "../../../../../../../libs/products-render-config/product-render-pilot"
import {
  EditContext,
  FontSizeConfig,
  SpaceId,
} from "../../../../../../../libs/products-render-config/types"
import { ObjectDpiCalculator } from "../../../../../../../libs/calculators/object-dpi.calculator"
import { FontSizeDefinition } from "../../../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import _ceil from "lodash/ceil"

/**
 * Fabric's default DPI is 96, we use 300
 */
const DPI_MULTIPLIER = 300 / 72

/**
 * Because of differences in DPI and Virtual Dieline vs Artwork dimensions, DTP Font Size != Editor Font Size.
 *
 * We need to calculate min font size in Editor based on DTP value to be sure we use proper values.
 *
 * DTP Font Size = Editor Font Size / (300 / 72) / (Virtual Dieline Width / Artwork Width)
 * Editor Font Size = DTP Font Size * ((300 / 72) / (Artwork Width / Virtual Dieline Width))
 *
 * 72 - DPI for screen display
 * 300 - DPI for printing
 *
 * Fabric uses 96 DPI as default, so we need to change it to 300 in our calculations.
 */
export class FontSizeCalculator {
  public constructor(
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly editContext: EditContext,
    private readonly vdSpace: VirtualDielineSpace
  ) {}

  public dtpToEditor(dtpFontSize: number): number {
    return _ceil(
      dtpFontSize * (DPI_MULTIPLIER / (this.widthApx / this.widthVdpx))
    )
  }

  public editorToDtp(editorFontSize: number): number {
    return editorFontSize / DPI_MULTIPLIER / (this.widthVdpx / this.widthApx)
  }

  public calcEditorLimits(): { min: number; max: number } {
    return {
      min: this.calcEditorMin(),
      max: this.calcEditorMax(),
    }
  }

  public calcEditorMin(): number {
    return Math.max(
      this.fontSizeConfig.editor.min,
      this.dtpToEditor(this.fontSizeConfig.dtp.min)
    )
  }

  public calcEditorMax(): number {
    return this.fontSizeConfig.editor.max
  }

  public generateFontSizeList(): FontSizeDefinition[] {
    const { min, max } = this.calcEditorLimits()

    const sizes: FontSizeDefinition[] = []

    for (let i = min; i <= max; i++) {
      const size = {
        id: i,
        name: i,
      }

      sizes.push(size)
    }

    return sizes
  }

  private get widthApx(): number {
    const artworkSpaceSize = this.productRenderPilot.getSpaceDimensions(
      this.editContext,
      this.vdSpace.id
    )

    return artworkSpaceSize.widthPx
  }

  private get widthVdpx(): number {
    const vdSpaceSize = ObjectDpiCalculator.getVdSize(this.vdSpace)

    return vdSpaceSize.widthVdpx
  }

  private get fontSizeConfig(): FontSizeConfig {
    return this.productRenderPilot.uiConfig.fontSize
  }
}
