import { Sentry } from "./sentry-service"
import _get from "lodash/get"

declare global {
  interface Window {
    growsumo: any
  }
}

export const getPartnerstackData = () => {
  try {
    return {
      partnerstack_data: {
        partner_key: _get(window, "growsumo.data.partner_key"),
        sub_ids: mapSubIDs(),
        gsxid: _get(window, "growsumo.data.xid"),
      },
    }
  } catch (e) {
    Sentry.captureException(e)
    throw new Error("Failed to load PartnerStack data")
  }
}

function mapSubIDs() {
  const searchParams = new URLSearchParams(window.location.search)
  var subIDs = new Array()

  searchParams.forEach((value, key) => {
    if (key.match(/sid\d?/)) {
      subIDs.push(value)
    }
  })

  return subIDs
}
