let DEV = false

if (__DEV__) {
  DEV = true
}

let googleFontsApiKey = !DEV
  ? "AIzaSyAHGc4SrYPFdzXDKEnbzTAlY39VZVEZEsc"
  : "AIzaSyAXtnwTSHOGQXAAxcbR1PcqMukG7MX4ZhQ"

export const application = {
  SHOW_BOOTSTRAP_COVER_FOR_MIN: 800,
  EMAILGATE_ENABLED: true,

  SOURCE_SHOP: "source:shop",
  SOURCE_USER: "source:user",
  SOURCE_FILES: "source:files",
  SOURCE_BOX_DESIGNS: "source:boxDesigns",

  SAMPLE_PACK_ORDER_FLOW: "sample-pack-mode",
  NO_PRINT_MODE_ORDER_FLOW: "no-print-mode",
  BOX_DESIGN_MODE: "standard-mode",

  LS_NAMESPACE: "zpkj",
  LS_API_TOKEN_NAME: "zpkj_apiToken",
  LS_REMEMBER_USER: "zpkj_rememberUser",
  LS_COOKIE_EXPIRES_AFTER_DAYS: 31,

  SS_NAMESPACE: "zpkj_ss",

  GUIDELINES_SECTION_HELP_SITE: "guidelinesSectionHelpSite",
  GUIDELINES_SECTION_SIZES: "guidelinesSectionSizes",
  GUIDELINES_SECTION_COLORS: "guidelinesSectionColors",
  GUIDELINES_SECTION_2D: "guideLinesSection2D",
  GUIDELINES_SECTION_FORMATS: "guidelinesSectionsFormats",
  GUIDELINES_SECTION_MIN_SIZES: "guidelinesSectionMinSizes",
  GUIDELINES_SECTION_MAX_LAYER_SIZE: "guidelinesSectionMaxLayerSize",

  GOOGLE_FONTS_API_KEY: googleFontsApiKey,
}
