import fabric from "editor/src/libs/vendors/Fabric"
import { Colour } from "../../../../models/colour"
import { PackhelpImage } from "../object-extensions/packhelp-objects"
import { IBaseFilter } from "fabric/fabric-impl"

fabric.textureSize = 5000

interface BlendFilterConfig {
  mode: string
  alpha: number
}

class FiltersModule {
  public static setBlendImageFilter(
    image: PackhelpImage,
    blendImage: fabric.Object,
    { mode = "multiply", alpha = 0.5 }: Partial<BlendFilterConfig> = {}
  ): PackhelpImage {
    const blendFilter = this.buildFilter("BlendImage", {
      image: blendImage,
      mode,
      alpha,
    })

    image.filters.push(blendFilter)
    image.applyFilters()

    return image
  }

  public static setBlendColorFilter(
    image: PackhelpImage,
    blendColor: Colour,
    { mode = "multiply", alpha = 1 }: Partial<BlendFilterConfig> = {}
  ): PackhelpImage {
    let filter = image.filters.find(
      (filter) => filter.mode === mode && filter.color
    )

    if (!filter) {
      filter = this.buildFilter("BlendColor", {
        mode,
      })

      image.filters.push(filter)
    }

    filter.setOptions({
      color: blendColor.getHex(),
      alpha,
    })

    image.applyFilters()

    return image
  }

  public static setTintFilter(
    image: PackhelpImage,
    color: Colour
  ): PackhelpImage {
    let filter = image.filters.find((filter) => filter.mode === "tint")

    if (!filter) {
      filter = this.buildFilter("BlendColor", {
        mode: "tint",
      })

      image.filters.push(filter)
    }

    filter.setOptions({
      color: color.getHex(),
      alpha: 1,
    })

    image.applyFilters()

    return image
  }

  public static setRemoveColorFilter(
    image: PackhelpImage,
    distance: number,
    color: Colour
  ): PackhelpImage {
    const removeColor = this.buildFilter("RemoveColor", {
      distance: distance,
      color: color.getHex(),
    })

    image.filters.push(removeColor)

    return image
  }

  public static setInvertFilter(image: PackhelpImage): PackhelpImage {
    const invertFilter = this.buildFilter("Invert", {})

    image.filters.push(invertFilter)

    return image
  }

  public static changeAssetThreshold(image: PackhelpImage, threshold: number) {
    const removeColorFilter = image.filters.find(
      (filter) => filter.type === "RemoveColor"
    )

    if (!removeColorFilter) {
      return
    }

    removeColorFilter.distance = threshold
    image.applyFilters()
  }

  public static toggleMonochrome(image) {
    const filtersConfigArr = image.get("filters")
    if (image.isImageInverted()) {
      filtersConfigArr.splice(
        filtersConfigArr.findIndex((filter) => filter.type === "Invert"),
        1
      )
    } else {
      const invertFilter = this.buildFilter("Invert", {})
      filtersConfigArr.unshift(invertFilter)
    }

    image.set("filters", filtersConfigArr)
    image.applyFilters(filtersConfigArr)
  }

  private static buildFilter(filterName, params): IBaseFilter {
    return new fabric.Image.filters[filterName](params)
  }
}

export default FiltersModule
