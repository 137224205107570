var NcpFinish;
(function (NcpFinish) {
    NcpFinish["PRINT_FINISH_NONE"] = "none";
    NcpFinish["PRINT_FINISH_DESIGN_BW_THANK_YOU"] = "designBwThankYou";
    NcpFinish["PRINT_FINISH_DESIGN_COLOR_HELLO"] = "designColorHello";
    NcpFinish["PRINT_FINISH_DESIGN_BW_HELLO"] = "designBwHello";
    NcpFinish["PRINT_FINISH_DESIGN_COLOR_OPEN_ME"] = "designColorOpenMe";
    NcpFinish["PRINT_FINISH_DESIGN_COLOR_THANK_YOU"] = "designColorThankYou";
    NcpFinish["PRINT_FINISH_BROWN"] = "brown";
    NcpFinish["PRINT_FINISH_WHITE"] = "white";
})(NcpFinish || (NcpFinish = {}));
export default NcpFinish;
