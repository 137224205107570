export const featureFlagDefaults = {
  shouldEnableErrorSegmentNotifications: false,
  shouldMe: 2,
}
export type AllowedEditorFeatureFlagNames = keyof typeof featureFlagDefaults
export type CommonFeatureFlags = typeof featureFlagDefaults

// Note: feature flags that should not be persisted in localstorage
export const dynamicFeatureFlags: readonly Partial<AllowedEditorFeatureFlagNames>[] =
  ["shouldEnableErrorSegmentNotifications"]
