import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import React from "react"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { SectionLoader } from "../../../molecules/loaders/SectionLoader/SectionLoader"

export const CanvasLoader = observer(() => {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { isVisible, labelIntl } = containerSet.ui.canvasLoaderUiController

  if (!isVisible) {
    return null
  }

  return <SectionLoader label={t(labelIntl)} e2eTargetName="canvas" />
})
