export enum ActiveTabEvents {
  up = "keyboard.activeTab.up",
  down = "keyboard.activeTab.down",
  select = "keyboard.activeTab.select",
  close = "keyboard.activeTab.close",
}

export type ActiveTabEventTypes = {
  [ActiveTabEvents.up]: () => void
  [ActiveTabEvents.down]: () => void
  [ActiveTabEvents.select]: () => void
  [ActiveTabEvents.close]: () => void
}
