import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import {
  isActiveSelection,
  isAssetGroup,
  isAssetText,
} from "../../../../../../types/asset.types"
import fabric from "../../../../../../libs/vendors/Fabric"
import rotateIconPath from "../../../../../../ui/assets/_icons/rotate.svg"

const DEFAULT_CONTROLS = {
  bl: true,
  br: true,
  mb: true,
  ml: true,
  mr: true,
  mt: true,
  tl: true,
  tr: true,
}

const NON_UNI_SCALING_CONTROLS = {
  bl: true,
  br: true,
  mb: false,
  ml: false,
  mr: false,
  mt: false,
  tl: true,
  tr: true,
}

const rotateIcon = new Image()
rotateIcon.src = rotateIconPath

const borderHover = "rgba(0, 6, 26, 0.4)"
const borderColor = "#2757FF"
const cornerColor = "#FFFFFF"

export class ControlsHelper {
  public static decorateHoverControls(object: PackhelpEditableObject) {
    object.set({
      borderColor: borderHover,
      borderScaleFactor: 1,
    })
  }

  public static setControls(object: PackhelpEditableObject) {
    this.decorateControls(object)

    if (
      isAssetText(object) ||
      isAssetGroup(object) ||
      isActiveSelection(object) ||
      object.lockUniScaling
    ) {
      return this.setNonUniScalingControls(object)
    }

    this.setDefaultControls(object)
  }

  private static setNonUniScalingControls(object: PackhelpEditableObject) {
    object.set({
      lockUniScaling: true,
    })

    object.setControlsVisibility(NON_UNI_SCALING_CONTROLS)
  }

  private static setDefaultControls(object: PackhelpEditableObject) {
    object.setControlsVisibility(DEFAULT_CONTROLS)
  }

  private static decorateControls(object: PackhelpEditableObject) {
    object.set({
      lockScalingFlip: true,
      centeredScaling: true,
      lockSkewingX: true,
      lockSkewingY: true,
      borderColor: borderColor,
      borderScaleFactor: 2,
      cornerColor: cornerColor,
      cornerStrokeColor: borderColor,
      cornerSize: 16,
    })

    this.decorateCornerControls(object)
    this.decorateRotationControl(object)
  }

  private static decorateCornerControls(object: PackhelpEditableObject) {
    const { bl, br, mb, ml, mr, mt, tl, tr } = object.controls

    const drawControl = (ctx: CanvasRenderingContext2D, size: number): void => {
      const x = -size / 2
      const y = -size / 2
      const width = size
      const height = size

      if (!!ctx.roundRect) {
        return ctx.roundRect(x, y, width, height, 2)
      }

      // Fallback for browsers that does not support roundRect (e.g. Safari 15.6 used by some of our clients)
      ctx.rect(x, y, width, height)
    }

    for (const control of [bl, br, mb, ml, mr, mt, tl, tr]) {
      control.render = function (ctx, left, top, styleOverride, fabricObject) {
        const size = fabricObject.cornerSize!

        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!))

        ctx.beginPath()
        drawControl(ctx, size)
        ctx.fillStyle = fabricObject.cornerColor!
        ctx.fill()
        ctx.lineWidth = fabricObject.borderScaleFactor!
        ctx.strokeStyle = fabricObject.cornerStrokeColor!
        ctx.stroke()

        ctx.restore()
      }
    }
  }

  private static decorateRotationControl(object: PackhelpEditableObject) {
    if (object.lockRotation) {
      object.setControlsVisibility({
        mtr: false,
      })

      return
    }

    const { mtr } = object.controls

    mtr.y = 0.5
    mtr.offsetY = 36
    mtr.withConnection = false

    const controlSize = object.cornerSize! * 1.5
    mtr.sizeX = mtr.sizeY = controlSize

    mtr.render = function (ctx, left, top, styleOverride, fabricObject) {
      const iconSize = controlSize / 2

      ctx.save()
      ctx.translate(left, top)
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle!))

      ctx.beginPath()
      ctx.arc(0, 0, controlSize / 2, 0, 2 * Math.PI, false)
      ctx.fillStyle = fabricObject.cornerColor!
      ctx.fill()
      ctx.lineWidth = fabricObject.borderScaleFactor!
      ctx.strokeStyle = fabricObject.cornerStrokeColor!
      ctx.stroke()

      ctx.drawImage(
        rotateIcon,
        -iconSize / 2,
        -iconSize / 2,
        iconSize,
        iconSize
      )

      ctx.restore()
    }
  }
}
