import { apiServiceWithCurrentToken } from "./api/api-service"
import {
  submitConsentsEndpoint,
  getGlobalConsentNeeded,
  submitOrderConsents,
} from "./api/endpoints/consents-endpoints"
import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import Cookie from "../storage/Cookie"
import { getFormatMessage } from "../others/i18n"
import { pushToGtm } from "../analytics/segment/segment-analytics.service"
import { EventBusinessUnit } from "../analytics/segment/segment-analytics.consts"
import SegmentAnalytics from "shared-libs/src/js/libs/analytics/segment/segment-analytics"
import { ConsentTypes } from "@ph/api-studio"
export { ConsentTypes }

export enum ConsentValueTypes {
  GRANTED = "granted",
  DECLINED = "declined",
}

enum ConsentEventNames {
  GLOBAL_CONSENTS_GIVEN = "Global Consents Given",
}

type ConsentIdsParams = {
  id: string
  variables?: {
    [key: string]: string
  }
}

export type ConsentParams = {
  value: string
  text: string | ConsentIdsParams
}

export type ConsentsParams = {
  [ConsentTypes.TermsAndPrivacy]?: ConsentParams
  [ConsentTypes.TermsAndConditionsPlus]?: ConsentParams
  [ConsentTypes.Marketing]?: ConsentParams
  [ConsentTypes.Newsletter]?: ConsentParams
  [ConsentTypes.Envelope]?: ConsentParams
}

export type ConsentServiceParams = {
  email: string
  location: string
  consents: ConsentsParams
  business_unit?: EventBusinessUnit
  userId?: number
  sku?: string
}

export type OrderConsentServiceParams = {
  orderNumber: string
  text: { id: string }
  type: ConsentTypes
  value: ConsentValueTypes.DECLINED | ConsentValueTypes.GRANTED
}

export type OrderConsentServicePayload = {
  order_number: string
  displayed_text: string
  type_name: ConsentTypes
  value: ConsentValueTypes.DECLINED | ConsentValueTypes.GRANTED
}

export type ConsentServicePayload = {
  email: string
  location: string
  locale: string
  region: string
  user_id: string
  consents: {
    name: string
    value: string
    displayed_text: string
  }[]
}

export const checkIfGlobalConsentIsNeeded = async (
  email: string
): Promise<{ result: boolean }> => {
  const response = await apiServiceWithCurrentToken().call(
    //@ts-ignore
    getGlobalConsentNeeded(email)
  )
  return response
}

export const submitOrderConsent: (
  params: OrderConsentServiceParams
) => Promise<any> = async ({
  orderNumber,
  text,
  type,
  value,
}: OrderConsentServiceParams) => {
  const formatMessage = await getFormatMessage()

  const payload: OrderConsentServicePayload = {
    order_number: orderNumber,
    type_name: type,
    displayed_text: getConsentText(formatMessage, text),
    value,
  }

  await apiServiceWithCurrentToken().call(
    //@ts-ignore
    submitOrderConsents(payload)
  )
}

export const submitConsents = async (data: ConsentServiceParams) => {
  const consents = await getLocalizedConsents(data.consents)
  const submitData = { ...data, consents }

  const userId = data.userId
    ? {
        user_id: data.userId,
      }
    : getUserId()

  const payload: ConsentServicePayload = Object.assign(
    {},
    submitData,
    getRegionData(),
    getAnonymousId(),
    userId
  )

  if (
    consents.find(
      (consent: any) =>
        consent.name === "marketing" && consent.value === "granted"
    )
  ) {
    const { location } = data

    pushToGtm({
      event: ConsentEventNames.GLOBAL_CONSENTS_GIVEN,
      properties: { location },
    })
  }

  return await apiServiceWithCurrentToken().call(
    //@ts-ignore
    submitConsentsEndpoint(payload)
  )
}

const getLocalizedConsents = async (ConsentsParams: ConsentsParams) => {
  const formatMessage = await getFormatMessage()
  const consents: ConsentServicePayload["consents"] = []

  for (const [name, data] of Object.entries(ConsentsParams)) {
    consents.push({
      name,
      value: data.value,
      displayed_text: getConsentText(formatMessage, data.text),
    })
  }

  return consents
}

const getRegionData = () => {
  return {
    region: RegionConfig.getCurrentRegion(),
    locale: RegionConfig.getCurrentLocale(),
  }
}

const getUserId = () => {
  return { user_id: Cookie.get("ajs_user_id") || "" }
}

export const getAnonymousId = () => {
  const segment = SegmentAnalytics.segmentApi()
  const anonymousIdFromSegment = segment.user && segment.user().anonymousId()
  const ajsAnonymousId = Cookie.get("ajs_anonymous_id")
  const anonymousIdFromCookie = ajsAnonymousId
    ? ajsAnonymousId.replace(/\"|%22/g, "")
    : null

  return { anonymous_id: anonymousIdFromSegment || anonymousIdFromCookie }
}

const getConsentText = (formatMessage, text) => {
  if (typeof text === "string") return text

  const variables = text.variables
  const formattedVariables = {}
  if (!variables) return formatMessage({ id: text.id })

  for (const [name, id] of Object.entries(variables)) {
    formattedVariables[name] = formatMessage({ id })
  }
  return formatMessage({ id: text.id }, formattedVariables)
}

export type ConsentAcceptanceMethod = "by-cta-click" | "manual"

type GetConsents = (params: {
  agreedToGlobalMarketingConsents: boolean
  agreedToTermsAndConditionsPlusConsent?: boolean
  isAgrementToGlobalMarketingConsentsNeeded?: boolean
  consentAcceptanceMethod: ConsentAcceptanceMethod
}) => ConsentsParams

type ConsentToTermsTextByMethodI18n = {
  id: string
  variables: {
    [key: string]: string
  }
}
export const consentToTermsI18nByAcceptanceMethod = new Map<
  ConsentAcceptanceMethod,
  ConsentToTermsTextByMethodI18n
>([
  [
    "by-cta-click",
    {
      id: "generic.agree-to-terms-and-privacy-policy-by-clicking-continue",
      variables: {
        terms:
          "form.request-a-quote.agree-terms-and-conditions.terms-and-conditions-link",
        privacy:
          "form.request-a-quote.agree-terms-and-conditions.privacy-policy-link",
      },
    },
  ],
  [
    "manual",
    {
      id: "form.get-a-quote.agree-terms-and-conditions",
      variables: {
        link_terms:
          "form.request-a-quote.agree-terms-and-conditions.terms-and-conditions-link",
        link_privacy:
          "form.request-a-quote.agree-terms-and-conditions.privacy-policy-link",
      },
    },
  ],
])

const manualConsentToTermsPlusI18n: ConsentToTermsTextByMethodI18n = {
  id: "form.request-a-quote.agree-terms-and-conditions-plus",
  variables: {
    link_terms:
      "form.request-a-quote.agree-terms-and-conditions.terms-and-conditions-link",
    link_privacy:
      "form.request-a-quote.agree-terms-and-conditions.privacy-policy-link",
    link_terms_plus:
      "form.request-a-quote.agree-terms-and-conditions.terms-and-conditions-plus-link",
  },
}

export const getConsents: GetConsents = ({
  agreedToGlobalMarketingConsents,
  agreedToTermsAndConditionsPlusConsent = false,
  isAgrementToGlobalMarketingConsentsNeeded = true,
  consentAcceptanceMethod,
}) => {
  const value = agreedToGlobalMarketingConsents
    ? ConsentValueTypes.GRANTED
    : ConsentValueTypes.DECLINED

  const consentToTermsI18n = consentToTermsI18nByAcceptanceMethod.get(
    consentAcceptanceMethod
  )

  const consentToTermsAndConditions = {
    [ConsentTypes.TermsAndPrivacy]: {
      value: ConsentValueTypes.GRANTED,
      text: agreedToTermsAndConditionsPlusConsent
        ? manualConsentToTermsPlusI18n
        : consentToTermsI18n,
    },
  }

  const consentToTermsAndConditionsPlus = {
    [ConsentTypes.TermsAndConditionsPlus]: {
      value: ConsentValueTypes.GRANTED,
      text: manualConsentToTermsPlusI18n,
    },
  }

  const consentToGlobal = {
    [ConsentTypes.Marketing]: {
      value,
      text: { id: "form.get-a-quote.agree-marketing" },
    },
    [ConsentTypes.Newsletter]: {
      value,
      text: { id: "form.get-a-quote.agree-marketing" },
    },
    [ConsentTypes.Envelope]: {
      value,
      text: { id: "form.get-a-quote.agree-marketing" },
    },
  }

  return {
    ...consentToTermsAndConditions,
    ...(agreedToTermsAndConditionsPlusConsent &&
      consentToTermsAndConditionsPlus),
    ...(isAgrementToGlobalMarketingConsentsNeeded && consentToGlobal),
  } as ConsentsParams
}
