import { PackhelpEditableObject } from "../../../../object-extensions/packhelp-objects"
import { BaseController } from "./base.controller"
import VirtualDielineEditor from "../../../../virtual-dieline-editor"
import { CanvasObjectEvent } from "../../../../../../../events/partials/domain.events"
import { isAssetGroup } from "../../../../../../../types/asset.types"
import { SpaceClippingHelper } from "../../helpers/space-clipping.helper"
import { DuplicationOptions } from "../types"
import { DuplicationController as GroupDuplicationController } from "../../editable-group-controller/controllers/duplication.controller"
import { MaskDuplicationController } from "../../mask-controller/mask-duplication.controller"

export class DuplicationController extends BaseController {
  constructor(
    protected readonly canvasObject: PackhelpEditableObject,
    protected readonly vdEditor: VirtualDielineEditor
  ) {
    super(canvasObject)
  }

  public async duplicate(
    options: DuplicationOptions
  ): Promise<PackhelpEditableObject> {
    const currentObject = this.getCurrentObject()
    currentObject.fire(CanvasObjectEvent.beforeDuplicated)

    if (isAssetGroup(currentObject)) {
      const controller = new GroupDuplicationController(
        currentObject,
        this.vdEditor
      )
      return controller.duplicate(options)
    }

    return await this.duplicateObject(currentObject, options)
  }

  private async duplicateObject(
    object: PackhelpEditableObject,
    options: DuplicationOptions
  ): Promise<PackhelpEditableObject> {
    const spaceId = options.newSpaceId || object.originSpaceArea
    const duplicatedObject = await this.vdEditor.assetsModule.cloneAsset(object)

    duplicatedObject.originSpaceArea = spaceId
    duplicatedObject.clipPath = undefined
    await SpaceClippingHelper.evokeSpaceClipping(
      this.vdEditor,
      spaceId,
      duplicatedObject
    )

    const maskDuplicationController = new MaskDuplicationController(
      object,
      this.vdEditor
    )
    await maskDuplicationController.duplicateForIfNeeded(
      duplicatedObject,
      options
    )

    if (options.withRender) {
      this.vdEditor.addOnCanvas(duplicatedObject)
    }

    return duplicatedObject
  }
}
