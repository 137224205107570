let DEV = false

if (__DEV__) {
  DEV = true
}

let filePickerBucketName = DEV ? "zpkj-files-dev" : "zpkj-files"
let requestAQuoteBucketName = DEV ? "packhelp-files-dev" : "packhelp-files"
let dielineBucketName = DEV ? "packhelp-files-dev" : "packhelp-files"
let contactPageBucketName = DEV ? "packhelp-files-dev" : "packhelp-files"
let inspirationsHandbookBucketName = DEV
  ? "packhelp-files-dev"
  : "packhelp-files"

export const files = {
  // FilePicker
  FILEPICKER_KEY: "Abr9vScyUSdWkpJtvCJvwz",
  FILEPICKER_OUTPUT_FORMAT: "png",
  FILEPICKER_STORAGE_LOCATION: "s3",
  FILEPICKER_PROCESS_URL: "https://process.filestackapi.com",
  FILEPICKER_CONVERSION_DENSITY: "300",
  FILEPICKER_CONVERSION_DENSITY_THUMBNAIL: "144",
  FILEPICKER_CONVERSION_DENSITY_DIELINE_MODE: "50",
  FILEPICKER_CONVERSION_DENSITY_DIELINE_MODE_THUMBNAIL: "50",
  FILEPICKER_ACCEPTED_MIMETYPES:
    "application/pdf,image/x-png,image/png,image/jpg,image/jpeg,image/svg,image/svg+xml",
  FILEPICKER_DIELINES_ACCEPTED_MIMETYPES:
    "application/pdf,application/illustrator,application/postscript",
  FILEPICKER_CONTACT_FORM_ACCEPTED_MIMETYPES:
    "application/pdf,application/illustrator,application/postscript,application/zip,image/x-png,image/png",
  FILEPICKER_DIELINES_ACCEPTED_EXTENSIONS: [".pdf", ".ai"],
  FILEPICKER_MAX_WIDTH_PX: 7000,
  FILEPICKER_MAX_HEIGHT_PX: 7000,

  // S3
  S3_FILEPICKER_BUCKET_NAME: filePickerBucketName,
  S3_FILEPICKER_BUCKET_REGION: "eu-west-1",
  S3_REGION_URL: "https://s3-eu-west-1.amazonaws.com",
  S3_REGION_URL_BUCKET_AS_PATH:
    "https://s3-eu-west-1.amazonaws.com/" + filePickerBucketName,
  S3_REGION_URL_BUCKET_AS_SUBDOMAIN:
    "https://" + filePickerBucketName + ".s3-eu-west-1.amazonaws.com",

  S3_REQUEST_A_QUOTE_BUCKET_NAME: requestAQuoteBucketName,
  S3_REQUEST_A_QUOTE_BUCKET_AS_SUBDOMAIN:
    "https://" + requestAQuoteBucketName + ".s3-eu-west-1.amazonaws.com",
  S3_REQUEST_A_QUOTE_BUCKET_CONTAINER:
    requestAQuoteBucketName + "/request-a-quote",

  S3_DIELINE_BUCKET_NAME: dielineBucketName,
  S3_DIELINE_BUCKET_AS_SUBDOMAIN:
    "https://" + dielineBucketName + ".s3-eu-west-1.amazonaws.com",
  S3_DIELINE_BUCKET_CONTAINER: dielineBucketName + "/dieline",

  // S3 - Contact Page
  S3_CONTACT_PAGE_BUCKET_NAME: contactPageBucketName,
  S3_CONTACT_PAGE_BUCKET_AS_SUBDOMAIN:
    "https://" + contactPageBucketName + ".s3-eu-west-1.amazonaws.com",
  S3_CONTACT_PAGE_BUCKET_CONTAINER: contactPageBucketName + "/contact-form",

  S3_INSPIRATIONS_HANDBOOK_BUCKET_AS_SUBDOMAIN:
    "https://" + inspirationsHandbookBucketName + ".s3-eu-west-1.amazonaws.com",
  S3_INSPIRATIONS_HANDBOOK_BUCKET_CONTAINER:
    inspirationsHandbookBucketName + "/inspirations-handbook",

  DIELINE_LOCATION: "https://dielines.packhelp.com",
}
