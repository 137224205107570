import {
  PackhelpInteractiveCanvas,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { CanvasEvent } from "../../../../../../events/partials/domain.events"
import { SpaceGuidelinesController } from "./controllers/space-guidelines.controller"
import { Axis, SmartGuidelineEvent } from "./types"
import { GuidelinesController } from "./controllers/guidelines.controller"
import { ObjectGuidelinesController } from "./controllers/object-guidelines.controller"
import { SafeZoneGuidelinesController } from "./controllers/safe-zone-guidelines.controller"
import { SnapDaddy } from "./snap-daddy"

export class SmartGuidelinesController {
  private isEventListenersAttached = false
  private readonly eventMap: SmartGuidelineEvent[]
  private readonly controllers: GuidelinesController[]
  private readonly snapDaddy: SnapDaddy

  constructor(private readonly canvas: PackhelpInteractiveCanvas) {
    this.snapDaddy = new SnapDaddy(this.canvas)

    this.controllers = [
      new SpaceGuidelinesController(this.canvas, this.snapDaddy),
      new SafeZoneGuidelinesController(this.canvas, this.snapDaddy),
      new ObjectGuidelinesController(this.canvas, this.snapDaddy),
    ]

    this.eventMap = this.buildEventMap()
  }

  public attachEventListeners() {
    if (this.isEventListenersAttached) {
      return
    }

    for (const event of this.eventMap) {
      this.canvas.on(event.name, event.fn)
    }

    this.isEventListenersAttached = true
  }

  public detachEventListeners(): void {
    if (!this.isEventListenersAttached) {
      return
    }

    for (const event of this.eventMap) {
      this.canvas.off(event.name, event.fn)
    }

    this.isEventListenersAttached = false
  }

  private process(): void {
    const object = this.canvas.getActiveObject() as PackhelpObject

    if (!object) {
      return this.clear()
    }

    const excludedAxes: Axis[] = []

    for (const controller of this.controllers) {
      const matchedAxes = controller.process(object, { excludedAxes })

      excludedAxes.push(...matchedAxes)
    }

    if (!excludedAxes.length) {
      this.snapDaddy.clear()
    }
  }

  private clear(): void {
    this.snapDaddy.clear()

    for (const controller of this.controllers) {
      controller.clear()
    }
  }

  private buildEventMap(): SmartGuidelineEvent[] {
    return [
      {
        name: CanvasEvent.selectionCleared,
        fn: this.clear.bind(this),
      },
      {
        name: CanvasEvent.selectionUpdated,
        fn: this.clear.bind(this),
      },
      {
        name: CanvasEvent.mouseUp,
        fn: this.clear.bind(this),
      },
      {
        name: CanvasEvent.objectMoving,
        fn: this.process.bind(this),
      },
      {
        name: CanvasEvent.objectScaling,
        fn: this.process.bind(this),
      },
    ]
  }
}
