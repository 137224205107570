import React, { PropsWithChildren } from "react"
import styles from "./ToolbarSection.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export interface ToolbarSectionProps {
  spacing?: "normal" | "small"
}

export function ToolbarSection({
  children,
  spacing = "normal",
}: PropsWithChildren<ToolbarSectionProps>) {
  return <div className={cx("wrapper", spacing)}>{children}</div>
}
