import React, { useEffect, useState } from "react"
import { InlineSvgWithLoader } from "../../molecules/InlineSvgWithLoader/InlineSvgWithLoader"
import { paintSVGInDom } from "../../../../libs/helpers/paint-pattern-in-dom"
import { Tile, TileProps } from "../Tile/Tile"
import { Pattern } from "../../../../models/pattern"

export interface PatternTileProps extends TileProps {
  pattern: Pattern
}

export function PatternTile({ pattern, ...tileProps }: PatternTileProps) {
  const [svgElement, setSvgElement] = useState<SVGElement>()

  useEffect(() => {
    if (svgElement) {
      paintSVGInDom(svgElement, pattern)
    }
  }, [svgElement, pattern])

  return (
    <Tile {...tileProps}>
      <figure>
        <InlineSvgWithLoader
          src={pattern.url}
          svgMountCallback={setSvgElement}
        />
      </figure>
    </Tile>
  )
}
