import { prepareAssetUrl } from "../../helpers/asset-url-preparator"

export class ImageLoader {
  public static async loadImage(imageUrl: string): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const image = new Image()
      image.crossOrigin = "Anonymous"

      image.onload = () => {
        resolve(image)
      }

      image.src = prepareAssetUrl(imageUrl)
    })
  }

  public static async toDataUrl(imageUrl: string): Promise<string> {
    const blob = await fetch(imageUrl).then((response) => response.blob())

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }
}
