import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import { GetFormattedPrice, Materials } from "./reforestation-calculator.types"
import { SupportedCurrency } from "shared-libs/src/configs/region-config/interfaces/js/region-config.types"
import {
  MultiplicationFactorForCurrency,
  DEFAULT_CURRENCY_MULTIPLIER,
  MultiplicationFactorForMaterialWaste,
  DEFAULT_MATERIAL_WASTE_MULTIPLIER,
  DEFAULT_TREES_PER_MATERIAL_KG_MULTIPLIER,
} from "./reforestation-calculator.multipliers"

export const getFormattedPrice: GetFormattedPrice = (price, currency) =>
  Intl.NumberFormat(RegionConfig.getCurrentLocaleWithCountryCode(), {
    style: "currency",
    currency,
  }).format(price)

export const getMaterialWasteMultiplier = (material: string) =>
  MultiplicationFactorForMaterialWaste.get(material as Materials) ||
  DEFAULT_MATERIAL_WASTE_MULTIPLIER

export const getCurrencyMultiplier = (currency: string) =>
  MultiplicationFactorForCurrency.get(
    currency.toUpperCase() as SupportedCurrency
  ) || DEFAULT_CURRENCY_MULTIPLIER

export const getTreesPerKgOfMaterialMultiplier = (material: string) =>
  DEFAULT_TREES_PER_MATERIAL_KG_MULTIPLIER
