import { EditableObjectTypes } from "../../../../types/asset.types"

export function overrideFabricGroup(fabric) {
  /*
   * Overwritten to return false for groups containing IText or Text.
   * Prevents glitches in fancy fonts rendering.
   */
  fabric.Group.prototype.shouldCache = function () {
    const ownCache = fabric.Object.prototype.shouldCache.call(this)
    const isEditableGroup = this.assetType === EditableObjectTypes.assetGroup

    if (ownCache && isEditableGroup) {
      for (let i = 0, len = this._objects.length; i < len; i++) {
        const object = this._objects[i]
        const isText = ["i-text", "text"].includes(object.type)
        const hasShadow = object.willDrawShadow()

        if (isText || hasShadow) {
          this.ownCaching = false
          return false
        }
      }
    }

    return ownCache
  }

  return fabric
}
