import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import { I18N } from "../../../../../i18n"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"
import { Slider } from "dsl/src/atoms/Slider/Slider"
import { MonochromeSlider } from "../../../../molecules/MonochromeSlider/MonochromeSlider"
import { Colour } from "../../../../../../models/colour"
import { ColorConfigurator } from "../../../configurators/ColorConfigurator/ColorConfigurator"
import { OptionPickerList } from "../../../../atoms/OptionPicker/OptionPickerList"
import {
  isLayoutAlternating,
  isLayoutAngled,
  isLayoutGrid,
  isLayoutStraight,
} from "../../../../../../render-engine/modules/vd-editor/modules/assets-module/pattern-replicator/layouts"
import { OptionPicker } from "../../../../atoms/OptionPicker/OptionPicker"

const i18n = I18N.editorTools.patterns

export const SettingsSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.replicablePatterns])

  if (!containerSet) {
    return null
  }

  const { replicablePatterns } = containerSet

  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns

  const { isHorizontalSpacingRangeVisible, isVerticalSpacingRangeVisible } =
    replicablePatternUiController
  const {
    artworkScale,
    artworkScaleStep,
    minArtworkScale,
    maxArtworkScale,
    horizontalSpacing,
    horizontalSpacingStep,
    minHorizontalSpacing,
    maxHorizontalSpacing,
    verticalSpacing,
    verticalSpacingStep,
    minVerticalSpacing,
    maxVerticalSpacing,
    isThresholdAvailable,
    imageThreshold,
    patternColor,
    selectedLayoutName,
  } = replicablePatternsStore

  const onArtworkScaleChange = (scale: number) => {
    replicablePatternUiController.setArtworkScale(scale)
  }

  const onHorizontalSpacingChange = (spacing: number) => {
    replicablePatternUiController.setHorizontalSpacing(spacing)
  }

  const onVerticalSpacingChange = (spacing: number) => {
    replicablePatternUiController.setVerticalSpacing(spacing)
  }

  const onThresholdChange = (threshold: number) => {
    replicablePatternUiController.setArtworkThreshold(threshold)
  }

  const onColorSelect = (color: Colour) => {
    replicablePatternUiController.setPatternColor(color)
  }

  const onLayoutChange = (layout: string) => {
    replicablePatternUiController.selectLayoutByPart(layout)
  }

  return (
    <>
      <TabSection>
        <FormGroup label={t(i18n.patternColor)} inline>
          <ColorConfigurator
            selectedColor={patternColor}
            onColorSelect={onColorSelect}
            analyticsSection="left-tab-pattern"
            analyticsName="pattern-color"
          />
        </FormGroup>
        <FormGroup label={t(i18n.artworkSize)} inline>
          <Slider
            value={artworkScale}
            step={artworkScaleStep}
            min={minArtworkScale}
            max={maxArtworkScale}
            onChange={onArtworkScaleChange}
          />
        </FormGroup>
        {isHorizontalSpacingRangeVisible && (
          <FormGroup label={t(i18n.horizontalSpacing)} inline>
            <Slider
              value={horizontalSpacing}
              step={horizontalSpacingStep}
              min={minHorizontalSpacing}
              max={maxHorizontalSpacing}
              onChange={onHorizontalSpacingChange}
            />
          </FormGroup>
        )}
        {isVerticalSpacingRangeVisible && (
          <FormGroup label={t(i18n.verticalSpacing)} inline>
            <Slider
              value={verticalSpacing}
              step={verticalSpacingStep}
              min={minVerticalSpacing}
              max={maxVerticalSpacing}
              onChange={onVerticalSpacingChange}
            />
          </FormGroup>
        )}
        {isThresholdAvailable && (
          <FormGroup label={t(i18n.artworkThreshold)} inline>
            <MonochromeSlider
              value={imageThreshold}
              onChange={onThresholdChange}
            />
          </FormGroup>
        )}
      </TabSection>

      {selectedLayoutName && (
        <>
          <TabSection>
            <FormGroup label={t(i18n.patternPosition)}>
              <OptionPickerList>
                <OptionPicker
                  label={t(i18n.straight)}
                  onClick={() => onLayoutChange("straight")}
                  active={isLayoutStraight(selectedLayoutName)}
                  analyticsSection="left-tab-pattern"
                  analyticsType="option-picker"
                  analyticsName="pattern-position"
                  analyticsId="straight"
                />
                <OptionPicker
                  label={t(i18n.angled)}
                  onClick={() => onLayoutChange("angled")}
                  active={isLayoutAngled(selectedLayoutName)}
                  analyticsSection="left-tab-pattern"
                  analyticsType="option-picker"
                  analyticsName="pattern-position"
                  analyticsId="angled"
                />
              </OptionPickerList>
            </FormGroup>
          </TabSection>

          <TabSection>
            <FormGroup label={t(i18n.layout)}>
              <OptionPickerList>
                <OptionPicker
                  label={t(i18n.regular)}
                  onClick={() => onLayoutChange("grid")}
                  active={isLayoutGrid(selectedLayoutName)}
                  analyticsSection="left-tab-pattern"
                  analyticsType="option-picker"
                  analyticsName="layout"
                  analyticsId="grid"
                />
                <OptionPicker
                  label={t(i18n.shifted)}
                  onClick={() => onLayoutChange("alternating")}
                  active={isLayoutAlternating(selectedLayoutName)}
                  analyticsSection="left-tab-pattern"
                  analyticsType="option-picker"
                  analyticsName="layout"
                  analyticsId="alternating"
                />
              </OptionPickerList>
            </FormGroup>
          </TabSection>
        </>
      )}
    </>
  )
})
