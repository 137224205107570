import {
  PackhelpCanvas,
  PackhelpObject,
} from "../../object-extensions/packhelp-objects"
import VirtualDielineEditor from "../../virtual-dieline-editor"
import { HighlightCreator } from "./highlight.creator"
import {
  EditableSpaceConfig,
  EditContext,
  SpaceId,
} from "../../../../../libs/products-render-config/types"
import { VirtualDielineSpaceNotFoundError } from "../../errors"
import { AvailableRotations } from "../dieline-navigator/calculators/translation.calculator"

export type HighlightMode = "hidden" | "visible"

export interface HighlightDefinition {
  editContext?: EditContext
  spaceId?: SpaceId
  x?: number
  y?: number
}

export class SpaceHighlightModule {
  private mode: HighlightMode = "hidden"
  private activeHighlight?: PackhelpObject

  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public setMode(mode: HighlightMode) {
    this.mode = mode

    for (const highlight of this.getHighlights()) {
      const attrs =
        mode === "hidden"
          ? {
              opacity: 0,
              strokeWidth: 0,
              scaleX: 1,
              scaleY: 1,
            }
          : {
              opacity: 1,
              strokeWidth: 20,
              scaleX: (highlight.width! - 20) / highlight.width!,
              scaleY: (highlight.height! - 20) / highlight.height!,
            }

      highlight.set(attrs)
    }

    this.canvas.renderAll()
  }

  public clearExistingHighlights(): void {
    for (const highlight of this.getHighlights()) {
      this.canvas.remove(highlight)
    }
  }

  public async createHighlights(
    spaceConfigs: EditableSpaceConfig[]
  ): Promise<void> {
    this.clearExistingHighlights()

    const shapeCreator = new HighlightCreator(this.vdEditor.dielineNavigator)

    const spaceIds: SpaceId[] = []

    for (const config of spaceConfigs) {
      spaceIds.push(config.spaceId)

      for (const child of config.children) {
        spaceIds.push(child.spaceId)
      }
    }

    await Promise.all(
      spaceIds.map(async (spaceId, index) => {
        try {
          const highlight = await shapeCreator.create(spaceId, index)

          this.vdEditor.addOnCanvas(highlight)
        } catch (e: any) {
          if (e instanceof VirtualDielineSpaceNotFoundError) {
            return window.Sentry?.captureException(
              new VirtualDielineSpaceNotFoundError(
                `Space defined in render config is not found in virtual dieline svg: ${spaceId}`
              )
            )
          }

          throw e
        }
      })
    )

    this.canvas.requestRenderAll()
  }

  public findSpace({
    spaceId,
    x,
    y,
  }: HighlightDefinition): PackhelpObject | undefined {
    if (spaceId) {
      return this.findSpaceById(spaceId)
    }

    if (x !== undefined && y !== undefined) {
      return this.findSpaceByCoords(x, y)
    }
  }

  public get isSpaceHighlighted(): boolean {
    return !!this.activeHighlight
  }

  public offHighlightSpace(): void {
    if (!this.activeHighlight) {
      return
    }

    this.activeHighlight.set({
      opacity: this.mode === "hidden" ? 0 : 1,
      evented: false,
    })
    this.activeHighlight = undefined
    this.canvas.renderAll()
  }

  public toggleHighlightedSpace(
    cursorDefinition: HighlightDefinition
  ): PackhelpObject | undefined {
    const highlightedSpace = this.findSpace(cursorDefinition)

    if (
      !highlightedSpace ||
      highlightedSpace.id === this.activeHighlight?.id ||
      !this.vdEditor.productRenderPilot.isSpaceEditable(
        this.vdEditor.editContext,
        highlightedSpace.originSpaceArea!
      )
    ) {
      return
    }

    this.activeHighlight = highlightedSpace
    this.activeHighlight.set({
      opacity: this.mode === "hidden" ? 1 : 0.5,
      evented: true,
      hoverCursor: "pointer",
    })

    this.canvas.renderAll()

    return highlightedSpace
  }

  private findSpaceById(spaceId: string): PackhelpObject | undefined {
    return this.getHighlights().find(
      (space) => space.originSpaceArea === spaceId
    )
  }

  private findSpaceByCoords(x: number, y: number): PackhelpObject | undefined {
    return this.getHighlights().find((space) => {
      const angle = space.angle!
      const left = space.left!
      const top = space.top!
      const width = space.width!
      const height = space.height!

      if (Math.abs(angle) === AvailableRotations.upsideDown) {
        return left > x && left - width < x && top > y && top - height < y
      }

      if (angle === AvailableRotations.verticalLeft) {
        return left > x && left - height < x && top < y && top + width > y
      }

      if (angle === AvailableRotations.verticalRight) {
        return left < x && left + height > x && top > y && top - width < y
      }

      return left < x && left + width > x && top < y && top + height > y
    })
  }

  private getHighlights(): PackhelpObject[] {
    return this.canvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.includes("temp_background_"))
  }

  private get canvas(): PackhelpCanvas {
    return this.vdEditor.fabricCanvas
  }
}
