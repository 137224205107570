import { config as fefco201 } from "../default-configs/fefco-201"
import { config as fefco427 } from "../default-configs/fefco-427"
import { config as tuckTopSnapLockBottom } from "../default-configs/tuck-top-snap-lock-bottom"
import { config as fefco215 } from "../default-configs/fefco-215"
import { config as reverseTuckEnd } from "../default-configs/reverse-tuck-end"
import { config as tuckTopAutoBottom } from "../default-configs/tuck-top-auto-bottom"
import { config as doubleSidewallDoubleBottom } from "../default-configs/double-sidewall-double-bottom"
import { config as doubleSidewall } from "../default-configs/double-sidewall"
import { config as sleeve } from "../default-configs/sleeve"
import { config as pizzaBox } from "../default-configs/pizza-box"

export const configs = {
  fefco201,
  fefco427,
  tuckTopSnapLockBottom,
  fefco215,
  reverseTuckEnd,
  tuckTopAutoBottom,
  doubleSidewallDoubleBottom,
  doubleSidewall,
  sleeve,
  pizzaBox,
}
