import fabric from "../../vendors/Fabric"
import { Pattern } from "../../../models/pattern"
import { PackhelpPatternContainer } from "../../../render-engine/modules/vd-editor/object-extensions/packhelp-base-objects"
import { CANVAS_DIM } from "../../../render-engine/types"
import { isGroup } from "../../../types/asset.types"
import { PackhelpGroup } from "../../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"

type Size = {
  width: number
  height: number
}

type Offset = {
  left: number
  top: number
}

export type ProcessArgs = {
  patternSizePx?: number
  scaleFactor?: number
  containerSize?: Size
  offset?: Offset
}

export class PatternGenerator {
  static PATTERN_DEFAULT_SIZE = CANVAS_DIM
  static PATTERN_DEFAULT_OFFSET = 0
  static PATTERN_SOURCE_FORMAT = ".png"
  static HDPI_FACTOR = 2
  static PATTERN_REPEAT = "repeat"

  private patternFabricSVG?: fabric.Group | fabric.Object

  constructor(private readonly pattern: Pattern) {}

  public loadPattern(): Promise<fabric.Object | fabric.Group> {
    return new Promise((resolve, reject) => {
      fabric.loadSVGFromURL(this.pattern.url, async (objects, options) => {
        this.patternFabricSVG = fabric.util.groupSVGElements(objects, options)

        if (typeof this.patternFabricSVG === "undefined") {
          reject(new Error("Pattern svg is undefined"))
        }

        resolve(this.patternFabricSVG)
      })
    })
  }

  public async process({
    patternSizePx = 64,
    scaleFactor = 1,
    containerSize = {
      width: PatternGenerator.PATTERN_DEFAULT_SIZE,
      height: PatternGenerator.PATTERN_DEFAULT_SIZE,
    },
    offset = {
      left: PatternGenerator.PATTERN_DEFAULT_OFFSET,
      top: PatternGenerator.PATTERN_DEFAULT_OFFSET,
    },
  }: ProcessArgs): Promise<{
    patternContainer: PackhelpPatternContainer
    source: fabric.Group | fabric.Object
  }> {
    let patternSourceSvg = this.patternFabricSVG

    if (typeof patternSourceSvg === "undefined") {
      patternSourceSvg = await this.loadPattern()
    }

    if (typeof patternSourceSvg === "undefined") {
      throw new Error("You're trying to process svg that's not initialized")
    }

    patternSourceSvg.set({
      strokeWidth: 0,
    })

    patternSourceSvg.scaleToWidth(patternSizePx * PatternGenerator.HDPI_FACTOR)

    const patternWithColoursConfig = this.colorsBuilder(patternSourceSvg)
    const patternContainer = this.buildPatternContainer(
      patternSizePx,
      containerSize,
      offset
    )
    const patternSource = patternWithColoursConfig.toDataURL({
      multiplier: scaleFactor,
      format: PatternGenerator.PATTERN_SOURCE_FORMAT,
      enableRetinaScaling: false,
    })

    return new Promise((resolve) => {
      return fabric.util.loadImage(patternSource, (patternImg) => {
        patternContainer.set(
          "fill",
          new fabric.Pattern({
            source: patternImg,
            repeat: PatternGenerator.PATTERN_REPEAT,
          })
        )

        patternContainer.scaleToWidth(containerSize.width)

        resolve({
          patternContainer,
          source: patternWithColoursConfig,
        })
      })
    })
  }

  private buildPatternContainer(
    patternSizePx: number,
    containerSize: Size,
    offset: Offset
  ) {
    return new PackhelpPatternContainer({
      patternSourceConfig: this.pattern.toSource(),
      patternSizePx,
      width: containerSize.width * PatternGenerator.HDPI_FACTOR,
      height: containerSize.height * PatternGenerator.HDPI_FACTOR,
      strokeWidth: 0,
      top: offset.top,
      left: offset.left,
    })
  }

  private colorsBuilder(
    patternSourceSvg: fabric.Group | fabric.Object
  ): fabric.Group | fabric.Object {
    if (isGroup(patternSourceSvg)) {
      return this.groupElementColorsBuilder(patternSourceSvg)
    }

    return this.singleElementColorsBuilder(patternSourceSvg)
  }

  private groupElementColorsBuilder(
    patternSourceSvg: PackhelpGroup
  ): PackhelpGroup {
    const patternColoursConfig = this.pattern.colorsConfig
    Object.keys(patternColoursConfig).forEach((key) => {
      const currentObjects = patternSourceSvg
        .getObjects()
        .filter((obj) => obj.id === key)
      const currentColourConfig = patternColoursConfig[key]
      currentObjects.forEach((object) => {
        object[currentColourConfig.param] = currentColourConfig.colour.getHex()
        object["colourConfig"] = currentColourConfig.colour.toSource()
      })
    })

    return patternSourceSvg
  }

  private singleElementColorsBuilder(
    patternSourceSvg: fabric.Object
  ): fabric.Object {
    const patternColoursConfig = this.pattern.colorsConfig
    const item = Object.values(patternColoursConfig)[0]

    if (typeof item === "undefined") {
      throw new Error("Pattern is wrong format")
    }

    patternSourceSvg[item.param] = item.colour.getRgb()
    patternSourceSvg["colourConfig"] = item.colour.toSource()

    return patternSourceSvg
  }
}
