import { ProductManager, Variant } from "@ph/product-api"
import { AppConfig } from "../app-config/app.config"

type Estimation = {
  id?: number
  value: number
  currency: string
  shippingIncluded: boolean
  taxRate?: number
}

export class QuoteRequestFormUrlProvider {
  constructor(
    private readonly appConfig: AppConfig,
    private readonly product: ProductManager
  ) {}

  public call({
    quantity,
    designId,
    estimation,
  }: {
    quantity: number
    designId?: string | number
    estimation?: Estimation
  }): string | undefined {
    if (!this.baseUrl) {
      return
    }

    const url = new URL(this.baseUrl)

    this.appendContext(url)
    this.appendSku(url)
    this.appendQuantity(url, quantity)
    this.appendCustomSize(url)
    this.appendDesignId(url, designId)
    this.appendEstimation(url, estimation)

    return url.toString()
  }

  private appendSku(url: URL) {
    url.searchParams.append("sku", this.variant.sku)
  }

  private appendQuantity(url: URL, quantity: number) {
    url.searchParams.append(
      "quantity",
      (quantity * this.variant.piecesPerUnit).toString()
    )
  }

  private appendCustomSize(url: URL) {
    const { size } = this.variant

    if (!size.isCustom) {
      return
    }

    const getKey = (type: "value" | "unit", dimension: string) => {
      return `custom[size][${dimension}][${type}]`
    }

    for (const dimension of ["width", "height", "length", "diameter"]) {
      const value = size[dimension]

      if (!value) {
        continue
      }

      url.searchParams.append(getKey("value", dimension), value)
      url.searchParams.append(getKey("unit", dimension), size.units.length)
    }
  }

  private appendDesignId(url: URL, designId?: string | number) {
    if (!designId) {
      return
    }

    url.searchParams.append("design_id", designId.toString())
  }

  private appendContext(url: URL) {
    url.searchParams.append("context", "editor")
  }

  private appendEstimation(url: URL, estimation?: Estimation) {
    if (!estimation) {
      return
    }

    if (estimation.id) {
      url.searchParams.append("estimation_id", estimation.id.toString())
    }

    url.searchParams.append("estimation_value", estimation.value.toString())
    url.searchParams.append(
      "estimation_currency",
      estimation.currency.toString()
    )
    url.searchParams.append(
      "estimation_shipping_included",
      estimation.shippingIncluded.toString()
    )
    if (estimation.taxRate) {
      url.searchParams.set("estimation_tax", estimation.taxRate.toString())
    }
  }

  private get baseUrl(): string | undefined {
    return this.appConfig.locale.getUrl("quoteRequestForm")
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }
}
