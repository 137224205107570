import { Template } from "../../models/template"

// Events
export enum TemplateEvents {
  loaded = "ph.template.loaded",
  removed = "ph.template.removed",
  applied = "ph.template.applied",
  modified = "ph.template.modified",
  popupClicked = "ph.template.popup.clicked",
  selected = "ph.template.selected",
  designerModeLoaded = "ph.templates.designer-mode.loaded",
}

export type PopUpType = "change" | "remove"
export type ButtonClicked = "yes" | "no"
export type ScrollActionType = "scroll" | "click"
export type ScrollDirection = "up" | "down"

export type TemplatesEventsTypes = {
  [TemplateEvents.loaded]: () => void
  [TemplateEvents.removed]: (template: Template, isPristine: boolean) => void
  [TemplateEvents.applied]: (template: Template, isPristine: boolean) => void
  [TemplateEvents.modified]: (template: Template, isPristine: boolean) => void
  [TemplateEvents.popupClicked]: (
    popUpType: PopUpType,
    buttonClicked: ButtonClicked
  ) => void
  [TemplateEvents.selected]: () => void
  [TemplateEvents.designerModeLoaded]: () => void
}
