import React from "react"
import styles from "./Aside.module.scss"
import { SidebarLoader } from "../Sidebar/SidebarLoader"
import { TabLoader } from "../tabs/Tab/TabLoader"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export function AsideLoader() {
  return (
    <div className={cx("wrapper", "normal", "open")}>
      <SidebarLoader />
      <div className={cx("aside", "open")}>
        <TabLoader />
      </div>
    </div>
  )
}
