import { TypedEmitter, EESet } from "./typed.event-emitter"
import { SessionEventNames, SessionEventTypes } from "./partials/session.events"
import {
  ProductDesignEvents,
  ProductDesignTypes,
} from "./partials/product-design.events"
import { PatternEvents, PatternsEventsTypes } from "./partials/patterns.events"
import {
  ProductDriverEvents,
  ProductDriverTypes,
} from "./partials/product-driver.events"
import {
  TemplateEvents,
  TemplatesEventsTypes,
} from "./partials/templates.events"
import { TabEvents, TabEventsTypes } from "./partials/editor-toolbar.events"
import { LogoEvents, LogoEventsTypes } from "./partials/logo.events"
import {
  NotificationEvents,
  NotificationEventsTypes,
} from "./partials/notification.events"

import { CartEvents, CartEventsTypes } from "./partials/cart.events"
import {
  EditorUIViewEvents,
  EditorUIViewTypes,
  GraphicAssetEvents,
  GraphicAssetTypes,
} from "./partials/domain.events"
import {
  BackgroundImageEvents,
  BackgroundImageEventTypes,
} from "./partials/background-image.events"
import { KeyboardEventTypes, KeyboardEvents } from "./partials/keyboard.events"
import {
  ActiveObjectEvents,
  ActiveObjectEventTypes,
} from "./partials/active-object.events"
import {
  ActiveTabEvents,
  ActiveTabEventTypes,
} from "./partials/active-tab.events"
import {
  FscCertificateEvents,
  FscCertificateEventsTypes,
} from "./partials/fsc-certificate.events"
import { PricingEvents, PricingEventsTypes } from "./partials/pricing.events"

type EditorEvents = SessionEventTypes &
  ProductDesignTypes &
  CartEventsTypes &
  PatternsEventsTypes &
  TemplatesEventsTypes &
  LogoEventsTypes &
  ProductDriverTypes &
  GraphicAssetTypes &
  EditorUIViewTypes &
  TabEventsTypes &
  BackgroundImageEventTypes &
  KeyboardEventTypes &
  ActiveObjectEventTypes &
  ActiveTabEventTypes &
  NotificationEventsTypes &
  FscCertificateEventsTypes &
  PricingEventsTypes

export type EditorEventNames = keyof EditorEvents

export type AllEditorEventsEmitter = EESet<EditorEvents>

export const eventTree = {
  session: SessionEventNames,
  pd: ProductDesignEvents,
  cart: CartEvents,
  patterns: PatternEvents,
  templates: TemplateEvents,
  logo: LogoEvents,
  productDriver: ProductDriverEvents,
  ui: EditorUIViewEvents,
  graphics: GraphicAssetEvents,
  tab: TabEvents,
  backgroundImages: BackgroundImageEvents,
  keyboard: KeyboardEvents,
  activeObject: ActiveObjectEvents,
  activeTab: ActiveTabEvents,
  notification: NotificationEvents,
  fsc: FscCertificateEvents,
  pricing: PricingEvents,
}

export const ee: AllEditorEventsEmitter = new TypedEmitter()
