import React, { ReactNode, RefObject } from "react"
import { Direction, QuantityItem } from "../../types"
import { PickerOptionsModal } from "./PickerOptionsModal"
import { PickerOptionsDropdown } from "./PickerOptionsDropdown"

interface PickerOptionsProps {
  confirmLabel?: string
  consise?: boolean
  direction?: Direction
  footer?: ReactNode
  headerLabel?: string
  isMobile?: boolean
  isOpen: boolean
  listRef?: RefObject<HTMLUListElement>
  match?: QuantityItem
  onClose: () => void
  onItemAction?: (item: QuantityItem) => void
  onSearchChange?: (value?: number) => void
  onSearchConfirm?: () => void
  onSelect: (item: QuantityItem) => void
  options: QuantityItem[]
  pickerRef?: RefObject<HTMLDivElement>
  scrollRef?: RefObject<HTMLDivElement>
  searchPlaceholder?: string
  searchRef?: RefObject<HTMLInputElement>
  searchValue?: string
  value: QuantityItem | undefined
}
export const PickerOptions = (props: PickerOptionsProps) => {
  const {
    confirmLabel,
    consise,
    direction,
    footer,
    headerLabel,
    isMobile,
    isOpen,
    listRef,
    pickerRef,
    match,
    onClose,
    onItemAction,
    onSearchChange,
    onSearchConfirm,
    onSelect,
    options,
    scrollRef,
    searchPlaceholder,
    searchRef,
    searchValue,
    value
  } = props

  return isMobile ? (
    <PickerOptionsModal
      value={value}
      match={match}
      isOpen={isOpen}
      searchValue={searchValue}
      headerLabel={headerLabel}
      confirmLabel={confirmLabel}
      searchPlaceholder={searchPlaceholder}
      options={options}
      footer={footer}
      onSelect={onSelect}
      onItemAction={onItemAction}
      onClose={onClose}
      onSearchChange={onSearchChange}
      onSearchConfirm={onSearchConfirm}
      listRef={listRef}
      searchRef={searchRef}
      scrollRef={scrollRef}
    />
  ) : (
    <PickerOptionsDropdown
      consise={consise}
      direction={direction}
      value={value}
      options={options}
      isOpen={isOpen}
      searchPlaceholder={searchPlaceholder}
      footer={footer}
      searchValue={searchValue}
      match={match}
      pickerRef={pickerRef}
      onSelect={onSelect}
      onItemAction={onItemAction}
      onSearchChange={onSearchChange}
      onSearchConfirm={onSearchConfirm}
      listRef={listRef}
      searchRef={searchRef}
      scrollRef={scrollRef}
    />
  )
}
