import { ApiSessionContainer } from "./api-session.container"
import type { AuthController } from "../controllers/auth.controller"
import type { AuthApp } from "auth/src/App"
import { UtilEnvContainer } from "./util.container"

async function importDeps() {
  const { AuthController } = await import("../controllers/auth.controller")
  const { AuthApp } = await import("auth/src/App")

  return { AuthController, AuthApp }
}

export async function provideAuthMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer
): Promise<AuthMaybeContainer> {
  const { appConfig } = utilEnvContainer

  if (!appConfig.api.ecommerce.features.auth) {
    return {
      available: false,
      authController: undefined,
      AuthApp: undefined,
    }
  }

  const { AuthController, AuthApp } = await importDeps()

  const authController = new AuthController(apiSessionContainer.sessionStore)

  return {
    available: true,
    authController,
    AuthApp,
  }
}

export type AuthMaybeContainer =
  | {
      available: true
      authController: AuthController
      AuthApp: typeof AuthApp
    }
  | {
      available: false
      authController: undefined
      AuthApp: undefined
    }
