export const events = {
  REGISTRATION_COMPLETED_EVENT_TYPE: {
    PLUS: "Plus",
    ONLINE: "Online",
  },

  PRODUCT_RECOMMENDATION_TYPES: {
    UPSELLING: "upselling",
    CROSS_SELL: "cross-sell",
  },

  RFM_POTENTIAL_LOYALIST: "Potential Loyalist",
  RFM_NEW_CUSTOMERS: "New Customers",
}

export enum EnumClickEvent {
  CLICK_OUTSIDE_MODAL = "modal",
  CLICK_OUTSIDE_COLOR_PICKER = "click.colorPicker",
  CLICK_OUTSIDE_FILES_LIBRARY = "click.filesLibrary",
}

export enum EnumKeyAsciiCode {
  KEY_ENTER = 13,
  KEY_ESCAPE = 27,
}

export enum EnumKeyEventName {
  KEYDOWN_ON_MODAL = "keydown.modalChild",
}
