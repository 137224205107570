import React, { PropsWithChildren } from "react"
import styles from "./TabSection.module.scss"
import { AnalyticsProps, TestsProps } from "../../../types"

export interface LinkProps extends AnalyticsProps, TestsProps {
  label: string
  onClick: () => void
}

export interface TabSectionProps extends TestsProps {
  title?: string
  link?: LinkProps
}

export function TabSection({
  children,
  title,
  link,
  e2eTarget = "tab-section",
  e2eTargetName,
}: PropsWithChildren<TabSectionProps>) {
  const isHeaderVisible = title || link

  return (
    <section
      className={styles.wrapper}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {isHeaderVisible && (
        <header className={styles.header}>
          {title && <h3 className={styles.title}>{title}</h3>}
          {link && (
            <button
              className={styles.link}
              onClick={link.onClick}
              analytics-section={link.analyticsSection}
              analytics-type={link.analyticsType}
              analytics-name={link.analyticsName}
              analytics-id={link.analyticsId}
              e2e-target={link.e2eTarget}
              e2e-target-name={link.e2eTargetName}
            >
              {link.label}
            </button>
          )}
        </header>
      )}

      {children}
    </section>
  )
}
