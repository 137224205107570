import { VirtualDielineObject } from "../../../types/asset.types"

export class VirtualDielineObjectParser {
  constructor(private readonly object: VirtualDielineObject) {}

  public parse() {
    if (this.object.path) {
      this.parsePath(this.object.path)
    }

    if (this.object.objects) {
      for (const groupObject of this.object.objects) {
        new VirtualDielineObjectParser(groupObject).parse()
      }
    }

    if (this.object.clipPath) {
      new VirtualDielineObjectParser(
        this.object.clipPath as VirtualDielineObject
      ).parse()
    }
  }

  /**
   * BigNumbers are converted to string in JSON, so we need to change them to numbers.
   */
  private parsePath(path: number[][]) {
    for (const line of path) {
      for (const [index, coord] of line.entries()) {
        if (index === 0) {
          continue
        }

        line[index] = Number(coord)
      }
    }
  }
}
