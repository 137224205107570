import _indexOf from "lodash/indexOf"
import _isString from "lodash/isString"
import _isObject from "lodash/isObject"
import _omit from "lodash/omit"
import _isEmpty from "lodash/isEmpty"
import _reject from "lodash/reject"
import URI from "urijs"
// don't refactor to import. Swift death will follow
const queryString = require("query-string")

type TQueryStringGetStringValueAllowedTypes = string | string[] | undefined

type TQueryStringSetAllowedTypes =
  | string
  | {
      [key: string]: TQueryStringGetStringValueAllowedTypes
    }

export interface UrlManipulatorProps {
  getUrl: () => string
  getPath: () => string
  replaceState: () => UrlManipulatorProps
  redirect: (params: { keepBrowsingHistory: boolean }) => UrlManipulatorProps
  getQueryStringValue(key: string): TQueryStringGetStringValueAllowedTypes
  setQueryString(qs: TQueryStringSetAllowedTypes): UrlManipulatorProps
  removeQueryStringParameter(key: string): UrlManipulatorProps
}

export class UrlManipulator implements UrlManipulatorProps {
  private url: string = ""

  static init(url?: string) {
    return new UrlManipulator({ url })
  }

  constructor(args: { url?: string }) {
    this.url = args.url || window.location.href
  }

  /**
   * Due to really unpredicted way of building npm query-string library with landing page,
   * the older version of query-string gets evaluted by building scritps,
   * making parseUrl() function unavailable - it is much better to write
   * ad-hoc "polyfill" function that fight the build function
   *
   * Make tests pass when removing this method !
   */
  private parseUrl(url: string): { url: string; query: string } {
    const parts = url.split("?")

    return {
      url: parts.length >= 1 ? parts[0] : "",
      query: parts.length > 1 ? parts[1] : "",
    }
  }

  public getUrl(): string {
    return this.url || ""
  }

  public getPath(): string {
    return URI(this.url).pathname()
  }

  public reload() {
    window.location.reload()
  }

  public replaceState(): UrlManipulatorProps {
    window.history.replaceState({}, document.title, this.url)

    return this
  }

  public redirect(
    { keepBrowsingHistory } = { keepBrowsingHistory: true }
  ): UrlManipulatorProps {
    switch (keepBrowsingHistory) {
      case false:
        window.location.href = this.url
        break
      case true:
        window.location.assign(this.url)
        break
    }

    return this
  }

  public getQueryStringValue(
    key: string
  ): TQueryStringGetStringValueAllowedTypes {
    const qs = this.url.slice(_indexOf(this.url, "?") + 1)

    const result = queryString.parse(qs)

    // @ts-ignore
    return result[key] || undefined
  }

  private setQueryStringByObject(qs: {
    [key: string]: TQueryStringGetStringValueAllowedTypes
  }): UrlManipulatorProps {
    const parsed = this.parseUrl(this.url)

    const queryStringObject = queryString.parse(parsed.query)

    const stringifedQuery = queryString.stringify({
      ...queryStringObject,
      ...qs,
    })

    this.url = `${parsed.url}?${stringifedQuery}`

    return this
  }

  public setQueryString(qs: TQueryStringSetAllowedTypes): UrlManipulatorProps {
    if (_isString(qs)) {
      // @ts-ignore
      return this.setQueryStringByObject(queryString.parse(qs))
    }

    if (_isObject(qs)) {
      // @ts-ignore
      return this.setQueryStringByObject(qs)
    }

    return this
  }

  public removeQueryStringParameter(key: string): UrlManipulatorProps {
    const parsed = this.parseUrl(this.url)

    const queryStringObject = queryString.parse(parsed.query)

    const urlParts = [
      parsed.url,
      queryString.stringify(_omit(queryStringObject, key)),
    ]

    this.url = _reject(urlParts, _isEmpty).join("?")

    return this
  }

  public domainSuffix = (): string => {
    let domainSuffix = URI(this.url).tld()

    if (domainSuffix === "to") {
      domainSuffix = "pl"
    }

    return domainSuffix
  }
}

export const redirectedFromCartByUpsellClick = () => {
  return UrlManipulator.init().getQueryStringValue("origin") === "cart"
}

type GetQueryStringValue = (query: string, url?: string) => string | string[]

export const getQueryStringValue: GetQueryStringValue = (
  query,
  url = window.location.href
) => queryString.parseUrl(url).query[query]
