import {
  ProductManager,
  ProductFactory,
  VariantCustomization,
} from "@ph/product-api"
import { action, computed, makeObservable, observable } from "mobx"
import { AppConfig } from "../app-config/app.config"
import { DESIGNER_MODE_FAKE_SKU } from "../libs/products-render-config/types"

export class ProductStore {
  @observable public product!: ProductManager

  private constructor(private readonly appConfig: AppConfig) {
    makeObservable(this)
  }

  public static async init(
    appConfig: AppConfig,
    sku: string,
    customization?: VariantCustomization
  ): Promise<ProductStore> {
    const productStore = new ProductStore(appConfig)
    await productStore.setProductBySku(sku, customization)

    return productStore
  }

  @computed
  public get isCustomSizeEnabled(): boolean {
    return (
      this.appConfig.api.ecommerce.features.ecommerce.customSizes &&
      this.product.isEditorCustomSizeEnabled
    )
  }

  public async setProductBySku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<ProductManager> {
    const product = await this.loadProduct(sku, customization)

    this.setProduct(product)

    return product
  }

  @computed
  public get productSku(): string {
    return this.product.variantManager.getSku()
  }

  @computed
  public get customization(): VariantCustomization | undefined {
    return this.product.getDefaultVariant().customization
  }

  private async loadProduct(
    sku: string,
    customization?: VariantCustomization
  ): Promise<ProductManager> {
    try {
      return this.product.reload(sku, customization)
    } catch (e: any) {
      return new ProductFactory({
        api: {
          ...this.appConfig.api.pim,
          lang: this.appConfig.locale.lang,
          region: this.appConfig.locale.region,
          salesChannelId: this.appConfig.locale.salesChannelId,
        },
        propertiesToExpand: [
          "variants",
          "configurable_properties",
          "pss",
          "editor_assets",
          "editor_related_products",
        ],
        withEmptyPricing: true,
        withUnavailable: sku === DESIGNER_MODE_FAKE_SKU,
        expandDefaultProperties: false,
      }).call(sku, customization)
    }
  }

  @action
  private setProduct(product: ProductManager): void {
    this.product = product
  }
}
