import _toNumber from "lodash/toNumber"

export const getValueInEuro = (value: string, currency: string): number => {
  const gbpRate = 1.15
  const plnRate = 0.23
  const ronRate = 0.2
  const sekRate = 0.089

  const convertValueToEuro = (rate: number): number =>
    _toNumber((parseFloat(value) * rate).toFixed(2))

  switch (currency) {
    case "GBP": {
      return convertValueToEuro(gbpRate)
    }
    case "PLN": {
      return convertValueToEuro(plnRate)
    }
    case "SEK": {
      return convertValueToEuro(sekRate)
    }
    case "RON": {
      return convertValueToEuro(ronRate)
    }
    case "EUR":
    default: {
      return parseFloat(value)
    }
  }
}
