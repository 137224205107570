import React, {
  PropsWithChildren,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react"
import styles from "./Picker.module.scss"
import { ContextNav } from "../../atoms/ContextNav/ContextNav"
import { ContextNavItem } from "../../atoms/ContextNav/ContentNavItem"
import { AnalyticsProps, TestsProps } from "../../types"

export interface LinkProps extends AnalyticsProps, TestsProps {
  label: string
  onClick: () => void
}

export interface PickerProps {
  link?: LinkProps
}

export function Picker({ children, link }: PropsWithChildren<PickerProps>) {
  const ref = useRef<HTMLDivElement>(null)

  const scrollPosition = useMemo(() => {
    if (ref.current) {
      return ref.current.scrollTop
    }

    return 0
  }, [children])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.scrollTop = scrollPosition
      }
    })
  }, [children])

  return (
    <div className={styles.wrapper}>
      <div className={styles.body} ref={ref}>
        {children}
      </div>

      {link && (
        <ContextNav>
          <ContextNavItem
            label={link.label}
            onClick={link.onClick}
            analyticsSection={link.analyticsSection}
            analyticsType={link.analyticsType}
            analyticsName={link.analyticsName}
            analyticsId={link.analyticsId}
            e2eTarget={link.e2eTarget}
            e2eTargetName={link.e2eTargetName}
          />
        </ContextNav>
      )}
    </div>
  )
}
