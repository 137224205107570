import { AbstractCreator } from "./abstract.creator"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import {
  IndexConfigFragments,
  PackhelpEditableObject,
  PackhelpEditableRect,
  VirtualDielineSpace,
} from "../../../object-extensions/packhelp-objects"
import { SpaceBoundingRect } from "../../dieline-navigator/calculators/translation.calculator"
import fabric from "../../../../../../libs/vendors/Fabric"
import { isObjectPath, isObjectRect } from "../../../../../../types/asset.types"

/**
 * Creates a safe zone object for calculation purposes only.
 *
 * Safe operating zone object dimensions = {Space dimensions} - 2 * {Safe zone border/stroke width}.
 *
 * E.g.
 * - Space = { left: 0, top: 0, width: 100, height: 100 }
 * - Safe zone size = 5
 *
 * - Safe operating zone object = { left: 5, top: 5, width: 90, height: 90 }
 */
export class SafeOperatingZoneForCalculationCreator extends AbstractCreator {
  public async create(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpEditableObject> {
    const vdSpace = this.dielineNavigator.getVirtualDielineSpace(spaceId)

    if (isObjectRect(vdSpace)) {
      return this.createForRect(spaceId, index)
    }

    if (isObjectPath(vdSpace)) {
      return this.createForPath(spaceId, index)
    }

    return this.createForPolygon(vdSpace, spaceId, index)
  }

  private async createForRect(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpEditableRect> {
    const safeZone = (await this.cloneVdSpace(spaceId)) as PackhelpEditableRect
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)

    safeZone.set({
      ...params,
      rx: (params.width / vdSpaceBoundingRect.width) * safeZone.rx!,
      ry: (params.height / vdSpaceBoundingRect.height) * safeZone.ry!,
    })

    return safeZone
  }

  private async createForPath(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpEditableObject> {
    const safeZone = await this.cloneVdSpace(spaceId)
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)
    const { scaleX, scaleY } = this.getClipPathParams(
      spaceId,
      vdSpaceBoundingRect
    )

    safeZone.set({
      ...params,
      scaleX,
      scaleY,
    })

    return safeZone
  }

  private async createForPolygon(
    vdSpace: VirtualDielineSpace,
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpEditableObject> {
    const vdSpaceBoundingRect = this.getVdSpaceBoundingRect(spaceId)
    const params = this.getParams(spaceId, vdSpaceBoundingRect, index)
    const scaleX = params.width / vdSpaceBoundingRect.width
    const scaleY = params.height / vdSpaceBoundingRect.height

    const points = vdSpace.points!.map((point) => {
      return {
        x: point.x * scaleX,
        y: point.y * scaleY,
      }
    })

    return new fabric.Polygon(
      points,
      params
    ) as unknown as PackhelpEditableObject
  }

  private getParams(
    spaceId: SpaceId,
    vdSpaceBoundingRect: SpaceBoundingRect,
    index: number
  ) {
    const safeZoneSize = this.getSafeZoneSize(spaceId, vdSpaceBoundingRect)
    const safeZoneWidth =
      vdSpaceBoundingRect.width - safeZoneSize.left - safeZoneSize.right
    const safeZoneHeight =
      vdSpaceBoundingRect.height - safeZoneSize.top - safeZoneSize.bottom
    const safeZoneLeft = vdSpaceBoundingRect.left + safeZoneSize.left
    const safeZoneTop = vdSpaceBoundingRect.top + safeZoneSize.top

    return {
      left: safeZoneLeft,
      top: safeZoneTop,
      width: safeZoneWidth,
      height: safeZoneHeight,
      fill: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      opacity: 1,
      evented: false,
      selectable: false,
      id: `safe_operating_zone_${spaceId}`,
      originSpaceArea: spaceId,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
    }
  }
}
