import React, { useMemo } from "react"
import _isUndefined from "lodash/isUndefined"
import Badge, { BadgeColorTypes } from "../../../atoms/Badge/Badge"
import baseStyles from "../../../atoms/Select/Select.module.scss"
import Typography, { TType } from "../../../atoms/Typography/Typography"

import styles from "../SelectQty.module.scss"
import { SelectItemData } from "../../../atoms/Select/types"
import Stepper, {
  StepColor,
  StepsEnumType
} from "../../../atoms/Stepper/Stepper"

/**
 * How selected item should be rendered
 * (where rendered "selected" means "not rendered in the popup")
 */
export interface SelectedItemBehaviour {
  renderBadge: boolean
  renderPrice: boolean
  renderPriceInSearch?: boolean
  renderSelectedIndicator?: boolean
}
interface ItemProps extends React.HTMLProps<HTMLLIElement> {
  item: SelectItemData
  itemRenderProp?: (item: SelectItemData) => React.ReactNode
  isSelected?: boolean
  selectedItemBehaviour?: SelectedItemBehaviour
  hidePricingDetails?: boolean
  tax?: number
}

export const getPriceWithTax = (price: number, tax: any) => {
  return tax ? price * (1 + tax / 100) : price
}

export const getPrice = (item: SelectItemData, tax?: any) => {
  let totalItemPrice =
    item.data.qty *
    (tax ? getPriceWithTax(item.data.price, tax) : item.data.price)

  if (typeof item.data.priceTotalAdjuster === "number") {
    const priceTotalAdjuster = item.data.priceTotalAdjuster
    totalItemPrice += tax
      ? getPriceWithTax(priceTotalAdjuster, tax)
      : priceTotalAdjuster
  }

  const formattedPrice = `$ ${totalItemPrice.toFixed(2)}`

  if (item.data.oldPrice) {
    return oldPrice(formattedPrice, item, tax)
  } else {
    return formattedPrice
  }
}

const oldPrice = (price: string, item: SelectItemData, tax?: any) => {
  const oldPrice =
    item.data.qty *
    (tax ? getPriceWithTax(item.data.oldPrice, tax) : item.data.oldPrice)
  return (
    <>
      <span>{price}</span>
      <span className={styles["oldprice"]}>{`$ ${oldPrice.toFixed(2)}`}</span>
    </>
  )
}

const Item = (props: ItemProps) => {
  const {
    item,
    isSelected,
    selectedItemBehaviour,
    itemRenderProp,
    tax,
    hidePricingDetails
  } = props

  const renderBadge = () => {
    if (item.data.isCustomValue) {
      return true
    } else {
      if (hidePricingDetails) {
        return false
      }
      if (!isSelected) {
        return item.tag && true
      }
      return (
        item.tag &&
        isSelected &&
        !_isUndefined(selectedItemBehaviour) &&
        selectedItemBehaviour.renderBadge
      )
    }
  }

  const renderPrice = () => {
    if (hidePricingDetails) {
      return false
    }
    if (item.data.isCustomValue) {
      return false
    }
    if (!isSelected) {
      return true
    }
    return (
      isSelected &&
      !_isUndefined(selectedItemBehaviour) &&
      selectedItemBehaviour.renderPrice
    )
  }

  const shouldRenderSelectedIndicator = useMemo(
    () =>
      isSelected &&
      selectedItemBehaviour &&
      selectedItemBehaviour.renderSelectedIndicator,
    [isSelected, selectedItemBehaviour]
  )

  return (
    <div className={styles["item-container"]}>
      <div
        className={styles["item-wrap"]}
        e2e-target="list-item"
        e2e-target-name={item.name}
      >
        <Typography type={TType.Body15_350} e2e-target="name">
          {item.name}
        </Typography>
        <div className={baseStyles.details}>
          <>
            {item.specialTag && (
              <div className={baseStyles.badge} e2e-target="quantity-badge">
                {item.specialTag}
              </div>
            )}
            {renderBadge() && (
              <div className={baseStyles.badge} e2e-target="quantity-badge">
                <Badge badgeColor={item.tagColor || BadgeColorTypes.WhiteFour}>
                  {item.tag}
                </Badge>
              </div>
            )}
            {renderPrice() && (
              <div className={baseStyles.price} e2e-target="quantity-price">
                {itemRenderProp ? itemRenderProp(item) : getPrice(item, tax)}
              </div>
            )}
            {shouldRenderSelectedIndicator && (
              <div className={styles["selected-item-indicator"]}>
                <Stepper
                  color={StepColor.LightishBlue}
                  status={StepsEnumType.Completed}
                />
              </div>
            )}
          </>
        </div>
      </div>
      {item.itemFooter && (
        <div className={styles["item-footer"]}>{item.itemFooter}</div>
      )}
    </div>
  )
}

export { Item, Item as default }
