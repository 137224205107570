/** @format */
// product sku def
import SkuFinish from "./polyMailerSkuDefinitions/SkuFinish";
import SkuMaterial from "./polyMailerSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./polyMailerSkuDefinitions/SkuPrintMode";
import SkuModel from "./polyMailerSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./polyMailerPartsDefinitions/PartFinish";
import PartMaterial from "./polyMailerPartsDefinitions/PartMaterial";
import PartPrintMode from "./polyMailerPartsDefinitions/PartPrintMode";
import PartModel from "./polyMailerPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var POLY_MAILER_CONSTS = {
    SKU_BASE: "poly-mailer-",
    PRODUCT_TYPE: "polyMailer",
    // prettier-ignore
    DEFAULT_LINE_ITEM_RANGES: [100, 200, 300, 400, 500, 750, 1000, 1500, 2000, 2500],
};
export default Object.assign({}, POLY_MAILER_CONSTS, SkuFinish, SkuMaterial, SkuPrintMode, SkuModel, PartFinish, PartMaterial, PartPrintMode, PartModel, Product, ProductVariant);
