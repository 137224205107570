import DeRegion from "./de"

const Region = {
  ...DeRegion,
  countryCode: "at",
  mainRegion: false,
  languageTag: "de-DE",
  iterableLocale: "de-DE",
  locale: "de",
  phoneCountryCode: "+43",
  defaultShippingCountryCode: "AT",
  additionalCustoms: 0,
  allowedPaymentMethods: [
    "paypal",
    "check",
    "stripe",
    "p24",
    "ideal",
    "bancontact",
    "eps",
    "giropay",
    "card",
  ],
  supportsPlus: false,
}

export default Region
