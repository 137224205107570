export const stores = {
  STORE_FILES: "store:files",
  STORE_BOX_SELECTION: "store:boxSelection",
  STORE_BOX_EDITOR: "store:boxEditor",
  STORE_BOX_DESIGNS: "store:boxDesigns",
  STORE_APP: "store:app",
  STORE_ERROR: "store:error",
  STORE_USER: "store:user",
  STORE_SHOP: "store:shop",
  STORE_PRICING: "store:pricing",
  STORE_STAGES: "store:stages",
  STORE_SIDE_EDIT: "store:sideEdit",
  STORE_MODAL: "store:modal",
  STORE_FORMS: "store:forms",
  STORE_LOGIN_FORM: "store:formsLogin",
  STORE_REGISTER_FORM: "store:formsRegister",
  STORE_FORM_SET_PASSWORD: "store:formsSetPassword",
  STORE_FORM_ORDER_DETAILS: "store:formsOrderDetails",
  STORE_FORM_USER_DETAILS: "store:formUserDetails",
  STORE_ORDER_SUMMARY: "store:formOrderSummary",
  STORE_GET_GUIDE: "store:getGuide",
}
