import { AxiosInstance } from "axios"
import type { AssetDto } from "@ph/design-api"
import { parseResponse } from "../helpers/parse-response"

export class LogoApi {
  constructor(private readonly api: AxiosInstance) {}

  public async get(): Promise<AssetDto | null> {
    const response = await this.api.get("/designs/logo")

    return parseResponse<AssetDto | null>(response)
  }

  public async upsert(payload: Object): Promise<void> {
    await this.api.put("/designs/logo", { logo: payload })
  }

  public async delete(): Promise<void> {
    await this.api.delete("/designs/logo")
  }
}
