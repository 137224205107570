import VirtualDielineEditor from "../virtual-dieline-editor"
import {
  PatternGenerator,
  ProcessArgs,
} from "../../../../libs/services/pattern-generator/pattern.generator"
import { Pattern } from "../../../../models/pattern"
import { Colour } from "../../../../models/colour"
import {
  EditableSpaceConfig,
  SpaceId,
  NonEditableSpaceConfig,
} from "../../../../libs/products-render-config/types"
import fabric from "../../../../libs/vendors/Fabric"
import {
  IndexConfigFragments,
  PackhelpObject,
  IndexConfigFragmentBottom,
  PackhelpGroup,
} from "../object-extensions/packhelp-objects"
import { PackhelpPatternContainer } from "../object-extensions/packhelp-base-objects"
import { BackgroundImageController } from "./backgrounds-module/background-image/background-image.controller"
import { BackgroundsLayers } from "./backgrounds-module/types"
import { SpaceClippingController } from "./backgrounds-module/space-clipping.controller"
import {
  AllEditorEventsEmitter,
  eventTree,
} from "../../../../events/editor.events"
import { PatternController } from "./dieline-navigator/controllers/pattern.controller"
import { PatternSizeCalculator } from "../../../../libs/services/pattern-generator/pattern-size.calculator"
import { isBackgroundTexture } from "../../../../types/asset.types"

export class BackgroundsModule {
  private readonly backgroundImageController: BackgroundImageController
  private readonly patternsController: PatternController
  private readonly ee: AllEditorEventsEmitter

  constructor(private readonly virtualDielineEditor: VirtualDielineEditor) {
    this.backgroundImageController = new BackgroundImageController(
      virtualDielineEditor
    )
    this.patternsController = new PatternController(virtualDielineEditor)
    this.ee = this.virtualDielineEditor.ee
  }

  public async applyBackgroundImage(backgroundImage: PackhelpObject) {
    await this.backgroundImageController.applyBackgroundImage(backgroundImage)
    await this.virtualDielineEditor.dielineNavigator.refreshTempBackgroundImageOnActiveSpace()
    this.ee.emit(eventTree.backgroundImages.applied, backgroundImage)
  }

  public async removeBackgroundImage() {
    this.backgroundImageController.removeBackgroundImage()
    await this.virtualDielineEditor.dielineNavigator.refreshTempBackgroundImageOnActiveSpace()
    this.ee.emit(eventTree.backgroundImages.removed)
  }

  public getGlobalBackgroundImage(): PackhelpObject | undefined {
    return this.backgroundImageController.getBackgroundImage()
  }

  public getGlobalPattern() {
    return this.patternsController.getPattern()
  }

  public getGlobalBackground(): PackhelpGroup | undefined {
    const background = this.virtualDielineEditor.fabricCanvas
      .getObjects()
      .find((object) => isBackgroundTexture(object))

    if (!background) {
      return
    }

    return background as PackhelpGroup
  }

  public isGlobalPatternActive(): boolean {
    return this.patternsController.isPatternActive()
  }

  public isGlobalBackgroundImageActive(): boolean {
    return this.backgroundImageController.isBackgroundImageActive()
  }

  public isClippingMaskAvailable(): boolean {
    return this.isGlobalBackgroundImageActive() || this.isGlobalPatternActive()
  }

  public async removeGlobalPattern(): Promise<void> {
    this.patternsController.removePattern()
    await this.virtualDielineEditor.dielineNavigator.refreshTempPatternOnActiveSpace()
  }

  public isPatternVisibleOnSpace(spaceId: SpaceId): boolean {
    const globalPattern = this.getGlobalPattern()

    if (!globalPattern) {
      return false
    }

    return this.isBackgroundLayerVisibleOnSpace(globalPattern, spaceId)
  }

  public async hideBackgroundLayerOnDisabledSpaces(
    object: PackhelpObject
  ): Promise<void> {
    const { productRenderPilot, dielineNavigator, editContext } =
      this.virtualDielineEditor

    const spacesAvailableForImage = new Set(
      productRenderPilot
        .getContextEditableSpaces(editContext)
        .map((spaceConfig) => spaceConfig.spaceId)
    )
    const clipSpaceIds = this.getBackgroundLayerClipSpaceIds(object)
    clipSpaceIds.forEach((spaceId) => spacesAvailableForImage.delete(spaceId))

    const allProductSpaceConfigs =
      productRenderPilot.getContextSpaces(editContext)

    await Promise.all(
      allProductSpaceConfigs
        .map((spaceConfig) => {
          if (!spacesAvailableForImage.has(spaceConfig.spaceId)) {
            return spaceConfig
          }

          return spaceConfig.children.filter((config) => config.disabled)
        })
        .flat()
        .map(async (spaceConfig) => {
          const spaceClippingController = new SpaceClippingController(
            dielineNavigator,
            object
          )

          await spaceClippingController.refreshClipping(spaceConfig)
        })
    )
  }

  public isBackgroundImageVisibleOnSpace(spaceId: SpaceId): boolean {
    const backgroundImage = this.getGlobalBackgroundImage()

    if (!backgroundImage) {
      return false
    }

    return this.isBackgroundLayerVisibleOnSpace(backgroundImage, spaceId)
  }

  public async toggleBackgroundImageVisibilityOnSpace(
    spaceConfig: EditableSpaceConfig
  ): Promise<void> {
    const globalBackgroundImage = this.getGlobalBackgroundImage()

    if (!globalBackgroundImage) {
      return
    }

    const spaceClippingController = new SpaceClippingController(
      this.virtualDielineEditor.dielineNavigator,
      globalBackgroundImage
    )

    await spaceClippingController.toggleClipping(spaceConfig)

    const isVisible = !spaceClippingController.isClippingActive(
      spaceConfig.spaceId
    )
    this.virtualDielineEditor.dielineNavigator.setTempBackgroundImageVisibility(
      isVisible
    )
  }

  public async togglePatternVisibilityOnSpace(
    spaceConfig: EditableSpaceConfig | NonEditableSpaceConfig
  ): Promise<void> {
    const globalPattern = this.getGlobalPattern()

    if (!globalPattern) {
      return
    }

    const spaceClippingController = new SpaceClippingController(
      this.virtualDielineEditor.dielineNavigator,
      globalPattern
    )

    await spaceClippingController.toggleClipping(spaceConfig)

    this.virtualDielineEditor.dielineNavigator.toggleVisibleTempPattern(
      spaceConfig.spaceId
    )
  }

  public getBackgroundImageClipSpaceIds(): SpaceId[] {
    const globalBackgroundImage = this.getGlobalBackgroundImage()

    if (!globalBackgroundImage) {
      return []
    }

    return this.getBackgroundLayerClipSpaceIds(globalBackgroundImage)
  }

  public getPatternClipSpaceIds(): SpaceId[] {
    const globalPattern = this.getGlobalPattern()

    if (!globalPattern) {
      return []
    }

    return this.getBackgroundLayerClipSpaceIds(globalPattern)
  }

  public async createPattern(
    pattern: Pattern
  ): Promise<PackhelpPatternContainer> {
    this.patternsController.removePattern()

    const patternSizeCalculator = new PatternSizeCalculator(
      this.virtualDielineEditor
    )
    const patternSize = patternSizeCalculator.calculate(pattern.size)

    const config: ProcessArgs = {
      patternSizePx: patternSize,
    }

    const { productRenderPilot } = this.virtualDielineEditor

    if (
      productRenderPilot
        .getAvailableSpaces(this.virtualDielineEditor.editContext)
        .includes(SpaceId.DEFAULT)
    ) {
      const vdSpaceBoundingRect =
        this.virtualDielineEditor.dielineNavigator.getSpaceBoundingRect(
          SpaceId.DEFAULT
        )

      config.containerSize = {
        width: vdSpaceBoundingRect.width,
        height: vdSpaceBoundingRect.height,
      }

      config.offset = {
        top: vdSpaceBoundingRect.top,
        left: vdSpaceBoundingRect.left,
      }
    }

    const patternGenerator = new PatternGenerator(pattern)
    const { patternContainer } = await patternGenerator.process(config)

    return patternContainer
  }

  public async applyPattern(
    patternObject: PackhelpPatternContainer
  ): Promise<PackhelpPatternContainer> {
    await this.patternsController.applyPattern(patternObject)

    await this.hideBackgroundLayerOnDisabledSpaces(
      patternObject as PackhelpObject
    )
    await this.virtualDielineEditor.dielineNavigator.refreshTempPatternOnActiveSpace()

    return patternObject
  }

  public getSpaceBackground(spaceId: SpaceId): Colour | undefined {
    const space = this.virtualDielineEditor
      .getProductVirtualDieline()
      .getObjects()
      .find((space) => space.id === spaceId)

    if (!space || typeof space.fill !== "string") {
      console.error("failing to load spaceId:", spaceId)
      throw new Error(
        "space with provided id is not available or is not compatible"
      )
    }

    return space.fill === "transparent" ? undefined : new Colour(space.fill)
  }

  public async applyColorOnProduct(colour: Colour | null): Promise<void> {
    await this.paintAllSides(colour)
    const backgroundTexture =
      await this.prepareTextureForThreeDimensionalVisualization()
    const currentBackground = this.getGlobalBackground()

    if (currentBackground) {
      this.virtualDielineEditor.fabricCanvas.remove(currentBackground)
    }

    this.virtualDielineEditor.addOnCanvas(backgroundTexture)

    await this.virtualDielineEditor.dielineNavigator.refreshTempBackgroundOnActiveSpace()
    await this.virtualDielineEditor.dielineNavigator.refreshSceneDecoration()
    this.ee.emit(eventTree.productDriver.backgroundChanged)
  }

  public async applyColorOnProductSelectedSpaces(
    colour: Colour,
    spaceIdsArr: string[]
  ) {
    await this.paintSides(spaceIdsArr, colour)
    const backgroundTexture =
      await this.prepareTextureForThreeDimensionalVisualization()
    const currentBackground = this.getGlobalBackground()

    if (currentBackground) {
      this.virtualDielineEditor.fabricCanvas.remove(currentBackground)
    }

    this.virtualDielineEditor.addOnCanvas(backgroundTexture)
  }

  public async applyColorOnSpaces(
    spaceConfig: EditableSpaceConfig,
    colour: Colour
  ) {
    const { fabricCanvas, dielineNavigator } = this.virtualDielineEditor
    await this.paintSide(spaceConfig.spaceId, colour)
    await this.paintSides(
      spaceConfig.children.map((child) => child.spaceId),
      colour
    )
    const backgroundTexture =
      await this.prepareTextureForThreeDimensionalVisualization()

    const currentBackground = this.getGlobalBackground()

    if (currentBackground) {
      fabricCanvas.remove(currentBackground)
    }

    this.virtualDielineEditor.addOnCanvas(backgroundTexture)
    await dielineNavigator.refreshTempBackground(spaceConfig.spaceId)
  }

  public async prepareTextureForThreeDimensionalVisualization(): Promise<fabric.Object> {
    const virtualDieline = this.virtualDielineEditor
      .getProductVirtualDieline()
      .set({ visible: true })

    return new Promise((resolve) => {
      virtualDieline.clone(
        (backgroundTexture) => {
          backgroundTexture.set({
            id: BackgroundsLayers.BACKGROUND_TEXTURE,
            indexConfig: {
              fragment: IndexConfigFragments.BOTTOM,
              index: IndexConfigFragmentBottom.BACKGROUND_TEXTURE,
            },
            evented: false,
            selectable: false,
            visible:
              !this.virtualDielineEditor.dielineNavigator.getActiveSpaceId(),
          })
          resolve(backgroundTexture)
        },
        ["colourSettings", "id", "rotation"]
      )
    })
  }

  private async paintAllSides(color: Colour | null): Promise<void> {
    await this.paintSides(
      this.virtualDielineEditor
        .getProductVirtualDieline()
        .getObjects()
        .map((object) => object.id),
      color
    )
  }

  private async paintSides(
    spaceIds: string[],
    color: Colour | null
  ): Promise<void> {
    const virtualDielineSpaces = this.virtualDielineEditor
      .getProductVirtualDieline()
      .getObjects()

    virtualDielineSpaces.map((virtualDielineSpace) => {
      if (spaceIds.includes(virtualDielineSpace.id)) {
        this.setFill(virtualDielineSpace, color)
      }
    })
  }

  private async paintSide(spaceId: SpaceId, color: Colour): Promise<void> {
    const space = this.virtualDielineEditor
      .getProductVirtualDieline()
      .getObjects()
      .find((space) => space.id === spaceId)

    if (!space) {
      return
    }

    this.setFill(space, color)
  }

  private isBackgroundLayerVisibleOnSpace(
    backgroundLayer: PackhelpObject,
    spaceId: SpaceId
  ): boolean {
    const spaceClippingController = new SpaceClippingController(
      this.virtualDielineEditor.dielineNavigator,
      backgroundLayer
    )

    return !spaceClippingController.isClippingActive(spaceId)
  }

  private getBackgroundLayerClipSpaceIds(
    backgroundLayer: PackhelpObject
  ): SpaceId[] {
    const spaceClippingController = new SpaceClippingController(
      this.virtualDielineEditor.dielineNavigator,
      backgroundLayer
    )

    return spaceClippingController.getClipSpaceIds()
  }

  private setFill(object: PackhelpObject, color: Colour | null) {
    if (!color || color.isTransparent()) {
      object.set({
        fill: "transparent",
        colourSettings: undefined,
      })

      return
    }

    object.set({
      fill: color.getRgb(),
      colourSettings: color.getSettings(),
    })
  }
}
