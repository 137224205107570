import { EffectControllable } from "./effect-controllable.interface"
import { TextObjectController } from "../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object.controller"
import Colour from "../../../models/colour"
import { EffectOptions, EffectUiConfig } from "../types"

export class ShadowEffectController implements EffectControllable {
  constructor(private objectController: TextObjectController) {}

  public getOptions(): EffectOptions {
    const shadow = this.objectController.getShadow()

    return shadow || {}
  }

  public getUiConfig(): EffectUiConfig {
    return {
      offsetX: {
        min: -100,
        max: 100,
        step: 1,
      },
      offsetY: {
        min: -100,
        max: 100,
        step: 1,
      },
      color: true,
    }
  }

  public init() {
    if (this.objectController.hasShadow()) {
      return
    }

    this.apply({
      offsetX: 4,
      offsetY: 4,
      color: new Colour("#c5c5c5"),
    })
  }

  public apply(options: EffectOptions) {
    this.objectController.setStyles({
      shadow: {
        offsetX: options.offsetX || 0,
        offsetY: options.offsetY || 0,
        color: options.color,
      },
    })
  }

  public clear() {
    this.apply({
      offsetX: 0,
      offsetY: 0,
    })
  }
}
