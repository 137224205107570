import { AxiosInstance } from "axios"
import { parseResponse } from "../helpers/parse-response"
import { PricingDto, PricingPayload, VolumePriceWithSkuDto } from "../../types"
import _groupBy from "lodash/groupBy"
import { adjustMaxRange } from "../helpers/adjust-max-range"

export class PricingApi {
  constructor(private readonly api: AxiosInstance) {}

  public async get(params: PricingPayload): Promise<PricingDto> {
    const response = await this.api.get("/volume_prices", {
      params,
    })

    const pricing = _groupBy(
      parseResponse<VolumePriceWithSkuDto[]>(response),
      (volumePrice) => volumePrice.variant.external_pim_sku
    )

    return Object.fromEntries(
      Object.entries(pricing).map(([sku, prices]) => {
        return [sku, adjustMaxRange(prices)]
      })
    )
  }
}
