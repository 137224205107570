import { AxiosInstance } from "axios"
import { CartDto, LineItemDto, LineItemPayload } from "../../types"
import { parseResponse } from "../helpers/parse-response"

export class CartApi {
  constructor(private readonly api: AxiosInstance) {}

  public async get(): Promise<CartDto> {
    let response

    try {
      response = await this.api.get("/cart")
    } catch (_e) {
      response = await this.api.post("/cart")
    }

    return parseResponse<CartDto>(response)
  }

  public async addLineItem(payload: LineItemPayload): Promise<LineItemDto> {
    const response = await this.api.post("/cart/line_items", payload)

    return parseResponse<LineItemDto>(response)
  }

  public async updateLineItem(): Promise<void> {
    return Promise.resolve()
  }
}
