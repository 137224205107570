import React, { PropsWithChildren, useContext } from "react"
import ReactDOM from "react-dom"
import { ModalContext } from "../ModalProvider/ModalProvider"
import { I18N } from "../../../../i18n"
import { LinkAsButton } from "dsl/src/atoms/Link/Link"
import { useTranslate } from "../../../../hooks/useTranslate"
import Button from "dsl/src/atoms/Button/Button"
import cxBinder from "classnames/bind"
import styles from "./Modal.module.scss"
import { CloseButton } from "../../../atoms/CloseButton/CloseButton"

const cx = cxBinder.bind(styles)

interface ModalProps {
  title?: string
  iconUrl?: string
  link?: {
    label: string
    onClick: () => void
    loading?: boolean
  }
  onClose: () => void
  withCancel?: boolean
  center?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

export function Modal({
  children,
  onClose,
  title,
  iconUrl,
  withCancel = true,
  link,
  center = false,
  e2eTarget = "modal",
  e2eTargetName,
}: PropsWithChildren<ModalProps>) {
  const context = useContext(ModalContext)
  const t = useTranslate()

  if (!context) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div
        className={cx("wrapper", { center })}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div className={styles.close}>
          <CloseButton
            onClick={onClose}
            size="l"
            e2eTarget="button"
            e2eTargetName="close"
          />
        </div>

        {title && (
          <header className={styles.header}>
            {iconUrl && <img src={iconUrl} alt="" />}
            <h3 className={styles.title}>{title}</h3>
          </header>
        )}

        <div className={styles.content}>{children}</div>

        <footer className={styles.footer}>
          {withCancel && (
            <LinkAsButton
              onClick={onClose}
              underlined={false}
              e2eTargetName="cancel"
            >
              {t(I18N.generic.cancel)}
            </LinkAsButton>
          )}
          {link && (
            <Button isLoading={link.loading} onClick={link.onClick}>
              {link.label}
            </Button>
          )}
        </footer>
      </div>
    </div>,
    context
  )
}
