// if no performance API in node, just use a dummy JS proxy stub
import mitt from "mitt"
import _once from "lodash/once"
import { stringToRoundedNumber } from "./utils"
// @ts-ignore
const blackHole = new Proxy({}, { get: () => blackHole })
// @ts-ignore
let p: typeof performance = blackHole

if (typeof performance !== "undefined") {
  p = performance
}

type Marks =
  | "PH.Editor.react-loaded"
  | "PH.Editor.root-store-finished"
  | "PH.Editor.ui-loaded"
  | "PH.Editor.visibly-loaded"
  // Product Page
  | "PH.ProductPage.react-loaded"
  | "PH.ProductPage.ui-loaded"
  | "PH.ProductPage.delivery-est-rendered"
  | "PH.ProductPage.visibly-loaded"
  // builtIn
  | "navigationStart"

export const RUM_Markers = {
  editor: {
    mark: {
      react_loaded: () => mark("PH.Editor.react-loaded"),
      root_store_finished: () => mark("PH.Editor.root-store-finished"),
      ui_loaded: () => {
        mark("PH.Editor.ui-loaded")
        measure(
          "PH.Editor.Measure.click-to-editor-ui-visible",
          "navigationStart",
          "PH.Editor.ui-loaded"
        )
      },
      visibly_loaded: () => {
        mark("PH.Editor.visibly-loaded")
        measure(
          "PH.Editor.Measure.click-to-editor-fully-loaded",
          "navigationStart",
          "PH.Editor.visibly-loaded"
        )
      },
    },
  },
  productPage: {
    mark: {
      react_loaded: _once(() => {
        mark("PH.ProductPage.react-loaded")
        measure(
          "PH.ProductPage.Measure.click-to-react",
          "navigationStart",
          "PH.ProductPage.react-loaded"
        )
      }),
      root_store_finished: _once(() => {
        // While this is a root store finished, it is kinda the same as ui-loaded
        mark("PH.ProductPage.ui-loaded")
        measure(
          "PH.ProductPage.Measure.ui-loaded",
          "navigationStart",
          "PH.ProductPage.ui-loaded"
        )
      }),

      delivery_estimate_rendered: _once(() => {
        mark("PH.ProductPage.delivery-est-rendered")
        measure(
          "PH.ProductPage.Measure.click-to-delivery-visible",
          "navigationStart",
          "PH.ProductPage.delivery-est-rendered"
        )
      }),
    },
  },
}
function mark(markName: Marks) {
  p.mark(markName)
  updateMetrics()
}
function measure(measureName: any, startMark: Marks, endMark: Marks) {
  p.measure(measureName, startMark, endMark)
}
const APP_METRICS_SOURCE = Object.freeze({
  _fully_loaded: false,
  Editor: {
    mark_react_loaded: undefined,
    mark_root_store_finished: undefined,
    mark_ui_loaded: undefined,
    mark_visibly_loaded: undefined,
  },
  ProductPage: {
    mark_react_loaded: undefined,
    mark_ui_loaded: undefined,
    mark_delivery_estimate_rendered: undefined,
  },
})
export type AppMetrics_DTO = {
  _fully_loaded: boolean
  Editor: {
    [K in keyof typeof APP_METRICS_SOURCE.Editor]?: number | undefined
  }
  ProductPage: {
    [K in keyof typeof APP_METRICS_SOURCE.ProductPage]?: number | undefined
  }
}
let metrics: AppMetrics_DTO = { ...APP_METRICS_SOURCE }
function _getMark(markName: Marks): number | undefined {
  let mark = p.getEntriesByName(markName)[0]
  if (mark == null) return
  return stringToRoundedNumber(mark.startTime)
}
function updateMetrics() {
  const E = metrics.Editor

  const reactLoaded = _getMark("PH.Editor.react-loaded")
  const rootStoreFinished = _getMark("PH.Editor.root-store-finished")
  const uiLoaded = _getMark("PH.Editor.ui-loaded")
  const visuallyLoaded = _getMark("PH.Editor.visibly-loaded")

  E.mark_react_loaded = reactLoaded
  E.mark_root_store_finished = rootStoreFinished
  E.mark_ui_loaded = uiLoaded
  E.mark_visibly_loaded = visuallyLoaded

  // with editor, we should only send metrics only after the editor is fully loaded
  if (visuallyLoaded != null) {
    metrics._fully_loaded = true
  }

  // PRODUCT PAGE
  const P = metrics.ProductPage

  P.mark_react_loaded = _getMark("PH.ProductPage.react-loaded")
  P.mark_ui_loaded = _getMark("PH.ProductPage.ui-loaded")
  P.mark_delivery_estimate_rendered = _getMark(
    "PH.ProductPage.delivery-est-rendered"
  )
  if (_getMark("PH.ProductPage.delivery-est-rendered")) {
    metrics._fully_loaded = true
  }

  ee.emit("app-metrics")
}

const ee = mitt()
export function onAppMetrics(cb: (err: any, instance: AppMetrics_DTO) => void) {
  ee.on("app-metrics", () => {
    cb(null, metrics)
  })
}
