import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import { I18N } from "../../../../../i18n"
import TextField from "dsl/src/atoms/TextField/TextField"
import { FontSelect } from "../../../../molecules/FontSelect/FontSelect"
import { IconList } from "../../../../atoms/IconList/IconList"
import boldIconUrl from "../../../../../assets/_icons/text-bold.svg"
import italicIconUrl from "../../../../../assets/_icons/text-italic.svg"
import {
  FontFamilyDefinition,
  FontStyle,
  FontWeight,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { Fonts } from "../../../../../../libs/services/fonts-loader-service/fonts-loader.config"
import { FontList } from "../../../FontList/FontList"
import styles from "./TextSubTab.module.scss"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"
import { SettingsSection } from "./SettingsSection"
import { IconListItem } from "../../../../atoms/IconList/IconListItem"

const i18nPatterns = I18N.editorTools.patterns
const i18nText = I18N.editorTools.text

export const TextSubTab = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.replicablePatterns])

  if (!containerSet) {
    return null
  }

  const { replicablePatterns } = containerSet

  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns
  const { textOptions } = replicablePatternsStore
  const { isFontListOpen } = replicablePatternUiController

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    replicablePatternUiController.setTextOptions({ text: e.target.value })
  }

  const toggleFontStyle = () => {
    replicablePatternUiController.toggleFontStyle()
  }

  const toggleFontWeight = () => {
    replicablePatternUiController.toggleFontWeight()
  }

  const onFontChange = (definition: FontFamilyDefinition) => {
    replicablePatternUiController.setFontFamily(definition.id as Fonts)
  }

  const onFontSelectClick = () => {
    replicablePatternUiController.setIsFontListOpen(!isFontListOpen)
  }

  return (
    <>
      <TabSection>
        <FormGroup label={t(i18nPatterns.yourText)}>
          <TextField value={textOptions.text} onChange={onTextChange} medium />
        </FormGroup>
      </TabSection>

      <TabSection>
        <FormGroup label={t(i18nPatterns.fontOptions)}>
          <div className={styles["font-family"]}>
            <FontSelect
              onClick={onFontSelectClick}
              selectedFontFamily={textOptions.fontFamily}
              open={isFontListOpen}
              analyticsSection="left-tab-pattern"
            />
          </div>
          <IconList>
            <IconListItem
              iconUrl={italicIconUrl}
              label={t(i18nText.fontStyle)}
              active={textOptions.fontStyle === FontStyle.italic}
              disabled={!replicablePatternsStore.isFontStyleAvailable}
              onClick={toggleFontStyle}
              analyticsSection="left-tab-pattern"
              analyticsType="icon"
              analyticsName="font-style"
            />
            <IconListItem
              iconUrl={boldIconUrl}
              label={t(i18nText.fontWeight)}
              active={textOptions.fontWeight === FontWeight.bold}
              disabled={!replicablePatternsStore.isFontWeightAvailable}
              onClick={toggleFontWeight}
              analyticsSection="left-tab-pattern"
              analyticsType="icon"
              analyticsName="font-weight"
            />
          </IconList>
        </FormGroup>
        {isFontListOpen && (
          <div className={styles["font-list"]}>
            <FontList
              onClick={onFontChange}
              selectedFontFamily={textOptions.fontFamily!}
              analyticsSection="left-tab-pattern"
            />
          </div>
        )}
      </TabSection>

      <SettingsSection />
    </>
  )
})
