import { Cart } from "../../stores/cart.store"

// Events
export enum CartEvents {
  productAdded = "ph.cart.product-added",
  productAddedProcessed = "ph.cart.product-added-processed",
  cartReceived = "ph.cart.received",
}

export interface ProductAddedData {
  id: number
  currentCart: {
    id: number
    number: string
    currency: string
    promotion_amount?: string
    promo_code?: string
  }
  newLineItem: {
    thumbnail: string
    price: string
    quantity: string
    total: string
    variant: {
      sku: string
    }
  }
}

export type CartEventsTypes = {
  [CartEvents.productAdded]: (data?: ProductAddedData) => void
  [CartEvents.productAddedProcessed]: () => void
  [CartEvents.cartReceived]: (cart: Cart) => void
}
