import React from "react"
import cxBinder from "classnames/bind"
import styles from "./Badge.module.scss"

const cx = cxBinder.bind(styles)

export enum BadgeColorTypes {
  Black = "wrapper--black",
  Green = "wrapper--green",
  LightishBlue = "wrapper--lightish-blue",
  Squash = "wrapper--squash",
  LightSquash = "wrapper--light-squash",
  BrownGrey = "wrapper--brown-grey",
  WhiteFour = "wrapper--white-four",
  WhiteThreeWithBorder = "wrapper--white-three-with-border",
  WhiteThreeWithEntan = "wrapper--white-three-with-entan-red",
  BubblegumPink = "wrapper--bubblegum-pink",
  Ultramarine = "wrapper--ultramarine",
  Jagger = "wrapper--jagger",
  BrightCyan = "wrapper--bright-cyan",
  PurpleBlue = "wrapper--purple-blue",
  Mariner = "wrapper--mariner",
  FrenchPass = "wrapper--french-pass",
  PaleRed = "wrapper--pale-red",
  VeryLightPink = "wrapper--very-light-pink",
  VeryLightPinkGray = "wrapper--very-light-pink--gray",
  VeryLightPinkTwo = "wrapper--very-light-pink-two",
  FlushMahogany = "wrapper--flush-mahogany",
  PapayaWhip = "wrapper--papaya-whip",
  Solitude = "wrapper--solitude",
  Orinoco = "wrapper--orinoco",
  DoveGray = "wrapper--dove-gray",
  PrimaryPlusBlue = "wrapper--primary-plus-blue",
  WildSand = "wrapper--supporting-wild-sand",
  Default = "wrapper--default",
  Specialty = "wrapper--specialty",
  OutlineBlack = "wrapper--outline-black",
  OliveLight = "wrapper--olive-light"
}

export enum BadgeIconTypes {
  NoIcon = "",
  Lightning = "wrapper--icon-lightning"
}

export interface BadgeProps extends React.HTMLProps<HTMLSpanElement> {
  badgeColor?: BadgeColorTypes
  badgeIcon?: BadgeIconTypes
  noMargin?: boolean
  noWrap?: boolean
  e2eTargetName?: string
}

const Badge = (props: BadgeProps) => {
  const {
    children,
    badgeColor = BadgeColorTypes.Black,
    badgeIcon = BadgeIconTypes.NoIcon,
    noMargin,
    noWrap,
    e2eTargetName
  } = props

  return (
    <span
      className={cx("wrapper", {
        [badgeColor]: badgeColor,
        [badgeIcon]: badgeIcon,
        ["wrapper--no-margin"]: noMargin,
        ["wrapper--no-wrap"]: noWrap
      })}
      e2e-target="badge"
      e2e-target-name={e2eTargetName}
    >
      {children}
    </span>
  )
}

export { Badge, Badge as default }
