export var PaperBagProductVariant;
(function (PaperBagProductVariant) {
    PaperBagProductVariant["PAPER_BAG_VARIANT_STANDARD"] = "paperBag";
    PaperBagProductVariant["DOYPACK_BAG_VARIANT"] = "doypackBag";
    PaperBagProductVariant["TOTE_BAG_VARIANT"] = "toteBag";
    PaperBagProductVariant["PAPER_BAG_PIM_VARIANT"] = "printed-paper-bag--260";
    PaperBagProductVariant["DOYPACK_BAG_PIM_VARIANT"] = "stand-up-pouch-with-label--259";
    PaperBagProductVariant["TOTE_BAG_PIM_VARIANT"] = "tote-bag--261";
})(PaperBagProductVariant || (PaperBagProductVariant = {}));
export default PaperBagProductVariant;
