import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { ElementsSection } from "../partials/ElementsSection"
import { ElementsTabSection } from "../../../../../types/ui.types"

const i18n = I18N.editorTools.assets

export const TabElements = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { elementsTabController } = ui
  const { sections, expandedSection } = elementsTabController

  const onExpandClick = (section: ElementsTabSection) => {
    elementsTabController.setExpandedSection(section)
  }

  const onBackClick = () => {
    elementsTabController.setExpandedSection()
  }

  const title = expandedSection
    ? t(expandedSection.titleIntl)
    : t(I18N.tabs.elements)
  const backLink = expandedSection
    ? { label: t(i18n.allElements), onClick: onBackClick }
    : undefined

  return (
    <Tab title={title} backLink={backLink} e2eTargetName="elements">
      {sections.map((section) => {
        const isExpanded = elementsTabController.isSectionExpanded(section)

        if (expandedSection && !isExpanded) {
          return null
        }

        return (
          <ElementsSection
            key={section.id}
            id={section.id}
            title={t(section.titleIntl)}
            elements={section.elements}
            onElementClick={section.onElementClick}
            onExpandClick={() => onExpandClick(section)}
            expanded={isExpanded}
            analyticsSection="left-tab-elements"
          />
        )
      })}
    </Tab>
  )
})
