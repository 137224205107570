import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { HeaderAddToCartButton } from "./HeaderAddToCartButton"
import { HeaderQrButton } from "./HeaderQrButton"
import { HeaderFinishDesignButton } from "./HeaderFinishDesignButton"
import { HeaderSaveTemplateButton } from "./HeaderSaveTemplateButton"

export const HeaderActions = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ecommerce, c.ui])

  if (!containerSet) {
    return null
  }

  const { ecommerce, ui } = containerSet
  const { isDtpPreviewMode, isDesignerMode } = ui.editorModeController

  if (isDtpPreviewMode) {
    return null
  }

  if (isDesignerMode) {
    return <HeaderSaveTemplateButton />
  }

  if (!ecommerce.available) {
    return null
  }

  const { isAfterPurchaseEdit, isCartFlowAvailable, isQrFlowAvailable } =
    ecommerce.ecommerceStore

  if (isAfterPurchaseEdit) {
    return <HeaderFinishDesignButton />
  }

  return (
    <>
      {isQrFlowAvailable && <HeaderQrButton />}

      {isCartFlowAvailable && <HeaderAddToCartButton />}
    </>
  )
})
