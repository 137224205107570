import {
  PackhelpInteractiveCanvas,
  PackhelpObject,
} from "../../../object-extensions/packhelp-objects"
import { ObjectEdge } from "./types"
import { CanvasEvent } from "../../../../../../events/partials/domain.events"
import { MoveSnapper } from "./snapping/move.snapper"
import { ScaleSnapper } from "./snapping/scale.snapper"

export class SnapDaddy {
  private readonly scaleSnapper: ScaleSnapper
  private readonly moveSnapper: MoveSnapper

  constructor(private readonly canvas: PackhelpInteractiveCanvas) {
    this.scaleSnapper = new ScaleSnapper()
    this.moveSnapper = new MoveSnapper()
  }

  public snap(
    object: PackhelpObject,
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): void {
    if (object["__corner"]) {
      this.scaleSnapper.snap(object, currentEdge, nextEdge)
    } else {
      this.moveSnapper.snap(object, currentEdge, nextEdge)
    }

    this.canvas.fire(CanvasEvent.objectSnapped, {
      target: object,
    })
  }

  public clear(): void {
    this.scaleSnapper.clear()
  }
}
