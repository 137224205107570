import { ImageAssetMetaT } from "../types/asset.types"
import { getInfo } from "@ph/editor-assets"

/**
 * Jun 4
 * - We've added `isPdf` that was not avaliable on the original metainfo
 *
 * This constructor will convert all old assets to new ones
 */

export class ImageAsset {
  public original: ImageAssetMetaT["original"]
  public converted: ImageAssetMetaT["converted"]

  constructor(data: ImageAssetMetaT) {
    this.original = data.original
    this.converted = data.converted

    // Fix invalid S3 url returned by Filestack
    if (this.original.url.endsWith("undefined")) {
      this.original.url = this.original.filestackUrl
    }

    // Jun 4 Database migration via frontend. Don't remove please
    this.original.metaInfo = getInfo(data.original.metaInfo.mimeType)
  }

  public toSource(): ImageAssetMetaT {
    return {
      original: this.original,
      converted: this.converted,
    }
  }

  /**
   * Handy meta getter
   */
  public getMeta() {
    return getInfo(this.original.metaInfo.mimeType)
  }

  public isOriginalSvg() {
    return this.original.metaInfo.isSvg
  }

  public getOriginImageConfig() {
    return this.original
  }

  public getScaledImageConfig() {
    return this.converted
  }

  public getBasicScaledConfig() {
    if (this.converted.mediumColor != null) {
      return this.converted.mediumColor
    } else {
      return this.converted.rasterized50
    }
  }

  public getOriginalImagePath() {
    return this.original.url
  }

  public getScaledImagePath() {
    if (this.converted.mediumColor != null) {
      return this.converted.mediumColor.url
    } else {
      return this.converted.rasterized50.url
    }
  }
}
