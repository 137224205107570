import THREE from "../../../../libs/vendors/THREE"
import { GeometryConfig, isMesh, SvgSpaceConfig } from "../types"

export class OffsetCalculator {
  constructor(
    private readonly object: THREE.Mesh,
    private readonly config: SvgSpaceConfig["folding"]
  ) {}

  public call(): THREE.Vector3 {
    return new THREE.Vector3(this.xOffset, this.yOffset, this.zOffset)
  }

  private get xOffset(): number {
    return 0
  }

  private get yOffset(): number {
    const parent = this.object.parent?.parent

    if (!this.config.shifting || !isMesh(parent)) {
      return 0
    }

    const boundingBox = this.object.geometry.boundingBox
    const parentBoundingBox = parent.geometry.boundingBox

    if (!boundingBox || !parentBoundingBox) {
      return 0
    }

    if (parentBoundingBox.min.y > boundingBox.min.y) {
      return -(boundingBox.max.y - parentBoundingBox.min.y)
    }

    return -(parentBoundingBox.max.y - boundingBox.min.y)
  }

  private get zOffset(): number {
    if (Math.abs(this.config.angle) === 180) {
      if (
        (this.config.angle > 0 && this.config.axis.y === 1) ||
        (this.config.angle < 0 && this.config.axis.x === 1)
      ) {
        return 0
      }

      return this.thickness
    }

    if (
      (this.config.angle < 0 && this.config.axis.y === 1) ||
      (this.config.angle > 0 && this.config.axis.x === 1)
    ) {
      return 0
    }

    const sign = this.config.boundingPoint === "min" ? 1 : -1

    return this.thickness * sign
  }

  private get thickness(): number {
    return this.geometryConfig.thickness
  }

  private get geometryConfig(): GeometryConfig {
    return this.object.geometry.userData as GeometryConfig
  }
}
