import { trackThroughBackend } from "../../../../../../libs/analytics/segment/segment-analytics.service"
import LineItemModel from "../../../../../../libs/models/LineItem.model"
import { prepareCrossSellProductData } from "../../../utils/modal-cross-sell.segment"

export enum CrossSellLocations {
  Cart = "Cart",
  CrossSellModal = "Cross Sell Modal",
  CrossSellModalInCart = "Cross Sell Modal In Cart",
  NcpDescriptionModal = "Description Modal",
  Editor = "Editor",
  Dby = "Dby",
}

export enum CrossSellVisitLocations {
  cart = "cart",
  editor = "editor",
  dby = "dby",
  productPage = "product page",
}

export enum CartCrossSellProductsSegmentEvents {
  ExtraSupportAdded = "Extra Support Added",
  CrossSellModalAddButtonClicked = "Cross Sell Modal Add Button Clicked",
  LearnMoreModalAddButtonClicked = "Learn More Add Button Clicked",
}

export const CrossSellNcpProductAdded = (
  item: LineItemModel,
  location: CrossSellLocations
) => {
  const segmentProductData = prepareCrossSellProductData(item)

  let eventName = CartCrossSellProductsSegmentEvents.ExtraSupportAdded

  if (location === CrossSellLocations.CrossSellModal) {
    eventName =
      CartCrossSellProductsSegmentEvents.CrossSellModalAddButtonClicked
  }

  if (location === CrossSellLocations.CrossSellModalInCart) {
    eventName =
      CartCrossSellProductsSegmentEvents.CrossSellModalAddButtonClicked
  }

  if (location === CrossSellLocations.NcpDescriptionModal) {
    eventName =
      CartCrossSellProductsSegmentEvents.LearnMoreModalAddButtonClicked
  }

  trackThroughBackend({
    event: eventName,
    properties: {
      products: segmentProductData,
      location: location,
    },
  })
}
