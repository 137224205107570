import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import {
  convertCustomSizeUnitToMm,
  VariantCustomization,
} from "@ph/product-api"
import { LocalePayload, VariantApi } from "../libs/api/ecommerce-api/types"
import { LocaleConfig } from "../app-config/configs/locale.config"
import { EcommerceStore } from "../stores/ecommerce.store"
import { ProductStore } from "../stores/product.store"
import { ProductPricingStore } from "../stores/product-pricing.store"
import { ShippingDestinationStore } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/store/shipping-destination.store"
import { MissingShippingDestinationError } from "./ecommerce.controller"
import _cloneDeep from "lodash/cloneDeep"

export class DynamicVariantController {
  private readonly variantApi: VariantApi
  private readonly ee: AllEditorEventsEmitter
  private readonly localeConfig: LocaleConfig
  private readonly ecommerceStore: EcommerceStore
  private readonly productStore: ProductStore
  private readonly productPricingStore: ProductPricingStore
  private readonly shippingDestinationStore: ShippingDestinationStore
  private readonly isDynamicPricesSupported: boolean

  constructor(
    services: {
      variantApi: VariantApi
      ee: AllEditorEventsEmitter
      localeConfig: LocaleConfig
      isDynamicPricesSupported: boolean
    },
    stores: {
      ecommerceStore: EcommerceStore
      productStore: ProductStore
      productPricingStore: ProductPricingStore
      shippingDestinationStore: ShippingDestinationStore
    }
  ) {
    this.isDynamicPricesSupported = services.isDynamicPricesSupported
    this.variantApi = services.variantApi
    this.ee = services.ee
    this.localeConfig = services.localeConfig

    this.ecommerceStore = stores.ecommerceStore
    this.productStore = stores.productStore
    this.productPricingStore = stores.productPricingStore
    this.shippingDestinationStore = stores.shippingDestinationStore
  }

  public async load(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    this.ecommerceStore.setIsDynamicVariantLoading(true)

    const { product } = this.productStore
    const { isDynamicPricingEnabled } = product
    const { selectedQuantity } = this.ecommerceStore
    const variant = product.getDefaultVariant()

    if (!this.isCurrentVariant(sku, customization)) {
      this.clear()
      this.ecommerceStore.setDynamicVariantError()
    }

    const isPricingLoaded =
      !customization &&
      this.productPricingStore.isPricingLoaded(product, selectedQuantity)

    if (
      !isDynamicPricingEnabled ||
      !this.isDynamicPricesSupported ||
      isPricingLoaded
    ) {
      this.ecommerceStore.setIsDynamicVariantLoading(false)
      return
    }

    try {
      const customizationPayload =
        this.prepareCustomizationPayload(customization)
      const localePayload = this.prepareLocalePayload()
      const quantitiesPayload = this.prepareQuantitiesPayload(customization)

      const customVariant = await this.variantApi.getDynamicVariant(
        sku,
        customizationPayload,
        localePayload,
        quantitiesPayload
      )

      this.ecommerceStore.setDynamicVariantSku(customVariant.sku)
      this.productPricingStore.setDynamicPricing(
        customVariant.dynamic_prices.map((price) => {
          return {
            ...price,
            range: {
              start: price.range.start / variant.piecesPerUnit,
              end: price.range.end / variant.piecesPerUnit,
            },
          }
        }),
        sku,
        !!customization
      )

      this.ee.emit(eventTree.pricing.dynamicPricingLoaded)
    } catch (e: any) {
      this.clear()
      this.ecommerceStore.setDynamicVariantError(e.message)

      throw e
    } finally {
      this.ecommerceStore.setIsDynamicVariantLoading(false)
    }
  }

  public clear(): void {
    this.ecommerceStore.setDynamicVariantSku()
    this.productPricingStore.clearCustomPricing()
  }

  private isCurrentVariant(
    sku: string,
    customization?: VariantCustomization
  ): boolean {
    const { productSku: currentSku, customization: currentCustomization } =
      this.productStore

    return sku === currentSku && customization === currentCustomization
  }

  private prepareQuantitiesPayload(
    customization?: VariantCustomization
  ): number[] {
    const { selectedQuantity } = this.ecommerceStore
    const { product } = this.productStore
    const variant = product.getDefaultVariant()

    const customSizeConfig = variant.customSizeConfig

    const quantities =
      this.productPricingStore.getDynamicPricingQuantityRanges(product)

    if (customSizeConfig && customization?.size) {
      const customSizeMinQuantity = customSizeConfig.minQuantity || 0
      quantities.push(Math.max(selectedQuantity, customSizeMinQuantity))
    } else {
      quantities.push(selectedQuantity)
    }

    return quantities.map((quantity) => quantity * variant.piecesPerUnit)
  }

  private prepareCustomizationPayload(
    customization?: VariantCustomization
  ): VariantCustomization {
    if (!customization) {
      return {}
    }

    const payload = _cloneDeep(customization)
    const unit = this.productStore.product.getDefaultVariant().size.units.length

    if (payload.size) {
      payload.size = convertCustomSizeUnitToMm(payload.size, unit)
    }

    return payload
  }

  private prepareLocalePayload(): LocalePayload {
    const { shippingDestination } = this.shippingDestinationStore
    const region = this.localeConfig.productRegion

    if (!shippingDestination) {
      throw new MissingShippingDestinationError()
    }

    return {
      region,
      zipcode: shippingDestination.zipCode,
      country_iso: shippingDestination.country.iso,
    }
  }
}
