import axios from "axios"
import RegionConfig from "../../../../configs/region-config/interfaces/js/region-config"

export function apiClientSetup(baseURL, errorInterceptor) {
  const axiosInstance = axios.create({
    baseURL,
    timeout: 30000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // @ts-ignore
      "X-Packhelp-Region": RegionConfig.getCurrentRegion(),
    },
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (typeof errorInterceptor === "function") errorInterceptor(error)
      return Promise.reject(error)
    }
  )

  return axiosInstance
}
