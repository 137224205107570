import { computed } from "mobx"
import { SidebarStore } from "../stores/sidebar.store"
import { EditorModeController } from "./editor-mode.controller"
import { TabDefinition, tabs } from "../ui/dsl/organisms/tabs/tabs.definition"
import { SidebarTab } from "../libs/products-render-config/types"

export class AsideController {
  private readonly editorModeController: EditorModeController
  private readonly sidebarStore: SidebarStore

  constructor(
    services: { editorModeController: EditorModeController },
    stores: { sidebarStore: SidebarStore }
  ) {
    this.editorModeController = services.editorModeController
    this.sidebarStore = stores.sidebarStore
  }

  @computed
  public get isAvailable(): boolean {
    return !this.editorModeController.isDesignReadOnly
  }

  @computed
  public get isSidebarVisible(): boolean {
    return !this.isSkippingDesign
  }

  @computed
  public get isSkippingDesign(): boolean {
    return this.editorModeController.isSkippingDesign
  }

  @computed
  public get selectedTab(): SidebarTab | undefined {
    if (this.isSkippingDesign) {
      return
    }

    return this.sidebarStore.selectedTab
  }

  @computed
  public get isCloseButtonVisible(): boolean {
    return !this.isSkippingDesign && !!this.selectedTab
  }

  @computed
  public get isOpen(): boolean {
    return this.isSkippingDesign || !!this.selectedTab
  }

  @computed
  public get availableTabs(): TabDefinition[] {
    return tabs.filter(({ name }) => this.sidebarStore.isTabAvailable(name))
  }

  public closeTab(): void {
    this.sidebarStore.closeTab(true)
  }
}
