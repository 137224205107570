/** @format */
// product sku def
import SkuFinish from "./labelSkuDefinitions/SkuFinish";
import SkuMaterial from "./labelSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./labelSkuDefinitions/SkuPrintMode";
import SkuModel from "./labelSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./labelPartsDefinitions/PartFinish";
import PartMaterial from "./labelPartsDefinitions/PartMaterial";
import PartPrintMode from "./labelPartsDefinitions/PartPrintMode";
import PartModel from "./labelPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var LABEL_CONSTS = {
    SKU_BASE: "label-",
    PRODUCT_TYPE: "label",
    DEFAULT_LINE_ITEM_RANGES: [
        30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500,
    ],
};
export default Object.assign({}, LABEL_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
