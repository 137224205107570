import { GetContainerFormat } from "iti"
import { AssetsStore } from "../stores/assets.store"
import { ee } from "../events/editor.events"
import { ProductDesignStore } from "../stores/product-design.store"
import ProductDriver from "../drivers/product.driver"
import type { ApiSessionContainer } from "./api-session.container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import { ObjectClipboardStore } from "../stores/object-clipboard.store"
import { SaveProductDesignController } from "../controllers/product-design/save-product-design.controller"
import { ProductDesignUiController } from "../controllers/product-design/product-design-ui.controller"
import type { UtilEnvContainer } from "./util.container"
import { ChangeProductController } from "../controllers/change-product-controller/change-product.controller"
import { DbyModeContainer } from "./dby-mode.container"
import { LoadProductDesignController } from "../controllers/product-design/load-product-design.controller"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { I18N } from "../ui/i18n"
import Basil from "basil.js"
import { RenderEngineBuilder } from "../render-engine/services/render-engine.builder"
import EventEmitter from "eventemitter3"
import { DielineGenerator } from "../render-engine/modules/dieline-manager/dieline.generator"
import { DielineStore } from "../stores/dieline.store"
import { ModelConfigProvider } from "../render-engine/modules/3d-renderer/model-config.provider"
import { AuthMaybeContainer } from "./auth-maybe.container"

export async function provideDesignAndProductDriverContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  dbyContainer: DbyModeContainer,
  authContainer: AuthMaybeContainer
) {
  const { uri, appConfig } = utilEnvContainer
  const { designApi, ecommerceApi, pqsApi, sessionStore } = apiSessionContainer
  const { productRenderPilot, productStore, designMeta } = rootBootstrapper
  const editorMode = productRenderPilot.getEditorMode()
  const { dbyModeStore } = dbyContainer
  const { authController } = authContainer

  const assetStore = new AssetsStore({
    logoApi: appConfig.api.ecommerce.features.user.logo
      ? ecommerceApi.logo
      : undefined,
    ee,
    config: {
      softDelete: editorMode !== "designer",
    },
  })

  const formatMessage = await getFormatMessage()

  const productDesignStore = new ProductDesignStore(
    {
      ee,
    },
    {
      productStore,
      assetStore,
    },
    {
      designName: formatMessage({
        id: I18N.component.navbar.defaultDesignName,
      }),
      designStatus: designMeta.designStatus,
      dataReadOnly: designMeta.dataReadOnly,
    }
  )
  productDesignStore.setProductDesignStatus(designMeta.designStatus)

  const dielineGenerator = new DielineGenerator(pqsApi.dielines)

  const dielineStore = new DielineStore(
    dielineGenerator,
    appConfig.locale.productRegion
  )
  await dielineStore.loadDielineUrls(productRenderPilot)

  const modelConfigProvider = new ModelConfigProvider(dielineStore)

  const eventEmitter = new EventEmitter()
  const renderEngineBuilder = new RenderEngineBuilder(
    dielineStore,
    modelConfigProvider,
    eventEmitter
  )
  const productDriver = new ProductDriver(
    {
      renderEngineBuilder,
      productRenderPilot,
      eventEmitter,
      ee,
    },
    {
      productStore,
      productDesignStore,
      dielineStore,
    }
  )

  /**
   * TODO:
   *
   * We can move ProductDesignSaveController & ProductDesignUiController to EcommerceContainer in the future
   * as there is no need to init them in the designer mode (and then we can remove editorMode checkers from ProductDesignSaveController).
   *
   * Anyway, we can skip it for now because EcommerceContainer is always initialized (it will be changed later, step by step ;)).
   */
  const saveProductDesignController = new SaveProductDesignController(
    productDriver,
    productDesignStore,
    dbyModeStore,
    designApi,
    ecommerceApi,
    ee,
    editorMode,
    !Basil.cookie.get("editor_no_auto_save")
  )

  const productDesignUiController = new ProductDesignUiController(
    {
      saveProductDesignController,
      designApi,
      ecommerceApi,
      uri,
      authController,
    },
    {
      productStore,
      productDesignStore,
      sessionStore,
    }
  )

  const changeProductController = new ChangeProductController(
    editorMode,
    {
      productDriver,
      ee,
      uri,
    },
    {
      productDesignStore,
      dielineStore,
      dbyModeStore,
    }
  )

  new ObjectClipboardStore(productDriver, ee)

  const loadProductDesignController = new LoadProductDesignController(
    designApi,
    productDesignStore,
    dbyModeStore,
    assetStore,
    productRenderPilot.getEditorMode()
  )

  return {
    assetStore,
    productDesignStore,
    productDriver,
    productDesignUiController,
    changeProductController,
    loadProductDesignController,
    ee,
    dielineStore,
    saveProductDesignController,
  }
}

export type DesignAndProductDriverContainer = GetContainerFormat<
  typeof provideDesignAndProductDriverContainer
>
