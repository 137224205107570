import FontFaceObserver from "fontfaceobserver"
import {
  Fonts,
  LOADABLE_FONTS_CONFIGS,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.config"
import {
  FontStyle,
  FontWeight,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import { Logger } from "../../../../../../services/logger"
import { PackhelpEditableText } from "../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../libs/vendors/Fabric"
import {
  EditableObjectTypes,
  isAssetText,
  VirtualDielineObject,
} from "../../../../../../types/asset.types"

const logger = Logger("ph:editor:font-helper")

export class FontHelper {
  public static async preloadAndFixTextObjects(
    objects: VirtualDielineObject[]
  ): Promise<void> {
    await Promise.all(
      objects.map(async (object) => {
        if (object.objects) {
          return await this.preloadAndFixTextObjects(object.objects)
        }

        if (object.type === "i-text") {
          object.assetType = EditableObjectTypes.assetText
        }

        if (isAssetText(object)) {
          FontHelper.fixFontAndNotify(object)
          await FontHelper.preloadObjectFont(object)
          FontHelper.clearFabricFontCache(object.fontFamily)
        }
      })
    )
  }

  public static async preloadObjectFont(
    textObject: PackhelpEditableText
  ): Promise<void> {
    const { fontFamily, fontStyle, fontWeight } = textObject
    await this.preloadFont({ fontFamily, fontStyle, fontWeight })
  }

  public static async preloadFont({
    fontFamily,
    fontStyle = FontStyle.normal,
    fontWeight = FontWeight.normal,
  }: {
    fontFamily: Fonts
    fontStyle?: FontStyle
    fontWeight?: FontWeight
  }) {
    const fontFaceObserver = new FontFaceObserver(fontFamily, {
      style: fontStyle,
      weight: fontWeight,
    })

    await fontFaceObserver.load().catch((e) => {
      logger.error(
        `Font family ${fontFamily} (style: ${fontStyle}, weight: ${fontWeight}) can not be loaded`
      )
      logger.captureException(new Error(`FontFaceObserver: ${e}`))
    })
  }

  public static clearFabricFontCache(fontFamily?: string) {
    fabric.util.clearFabricFontCache(fontFamily)
  }

  public static fixFontAndNotify(
    textObject: PackhelpEditableText
  ): PackhelpEditableText {
    if (!textObject.fontFamily) {
      return textObject
    }

    const fontFamilyConfig = LOADABLE_FONTS_CONFIGS[textObject.fontFamily]

    if (!fontFamilyConfig) {
      return textObject
    }

    const isBold = textObject.fontWeight === FontWeight.bold
    const isItalic = textObject.fontStyle === FontStyle.italic

    const isFontWeightValid = [FontWeight.bold, FontWeight.normal].includes(
      textObject.fontWeight as FontWeight
    )
    const isFontStyleValid = [FontStyle.italic, FontStyle.normal].includes(
      textObject.fontStyle as FontStyle
    )

    let shouldNotify = false

    if (isBold && isItalic && !fontFamilyConfig.boldItalic) {
      textObject.fontWeight = FontWeight.normal
      textObject.fontStyle = FontStyle.normal
      shouldNotify = true
    }

    if ((isBold && !fontFamilyConfig.bold) || !isFontWeightValid) {
      textObject.fontWeight = FontWeight.normal
      shouldNotify = true
    }

    if ((isItalic && !fontFamilyConfig.italic) || !isFontStyleValid) {
      textObject.fontStyle = FontStyle.normal
      shouldNotify = true
    }

    if (shouldNotify) {
      const predefinedTextId = textObject.predefinedTextId

      console.error(
        `The predefined text (ID=${predefinedTextId}) currently being added to canvas is using font weight and/or font style (italic) which is not supported for the font family we're using. We've cleared it to normal weight and style to avoid problems in production, but please fix the predefined text.`,
        textObject
      )
    }

    return textObject
  }
}
