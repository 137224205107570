import { AxiosInstance } from "axios"
import { DirectusTemplate } from "../types"

export interface GetTemplateByIdOptions {
  id: number
  candidateId?: number | null
}

export interface GetTemplatesOptions {
  sku: string
}

export class DirectusTemplateApi {
  constructor(private ax: AxiosInstance) {}

  public async save(id: number, data: { fileId: string }): Promise<void> {
    await this.ax.post(`templates/${id}/candidates`, data)
  }

  public async getTemplates(
    options?: GetTemplatesOptions
  ): Promise<DirectusTemplate[]> {
    const response = await this.ax.get<{ data: DirectusTemplate[] }>(
      "templates",
      {
        params: {
          ...options,
          limit: -1,
        },
      }
    )

    return response.data.data
  }

  public async getById({
    id,
    candidateId = null,
  }: GetTemplateByIdOptions): Promise<DirectusTemplate> {
    const response = await this.ax.get<{ data: DirectusTemplate }>(
      `templates/${id}`,
      {
        params: { candidateId },
      }
    )

    return response.data.data
  }
}
