import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../../Tab/TabSection"
import { I18N } from "../../../../../i18n"
import { OptionBox } from "../../../../molecules/OptionBox/OptionBox"
import imageUrl from "../../../../../assets/_images/option-box--trees.png"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { Shape } from "../../../../../../models/shape"

const i18n = I18N.editorTools.eco

export const TreeSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ecommerce, c.assets])

  if (!containerSet) {
    return null
  }

  const { ecommerce, assets } = containerSet

  if (!ecommerce.available) {
    return null
  }

  const { plantingTreesStore } = ecommerce
  const { isSelected, price } = plantingTreesStore
  const { ecoShapesStore, shapesController } = assets
  const { plantingTreesBadges } = ecoShapesStore

  const onClick = () => {
    plantingTreesStore.toggleSelected()
  }

  const onShapeClick = (shape: Shape) => {
    shapesController.addEcoShape(shape)
  }

  return (
    <TabSection>
      <OptionBox
        title={t(i18n.plantTrees)}
        description={t(i18n.supportForest)}
        onClick={onClick}
        active={isSelected}
        imageUrl={imageUrl}
        price={price}
        analyticsSection="left-tab-certificates"
        analyticsName="plant-trees"
        e2eTargetName="plant-trees"
      />
      {isSelected && (
        <TileList cols={3}>
          {plantingTreesBadges.map((shape) => {
            return (
              <Tile
                key={shape.id}
                onClick={() => onShapeClick(shape)}
                analyticsSection="left-tab-certificates"
                analyticsType="thumbnail"
                analyticsName="shape"
                analyticsId={shape.title}
                e2eTarget="button"
                e2eTargetName="trees-shape"
              >
                <figure>
                  <img src={shape.src} alt={shape.title} />
                </figure>
              </Tile>
            )
          })}
        </TileList>
      )}
    </TabSection>
  )
})
