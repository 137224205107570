import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableShape } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { EditableObjectTypes } from "../../../../../../types/asset.types"
import { ScaleHelper } from "../helpers/scale.helper"
import { ColorHelper } from "../helpers/color.helper"
import { Shape } from "../../../../../../models/shape"
import { ShapeHelper } from "../helpers/shape.helper"
import { DEFAULT_COLOR } from "../asset-color.controller"
import { Creator } from "./creator"

export class ShapeCreator extends Creator {
  public async create(
    shape: Shape,
    spaceId: SpaceId
  ): Promise<PackhelpEditableShape> {
    const object = await ShapeHelper.shapeToPackhelpObject(shape)

    object.set({
      lockUniScaling: true,
      assetType: EditableObjectTypes.assetObject,
      id: uuidv4(),
      originSpaceArea: spaceId,
      isSpaceClippingDisabled: this.vdEditor.isSpaceClippingTogglingAvailable(),
    })

    // We need to reset it to the default color (#000000),
    // because SVG images have a fill property set to #2F2F2F
    if (object.assetObjectMeta?.colourConfig.colourParam === "fill") {
      object.fill = DEFAULT_COLOR.getHex()
    }

    ColorHelper.applyDefaultColor(this.vdEditor, object)

    this.scaleObject(object, spaceId)
    this.alignObject(object)
    this.rotateObject(object, spaceId)
    await this.setObjectSpaceClipping(object, spaceId)

    return object
  }

  private scaleObject(object: PackhelpEditableShape, spaceId: SpaceId) {
    const minScale = ScaleHelper.calcMinScaleLimit(this.vdEditor, object)

    if (minScale) {
      object.set({
        scaleX: minScale,
        scaleY: minScale,
      })

      return
    }

    const MANUALLY_ADJUSTED_SIZE = 256
    ScaleHelper.scaleObjectToSpace(
      this.vdEditor,
      object,
      {
        width: MANUALLY_ADJUSTED_SIZE,
        height: MANUALLY_ADJUSTED_SIZE,
      },
      spaceId
    )
  }
}
