import _each from "lodash/each"
import _extend from "lodash/extend"
import _isObject from "lodash/isObject"
import { ProductManager, ProductFactory } from "@ph/product-api"
import { setAnonymousIdFromUrl } from "../../helpers/region-switcher.helpers"
import { EventsConsts } from "./segment-analytics.consts"
import RegionConfig from "../../../../configs/region-config/interfaces/js/region-config"
import { getProductApiConfig } from "../../helpers/get-product-api-config"
import { getValueInEuro } from "../../quote-request-helpers/utils/get-value-in-euro"

export type ProductParamsFromProduct = {
  product_id: number | string
  product_sku: string
  product_name: string
  product_size: string
  product_size_type: string
  product_type: string
  product_image_url: string
  sku: string
}

export type LineItemParams = {
  price: number
  quantity: number
  value: number
  product_thumbnail: string
  paid_assets: {
    asset_type: string
    name: string
    variant_id: number
  }[]
  purchase_trigger: string
  fsc: boolean
  skipped_design: boolean
}

type ProductParamsFromLineItem = ProductParamsFromProduct & LineItemParams

export type ProductParamsFromOrder = ProductParamsFromLineItem & {
  checkout_id: string
  currency: string
  discount: string
  order_id: string
  promo_code: string | null
  location?: string
}

type OrderParams = {
  order_id: string
  checkout_id: string
  value: number
  shipping: string
  tax: string
  discount: string
  currency: string
  revenue?: string
  promo_code: string
  products: ProductParamsFromLineItem[]
  line_items_count: number
  total_eur: number
  total_estimated_gross_margin_value_eur: number | null
  billing_country: string
}

type ItemInCartParams = {
  product_id: string | number
  product_name: string
  product_sku: string
  sku: string
  currency: string
  value: number
}

export default class SegmentAnalytics {
  static functionsQueue: any[] = []
  static isQueueEnabled = false
  static queueInterval: any = null
  static queueIntervalTimeout = Infinity

  public static segmentApi(): any {
    setAnonymousIdFromUrl()

    if (typeof window === "undefined") return

    return window.analytics
  }

  public static async anonymousId() {
    return window.analytics && window.analytics.getAnonymousId
      ? window.analytics.getAnonymousId()
      : null
  }

  private static isAnalyticsReady(): boolean {
    return typeof window !== "undefined" && _isObject(window.analytics)
  }

  public static runWhenReady(cb: () => void): void {
    try {
      if (SegmentAnalytics.isAnalyticsReady()) {
        cb()
      } else {
        SegmentAnalytics.addToQueue(cb)
        SegmentAnalytics.enableQueue()
      }
    } catch (e: any) {
      SegmentAnalytics.handleError(e)
    }
  }

  private static addToQueue(func): void {
    SegmentAnalytics.functionsQueue.push(func)
  }

  private static executeQueue(): void {
    try {
      _each(SegmentAnalytics.functionsQueue, (func) => {
        func()
      })
    } catch (e: any) {
      SegmentAnalytics.handleError(e)
    }
  }

  private static checkQueueStatus(): void {
    if (SegmentAnalytics.isAnalyticsReady()) {
      SegmentAnalytics.isQueueEnabled = false
      clearInterval(SegmentAnalytics.queueInterval)
      SegmentAnalytics.executeQueue()
    }
  }

  private static enableQueue(): void {
    if (
      SegmentAnalytics.isQueueEnabled ||
      SegmentAnalytics.isAnalyticsReady()
    ) {
      return
    }

    SegmentAnalytics.isQueueEnabled = true
    SegmentAnalytics.queueInterval = setInterval(
      () => SegmentAnalytics.checkQueueStatus(),
      SegmentAnalytics.queueIntervalTimeout
    )
  }

  private static handleError(e: any, returnValue: any = {}): any {
    if (window.Sentry) {
      window.Sentry.captureException(e)

      return returnValue
    } else {
      throw e
    }
  }

  public static async getParamsFromSpreeOrder(
    order,
    { withRevenue } = {
      withRevenue: false,
    }
  ): Promise<Partial<OrderParams>> {
    try {
      const isCustomOffer = order.channel === "custom"
      const products = await Promise.all(
        order.line_items.map(async (item) => {
          return SegmentAnalytics.getParamsFromSpreeLineItem(
            item,
            isCustomOffer
          )
        })
      )

      const margin = SegmentAnalytics.getOrderBlendedMargin(products)

      const marginInEur =
        margin !== null
          ? getValueInEuro(margin.toString(), order.currency)
          : null

      return {
        order_id: order.number,
        checkout_id: order.number,
        value: Number(order.total),
        shipping: order.ship_total,
        tax: order.tax_total,
        discount: order.promotion_amount,
        currency: order.currency,
        revenue: withRevenue ? order.total : undefined,
        promo_code: order.promo_code,
        total_eur: getValueInEuro(order.total, order.currency),
        total_estimated_gross_margin_value_eur: marginInEur,
        billing_country: order.billCountryIso,
        products,
        line_items_count: order.line_items.count,
      }
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static getPromoCodeParams(order, action, error) {
    const basePromoCodeInfo = {
      order_id: order.number,
      cart_id: order.number,
      coupon_code: order.promo_code,
    }

    switch (action) {
      case EventsConsts.COUPON_ENTERED:
        return basePromoCodeInfo
      case EventsConsts.COUPON_APPLIED || EventsConsts.COUPON_REMOVED:
        return {
          ...basePromoCodeInfo,
          coupon_name: order.promo_code,
          discount: order.promo_total,
        }
      case EventsConsts.COUPON_DENIED:
        return {
          ...basePromoCodeInfo,
          reason: error,
        }
      default:
        return null
    }
  }

  public static getParamsFromSpreeOffer(offer) {
    const { currency, email, id, order_manager, valid_until, url, qr_number } =
      offer

    try {
      return {
        co_currency: currency,
        email,
        offer_id: id,
        order_id: offer.order_number,
        order_manager: {
          email: order_manager.email,
          name: order_manager.name,
          url_avatar: order_manager.avatar,
        },
        qr_number,
        co_url: url,
        valid_until,
        view_date: new Date(),
        z_last_used_locale: RegionConfig.getCurrentLocale(),
        z_last_used_region: RegionConfig.getCurrentRegion(),
      }
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static async getProductParamsFromOrder(
    order,
    item
  ): Promise<Partial<ProductParamsFromOrder>> {
    if (!item) {
      return SegmentAnalytics.handleError(
        "SegmentAnalytics::getProductInfo: line item was not specified"
      )
    }

    try {
      return {
        ...(await SegmentAnalytics.getParamsFromSpreeLineItem(item)),
        checkout_id: order.number,
        currency: order.currency,
        discount: order.promotion_amount,
        order_id: order.number,
        promo_code: order.promo_code,
      }
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static async getParamsFromItemInCart(
    lineItem,
    currency?: string
  ): Promise<Partial<ItemInCartParams>> {
    try {
      const params = await this.getProductParamsFromProduct(lineItem.product)
      const { product_name, product_id, product_sku, sku } = params

      return {
        product_id,
        product_name,
        product_sku,
        sku,
        currency,
        value: parseFloat(lineItem.total),
      }
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  private static async getParamsFromSpreeLineItem(
    lineItem,
    isCustomOffer = false
  ): Promise<Partial<ProductParamsFromLineItem>> {
    try {
      const sku = lineItem.variant.external_pim_sku || lineItem.variant.sku
      const product =
        lineItem.product ||
        (await new ProductFactory({
          api: getProductApiConfig(),
          withEmptyPricing: true,
          withUnavailable: true,
        }).call(sku))

      const paidAssets = lineItem.paid_assets || []
      const hasFsc = !!paidAssets.find(
        (asset) => asset.asset_type === "service-fsc-certificate"
      )
      const skippedDesign =
        lineItem.box_design && lineItem.box_design.design_status == "draft"

      return _extend(
        {},
        {
          price: Number(lineItem.price),
          quantity: Number(lineItem.quantity),
          value: parseFloat(lineItem.total),
          product_thumbnail: lineItem.thumbnail,
          paid_assets: paidAssets,
          purchase_trigger: lineItem.purchase_trigger,
          fsc: hasFsc,
          skipped_design: skippedDesign,
        },
        await SegmentAnalytics.getProductParamsFromProduct(
          product,
          isCustomOffer
        )
      )
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static async getProductParamsFromSku(
    sku: string
  ): Promise<Partial<ProductParamsFromProduct>> {
    try {
      const product = await new ProductFactory({
        api: getProductApiConfig(),
        withEmptyPricing: true,
        withUnavailable: true,
      }).call(sku)

      return this.getProductParamsFromProduct(product)
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static getProductParamsFromProduct(
    product: ProductManager,
    isCustomOffer = false
  ): ProductParamsFromProduct {
    try {
      const { variantManager, wizzardManager, assetsManager } = product

      const isSample = wizzardManager.isProductSamplePack()

      const getProductId = (): string | number => {
        if (isSample) return "sample"
        if (isCustomOffer) return "custom-custom"

        return product.id
      }

      return {
        product_id: getProductId(),
        product_sku: variantManager.getSku(),
        product_name: product.translatedName || variantManager.getName(),
        product_size: variantManager.getSize(),
        product_size_type: variantManager.getSizeType(),
        product_type: isSample ? "sample" : variantManager.getProductType(),
        product_image_url:
          assetsManager && assetsManager.getMainKeyVisualPath(),
        sku: variantManager.getVariantDashed(),
      }
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  public static getCommonDataForSpreeCheckoutProcess(order, params) {
    try {
      return _extend(
        {},
        {
          checkout_id: order.number,
        },
        params
      )
    } catch (e: any) {
      return SegmentAnalytics.handleError(e)
    }
  }

  private static getOrderBlendedMargin(
    products: Partial<ProductParamsFromLineItem>[]
  ): number | null {
    const marginForCustoms = 0.38
    const marginForStocks = 0.3

    for (const product of products) {
      if (product.product_type === "sample") {
        return null
      }
    }

    const margin = products.reduce((totalMargin, product) => {
      const margin =
        product.product_type === "custom" ? marginForCustoms : marginForStocks
      const productValue = product.value || 0

      return totalMargin + productValue * margin
    }, 0)

    return parseFloat(margin.toFixed(2))
  }
}

if (typeof window !== "undefined") {
  // Make our live with node.js easier... :)
  if (
    typeof global !== "undefined" &&
    global != null &&
    global.window == null
  ) {
    // @ts-ignore
    global.window = {}
  }

  // @ts-ignore
  window.SegmentAnalytics = SegmentAnalytics
}
