import React, { PropsWithChildren } from "react"
import styles from "./Tile.module.scss"
import cxBinder from "classnames/bind"
import { usePortal } from "../../../hooks/usePortal"
import menuIconUrl from "../../../assets/_icons/dots.svg"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

const cx = cxBinder.bind(styles)

export interface TileProps extends AnalyticsProps, TestsProps {
  onClick: () => void
  monochromatic?: boolean
  contextMenu?: React.ReactNode
  badge?: React.ReactNode
  active?: boolean
  disabled?: boolean
  label?: string
}

export function Tile({
  children,
  onClick,
  monochromatic = false,
  contextMenu,
  badge,
  active = false,
  disabled = false,
  label,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: PropsWithChildren<TileProps>) {
  const { isPortalOpen, togglePortal, Portal } = usePortal({
    offsetTop: 2,
  })

  return (
    <div className={styles.wrapper}>
      <button
        onClick={onClick}
        className={cx("tile", { active: active || isPortalOpen })}
        disabled={disabled}
        analytics-section={analyticsSection}
        analytics-type={analyticsType}
        analytics-name={analyticsName}
        analytics-id={analyticsId}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div className={styles.square}>
          <div
            className={cx("content", {
              monochromatic,
            })}
          >
            {children}
            {badge && <div className={styles.badge}>{badge}</div>}
          </div>
        </div>
        {label && <div className={styles.label}>{label}</div>}
      </button>
      {contextMenu && (
        <>
          <button className={styles.menu} onClick={togglePortal}>
            <SvgIcon url={menuIconUrl} size="xs" />
          </button>

          {isPortalOpen && <Portal>{contextMenu}</Portal>}
        </>
      )}
    </div>
  )
}
