import * as CountriesI18n from "i18n-iso-countries"
import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"
import { Locale } from "../../../configs/region-config/interfaces/js/region-config"

export const loadI18nCountries = (locale: string) => {
  // CountriesI18n.registerLocale(
  //     require(`i18n-iso-countries/langs/${locale}.json`)
  // )

  // Please do not refactor it.
  // Product Configurator Light does not allow creating require with dynamic variable
  const localeMap = {
    en: () => require("i18n-iso-countries/langs/en.json"),
    es: () => require("i18n-iso-countries/langs/es.json"),
    fr: () => require("i18n-iso-countries/langs/fr.json"),
    de: () => require("i18n-iso-countries/langs/de.json"),
    cs: () => require("i18n-iso-countries/langs/cs.json"),
    it: () => require("i18n-iso-countries/langs/it.json"),
    pl: () => require("i18n-iso-countries/langs/pl.json"),
    ro: () => require("i18n-iso-countries/langs/ro.json"),
    nl: () => require("i18n-iso-countries/langs/nl.json"),
    sv: () => require("i18n-iso-countries/langs/sv.json"),
  }

  CountriesI18n.registerLocale(localeMap[locale]() || localeMap["en"]())
}

const loadI18nForCurrentLocale = () => {
  const iso639CurrentLocale = RegionConfig.getCurrentLocaleIso639()
  loadI18nCountries(iso639CurrentLocale)
}

const loadI18nForForcedLocale = () => {
  const forcedLocale = new URLSearchParams(window.location.search).get(
    "locale"
  ) as Locale

  if (forcedLocale) {
    const iso639ForcedLocale =
      RegionConfig.convertRegionToIso639Locale(forcedLocale)

    if (iso639ForcedLocale) {
      loadI18nCountries(iso639ForcedLocale)
    } else {
      throw new Error("Incorrect or unsupported forced locale")
    }
  }
}

loadI18nForCurrentLocale()
loadI18nForForcedLocale()

export { CountriesI18n as countriesI18n }
