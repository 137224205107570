var _a;
import _invert from "lodash/invert";
import ProductConsts from "../../definitions/products/ProductConsts";
var skuParts = (_a = {},
    // Boxes
    _a[ProductConsts["BoxProductConsts"]
        .BOX_MODEL_PRE_PRINTED_MAILER_BOX_FULL_COLOUR] = ProductConsts["BoxProductConsts"]
        .SKU_MODEL_PRE_PRINTED_MAILER_BOX_FULL_COLOUR,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_PRE_PRINTED_MAILER_BOX_ECO] = ProductConsts["BoxProductConsts"].SKU_MODEL_PRE_PRINTED_MAILER_BOX_ECO,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_MAILER_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_MAILER_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_MAILER_BOX_PLAIN] = ProductConsts["BoxProductConsts"].SKU_MODEL_MAILER_BOX_PLAIN,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_SLEEVED_MAILER_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_SLEEVED_MAILER_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_PRODUCT_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_PRODUCT_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_PRODUCT_BOX_TWO_PIECES] = ProductConsts["BoxProductConsts"].SKU_MODEL_PRODUCT_BOX_TWO_PIECES,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_PRODUCT_BOX_WINE_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_PRODUCT_BOX_WINE_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_SHIPPING_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_SHIPPING_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_SHIPPING_BOX_PLAIN] = ProductConsts["BoxProductConsts"].SKU_MODEL_SHIPPING_BOX_PLAIN,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_SHIPPING_BOX_PLAIN_V2] = ProductConsts["BoxProductConsts"].SKU_MODEL_SHIPPING_BOX_PLAIN_V2,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_MAILER_BOX_WHITE_INK] = ProductConsts["BoxProductConsts"].SKU_MODEL_MAILER_BOX_WHITE_INK,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_DELIVERY_MAILER_BOX] = ProductConsts["BoxProductConsts"].SKU_MODEL_DELIVERY_MAILER_BOX,
    _a[ProductConsts["BoxProductConsts"].BOX_MODEL_DELIVERY_MAILER_BOX_PLAIN] = ProductConsts["BoxProductConsts"].SKU_MODEL_DELIVERY_MAILER_BOX_PLAIN,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_COLOR_BUDGET] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_COLOR_BUDGET,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_COLOR_ECO] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_COLOR_ECO,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_COLOR_HD] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_COLOR_HD,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_MONOCHROME] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_MONOCHROME,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_MONOCHROME_WITH_PRINT_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_PRINT_COLOR_MODE_MONOCHROME_WITH_PRINT_INSIDE,
    _a[ProductConsts["BoxProductConsts"]
        .PRINT_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_PRINT_COLOR_MODE_MONOCHROME_WHITE_WITH_PRINT_INSIDE,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_COLOR_ECO_WITH_PRINT_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_PRINT_COLOR_MODE_COLOR_ECO_WITH_PRINT_INSIDE,
    _a[ProductConsts["BoxProductConsts"].PRINT_MODE_MONOCHROME_WHITE] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_MONOCHROME_WHITE,
    _a[ProductConsts["BoxProductConsts"].PRINT_COLOR_MODE_INDIGO_COLOR] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_INDIGO_COLOR,
    _a[ProductConsts["BoxProductConsts"].PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_PRINT_COLOR_MODE_INDIGO_COLOR_WITH_INSIDE,
    _a[ProductConsts["BoxProductConsts"].PRINT_COLOR_MODE_NO_PRINT] = ProductConsts["BoxProductConsts"].SKU_PRINT_COLOR_MODE_NO_PRINT,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_WHITE_ONE_SIDE_CARDBOARD] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_WHITE_ONE_SIDE_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_WHITE_TWO_SIDES_CARDBOARD] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_WHITE_TWO_SIDES_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_WHITE_CARDBOARD] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_WHITE_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"]
        .PRINT_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD] = ProductConsts["BoxProductConsts"]
        .SKU_MATERIAL_WHITE_ONE_SIDE_NATURAL_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_COATED_CARDBOARD] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_COATED_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"]
        .PRINT_MATERIAL_NATURAL_CARDBOARD_WHITE_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_MATERIAL_NATURAL_CARDBOARD_WHITE_INSIDE,
    _a[ProductConsts["BoxProductConsts"]
        .PRINT_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE] = ProductConsts["BoxProductConsts"]
        .SKU_MATERIAL_COATED_CARDBOARD_WHITE_INSIDE,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_NATURAL_CARDBOARD] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_NATURAL_CARDBOARD,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_CARDSTOCK] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_CARDSTOCK,
    _a[ProductConsts["BoxProductConsts"].PRINT_MATERIAL_WHITE] = ProductConsts["BoxProductConsts"].SKU_MATERIAL_WHITE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_NONE] = ProductConsts["BoxProductConsts"].SKU_FINISH_NONE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_FOIL_GLOSSY] = ProductConsts["BoxProductConsts"].SKU_FINISH_FOIL_GLOSSY,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_FOIL_MATTE] = ProductConsts["BoxProductConsts"].SKU_FINISH_FOIL_MATTE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_FOIL_MATTE] = ProductConsts["BoxProductConsts"].SKU_FINISH_FOIL_MATTE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_FOIL_MATTE] = ProductConsts["BoxProductConsts"].SKU_FINISH_FOIL_MATTE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_SINGLE_STRIP] = ProductConsts["BoxProductConsts"].SKU_FINISH_SINGLE_STRIP,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_DOUBLE_STRIP] = ProductConsts["BoxProductConsts"].SKU_FINISH_DOUBLE_STRIP,
    // sleeved box finished
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_SLEEVE_WHITE] = ProductConsts["BoxProductConsts"].SKU_FINISH_SLEEVE_WHITE,
    _a[ProductConsts["BoxProductConsts"].PRINT_FINISH_SLEEVE_KRAFT] = ProductConsts["BoxProductConsts"].SKU_FINISH_SLEEVE_KRAFT,
    // Poly mailers
    _a[ProductConsts["PolyMailerProductConsts"].POLY_MAILER_MODEL_DEFAULT] = ProductConsts["PolyMailerProductConsts"].SKU_MODEL_POLY_MAILER_DEFAULT,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MATERIAL_FOIL_BLACK] = ProductConsts["PolyMailerProductConsts"].SKU_MATERIAL_FOIL_BLACK,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MATERIAL_FOIL_WHITE] = ProductConsts["PolyMailerProductConsts"].SKU_MATERIAL_FOIL_WHITE,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MATERIAL_FOIL_TRANSPARENT] = ProductConsts["PolyMailerProductConsts"].SKU_MATERIAL_FOIL_TRANSPARENT,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MODE_MONO_PANTONE_ONE_SIDE] = ProductConsts["PolyMailerProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_ONE_SIDE,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MODE_MONO_PANTONE_TWO_SIDES] = ProductConsts["PolyMailerProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_TWO_SIDES,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_MODE_NO_PRINT] = ProductConsts["PolyMailerProductConsts"].SKU_PRINT_MODE_NO_PRINT,
    _a[ProductConsts["PolyMailerProductConsts"].PRINT_FINISH_NONE] = ProductConsts["PolyMailerProductConsts"].SKU_FINISH_NONE,
    // Bio poly mialers
    _a[ProductConsts["PolyMailerProductConsts"].BIO_POLY_MAILER_MODEL_DEFAULT] = ProductConsts["PolyMailerProductConsts"].SKU_MODEL_BIO_POLY_MAILER_DEFAULT,
    _a[ProductConsts["PolyMailerProductConsts"]
        .BIO_POLY_MAILER_PLAIN_MODEL_DEFAULT] = ProductConsts["PolyMailerProductConsts"]
        .SKU_MODEL_BIO_POLY_MAILER_PLAIN_DEFAULT,
    //Recycled poly mailer
    _a[ProductConsts["PolyMailerProductConsts"].RECYCLED_POLY_MAILER_MODEL_DEFAULT] = ProductConsts["PolyMailerProductConsts"]
        .SKU_MODEL_RECYCLED_POLY_MAILER_DEFAULT,
    _a[ProductConsts["PolyMailerProductConsts"]
        .PRINT_MATERIAL_FOIL_WHITE_BLACK_INSIDE] = ProductConsts["PolyMailerProductConsts"]
        .SKU_MATERIAL_FOIL_WHITE_BLACK_INSIDE,
    // Paper Mailer
    _a[ProductConsts["PaperMailerProductConsts"].PAPER_MAILER_MODEL_DEFAULT] = ProductConsts["PaperMailerProductConsts"].SKU_MODEL_PAPER_MAILER_DEFAULT,
    _a[ProductConsts["PaperMailerProductConsts"].PRINT_MATERIAL_PAPER_KRAFT] = ProductConsts["PaperMailerProductConsts"].SKU_MATERIAL_PAPER_KRAFT,
    _a[ProductConsts["PaperMailerProductConsts"].PRINT_MODE_MONO_PANTONE_ONE_SIDE] = ProductConsts["PaperMailerProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_ONE_SIDE,
    _a[ProductConsts["PaperMailerProductConsts"].PRINT_MODE_MONO_PANTONE_TWO_SIDES] = ProductConsts["PaperMailerProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_TWO_SIDES,
    _a[ProductConsts["PaperMailerProductConsts"].PRINT_FINISH_NONE] = ProductConsts["PaperMailerProductConsts"].SKU_FINISH_NONE,
    //Paper bags
    _a[ProductConsts["PaperBagProductConsts"].PAPER_BAG_MODEL_DEFAULT] = ProductConsts["PaperBagProductConsts"].SKU_MODEL_PAPER_BAG_DEFAULT,
    _a[ProductConsts["PaperBagProductConsts"].DOYPACK_BAG_MODEL] = ProductConsts["PaperBagProductConsts"].SKU_MODEL_DOYPACK_BAG,
    _a[ProductConsts["PaperBagProductConsts"].TOTE_BAG] = ProductConsts["PaperBagProductConsts"].SKU_MODEL_TOTE_BAG,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_PAPER_NATURAL] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_PAPER_NATURAL,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_PAPER_WHITE] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_PAPER_WHITE,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_WHITE] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_WHITE,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_KRAFT] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_KRAFT,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_BLACK] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_BLACK,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_ORGANIC_COTTON] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_ORGANIC_COTTON,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MATERIAL_RECYCLED_COTTON] = ProductConsts["PaperBagProductConsts"].SKU_MATERIAL_RECYCLED_COTTON,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MODE_MONO_PANTONE_ONE_SIDE] = ProductConsts["PaperBagProductConsts"].SKU_PRINT_MODE_MONO_PANTONE_ONE_SIDE,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MODE_MONO_PANTONE_TWO_SIDES] = ProductConsts["PaperBagProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_TWO_SIDES,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_MODE_INDIGO_COLOR] = ProductConsts["PaperBagProductConsts"].SKU_PRINT_MODE_INDIGO_COLOR,
    _a[ProductConsts["PaperBagProductConsts"].PRINT_FINISH_NONE] = ProductConsts["PaperBagProductConsts"].SKU_FINISH_NONE,
    // Paper Tubes
    _a[ProductConsts["PaperTubeProductConsts"].PAPER_TUBE_MODEL_DEFAULT] = ProductConsts["PaperTubeProductConsts"].SKU_MODEL_PAPER_TUBE_DEFAULT,
    _a[ProductConsts["PaperTubeProductConsts"].PRINT_MATERIAL_CARDSTOCK_COATED] = ProductConsts["PaperTubeProductConsts"].SKU_MATERIAL_CARDSTOCK_COATED,
    _a[ProductConsts["PaperTubeProductConsts"].PRINT_COLOR_MODE_INDIGO_COLOR] = ProductConsts["PaperTubeProductConsts"].SKU_PRINT_COLOR_MODE_INDIGO_COLOR,
    _a[ProductConsts["PaperTubeProductConsts"].PRINT_FINISH_NONE] = ProductConsts["PaperTubeProductConsts"].SKU_FINISH_NONE,
    //Envelopes
    _a[ProductConsts["EnvelopeProductConsts"].ENVELOPE_MODEL_DEFAULT] = ProductConsts["EnvelopeProductConsts"].SKU_MODEL_ENVELOPE_DEFAULT,
    _a[ProductConsts["EnvelopeProductConsts"].PRINT_MATERIAL_KRAFT] = ProductConsts["EnvelopeProductConsts"].SKU_MATERIAL_KRAFT,
    _a[ProductConsts["EnvelopeProductConsts"].PRINT_MATERIAL_CARDBOARD_CARDSTOCK] = ProductConsts["EnvelopeProductConsts"].SKU_MATERIAL_CARDBOARD_CARDSTOCK,
    _a[ProductConsts["EnvelopeProductConsts"].PRINT_COLOR_MODE_INDIGO_COLOR] = ProductConsts["EnvelopeProductConsts"].SKU_PRINT_COLOR_MODE_INDIGO_COLOR,
    _a[ProductConsts["EnvelopeProductConsts"].PRINT_FINISH_FOIL_NONE] = ProductConsts["EnvelopeProductConsts"].SKU_FINISH_FOIL_NONE,
    // Tissue Papers
    _a[ProductConsts["TissuePaperProductConsts"].TISSUE_PAPER_MODEL_DEFAULT] = ProductConsts["TissuePaperProductConsts"].SKU_MODEL_TISSUE_PAPER_DEFAULT,
    _a[ProductConsts["TissuePaperProductConsts"].PACKING_PAPER_MODEL_DEFAULT] = ProductConsts["TissuePaperProductConsts"].SKU_MODEL_PACKING_PAPER_DEFAULT,
    _a[ProductConsts["TissuePaperProductConsts"].PRINT_MATERIAL_TISSUE] = ProductConsts["TissuePaperProductConsts"].SKU_MATERIAL_TISSUE,
    _a[ProductConsts["TissuePaperProductConsts"].PRINT_MODE_MONO_PANTONE_ONE_SIDE] = ProductConsts["TissuePaperProductConsts"]
        .SKU_PRINT_MODE_MONO_PANTONE_ONE_SIDE,
    _a[ProductConsts["TissuePaperProductConsts"].PRINT_FINISH_NONE] = ProductConsts["TissuePaperProductConsts"].SKU_FINISH_NONE,
    // Tapes
    _a[ProductConsts["TapeProductConsts"].TAPE_MODEL_PACKING_TAPE] = ProductConsts["TapeProductConsts"].SKU_MODEL_PACKING_TAPE,
    _a[ProductConsts["TapeProductConsts"].TAPE_MODEL_KRAFT_TAPE] = ProductConsts["TapeProductConsts"].SKU_MODEL_KRAFT_TAPE,
    _a[ProductConsts["TapeProductConsts"].TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE] = ProductConsts["TapeProductConsts"].SKU_MODEL_PRE_PRINTED_KRAFT_TAPE,
    _a[ProductConsts["TapeProductConsts"].PRINT_MATERIAL_PAPER_KRAFT] = ProductConsts["TapeProductConsts"].SKU_MATERIAL_PAPER_KRAFT,
    _a[ProductConsts["TapeProductConsts"].PRINT_MATERIAL_FOIL_WHITE] = ProductConsts["TapeProductConsts"].SKU_MATERIAL_FOIL_WHITE,
    _a[ProductConsts["TapeProductConsts"].PRINT_MATERIAL_FOIL_TRANSPARENT] = ProductConsts["TapeProductConsts"].SKU_MATERIAL_FOIL_TRANSPARENT,
    _a[ProductConsts["TapeProductConsts"].PRINT_MATERIAL_KRAFT] = ProductConsts["TapeProductConsts"].SKU_MATERIAL_KRAFT,
    _a[ProductConsts["TapeProductConsts"].PRINT_MODE_FLEXO_COLOR] = ProductConsts["TapeProductConsts"].SKU_PRINT_MODE_FLEXO_COLOR,
    _a[ProductConsts["TapeProductConsts"].PRINT_MODE_WHITE_PRINT] = ProductConsts["TapeProductConsts"].SKU_PRINT_MODE_WHITE_PRINT,
    _a[ProductConsts["TapeProductConsts"].PRINT_FINISH_NONE] = ProductConsts["TapeProductConsts"].SKU_FINISH_NONE,
    _a[ProductConsts["TapeProductConsts"].PRINT_FINISH_FRAGILE] = ProductConsts["TapeProductConsts"].SKU_FINISH_FRAGILE,
    _a[ProductConsts["TapeProductConsts"].PRINT_FINISH_PATTERN] = ProductConsts["TapeProductConsts"].SKU_FINISH_PATTERN,
    //Labels
    _a[ProductConsts["LabelProductConsts"].CIRCLE_LABEL_MODEL] = ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_CIRCLE,
    _a[ProductConsts["LabelProductConsts"].SQUARE_LABEL_MODEL] = ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_SQUARE,
    _a[ProductConsts["LabelProductConsts"].RECTANGLE_LABEL_MODEL] = ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_RECTANGLE,
    _a[ProductConsts["LabelProductConsts"].PRINT_MATERIAL_PAPER_MID_GLOSS] = ProductConsts["LabelProductConsts"].SKU_MATERIAL_PAPER_MID_GLOSS,
    _a[ProductConsts["LabelProductConsts"].PRINT_MODE_INDIGO_COLOR] = ProductConsts["LabelProductConsts"].SKU_PRINT_MODE_INDIGO_COLOR,
    _a[ProductConsts["LabelProductConsts"].PRINT_FINISH_NONE] = ProductConsts["LabelProductConsts"].SKU_FINISH_NONE,
    //Card
    _a[ProductConsts["CardProductConsts"].PRINTED_CARD_MODEL] = ProductConsts["CardProductConsts"].SKU_MODEL_PRINTED_CARD,
    _a[ProductConsts["CardProductConsts"].PRINT_MATERIAL_WHITE_MATT] = ProductConsts["CardProductConsts"].SKU_MATERIAL_WHITE_MATT,
    _a[ProductConsts["CardProductConsts"].PRINT_MATERIAL_KRAFT] = ProductConsts["CardProductConsts"].SKU_MATERIAL_KRAFT,
    _a[ProductConsts["CardProductConsts"].PRINT_MODE_INDIGO_PRINT] = ProductConsts["CardProductConsts"].SKU_MATERIAL_PAPER_MID_GLOSS,
    _a[ProductConsts["CardProductConsts"].PRINT_MODE_INDIGO_PRINT_ONE_SIDE] = ProductConsts["CardProductConsts"].SKU_PRINT_MODE_INDIGO_PRINT_ONE_SIDE,
    _a[ProductConsts["CardProductConsts"].PRINT_MODE_INDIGO_PRINT_TWO_SIDES] = ProductConsts["CardProductConsts"].SKU_PRINT_MODE_INDIGO_PRINT_TWO_SIDES,
    _a[ProductConsts["CardProductConsts"].PRINT_FINISH_NONE] = ProductConsts["CardProductConsts"].SKU_FINISH_NONE,
    // Ncp Products
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_WOOD_WOOL] = ProductConsts["NcpProductConsts"].SKU_MODEL_WOOD_WOOL,
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_SELF_ADHESIVE_ENVELOPE] = ProductConsts["NcpProductConsts"].SKU_MODEL_SELF_ADHESIVE_ENVELOPE,
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_STICKER_CIRCLE] = ProductConsts["NcpProductConsts"].SKU_MODEL_STICKER_CIRCLE,
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_STICKER_RECTANGLE] = ProductConsts["NcpProductConsts"].SKU_MODEL_STICKER_RECTANGLE,
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_THANK_YOU_CARD] = ProductConsts["NcpProductConsts"].SKU_MODEL_THANK_YOU_CARD,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_NONE] = ProductConsts["NcpProductConsts"].SKU_FINISH_NONE,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_PINE_SPRUCE] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_PINE_SPRUCE,
    _a[ProductConsts["NcpProductConsts"].PRINT_COLOR_MODE_NO_PRINT] = ProductConsts["NcpProductConsts"].SKU_PRINT_COLOR_MODE_NO_PRINT,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_FOIL_LDPE] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_FOIL_LDPE,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_PAPER_GLOSS] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_PAPER_GLOSS,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_PAPER_LINEN] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_PAPER_LINEN,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_DESIGN_BW_THANK_YOU] = ProductConsts["NcpProductConsts"].SKU_FINISH_DESIGN_BW_THANK_YOU,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_DESIGN_COLOR_HELLO] = ProductConsts["NcpProductConsts"].SKU_FINISH_DESIGN_COLOR_HELLO,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_DESIGN_BW_HELLO] = ProductConsts["NcpProductConsts"].SKU_FINISH_DESIGN_BW_HELLO,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_DESIGN_COLOR_OPEN_ME] = ProductConsts["NcpProductConsts"].SKU_FINISH_DESIGN_COLOR_OPEN_ME,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_DESIGN_COLOR_THANK_YOU] = ProductConsts["NcpProductConsts"].SKU_FINISH_DESIGN_COLOR_THANK_YOU,
    _a[ProductConsts["NcpProductConsts"].NCP_MODEL_PAPER_BUBBLE_WRAP] = ProductConsts["NcpProductConsts"].SKU_MODEL_PAPER_BUBBLE_WRAP,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_WHITE] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_WHITE,
    _a[ProductConsts["NcpProductConsts"].PRINT_MATERIAL_BROWN] = ProductConsts["NcpProductConsts"].SKU_MATERIAL_BROWN,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_BROWN] = ProductConsts["NcpProductConsts"].SKU_FINISH_BROWN,
    _a[ProductConsts["NcpProductConsts"].PRINT_FINISH_WHITE] = ProductConsts["NcpProductConsts"].SKU_FINISH_WHITE,
    _a);
export function translateBoxAttributeKeyToSkuVersion(part) {
    if (skuParts[part]) {
        return skuParts[part];
    }
    else {
        return part;
    }
}
export function translateSkuPartToAttributeKey(part) {
    if (!part) {
        return;
    }
    return _invert(skuParts)[part] || part;
}
export function buildSku(_a) {
    var productRegion = _a.productRegion, productName = _a.productName, productSize = _a.productSize, productMaterial = _a.productMaterial, productColorMode = _a.productColorMode, productFinish = _a.productFinish;
    var skuData = [
        productRegion,
        productName,
        productSize,
        productMaterial,
        productColorMode,
        productFinish,
    ]
        .map(translateBoxAttributeKeyToSkuVersion)
        .join(ProductConsts["SharedConsts"].SKU_DELIMITER);
    var skuBase = getSkuBaseBasedOnProductName(productName);
    if (!productRegion) {
        skuBase = skuBase.slice(0, -1);
    }
    return skuBase + skuData;
}
function splitSkuParts(sku) {
    var splittedSku = sku.split(ProductConsts["SharedConsts"].SKU_DELIMITER);
    return splittedSku;
}
function getSkuBaseBasedOnProductName(productName) {
    switch (productName) {
        // Paper Tubes
        case ProductConsts["PaperTubeProductConsts"].PAPER_TUBE_MODEL_DEFAULT:
        case ProductConsts["PaperTubeProductConsts"].SKU_MODEL_PAPER_TUBE_DEFAULT:
            return ProductConsts["PaperTubeProductConsts"].SKU_BASE;
        // Paper bags
        case ProductConsts["PaperBagProductConsts"].PAPER_BAG_MODEL_DEFAULT:
        case ProductConsts["PaperBagProductConsts"].SKU_MODEL_PAPER_BAG_DEFAULT:
        case ProductConsts["PaperBagProductConsts"].DOYPACK_BAG_MODEL:
        case ProductConsts["PaperBagProductConsts"].SKU_MODEL_DOYPACK_BAG:
        case ProductConsts["PaperBagProductConsts"].TOTE_BAG:
        case ProductConsts["PaperBagProductConsts"].SKU_MODEL_TOTE_BAG:
            return ProductConsts["PaperBagProductConsts"].SKU_BASE;
        // Poly mailers
        case ProductConsts["PolyMailerProductConsts"].POLY_MAILER_MODEL_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"].SKU_MODEL_POLY_MAILER_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"]
            .RECYCLED_POLY_MAILER_MODEL_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"]
            .SKU_MODEL_RECYCLED_POLY_MAILER_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"].BIO_POLY_MAILER_MODEL_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"]
            .SKU_MODEL_BIO_POLY_MAILER_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"]
            .BIO_POLY_MAILER_PLAIN_MODEL_DEFAULT:
        case ProductConsts["PolyMailerProductConsts"]
            .SKU_MODEL_BIO_POLY_MAILER_PLAIN_DEFAULT:
            return ProductConsts["PolyMailerProductConsts"].SKU_BASE;
        // Paper mailers
        case ProductConsts["PaperMailerProductConsts"].PAPER_MAILER_MODEL_DEFAULT:
        case ProductConsts["PaperMailerProductConsts"]
            .SKU_MODEL_PAPER_MAILER_DEFAULT:
            return ProductConsts["PaperMailerProductConsts"].SKU_BASE;
        // Envelopes
        case ProductConsts["EnvelopeProductConsts"].ENVELOPE_MODEL_DEFAULT:
        case ProductConsts["EnvelopeProductConsts"].SKU_MODEL_ENVELOPE_DEFAULT:
            return ProductConsts["EnvelopeProductConsts"].SKU_BASE;
        // Tapes
        case ProductConsts["TapeProductConsts"].TAPE_MODEL_PACKING_TAPE:
        case ProductConsts["TapeProductConsts"].SKU_MODEL_PACKING_TAPE:
        case ProductConsts["TapeProductConsts"].TAPE_MODEL_KRAFT_TAPE:
        case ProductConsts["TapeProductConsts"].SKU_MODEL_KRAFT_TAPE:
        case ProductConsts["TapeProductConsts"].TAPE_MODEL_PRE_PRINTED_KRAFT_TAPE:
        case ProductConsts["TapeProductConsts"].SKU_MODEL_PRE_PRINTED_KRAFT_TAPE:
            return ProductConsts["TapeProductConsts"].SKU_BASE;
        // Tissue Papers
        case ProductConsts["TissuePaperProductConsts"].TISSUE_PAPER_MODEL_DEFAULT:
        case ProductConsts["TissuePaperProductConsts"]
            .SKU_MODEL_TISSUE_PAPER_DEFAULT:
        case ProductConsts["TissuePaperProductConsts"].PACKING_PAPER_MODEL_DEFAULT:
        case ProductConsts["TissuePaperProductConsts"]
            .SKU_MODEL_PACKING_PAPER_DEFAULT:
            return ProductConsts["TissuePaperProductConsts"].SKU_BASE;
        //Labels
        case ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_CIRCLE:
        case ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_SQUARE:
        case ProductConsts["LabelProductConsts"].SKU_MODEL_LABEL_RECTANGLE:
        case ProductConsts["LabelProductConsts"].CIRCLE_LABEL_MODEL:
        case ProductConsts["LabelProductConsts"].SQUARE_LABEL_MODEL:
        case ProductConsts["LabelProductConsts"].RECTANGLE_LABEL_MODEL:
            return ProductConsts["LabelProductConsts"].SKU_BASE;
        //Cards
        case ProductConsts["CardProductConsts"].PRINTED_CARD_MODEL:
        case ProductConsts["CardProductConsts"].SKU_MODEL_PRINTED_CARD:
            return ProductConsts["CardProductConsts"].SKU_BASE;
        // Ncp Products
        case ProductConsts["NcpProductConsts"].NCP_MODEL_WOOD_WOOL:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_WOOD_WOOL:
        case ProductConsts["NcpProductConsts"].NCP_MODEL_SELF_ADHESIVE_ENVELOPE:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_SELF_ADHESIVE_ENVELOPE:
        case ProductConsts["NcpProductConsts"].NCP_MODEL_STICKER_CIRCLE:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_STICKER_CIRCLE:
        case ProductConsts["NcpProductConsts"].NCP_MODEL_STICKER_RECTANGLE:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_STICKER_RECTANGLE:
        case ProductConsts["NcpProductConsts"].NCP_MODEL_THANK_YOU_CARD:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_THANK_YOU_CARD:
        case ProductConsts["NcpProductConsts"].NCP_MODEL_PAPER_BUBBLE_WRAP:
        case ProductConsts["NcpProductConsts"].SKU_MODEL_PAPER_BUBBLE_WRAP:
            return ProductConsts["NcpProductConsts"].SKU_BASE;
        // boxes
        default:
            return ProductConsts["BoxProductConsts"].SKU_BASE;
    }
}
function getValueFromFirstParameter(param) {
    var countChars = param.match(/-/g);
    var arr = [];
    if (countChars && countChars.length > 1) {
        arr = param.split("-");
        arr = arr.splice(2, 2);
    }
    else {
        arr = param.split("-");
        arr.shift();
    }
    return arr.join("-");
}
export function convertSkuToCurrentRegion(sku, locale) {
    var parsedSku = getSkuData(sku);
    parsedSku.productRegion = locale;
    return buildSku({
        productRegion: parsedSku.productRegion,
        productName: parsedSku.productName,
        productSize: parsedSku.productSize,
        productMaterial: parsedSku.productMaterial,
        productColorMode: parsedSku.productColorMode,
        productFinish: parsedSku.productFinish,
    });
}
function isSkuV3(parsedSku) {
    return [6, 7].includes(parsedSku.length); // 7 for dynamic variants
}
function isSkuEcommercePim(parsedSku) {
    return ([4, 5].includes(parsedSku.length) && parsedSku[0].startsWith("pim-product") // 5 for dynamic variants
    );
}
function getRegionFromSkuPart(skuPart) {
    var region = skuPart.split("-").pop();
    if (region.length !== 2) {
        return;
    }
    return region;
}
function constructSkuData(parsedSku) {
    if (isSkuV3(parsedSku) || isSkuEcommercePim(parsedSku)) {
        return {
            productRegion: getRegionFromSkuPart(parsedSku[0]),
            productName: parsedSku[1],
            productSize: parsedSku[2],
            productMaterial: parsedSku[3],
            productColorMode: parsedSku[4],
            productFinish: parsedSku[5],
        };
    }
    else {
        return {
            productName: getValueFromFirstParameter(parsedSku[0]),
            productSize: parsedSku[1],
            productMaterial: parsedSku[2],
            productColorMode: parsedSku[3],
            productFinish: parsedSku[4],
        };
    }
}
export function isProductTypeCustom(sku) {
    return sku.includes("custom");
}
export function isExtensionServiceVariants(serviceName) {
    var services = [
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_SERVICE_SHIPMENT,
        ProductConsts["ExtensionProductConsts"]
            .EXTENSION_PRODUCT_SERVICE_CO_PACKING,
        ProductConsts["ExtensionProductConsts"]
            .EXTENSION_PRODUCT_SERVICE_FULFILMENT,
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_SERVICE_PROOF,
        ProductConsts["ExtensionProductConsts"]
            .EXTENSION_PRODUCT_SERVICE_SUBSCRIPTION,
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_SERVICE_TAXES,
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_SERVICE_DESIGN,
    ];
    if (services.indexOf(serviceName) !== -1) {
        return serviceName;
    }
    return false;
}
export function isExtensionAssetVariants(assetName) {
    var assets = [
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_ASSET_DIELINE,
        ProductConsts["ExtensionProductConsts"]
            .EXTENSION_PRODUCT_ASSET_PRINTING_MATRIX,
        ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_DIGITAL_DESIGN,
    ];
    if (assets.indexOf(assetName) !== -1) {
        return assetName;
    }
    return false;
}
export function isExtensionOther(extensionName) {
    return (ProductConsts["ExtensionProductConsts"].EXTENSION_PRODUCT_TYPE_OTHER ===
        extensionName);
}
export function isProductExtension(sku) {
    return (isExtensionServiceVariants(sku) ||
        isExtensionAssetVariants(sku) ||
        isExtensionOther(sku));
}
export function isProductPattern(sku) {
    return sku.startsWith(ProductConsts.DesignItemProductConsts.PATTERN_SKU);
}
export function isProductDesignTemplate(sku) {
    return sku.includes(ProductConsts.DesignItemProductConsts.DESIGN_TEMPLATE_SKU);
}
export function isProductFscCertificate(sku) {
    return sku.includes(ProductConsts.DesignItemProductConsts.FSC_CERTIFICATE_SKU);
}
export function isProductDesignItem(sku) {
    return (isProductPattern(sku) ||
        isProductDesignTemplate(sku) ||
        isProductFscCertificate(sku));
}
export function getSkuData(sku) {
    var parsedSku = splitSkuParts(sku);
    return constructSkuData(parsedSku);
}
