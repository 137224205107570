import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import {
  ClipPathGenerator,
  ClipPathModes,
} from "../../../services/clip-path.generator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { isGroup } from "../../../../../../types/asset.types"
import { SpaceId } from "../../../../../../libs/products-render-config/types"

export class SpaceClippingHelper {
  public static async evokeSpaceClipping(
    vdEditor: VirtualDielineEditor,
    spaceId,
    object
  ): Promise<PackhelpObject> {
    const clipPathMode =
      object.clipMode === ClipPathModes.FillMode
        ? ClipPathModes.FillMode
        : ClipPathModes.AlphaMaskMode

    return this.setSpaceClipping(vdEditor, spaceId, object, clipPathMode)
  }

  public static async setSpaceClipping(
    vdEditor: VirtualDielineEditor,
    spaceId: SpaceId,
    object: PackhelpObject,
    clipPathMode: ClipPathModes
  ): Promise<PackhelpObject> {
    if (object.isSpaceClippingDisabled) {
      return object
    }

    const clonedSpaceObject =
      await vdEditor.dielineNavigator.cloneSpaceToClipPath(spaceId)
    const clipPathGenerator = new ClipPathGenerator(
      clonedSpaceObject,
      vdEditor.getCanvasDimensions()
    )

    return clipPathGenerator.generate(object, clipPathMode, "space-clipping")
  }

  public static removeSpaceClipping(object: PackhelpObject): PackhelpObject {
    const { clipPath } = object

    if (isGroup(object)) {
      for (const groupObject of object.getObjects()) {
        this.removeSpaceClipping(groupObject)
      }

      object.set({
        dirty: true,
      })
    }

    if (!clipPath) {
      return object
    }

    if (isGroup(clipPath)) {
      const currentItem = clipPath
        .getObjects()
        .find((obj) => obj.id === "space-clipping")

      if (currentItem) {
        clipPath.remove(currentItem)
      }
    }

    return object
  }
}
