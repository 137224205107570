import { useState } from "react"
import {
  ArrowPlacements,
  TooltipDirections
} from "../../atoms/BaseTooltip/BaseTooltip"
import { CoordinatesType } from "./Tooltip"

type GetCoordinatesFunctionParameters = {
  (
    element: HTMLElement,
    arrowPlacement: ArrowPlacements,
    direction: TooltipDirections
  ): CoordinatesType
}

const getTooltipCoordinates: GetCoordinatesFunctionParameters = (
  element,
  arrowPlacement,
  direction
) => {
  const output = {} as CoordinatesType
  const {
    width,
    height,
    left,
    right,
    top: topFromViewport
  } = element.getBoundingClientRect()

  const top = topFromViewport + window.pageYOffset
  const bottom = top + height

  const horizontalPlacement = () => {
    switch (arrowPlacement) {
      case ArrowPlacements.left:
        return left
      case ArrowPlacements.center:
        return left + width / 2
      case ArrowPlacements.right:
        return right
      default:
        return 0
    }
  }
  const verticalPlacement = () => {
    switch (arrowPlacement) {
      case ArrowPlacements.top:
        return top
      case ArrowPlacements.center:
        return top + height / 2
      case ArrowPlacements.bottom:
        return bottom
      default:
        return 0
    }
  }

  switch (direction) {
    case TooltipDirections.top:
      output.top = top
      output.left = horizontalPlacement()
      break
    case TooltipDirections.bottom:
      output.top = bottom
      output.left = horizontalPlacement()
      break
    case TooltipDirections.left:
      output.left = left
      output.top = verticalPlacement()
      break
    case TooltipDirections.right:
      output.left = right
      output.top = verticalPlacement()
      break
  }

  return {
    left: Math.round(output.left),
    top: Math.round(output.top)
  }
}

export const useTooltipParameters = (
  direction: TooltipDirections,
  arrowPlacement: ArrowPlacements
) => {
  const [open, setOpen] = useState(false)
  const [preventReopen, setPreventReopen] = useState(false)
  const [coordinates, setTooltipCoordinates] = useState<CoordinatesType | {}>(
    {}
  )

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (preventReopen) {
      setPreventReopen(false)
      return
    }
    event.stopPropagation()
    const newCoordinates = getTooltipCoordinates(
      event.currentTarget as HTMLElement,
      arrowPlacement,
      direction
    )
    setTooltipCoordinates(newCoordinates)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOutsideClick = () => {
    setPreventReopen(true)
    setOpen(false)
    setTimeout(() => setPreventReopen(false), 500)
  }

  return {
    open,
    coordinates,
    handleOpen,
    handleClose,
    handleOutsideClick
  }
}
