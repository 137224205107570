import { PackhelpObject } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"

// Events
export enum BackgroundImageEvents {
  applied = "ph.background-image.applied",
  removed = "ph.background-image.removed",
}

export type BackgroundImageEventTypes = {
  [BackgroundImageEvents.applied]: (backgroundImage: PackhelpObject) => void
  [BackgroundImageEvents.removed]: () => void
}
