import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { FscSection } from "./partials/FscSection"
import { TreeSection } from "./partials/TreeSection"
import { TabSection } from "../Tab/TabSection"

export const TabCertificates = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.assets,
    c.logo,
  ])

  if (!containerSet) {
    return null
  }

  return (
    <Tab title={t(I18N.tabs.certificates)} e2eTargetName="certificates">
      <TabSection>
        <FscSection />
        <TreeSection />
      </TabSection>
    </Tab>
  )
})
