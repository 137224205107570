import React from "react"
import cxBinder from "classnames/bind"
import styles from "./SwitcherItem.module.scss"
import { AnalyticsProps, TestsProps } from "../../types"
import { Loader, LoaderTypes } from "dsl/src/atoms/Loader/Loader"
import { SvgIcon } from "../SvgIcon/SvgIcon"
const cx = cxBinder.bind(styles)

interface SwitcherItemProps extends AnalyticsProps, TestsProps {
  label?: string
  iconUrl?: string
  onClick: () => void
  active?: boolean
  highlighted?: boolean
  loading?: boolean
  disabled?: boolean
}

interface SwitcherWithLabelItemsProps extends SwitcherItemProps {
  label: string
}

interface SwitcherWithIconItemsProps extends SwitcherItemProps {
  iconUrl: string
}

export function SwitcherItem({
  label,
  iconUrl,
  onClick,
  active = false,
  highlighted = false,
  loading = false,
  disabled = false,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: SwitcherWithLabelItemsProps | SwitcherWithIconItemsProps) {
  return (
    <button
      className={cx("item", {
        active,
        highlighted,
        ["icon-only"]: !label && !loading,
      })}
      onClick={active ? undefined : onClick}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
      disabled={active || disabled || loading}
    >
      {iconUrl && <SvgIcon url={iconUrl} size="s" />}
      {label}
      {loading && (
        <div className={styles.loader}>
          <Loader type={LoaderTypes.circular} />
        </div>
      )}
    </button>
  )
}
