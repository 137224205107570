export const actions = {
  APP_ACTIONS: "commonActions",
  BOX_EDITOR_ACTIONS: "boxEditorActions",
  ORDER_ACTIONS: "orderActions",
  MODAL_ACTIONS: "modalActions",
  FORMS_ACTIONS: "formsActions",
  USER_ACTIONS: "userActions",
  SHOP_ACTIONS: "shopActions",
  FILES_ACTIONS: "filesActions",
  BOX_SELECTION_ACTIONS: "boxSeletionActions",
  PRICING_ACTIONS: "pricingActions",
  BOX_DESIGNS_ACTIONS: "boxDesignsActions",
}
