import fabric from "../../../../../../libs/vendors/Fabric"
import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import { Size } from "../../../types/render-engine.types"
import { AvailableRotations } from "../../dieline-navigator/calculators/translation.calculator"
import { CANVAS_DIM } from "../../../../../types"

export class RotationHelper {
  public static alignObjectRotation(object: PackhelpObject, sensitivity = 5) {
    const angle = object.angle!

    for (const snapAngle of [0, 45, 90, 135, 180, 225, 270, 315, 360]) {
      if (angle > snapAngle - sensitivity && angle < snapAngle + sensitivity) {
        object.rotate(snapAngle)

        break
      }
    }
  }

  public static rotateObject(
    object: PackhelpObject,
    centerPoint: fabric.Point,
    angle: number
  ) {
    const newPosition = this.rotatePoint(
      new fabric.Point(object.left!, object.top!),
      centerPoint,
      angle
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: object.angle! + angle,
    })
  }

  public static rotateObjectAroundCanvasCenter(
    object: fabric.Object,
    angle: number
  ) {
    const newPosition = this.rotatePointAroundCanvasCenter(
      new fabric.Point(object.left!, object.top!),
      angle
    )

    object.set({
      left: newPosition.x,
      top: newPosition.y,
      angle: object.angle! + angle,
    })
  }

  public static rotatePoint(
    point: fabric.Point,
    centerPoint: fabric.Point,
    angle: number
  ): fabric.Point {
    return fabric.util.rotatePoint(
      point,
      centerPoint,
      fabric.util.degreesToRadians(angle)
    )
  }

  public static rotatePointAroundCanvasCenter(
    point: fabric.Point,
    angle: number
  ): fabric.Point {
    return fabric.util.rotatePoint(
      point,
      new fabric.Point(CANVAS_DIM / 2, CANVAS_DIM / 2),
      fabric.util.degreesToRadians(angle)
    )
  }

  public static calculateCenterPoint(
    topLeftPoint: fabric.Point,
    objectSize: Size,
    spaceRotation: AvailableRotations
  ): fabric.Point {
    if (spaceRotation === AvailableRotations.upsideDown) {
      return new fabric.Point(
        topLeftPoint.x - objectSize.width / 2,
        topLeftPoint.y - objectSize.height / 2
      )
    }

    if (spaceRotation === AvailableRotations.verticalLeft) {
      return new fabric.Point(
        topLeftPoint.x + objectSize.height / 2,
        topLeftPoint.y - objectSize.width / 2
      )
    }

    if (spaceRotation === AvailableRotations.verticalRight) {
      return new fabric.Point(
        topLeftPoint.x - objectSize.height / 2,
        topLeftPoint.y + objectSize.width / 2
      )
    }

    return new fabric.Point(
      topLeftPoint.x + objectSize.width / 2,
      topLeftPoint.y + objectSize.height / 2
    )
  }

  public static getPositionMultiplierForSpaceRotation(
    rotation: AvailableRotations
  ): { left: number; top: number; rotation: number } {
    if (Math.abs(rotation) === AvailableRotations.upsideDown) {
      return {
        top: -1,
        left: -1,
        rotation: -1,
      }
    }

    if (rotation === AvailableRotations.verticalRight) {
      return {
        top: -1,
        left: 1,
        rotation: 1,
      }
    }

    if (rotation === AvailableRotations.verticalLeft) {
      return {
        top: 1,
        left: -1,
        rotation: 1,
      }
    }

    return {
      top: 1,
      left: 1,
      rotation: 1,
    }
  }
}
