import { SvgElement } from "./svg-element"
import { Point, Segment } from "../types"

export class SvgLine extends SvgElement {
  public getSegment(): Segment {
    const { x1, y1, x2, y2 } = this.node.attributes

    return {
      start: {
        x: parseFloat(x1),
        y: parseFloat(y1),
      },
      end: {
        x: parseFloat(x2),
        y: parseFloat(y2),
      },
    }
  }

  public reverse() {
    const { x1, y1, x2, y2 } = this.node.attributes

    this.node.attributes = {
      ...this.node.attributes,
      x1: x2,
      x2: x1,
      y1: y2,
      y2: y1,
    }
  }

  public getExtremePoints(): Point[] {
    return Object.values(this.getSegment())
  }
}
