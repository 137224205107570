import { AbstractLayoutProcessor } from "./abstract-layout.processor"
import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../../libs/vendors/Fabric"

/**
 * Alternating layout
 *
 * [@]  [@]  [@]  [@]
 *    [@]  [@]  [@]
 * [@]  [@]  [@]  [@]
 *    [@]  [@]  [@]
 * [@]  [@]  [@]  [@]
 */
export class AlternatingLayoutProcessor extends AbstractLayoutProcessor {
  /*
   * In this layout the replicable canvas contains 2 objects, so:
   *
   * COLUMNS
   *
   * For X columns we need to replicate pattern horizontally X / 2 times
   *
   * E.g. for 7 columns -> 7 / 2 = 3.5
   *
   * 1.   2.   3.   3.5.
   * [@]  [@]  [@]  [@]
   *    [@]  [@]  [@]
   *
   */
  private readonly colsDivider = 2

  /*
   * ROWS
   *
   * For X rows we need to replicate pattern vertically X / 2 times
   *
   * E.g. for 5 rows -> 5 / 2 = 2.5
   *
   * 1.   [@]  [@]  [@]  [@]
   *         [@]  [@]  [@]
   * 2.   [@]  [@]  [@]  [@]
   *         [@]  [@]  [@]
   * 2.5. [@]  [@]  [@]  [@]
   *
   */
  private readonly rowsDivider = 2

  /*
   * We always want to have N + 1 columns, because we need minimum 2 columns and 2 rows
   * to get this effect:
   *
   * [@]
   *   [@]
   */
  private readonly rowsAndColsOffset = 1

  private topObjectToProcess?: PackhelpObject
  private bottomObjectToProcess?: PackhelpObject

  protected async prepareReplicableObjectToProcess() {
    this.topObjectToProcess = await this.cloneObject(this.replicableObject)
    this.bottomObjectToProcess = await this.cloneObject(this.replicableObject)
    this.shouldPrepareToProcess = false
  }

  public async process() {
    if (this.shouldPrepareToProcess) {
      await this.prepareReplicableObjectToProcess()
    }

    const cols = this.config.cols + this.rowsAndColsOffset
    const rows = this.config.rows + this.rowsAndColsOffset

    const width =
      (this.space.width - this.safeZoneSizeSum) / (cols / this.colsDivider)
    const height =
      (this.space.height - this.safeZoneSizeSum) / (rows / this.rowsDivider)

    this.patternSourceCanvas.setDimensions({
      width,
      height,
    })

    this.patternSourceCanvas.clear()

    this.processTopObject()
    this.processBottomObject()

    this.patternSourceCanvas.renderAll()
  }

  private processTopObject() {
    if (!this.topObjectToProcess) {
      return
    }

    const width = this.patternSourceCanvas.getWidth()
    const height = this.patternSourceCanvas.getHeight()
    const centerPoint = new fabric.Point(width / 4, height / 4)

    this.scaleObject(this.topObjectToProcess, 0.5)
    this.moveObject(this.topObjectToProcess, centerPoint)
    this.rotateObject(this.topObjectToProcess, centerPoint)

    this.patternSourceCanvas.add(this.topObjectToProcess)
  }

  private processBottomObject() {
    if (!this.bottomObjectToProcess) {
      return
    }

    const width = this.patternSourceCanvas.getWidth()
    const height = this.patternSourceCanvas.getHeight()
    const centerPoint = new fabric.Point((width / 4) * 3, (height / 4) * 3)

    this.scaleObject(this.bottomObjectToProcess, 0.5)
    this.moveObject(this.bottomObjectToProcess, centerPoint)
    this.rotateObject(this.bottomObjectToProcess, centerPoint)

    this.patternSourceCanvas.add(this.bottomObjectToProcess)
  }
}
