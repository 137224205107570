import React from "react"
import { ProsceniumLoader } from "./Proscenium/ProsceniumLoader"
import { FullScreenLoader } from "../dsl/molecules/loaders/FullScreenLoader/FullScreenLoader"
import { useIsDeviceTooSmallForEditor } from "product-configurator-light/src/libs/hooks/use-is-device-too-small-for-editor"
import { useTranslate } from "../hooks/useTranslate"
import { I18N } from "../i18n"

export function TheaterLoader() {
  const t = useTranslate()
  const isDeviceTooSmallForEditor = useIsDeviceTooSmallForEditor()

  const url = new URL(window.location.href)
  const isDesignLater = !!url.searchParams.get("design-later")

  if (isDeviceTooSmallForEditor || isDesignLater) {
    return <FullScreenLoader label={t(I18N.component.loader.generic)} />
  }

  return <ProsceniumLoader />
}
