import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { Dropzone } from "../../../atoms/Dropzone/Dropzone"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../Tab/TabSection"
import { UploadError } from "../../../molecules/UploadError/UploadError"
import { ElementsSection } from "../partials/ElementsSection"

const i18nUploader = I18N.component.assetsUploader

export const TabUpload = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { uploadTabController } = containerSet.ui
  const { uploadedImagesSection, failedImages } = uploadTabController

  const onDrop = (files: File[]) => {
    uploadTabController.uploadFiles(files)
  }

  return (
    <Tab title={t(I18N.tabs.upload)} e2eTargetName="upload">
      <TabSection>
        <Dropzone
          onDrop={onDrop}
          label={t(i18nUploader.uploadImage)}
          multiple={true}
          analytics-section="left-tab-upload"
          analytics-name="upload-image"
        />

        {failedImages.map((image) => {
          return <UploadError filename={image.name} error={image.error!} />
        })}
      </TabSection>

      {uploadedImagesSection.elements.length > 0 && (
        <ElementsSection
          id={uploadedImagesSection.id}
          title={t(uploadedImagesSection.titleIntl)}
          elements={uploadedImagesSection.elements}
          onElementClick={uploadedImagesSection.onElementClick}
          initialElementsCount={uploadedImagesSection.elements.length}
          analyticsSection="left-tab-upload"
        />
      )}
    </Tab>
  )
})
