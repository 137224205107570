import {
  PackhelpInteractiveCanvas,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import { Axis, EdgeType, LinePoints, ObjectEdge } from "../types"
import { calculateEdges } from "../helpers/calculate-edges"
import { inRange } from "../helpers/in-range"
import { setPositionOnAxis } from "../helpers/set-position-on-axis"
import { createLine } from "../helpers/create-line"
import _pickBy from "lodash/pickBy"
import { includesAllAxes } from "../helpers/includes-all-axes"
import { SnapDaddy } from "../snap-daddy"
import { isScaling } from "../helpers/is-scaling"

interface Config {
  excludedAxes: Axis[]
}

const defaultConfig: Config = {
  excludedAxes: [],
}

export abstract class GuidelinesController {
  protected readonly guidelines: Record<string, PackhelpObject> = {}
  protected readonly edgeTypesToCompare: EdgeType[] = [
    "top",
    "hcenter",
    "bottom",
    "left",
    "vcenter",
    "right",
  ]
  protected readonly showAllGuidelinesOnMatch: boolean = false

  constructor(
    protected readonly canvas: PackhelpInteractiveCanvas,
    private readonly snapDaddy: SnapDaddy
  ) {}

  protected abstract getObjectsToCompare(
    currentObject: PackhelpObject
  ): PackhelpObject[]

  protected abstract calculateLinePoints(
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): LinePoints

  public process(
    currentObject: PackhelpObject,
    customConfig: Partial<Config> = {}
  ): Axis[] {
    this.clear()

    const config = {
      ...defaultConfig,
      ..._pickBy(customConfig),
    }

    if (includesAllAxes(config.excludedAxes)) {
      return []
    }

    const currentObjectEdges = calculateEdges(
      currentObject,
      this.getObjectEdgeTypes(currentObject)
    )
    const matchedAxes: Axis[] = []

    for (const nextObject of this.getObjectsToCompare(currentObject)) {
      const nextObjectEdges = calculateEdges(
        nextObject,
        this.edgeTypesToCompare
      )

      const currentMatchedAxes: Axis[] = []

      for (const currentEdge of currentObjectEdges) {
        const matchedEdge = nextObjectEdges.find((nextSide) => {
          return (
            !config.excludedAxes.includes(nextSide.axis) &&
            inRange(
              currentEdge,
              nextSide,
              this.getSnappingRadius(currentObject)
            )
          )
        })

        if (!matchedEdge) {
          continue
        }

        const { axis } = matchedEdge

        if (matchedAxes.includes(axis)) {
          continue
        }

        if (this.showAllGuidelinesOnMatch) {
          nextObjectEdges.forEach((nextEdge) =>
            this.drawGuideline(currentEdge, nextEdge)
          )
        } else {
          this.drawGuideline(currentEdge, matchedEdge)
        }

        this.snapDaddy.snap(currentObject, currentEdge, matchedEdge)

        currentMatchedAxes.push(axis)
      }

      matchedAxes.push(...currentMatchedAxes)

      if (includesAllAxes(matchedAxes)) {
        break
      }
    }

    return matchedAxes
  }

  public clear(): void {
    for (const guideline of Object.values(this.guidelines)) {
      guideline.set({ opacity: 0 })
    }
  }

  public dispose(): void {
    for (const [id, guideline] of Object.entries(this.guidelines)) {
      this.canvas.remove(guideline)
      delete this.guidelines[id]
    }
  }

  protected drawGuideline(currentEdge: ObjectEdge, nextEdge: ObjectEdge): void {
    const line = this.createLine(currentEdge, nextEdge)
    const { axis, points } = nextEdge

    setPositionOnAxis(line, axis, points.start[axis])

    line.set({
      opacity: 1,
    })

    line.setCoords()
  }

  protected createLine(
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): PackhelpObject {
    const { type } = nextEdge

    const currentLine = this.guidelines[type]

    if (currentLine) {
      this.canvas.remove(currentLine)
    }

    const points = this.calculateLinePoints(currentEdge, nextEdge)
    const line = createLine(points)

    this.guidelines[type] = line
    this.canvas.add(line)

    return line
  }

  private getObjectEdgeTypes(object: PackhelpObject): EdgeType[] {
    if (isScaling(object)) {
      return ["top", "bottom", "left", "right"]
    }

    return ["top", "hcenter", "bottom", "left", "vcenter", "right"]
  }

  private getSnappingRadius(object): number {
    if (isScaling(object)) {
      return 2
    }

    return 5
  }
}
