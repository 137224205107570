import Basil from "shared-libs/src/js/libs/storage/basil.js"

export const getUserCountry = (): string | undefined => {
  const country = Basil.cookie.get("cf-user-country")

  if (!country) {
    return
  }

  return country
}
