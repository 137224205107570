import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { TileButton } from "../../../../atoms/TileButton/TileButton"
import { I18N } from "../../../../../i18n"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Tile } from "../../../../atoms/Tile/Tile"
import { Template } from "../../../../../../models/template"
import disabledIconUrl from "../../../../../assets/_icons/disabled.svg"
import Badge from "dsl/src/atoms/Badge/Badge"
import { useCurrency } from "../../../../../hooks/useCurrency"
import { formatPriceWithCurrency } from "shared-libs/src/js/shared-components/formatted-currency/format-price-with-currency"
import { TabSection } from "../../Tab/TabSection"
import { SkeletonTileList } from "../../../../atoms/Skeleton/SkeletonTileList"

export const TemplatesSubTab = observer(function () {
  const t = useTranslate()
  const currency = useCurrency()

  const [containerSet] = useContainerSet((c) => [c.templates, c.ecommerce])

  if (!containerSet) {
    return null
  }

  const { templates: templatesContainer, ecommerce: ecommerceContainer } =
    containerSet

  if (!templatesContainer.available || !ecommerceContainer.available) {
    return null
  }

  const { templatesStore, templatesController } = templatesContainer
  const { taxStore } = ecommerceContainer
  const { isLoadingTemplates, templates, selectedTemplateId } = templatesStore
  const { isSelectingDisabled } = templatesController

  const onRemoveTemplateClick = () => {
    templatesController.confirmActionIfNeeded("removeTemplate", () =>
      templatesController.removeTemplate()
    )
  }

  const onTemplateClick = (template: Template) => {
    if (template.id === selectedTemplateId) {
      return
    }

    templatesController.confirmActionIfNeeded(
      templatesStore.selectedTemplateId ? "changeTemplate" : "applyTemplate",
      () => templatesController.selectTemplate(template.id)
    )
  }

  return (
    <TabSection>
      <TileButton
        iconUrl={disabledIconUrl}
        label={t(I18N.editorTools.templates.noTemplates)}
        onClick={onRemoveTemplateClick}
        active={!selectedTemplateId}
        disabled={isSelectingDisabled || !selectedTemplateId}
        type="light"
        analyticsSection="left-tab-templates"
        analyticsType="button"
        analyticsName="no-template"
        e2eTarget="button"
        e2eTargetName="no-template"
      />

      {isLoadingTemplates ? (
        <SkeletonTileList repeats={12} cols={2} />
      ) : (
        <TileList>
          {templates.map((template) => {
            const imageUrl = template.getAllImageUrls()[0]
            const price = taxStore.getPrice(template).displayPrice
            const isBadgeVisible = !!price && !!currency
            const isActive = template.id === selectedTemplateId
            const isDisabled = isSelectingDisabled || isActive

            return (
              <Tile
                key={template.id}
                active={isActive}
                disabled={isDisabled}
                onClick={() => onTemplateClick(template)}
                badge={
                  isBadgeVisible && (
                    <Badge>
                      {formatPriceWithCurrency(price.toString(), currency)}
                    </Badge>
                  )
                }
                analyticsSection="left-tab-templates"
                analyticsType="thumbnail"
                analyticsName="template"
                analyticsId={template.name}
                e2eTarget="button"
                e2eTargetName="template"
              >
                <figure>
                  <img src={imageUrl} alt={template.name} />
                </figure>
              </Tile>
            )
          })}
        </TileList>
      )}
    </TabSection>
  )
})
