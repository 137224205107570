import { ProductManager, Variant, VariantEditorAssets } from "@ph/product-api"
import {
  AvailableColourModes,
  EditorMode,
  EditZoneControlsColorTheme,
  EditZoneRulersConfig,
  FontSizeConfig,
  FscConfig,
  GlobalDropzoneConfig,
  ReplicablePatternConfig,
  ThumbnailConfig,
  UIConfig,
  ZoomConfig,
} from "../types"
import _isNil from "lodash/isNil"

export class PimUiConfigBuilder {
  constructor(
    private readonly product: ProductManager,
    private readonly editorMode: EditorMode,
    private readonly isAfterPurchaseEdit: boolean
  ) {}

  public build(): UIConfig {
    return {
      features: {
        mask: this.isEditorFeatureEnabled("mask", {
          afterPurchase: true,
          designerMode: true,
        }),
        textEffects: this.isEditorFeatureEnabled("textEffects", {
          afterPurchase: true,
          designerMode: true,
        }),
        patterns: this.isEditorFeatureEnabled("patterns", {
          afterPurchase: false,
          designerMode: false,
        }),
        backgroundColor: this.isBackgroundColorEnabled,
        fsc: this.isFscEnabled,
        backgroundImage: this.isDesignerMode,
        plantingTrees: !this.isAfterPurchaseEdit,
        replicablePatterns: this.isEditorFeatureEnabled("replicablePatterns", {
          afterPurchase: true,
          designerMode: false,
        }),
        templates: this.isEditorFeatureEnabled("templates", {
          afterPurchase: false,
          designerMode: false,
        }),
      },
      tabs: {
        product: this.isProductTabEnabled,
        configuration: this.isConfigurationTabEnabled,
        size: this.isConfigurationTabEnabled,
        logo: this.isEditorFeatureEnabled("logo", {
          afterPurchase: true,
          designerMode: false,
        }),
        logoPlaceholder: this.isDesignerMode,
        templatesAndPatterns: this.isTemplatesAndPatternsTabEnabled,
        replicablePatterns: this.isReplicablePatternsEnabled,
        text: this.isEditorFeatureEnabled("text", {
          afterPurchase: true,
          designerMode: true,
        }),
        textOptions: true,
        fonts: true,
        background: this.isBackgroundTabEnabled,
        elements: this.isElementsTabEnabled,
        certificates: !this.isAfterPurchaseEdit,
        devTools: true,
        dby: this.isDbyMode,
        upload: this.isUploadTabEnabled,
      },
      fontSize: this.fontSizeConfig,
      editZone: {
        rulers: this.rulersConfig,
        controlsTheme: this.controlsTheme,
        shadow: {
          color: "rgba(47, 47, 47, 0.4)",
          blur: 1,
          offsetX: 0,
          offsetY: 0,
        },
        safeZoneSizeMm: this.safeZoneSize,
        paddingZoneSizeMm: this.paddingZoneSize,
        zoom: this.zoomConfig,
      },
      thumbnail: this.thumbnailConfig,
      globalDropzone: {
        mode: this.dropZoneMode,
      },
      bleedSizeMm: this.bleedSize,
      fsc: this.fscConfig,
      replicablePattern: this.replicablePatternsConfig,
    }
  }

  private get fscConfig(): FscConfig | undefined {
    const config = this.editorConfig.renderConfig?.featureConfigs?.fsc

    if (!config) {
      return
    }

    return {
      availableSpaces: Object.fromEntries(
        config.availableSpaces.map((spaceConfig) => [
          spaceConfig.editContext,
          spaceConfig.spaces,
        ])
      ),
    }
  }

  private get replicablePatternsConfig(): ReplicablePatternConfig | undefined {
    const config =
      this.editorConfig.renderConfig?.featureConfigs?.replicablePatterns

    if (!config) {
      return
    }

    return {
      ...config,
      safeZoneSizeMm: config.safeZoneSize,
      availableLayouts: {
        alternating: config.availableLayouts.includes("alternating"),
        grid: config.availableLayouts.includes("grid"),
      },
    }
  }

  private get rulersConfig(): EditZoneRulersConfig {
    const config = this.editorConfig.renderConfig?.rulers

    if (!config) {
      return {
        lines: "dashed",
        dimensions: true,
      }
    }

    return config as EditZoneRulersConfig
  }

  private get thumbnailConfig(): ThumbnailConfig {
    const config = this.editorConfig.renderConfig?.thumbnail

    if (!config) {
      return {
        backgroundTextureEnabled: false,
        editZoneBackgroundTextureEnabled: false,
      }
    }

    return {
      backgroundTextureEnabled: config.backgroundTexture,
      editZoneBackgroundTextureEnabled: config.editZoneTexture,
    }
  }

  private get dropZoneMode(): GlobalDropzoneConfig["mode"] {
    if (this.isReplicablePatternsEnabled) {
      return "replicablePattern"
    }

    return "images"
  }

  private get bleedSize(): number {
    const dtp = this.editorConfig.renderConfig?.dtp

    if (!dtp || _isNil(dtp.bleedSize)) {
      return 5
    }

    return dtp.bleedSize
  }

  private get safeZoneSize(): number {
    const dtp = this.editorConfig.renderConfig?.dtp

    if (!dtp || _isNil(dtp.safeZoneSize)) {
      return 5
    }

    return dtp.safeZoneSize
  }

  private get paddingZoneSize(): number {
    const dtp = this.editorConfig.renderConfig?.dtp

    if (!dtp || _isNil(dtp.paddingZoneSize)) {
      return 0
    }

    return dtp.paddingZoneSize
  }

  private get controlsTheme(): EditZoneControlsColorTheme {
    const controls = this.editorConfig.renderConfig?.controls

    if (!controls || !controls.theme) {
      return "dark"
    }

    return controls.theme as EditZoneControlsColorTheme
  }

  private get zoomConfig(): ZoomConfig {
    const { zoom } = this.editorConfig.features

    return {
      available: zoom.enabled,
      activeByDefault: zoom.activeByDefault,
    }
  }

  private get fontSizeConfig(): FontSizeConfig {
    const fontSize = this.editorConfig.renderConfig?.fontSize

    if (!fontSize) {
      return {
        dtp: {
          min: 14,
        },
        editor: {
          min: 14,
          max: 200,
        },
      }
    }

    return fontSize
  }

  private isEditorFeatureEnabled(
    name: string,
    availability: {
      afterPurchase: boolean
      designerMode: boolean
    }
  ): boolean {
    if (!availability.afterPurchase && this.isAfterPurchaseEdit) {
      return false
    }

    const isModeAllowed =
      this.isEditorMode || (availability.designerMode && this.isDesignerMode)

    return isModeAllowed && !!this.editorConfig.features[name]
  }

  private get isBackgroundColorEnabled(): boolean {
    const colorMode = this.editorConfig.renderConfig?.colorPreset?.mode

    if (!colorMode) {
      return false
    }

    const isColorful = [
      AvailableColourModes.FULL_COLOR,
      AvailableColourModes.ECO_COLOR,
    ].includes(colorMode as AvailableColourModes)

    const backgroundPalette =
      this.editorConfig.renderConfig?.colorPreset?.pantonePalette?.background
    const isPantoneWithBackground =
      colorMode === AvailableColourModes.MONO_PANTONE && !!backgroundPalette

    return (
      this.isEditorFeatureEnabled("backgroundColor", {
        afterPurchase: true,
        designerMode: true,
      }) &&
      (isColorful || isPantoneWithBackground)
    )
  }

  private get isFscEnabled(): boolean {
    if (this.isAfterPurchaseEdit) {
      return false
    }

    return this.product.variantManager.isFscCertificateAvailable()
  }

  private get isReplicablePatternsEnabled(): boolean {
    return this.isEditorFeatureEnabled("replicablePatterns", {
      afterPurchase: true,
      designerMode: false,
    })
  }

  private get isBackgroundTabEnabled(): boolean {
    return this.isBackgroundColorEnabled
  }

  private get isProductTabEnabled(): boolean {
    return !this.isAfterPurchaseEdit && !this.isDesignerMode
  }

  private get isConfigurationTabEnabled(): boolean {
    return !this.isAfterPurchaseEdit && !this.isDesignerMode
  }

  private get isTemplatesAndPatternsTabEnabled(): boolean {
    const hasPatterns = this.isEditorFeatureEnabled("patterns", {
      afterPurchase: false,
      designerMode: false,
    })
    const hasTemplates = this.isEditorFeatureEnabled("templates", {
      afterPurchase: false,
      designerMode: false,
    })

    return hasPatterns || hasTemplates
  }

  private get isElementsTabEnabled(): boolean {
    return this.isEditorFeatureEnabled("shapes", {
      afterPurchase: true,
      designerMode: true,
    })
  }

  private get isUploadTabEnabled(): boolean {
    return this.isEditorFeatureEnabled("images", {
      afterPurchase: true,
      designerMode: true,
    })
  }

  private get isEditorMode(): boolean {
    return this.editorMode === "editor"
  }

  private get isDbyMode(): boolean {
    return this.editorMode === "dby"
  }

  private get isDesignerMode(): boolean {
    return this.editorMode === "designer"
  }

  private get variant(): Variant {
    return this.product.getDefaultVariant()
  }

  private get editorConfig(): VariantEditorAssets {
    return this.variant.editorAssets
  }
}
