export function prepareImgixUrl(
  url: string,
  options: Record<string, any> = {}
): string {
  const preparedUrl = new URL(url)

  for (const [key, value] of Object.entries(options)) {
    preparedUrl.searchParams.append(key, value)
  }

  return preparedUrl.toString()
}
