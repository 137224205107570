import React from "react"
import styles from "./OptionPicker.module.scss"
import cxBinder from "classnames/bind"
import { AnalyticsProps, TestsProps } from "../../types"

const cx = cxBinder.bind(styles)

export interface OptionPickerProps extends AnalyticsProps, TestsProps {
  label: string
  onClick: () => void
  active?: boolean
  disabled?: boolean
}

export function OptionPicker({
  label,
  onClick,
  active = false,
  disabled = false,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: OptionPickerProps) {
  return (
    <button
      className={cx("picker", { active })}
      onClick={onClick}
      disabled={disabled}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {label}
    </button>
  )
}
