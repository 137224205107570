import React from "react"
import { QuantityItem } from "../../types"
import { PickerOptionLabel } from "../picker-option/PickerOptionLabel"
import { PickerOptionValue } from "../picker-option/PickerOptionValue"
import styles from "./PickerValue.module.scss"

interface PickerValueProps {
  consise?: boolean
  value: QuantityItem
  fixedPromo?: number
}

export const PickerValue = (props: PickerValueProps) => {
  const { consise, value, fixedPromo } = props

  return (
    <div className={styles["wrapper"]}>
      <PickerOptionValue value={value.quantity} />
      <PickerOptionLabel
        item={value}
        shouldHideLabel={consise}
        fixedPromo={fixedPromo}
      />
    </div>
  )
}
