import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { useTranslate } from "../../../../hooks/useTranslate"
import { Nav } from "../../../atoms/Nav/Nav"
import accountIconUrl from "../../../../assets/_icons/account.svg"
import { I18N } from "../../../../i18n"
import { Label } from "../../../atoms/Label/Label"
import { NavButton } from "../../../atoms/Nav/NavButton"

export const MenuUserInfo = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.apiSession, c.auth])

  if (!containerSet) {
    return null
  }

  const { apiSession, auth } = containerSet

  if (!auth.available) {
    return null
  }

  const { sessionStore } = apiSession
  const { user } = sessionStore

  if (user.email) {
    return (
      <>
        <Label>{t(I18N.generic.loggedAs)}</Label>
        {user.email}
      </>
    )
  }

  const onLoginClick = () => {
    auth.authController.openLoginModal()
  }

  return (
    <Nav>
      <NavButton
        iconUrl={accountIconUrl}
        label={t(I18N.menu.signIn)}
        onClick={onLoginClick}
        analyticsSection="menu"
        analyticsType="icon"
        analyticsName="sign-in"
      />
    </Nav>
  )
})
