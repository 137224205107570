import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpObject,
} from "../../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { TempLayers } from "../../../../types/render-engine.types"
import { LinePoints } from "../types"

const lineColor = "#8a140c"

export function createLine(points: LinePoints): PackhelpObject {
  const { start, end } = points

  const line = new fabric.Line([
    start.x,
    start.y,
    end.x,
    end.y,
  ]) as unknown as PackhelpObject

  line.set({
    stroke: lineColor,
    strokeWidth: 0.5,
    opacity: 0,
    evented: false,
    selectable: false,
    indexConfig: {
      fragment: IndexConfigFragments.BOTTOM,
      index: IndexConfigFragmentBottom.BACKGROUND_IMAGE,
    },
    id: TempLayers.TEMP_SMART_GUIDELINE,
  })

  return line
}
