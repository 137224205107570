export const colorsListDemo = [
  {
    uid: "color-#8AC7A5",
    color: "#8AC7A5",
  },
  {
    uid: "color-#9DA350",
    color: "#9DA350",
  },
  {
    uid: "color-#FFDC75",
    color: "#FFDC75",
  },
  {
    uid: "color-#F0B461",
    color: "#F0B461",
  },
  {
    uid: "color-#EEC3D7",
    color: "#EEC3D7",
  },
  {
    uid: "color-#DCCDAF",
    color: "#DCCDAF",
  },
  {
    uid: "color-#F28899",
    color: "#F28899",
  },
  {
    uid: "color-#FF8775",
    color: "#FF8775",
  },
  {
    uid: "color-#A3D0EF",
    color: "#A3D0EF",
  },
  {
    uid: "color-#A6ABB5",
    color: "#A6ABB5",
  },
  {
    uid: "color-#28A660",
    color: "#28A660",
  },
  {
    uid: "color-#77755D",
    color: "#77755D",
  },
  {
    uid: "color-#E3C670",
    color: "#E3C670",
  },
  {
    uid: "color-#C37509",
    color: "#C37509",
  },
  {
    uid: "color-#B97E99",
    color: "#B97E99",
  },
  {
    uid: "color-#A6997F",
    color: "#A6997F",
  },
  {
    uid: "color-#EA3755",
    color: "#EA3755",
  },
  {
    uid: "color-#F46B55",
    color: "#F46B55",
  },
  {
    uid: "color-#0068AD",
    color: "#0068AD",
  },
  {
    uid: "color-#4F535A",
    color: "#4F535A",
  },
  {
    uid: "color-#006D31",
    color: "#006D31",
  },
  {
    uid: "color-#454433",
    color: "#454433",
  },
  {
    uid: "color-#BE9133",
    color: "#BE9133",
  },
  {
    uid: "color-#673E06",
    color: "#673E06",
  },
  {
    uid: "color-#854FA6",
    color: "#854FA6",
  },
  {
    uid: "color-#89644C",
    color: "#89644C",
  },
  {
    uid: "color-#AF2241",
    color: "#AF2241",
  },
  {
    uid: "color-#AE3C2A",
    color: "#AE3C2A",
  },
  {
    uid: "color-#45415A",
    color: "#45415A",
  },
  {
    uid: "color-#000000",
    color: "#000000",
  },
]

export const blankColor = {
  uid: "color-blank",
  color: undefined,
}

export const colorsListDemoWithBlankColor = [
  blankColor,
  ...colorsListDemo.slice(1, colorsListDemo.length),
]

Object.freeze(blankColor)
Object.freeze(colorsListDemo)
Object.freeze(colorsListDemoWithBlankColor)
