import React from "react"
// @ts-ignore
import ReactDOM from "react-dom"

interface TooltipPortalProps extends React.HTMLProps<HTMLDivElement> {
  coordinates?: { top: number; left: number } | {}
  zIndex?: number
}

const TooltipPortal = (props: TooltipPortalProps) => {
  const { children, coordinates, zIndex } = props
  return ReactDOM.createPortal(
    // @ts-ignore
    React.cloneElement(React.Children.only(children), {
      style: { ...coordinates, zIndex }
    }),
    document.body
  )
}

export { TooltipPortal, TooltipPortal as default }
