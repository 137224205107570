import axios from "axios"
import {
  PredefinedTextAssetDto,
  PredefinedTextDto,
  StrapiImageResponseDto,
  Tag,
} from "../types/predefined-asset.types"

export class PredefinedText {
  protected _assetData?: PredefinedTextAssetDto

  constructor(protected text: PredefinedTextDto) {}

  get id(): string {
    return this.text.id
  }

  get name(): string {
    return this.text.name
  }

  get slug(): string {
    return this.text.slug
  }

  get tags(): Tag[] {
    return this.text.tags
  }

  get previews(): StrapiImageResponseDto[] {
    return this.text.previews
  }

  get order(): number {
    return this.text.order
  }

  async getAssetData(): Promise<PredefinedTextAssetDto | null> {
    if (!this.text.assetFile) {
      console.warn("Asset URL is not provided!")
      return null
    }

    return axios
      .get<PredefinedTextAssetDto>(this.text.assetFile.url)
      .then((response) => {
        return response.data
      })
  }
}
