import React from "react"
import { observer } from "mobx-react-lite"
import { TabSection } from "../../Tab/TabSection"
import { TileList } from "../../../../atoms/Tile/TileList"
import { Button } from "dsl/src/atoms/Button/Button"
import { ButtonSizes } from "dsl/src/atoms/Button"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { ListingTile } from "./ListingTile"
import { I18N } from "../../../../../i18n"
import { ListingItem } from "shared-libs/src/js/libs/services/listing-services/utils/listing-services.types"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { SkeletonTileList } from "../../../../atoms/Skeleton/SkeletonTileList"

const i18n = I18N.editorTools.productSwitcher

export const ListingSection = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.productSwitcher])

  if (!containerSet) {
    return null
  }

  const { productSwitcher } = containerSet

  if (!productSwitcher.available) {
    return null
  }

  const { listingStore, productSwitcherUiController } = productSwitcher
  const { isLoading, items, selectedItem } = listingStore
  const { loadingItem } = productSwitcherUiController

  const onClearFiltersClick = () => {
    listingStore.clearFilters()
  }

  const onListingTileClick = (item: ListingItem) => {
    productSwitcherUiController.switchProduct(item)
  }

  if (isLoading) {
    return (
      <TabSection>
        <SkeletonTileList repeats={12} cols={3} />
      </TabSection>
    )
  }

  if (items.length === 0) {
    return (
      <TabSection>
        <p>{t(i18n.noResults)}</p>

        <Button size={ButtonSizes.small} onClick={onClearFiltersClick}>
          {t(i18n.clearFilters)}
        </Button>
      </TabSection>
    )
  }

  return (
    <TabSection>
      <TileList cols={3}>
        {items.map((item) => {
          return (
            <ListingTile
              key={item.id}
              item={item}
              onClick={onListingTileClick}
              buildProductPageUrl={(path: string) =>
                productSwitcherUiController.getProductPageUrl(path)
              }
              loading={item.id === loadingItem?.id}
              active={item.id === selectedItem?.id}
            />
          )
        })}
      </TileList>
    </TabSection>
  )
})
