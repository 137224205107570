import * as sbjs from "sourcebuster"
import _forOwn from "lodash/forOwn"
import Basil from "shared-libs/src/js/libs/storage/basil.js"

import { UrlManipulator } from "../../urls"
import RegionConfig from "../../../../configs/region-config/interfaces/js/region-config"

/**
 * !!! IMPORTANT !!!
 *
 * THIS IS LEGACY SERVICE USING UNIVERSAL ANALYTICS AND WILL BE REMOVED SOON.
 *
 * INSTEAD OF IT USE GA4SERVICE USING GA4.
 */

function initSourcebusterCookies() {
  const urlManipulator = UrlManipulator.init()
  let sbjsData = urlManipulator.getQueryStringValue("sbjs_data")

  if (!sbjsData) {
    return
  }

  const domain = RegionConfig.getMainDomainForCurrentRegion().replace(
    /https?:\/\//,
    "."
  )
  sbjsData = JSON.parse(sbjsData)

  _forOwn(sbjsData, function (value, key) {
    Basil.cookie.set(key, value, { expireDays: 365, domain: domain, path: "/" })
  })

  urlManipulator.removeQueryStringParameter("sbjs_data")
  urlManipulator.replaceState()
}

function initSourcebuster() {
  initSourcebusterCookies()

  sbjs.init({
    session_length: 30,
    lifetime: 24,
  })
}

// export class GoogleAnalyticsService {
//   /**
//    * ga and default tracker must be present
//    */
//   constructor(ga, defaultTracker) {
//     if (ga == null || defaultTracker == null) {
//       throw new Error("No google analytics instance")
//     }
//     this.ga = ga
//     this.defaultTracker = defaultTracker

//     // Assume existing user, check for cookie, and init sbjs
//     // sbjs fill set and lock cookies
//     // this.probablyAFirstTimeVisitor = false
//     // if (Cookie.get('sbjs_first') == null) {
//     //   this.probablyAFirstTimeVisitor = true
//     // }

//     return this
//   }

//   getGaClientId() {
//     return this.defaultTracker.get("clientId")
//   }

//   async getClientIp() {
//     const apiUrl = RegionConfig.getEditorExternalUrl("api/" + urls.API_IP, "eu")
//     const client = apiClientSetup(apiUrl)
//     const ip_data = await client.get(apiUrl)

//     return ip_data.data.ip
//   }

//   getFirstTouchUtmData() {
//     const firstTouchUtm = getFirstTouchUtmDataOnly()
//     firstTouchUtm.clientId = this.getGaClientId()

//     return firstTouchUtm
//   }
// }

function getFirstTouchUtmDataOnly() {
  initSourcebuster()
  const first = sbjs.get.first

  // strip silly "(none)" string
  const _normalizeSbjs = function (s) {
    if (s === "(none)") {
      return undefined
    } else {
      return s
    }
  }

  const firstTouchUtm = {
    type: _normalizeSbjs(first.typ), // utm, organic, referral, typein.
    source: _normalizeSbjs(first.src), // google
    medium: _normalizeSbjs(first.mdm),
    campaign: _normalizeSbjs(first.cmp),
    content: _normalizeSbjs(first.cnt),
    term: _normalizeSbjs(first.trm),
  }

  return firstTouchUtm
}

export function getFirstTouchUtmData(clientId) {
  const firstTouchUtm = getFirstTouchUtmDataOnly()
  if (clientId != null) {
    firstTouchUtm.clientId = clientId
  }
  return firstTouchUtm
}
