import { INode } from "svgson"
import { SvgElement } from "./svg-element"
import { ElementType } from "../types"
import { SvgLine } from "./svg-line"
import { SvgPath } from "./svg-path"

export function SvgElementFactory(node: INode): SvgElement {
  const { name } = node

  if (name === ElementType.line) {
    return new SvgLine(node)
  }

  if (name === ElementType.path) {
    return new SvgPath(node)
  }

  throw new Error(`Element ${name} is not supported`)
}
