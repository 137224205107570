import { computed } from "mobx"
import { ProductStore } from "../../stores/product.store"
import { DielineStore } from "../../stores/dieline.store"
import { DbyDownloadItem } from "../../types/ui.types"
import pdfIconUrl from "../../ui/assets/_icons/pdf.svg"
import aiIconUrl from "../../ui/assets/_icons/ai.svg"
import { I18N } from "../../ui/i18n"
import { DbyModeController } from "../dby-mode.controller"
import { DbyModeStore } from "../../stores/dby-mode.store"
import { AssetError } from "../../services/asset-service/asset.errors"

const i18n = I18N.component.dbyUploader

const fileTypes = ["pdf", "ai"]
const fileDefinitions = {
  pdf: {
    iconUrl: pdfIconUrl,
    titleIntl: i18n.link.pdf,
  },
  ai: {
    iconUrl: aiIconUrl,
    titleIntl: i18n.link.ai,
  },
}

export class DbyTabController {
  private readonly dbyModeController: DbyModeController
  private readonly dbyModeStore: DbyModeStore
  private readonly dielineStore: DielineStore
  private readonly productStore: ProductStore

  constructor(
    services: { dbyModeController: DbyModeController },
    stores: {
      dbyModeStore: DbyModeStore
      dielineStore: DielineStore
      productStore: ProductStore
    }
  ) {
    this.dbyModeController = services.dbyModeController
    this.dbyModeStore = stores.dbyModeStore
    this.dielineStore = stores.dielineStore
    this.productStore = stores.productStore
  }

  public async uploadFile(file: File): Promise<void> {
    await this.dbyModeController.uploadFile(file)
  }

  public async removeFile(): Promise<void> {
    await this.dbyModeController.removeFile()
  }

  @computed
  public get previewUrl(): string | undefined {
    return (
      this.dbyModeStore.uploadedFile && this.dbyModeStore.uploadedFilePreviewUrl
    )
  }

  @computed
  public get filename(): string | undefined {
    return this.dbyModeStore.uploadedFile && this.dbyModeStore.uploadedFilename
  }

  @computed
  public get isUploading(): boolean {
    return this.dbyModeStore.isUploading
  }

  @computed
  public get uploadError(): AssetError | null {
    return this.dbyModeStore.uploadError
  }

  @computed
  public get isLoadingDielineUrls(): boolean {
    return this.dielineStore.isLoadingDbyDielineUrls
  }

  @computed
  public get downloadItems(): DbyDownloadItem[] {
    const items: DbyDownloadItem[] = []

    for (const type of fileTypes) {
      const url = this.dielineStore.getDbyDielineUrl(
        this.productStore.product,
        type
      )

      const definition = fileDefinitions[type]

      if (url) {
        items.push({
          ...definition,
          url,
          type,
        })
      }
    }

    return items
  }
}
