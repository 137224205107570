import React, { useState } from "react"
import * as Yup from "yup"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { Modal } from "../../Modal/Modal"
import TextField from "dsl/src/atoms/TextField/TextField"
import { Notification } from "../../../../atoms/Notification/Notification"
import { ModalSection } from "../../ModalSection/ModalSection"
import { I18N } from "../../../../../i18n"

const i18n = I18N.modals.shareDesign
const validationSchema = Yup.string().email().required().trim()

export interface ShareDesignFormProps {
  onClose: () => void
  onSend: (email: string) => void
  success: boolean
  loading: boolean
  error: string | null
}

export function ModalShareDesignForm({
  error,
  success,
  loading,
  onClose,
  onSend,
}: ShareDesignFormProps) {
  const [email, setEmail] = useState<string>("")
  const t = useTranslate()

  return (
    <Modal
      onClose={onClose}
      title={t(i18n.title)}
      link={{
        label: t(i18n.send),
        onClick: async () => {
          validationSchema
            .validate(email)
            .then(() => onSend(email))
            .catch((e) => {
              console.log(e)
            })
        },
        loading,
      }}
    >
      <ModalSection title={t(i18n.linkShare.title)}>
        <p>
          {t(i18n.linkShare.description, {
            link: "",
          })}
        </p>

        <TextField type="text" value={window.location.href} disabled />
      </ModalSection>

      <ModalSection title={t(i18n.emailShare.title)}>
        <p>{t(i18n.emailShare.description)}</p>

        <TextField
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target["value"])}
        />

        {error && <Notification type="error">{error}</Notification>}

        {success && (
          <Notification type="success">
            {t(i18n.emailShare.success)}
          </Notification>
        )}
      </ModalSection>
    </Modal>
  )
}
