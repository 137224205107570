import ProductDriver from "../../drivers/product.driver"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { EditContext, SpaceId } from "../../libs/products-render-config/types"
import fabric from "../../libs/vendors/Fabric"

export interface ProcessParams {
  editContext: EditContext
  spaceId: SpaceId
}

export abstract class AssetsController {
  protected readonly productDriver: ProductDriver
  protected readonly ee: AllEditorEventsEmitter

  protected constructor(services: {
    productDriver: ProductDriver
    ee: AllEditorEventsEmitter
  }) {
    this.productDriver = services.productDriver
    this.ee = services.ee
  }

  protected async addAsset(
    process: ({ editContext, spaceId }: ProcessParams) => Promise<void>
  ): Promise<void> {
    const {
      isRendererLoading,
      activeViewType,
      activeContext,
      productRenderPilot,
    } = this.productDriver.state

    if (isRendererLoading) {
      return
    }

    await this.productDriver.toggleDragMode(false)

    const isModelView = activeViewType === "model"
    const isOneSpaceAvailable =
      productRenderPilot.getAvailableSpaces(activeContext).length === 1

    if (!isModelView || isOneSpaceAvailable) {
      const spaceId = this.getSpaceId()

      return await process({
        editContext: activeContext,
        spaceId,
      })
    }

    this.productDriver.setIsSelectingAssetPlacement(true)

    this.ee.once(eventTree.productDriver.spaceSelectedForAsset, process)
  }

  protected get shouldSelectAsset(): boolean {
    const { activeViewType } = this.productDriver.state

    return activeViewType !== "model"
  }

  protected touchDesign(): void {
    this.productDriver.setDesignTouched(true)
  }

  private getSpaceId(): SpaceId {
    const { activeContext, activeSpace, productRenderPilot, dielineZoom } =
      this.productDriver.state

    const spaceId =
      activeSpace || productRenderPilot.getDefaultSpace(activeContext)

    if (dielineZoom <= 1) {
      return spaceId
    }

    const vdEditor = this.productDriver.getVdEditor()
    const viewportCenter = vdEditor.dielineNavigator.getViewportCenter()
    const viewportCenterSpaceId =
      vdEditor.spaceHighlightModule.findSpace(viewportCenter)?.originSpaceArea

    return viewportCenterSpaceId || spaceId
  }
}
