/** @format */
// product sku def
import SkuFinish from "./envelopeSkuDefinitions/SkuFinish";
import SkuMaterial from "./envelopeSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./envelopeSkuDefinitions/SkuPrintMode";
import SkuModel from "./envelopeSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./envelopePartsDefinitions/PartFinish";
import PartMaterial from "./envelopePartsDefinitions/PartMaterial";
import PartPrintMode from "./envelopePartsDefinitions/PartPrintMode";
import PartModel from "./envelopePartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var ENVELOPE_CONSTS = {
    SKU_BASE: "envelope-",
    PRODUCT_TYPE: "envelope",
    DEFAULT_LINE_ITEM_RANGES: [
        30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500,
    ],
    sizeC5: "5",
    sizeC4: "4",
};
export default Object.assign({}, ENVELOPE_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
