import { action, computed, observable, makeObservable } from "mobx"
import { TemplatesStore } from "../../stores/templates.store"
import { TemplatesController } from "../templates.controller"
import { PatternController } from "../patterns.controller"
import { PatternsStore } from "../../stores/patterns.store"
import { SubTab } from "../../types/ui.types"
import { I18N } from "../../ui/i18n"
import { PatternCategory } from "../../models/pattern"
import { AllEditorEventsEmitter } from "../../events/editor.events"
import { AbstractTabController } from "./abstract-tab.controller"

export class TemplatesPatternsTabController extends AbstractTabController {
  @observable public expandedPatternCategory?: PatternCategory

  private readonly templatesController?: TemplatesController
  private readonly patternsController?: PatternController
  private readonly templatesStore?: TemplatesStore
  private readonly patternsStore?: PatternsStore

  constructor(
    services: {
      templatesController?: TemplatesController
      patternsController?: PatternController
      ee: AllEditorEventsEmitter
    },
    stores: {
      templatesStore?: TemplatesStore
      patternsStore?: PatternsStore
    }
  ) {
    super(services)

    this.templatesController = services.templatesController
    this.patternsController = services.patternsController
    this.templatesStore = stores.templatesStore
    this.patternsStore = stores.patternsStore

    makeObservable(this)
  }

  public setActiveSubTab(subTab?: SubTab): void {
    super.setActiveSubTab(subTab)

    this.expandedPatternCategory = undefined
  }

  @action
  public setExpandedPatternCategory(category?: PatternCategory): void {
    this.expandedPatternCategory = category
  }

  @computed
  public get isSubTabSwitcherVisible(): boolean {
    return super.isSubTabSwitcherVisible && !this.expandedPatternCategory
  }

  public get subTabs(): SubTab[] {
    const subTabs: SubTab[] = []

    if (this.templatesController) {
      subTabs.push({
        id: "templates",
        titleIntl: I18N.tabs.templates,
        analyticsName: "templates",
      })
    }

    if (this.patternsStore) {
      subTabs.push({
        id: "patterns",
        titleIntl: I18N.tabs.patterns,
        analyticsName: "patterns",
      })
    }

    return subTabs
  }
}
