import { updateRunningConsents } from "../../others/consent-manager/common/helpers/consents-handler"
import { getPreferencesArrays } from "../../others/consent-manager/common/helpers/preferences-handler"

declare global {
  interface Window {
    analytics: any
    rudderanalytics: any
    analyticsDisabled?: boolean
    rudderAnalyticsBuildType: string
    RudderSnippetVersion: string
    rudderAnalyticsMount: () => void
    ph?: {
      integrations?: {
        rudderstack?: {
          code: string
          dataPlaneUrl: string
        }
      }
    }
  }
}

const getRudderStackConfig = () => {
  if (!window) {
    return null
  }
  if (
    window.ph &&
    window.ph.integrations &&
    window.ph.integrations.rudderstack
  ) {
    return window.ph.integrations.rudderstack
  }
  if (
    window.zpkj &&
    window.zpkj.env &&
    window.zpkj.env.REACT_APP_Z_RUDDERSTACK_CODE &&
    window.zpkj.env.REACT_APP_Z_RUDDERSTACK_DATA_PLANE_URL
  ) {
    return {
      code: window.zpkj.env.REACT_APP_Z_RUDDERSTACK_CODE,
      dataPlaneUrl: window.zpkj.env.REACT_APP_Z_RUDDERSTACK_DATA_PLANE_URL,
    }
  }

  return null
}

export const initRudderstack = () => {
  const rudderstackConfig = getRudderStackConfig()
  console.info("[Analytics]", "Initialize")
  if (
    rudderstackConfig &&
    rudderstackConfig.code &&
    rudderstackConfig.dataPlaneUrl
  ) {
    if (window && window.rudderanalytics) {
      console.info("[Analytics]", "Already initialized")
      return window.rudderanalytics
    }
    try {
      //@ts-ignore
      !(function () {
        "use strict"
        window.RudderSnippetVersion = "3.0.3"
        var sdkBaseUrl = "https://cdn.rudderlabs.com/v3"
        var sdkName = "rsa.min.js"
        var asyncScript = true
        ;(window.rudderAnalyticsBuildType = "legacy"),
          (window.analytics = window.rudderanalytics = [])
        var e = [
          "setDefaultInstanceKey",
          "load",
          "ready",
          "page",
          "track",
          "identify",
          "alias",
          "group",
          "reset",
          "setAnonymousId",
          "startSession",
          "endSession",
          "consent",
          "debug",
          "off",
          "on",
          "once",
          "pageview",
          "trackClick",
          "trackForm",
          "trackLink",
          "trackSubmit",
        ]

        for (var n = 0; n < e.length; n++) {
          var t = e[n]
          window.rudderanalytics[t] = (function (e) {
            return function () {
              if (
                window.rudderanalytics &&
                window.rudderanalytics.push &&
                typeof window.rudderanalytics.push === "function"
              ) {
                window.rudderanalytics.push(
                  [e].concat(Array.prototype.slice.call(arguments))
                )
              }
            }
          })(t)
        }
        try {
          new Function('return import("")'),
            (window.rudderAnalyticsBuildType = "modern")
        } catch (a) {}
        if (
          ((window.rudderAnalyticsMount = function () {
            "undefined" == typeof globalThis &&
              (Object.defineProperty(Object.prototype, "__globalThis_magic__", {
                get: function get() {
                  return this
                },
                configurable: true,
              }),
              // @ts-ignore
              (__globalThis_magic__.globalThis = __globalThis_magic__),
              // @ts-ignore
              delete Object.prototype.__globalThis_magic__)
            var e = document.createElement("script")
            e.onerror = function () {
              console.error("[Analytics] Blocked")
              window.analyticsDisabled = true
            }
            ;(e.src = ""
              .concat(sdkBaseUrl, "/")
              .concat(window.rudderAnalyticsBuildType, "/")
              .concat(sdkName)),
              (e.async = asyncScript),
              document.head
                ? document.head.appendChild(e)
                : document.body.appendChild(e)
          }),
          "undefined" == typeof Promise || "undefined" == typeof globalThis)
        ) {
          var d = document.createElement("script")
          ;(d.src =
            "https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount"),
            (d.async = asyncScript),
            document.head
              ? document.head.appendChild(d)
              : document.body.appendChild(d)
        } else {
          window.rudderAnalyticsMount()
        }

        window.rudderanalytics.load(
          rudderstackConfig.code,
          rudderstackConfig.dataPlaneUrl,
          {
            preConsent: {
              enabled: true,
              storage: {
                strategy: "none",
              },
              events: {
                delivery: "buffer",
              },
            },
            consentManagement: {
              enabled: true,
              provider: "custom",
              ...getPreferencesArrays(),
            },
            onLoaded: () => {
              console.info("[Analytics Loaded]")
              window.analytics = window.rudderanalytics
              updateRunningConsents()
            },
          }
        )

        window.rudderanalytics.page()
      })()

      return window.rudderanalytics
    } catch (e) {
      console.error("[Analytics Error]", e)
    }
  } else {
    console.warn("[Analytics] Config not found")
  }
  return null
}
