import FiltersModule from "../../filters.module"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import FabricAssetLoader from "../../../../../../libs/services/loaders/fabric-asset.loader"
import Colour from "../../../../../../models/colour"
import { PackhelpImage } from "../../../object-extensions/packhelp-objects"

export class SceneDecorationController {
  private image?: PackhelpImage

  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async decorate(isSpaceZoomActive: boolean): Promise<void> {
    if (!this.canvasContainer) {
      return
    }

    const backgroundTextureUrl =
      this.vdEditor.productRenderPilot.getSceneBackgroundTextureUrl(
        this.vdEditor.editContext
      )

    if (isSpaceZoomActive || !backgroundTextureUrl) {
      this.canvasContainer.style.backgroundImage = ""

      return
    }

    this.image = await FabricAssetLoader.loadAsset(backgroundTextureUrl, {})

    this.applyBackgroundImage()
  }

  public async refresh() {
    this.applyBackgroundImage()
  }

  private applyBackgroundImage(): void {
    if (!this.image || !this.canvasContainer) {
      return
    }

    const backgroundColor = this.getBackgroundColor()

    if (!this.vdEditor.texture && backgroundColor) {
      FiltersModule.setBlendColorFilter(this.image, backgroundColor)
    }

    this.canvasContainer.style.backgroundImage = `url(${this.image.toDataURL(
      {}
    )})`
  }

  private getBackgroundColor(): Colour | undefined {
    const space = this.vdEditor.dielineNavigator.getActiveVirtualDielineSpace()

    if (!space?.fill) {
      return
    }

    return new Colour(space.fill as string)
  }

  private get canvasContainer(): HTMLElement | null {
    const canvas = this.vdEditor.fabricCanvas

    return canvas.wrapperEl?.parentElement
  }
}
