import { observable, action, makeObservable } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { ProductDesignStore } from "../../stores/product-design.store"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import {
  ChangeProductControllable,
  OriginalDesignData,
} from "./change-product.interface"
import { DbyModeStore } from "../../stores/dby-mode.store"
import { VariantCustomization } from "@ph/product-api"

export class ChangeProductDbyMode implements ChangeProductControllable {
  @observable public originalDesign?: OriginalDesignData

  constructor(
    private readonly services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    private readonly stores: {
      productDesignStore: ProductDesignStore
      dbyModeStore: DbyModeStore
    }
  ) {
    makeObservable(this)
  }

  public isChangeRisky(): boolean {
    return !!this.stores.dbyModeStore.uploadedFile
  }

  @action
  public async changeSku(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    if (this.services.productDriver.state.isProductChanging) {
      return
    }

    if (!this.originalDesign) {
      this.cacheOriginalDesign()
    }

    await this.services.productDriver.changeSku(sku, customization)
    this.services.ee.emit(eventTree.productDriver.productChanged)
    this.stores.dbyModeStore.removeUploadedFile()

    this.services.productDriver.setIsProductChanging(false)
  }

  @action
  public cacheOriginalDesign(): void {
    const { productSku, customization } =
      this.services.productDriver.productStore

    this.setOriginalDesign({
      sku: productSku,
      customization,
      file: this.stores.dbyModeStore.uploadedFile,
    })
  }

  @action
  public setOriginalDesign(originalDesign: OriginalDesignData): void {
    this.originalDesign = originalDesign
  }

  @action
  public clearOriginalDesign(): void {
    this.originalDesign = undefined
  }

  public async cancel(): Promise<void> {
    if (!this.originalDesign) {
      return
    }

    const { sku, customization, file } = this.originalDesign

    await this.changeSku(sku, customization)
    this.stores.dbyModeStore.setUploadedFile(file)
    this.clearOriginalDesign()
  }
}
