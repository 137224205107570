import React from "react"
import { observer } from "mobx-react-lite"
import { Sidebar } from "../Sidebar/Sidebar"
import styles from "./Aside.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import cxBinder from "classnames/bind"
import { TabSkipDesign } from "../tabs/TabSkipDesign/TabSkipDesign"
import { SvgIcon } from "../../atoms/SvgIcon/SvgIcon"
import closeIconUrl from "../../../assets/_icons/arrow-left.svg"

const cx = cxBinder.bind(styles)

export const Aside = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { asideController } = containerSet.ui
  const {
    isAvailable,
    isOpen,
    availableTabs,
    isCloseButtonVisible,
    isSkippingDesign,
    selectedTab,
    isSidebarVisible,
  } = asideController

  if (!isAvailable) {
    return null
  }

  const onCloseClick = () => {
    asideController.closeTab()
  }

  return (
    <div
      className={cx("wrapper", {
        open: isOpen,
        ["no-sidebar"]: !isSidebarVisible,
      })}
    >
      {isSkippingDesign ? (
        <div className={styles.tab}>
          <TabSkipDesign />
        </div>
      ) : (
        <>
          {isSidebarVisible && (
            <div className={styles.sidebar}>
              <Sidebar />
            </div>
          )}

          {availableTabs.map(({ name, Component }) => {
            if (selectedTab === name) {
              return (
                <div key={name} className={styles.tab}>
                  <Component key={name} />
                </div>
              )
            }
          })}
        </>
      )}

      {isCloseButtonVisible && (
        <button
          className={styles.close}
          onClick={onCloseClick}
          e2e-target="button"
          e2e-target-name="close-tab"
        >
          <SvgIcon url={closeIconUrl} />
        </button>
      )}
    </div>
  )
})
