import { ThumbnailGeneratorConfig } from "../../types"

type PreviewAlignement = "left" | "center" | "right"

export class TwoDimThumbnailGenerator {
  private readonly thumbnailCanvas: HTMLCanvasElement

  constructor(
    private readonly canvases: Record<string, HTMLCanvasElement>,
    private readonly config: ThumbnailGeneratorConfig
  ) {
    if (Object.keys(this.canvases).length > 2) {
      throw new Error("Max 2 canvases per generator are supported now")
    }

    this.thumbnailCanvas = document.createElement("canvas")
    this.thumbnailCanvas.setAttribute("width", this.config.width.toString())
    this.thumbnailCanvas.setAttribute("height", this.config.height.toString())
  }

  /**
   * Basic workflow:
   * https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images
   *
   * Get pixels, draw on new canvas, grab pixels from new canvas
   */
  public async getThumbBase64(): Promise<string> {
    const thumbnailContext = this.thumbnailCanvas.getContext("2d")
    if (thumbnailContext == null) {
      throw new Error("2D context must be avaliable")
    }

    // One sided poly
    if (Object.keys(this.canvases).length === 1) {
      const firstPixesls = this.getCanvasPixels(Object.values(this.canvases)[0])
      await this.drawSideOnThumbnail(firstPixesls, "center")
    }

    // Two sided poly
    if (Object.keys(this.canvases).length === 2) {
      const firstPixesls = this.getCanvasPixels(Object.values(this.canvases)[0])
      await this.drawSideOnThumbnail(firstPixesls, "left")

      const secondPixesls = this.getCanvasPixels(
        Object.values(this.canvases)[1]
      )
      await this.drawSideOnThumbnail(secondPixesls, "right")
    }

    const pixelsPromise = new Promise<string>((resolve) => {
      const base64png = this.getCanvasPixels(this.thumbnailCanvas)
      // quick and dirty export debugging
      // https://base64.guru/converter/decode/image/png
      // console.log("base64 thumb", base64png)
      resolve(base64png)
    })

    return pixelsPromise
  }

  private async drawSideOnThumbnail(
    pixels: string,
    alignment: PreviewAlignement
  ) {
    const thumbnailContext = this.thumbnailCanvas.getContext("2d")
    if (thumbnailContext == null) {
      throw new Error("2D context must be avaliable")
    }

    return new Promise<void>((resolveOneSide) => {
      const img = new Image()
      img.addEventListener(
        "load",
        () => {
          const thumbW = this.thumbnailCanvas.width
          const thumbH = this.thumbnailCanvas.height

          const wRatio = thumbW / img.width
          const hRatio = thumbH / img.height
          const ratio = Math.min(wRatio, hRatio)

          // Perfect Center
          let centerShift_x = thumbW / 2 - (img.width * ratio) / 2
          let centerShift_y = (thumbH - img.height * ratio) / 2

          if (alignment === "left") {
            centerShift_x = thumbW / 2 - img.width * ratio - 5
          }
          if (alignment === "right") {
            centerShift_x = thumbW / 2 + 5
          }

          thumbnailContext.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            centerShift_x,
            centerShift_y,
            img.width * ratio,
            img.height * ratio
          )
          resolveOneSide()
        },
        false
      )
      img.src = pixels
    })
  }

  private getCanvasPixels(canvas: HTMLCanvasElement): string {
    return this.trimCanvas(canvas).toDataURL(
      this.config.mimeType,
      this.config.quality
    )
  }

  /**
   * Creates new HTMLCanvasElement trimmed from transparent pixels on each side, with optional padding added.
   * src: https://ourcodeworld.com/articles/read/683/how-to-remove-the-transparent-pixels-that-surrounds-a-canvas-in-javascript
   * @param canvas canvas to be trimmed
   * @param padding optional padding to add, 10 by default
   * @returns {HTMLCanvasElement} trimmed canvas copy
   */
  private trimCanvas(
    canvas: HTMLCanvasElement,
    padding = 10
  ): HTMLCanvasElement {
    const ctx = canvas.getContext("2d")
    const copy = document.createElement("canvas").getContext("2d")
    if (ctx === null || copy === null) {
      return canvas
    }
    let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const bound: {
      top: number | null
      left: number | null
      right: number | null
      bottom: number | null
    } = {
      top: null,
      left: null,
      right: null,
      bottom: null,
    }

    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    let i: number, x: number, y: number
    for (i = 0; i < pixels.data.length; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width
        y = ~~(i / 4 / canvas.width)

        if (bound.top === null) {
          bound.top = y
        }

        if (bound.left === null) {
          bound.left = x
        } else if (x < bound.left) {
          bound.left = x
        }

        if (bound.right === null) {
          bound.right = x
        } else if (bound.right < x) {
          bound.right = x
        }

        if (bound.bottom === null) {
          bound.bottom = y
        } else if (bound.bottom < y) {
          bound.bottom = y
        }
      }
    }

    if (
      bound.top === null ||
      bound.left === null ||
      bound.right === null ||
      bound.bottom === null
    ) {
      return canvas
    }

    // Calculate the height and width of the content
    const trimHeight = bound.bottom - bound.top
    const trimWidth = bound.right - bound.left
    const trimmed = ctx.getImageData(
      bound.left,
      bound.top,
      trimWidth,
      trimHeight
    )

    copy.canvas.width = trimWidth + padding * 2
    copy.canvas.height = trimHeight + padding * 2
    copy.putImageData(trimmed, padding, padding)

    // Return trimmed canvas
    return copy.canvas
  }

  // Keep!
  // a more optimal way to skip images and base64 and go for buffers direcly

  // private async drawPixelsOnThumbnail(
  //   pixels: ImageData,
  //   alignment: PreviewAlignement
  // ) {
  //    const ctx = this.canvases[0].canvas.getContext("2d")
  //    if (ctx == null) {
  //      throw new Error("2D context must be avaliable")
  //    }
  //    const c = {
  //      w: this.canvases[0].canvas.width,
  //      h: this.canvases[0].canvas.height,
  //    }
  //    const firstPixesls = ctx.getImageData(0, 0, c.w, c.h)
  //    await this.drawPixelsOnThumbnail(firstPixesls, "center")

  //   const thumbnailContext = this.thumbnailCanvas.getContext("2d")
  //   if (thumbnailContext == null) {
  //     throw new Error("2D context must be avaliable")
  //   }

  //   return new Promise<void>((resolveOneSide) => {
  //     thumbnailContext.putImageData(pixels, 20, 20)
  //     resolveOneSide()
  //   })
  // }

  // ---

  //   import { EventEmitter } from "events"
  // import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot.interface"
  // import { CanvasContextConfig } from "../3d-renderer/three-dim-renderer"
  // import VirtualDielineInitializer from "../vd-editor/services/virtual-dieline-initializer"
  // import VirtualDielineEditor from "../vd-editor/virtual-dieline-editor"

  // Do not remove
  // This is a second failed approach to reacreate virtual dieline from the start.
  // There are background issues

  // private createVD() {
  //   // const front = this.canvases[0]
  //   // // VD New Init:
  //   // const virtualDielineInitializer = new VirtualDielineInitializer({
  //   //   canvasDimensions: { width: 1024, height: 768 },
  //   //   productRenderPilot: this.pilot,
  //   //   editContext: front.context,
  //   //   mountPoint: this.createMountPoint(),
  //   //   eventEmitter: new EventEmitter(),
  //   // })
  //   // let vd = await virtualDielineInitializer.init()
  //   // const data = await this.sourceVD.fullExport()
  //   // await vd.import(data, "latest")
  //   // await vd.changeSpace("front")
  //   // let x = await vd.getCanvasToPreview()
  //   // const pixelsNEW = this.getCanvasPixels(x)
  //   // console.log("NEW NEW NEW", pixelsNEW)
  //   // debugger
  //   ///// OLDDER
  // }

  // private createMountPoint(): HTMLCanvasElement {
  //   const rendererContainer = document.createElement("canvas")
  //   rendererContainer.setAttribute("width", this.dupaW.toString())
  //   rendererContainer.setAttribute("height", this.dupaH.toString())
  //   return rendererContainer
  // }
}
