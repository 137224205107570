import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { LinePoints, ObjectEdge } from "../types"
import { GuidelinesController } from "./guidelines.controller"
import { getEditableObjects } from "../../canvas-object-controller/helpers"

export class ObjectGuidelinesController extends GuidelinesController {
  protected getObjectsToCompare(
    currentObject: PackhelpObject
  ): PackhelpObject[] {
    const { activeSpaceId } = this.canvas

    return getEditableObjects(this.canvas).filter((nextObject) => {
      const isSameObject = currentObject === nextObject
      const isMask = currentObject.id === nextObject.maskParentId
      const isMaskParent = currentObject.maskParentId === nextObject.id
      const isEditableGroup = currentObject.groupId === nextObject.id

      const isProperObject =
        !isSameObject && !isMask && !isMaskParent && !isEditableGroup

      const onProperSpace =
        !activeSpaceId || nextObject.originSpaceArea === activeSpaceId

      return isProperObject && onProperSpace
    })
  }

  protected calculateLinePoints(
    currentEdge: ObjectEdge,
    nextEdge: ObjectEdge
  ): LinePoints {
    const { axis } = currentEdge

    if (axis === "x") {
      let startY = currentEdge.points.start.y
      let endY = nextEdge.points.end.y

      if (currentEdge.points.start.y >= nextEdge.points.start.y) {
        startY = nextEdge.points.start.y
        endY = currentEdge.points.end.y
      }

      return {
        start: { x: nextEdge.points.start.x, y: startY },
        end: { x: nextEdge.points.end.x, y: endY },
      }
    }

    let startX = currentEdge.points.start.x
    let endX = nextEdge.points.end.x

    if (currentEdge.points.start.x >= nextEdge.points.start.x) {
      startX = nextEdge.points.start.x
      endX = currentEdge.points.end.x
    }

    return {
      start: { x: startX, y: nextEdge.points.start.y },
      end: { x: endX, y: nextEdge.points.end.y },
    }
  }
}
