import VirtualDielineEditor from "../../../virtual-dieline-editor"

import { PackhelpEditableText } from "../../../object-extensions/packhelp-objects"
import { EditableObjectTypes } from "../../../../../../types/asset.types"
import {
  FabricObjectStyles,
  ObjectController,
  ObjectStyles,
} from "./object.controller"
import {
  CanvasEvent,
  CanvasObjectEvent,
} from "../../../../../../events/partials/domain.events"
import { degreesToRadians } from "../../../../../../libs/maths/angle-calculators"
import fabric from "../../../../../../libs/vendors/Fabric"
import {
  FontFamilyDefinition,
  FontSettings,
  FontStyle,
  FontWeight,
  FontSizeDefinition,
  TextAlign,
  FontShadow,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.service"
import {
  FontDef,
  Fonts,
  LOADABLE_FONTS_CONFIGS,
} from "../../../../../../libs/services/fonts-loader-service/fonts-loader.config"
import Colour from "../../../../../../models/colour"
import { FontHelper } from "../helpers/font.helper"

export interface TextStyles extends ObjectStyles {
  fontFamily?: Fonts
  fontSize?: number
  textAlign?: TextAlign
  fontWeight?: FontWeight
  fontStyle?: FontStyle
  lineHeight?: number
  charSpacing?: number
  strokeWidth?: number
  stroke?: Colour
  shadow?: FontShadow
}

export class TextObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableText,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (this.canvasObject.assetType !== EditableObjectTypes.assetText) {
      throw new Error("Type not supported")
    }
  }

  public getObject(): PackhelpEditableText {
    return this.canvasObject
  }

  public setStyles(styles: TextStyles) {
    const centerPoint = this.canvasObject.getCenterPoint()
    const stylesCopy = { ...styles }

    if (!!stylesCopy.fontFamily) {
      this.setFontFamily(stylesCopy.fontFamily)
      delete stylesCopy.fontFamily
    }

    if (stylesCopy.fontSize) {
      this.setFontSize(stylesCopy.fontSize)
      delete stylesCopy.fontSize
    }

    if (stylesCopy.fill) {
      this.setFill(stylesCopy.fill)
      delete stylesCopy.fill
    }

    if (stylesCopy.stroke) {
      this.setStroke(stylesCopy.stroke)
      delete stylesCopy.stroke
    }

    if (stylesCopy.shadow) {
      this.setShadow(stylesCopy.shadow)
      delete stylesCopy.shadow
    }

    this.canvasObject.set(stylesCopy as FabricObjectStyles)
    this.recenter(centerPoint)

    this.canvasObject.fire(CanvasObjectEvent.changed)
    this.canvas.fire(CanvasEvent.objectRestyled, {
      target: this.canvasObject,
    })
  }

  private recenter(centerPoint: fabric.Point) {
    const newPosition = fabric.util.rotatePoint(
      new fabric.Point(
        centerPoint.x - this.canvasObject.getScaledWidth() / 2,
        centerPoint.y - this.canvasObject.getScaledHeight() / 2
      ),
      centerPoint,
      degreesToRadians(this.canvasObject.angle)
    )

    this.canvasObject.set({
      left: newPosition.x,
      top: newPosition.y,
    })
  }

  private setFontFamily(fontFamily: Fonts) {
    FontHelper.clearFabricFontCache(fontFamily)

    this.canvasObject.set({
      fontFamily,
      fontStyle: FontStyle.normal,
      fontWeight: FontWeight.normal,
    })
  }

  public getFontFamilyDefinition(): FontFamilyDefinition | undefined {
    const fontFamily = this.canvasObject.get("fontFamily")
    const fontConfig = this.getFontConfig()

    if (!fontConfig) {
      return
    }

    return {
      id: fontFamily,
      name: fontFamily,
      config: fontConfig,
    }
  }

  public hasShadow(): boolean {
    const shadow = this.getShadow()

    if (!shadow) {
      return false
    }

    return !!shadow.color && (!!shadow.offsetX || !!shadow.offsetY)
  }

  public getShadow(): FontShadow | undefined {
    let shadow = this.canvasObject.shadow

    if (!shadow) {
      return
    }

    if (typeof shadow === "string") {
      shadow = new fabric.Shadow(shadow)
    }

    return {
      offsetX: shadow.offsetX || 0,
      offsetY: shadow.offsetY || 0,
      color: new Colour(shadow.color),
    }
  }

  public hasStroke(): boolean {
    return !!this.getStrokeColor() && !!this.getStrokeThickness()
  }

  public getStrokeColor(): Colour | undefined {
    const stroke = this.canvasObject.stroke

    if (!stroke) {
      return
    }

    return new Colour(stroke)
  }

  public getStrokeThickness(): number {
    return this.canvasObject.strokeWidth || 0
  }

  public getLineHeight(): number {
    return this.canvasObject.lineHeight
  }

  public getCharSpacing(): number {
    return this.canvasObject.charSpacing
  }

  public getFontConfig(): FontDef | undefined {
    return LOADABLE_FONTS_CONFIGS[this.canvasObject.get("fontFamily")]
  }

  public isFontStyleAvailable() {
    const fontConfig = this.getFontConfig()

    if (!fontConfig) {
      return false
    }

    const fontWeight = this.canvasObject.get("fontWeight")

    if (fontWeight === FontWeight.bold) {
      return !!fontConfig.boldItalic
    }

    return !!fontConfig.italic
  }

  public isFontWeightAvailable() {
    const fontConfig = this.getFontConfig()

    if (!fontConfig) {
      return false
    }

    const fontStyle = this.canvasObject.get("fontStyle")

    if (fontStyle === FontStyle.italic) {
      return !!fontConfig.boldItalic
    }

    return !!fontConfig.bold
  }

  public isFontPropertyActive(fontAttribute: FontSettings): boolean {
    switch (fontAttribute) {
      case FontSettings.fontWeight:
        const fontWeight = this.canvasObject.get("fontWeight")
        return fontWeight === FontWeight.bold

      case FontSettings.fontStyle:
        const fontStyle = this.canvasObject.get("fontStyle")
        return fontStyle === FontStyle.italic

      default:
        return false
    }
  }

  public isFontPropertyAvailable(fontAttribute: FontSettings): boolean {
    switch (fontAttribute) {
      case FontSettings.fontWeight:
        return this.isFontWeightAvailable()
      case FontSettings.fontStyle:
        return this.isFontStyleAvailable()
      default:
        return false
    }
  }

  private setFontSize(fontSize: number) {
    const scale = fontSize / this.canvasObject.fontSize

    this.canvasObject.set({
      scaleX: scale,
      scaleY: scale,
    })
  }

  private setShadow(shadow: FontShadow) {
    this.canvasObject.set({
      shadow: new fabric.Shadow({
        offsetX: shadow.offsetX,
        offsetY: shadow.offsetY,
        color: shadow.color?.getHex(),
      }),
    })
  }

  private setStroke(stroke: Colour) {
    this.canvasObject.set({
      stroke: stroke.getHex(),
    })
  }

  public getFontSize(): number {
    let scale = this.canvasObject.scaleX!

    if (this.hasGroup()) {
      scale *= this.getGroup()!.scaleX!
    }

    return this.canvasObject.fontSize * scale
  }

  public getFontSizeDefinition(): FontSizeDefinition {
    const fontSize = Math.round(this.getFontSize())

    return { id: fontSize, name: fontSize }
  }
}
