import { actions } from "./constants-actions"
import { application } from "./constants-app"
import { colors } from "./constants-colors"
import { company } from "./constants-company"
import { editor } from "./constants-editor"
import { events } from "./constants-events"
import { files } from "./constants-files"
import { forms } from "./constants-forms"
import { locales } from "./constants-locales"
import { modals } from "./constants-modals"
import { payments } from "./constants-payments"
import { products } from "./constants-products"
import { routes } from "./constants-routes"
import { PUBLIC_ROUTE_IDS } from "./constants-routes"
import { spree } from "./constants-spree"
import { stages } from "./constants-stages"
import { stores } from "./constants-stores"
import { wordpress } from "./constants-wordpress"
import { urls } from "../services/api/api-endpoint-urls"

let zc = {
  API_TOKEN: "api-token",

  // Routes
  ...routes,
  PUBLIC_ROUTE_IDS,

  // Locales
  ...locales,

  // API URLs
  API_URL: "/api",
  ...urls,
  ...wordpress,

  // Support URLS
  API_SUPPORT_GET_PRODUCT_BY_ORDER: "/support/order",

  // Stores
  ...stores,

  // Actions
  ...actions,

  // Stages
  ...stages,

  // Forms
  ...forms,

  // Editor
  ...editor,

  // Colors
  ...colors,

  // Modals
  ...modals,

  // Files
  ...files,

  // Spree
  ...spree,

  // Events
  ...events,

  // Payments, invoices etc.
  ...payments,

  // Company
  ...company,

  // Application - repo, misc.
  ...application,

  // Products
  ...products,
}

export default zc
