/** @prettier */

import { BadgeColorTypes } from "../../../atoms/Badge/Badge"
import type {
  PriceBreak,
  PriceDisplayOptions,
  PriceFormatter,
  QuantityItem,
  TranslatedKeys
} from "../types"
import {
  createBasicQuantityItem,
  createCalculateQuantityItem
} from "./quantity-items"

interface QuantityItemForSearchValueParams {
  getPriceBreak: (quantity: number) => PriceBreak
  piecePriceFormatter?: PriceFormatter
  priceDisplayOptions?: PriceDisplayOptions
  priceFormatter?: PriceFormatter
  quantity: number
  translations: TranslatedKeys
}

export const createQuantityItemForSearchValue = ({
  getPriceBreak,
  piecePriceFormatter,
  priceDisplayOptions,
  priceFormatter,
  quantity,
  translations
}: QuantityItemForSearchValueParams): QuantityItem => {
  const {
    currency,
    deal,
    pricePerPiece,
    priceTotal,
    outOfStock,
    isCustomValue,
    isDynamicPricingAvailable
  } = getPriceBreak(quantity)

  if (isCustomValue) {
    if (isDynamicPricingAvailable) {
      return createCalculateQuantityItem({
        quantity: String(quantity),
        label: translations.calculateLabel
      })
    }

    return createBasicQuantityItem({
      quantity: String(quantity),
      badge: translations.customValueLabel
    })
  }

  const badge = outOfStock
    ? {
        label: translations.outOfStockLabel,
        color: BadgeColorTypes.WhiteFour
      }
    : {}

  return createBasicQuantityItem(
    {
      currency,
      discount: deal,
      badge: badge.label,
      badgeColor: badge.color,
      outOfStock,
      piecePrice: pricePerPiece,
      price: priceTotal,
      quantity: String(quantity)
    },
    priceDisplayOptions,
    priceFormatter,
    piecePriceFormatter
  )
}
