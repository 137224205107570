import { computed, makeObservable } from "mobx"
import { SubTab } from "../../types/ui.types"
import ProductDriver from "../../drivers/product.driver"
import { I18N } from "../../ui/i18n"
import { AllEditorEventsEmitter } from "../../events/editor.events"
import { AbstractTabController } from "./abstract-tab.controller"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import {
  EditContext,
  HexColorsPreset,
  PantoneColorsPreset,
  UIConfig,
} from "../../libs/products-render-config/types"
import Colour from "../../models/colour"
import BackgroundsDriver from "../../drivers/backgrounds.driver"
import { BackgroundImageUiController } from "../background-image-ui.controller"
import { ImageAssetItemStore } from "../../stores/image-asset-item.store"
import { BackgroundImageStore } from "../../stores/background-image.store"
import { AssetsStore } from "../../stores/assets.store"
import { HexColorsDatabase } from "../../libs/colors/hex-colors.database"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { colorsListDemoWithBlankColor } from "../../libs/colors/hex-colors.list"

const i18n = I18N.component.cameraControlsToolbar

export class BackgroundTabController extends AbstractTabController {
  private readonly productDriver: ProductDriver
  private readonly backgroundImageUiController: BackgroundImageUiController
  private readonly backgroundImageStore: BackgroundImageStore
  private readonly assetStore: AssetsStore

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
      backgroundImageUiController: BackgroundImageUiController
    },
    stores: {
      backgroundImageStore: BackgroundImageStore
      assetStore: AssetsStore
    }
  ) {
    super(services)

    this.productDriver = services.productDriver
    this.backgroundImageUiController = services.backgroundImageUiController
    this.backgroundImageStore = stores.backgroundImageStore
    this.assetStore = stores.assetStore

    makeObservable(this)
  }

  @computed
  public get pantoneColorsPreset(): PantoneColorsPreset | undefined {
    return this.productRenderPilot.getBackgroundPantoneColorsPreset()
  }

  @computed
  public get hexColorsList(): ColorListArray[] {
    const hexDb = new HexColorsDatabase()
    const preset = this.productRenderPilot.getBackgroundHexColorsPreset()

    if (!preset) {
      return colorsListDemoWithBlankColor
    }

    return hexDb.getByPreset(preset)
  }

  public get subTabs(): SubTab[] {
    return this.productRenderPilot
      .getAvailableEditContexts()
      .map((editContext) => ({
        id: editContext,
        titleIntl: i18n.context[editContext],
        analyticsName: editContext,
      }))
  }

  public async setBackgroundColor(color?: Colour): Promise<void> {
    if (!this.editContext) {
      return
    }

    if (color) {
      return this.backgroundsDriver.paintProductEditContext(
        color,
        this.editContext
      )
    }

    return this.backgroundsDriver.paintProductEditContext(
      null,
      this.editContext
    )
  }

  public async uploadAndSetBackgroundColor(file: File): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.uploadAndApplyBackgroundImage(
      this.editContext,
      file
    )
  }

  @computed
  public get isBackgroundImageUploading(): boolean {
    return this.backgroundImageStore.isUploading
  }

  @computed
  public get selectedBackgroundColor(): Colour | undefined {
    if (!this.editContext) {
      return
    }

    return this.backgroundsDriver.backgroundColor[this.editContext]
  }

  public async setBackgroundImage(image: ImageAssetItemStore): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.applyBackgroundImage(
      this.editContext,
      image
    )
  }

  public async clearBackgroundImage(): Promise<void> {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.removeBackgroundImage(
      this.editContext
    )
  }

  public async removeImage(image: ImageAssetItemStore): Promise<void> {
    await this.backgroundImageUiController.removeBackgroundImagesByImageAssetStore(
      image
    )

    this.assetStore.removeAsset(image)
  }

  @computed
  public get selectedBackgroundImage(): ImageAssetItemStore | undefined {
    if (!this.editContext) {
      return
    }

    return this.backgroundImageUiController.getBackgroundImageAssetStore(
      this.editContext
    )
  }

  @computed
  public get isBackgroundImageAvailable(): boolean {
    return this.isPrintActive && this.features.backgroundImage
  }

  @computed
  public get isBackgroundColorAvailable(): boolean {
    return this.isPrintActive && this.features.backgroundColor
  }

  @computed
  public get isPrintAdditionallyPaid(): boolean {
    if (!this.activeSubTab) {
      return false
    }

    return this.productRenderPilot.isPrintAdditionallyPaidFor(
      this.activeSubTab.id as EditContext
    )
  }

  @computed
  public get isPrintActive(): boolean {
    if (!this.activeSubTab) {
      return false
    }

    return this.productRenderPilot.isPrintActiveFor(
      this.activeSubTab.id as EditContext
    )
  }

  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  private get backgroundsDriver(): BackgroundsDriver {
    return this.productDriver.backgroundsDriver
  }

  private get features(): UIConfig["features"] {
    return this.productRenderPilot.uiConfig.features
  }

  private get editContext(): EditContext | undefined {
    if (!this.activeSubTab) {
      return
    }

    return this.activeSubTab.id as EditContext
  }
}
