/** @format */
// product sku def
import SkuFinish from "./cardSkuDefinitions/SkuFinish";
import SkuMaterial from "./cardSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./cardSkuDefinitions/SkuPrintMode";
import SkuModel from "./cardSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./cardPartsDefinitions/PartFinish";
import PartMaterial from "./cardPartsDefinitions/PartMaterial";
import PartPrintMode from "./cardPartsDefinitions/PartPrintMode";
import PartModel from "./cardPartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var CARD_CONSTS = {
    SKU_BASE: "card-",
    PRODUCT_TYPE: "card",
    DEFAULT_LINE_ITEM_RANGES: [
        30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500,
    ],
};
export default Object.assign({}, CARD_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
