import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableText } from "../../../object-extensions/packhelp-objects"
import { v4 as uuidv4 } from "uuid"
import { EditableObjectTypes } from "../../../../../../types/asset.types"
import { ScaleHelper } from "../helpers/scale.helper"
import { ColorHelper } from "../helpers/color.helper"
import { Text } from "../../../../../../models/text"
import { FontHelper } from "../helpers/font.helper"
import fabric from "../../../../../../libs/vendors/Fabric"
import { Creator } from "./creator"

export class TextCreator extends Creator {
  public async create(
    text: Text,
    spaceId: SpaceId
  ): Promise<PackhelpEditableText> {
    await this.preloadFont(text)

    const object = new fabric.IText(
      text.getText()
    ) as unknown as PackhelpEditableText

    if (
      typeof object.width === "undefined" ||
      typeof object.height === "undefined"
    ) {
      throw new Error("object text is not initialize properly")
    }

    object.set({
      lockUniScaling: true,
      originSpaceArea: spaceId,
      fontFamily: text.getFontFamily(),
      assetTextMeta: text.toSource(),
      id: uuidv4(),
      assetType: EditableObjectTypes.assetText,
      isSpaceClippingDisabled: this.vdEditor.isSpaceClippingTogglingAvailable(),
    })

    ColorHelper.applyDefaultColor(this.vdEditor, object)

    this.scaleObject(object, spaceId)
    this.alignObject(object)
    this.rotateObject(object, spaceId)
    await this.setObjectSpaceClipping(object, spaceId)

    return object
  }

  private scaleObject(object: PackhelpEditableText, spaceId: SpaceId): void {
    const MANUALLY_ADJUSTED_SIZE = 512
    ScaleHelper.scaleObjectToSpace(
      this.vdEditor,
      object,
      {
        width: MANUALLY_ADJUSTED_SIZE,
        height: MANUALLY_ADJUSTED_SIZE,
      },
      spaceId
    )
  }

  private async preloadFont(text: Text): Promise<void> {
    const fontFamily = text.getFontFamily()
    await FontHelper.preloadFont({ fontFamily })
    FontHelper.clearFabricFontCache(fontFamily)
  }
}
