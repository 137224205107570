import React from "react"
import _times from "lodash/times"
import { Skeleton } from "./Skeleton"
import { OptionPickerList } from "../OptionPicker/OptionPickerList"

export interface SkeletonOptionPickerListProps {
  repeats: number
}

export function SkeletonOptionPickerList({
  repeats,
}: SkeletonOptionPickerListProps) {
  return (
    <OptionPickerList>
      {_times(repeats, () => (
        <Skeleton key={Math.random()} width={120} height={48} />
      ))}
    </OptionPickerList>
  )
}
