import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../_containers-react/_editor-app-hooks"
import { ModalShippingDestination } from "../../dsl/organisms/modals/ModalShippingDestination/ModalShippingDestination"
import { ModalChangeEditorModeConfirmation } from "../../dsl/organisms/modals/ModalChangeEditorModeConfirmation/ModalChangeEditorModeConfirmation"
import { ModalShareDesign } from "../../dsl/organisms/modals/ModalShareDesign/ModalShareDesign"
import { ModalChangeTemplateConfirmation } from "../../dsl/organisms/modals/ModalChangeTemplateConfirmation/ModalChangeTemplateConfirmation"
import { Menu } from "../../dsl/organisms/Menu/Menu"
import { AuthApp } from "../wrappers/AuthApp"
import { OnBeforeUnload } from "../wrappers/OnBeforeUnload"
import { ModalFinishDesignConfirmation } from "../../dsl/organisms/modals/ModalFinishDesignConfirmation/ModalFinishDesignConfirmation"
import { ModalRestartDesignConfirmation } from "../../dsl/organisms/modals/ModalRestartDesignConfirmation/ModalRestartDesignConfirmation"

export const Orchestra = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.auth])

  if (!containerSet) {
    return null
  }

  const { auth } = containerSet

  return (
    <>
      <AuthApp authContainer={auth} />
      <OnBeforeUnload />

      <Menu />

      <ModalShippingDestination />
      <ModalChangeEditorModeConfirmation />
      <ModalShareDesign />
      <ModalChangeTemplateConfirmation />
      <ModalFinishDesignConfirmation />
      <ModalRestartDesignConfirmation />
    </>
  )
})
