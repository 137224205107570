import { action, observable, computed, makeObservable } from "mobx"
import { TextObjectController } from "../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object.controller"
import { AllEditorEventsEmitter, eventTree } from "../../events/editor.events"
import { TextEffectsController } from "../text-effects/text-effects.controller"
import ProductDriver from "../../drivers/product.driver"

export class TextOptionsTabController {
  @observable public textEffectsController?: TextEffectsController
  @observable public lineHeight = 1
  @observable public letterSpacing = 1

  public readonly lineHeightConfig = {
    min: 0.1,
    max: 3,
    step: 0.1,
  }
  public readonly letterSpacingConfig = {
    min: -30,
    max: 200,
    step: 1,
  }

  private readonly productDriver: ProductDriver
  private activeObjectController?: TextObjectController

  constructor(
    services: {
      productDriver: ProductDriver
      ee: AllEditorEventsEmitter
    },
    stores: {}
  ) {
    this.productDriver = services.productDriver

    makeObservable(this)

    services.ee.on(
      eventTree.activeObject.selected,
      this.initForActiveObject.bind(this)
    )
    services.ee.on(
      eventTree.activeObject.deselected,
      this.initForActiveObject.bind(this)
    )
  }

  @computed
  public get isEffectsSectionAvailable(): boolean {
    return this.productDriver.state.productRenderPilot.uiConfig.features
      .textEffects
  }

  @action
  public setLineHeight(lineHeight: number): void {
    this.lineHeight = Math.ceil(lineHeight * 100) / 100

    this.activeObjectController?.setStyles({
      lineHeight: this.lineHeight,
    })
  }

  @action
  public setLetterSpacing(letterSpacing: number): void {
    this.letterSpacing = letterSpacing

    this.activeObjectController?.setStyles({
      charSpacing: this.letterSpacing,
    })
  }

  @action
  private initForActiveObject(): void {
    const { activeObjectController } = this.productDriver.activeObjectDriver

    if (activeObjectController instanceof TextObjectController) {
      this.activeObjectController = activeObjectController
      this.textEffectsController = new TextEffectsController(
        activeObjectController
      )
      this.lineHeight = activeObjectController.getLineHeight()
      this.letterSpacing = activeObjectController.getCharSpacing()
    } else {
      this.activeObjectController = undefined
      this.textEffectsController = undefined
    }
  }
}
