import { CanvasEvent } from "../events/partials/domain.events"
import EventEmitter from "eventemitter3"
import { PackhelpObject } from "../render-engine/modules/vd-editor/object-extensions/packhelp-objects"
import {
  getDesignStructureObjectIds,
  getTempLayerIds,
} from "../render-engine/modules/vd-editor/types/render-engine.types"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"

export class ProductDesignModificationListener {
  private isEditModeEventListenersAttached = false

  constructor(
    // @TODO: We need to get rid of this and stay with AllEditorEventsEmitter only.
    private readonly productDriverEventEmitter: EventEmitter,
    private readonly ee: AllEditorEventsEmitter
  ) {}

  public attachEditModeEventListeners() {
    if (this.isEditModeEventListenersAttached) {
      return
    }

    this.productDriverEventEmitter
      .on(CanvasEvent.objectAdded, this.onDesignPotentiallyTouched)
      .on(CanvasEvent.objectRemoved, this.onDesignPotentiallyTouched)
      .on(CanvasEvent.objectModified, this.onDesignPotentiallyTouched)

    this.isEditModeEventListenersAttached = true
  }

  public clearEditModeEventListeners() {
    this.productDriverEventEmitter
      .off(CanvasEvent.objectAdded, this.onDesignPotentiallyTouched)
      .off(CanvasEvent.objectRemoved, this.onDesignPotentiallyTouched)
      .off(CanvasEvent.objectModified, this.onDesignPotentiallyTouched)

    this.isEditModeEventListenersAttached = false
  }

  private onDesignPotentiallyTouched = (object: PackhelpObject) => {
    const objectIdsToIgnore: string[] = [
      ...getTempLayerIds(),
      ...getDesignStructureObjectIds(),
    ]

    const isAddedToCanvas = !!object.canvas
    const isIgnored = objectIdsToIgnore.includes(object.id)

    if (isAddedToCanvas && !isIgnored) {
      this.ee.emit(eventTree.productDriver.touchDesign)
    }
  }
}
