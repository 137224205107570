import { action, observable, makeObservable } from "mobx"

export class AppUiController {
  @observable isMenuOpen = false
  @observable isMenuClosing = false

  constructor() {
    makeObservable(this)
  }

  public toggleMenu(): void {
    if (this.isMenuOpen) {
      this.setIsMenuClosing(true)
      setTimeout(() => this.setIsMenuOpen(false), 300)
    } else {
      this.setIsMenuClosing(false)
      this.setIsMenuOpen(true)
    }
  }

  @action
  private setIsMenuClosing(isClosing: boolean): void {
    this.isMenuClosing = isClosing
  }

  @action
  private setIsMenuOpen(isOpen: boolean): void {
    this.isMenuOpen = isOpen
  }
}
