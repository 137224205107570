import React from "react"
import { CloseButtonProps } from "../utils/modal.types"
import { CloseIcon } from "../../../../assets/_svgr/icons/Close"
import styles from "../Modal.module.scss"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export const CloseButton = (props: CloseButtonProps) => {
  const { onCloseAction, alignTop = true } = props

  return (
    <button
      className={cx("close-btn", { "close-btn--align-top": alignTop })}
      onClick={onCloseAction}
      e2e-target="button"
      e2e-target-name="close"
      type="button"
    >
      <CloseIcon className={styles.icon} />
    </button>
  )
}
