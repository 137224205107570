import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import changeIconUrl from "../../../../assets/_shape-icons/change.svg"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Modal } from "../Modal/Modal"
import { ModalSection } from "../ModalSection/ModalSection"

export const ModalChangeTemplateConfirmation = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.templates])

  if (!containerSet) {
    return null
  }

  const { templatesController } = containerSet.templates

  if (!templatesController) {
    return null
  }

  const { confirmationModalType } = templatesController

  if (!confirmationModalType) {
    return null
  }

  const i18n = I18N.modals.changeTemplate[confirmationModalType]

  const onClose = () => {
    templatesController.closeConfirmationModal()
  }

  const onConfirm = () => {
    templatesController.confirmChange()
  }

  return (
    <Modal
      title={t(i18n.areYouSure)}
      iconUrl={changeIconUrl}
      onClose={onClose}
      link={{ label: t(i18n.confirm), onClick: onConfirm }}
      center
    >
      <ModalSection>
        <p>{t(i18n.changesLost)}</p>
        <p>{t(i18n.elementsRemoved)}</p>
      </ModalSection>
    </Modal>
  )
})
