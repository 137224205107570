import { computed } from "mobx"
import ProductDriver from "../drivers/product.driver"
import {
  EditContext,
  SpaceId,
  ViewType,
} from "../libs/products-render-config/types"
import { ProductRenderPilot } from "../libs/products-render-config/product-render-pilot"
import { ProductDesignStore } from "../stores/product-design.store"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"

export class CameraControlsUiController {
  private readonly productDriver: ProductDriver
  private readonly uri: UrlManipulatorProvider
  private readonly productDesignStore: ProductDesignStore

  constructor(
    services: {
      productDriver: ProductDriver
      uri: UrlManipulatorProvider
    },
    stores: { productDesignStore: ProductDesignStore }
  ) {
    this.productDriver = services.productDriver
    this.uri = services.uri
    this.productDesignStore = stores.productDesignStore
  }

  @computed
  public get isAvailable(): boolean {
    return this.productRenderPilot.getEditorMode() !== "dby"
  }

  @computed
  public get isSpaceNameAvailable(): boolean {
    const is3dProduct = !!this.productDriver.state.renderEngine?.has3D

    return this.activeViewType === "space" && is3dProduct
  }

  @computed
  public get isEditContextSwitcherAvailable(): boolean {
    return this.editContexts.length > 1 && !this.isSpaceNameAvailable
  }

  @computed
  public get isViewTypeSwitcherAvailable(): boolean {
    return this.viewTypes.length > 1 && this.activeViewType !== "space"
  }

  @computed
  public get isSpaceZoomAvailable(): boolean {
    return (
      !this.productDesignStore.isDesignReadOnly &&
      this.activeViewType === "space" &&
      this.productRenderPilot.uiConfig.editZone.zoom.available
    )
  }

  @computed
  public get isSpaceSwitcherAvailable(): boolean {
    return (
      this.productRenderPilot.isFoldableProduct2D() && this.spaces.length > 1
    )
  }

  @computed
  public get isDielineZoomAvailable(): boolean {
    return (
      !this.productDesignStore.isDesignReadOnly &&
      this.activeViewType === "dieline"
    )
  }

  @computed
  public get isSpaceZoomActive(): boolean {
    return this.productDriver.state.isSpaceZoomActive
  }

  public async setIsSpaceZoomActive(isActive: boolean): Promise<void> {
    await this.productDriver.setIsSpaceZoomActive(isActive)
  }

  @computed
  public get dielineZoomPercentage(): string {
    return `${this.productDriver.state.dielineZoom * 100}%`
  }

  public async zoomDieline(value: number): Promise<void> {
    this.productDriver.zoomDieline(value)
  }

  @computed
  public get isDielineZoomOutAvailable(): boolean {
    return (
      this.productDriver.minDielineZoom < this.productDriver.state.dielineZoom
    )
  }

  @computed
  public get isDielineZoomInAvailable(): boolean {
    return (
      this.productDriver.maxDielineZoom > this.productDriver.state.dielineZoom
    )
  }

  @computed
  public get isDragModeAvailable(): boolean {
    return this.productDriver.state.activeViewType === "dieline"
  }

  @computed
  public get isDragModeActive(): boolean {
    return this.productDriver.state.isDragModeActive
  }

  public async toggleDragMode(): Promise<void> {
    await this.productDriver.toggleDragMode()
  }

  @computed
  public get editContexts(): EditContext[] {
    return this.productRenderPilot.getAvailableEditContexts()
  }

  @computed
  public get spaces(): SpaceId[] {
    return this.productRenderPilot.getAvailableSpaces(this.activeContext)
  }

  @computed
  public get activeContext(): EditContext {
    return this.productDriver.state.activeContext
  }

  @computed
  public get viewTypes(): ViewType[] {
    return this.productRenderPilot.getAvailableViewTypes()
  }

  @computed
  public get activeViewType(): ViewType {
    return this.productDriver.state.activeViewType
  }

  @computed
  public get activeSpace(): SpaceId | null {
    if (this.activeViewType !== "space") {
      return null
    }

    return this.productDriver.state.activeSpace
  }

  public async changeToDefaultEditContext(): Promise<void> {
    await this.productDriver.setView(
      this.activeViewType,
      this.productRenderPilot.getDefaultEditContext(),
      this.activeSpace
    )
  }

  public async changeEditContext(editContext: EditContext): Promise<void> {
    await this.productDriver.setView(
      this.activeViewType,
      editContext,
      this.activeSpace
    )
  }

  public async changeSpace(spaceId: SpaceId): Promise<void> {
    await this.productDriver.setView(
      this.activeViewType,
      this.activeContext,
      spaceId
    )
  }

  public async changeViewType(viewType: ViewType): Promise<void> {
    await this.productDriver.setView(
      viewType,
      this.activeContext,
      this.activeSpace
    )
  }

  @computed
  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }
}
