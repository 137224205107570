import React, { PropsWithChildren } from "react"

import { ModalFooter } from "../partials/Footer"
import { ModalHeader } from "../partials/Header"
import { ModalLayout } from "../partials/Layout"
import { ModalTemplateWithEnclosureProps } from "../utils/modal.types"

export const ModalTemplateWithEnclosure = ({
  children,
  footer,
  header,
  layoutProps,
  onCloseAction,
  withBorderedHeader = true
}: PropsWithChildren<ModalTemplateWithEnclosureProps>) => (
  <ModalLayout {...layoutProps}>
    <ModalHeader
      onCloseAction={onCloseAction}
      withBorder={withBorderedHeader}
      paddingBottom={Boolean(header) ? 24 : 0}
    >
      {header}
    </ModalHeader>
    {children}
    {Boolean(footer) && <ModalFooter>{footer}</ModalFooter>}
  </ModalLayout>
)
