import {
  AvailableColourModes,
  CameraSettings,
  ColoursPreset,
  DbyDielineType,
  EditableSpaceConfig,
  EditContext,
  EditorMode,
  ModelContext,
  SpaceId,
  PantoneColorsPreset,
  RenderConfig,
  RotationMap,
  SpaceDimensions,
  TextureDefinition,
  UIConfig,
  ViewMode,
  ViewType,
  HexColorsPreset,
} from "./types"
import { DesignVersion } from "../../types/design.version"
import { TexturesController } from "./controllers/textures-controller"
import { RenderConfigReader } from "./controllers/render-config-reader"
import { loadProductConfig } from "./helpers"
import {
  AvailableProperty,
  AvailableSize,
  ProductManager,
} from "@ph/product-api"
import { AvailableRotations } from "../../render-engine/modules/vd-editor/modules/dieline-navigator/calculators/translation.calculator"

export abstract class ProductRenderPilot {
  public texturesController: TexturesController
  public renderConfig: RenderConfig
  public configReader: RenderConfigReader
  public isAfterPurchaseEdit = false

  public abstract setAfterPurchaseEdit(isAfterPurchaseEdit: boolean)

  protected constructor(
    protected readonly product: ProductManager,
    protected readonly designVersion: DesignVersion,
    protected readonly editorMode: EditorMode,
    renderConfigMap,
    public uiConfig: UIConfig
  ) {
    this.renderConfig = loadProductConfig(this.product, renderConfigMap)

    this.texturesController = new TexturesController(
      this.product,
      this.renderConfig
    )

    this.configReader = new RenderConfigReader(this.renderConfig)
  }

  public getModelContextByEditContext(editContext: EditContext): ModelContext {
    const modelContext =
      editContext === EditContext.INSIDE
        ? ModelContext.OPENED
        : ModelContext.CLOSED

    const hasObjModel = this.hasObjModel()

    if (!hasObjModel || this.hasObjModel(modelContext)) {
      return modelContext
    }

    return this.getDefaultModelContext()
  }

  public getAvailableSizes(): AvailableSize[] {
    if (!this.product) {
      return []
    }

    return this.product.variantManager.getAvailableSizesForProduct()
  }

  public getProduct(): ProductManager {
    return this.product
  }

  public getProductVariantName(): string {
    return this.product.variantManager.getVariant()
  }

  public getConfig(): RenderConfig {
    return this.renderConfig
  }

  public isViewModeAvailable(viewMode: ViewMode): boolean {
    return this.getAvailableViewModes().includes(viewMode)
  }

  public getAvailableViewModes(): ViewMode[] {
    return this.configReader.getAvailableViewModes()
  }

  public getColoursPreset(): ColoursPreset {
    return {
      mode: AvailableColourModes.FULL_COLOR,
      colourSettings: {
        blendFactor: 1,
      },
    }
  }

  public getColorMode(): AvailableColourModes {
    return this.getColoursPreset().mode
  }

  public getHexColorsPreset(): undefined | HexColorsPreset {
    return undefined
  }

  public getPantoneColorsPreset(): undefined | PantoneColorsPreset {
    return undefined
  }

  public getRotationMap(editContext: EditContext): RotationMap {
    return {}
  }

  public getVirtualDielineTexture(
    editContext: EditContext,
    isSpaceZoomActive: boolean
  ): TextureDefinition | undefined {
    return this.texturesController.getVirtualDielineTexture(
      editContext,
      isSpaceZoomActive
    )
  }

  public getThumbCameraSettings(
    modelContext: ModelContext
  ): CameraSettings | undefined {
    return this.configReader.getThumbCameraSettings(modelContext)
  }

  public getCameraSettings(modelContext: ModelContext) {
    return this.configReader.getCameraSettings(modelContext)
  }

  public getObjModelPath(modelContext: ModelContext): string {
    const modelPath = this.configReader.getObjModelPath(modelContext)

    if (!modelPath) {
      throw new Error(`No model path for ${modelContext}`)
    }

    return modelPath
  }

  public getObjModelTextures(modelContext: ModelContext) {
    return this.texturesController!.getObjModelTextures(modelContext)
  }

  public isModelOpenable(): boolean {
    if (this.hasObjModel()) {
      return [ModelContext.CLOSED, ModelContext.OPENED].every((context) =>
        this.configReader.hasObjModel(context)
      )
    }

    return true
  }

  public isSpaceEditable(editContext: EditContext, spaceId: SpaceId): boolean {
    for (const config of this.getContextEditableSpaces(editContext)) {
      if (config.spaceId === spaceId) {
        return !!config.editable
      }
    }

    return false
  }

  public getContextEditableSpaces(
    editContext: EditContext
  ): EditableSpaceConfig[] {
    if (!this.isPrintAvailableFor(editContext)) {
      return []
    }

    return this.configReader.getContextEditableSpaceConfigs(editContext)
  }

  public getDefaultSpace(editContext: EditContext): SpaceId {
    return this.configReader.getDefaultSpace(editContext)
  }

  public getDefaultEditContext() {
    return this.configReader.getDefaultEditContext()
  }

  public getDefaultViewType(): ViewType {
    const viewTypes = this.getAvailableViewTypes()

    if (viewTypes.includes(ViewType.DBY)) {
      return ViewType.DBY
    }

    if (viewTypes.includes(ViewType.MODEL)) {
      return ViewType.MODEL
    }

    if (viewTypes.includes(ViewType.DIELINE)) {
      return ViewType.DIELINE
    }

    return ViewType.SPACE
  }

  public getAvailableViewTypes(): ViewType[] {
    if (this.isDbyOnly()) {
      return [ViewType.DBY]
    }

    const viewTypes: ViewType[] = []

    if (this.isModelViewAvailable()) {
      viewTypes.push(ViewType.MODEL)
    }

    if (this.isDielineViewAvailable(this.getDefaultEditContext())) {
      viewTypes.push(ViewType.DIELINE)
    }

    return viewTypes
  }

  public isModelViewAvailable(): boolean {
    return this.isViewModeAvailable(ViewMode.THREE_DIMENSIONAL)
  }

  public isDielineViewAvailable(editContext: EditContext): boolean {
    if (!this.is3DProduct()) {
      return false
    }

    return this.getAvailableSpaces(editContext).length > 1
  }

  public getDefaultView(): {
    viewType: ViewType
    editContext: EditContext
    spaceId: SpaceId | null
  } {
    const defaultEditContext = this.getDefaultEditContext()

    return {
      viewType: this.getDefaultViewType(),
      editContext: defaultEditContext,
      spaceId: this.isDbyOnly()
        ? null
        : this.getDefaultSpace(defaultEditContext),
    }
  }

  public getSpaceConfig(
    editContext: EditContext,
    spaceId: SpaceId
  ): EditableSpaceConfig {
    return this.configReader.getSpaceConfig(editContext, spaceId)
  }

  public getAvailableEditContexts() {
    const editContexts = this.configReader.getAvailableEditContexts()

    if (!this.isAfterPurchaseEdit) {
      return editContexts
    }

    return editContexts.filter((editContext) =>
      this.isPrintActiveFor(editContext)
    )
  }

  public getAvailableSpaces(editContext: EditContext): SpaceId[] {
    if (editContext === EditContext.FRONT) {
      return [SpaceId.DEFAULT]
    }

    return []
  }

  public getSceneBackgroundTextureUrl(editContext: EditContext) {
    return this.texturesController!.getSceneBackgroundTextureUrl(editContext)
  }

  public getEditorMode(): EditorMode {
    return this.editorMode
  }

  public isDbyMode(): boolean {
    return this.editorMode === "dby"
  }

  public getBackgroundHexColorsPreset(): undefined | HexColorsPreset {
    return undefined
  }

  public getBackgroundPantoneColorsPreset(): undefined | PantoneColorsPreset {
    return undefined
  }

  public getContextSpaces(editContext: EditContext) {
    return this.configReader.getContextSpaceConfigs(editContext)
  }

  public isBackgroundAssetToggleAvailableForSpace(
    editContext: EditContext
  ): boolean {
    return this.configReader.getContextEditableSpaceIds(editContext).length > 1
  }

  public isPrintActiveFor(editContext: EditContext) {
    if (editContext === EditContext.FRONT) {
      return true
    }

    return false
  }

  public isPrintAvailableFor(editContext: EditContext) {
    return [EditContext.FRONT, EditContext.BACK].includes(editContext)
  }

  public isPrintAdditionallyPaidFor(editContext: EditContext) {
    return editContext === EditContext.BACK
  }

  public getAdditionallyPaidEditContext(): EditContext | undefined {
    return this.getAvailableEditContexts().find((editContext) =>
      this.isPrintAdditionallyPaidFor(editContext)
    )
  }

  public is3DProduct(): boolean {
    return false
  }

  public hasObjModel(modelContext?: ModelContext): boolean {
    const modelViewAvailable = this.renderConfig.availableViewModes.includes(
      ViewMode.THREE_DIMENSIONAL
    )
    const modelAvailable = this.configReader.hasObjModel(
      modelContext || this.getDefaultModelContext()
    )

    return modelViewAvailable && modelAvailable
  }

  public getDefaultModelContext(): ModelContext {
    return ModelContext.CLOSED
  }

  public getAvailablePrintingModes(): AvailableProperty[] {
    return []
  }

  public getSpaceDimensions(
    editContext: EditContext,
    spaceId: SpaceId
  ): SpaceDimensions {
    return {
      widthPx: 0,
      heightPx: 0,
      widthCm: 0,
      heightCm: 0,
    }
  }

  public isEditContextDisabled(editContext: EditContext): boolean {
    return (
      !this.isPrintAvailableFor(editContext) ||
      !this.isPrintActiveFor(editContext)
    )
  }

  public getPredefinedVirtualDielineUrl(
    editContext: EditContext
  ): string | undefined {
    return this.renderConfig.virtualDielinesPaths?.[editContext]
  }

  public getPredefinedDbyDielineUrl(
    type: DbyDielineType,
    region = "eu"
  ): string | undefined {
    return
  }

  public isDbyOnly(): boolean {
    return false
  }

  protected get sku(): string {
    return this.product.variantManager.getSku()
  }

  public isFoldableProduct2D(): boolean {
    return (
      !this.is3DProduct() &&
      this.getAvailableEditContexts().includes(EditContext.OUTSIDE)
    )
  }

  public getDielineRotation(_editContext: EditContext): AvailableRotations {
    return AvailableRotations.none
  }
}
