import { AbstractLayoutProcessor } from "./abstract-layout.processor"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"

/**
 * Grid layout
 *
 * [@] [@] [@] [@]
 * [@] [@] [@] [@]
 * [@] [@] [@] [@]
 * [@] [@] [@] [@]
 */
export class GridLayoutProcessor extends AbstractLayoutProcessor {
  private objectToProcess?: PackhelpObject

  protected async prepareReplicableObjectToProcess() {
    this.objectToProcess = await this.cloneObject(this.replicableObject)
    this.shouldPrepareToProcess = false
  }

  public async process() {
    if (this.shouldPrepareToProcess) {
      await this.prepareReplicableObjectToProcess()
    }

    if (!this.objectToProcess) {
      return
    }

    const width = (this.space.width - this.safeZoneSizeSum) / this.config.cols
    const height = (this.space.height - this.safeZoneSizeSum) / this.config.rows
    const centerPoint = new fabric.Point(width / 2, height / 2)

    this.patternSourceCanvas.setDimensions({
      width,
      height,
    })

    this.scaleObject(this.objectToProcess)
    this.moveObject(this.objectToProcess, centerPoint)
    this.rotateObject(this.objectToProcess, centerPoint)

    this.patternSourceCanvas.clear()
    this.patternSourceCanvas.add(this.objectToProcess)

    this.patternSourceCanvas.renderAll()
  }
}
