import { CardType, HeightType } from "dsl/src/atoms/Card/Card"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"
import { Modal, ModalTopSpacingType } from "dsl/src/organisms/Modal/modal/Modal"
import { ModalTemplateFlex } from "dsl/src/organisms/Modal/modal/templates/ModalTemplateFlex"
import { ModalTemplateWithEnclosure } from "dsl/src/organisms/Modal/modal/templates/ModalTemplateWithEnclosure"
import React from "react"
import { useTranslate } from "../../../../shared-components/i18n/useTranslate"
import { i18nId } from "../utils/translations"
import styles from "./ShippingDestinationModal.module.scss"

interface ShippingDestinationModalProps {
  children: React.ReactNode
  isMobile: boolean
  isModalOpen: boolean
  onClose: () => void
}

export const ShippingDestinationModal = (
  props: ShippingDestinationModalProps
) => {
  const { children, isMobile, isModalOpen, onClose } = props
  const translate = useTranslate()

  if (isMobile) {
    return (
      <Modal
        open={isModalOpen}
        cardProps={{
          fluid: true,
          type: CardType.RoundedTop,
          mobileType: CardType.RoundedTop,
        }}
        topSpacing={ModalTopSpacingType.Large}
        onCloseAction={onClose}
      >
        <ModalTemplateFlex
          header={translate(i18nId.Delivery)}
          onCloseAction={onClose}
        >
          <div className={styles.outer} e2e-target="shipping-destination-modal">
            {children}
          </div>
        </ModalTemplateFlex>
      </Modal>
    )
  }

  return (
    <Modal
      open={isModalOpen}
      onCloseAction={onClose}
      cardProps={{
        type: CardType.Round16,
        heightType: HeightType.Auto,
        noBorder: true,
      }}
    >
      <ModalTemplateWithEnclosure
        onCloseAction={onClose}
        layoutProps={{
          size: "sm",
        }}
        header={
          <Typography type={TType.Header32_400}>
            {translate(i18nId.Delivery)}
          </Typography>
        }
        withBorderedHeader={false}
      >
        <div e2e-target="shipping-destination-modal">{children}</div>
      </ModalTemplateWithEnclosure>
    </Modal>
  )
}
