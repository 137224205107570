import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"

export type AuthSources = "cart" | "sample-pack" | "auth" | "qr" | "editor"

type RedirectRes = {
  shouldRedirect: boolean
  redirectUri: string
}

enum QS {
  redirectUri = "redirect_url",
  token = "token",
  source = "source",
  value = "value",
  currency = "currency",
  email = "email",
}

function assertUri(uri: URL) {
  if (!(uri instanceof URL)) {
    throw new Error("Provided URL is not an instace of a URL")
  }
}

/**
 * Append a redirection URL.
 * If `redirect_url` param in query string is detected, these helpers, provide logic for the flow
 * "httpa://a.com/a" +
 * "http://b.com" =
 * "httpa://a.com/a?redirect_url=http%3A%2F%2Fb.com"
 */
export function appendRedirectParam(
  signInUri: string,
  callbackUri: string
): string {
  const uri = new URL(signInUri)
  if (uri.searchParams.has(QS.redirectUri)) {
    throw new Error("Provided URL already has a callback: " + uri.href)
  }

  uri.searchParams.append(QS.redirectUri, callbackUri)
  return uri.href
}

export function appendSegmentationParams(
  uri: string,
  source: AuthSources,
  value: string,
  currency: string,
  email: string
): string {
  const newUri = new URL(uri)
  newUri.searchParams.append(QS.source, source)
  newUri.searchParams.append(QS.value, value)
  newUri.searchParams.append(QS.currency, currency)
  newUri.searchParams.append(QS.email, email)
  return newUri.href
}

export function getRedirectParams(uri: URL): RedirectRes {
  assertUri(uri)

  if (uri.searchParams.has(QS.redirectUri)) {
    // Check if only one URL is present
    const all = uri.searchParams.getAll(QS.redirectUri)
    if (all.length > 1) {
      throw new Error("Only one redirect URL is allowed!")
    }

    return {
      shouldRedirect: true,
      redirectUri: String(uri.searchParams.get(QS.redirectUri)),
    }
  } else {
    return {
      shouldRedirect: false,
      redirectUri: "",
    }
  }
}

export function removeRedirectParam(uri: URL) {
  assertUri(uri)

  if (uri.searchParams.has(QS.redirectUri)) {
    uri.searchParams.delete(QS.redirectUri)
  }
  return uri
}

export function addTokenToUri(uri: URL, token: string): URL {
  assertUri(uri)

  if (uri.searchParams.has(QS.token)) {
    throw new Error("Should not provide redirect with a token a present")
  }

  const newUri = new URL(uri.href)
  newUri.searchParams.append(QS.token, token)

  return newUri
}

type RedirectWithQsParams = {
  token?: string
  customRedirectUrl?: string
}

/**
 * Return the redirect url using query string params
 * @param customRedirectUrl fallback for when no redirect_url provided, if neither is present, the user is redirected to homepage
 * @param token spree token kept in the url after redirection (possibly not used anymore)
 */
export const getRedirectUrlWithQsParams = (params?: RedirectWithQsParams) => {
  const { token, customRedirectUrl } = params || {}

  let redirectUrl = ""

  const searchParams = new URL(window.location.href).searchParams
  const redirectUrlFromQueryString = searchParams.get(QS.redirectUri)

  if (redirectUrlFromQueryString) {
    redirectUrl = redirectUrlFromQueryString
  } else if (customRedirectUrl) {
    redirectUrl = customRedirectUrl
  } else {
    const homepageUrl = RegionConfig.getHomePageUrlForRegion()
    redirectUrl = homepageUrl
  }

  if (token) {
    const enhancedRedirectURL = addTokenToUri(new URL(redirectUrl), token)
    redirectUrl = enhancedRedirectURL.href
  }

  return redirectUrl
}

/**
 * Redirect the user using query string params
 * @param customRedirectUrl fallback for when no redirect_url provided, if neither is present, the user is redirected to homepage
 * @param token spree token kept in the url after redirection (possibly not used anymore)
 */
export const redirectWithQsParams = (params?: RedirectWithQsParams) => {
  const redirectUrl = getRedirectUrlWithQsParams(params)
  window.location.href = redirectUrl
}
