import React, { useLayoutEffect } from "react"
import { Curtain } from "./Curtain/Curtain"
import useTitle from "../hooks/useTitle"
import { useTranslate } from "../hooks/useTranslate"
import { Orchestra } from "./Orchestra/Orchestra"
import { Proscenium } from "./Proscenium/Proscenium"
import { useContainerSet } from "../../_containers-react/_editor-app-hooks"
import { useIsDeviceTooSmallForEditor } from "product-configurator-light/src/libs/hooks/use-is-device-too-small-for-editor"
import { ModalProvider } from "../dsl/organisms/modals/ModalProvider/ModalProvider"
import { ErrorScreenSizeNotSupported } from "./errors/ErrorScreenSizeNotSupported/ErrorScreenSizeNotSupported"

export function Theater() {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.ecommerce,
  ])

  useTitle(t("editor.document.title"))

  const isDeviceTooSmallForEditor = useIsDeviceTooSmallForEditor()
  const isInstantPurchase = containerSet?.ecommerce.cartStore?.isInstantPurchase
  const isSizeErrorVisible = isDeviceTooSmallForEditor && !isInstantPurchase

  useLayoutEffect(() => {
    if (isSizeErrorVisible) {
      containerSet?.designAndProductDriver?.productDriver.onDeregisterMountPoints()
    }
  }, [isSizeErrorVisible])

  return (
    <ModalProvider>
      {isSizeErrorVisible ? (
        <ErrorScreenSizeNotSupported />
      ) : (
        <Curtain>
          <Orchestra />
          <Proscenium />
        </Curtain>
      )}
    </ModalProvider>
  )
}
