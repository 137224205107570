import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { VariantCustomization } from "@ph/product-api"
import { OptionPickerList } from "../../../atoms/OptionPicker/OptionPickerList"
import { OptionPicker } from "../../../atoms/OptionPicker/OptionPicker"
import { CustomSizeOptionPicker } from "./partials/CustomSizeOptionPicker"

export const TabSize = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { sizeTabController } = ui
  const {
    availableSizes,
    isCustomSizeEnabled,
    currentSku,
    isAnyActionDisabled,
    isSizeCustom,
  } = sizeTabController

  const onBackClick = () => {
    sizeTabController.openConfigurationTab()
  }

  const onSizeClick = (sku: string, customization?: VariantCustomization) => {
    sizeTabController.changeSize(sku, customization)
  }

  return (
    <Tab
      title={t(I18N.tabs.size)}
      backLink={{ label: t(I18N.tabs.configuration), onClick: onBackClick }}
      e2eTargetName="size"
    >
      <OptionPickerList type="vertical">
        {isCustomSizeEnabled && <CustomSizeOptionPicker />}

        {availableSizes.map((size) => {
          return (
            <OptionPicker
              key={size.sku}
              label={size.productVariantDimensions}
              onClick={() => onSizeClick(size.sku)}
              active={!isSizeCustom && currentSku === size.sku}
              disabled={isAnyActionDisabled}
              analyticsSection="left-tab-configuration"
              analyticsType="option-picker"
              analyticsName="size"
              analyticsId={size.label}
              e2eTarget="button"
              e2eTargetName="size"
            />
          )
        })}
      </OptionPickerList>
    </Tab>
  )
})
