import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { ToolbarSpace } from "../ToolbarSpace/ToolbarSpace"
import { ToolbarAsset } from "../ToolbarAsset/ToolbarAsset"
import styles from "./ToolbarEditor.module.scss"

export const ToolbarEditor = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { editorToolbarController, spaceToolbarController } = containerSet.ui
  const { activeToolbar } = editorToolbarController
  const { isToolbarVisible: isSpaceToolbarAvailable } = spaceToolbarController

  const isSpaceToolbarVisible =
    activeToolbar === "space" && isSpaceToolbarAvailable
  const isAssetToolbarVisible = activeToolbar === "asset"

  if (!isSpaceToolbarVisible && !isAssetToolbarVisible) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      {isSpaceToolbarVisible && <ToolbarSpace />}
      {isAssetToolbarVisible && <ToolbarAsset />}
    </div>
  )
})
