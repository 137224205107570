import { computed, makeObservable, observable, action } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import {
  AvailableSize,
  CustomSizeConfig,
  CustomSizeDimensionValidationResult,
  CustomSizeValidationResult,
  CustomSizeValidator,
  ProductManager,
  VariantCustomization,
} from "@ph/product-api"
import { SidebarStore } from "../../stores/sidebar.store"
import { ChangeProductController } from "../change-product-controller/change-product.controller"
import { EcommerceStore } from "../../stores/ecommerce.store"
import _omitBy from "lodash/omitBy"
import _isNil from "lodash/isNil"

type CustomSize = Record<string, number>

export class SizeTabController {
  @observable public customSize: CustomSize = {}
  @observable public isCustomSizeFormVisible = false
  @observable public isCustomSizeFormTouched = false

  private readonly productDriver: ProductDriver
  private readonly changeProductController: ChangeProductController
  private readonly sidebarStore: SidebarStore
  private readonly ecommerceStore?: EcommerceStore

  constructor(
    services: {
      productDriver: ProductDriver
      changeProductController: ChangeProductController
    },
    stores: {
      sidebarStore: SidebarStore
      ecommerceStore?: EcommerceStore
    }
  ) {
    this.productDriver = services.productDriver
    this.changeProductController = services.changeProductController
    this.sidebarStore = stores.sidebarStore
    this.ecommerceStore = stores.ecommerceStore

    makeObservable(this)

    this.init()
  }

  public openConfigurationTab(): void {
    this.sidebarStore.selectTab("configuration")
  }

  public async changeSize(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    if (!customization) {
      this.setCustomSize({})
      this.setIsCustomSizeFormVisible(false)
      this.setIsCustomSizeFormTouched(false)
    }

    return this.changeProductController.changeProduct(sku, customization)
  }

  @action
  public setCustomSize(customSize: CustomSize): void {
    this.customSize = customSize
  }

  @action
  public setIsCustomSizeFormVisible(isVisible: boolean): void {
    this.isCustomSizeFormVisible = isVisible
  }

  @action
  public setIsCustomSizeFormTouched(isTouched: boolean): void {
    this.isCustomSizeFormTouched = isTouched
  }

  @computed
  public get isSizeCustom(): boolean {
    const { size } = this.product.getDefaultVariant()

    return size.isCustom
  }

  @computed
  public get isDynamicVariantLoading(): boolean {
    return !!this.ecommerceStore?.isDynamicVariantLoading
  }

  @computed
  public get dynamicVariantError(): string | undefined {
    return this.ecommerceStore?.dynamicVariantError
  }

  @computed
  public get isAnyActionDisabled(): boolean {
    return this.changeProductController.isSelectingDisabled
  }

  @computed
  public get availableSizes(): AvailableSize[] {
    return this.productRenderPilot
      .getAvailableSizes()
      .filter((size) => !size.isCustom)
  }

  @computed
  public get isCustomSizeEnabled(): boolean {
    return this.productDriver.productStore.isCustomSizeEnabled
  }

  @computed
  public get isCustomSizeApplyingDisabled(): boolean {
    return (
      this.isCustomSizeIncorrect ||
      !!this.customSizeValidationResult?.generic.length ||
      this.isSameSize ||
      this.isAnyActionDisabled ||
      this.isDynamicVariantLoading
    )
  }

  @computed
  public get currentSku(): string {
    return this.product.variantManager.getSku()
  }

  @computed
  public get product(): ProductManager {
    return this.productDriver.productStore.product
  }

  @computed
  public get customSizeValidationResult():
    | CustomSizeValidationResult
    | undefined {
    return this.customSizeValidator?.call(this.customSize)
  }

  @computed
  public get customSizeConfig(): CustomSizeConfig | undefined {
    return this.product.getCustomSizeConfig()
  }

  @computed
  private get isSameSize(): boolean {
    const { size } = this.product.getDefaultVariant()

    return Object.entries(this.customSize).every(
      ([key, value]) => size?.[key] === value
    )
  }

  @computed
  private get isCustomSizeIncorrect(): boolean {
    return Object.values<CustomSizeDimensionValidationResult>(
      this.customSizeValidationResult?.dimensions || {}
    ).some((validation) => {
      return !validation.present || !validation.min || !validation.max
    })
  }

  @computed
  private get customSizeValidator(): CustomSizeValidator | undefined {
    if (!this.customSizeConfig) {
      return
    }

    return new CustomSizeValidator(this.customSizeConfig)
  }

  @computed
  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  private init(): void {
    const { size } = this.product.getDefaultVariant()

    if (!size.isCustom) {
      return
    }

    const { width, height, length } = size

    this.setCustomSize(
      _omitBy(
        {
          width,
          height,
          length,
        },
        _isNil
      ) as CustomSize
    )

    this.setIsCustomSizeFormVisible(true)
  }
}
