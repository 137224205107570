import { ApiStudio } from "@ph/api-studio"
import RegionConfig from "../../../../../configs/region-config/interfaces/js/region-config"
import { getApiTokenFromCookieWithFallback } from "../../../../libs/helpers/api-token.helpers"
import {
  ShippingDestinationStore,
  ShippingDestinationApiServiceInterface,
} from "./shipping-destination.store"

const getApiService =
  async (): Promise<ShippingDestinationApiServiceInterface> => {
    const region = RegionConfig.getCurrentRegion()
    const apiTokenFromCookie = await getApiTokenFromCookieWithFallback()
    const axiosConfig = {
      baseURL: `${RegionConfig.getEditorSharedLink("")}/api`,
      headers: { "X-Packhelp-Region": region },
    }

    const apiStudio = new ApiStudio({
      token: apiTokenFromCookie,
      axios: axiosConfig,
    })

    return {
      user: {
        getUserMe: async () => {
          const user = await apiStudio.user.getUserMe()
          return { shipping_destination: user.shipping_destination }
        },
        saveShippingDestination: apiStudio.user.saveShippingDestination,
      },
    }
  }

export async function provideShippingDestinationStore(
  apiService?: ShippingDestinationApiServiceInterface
): Promise<ShippingDestinationStore> {
  const shippingDestinationApiService = apiService || (await getApiService())

  return await ShippingDestinationStore.init(shippingDestinationApiService)
}
