import { PredefinedTextAssetDto } from "../../../../../../types/predefined-asset.types"
import { PredefinedText } from "../../../../../../models/predefined-text"
import { SpaceId } from "../../../../../../libs/products-render-config/types"
import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { JsonToFabricTransformer } from "../transformers/json-to-fabric.transformer"
import { v4 as uuidv4 } from "uuid"
import { VirtualDielineObject } from "../../../../../../types/asset.types"
import { EditableGroupCreator } from "../editable-group-controller/editable-group.creator"
import { ScaleHelper } from "../helpers/scale.helper"
import { ColorHelper } from "../helpers/color.helper"
import { Creator } from "./creator"

export class PredefinedTextCreator extends Creator {
  public async create(
    asset: PredefinedTextAssetDto,
    predefinedText: PredefinedText,
    spaceId: SpaceId
  ): Promise<PackhelpEditableObject> {
    const object = await this.transformJsonToPackhelpObject(
      predefinedText,
      asset.objects,
      spaceId
    )

    this.scaleObject(object, asset, spaceId)
    this.alignObject(object)
    this.rotateObject(object, spaceId)
    await this.setObjectSpaceClipping(object, spaceId)

    return object
  }

  private scaleObject(
    object: PackhelpEditableObject,
    asset: PredefinedTextAssetDto,
    spaceId: SpaceId
  ): void {
    ScaleHelper.scaleObjectToSpace(
      this.vdEditor,
      object,
      asset.canvasDimensions,
      spaceId
    )
  }

  private async transformJsonToPackhelpObject(
    predefinedText: PredefinedText,
    predefinedTextObjects: VirtualDielineObject[],
    spaceId: SpaceId
  ): Promise<PackhelpEditableObject> {
    const objects =
      await JsonToFabricTransformer.transform<PackhelpEditableObject>(
        predefinedTextObjects
      )
    this.postTransformProcess(predefinedText, objects, spaceId)

    if (objects.length === 1) {
      return objects[0]
    }

    const groupCreator = new EditableGroupCreator(this.vdEditor)
    return await groupCreator.create(spaceId, objects)
  }

  private postTransformProcess(
    predefinedText: PredefinedText,
    objects: PackhelpEditableObject[],
    spaceId: SpaceId
  ) {
    for (const object of objects) {
      object.originSpaceArea = spaceId
      object.id = uuidv4()
      object.predefinedTextId = predefinedText.id
      object.isSpaceClippingDisabled =
        this.vdEditor.isSpaceClippingTogglingAvailable()

      ColorHelper.applyDefaultColor(this.vdEditor, object)
    }
  }
}
