import { AxiosInstance } from "axios"
import { DirectusPattern } from "../types"

export class DirectusPatternApi {
  constructor(private ax: AxiosInstance) {}

  public async getPatterns(): Promise<DirectusPattern[]> {
    const response = await this.ax.get<{ data: DirectusPattern[] }>(
      "patterns",
      {
        params: {
          limit: -1,
        },
      }
    )

    return response.data.data
  }
}
