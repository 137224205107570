import EuRegion from "./eu"
import * as shared from "../shared/shared-consts"
import {
  DESIGNLESS_PRODUCTS,
  EU_ALLOWED_PRODUCT_TYPES,
  REGION_SWITCHER_ALLOWED_COUNTRIES,
} from "../shared/shared-consts"

const Region = {
  ...EuRegion,

  languageTag: "en-US",
  iterableLocale: "en-US",
  locale: "en",
  phoneCountryCode: "+40",
  defaultShippingCountryCode: "US",
  mainDomain: shared.EU_MAIN_DOMAIN,
  hasVat: false,
  secondaryAnalyticsCode: "UA-71580330-xx", // @TODO replace xx with code provided by @pasko
  productRegion: "us",
  countryCode: "us",
  emailAddress: "hello@packhelp.com",
  emailSenderName: "Packhelp.com",
  phoneNumber: "",

  logoSVGIcon: "v3-packhelp_color_on_white",
  logoSVGIconWhite: "v3-packhelp_white_logo_on_black",

  additionalCustoms: 0,

  pageTitle: "Editor - Packhelp.com/en-us/",
  footerLabel: "Packhelp",
  frontendBoxDesignRoute: "box/design",
  frontendAccountOrdersRoute: "account/orders",

  frontendTermsOfServiceRoute: "/terms_of_service/", // english is on purpose
  frontendPrivacyPolicyRoute: "/privacy_policy/", // english is on purpose

  domain: {
    basename: "packhelp",
    tld: "com",
    pathBased: true,
    pathRegion: "/en-us",
  },

  outsidePackhelpDomainLinks: {
    ...shared.externalDomainPackhelpLinks,
  },

  // Work in progress
  allSupportedCountries: ["US"],
  partiallySupportedCountries: [],
  regionSwitcherAllowedCountries: REGION_SWITCHER_ALLOWED_COUNTRIES,
  allowedCountriesOutsideEu: [],
  allowedProductTypes: [],
  allowedShippingCountries: ["US"],
  offerExtraAvailableCountries: [],
  allowedShipments: ["US"],
  deliveryTime: {
    compartmentDeliveryTime: "3-5",
    avarageDeliveryTime: "5",
    samplePackAverageDeliveryTime: "3",
  },
  allowedShipmentTypes: ["shipping-method-courier"],
  allowedPaymentMethods: ["stripe", "card"],
  designlessProductIds: DESIGNLESS_PRODUCTS,
  shipmentsConfig: {
    allowedShipments: {
      "shipping-method-courier": [
        "shipping-method-courier-free",
        "shipping-method-sample-packs-eu4",
      ],
    },
    deliveryTime: {
      compartmentDeliveryTime: "1-2",
      avarageDeliveryTime: "5",
      samplePackAverageDeliveryTime: "5",
    },
  },

  ...shared.SHARED_CURRENCY_PROPERTIES.usd,

  remindMeOnDesktop: {
    // @TODO pasko replace xxxxxx with valid numer / or remove - waiting for decision
    remindme_form_url:
      "//packhelp.us12.list-manage.com/subscribe/post-json?u=2060c1de14ec30d28a9fab813&amp;id=xxxxxx&c=?",
    remindme_hidden_input_name: "b_2060c1de14ec30d28a9fab813_xxxxxx",
  },

  datesFormattingRules: shared.DATES_FORMATTING_RULES_US,

  googleConversionId: "859901857",
  googleAnalytics: {
    primaryCode: "UA-71580330-2",
    secondaryCode: "UA-71580330-xx", // @TODO replace xx with code provided by @pasko
    segmentCode: "uXDomf9GW2BCffFseKvLrarxNiGNLI5O",
  },
  googleAdWords: {
    remarketingCode: "xxxxx", // @TODO replace xx with code provided by @pasko
    conversionCode: "xxxxxxxxx", // @TODO replace xx with code provided by @pasko
  },

  facebookAppId: "1660833110841347",
  supportsPlus: false,
}

export default Region
