import React from "react"
import { Checkbox } from "dsl/src/atoms/Checkbox/Checkbox"
import { FormControlLabel } from "dsl/src/atoms/FormControlLabel/FormControlLabel"
import styles from "./ToolbarCheckbox.module.scss"
import { AnalyticsProps, TestsProps } from "../../types"

interface ToolbarCheckboxProps extends AnalyticsProps, TestsProps {
  onChange: () => void
  checked?: boolean
  label: string
}

export function ToolbarCheckbox({
  onChange,
  checked = false,
  label,
  analyticsSection = "design-toolbar",
  analyticsType = "checkbox",
  analyticsName,
  analyticsId,
  e2eTarget = "checkbox",
  e2eTargetName,
}: ToolbarCheckboxProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          analytics-section={analyticsSection}
          analytics-type={analyticsType}
          analytics-name={analyticsName}
          analytics-id={analyticsId}
          e2eTarget={e2eTarget}
          e2eTargetName={e2eTargetName}
        />
      }
    >
      <div className={styles.label}>{label}</div>
    </FormControlLabel>
  )
}
