var ProductModel;
(function (ProductModel) {
    ProductModel["BOX_MODEL_PRE_PRINTED_MAILER_BOX_ECO"] = "prePrintedMailerBoxEco";
    ProductModel["BOX_MODEL_PRE_PRINTED_MAILER_BOX_FULL_COLOUR"] = "prePrintedMailerBoxFullColour";
    ProductModel["BOX_MODEL_MAILER_BOX"] = "mailerBox";
    ProductModel["BOX_MODEL_MAILER_BOX_WHITE_INK"] = "mailerBoxWhiteInk";
    ProductModel["BOX_MODEL_DELIVERY_MAILER_BOX"] = "deliveryMailerBox";
    ProductModel["BOX_MODEL_DELIVERY_MAILER_BOX_PLAIN"] = "deliveryMailerBoxPlain";
    ProductModel["BOX_MODEL_MAILER_BOX_PLAIN"] = "mailerBoxPlain";
    ProductModel["BOX_MODEL_SLEEVED_MAILER_BOX"] = "sleevedMailerBox";
    ProductModel["BOX_MODEL_PRODUCT_BOX"] = "productBox";
    ProductModel["BOX_MODEL_PRODUCT_BOX_TWO_PIECES"] = "productBoxTwoPieces";
    ProductModel["BOX_MODEL_PRODUCT_BOX_WINE_BOX"] = "productBoxWineBox";
    ProductModel["BOX_MODEL_SHIPPING_BOX"] = "shippingBox";
    ProductModel["BOX_MODEL_SHIPPING_BOX_PLAIN"] = "shippingBoxPlain";
    ProductModel["BOX_MODEL_SHIPPING_BOX_PLAIN_V2"] = "shippingBoxPlainV2";
})(ProductModel || (ProductModel = {}));
export default ProductModel;
