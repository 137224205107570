import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { TabSection } from "../Tab/TabSection"
import { Switcher } from "../../../atoms/Switcher/Switcher"
import { SwitcherItem } from "../../../atoms/Switcher/SwitcherItem"
import { PatternMode } from "../../../../../render-engine/modules/vd-editor/modules/assets-module/pattern-replicator/types"
import { ArtworkSubTab } from "./partials/ArtworkSubTab"
import { TextSubTab } from "./partials/TextSubTab"

const i18n = I18N.editorTools.patterns

export const TabPatternReplicator = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.replicablePatterns])

  if (!containerSet) {
    return null
  }

  const { replicablePatterns } = containerSet

  if (!replicablePatterns.available) {
    return null
  }

  const { replicablePatternsStore, replicablePatternUiController } =
    replicablePatterns
  const { selectedMode } = replicablePatternsStore

  const onSubTabClick = (mode: PatternMode) => {
    replicablePatternUiController.selectMode(mode)
  }

  return (
    <Tab title={t(I18N.tabs.replicablePatterns)} e2eTargetName="pattern">
      <TabSection>
        <Switcher>
          <SwitcherItem
            label={t(i18n.artwork)}
            onClick={() => onSubTabClick("image")}
            active={selectedMode === "image"}
            analyticsSection="left-tab-pattern"
            analyticsType="menu-item"
            analyticsName="image"
          />
          <SwitcherItem
            label={t(i18n.text)}
            onClick={() => onSubTabClick("text")}
            active={selectedMode === "text"}
            analyticsSection="left-tab-pattern"
            analyticsType="menu-item"
            analyticsName="text"
          />
        </Switcher>
      </TabSection>

      {selectedMode === "image" && <ArtworkSubTab />}
      {selectedMode === "text" && <TextSubTab />}
    </Tab>
  )
})
