import styles from "../Modal.module.scss"

export const MODAL_OPEN_BODY_CLASSNAME = styles["body-modal-opened"]

export const bodyModalLock = (disable: boolean = false) => {
  if (typeof document != "undefined" && typeof document.body != "undefined") {
    if (disable) {
      document.body.classList.remove(MODAL_OPEN_BODY_CLASSNAME)
    } else {
      document.body.classList.add(MODAL_OPEN_BODY_CLASSNAME)
    }
  }
}
