import React from "react"
import { App } from "./App"
import { EditorSnowAppWrapper } from "./_containers-react/_app-bootstrap"
import { AsyncIntContainer } from "shared-libs/src/js/libs/AsyncI18nLocales"

export const AppPreload = () => {
  return (
    <EditorSnowAppWrapper>
      <AsyncIntContainer>
        <App />
      </AsyncIntContainer>
    </EditorSnowAppWrapper>
  )
}
