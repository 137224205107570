import { getFirstTouchUtmData } from "../analytics/google/google-analytics-service"
import { Ga4Service } from "../analytics/google/ga4-service"
import { Sentry } from "./sentry-service"

export class FirstTouchService {
  async getUserFirstTouchData(timeout?: number) {
    try {
      let client_id = null
      try {
        const gtagData = await Ga4Service.getGtagData()
        if (gtagData != null) {
          client_id = gtagData.client_id
        }
      } catch (e) {
        console.debug("Failed to get gtag data")
      } finally {
        const firstTouchUtmPayload = getFirstTouchUtmData(client_id)
        return firstTouchUtmPayload
      }
    } catch (e) {
      Sentry.captureException(e)
      throw new Error("Failed to register a first touch event")
    }
  }
}
