import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import ProductDriver from "../../../drivers/product.driver"
import { RendererMountPoints } from "../../../render-engine/types"
import { ViewType } from "../../../libs/products-render-config/types"
import { ThreeDimensionalScene } from "../../dsl/organisms/scenes/ThreeDimensionalScene/ThreeDimensionalScene"
import { TwoDimensionalScene } from "../../dsl/organisms/scenes/TwoDimensionalScene/TwoDimensionalScene"
import styles from "./Scene.module.scss"
import cxBinder from "classnames/bind"
import { DbyScene } from "../../dsl/organisms/scenes/DbyScene/DbyScene"

const cx = cxBinder.bind(styles)

export interface RendererProps {
  productDriver: ProductDriver
  loading: boolean
}

export const Scene = observer(function ({
  productDriver,
  loading,
}: RendererProps) {
  const [rendererMountPoint, setRendererMountPoint] = useState<
    RendererMountPoints["rendererMountPoint"] | null
  >(null)
  const [vdMountPoints, setVdMountPoints] = useState<
    RendererMountPoints["vdMountPoints"] | null
  >(null)

  useEffect(() => {
    if (rendererMountPoint && vdMountPoints && !loading) {
      productDriver.renderEngineDomMounter({
        rendererMountPoint,
        vdMountPoints,
      })
    }
  }, [rendererMountPoint, vdMountPoints, loading])

  const { activeViewType, productRenderPilot } = productDriver.state
  const isModelView = activeViewType === ViewType.MODEL

  if (productRenderPilot.getEditorMode() === "dby") {
    return (
      <div className={styles.scene}>
        <DbyScene />
      </div>
    )
  }

  return (
    <>
      <div className={cx("scene", { hidden: !isModelView })}>
        <ThreeDimensionalScene
          productDriver={productDriver}
          onSceneMount={setRendererMountPoint}
        />
      </div>
      <div className={cx("scene", { hidden: isModelView })}>
        <TwoDimensionalScene
          productDriver={productDriver}
          onSceneMount={setVdMountPoints}
        />
      </div>
    </>
  )
})
