import React from "react"
import styles from "./LogoLoader.module.scss"
import packhelpLoader from "../../../../assets/packhelp-logo-sign.svg"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

interface LogoLoaderProps {
  label?: string
}

export function LogoLoader({ label }: LogoLoaderProps) {
  const [containerSet] = useContainerSet((c) => [c.envUtil])

  if (!containerSet) {
    return null
  }

  const { appConfig } = containerSet.envUtil
  const { loader } = appConfig.branding

  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <img src={loader || packhelpLoader} alt="" />
      </div>
      <span>{label}</span>
    </div>
  )
}
