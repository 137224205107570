const MOBILE = {
  XS: 320,
  SM: 424,
  MD: 480,
  LG: 767
}

const TABLET = {
  MD: 992,
  LG: 1024
}

const DESKTOP = {
  MD: 1200,
  LG: 1300,
  XL: 1920
}

export const breakpoint = {
  MOBILE,
  TABLET,
  DESKTOP
}
