import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { TileList } from "../../../atoms/Tile/TileList"
import { Tile } from "../../../atoms/Tile/Tile"
import { PredefinedText } from "../../../../../models/predefined-text"
import { Text } from "../../../../../models/text"
import { TileButton } from "../../../atoms/TileButton/TileButton"
import { SkeletonTileList } from "../../../atoms/Skeleton/SkeletonTileList"

export const TabText = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.assets])

  if (!containerSet) {
    return null
  }

  const { assets } = containerSet
  const { predefinedTextsStore, textsController } = assets
  const { isLoadingPredefinedTexts, orderedPredefinedTexts } =
    predefinedTextsStore

  const onTextClick = () => {
    textsController.addText(new Text())
  }

  const onPredefinedTextClick = (text: PredefinedText) => {
    textsController.addPredefinedText(text)
  }

  return (
    <Tab title={t(I18N.tabs.text)} e2eTargetName="text">
      <TileButton
        label={t(I18N.editorTools.text.addText)}
        onClick={onTextClick}
        analyticsSection="left-tab-text"
        analyticsType="button"
        analyticsName="add-text"
        e2eTarget="button"
        e2eTargetName="add-text"
      />

      {isLoadingPredefinedTexts ? (
        <SkeletonTileList repeats={12} cols={2} />
      ) : (
        <TileList>
          <>
            {orderedPredefinedTexts.map((text) => {
              const imageUrl = textsController.getPreviewUrl(text)

              return (
                <Tile
                  key={text.id}
                  onClick={() => onPredefinedTextClick(text)}
                  monochromatic={textsController.isMonochromatic()}
                  analyticsSection="left-tab-text"
                  analyticsType="thumbnail"
                  analyticsName="predefined-text"
                  analyticsId={text.name}
                  e2eTarget="button"
                  e2eTargetName="add-predefined-text"
                >
                  <figure>
                    <img src={imageUrl} alt={text.name} />
                  </figure>
                </Tile>
              )
            })}
          </>
        </TileList>
      )}
    </Tab>
  )
})
