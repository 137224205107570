import { urls } from "../api-endpoint-urls"
import {
  ConsentServicePayload,
  OrderConsentServicePayload,
} from "../../consent-service"

export const submitConsentsEndpoint = (payload: ConsentServicePayload) => ({
  method: "post",
  url: `${urls.API_EMAILS_WITH_CONSENTS}`,
  data: payload,
})

export const getGlobalConsentNeeded = (email: string) => ({
  method: "get",
  url: `${urls.API_EMAILS_GLOBAL_CONSENTS_NEEDED}?email=${email}`,
})

export const submitOrderConsents = (payload: OrderConsentServicePayload) => ({
  method: "post",
  url: `${urls.API_ORDERS_CONSENTS}`,
  data: payload,
})
