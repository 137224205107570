export function addObjects(fabric) {
  fabric.PackhelpRect = fabric.util.createClass(fabric.Rect, {
    type: "packhelpRect",

    initialize: function (options) {
      options = options || {}
      this.set("id", options.id)
      this.callSuper("initialize", options)
      options && this.set("name", options.name)
    },
    toObject: function () {
      return fabric.util.object.extend(this.callSuper("toObject"), {
        id: this.get("id"),
      })
    },
  })

  fabric.PackhelpRect.fromObject = function (object, callback) {
    return fabric.Object._fromObject("PackhelpRect", object, callback)
  }

  return fabric
}
