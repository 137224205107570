import React from "react"
import cxBinder from "classnames/bind"
import Badge, { BadgeColorTypes } from "../../../../../atoms/Badge/Badge"
import Typography, { TType } from "../../../../../atoms/Typography/Typography"
import { BasicQuantityItem, LabelProps } from "../../../types"
import styles from "./BasicLabel.module.scss"

const cx = cxBinder.bind(styles)

export const BasicLabel = (props: LabelProps) => {
  const { item } = props
  const {
    piecePrice,
    price,
    promoPrice,
    discount,
    badge,
    badgeColor,
    outOfStock
  } = item as BasicQuantityItem

  const isPromoPriceAvailable = !!promoPrice

  return (
    <div className={styles["wrapper"]} e2e-target-name="selected-item-label">
      {/*{discount && !outOfStock && (*/}
      {/*  <Badge badgeColor={BadgeColorTypes.WhiteFour}>{discount}</Badge>*/}
      {/*)}*/}
      {piecePrice && (
        <Typography className={styles["piece-price"]} type={TType.Body15_350}>
          {piecePrice}
        </Typography>
      )}
      {badge && (
        <Badge badgeColor={badgeColor as BadgeColorTypes}>{badge}</Badge>
      )}
      {price && (
        <Typography
          className={cx("price", {
            "with-promo": isPromoPriceAvailable
          })}
          type={TType.Header15_500}
          htmlElement="span"
        >
          {price}
        </Typography>
      )}
      {isPromoPriceAvailable && (
        <Typography
          className={styles.price}
          type={TType.Header15_500}
          htmlElement="span"
        >
          {promoPrice}
        </Typography>
      )}
    </div>
  )
}
