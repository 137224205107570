import THREE from "../../../../libs/vendors/THREE"
import { GeometryConfig } from "../types"

export class GeometryCreator {
  constructor(private readonly shape: THREE.Shape) {}

  public call(config: GeometryConfig): THREE.ExtrudeGeometry {
    const geometry = new THREE.ExtrudeGeometry(this.shape, {
      depth: config.thickness,
      bevelEnabled: false,
    })

    this.recalculateUv(geometry)
    geometry.computeBoundingBox()
    this.recalculateMaterialGroups(geometry)

    geometry.userData = config

    return geometry
  }

  private recalculateMaterialGroups(geometry: THREE.ExtrudeGeometry): void {
    const { groups } = geometry

    const outsideStart = groups[0].start
    const outsideCount = groups[0].count / 2

    const insideStart = outsideCount
    const insideCount = outsideCount

    const sideStart = outsideStart + insideCount
    const sideCount = groups[1].count

    geometry.clearGroups()
    geometry.addGroup(outsideStart, outsideCount, 0) // outside
    geometry.addGroup(insideStart, insideCount, 1) // inside
    geometry.addGroup(sideStart, sideCount, 2) // sides
  }

  // https://discourse.threejs.org/t/custom-shape-in-image-not-working/49348/9
  private recalculateUv(geometry: THREE.ExtrudeGeometry): void {
    const pos = geometry.attributes.position as THREE.BufferAttribute
    const b3 = new THREE.Box3().setFromBufferAttribute(pos)
    const b3size = new THREE.Vector3()
    b3.getSize(b3size)

    const uv: number[] = []

    for (let i = 0; i < pos.count; i++) {
      const x = pos.getX(i)
      const y = pos.getY(i)
      const u = (x - b3.min.x) / b3size.x
      const v = (y - b3.min.y) / b3size.y

      uv.push(u, v)
    }

    geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uv, 2))
  }
}
