/** @format */
// product sku def
import SkuFinish from "./tapeSkuDefinitions/SkuFinish";
import SkuMaterial from "./tapeSkuDefinitions/SkuMaterial";
import SkuPrintMode from "./tapeSkuDefinitions/SkuPrintMode";
import SkuModel from "./tapeSkuDefinitions/SkuModel";
// product parts def
import PartFinish from "./tapePartsDefinitions/PartFinish";
import PartMaterial from "./tapePartsDefinitions/PartMaterial";
import PartPrintMode from "./tapePartsDefinitions/PartPrintMode";
import PartModel from "./tapePartsDefinitions/PartModel";
// product and variants
import Product from "./Product";
import ProductVariant from "./ProductVariant";
var TAPE_CONSTS = {
    SKU_BASE: "tape-",
    PRODUCT_TYPE: "tape",
    SKU_PRODUCT_TAPE: "tape",
    // MATERIAL_ID_PAPER: 'paper', // Materials translation definitions
    DEFAULT_LINE_ITEM_RANGES: [240, 480, 720, 960, 1200, 1440, 1680, 2040],
};
export default Object.assign({}, TAPE_CONSTS, SkuModel, SkuFinish, SkuMaterial, SkuPrintMode, PartModel, PartFinish, PartMaterial, PartPrintMode, Product, ProductVariant);
