export const stages = {
  STAGE_PRODUCT_SELECT: "stageProductSelect",
  STAGE_PRODUCT_CONFIGURATOR: "stageProductConfigurator",
  STAGE_BOX_TYPE: "stageBoxType",
  STAGE_DESIGN: "stageDesign",
  STAGE_QUANTITY: "stageQuantity",
  STAGE_DELIVERY_AND_PAYMENT_METHOD: "stageDeliveryAndPaymentMethod",
  STAGE_ORDER_SUMMARY: "stageOrderDetails",
  STAGE_ORDER_CONFIRMATION: "stageOrderConfirmation",
  STAGE_PAYMENT_RETURN: "stagePaymentReturn",
  STAGE_OFFER_REVIEW: "stageOfferReview",
  STAGE_CREATE_SAMPLE_PACK: "stageCreateSamplePack",

  // Overlays
  STAGE_OVERLAY_WAITING_FOR_FILES: "stageOverlayWaitingForFiles",
  STAGE_OVERLAY_CONVERTING_BOX_DESIGN: "stageOverlayConvertingBoxDesign",
  STAGE_OVERLAY_CREATING_BOX_DESIGN: "stageOverlayCreatingBoxDesign",
  STAGE_OVERLAY_FETCHING_BOX_DESIGN: "stageOverlayFetchingBoxDesign",
  STAGE_OVERLAY_ADDING_ITEM_TO_ORDER: "stageOverlayAddingItemToOrder",
  STAGE_OVERLAY_SAVING_BOX_DESIGN: "stageOverlaySavingBoxDesign",
  STAGE_OVERLAY_PREPARING_ORDER: "stageOverlayPreparingOrder",
  STAGE_OVERLAY_PREPARING_OFFER: "stageOverlayPreparingOffer",
  STAGE_OVERLAY_UPDATING_QUANTITY_QUALITY:
    "stageOverlayUpdatingQuantityQuality",
  STAGE_OVERLAY_SUBMITTING_ORDER_DETAILS: "stageOverlaySubmittingOrderDetails",
  STAGE_OVERLAY_REDIRECTING_TO_PAYMENT: "stagesOverlayRedirectingToPayment",
  STAGE_OVERLAY_RETURNING_FROM_PAYMENT: "stagesOverlayReturningFromPayment",
  STAGE_OVERLAY_LOADING_BOX_DATA: "stagesOverlayLoadingBoxData",
  STAGE_OVERLAY_LOADING_BOX_DATA_ERROR_UNAUTHORIZED:
    "stagesOverlayLoadingBoxDataErrorUnauthorized",
  STAGE_OVERLAY_PREPARING_SAMPLE_PACK_PRODUCT:
    "stagesOverlayPreparingSamplePackProduct",
}
