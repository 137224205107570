import { VirtualDielineSpace } from "../../render-engine/modules/vd-editor/object-extensions/packhelp-objects"

const DPI = 300
const InchToCm = 2.54
const InchToMm = InchToCm * 10

export type Size = {
  width: number
  height: number
}

export type SizePx = {
  widthPx: number
  heightPx: number
}

/**
 * Vdpx stands for "virtual dieline pixels"
 * We use it to measure dimensions of the **virtual** dieline (SVG file with spaces objects)
 * which is usually 1024px high and every space is a rectangle or polygon.
 * It's much smaller than the actual artwork. It can have for example 233x208px.
 * They're not HTML canvas pixels, nor pixels in print (artwork).
 * During spit generating, the virtual dieline (or space, I'm not sure, ask @Soszu)
 * is scaled up to fit the actual artwork.
 */
export type SizeVdpx = {
  widthVdpx: number
  heightVdpx: number
}

/**
 * Apx stands for "artwork pixels".
 * These pixels are actually used in print.
 * We use it to show how many pixels fit into the real dieline (the artwork).
 *
 * If the space is 1223px long (mailerbox F33) =>
 * it's 4.07(6) in (300 dpi)
 * it's 103.55 mm
 * We can easily convert it into inches or milimeters
 */
export type SizeApx = {
  widthApx: number
  heightApx: number
}

export type SizeMm = {
  widthMm: number
  heightMm: number
}

export class ObjectDpiCalculator {
  static cmToInch(value: number): number {
    return value / InchToCm
  }

  static getImageMaxSizeDpx(
    virtualDielineSpace: VirtualDielineSpace,
    imageSize: SizePx,
    artworkSpaceSize: SizeApx
  ): SizeVdpx {
    const vdSpaceSize = ObjectDpiCalculator.getVdSize(virtualDielineSpace)

    const widthDpx = Math.round(
      (imageSize.widthPx / artworkSpaceSize.widthApx) * vdSpaceSize.widthVdpx
    )
    const heightDpx = Math.round(
      (imageSize.heightPx / artworkSpaceSize.heightApx) * vdSpaceSize.heightVdpx
    )

    return {
      widthVdpx: widthDpx,
      heightVdpx: heightDpx,
    }
  }

  static getObjectRealSizeMm(
    virtualDielineSpace: VirtualDielineSpace,
    assetSize: SizeVdpx,
    artworkSpaceSizeApx: SizeApx
  ): SizeMm {
    const vdSpaceSize = ObjectDpiCalculator.getVdSize(virtualDielineSpace)

    const artworkSpaceSize = ObjectDpiCalculator.apxToMm(artworkSpaceSizeApx)

    const widthMm =
      (assetSize.widthVdpx / vdSpaceSize.widthVdpx) * artworkSpaceSize.widthMm

    const heightMm = (assetSize.heightVdpx * widthMm) / assetSize.widthVdpx

    return {
      widthMm,
      heightMm,
    }
  }

  static getVdSize(space: VirtualDielineSpace): SizeVdpx {
    const vdSpaceSize = ObjectDpiCalculator.sizeToVdpx(space)

    const isRotated = [90, -90, 270, -270].includes(Number(space.rotation))
    if (isRotated) {
      const tempHeight = vdSpaceSize.heightVdpx
      vdSpaceSize.heightVdpx = vdSpaceSize.widthVdpx
      vdSpaceSize.widthVdpx = tempHeight
    }

    return vdSpaceSize
  }

  static getDpi() {
    return DPI
  }

  static getInchToMm() {
    return InchToMm
  }

  static pxToMm(pxSize: number) {
    return (pxSize / DPI) * InchToMm
  }

  static apxToMm(size: SizeApx): SizeMm {
    return {
      widthMm: (size.widthApx / DPI) * InchToMm,
      heightMm: (size.heightApx / DPI) * InchToMm,
    }
  }

  static mmToApx(size: SizeMm): SizeApx {
    return {
      widthApx: (size.widthMm / InchToMm) * DPI,
      heightApx: (size.heightMm / InchToMm) * DPI,
    }
  }

  static sizeToPx(size: Size): SizePx {
    return {
      widthPx: size.width,
      heightPx: size.height,
    }
  }

  static sizeToMm(size: Size): SizeMm {
    return {
      widthMm: size.width,
      heightMm: size.height,
    }
  }

  static sizeToVdpx(size: Size): SizeVdpx {
    return {
      widthVdpx: size.width,
      heightVdpx: size.height,
    }
  }

  static sizeToApx(size: Size): SizeApx {
    return {
      widthApx: size.width,
      heightApx: size.height,
    }
  }
}
