import { getUserCountry } from "../helpers/get-user-country"
import { Sentry } from "../services/sentry-service"

export class CountryNotAvailableError extends Error {}
export class CountryDataNotSetError extends Error {}

export class AttributionService {
  public async initAttributionData(): Promise<void> {
    this.initGclid()
    await this.initCountry()
  }

  public getGclid(): string {
    const gclid = sessionStorage.getItem("gclid")
    return gclid !== null ? gclid : ""
  }

  public getCountry(): string {
    const country = sessionStorage.getItem("country")
    return country !== null ? country : ""
  }

  private initGclid(): void {
    const urlParams = new URLSearchParams(window.location.search)
    const gclid = urlParams.get("gclid")

    if (gclid) {
      sessionStorage.setItem("gclid", gclid)
    }
  }

  private async initCountry(): Promise<void> {
    try {
      const country = getUserCountry() || ""

      sessionStorage.setItem("country", country)
    } catch (error) {
      console.error(
        "Attribution Service: Failed to set country in session storage",
        error
      )
      Sentry.captureException(
        new CountryDataNotSetError(
          "Attribution Service: Failed to set country in session storage"
        ),
        true
      )
    }
  }
}
