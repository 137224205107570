import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"
import styles from "./SubTabContent.module.scss"

const cx = cxBinder.bind(styles)

export interface SubTabContentProps {
  visible?: boolean
}

export function SubTabContent({
  children,
  visible = true,
}: PropsWithChildren<SubTabContentProps>) {
  return (
    <div
      className={cx("wrapper", {
        visible,
      })}
    >
      {children}
    </div>
  )
}
