const SPREE_COUNTRY_ID_AU = 13
const SPREE_COUNTRY_ID_CA = 38
const SPREE_COUNTRY_ID_US = 232

export const spree = {
  SPREE_COUNTRY_UNITED_KINGDOM_ID: 77,
  SPREE_COUNTRY_POLAND_ID: 179,
  SPREE_PARTIALLY_SUPPORTED_COUNTRY_IDS: [
    SPREE_COUNTRY_ID_AU,
    SPREE_COUNTRY_ID_CA,
    SPREE_COUNTRY_ID_US,
  ],
  SPREE_TRANSFER_PAYMENT_METHOD_TYPE: "check",
  SPREE_PAYPAL_PAYMENT_METHOD_TYPE: "paypal",
  SPREE_STRIPE_CARD_PAYMENT_METHOD_TYPE: "stripe",
  SPREE_PAYMENT_STATUS_SUCCESS: "OK",
  SPREE_PAYMENT_STATUS_WAITING: "WAITING",
  SPREE_PAYMENT_STATUS_DELAYED: "DELAYED",
  SPREE_PAYMENT_STATUS_FAILED: "FAILED",
  SPREE_PAYMENT_STATUS_NOT_STARTED: "NOT_STARTED",
  SPREE_VALIDATION_SHUT_UP: "z.n/a",

  SPREE_ORDER_STATE_COMPLETE: "complete",

  SPREE_DELIVERY_METHOD_COURIER: "shipping-method-courier",
  SPREE_DELIVERY_METHOD_SAMPLE_PACKS: "shipping-method-sample-packs",
  SPREE_DELIVERY_METHOD_PALETTE: "shipping-method-palettes",
  SPREE_DELIVERY_METHOD_PICK_UP: "shipping-method-pick-up",
  SPREE_DELIVERY_METHOD_POLISH_POST: "shipping-method-polish-post",
  SPREE_NOT_A_COMPANY_ID: "not-a-company-id", // TODO: MAGIC VARIABLE BOOGALOO - FIX ME
}
