import React, { ReactNode } from "react"
import cxBinder from "classnames/bind"
import Typography, { TType } from "../../../../atoms/Typography/Typography"
import { CloseButton } from "../partials/CloseButton"
import styles from "./ModalTemplateFlex.module.scss"

const cx = cxBinder.bind(styles)

interface ModalTemplateFlexProps {
  children: ReactNode
  header?: string
  footer?: ReactNode
  onCloseAction?: () => void
  withBackgroundGrey?: boolean
  withHeaderBorderNone?: boolean
}

export const ModalTemplateFlex = (props: ModalTemplateFlexProps) => {
  const {
    header,
    children,
    footer,
    onCloseAction,
    withBackgroundGrey = false,
    withHeaderBorderNone = false
  } = props

  return (
    <div
      className={cx("wrapper", {
        "wrapper-background-grey": withBackgroundGrey
      })}
    >
      {header && (
        <div
          className={cx("header", {
            "header-border-none": withHeaderBorderNone
          })}
        >
          <Typography type={TType.Header17_500}>{header}</Typography>
          {onCloseAction && (
            <CloseButton onCloseAction={onCloseAction} alignTop={false} />
          )}
        </div>
      )}
      {children}
      {footer && <div className={styles["footer"]}>{footer}</div>}
    </div>
  )
}
