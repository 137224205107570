import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import { EdgeType, ObjectEdge } from "../types"
import { getBoundingRect } from "./get-bounding-rect"

export function calculateEdges(
  object: PackhelpObject,
  types: EdgeType[] = ["top", "hcenter", "bottom", "left", "vcenter", "right"]
): ObjectEdge[] {
  const { width, height, left, top } = getBoundingRect(object)

  const edges: ObjectEdge[] = [
    {
      type: "top",
      axis: "y",
      offset: 0,
      points: {
        start: { x: left, y: top },
        end: { x: left + width, y: top },
      },
    },
    {
      type: "hcenter",
      axis: "y",
      offset: height / 2,
      points: {
        start: { x: left, y: top + height / 2 },
        end: { x: left + width, y: top + height / 2 },
      },
    },
    {
      type: "bottom",
      axis: "y",
      offset: height,
      points: {
        start: { x: left, y: top + height },
        end: { x: left + width, y: top + height },
      },
    },
    {
      type: "left",
      axis: "x",
      offset: 0,
      points: {
        start: { x: left, y: top },
        end: { x: left, y: top + height },
      },
    },
    {
      type: "vcenter",
      axis: "x",
      offset: width / 2,
      points: {
        start: { x: left + width / 2, y: top },
        end: { x: left + width / 2, y: top + height },
      },
    },
    {
      type: "right",
      axis: "x",
      offset: width,
      points: {
        start: { x: left + width, y: top },
        end: { x: left + width, y: top + height },
      },
    },
  ]

  return edges.filter((edge) => types.includes(edge.type))
}
