import { computed, toJS } from "mobx"
import { ProductPricingStore } from "../stores/product-pricing.store"
import { TaxStore } from "../stores/tax.store"
import { ProductStore } from "../stores/product.store"
import { EcommerceStore } from "../stores/ecommerce.store"
import { EcommerceController } from "./ecommerce.controller"
import type { PriceBreak as PickerPriceBreak } from "dsl/src/components/QuantityPicker/types"
import { ProductDesignStore } from "../stores/product-design.store"
import { ProductManager } from "@ph/product-api"
import { CurrencyStore } from "../stores/currency.store"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { EcommerceDesignItem } from "../types/design.types"
import { PriceBreak } from "../types/ecommerce.types"
import { PriceDisplayOptions } from "dsl/src/components/QuantityPicker/types"

export class QuantityPickerUiController {
  private readonly ecommerceController: EcommerceController
  private readonly ee: AllEditorEventsEmitter
  private readonly productPricingStore: ProductPricingStore
  private readonly taxStore: TaxStore
  private readonly productStore: ProductStore
  private readonly ecommerceStore: EcommerceStore
  private readonly productDesignStore: ProductDesignStore
  private readonly currencyStore: CurrencyStore

  constructor(
    services: {
      ecommerceController: EcommerceController
      ee: AllEditorEventsEmitter
    },
    stores: {
      productPricingStore: ProductPricingStore
      taxStore: TaxStore
      productStore: ProductStore
      ecommerceStore: EcommerceStore
      productDesignStore: ProductDesignStore
      currencyStore: CurrencyStore
    }
  ) {
    this.ecommerceController = services.ecommerceController
    this.ee = services.ee
    this.productPricingStore = stores.productPricingStore
    this.taxStore = stores.taxStore
    this.productStore = stores.productStore
    this.ecommerceStore = stores.ecommerceStore
    this.productDesignStore = stores.productDesignStore
    this.currencyStore = stores.currencyStore
  }

  @computed
  public get isLoading(): boolean {
    return this.productPricingStore.isLoadingPricing
  }

  @computed
  public get isDisabled(): boolean {
    const {
      state: { isDesignInCart },
      isDesignReadOnly,
    } = this.productDesignStore

    return isDesignInCart || isDesignReadOnly
  }

  @computed
  public get displayOptions(): PriceDisplayOptions {
    return {
      withTax: this.taxStore.taxConfig.hasTax,
      withPricePerPiece: true,
      withPrice: true,
    }
  }

  @computed
  public get visibleQuantity(): number {
    return this.ecommerceStore.selectedQuantity * this.piecesPerUnit
  }

  public setQuantity(visibleQuantity: number): void {
    this.ecommerceController.setSelectedQuantity(
      visibleQuantity / this.piecesPerUnit
    )
  }

  @computed
  public get minVisibleQuantity(): number {
    return this.quantityRanges[0]
  }

  @computed
  public get maxVisibleQuantity(): number {
    return this.quantityRanges[this.quantityRanges.length - 1]
  }

  @computed
  public get piecesPerUnit(): number {
    return this.product.variantManager.getPiecesPerUnit()
  }

  @computed
  public get priceBreaks(): PickerPriceBreak[] {
    return this.productPricingStore
      .getPriceBreaks(this.product, this.designItems)
      .map((priceBreak) => this.mapPriceBreak(priceBreak))
  }

  public calculatePriceBreak(visibleQuantity: number): PickerPriceBreak {
    const quantity = visibleQuantity / this.piecesPerUnit

    const priceBreak = this.productPricingStore.getPriceBreak(
      this.product,
      this.designItems,
      quantity
    )

    this.ee.emit(
      eventTree.pd.customQtyTyped,
      quantity,
      priceBreak.priceTotal - priceBreak.addonsPrice
    )

    return this.mapPriceBreak(priceBreak)
  }

  private mapPriceBreak(priceBreak: PriceBreak): PickerPriceBreak {
    return {
      ...priceBreak,
      quantity: priceBreak.quantity * this.piecesPerUnit,
      isLoadingDynamicPricing: this.ecommerceStore.isDynamicVariantLoading,
      currency: this.currencyStore.currency,
      isDynamicPricingAvailable: !priceBreak.type,
      isCustomValue: !priceBreak.priceTotal,
    }
  }

  @computed
  private get quantityRanges(): number[] {
    return this.productPricingStore
      .getQuantityRanges(this.product)
      .map((quantity) => quantity * this.piecesPerUnit)
  }

  @computed
  private get product(): ProductManager {
    return this.productStore.product
  }

  @computed
  private get designItems(): EcommerceDesignItem[] {
    return toJS(this.productDesignStore.state.meta.designItems)
  }
}
