import React, { ReactNode, RefObject, useEffect, useRef, useState } from "react"
import { useMatchQuery } from "../../common/hooks"
import { useOutsideClick } from "../../common/hooks/use-outside-click"
import { PickerValueContainer } from "./components/picker-value/PickerValueContainer"
import { PickerOptions } from "./components/picker-options/PickerOptions"
import { useQuantityItems } from "./hooks/use-quantity-items"
import { useDebouncedSearchValue } from "./hooks/use-debounced-search-value"
import { useListSearch } from "./hooks/use-list-search"
import { useSearchConfirm } from "./hooks/use-search-confirm"
import {
  Direction,
  PriceBreak,
  PriceDisplayOptions,
  PriceFormatter,
  QuantityItem,
  TranslatedKeys
} from "./types"
import styles from "./QuantityPicker.module.scss"

export interface QuantityPickerProps {
  consise?: boolean
  direction?: Direction
  disabled?: boolean
  footer?: ReactNode
  getPriceBreak: (quantity: number) => PriceBreak
  isOpenOnInit?: boolean
  listRef?: RefObject<HTMLUListElement>
  maxQuantity: number
  maxQuantityForStandardOffer?: number
  minQuantity: number
  minQuantityForCustomOffer?: number
  onClose?: () => void
  onItemAction?: (item: QuantityItem) => void
  onOpen?: () => void
  piecePriceFormatter: PriceFormatter
  priceBreaks: PriceBreak[]
  priceDisplayOptions: PriceDisplayOptions
  priceFormatter: PriceFormatter
  quantity: number
  scrollRef?: RefObject<HTMLDivElement>
  searchRef?: RefObject<HTMLInputElement>
  setQuantity: (quantity: number) => void
  step: number
  translations: TranslatedKeys
  withPulsatingGlow?: boolean
  analyticsSection?: string
  analyticsType?: string
  analyticsName?: string
  analyticsId?: string
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const {
    consise,
    direction,
    disabled,
    footer,
    getPriceBreak,
    isOpenOnInit = false,
    listRef,
    maxQuantity,
    maxQuantityForStandardOffer,
    minQuantity,
    minQuantityForCustomOffer,
    onClose,
    onItemAction,
    onOpen,
    piecePriceFormatter,
    priceBreaks,
    priceDisplayOptions,
    priceFormatter,
    quantity,
    scrollRef,
    searchRef,
    setQuantity,
    step,
    translations,
    withPulsatingGlow = false,
    analyticsSection,
    analyticsType,
    analyticsName,
    analyticsId
  } = props
  const pickerRef = useRef<HTMLDivElement>(null)

  const [value, setValue] = useState<QuantityItem>()
  const quantityItems = useQuantityItems({
    options: priceDisplayOptions,
    piecePriceFormatter,
    priceBreaks,
    priceFormatter,
    translations
  })
  const [searchValue, setSearchValue] = useState("")

  const debouncedSearchValue = useDebouncedSearchValue(searchValue, 300)

  const { match, options } = useListSearch({
    getPriceBreak,
    maxQuantity,
    maxQuantityForStandardOffer,
    minQuantity,
    minQuantityForCustomOffer,
    options: quantityItems,
    piecePriceFormatter,
    priceDisplayOptions,
    priceFormatter,
    searchValue: debouncedSearchValue,
    step,
    translations
  })

  const [isOpen, setOpen] = useState(isOpenOnInit)

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  useOutsideClick(pickerRef, () => {
    handleClose()
  })

  const handleSelect = (value: QuantityItem) => {
    setQuantity(Number(value.quantity))
    handleClose()
  }

  const handleSearchChange = (value?: number) =>
    setSearchValue(value ? `${value}` : "")

  const { onSearchConfirm } = useSearchConfirm({
    match,
    setSearchValue,
    onSelect: handleSelect,
    withClear: true
  })

  const handleSearchConfirm = () => {
    if (onSearchConfirm) {
      onSearchConfirm()
    }
    handleClose()
  }

  const handleToggleOpen = (isOpen: boolean) => {
    setOpen(isOpen)
    if (isOpen && onOpen) {
      onOpen()
    }
    if (!isOpen && onClose) {
      onClose()
    }
  }

  useEffect(() => {
    const quantitySelectedFromRange = quantityItems.find(
      (quantityItem) => quantityItem.quantity === String(quantity)
    )

    setValue(quantitySelectedFromRange)
  }, [quantityItems, quantity])

  const isMobile = useMatchQuery()

  const isDisabled = (): boolean => {
    if (value && "isLoading" in value) {
      return !!value.isLoading
    }

    return !!disabled
  }

  return (
    <div
      ref={pickerRef}
      className={styles["picker"]}
      e2e-target="quantity-select"
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
    >
      <PickerValueContainer
        consise={consise}
        isDisabled={isDisabled()}
        isOpen={isOpen}
        onToggleOpen={handleToggleOpen}
        placeholder={translations.placeholder}
        value={value}
        withPulsatingGlow={withPulsatingGlow}
        priceDisplayOptions={priceDisplayOptions}
      />
      <PickerOptions
        consise={consise}
        confirmLabel={translations.confirmLabel}
        direction={direction}
        footer={footer}
        headerLabel={translations.headerLabel}
        isMobile={isMobile}
        isOpen={isOpen}
        listRef={listRef}
        match={match}
        onClose={handleClose}
        onItemAction={onItemAction}
        onSearchChange={handleSearchChange}
        onSearchConfirm={handleSearchConfirm}
        onSelect={handleSelect}
        options={options}
        pickerRef={pickerRef}
        scrollRef={scrollRef}
        searchPlaceholder={translations.searchPlaceholder}
        searchRef={searchRef}
        searchValue={searchValue}
        value={value}
      />
    </div>
  )
}
