import { GetContainerFormat } from "iti"
import type { ApiSessionContainer } from "./api-session.container"
import { ProductDesignStore } from "../stores/product-design.store"
import { ProductRenderPilotFactory } from "../libs/products-render-config/product-render-pilot-factory"
import type { UtilEnvContainer } from "./util.container"
import { EditorMode } from "../libs/products-render-config/types"
import { Debug } from "../services/logger"
import { ProductStore } from "../stores/product.store"
import { AppConfig } from "../app-config/app.config"
import { VariantCustomization, NotFoundError } from "@ph/product-api"
import { DesignStatus } from "../types/design.types"
const debug = Debug("ph:editor:containers:root-store-bootstrap")

interface DesignMeta {
  sku: string
  customization?: VariantCustomization
  editorMode: EditorMode
  designId?: string
  designStatus: DesignStatus
  isOrdered: boolean
  dataReadOnly: boolean
}

/**
 * Used as a fallback when requested SKU is invalid
 */
const fallbackSku =
  "box--mailer-box--52--cardboard-natural--print-monochrome--foil-none"

async function initProductStore(
  appConfig: AppConfig,
  designMeta: DesignMeta,
  maxAttemptCount = 3
): Promise<ProductStore> {
  const { sku, customization, designId } = designMeta

  try {
    return await ProductStore.init(appConfig, sku, customization)
  } catch (e: any) {
    if (e instanceof NotFoundError && !designId) {
      window.Sentry?.captureException(
        new Error(
          `Product not found in PIM: ${sku}. Fallback to default product.`
        )
      )

      return ProductStore.init(appConfig, fallbackSku)
    }

    if (maxAttemptCount > 0) {
      return initProductStore(appConfig, designMeta, maxAttemptCount - 1)
    }

    throw new Error(`Failed to load product: ${sku}. ${e.message}.`)
  }
}

export async function provideBootstrapClassicRootStoreContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer
) {
  const { designApi } = apiSessionContainer
  const { uri, appConfig } = utilEnvContainer

  const getMeta = async (): Promise<DesignMeta> => {
    const designId = uri.getDesignIdParam()
    const hasModeInParams = uri.hasModeInParams()

    if (designId) {
      const {
        sku,
        type,
        status: designStatus,
        ordered: isOrdered,
        customization,
        read_only: dataReadOnly,
      } = await designApi.getMeta(designId)

      return {
        sku,
        editorMode: hasModeInParams ? uri.getMode() : type,
        designId,
        designStatus,
        isOrdered,
        customization,
        dataReadOnly,
      }
    }

    return {
      sku: uri.getSkuFromUrl() || fallbackSku,
      customization: uri.getCustomizationFromUrl(),
      editorMode: uri.getMode(),
      designStatus: "draft",
      isOrdered: false,
      dataReadOnly: false,
    }
  }

  const designMeta = await getMeta()

  debug(`Setting editor mode: ${designMeta.editorMode}`)

  const productStore = await initProductStore(appConfig, designMeta)
  const { product } = productStore

  if (designMeta.sku !== productStore.productSku) {
    uri.setRouteToBasePath()
  }

  const rendererType =
    product.getDefaultVariant().editorAssets?.renderConfig?.type

  const productRenderPilot = await ProductRenderPilotFactory(
    product,
    ProductDesignStore.designFormatVersion,
    rendererType === "dby" ? "dby" : designMeta.editorMode
  )

  return {
    productRenderPilot,
    productStore,
    designMeta,
  }
}

export type BootstrapClassicRootStoreContainer = GetContainerFormat<
  typeof provideBootstrapClassicRootStoreContainer
>
