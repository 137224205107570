var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var ComingSoonBoxProductModel;
(function (ComingSoonBoxProductModel) {
    ComingSoonBoxProductModel["RIGID_BOX"] = "rigidBox";
    ComingSoonBoxProductModel["RIGID_BOX_TWO_PIECES"] = "rigidBoxTwoPieces";
    ComingSoonBoxProductModel["RIGID_DRAWER_BOX"] = "rigidDrawerBox";
    ComingSoonBoxProductModel["SPECIAL_BOX"] = "specialBox";
    ComingSoonBoxProductModel["MAGNETIC_BOX"] = "magneticBox";
    ComingSoonBoxProductModel["CARRIER_BOX"] = "carrierBox";
    ComingSoonBoxProductModel["CARDBOARD_DRAWER_BOX"] = "cardboardDrawerBox";
    ComingSoonBoxProductModel["CAKE_BOX"] = "cakeBox";
    ComingSoonBoxProductModel["MAILER_BOX_FULL_COLOR_DOUBLE_SIDED"] = "mailerBoxFullColorDoubleSided";
    ComingSoonBoxProductModel["PADDED_ENVELOPE"] = "paddedEnvelope";
    ComingSoonBoxProductModel["PADDED_ENVELOPE_ECO"] = "paddedEnvelopeEco";
    ComingSoonBoxProductModel["MUSLIN_BAG"] = "muslinBag";
    ComingSoonBoxProductModel["DOYPACK"] = "doypack";
})(ComingSoonBoxProductModel || (ComingSoonBoxProductModel = {}));
export var ComingSoonBoxProductVariant;
(function (ComingSoonBoxProductVariant) {
    ComingSoonBoxProductVariant["RIGID_BOX_DEFAULT_VARIANT"] = "rigidBox";
    ComingSoonBoxProductVariant["RIGID_BOX_TWO_PIECES_DEFAULT_VARIANT"] = "rigidBoxTwoPieces";
    ComingSoonBoxProductVariant["RIGID_DRAWER_BOX_DEFAULT_VARIANT"] = "rigidDrawerBox";
    ComingSoonBoxProductVariant["SPECIAL_BOX_DEFAULT_VARIANT"] = "specialBox";
    ComingSoonBoxProductVariant["MAGNETIC_BOX_DEFAULT_VARIANT"] = "magneticBox";
    ComingSoonBoxProductVariant["CARRIER_BOX_DEFAULT_VARIANT"] = "carrierBox";
    ComingSoonBoxProductVariant["CARDBOARD_DRAWER_BOX_DEFAULT_VARIANT"] = "cardboardDrawerBox";
    ComingSoonBoxProductVariant["CAKE_BOX_DEFAULT_VARIANT"] = "cakeBox";
    ComingSoonBoxProductVariant["MAILER_BOX_FULL_COLOR_DOUBLE_SIDED_DEFAULT_VARIANT"] = "mailerBoxFullColorDoubleSided";
    ComingSoonBoxProductVariant["PADDED_ENVELOPE_DEFAULT_VARIANT"] = "paddedEnvelope";
    ComingSoonBoxProductVariant["PADDED_ENVELOPE_ECO_DEFAULT_VARIANT"] = "paddedEnvelopeEco";
    ComingSoonBoxProductVariant["MUSLIN_BAG_DEFAULT_VARIANT"] = "muslinBag";
    ComingSoonBoxProductVariant["DOYPACK_DEFAULT_VARIANT"] = "doypack";
})(ComingSoonBoxProductVariant || (ComingSoonBoxProductVariant = {}));
export var ComingSoonBoxProductConsts = __assign(__assign({}, ComingSoonBoxProductVariant), ComingSoonBoxProductModel);
