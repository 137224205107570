import { EditContext, SpaceId } from "../../types"

export const ORIGIN_URL = "https://editor.s3.eu-central-1.amazonaws.com/"

export const SPACES_MAP = {
  [EditContext.FRONT]: [
    {
      spaceId: SpaceId.DEFAULT,
      editable: true,
      context: EditContext.FRONT,
      isDefault: true,
      children: [],
    },
  ],
}
