import React from "react"
import { Switcher } from "../../atoms/Switcher/Switcher"
import { SwitcherItem } from "../../atoms/Switcher/SwitcherItem"
import { SubTab } from "../../../../types/ui.types"
import { useTranslate } from "../../../hooks/useTranslate"
import { AnalyticsSection } from "../../types"

export interface SubTabSwitcherProps {
  subTabs: SubTab[]
  onClick: (subTab: SubTab) => void
  activeSubTab?: SubTab
  analyticsSection?: AnalyticsSection
}

export function SubTabSwitcher({
  subTabs,
  onClick,
  activeSubTab,
  analyticsSection,
}: SubTabSwitcherProps) {
  const t = useTranslate()

  return (
    <Switcher>
      {subTabs.map((subTab) => {
        return (
          <SwitcherItem
            key={subTab.id}
            label={t(subTab.titleIntl)}
            onClick={() => onClick(subTab)}
            active={activeSubTab?.id === subTab.id}
            analyticsSection={analyticsSection}
            analyticsType="menu-item"
            analyticsName={subTab.analyticsName}
            e2eTarget="menu-item"
            e2eTargetName={subTab.id}
          />
        )
      })}
    </Switcher>
  )
}
