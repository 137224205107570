import fabric from "../../../libs/vendors/Fabric"
import {
  SceneDecoration,
  VirtualDielineSpace,
  VirtualDieline,
  PackhelpObject,
} from "../vd-editor/object-extensions/packhelp-objects"
import { RotationMap } from "../../../libs/products-render-config/types"
import { isGroup, isObjectPath, isObjectRect } from "../../../types/asset.types"

export class VirtualDielineParser {
  constructor(
    private readonly canvasWidth: number,
    private readonly rotationMap: RotationMap,
    private readonly sceneDecoration: SceneDecoration
  ) {}

  public parse(svg: string): Promise<VirtualDieline> {
    return new Promise((resolve) => {
      fabric.loadSVGFromString(
        svg,
        (spaces, options): void => {
          resolve(
            this.recreateVirtualDielineObject(
              this.adjustDielineSize(this.createDielineObject(spaces, options))
            )
          )
        },
        this.preprocess
      )
    })
  }

  private createDielineObject(
    spaces: fabric.Object[],
    options: any
  ): fabric.Group {
    const dielineObject = fabric.util.groupSVGElements(spaces, options)

    if (!isGroup(dielineObject)) {
      return new fabric.Group([dielineObject])
    }

    return dielineObject
  }

  private adjustDielineSize(dielineObject: fabric.Group): fabric.Group {
    if (!dielineObject.width || !dielineObject.height) {
      throw new Error("Dieline is not compatible")
    }

    // If vd is smaller than canvas width and height skip scaling
    if (
      dielineObject.width < this.canvasWidth &&
      dielineObject.height < this.canvasWidth
    ) {
      return dielineObject
    }

    if (dielineObject.width > dielineObject.height) {
      dielineObject.scaleToWidth(this.canvasWidth)
      dielineObject.set({
        height: Math.round(dielineObject.height),
      })
    } else {
      dielineObject.scaleToHeight(this.canvasWidth)
      dielineObject.set({
        width: Math.round(dielineObject.width),
      })
    }

    dielineObject.setCoords()

    return dielineObject
  }

  private recreateVirtualDielineObject(
    dielineObject: fabric.Group
  ): VirtualDieline {
    const scaledSpaces = this.createScaledSpaces(dielineObject)
    const { scaleX } = dielineObject.getObjectScaling()

    const virtualDieline = new fabric.Group(scaledSpaces) as VirtualDieline

    virtualDieline.set({
      left: dielineObject.left,
      top: dielineObject.top,
      height: Math.round(Number(dielineObject.height) * scaleX),
      width: Math.round(Number(dielineObject.width) * scaleX),
      id: "virtual_dieline",
      evented: false,
      selectable: false,
      originX: "left",
      originY: "top",
      sceneDecoration: this.sceneDecoration,
    })

    return virtualDieline
  }

  private createScaledSpaces(
    dielineObject: fabric.Group
  ): VirtualDielineSpace[] {
    const { scaleX, scaleY } = dielineObject.getObjectScaling()

    return dielineObject.getObjects().map((object: fabric.Object) => {
      const space = object as PackhelpObject

      const coordsOnDieline = this.getScaledCoordinatesOnDieline(space, scaleX)
      const options = {
        top: coordsOnDieline.top,
        left: coordsOnDieline.left,
        width: coordsOnDieline.width,
        height: coordsOnDieline.height,
        fill: "transparent",
        stroke: "transparent",
        rotation: this.rotationMap[space.id],
        id: space.id,
      }

      if (space.points) {
        return new fabric.Polygon(
          this.getScaledPoints(space, scaleX, scaleY),
          options
        ) as unknown as VirtualDielineSpace
      }

      if (isObjectPath(space)) {
        space.set(options)

        return space as unknown as VirtualDielineSpace
      }

      if (isObjectRect(space)) {
        return new fabric.Rect({
          ...options,
          rx: Math.round(space.rx! * scaleX),
          ry: Math.round(space.ry! * scaleX),
        }) as VirtualDielineSpace
      }

      return new fabric.Rect(options) as VirtualDielineSpace
    })
  }

  private getScaledPoints(space: PackhelpObject, scaleX = 1, scaleY = 1) {
    if (!space.points) {
      return []
    }

    return space.points.map((point): fabric.Point => {
      return new fabric.Point(
        Math.round(scaleX * point.x),
        Math.round(scaleY * point.y)
      )
    })
  }

  private getScaledCoordinatesOnDieline(space: PackhelpObject, scaleX = 1) {
    const dieline = space.group as VirtualDieline
    const left = Number(space.left) + dieline.left + dieline.width / 2
    const top = Number(space.top) + dieline.top + dieline.height / 2

    return {
      left: Math.round(left * scaleX),
      top: Math.round(top * scaleX),
      height: Math.round(Number(space.height) * scaleX),
      width: Math.round(Number(space.width) * scaleX),
    }
  }

  private preprocess(
    svgObject: HTMLElement,
    fabricSpaceObject: VirtualDielineSpace
  ) {
    const rotation = svgObject.getAttribute("rotation")
    if (rotation) {
      fabricSpaceObject.set("rotation", Number(rotation))
    }

    fabricSpaceObject.sceneDecoration = this.sceneDecoration
  }
}
