export var MailerBoxVariant;
(function (MailerBoxVariant) {
    MailerBoxVariant["PRE_PRINTED_MAILER_BOX_ECO_VARIANT"] = "prePrintedMailerBoxEco";
    MailerBoxVariant["PRE_PRINTED_MAILER_BOX_FULL_COLOUR_VARIANT"] = "prePrintedMailerBoxFullColour";
    MailerBoxVariant["MAILER_BOX_PLAIN_DEFAULT_VARIANT"] = "mailerBoxPlain";
    MailerBoxVariant["MAILER_BOX_PLAIN_WHITE_VARIANT"] = "mailerBoxPlainWhite";
    MailerBoxVariant["MAILER_BOX_ECO_VARIANT"] = "mailerBoxEco";
    MailerBoxVariant["MAILER_BOX_ECO_WHITE_VARIANT"] = "mailerBoxEcoWhite";
    MailerBoxVariant["MAILER_BOX_ECO_COLOR_VARIANT"] = "mailerBoxEcoColor";
    MailerBoxVariant["MAILER_BOX_FULL_COLOR_VARIANT"] = "mailerBoxFullColor";
    MailerBoxVariant["MAILER_BOX_LITE_COLOR_VARIANT"] = "mailerBoxLiteColor";
    MailerBoxVariant["MAILER_BOX_OLD_COLOR_BUDGET_VARIANT"] = "mailerBoxOldColorBudget";
    MailerBoxVariant["MAILER_BOX_ECO_PIM_VARIANT"] = "eco-mailer-box--265";
    MailerBoxVariant["MAILER_BOX_ECO_WHITE_PIM_VARIANT"] = "eco-white-mailer-box--267";
    MailerBoxVariant["MAILER_BOX_ECO_COLOR_PIM_VARIANT"] = "eco-color-mailer-box--266";
    MailerBoxVariant["MAILER_BOX_FULL_COLOR_PIM_VARIANT"] = "full-color-mailer-box--264";
    MailerBoxVariant["MAILER_BOX_LITE_COLOR_PIM_VARIANT"] = "lite-color-mailer-box--470";
})(MailerBoxVariant || (MailerBoxVariant = {}));
export var SleevedMailerBoxVariant;
(function (SleevedMailerBoxVariant) {
    SleevedMailerBoxVariant["SLEEVED_MAILER_BOX_VARIANT"] = "sleevedMailerBox";
    SleevedMailerBoxVariant["SLEEVED_MAILER_BOX_PIM_VARIANT"] = "sleeved-mailer-box--276";
})(SleevedMailerBoxVariant || (SleevedMailerBoxVariant = {}));
export var DeliveryMailerBoxVariant;
(function (DeliveryMailerBoxVariant) {
    DeliveryMailerBoxVariant["DELIVERY_MAILER_BOX_VARIANT"] = "deliveryMailerBox";
    DeliveryMailerBoxVariant["DELIVERY_MAILER_BOX_PLAIN_VARIANT"] = "deliveryMailerBoxPlain";
    DeliveryMailerBoxVariant["DELIVERY_MAILER_BOX_PLAIN_WHITE_VARIANT"] = "deliveryMailerBoxPlainWhite";
    DeliveryMailerBoxVariant["DELIVERY_MAILER_BOX_PIM_VARIANT"] = "e-commerce-delivery-box--262";
})(DeliveryMailerBoxVariant || (DeliveryMailerBoxVariant = {}));
export var WhiteInkMailerBoxVariant;
(function (WhiteInkMailerBoxVariant) {
    WhiteInkMailerBoxVariant["MAILER_BOX_WHITE_ON_KRAFT_VARIANT"] = "mailerBoxWhiteInk";
    WhiteInkMailerBoxVariant["MAILER_BOX_WHITE_ON_KRAFT_PIM_VARIANT"] = "white-on-kraft-mailer-box--269";
})(WhiteInkMailerBoxVariant || (WhiteInkMailerBoxVariant = {}));
export var ProductBoxVariant;
(function (ProductBoxVariant) {
    ProductBoxVariant["PRODUCT_BOX_DEFAULT_VARIANT"] = "productBox";
    ProductBoxVariant["PRODUCT_BOX_PIM_VARIANT"] = "tuck-end-box-classic-product-box--271";
})(ProductBoxVariant || (ProductBoxVariant = {}));
export var ProductBoxTwoPiecesVariant;
(function (ProductBoxTwoPiecesVariant) {
    ProductBoxTwoPiecesVariant["PRODUCT_BOX_TWO_PIECES_DEFAULT_VARIANT"] = "productBoxTwoPieces";
    ProductBoxTwoPiecesVariant["PRODUCT_BOX_TWO_PIECES_PIM_VARIANT"] = "two-piece-product-box--272";
})(ProductBoxTwoPiecesVariant || (ProductBoxTwoPiecesVariant = {}));
export var ProductBoxWineBoxVariant;
(function (ProductBoxWineBoxVariant) {
    ProductBoxWineBoxVariant["PRODUCT_BOX_WINE_BOX_DEFAULT_VARIANT"] = "productBoxWineBox";
    ProductBoxWineBoxVariant["PRODUCT_BOX_WINE_BOX_PIM_VARIANT"] = "wine-box--273";
})(ProductBoxWineBoxVariant || (ProductBoxWineBoxVariant = {}));
export var ShippingBoxVariant;
(function (ShippingBoxVariant) {
    ShippingBoxVariant["SHIPPING_BOX_DEFAULT_VARIANT"] = "shippingBox";
    ShippingBoxVariant["SHIPPING_BOX_PLAIN_DEFAULT_VARIANT"] = "shippingBoxPlain";
    ShippingBoxVariant["SHIPPING_BOX_PLAIN_V2_DEFAULT_VARIANT"] = "shippingBoxPlainV2";
    ShippingBoxVariant["SHIPPING_BOX_PLAIN_V2_PIM_VARIANT"] = "shipping-box--274";
})(ShippingBoxVariant || (ShippingBoxVariant = {}));
export default Object.assign({}, MailerBoxVariant, DeliveryMailerBoxVariant, SleevedMailerBoxVariant, WhiteInkMailerBoxVariant, ProductBoxVariant, ProductBoxTwoPiecesVariant, ProductBoxWineBoxVariant, ShippingBoxVariant);
