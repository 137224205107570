import React from "react"
import { observer } from "mobx-react-lite"
import { Logo } from "../../atoms/Logo/Logo"
import { HeaderEditorModeSwitcher } from "./partials/HeaderEditorModeSwitcher"
import styles from "./Header.module.scss"
import { HeaderShortcutMenu } from "./partials/HeaderShortcutMenu"
import { HeaderActions } from "./partials/HeaderActions"
import { ProductInfo } from "../../molecules/ProductInfo/ProductInfo"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

export const Header = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { editorModeController } = containerSet.ui
  const { editorMode, isDesignReadOnly } = editorModeController
  const isNavVisible = editorMode !== "designer" && !isDesignReadOnly

  return (
    <header className={styles.header}>
      <Logo />

      {isNavVisible && (
        <>
          <HeaderEditorModeSwitcher />
          <HeaderShortcutMenu />
        </>
      )}

      <div className={styles["right-side"]}>
        <ProductInfo />
        <HeaderActions />
      </div>
    </header>
  )
})
