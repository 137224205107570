import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./Menu.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"
import { Logo } from "../../atoms/Logo/Logo"
import { MenuNav } from "./partials/MenuNav"
import { MenuUserInfo } from "./partials/MenuUserInfo"
import { MenuProjectName } from "./partials/MenuProjectName"
import { CloseButton } from "../../atoms/CloseButton/CloseButton"
import cxBinder from "classnames/bind"

const cx = cxBinder.bind(styles)

export const Menu = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { isMenuOpen, isMenuClosing } = ui.appUiController

  const onCloseClick = () => {
    ui.appUiController.toggleMenu()
  }

  if (!isMenuOpen) {
    return null
  }

  const isMenuVisible = isMenuOpen && !isMenuClosing

  return (
    <>
      <div
        className={cx("overlay", { visible: isMenuVisible })}
        onClick={onCloseClick}
      ></div>
      <div className={cx("menu", { open: isMenuVisible })}>
        <header className={styles.header}>
          <Logo />
          <CloseButton onClick={onCloseClick} />
        </header>
        <div className={styles.content}>
          <MenuProjectName />
          <MenuNav />
        </div>
        <footer className={styles.footer}>
          <MenuUserInfo />
        </footer>
      </div>
    </>
  )
})
