import React from "react"
import { PickerToggle } from "../../../atoms/PickerToggle/PickerToggle"
import { usePortal } from "../../../../hooks/usePortal"
import { ColorListArray } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import { Colour } from "../../../../../models/colour"
import { AvailableColourModes } from "../../../../../libs/products-render-config/types"
import { useContainer } from "../../../../../_containers-react/_editor-app-hooks"
import styles from "./ColorConfigurator.module.scss"
import { PantoneConfigurator } from "../../../molecules/PantoneConfigurator/PantoneConfigurator"
import { HexConfigurator } from "../../../molecules/HexConfigurator/HexConfigurator"
import { AnalyticsSection } from "../../../types"

export interface ColorConfiguratorProps {
  title?: string
  selectedColor: Colour | undefined
  colorsList?: ColorListArray[]
  onColorSelect: (color: Colour) => void
  analyticsSection?: AnalyticsSection
  analyticsName?: string
}

export function ColorConfigurator({
  selectedColor,
  colorsList,
  title,
  onColorSelect,
  analyticsSection,
  analyticsName,
}: ColorConfiguratorProps) {
  const { openPortal, closePortal, isPortalOpen, Portal } = usePortal({
    offsetTop: 6,
  })

  const onPickerClick = (e) => {
    isPortalOpen ? closePortal(e) : openPortal(e)
  }

  const [designAndProductDriverContainer] =
    useContainer().designAndProductDriver

  if (!designAndProductDriverContainer) {
    return null
  }

  const { productDriver } = designAndProductDriverContainer

  const { productRenderPilot } = productDriver.state
  const pantoneColorsPreset =
    productRenderPilot !== undefined
      ? productRenderPilot.getPantoneColorsPreset()
      : undefined

  const isMonoPantone =
    productRenderPilot.getColorMode() === AvailableColourModes.MONO_PANTONE

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <PickerToggle
          backgroundColor={selectedColor}
          onClick={onPickerClick}
          analyticsSection={analyticsSection}
          analyticsType="picker-toggle"
          analyticsName={analyticsName}
        />
      </header>

      {isPortalOpen && (
        <Portal>
          <div className={styles.portal}>
            {isMonoPantone ? (
              <PantoneConfigurator
                selectedColor={selectedColor}
                onColorSelect={onColorSelect}
                pantoneColorsPreset={pantoneColorsPreset}
                analyticsSection={analyticsSection}
              />
            ) : (
              <HexConfigurator
                selectedColor={selectedColor}
                onColorSelect={onColorSelect}
                colorsList={colorsList}
                analyticsSection={analyticsSection}
              />
            )}
          </div>
        </Portal>
      )}
    </div>
  )
}
