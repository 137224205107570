import { PackhelpObject } from "../../../../object-extensions/packhelp-objects"
import fabric from "../../../../../../../libs/vendors/Fabric"
import { PatternReplicableSpace, LayoutConfig } from "../types"
import { RotationHelper } from "../../helpers/rotation.helper"

export abstract class AbstractLayoutProcessor {
  protected readonly patternSourceCanvas: fabric.StaticCanvas
  protected shouldPrepareToProcess: boolean

  constructor(
    protected readonly space: PatternReplicableSpace,
    protected replicableObject: PackhelpObject,
    protected config: LayoutConfig,
    protected safeZoneSize = 0
  ) {
    this.patternSourceCanvas = new fabric.StaticCanvas(null, {
      // Retina scaling should be disabled for static canvas used for patterns
      // https://github.com/fabricjs/fabric.js/issues/4645
      enableRetinaScaling: false,
    })
    this.shouldPrepareToProcess = true
  }

  public abstract process(): Promise<void>
  protected abstract prepareReplicableObjectToProcess(): Promise<void>

  public setReplicableObject(replicableObject: PackhelpObject) {
    this.replicableObject = replicableObject
    this.shouldPrepareToProcess = true
  }

  public setScale(scale: number) {
    this.config.scale = scale
  }

  public setRows(rows: number) {
    this.config.rows = rows
  }

  public setCols(cols: number) {
    this.config.cols = cols
  }

  public setAngle(angle: number) {
    this.config.angle = angle
  }

  public setConfig(config: LayoutConfig) {
    this.config = config
  }

  public setSafeZoneSize(safeZoneSize: number) {
    this.safeZoneSize = safeZoneSize
  }

  public getPatternSourceCanvas(): fabric.StaticCanvas {
    return this.patternSourceCanvas
  }

  public getConfig(): LayoutConfig {
    return this.config
  }

  public getReplicableObject(): PackhelpObject {
    return this.replicableObject
  }

  public getSafeZoneSize(): number {
    return this.safeZoneSize
  }

  protected cloneObject(object: PackhelpObject): Promise<PackhelpObject> {
    return new Promise((resolve) => {
      object.clone((clonedObject) => {
        resolve(clonedObject)
      })
    })
  }

  protected scaleObject(object: PackhelpObject, objectScaleRatio = 1) {
    if (object.getScaledWidth() >= object.getScaledHeight()) {
      object.scaleToWidth(
        this.patternSourceCanvas.getWidth() *
          objectScaleRatio *
          this.config.scale
      )
    } else {
      object.scaleToHeight(
        this.patternSourceCanvas.getHeight() *
          objectScaleRatio *
          this.config.scale
      )
    }
  }

  protected moveObject(object: PackhelpObject, centerPoint: fabric.Point) {
    object.left = centerPoint.x - object.getScaledWidth() / 2
    object.top = centerPoint.y - object.getScaledHeight() / 2
  }

  protected rotateObject(object: PackhelpObject, centerPoint: fabric.Point) {
    const angle = this.space.rotation * -1 + this.config.angle
    RotationHelper.rotateObject(object, centerPoint, angle)
    object.angle = angle
  }

  protected get safeZoneSizeSum(): number {
    return this.safeZoneSize * 2
  }
}
