import { Alpha2CountryCode } from "@ph/api-studio"

export const payments = {
  INVOICE_TYPE_PRO_FORMA: "proforma",
  INVOICE_TYPE_NORMAL: "normal",

  PAYMENT_METHOD_TRANSFER: "check",
  PAYMENT_METHOD_PAYPAL: "paypal",
  PAYMENT_METHOD_STRIPE: "stripe",
  PAYMENT_METHOD_STRIPE_INTENT: "stripeIntent",
  PAYMENT_METHOD_PRZELEWY24: "p24",
  PAYMENT_METHOD_IDEAL: "ideal",
  PAYMENT_METHOD_BANCONTACT: "bancontact",
  PAYMENT_METHOD_EPS: "eps",
  PAYMENT_METHOD_GIROPAY: "giropay",
  PAYMENT_METHOD_SOFORT: "sofort",
  PAYMENT_METHOD_CARD: "card",
  PAYMENT_METHOD_HOKODO: "hokodo",

  /** not used in checkout v2 */
  PAYMENT_SCHEDULE_PAY_FULL: "payFull",
  PAYMENT_SCHEDULE_PAY_INSTALLMENT: "payInstallment",

  /**
   * Please refrain from using Spree country IDs on front end
   * Use iso Alpha-2 codes instead: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
   * Why are these in here anyway if we have constants.spree? ...sigh...
   */
  POLAND_COUNTRY_ID: 179,
  UK_COUNTRY_ID: 77,
  DE_COUNTRY_ID: 57,
  NOT_EU_COUNTRIES_ID: [43, 167, 77, 232],
  NOT_EU_COUNTRIES_ISO_2: ["CH", "NO", "GB", "US"] as Alpha2CountryCode[],

  STRIPE_CARD_URL: "gateway/stripe/card",
} as const
