import type { ProductSwitcherUiController } from "../controllers/product-switcher-ui.controller"
import type { ListingStore } from "../stores/listing.store"
import type { ApiSessionContainer } from "./api-session.container"
import type { BootstrapClassicRootStoreContainer } from "./root-store-bootstrap.container"
import type { UtilEnvContainer } from "./util.container"
import { DesignAndProductDriverContainer } from "./design-and-product-driver.container"

async function importDeps() {
  const { ListingStore } = await import("../stores/listing.store")
  const { ProductSwitcherUiController } = await import(
    "../controllers/product-switcher-ui.controller"
  )

  return {
    ListingStore,
    ProductSwitcherUiController,
  }
}

type ProductSwitcherMaybeContainer =
  | {
      available: true
      listingStore: ListingStore
      productSwitcherUiController: ProductSwitcherUiController
    }
  | {
      available: false
      listingStore: undefined
      productSwitcherUiController: undefined
    }

export async function provideProductSwitcherMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  rootBootstrapper: BootstrapClassicRootStoreContainer,
  designAndProductContainer: DesignAndProductDriverContainer
): Promise<ProductSwitcherMaybeContainer> {
  const { appConfig } = utilEnvContainer

  if (!appConfig.api.ecommerce.features.ecommerce.productSwitcher) {
    return {
      available: false,
      listingStore: undefined,
      productSwitcherUiController: undefined,
    }
  }

  const { uri } = utilEnvContainer
  const { listingApiService } = apiSessionContainer
  const { productStore } = rootBootstrapper
  const { productDesignStore, productDesignUiController } =
    designAndProductContainer

  const { ListingStore, ProductSwitcherUiController } = await importDeps()

  const listingStore = ListingStore.init(
    { appConfig, listingApiService },
    { productStore }
  )

  const productSwitcherUiController = new ProductSwitcherUiController(
    { appConfig, uri, productDesignUiController },
    { productStore, productDesignStore }
  )

  return {
    available: true,
    listingStore,
    productSwitcherUiController,
  }
}
