import THREE from "../../../../libs/vendors/THREE"
import { ModelStrategy } from "./model.strategy"
import {
  ModelContext,
  TextureDefinition,
} from "../../../../libs/products-render-config/types"
import { SvgModelConfig } from "../types"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { SvgModel } from "../../../../libs/services/svg-model/svg.model"
import md5 from "md5"

export class SvgModelStrategy extends ModelStrategy {
  private svgModel?: SvgModel
  private id?: string

  public getModel(): THREE.Group | undefined {
    return this.svgModel?.getModel()
  }

  public async init(config: SvgModelConfig): Promise<THREE.Group> {
    this.svgModel = await this.initSvgModel(config)
    this.svgModel.setVirtualDielineCanvases(this.vdCanvases)
    await this.svgModel.setModelTextures(config.textureDefinitions)
    this.svgModel.touchTextures()

    this.setFoldingPercentage(this.getTargetFoldingPercentage(config.context))

    return this.svgModel.getModel()
  }

  public isFoldingSupported(): boolean {
    return true
  }

  public getFoldingPercentage(): number {
    return this.svgModel?.getFoldingPercentage() || 0
  }

  public setFoldingPercentage(percentage: number): void {
    this.svgModel?.setFoldingPercentage(percentage)
  }

  public getTargetFoldingPercentage(modelContext: ModelContext): number {
    return this.svgModel?.getTargetFoldingPercentage(modelContext) || 100
  }

  public getFoldingStep(): number {
    return 0.5
  }

  public fitModelToScreen(orbitControls: OrbitControls): void {
    if (!this.svgModel) {
      return
    }

    const camera = orbitControls.object as THREE.PerspectiveCamera
    camera.zoom = this.svgModel.getScaleToFitScreen()

    orbitControls.update()
  }

  public dispose(): void {
    if (!this.svgModel) {
      return
    }

    this.svgModel = undefined
  }

  public async setModelTextures(
    textureDefinitions: TextureDefinition[]
  ): Promise<void> {
    await this.svgModel?.setModelTextures(textureDefinitions)
  }

  public touchTextures(): void {
    this.svgModel?.touchTextures()
  }

  public isFogRequired(): boolean {
    return true
  }

  private async initSvgModel(config: SvgModelConfig): Promise<SvgModel> {
    const id = md5(config.svg)

    if (this.svgModel && this.id === id) {
      return this.svgModel
    }

    this.id = id

    return SvgModel.loadFromString(config.svg, config.settings)
  }
}
