export type SnappingOriginOptions =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "center"
  | "none"

export type SnappingOrigin = {
  x: SnappingOriginOptions
  y: SnappingOriginOptions
}

export type SnappingRelatedToSpace = {
  type: "space"
  spaceName: string
  origin: SnappingOrigin
}

export type SnappingRelatedToObject = {
  type: "object"
  objectId: string
  origin: SnappingOrigin
}

export type SnappingRelatedToAnything =
  | SnappingRelatedToObject
  | SnappingRelatedToSpace

export type SnappingRelatedToTwoObjects = {
  type: "betweenTwo"
  relations: [SnappingRelatedToAnything, SnappingRelatedToAnything]
}

export type SnappingRelatedTo =
  | SnappingRelatedToSpace
  | SnappingRelatedToObject
  | SnappingRelatedToTwoObjects

export type Snapping = {
  origin: SnappingOrigin
  relatedTo: SnappingRelatedTo
}

export interface WithSnapping {
  snapping?: Snapping
}

export const isSimpleSnapping = (
  snappingRelatedTo: any
): snappingRelatedTo is SnappingRelatedToAnything => {
  return (
    snappingRelatedTo.type === "space" || snappingRelatedTo.type === "object"
  )
}
