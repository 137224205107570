import React from "react"
import { I18N } from "../../../i18n"
import { useTranslate } from "../../../hooks/useTranslate"
import { Error } from "../Error/Error"
import { useContainer } from "../../../../_containers-react/_editor-app-hooks"

const i18n = I18N.errors.sizeNotSupported

export function ErrorScreenSizeNotSupported() {
  const t = useTranslate()
  const [envUtil] = useContainer().envUtil

  if (!envUtil) {
    return null
  }

  const onClick = () => {
    envUtil.uri.redirectToHomepage()
  }

  return (
    <Error
      title={t(i18n.title)}
      description={t(i18n.description)}
      link={{ label: t(i18n.button), onClick: onClick }}
    />
  )
}
