import React from "react"
import styles from "./DropdownItem.module.scss"
import { SvgIcon } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

export interface DropdownButtonProps extends AnalyticsProps, TestsProps {
  iconUrl?: string
  label: string
  onClick: () => void
}

export function DropdownButton({
  label,
  iconUrl,
  onClick,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: DropdownButtonProps) {
  return (
    <button
      className={styles.item}
      onClick={onClick}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      {iconUrl && <SvgIcon url={iconUrl} size="s" />}
      {label}
    </button>
  )
}
