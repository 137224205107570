export const products = {
  DEFAULT_QUANTITY: 30,
  DEFAULT_LINE_ITEM_RANGES: [
    30, 60, 90, 120, 240, 500, 750, 1000, 1500, 2000, 2500,
  ],

  EXPRESS_PRODUCTION: "express",

  PARAM_BOX_SKU: "sku",
  PARAM_BOX_QUANTITY: "quantity",

  API_VARIANTS_CONTEXT: "context",
  API_VARIANTS_CONTEXT_OFFERS: "offer",
}
