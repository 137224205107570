import React from "react"
import { observer } from "mobx-react-lite"
import { Toolbar } from "../Toolbar/Toolbar"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { ToolbarCheckbox } from "../../../molecules/ToolbarCheckbox/ToolbarCheckbox"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"
import { colorsListDemoWithBlankColor } from "../../../../../libs/colors/hex-colors.list"
import { ColorConfigurator } from "../../configurators/ColorConfigurator/ColorConfigurator"
import Colour from "../../../../../models/colour"
import { ToolbarSection } from "../Toolbar/ToolbarSection"

const i18n = I18N.toolbar.space

export const ToolbarSpace = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { spaceToolbarController } = containerSet.ui
  const {
    isToolbarVisible,
    isBackgroundColorPickerVisible,
    selectedBackgroundColor,
    isPatternToggleVisible,
    isPatternVisible,
    isBackgroundImageToggleVisible,
    isBackgroundImageVisible,
  } = spaceToolbarController

  const onPatternToggle = () => {
    spaceToolbarController.togglePattern()
  }

  const onBackgroundColorSelect = (color: Colour) => {
    spaceToolbarController.setBackgroundColor(color)
  }

  const onBackgroundImageToggle = () => {
    spaceToolbarController.toggleBackgroundImage()
  }

  if (!isToolbarVisible) {
    return null
  }

  return (
    <Toolbar e2eTargetName="space">
      <ToolbarSection>
        {isBackgroundColorPickerVisible && (
          <ColorConfigurator
            title={t(i18n.bgColor)}
            onColorSelect={onBackgroundColorSelect}
            selectedColor={selectedBackgroundColor}
            colorsList={colorsListDemoWithBlankColor}
            analyticsSection="design-toolbar"
            analyticsName="space-color"
          />
        )}

        {isPatternToggleVisible && (
          <ToolbarCheckbox
            label={t(i18n.togglePattern)}
            checked={isPatternVisible}
            onChange={onPatternToggle}
            analyticsName="pattern"
            analyticsId={isPatternVisible ? "enable" : "disable"}
          />
        )}

        {isBackgroundImageToggleVisible && (
          <ToolbarCheckbox
            label={t(i18n.toggleBgImage)}
            checked={isBackgroundImageVisible}
            onChange={onBackgroundImageToggle}
            analyticsName="background-image"
            analyticsId={isPatternVisible ? "enable" : "disable"}
          />
        )}
      </ToolbarSection>
    </Toolbar>
  )
})
