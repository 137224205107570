var PartMaterial;
(function (PartMaterial) {
    PartMaterial["PRINT_MATERIAL_PAPER_WHITE"] = "paperWhite";
    PartMaterial["PRINT_MATERIAL_PAPER_NATURAL"] = "paperNatural";
    PartMaterial["PRINT_MATERIAL_WHITE"] = "white";
    PartMaterial["PRINT_MATERIAL_KRAFT"] = "kraft";
    PartMaterial["PRINT_MATERIAL_BLACK"] = "black";
    PartMaterial["PRINT_MATERIAL_ORGANIC_COTTON"] = "organicCotton";
    PartMaterial["PRINT_MATERIAL_RECYCLED_COTTON"] = "recycledCotton";
})(PartMaterial || (PartMaterial = {}));
export default PartMaterial;
