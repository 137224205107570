import fabric from "../../../../../../../libs/vendors/Fabric"
import {
  loadSvgFromString,
  defaultBackgroundSettings,
  defaultTextSettings,
  Colors,
  placeholderMargin,
} from "./logo-placeholder-slot.helper"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import { I18N } from "../../../../../../../ui/i18n"

const spinner = `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<path fill="#3B61FF" d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C6.49019012,16 5.07806649,15.5817554 3.87317002,14.8548071 L5.34593336,13.3825634 C6.14610275,13.7778688 7.04711062,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,8.89946844 2.19792306,9.75270947 2.55259312,10.518547 L2.55259312,10.518547 L1.07092854,12.0011597 C0.389833167,10.8242042 0,9.4576273 0,8 C0,3.581722 3.581722,0 8,0 Z" id="path-1" />
</svg>`

export async function createLoaderPlaceholder(
  size: number
): Promise<fabric.Group> {
  const backgroundPlaceholder = new fabric.Circle(defaultBackgroundSettings)
  const formatMessage = await getFormatMessage()

  const text = formatMessage({ id: I18N.editorTools.logoPlaceholder.loading })

  const placeholderText = new fabric.IText(text.toUpperCase(), {
    ...defaultTextSettings,
    fill: Colors.black,
    originY: "center",
    top: 20,
    fontSize: 12,
  })

  const spinnerIcon = await loadSvgFromString(spinner)

  spinnerIcon.set({
    scaleX: 2,
    scaleY: 2,
    originX: "center",
    originY: "center",
    top: -15,
  })

  const placeholder = new fabric.Group(
    [backgroundPlaceholder, placeholderText, spinnerIcon],
    {
      width: size,
      originX: "center",
      originY: "center",
      scaleX: size / (backgroundPlaceholder.width! + placeholderMargin),
      scaleY: size / (backgroundPlaceholder.width! + placeholderMargin),
    }
  )

  return placeholder
}
