export function createCanvas(size: {
  width: number
  height: number
}): HTMLCanvasElement {
  const canvas = document.createElement("canvas")
  canvas.width = size.width
  canvas.height = size.height

  return canvas
}
