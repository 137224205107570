import { useMemo } from "react"
import { BadgeColorTypes } from "../../../atoms/Badge/Badge"
import type {
  PriceBreak,
  PriceDisplayOptions,
  PriceFormatter,
  TranslatedKeys
} from "../types"
import { calculatePromoPrice } from "../utils/calculate-promo-price"
import {
  createBasicQuantityItem,
  createCalculateQuantityItem
} from "../utils/quantity-items"

type Badge = { label?: string; color?: BadgeColorTypes }

type UseQuantityItemsParams = {
  options: PriceDisplayOptions
  piecePriceFormatter: PriceFormatter
  priceBreaks: PriceBreak[]
  priceFormatter: PriceFormatter
  translations: TranslatedKeys
}

export const useQuantityItems = ({
  options = {
    withPrice: false,
    withPricePerPiece: false,
    withDeal: false,
    withTax: false
  },
  priceFormatter,
  priceBreaks,
  piecePriceFormatter,
  translations
}: UseQuantityItemsParams) => {
  return useMemo(
    () =>
      priceBreaks.map(
        ({
          currency,
          deal,
          isCustomValue,
          isDynamicPricingAvailable,
          isLoadingDynamicPricing,
          outOfStock,
          pricePerPiece,
          priceTotal,
          quantity
        }) => {
          if (isDynamicPricingAvailable && !pricePerPiece) {
            return createCalculateQuantityItem({
              quantity: String(quantity),
              label: translations.calculateLabel,
              isLoading: isLoadingDynamicPricing
            })
          }

          const showTaxForQuantityItem =
            options.withTax && !isDynamicPricingAvailable

          const priceDisplayOptions = {
            ...options,
            withTax: showTaxForQuantityItem
          }

          const getBadge = (): Badge => {
            if (isCustomValue && !isDynamicPricingAvailable) {
              return {
                label: translations.customValueLabel
              }
            }

            if (outOfStock) {
              return {
                label: translations.outOfStockLabel,
                color: BadgeColorTypes.WhiteFour
              }
            }

            if (options.fixedPromo) {
              return {
                label: `-${options.fixedPromo}%`,
                color: BadgeColorTypes.WhiteThreeWithEntan
              }
            }

            return {}
          }

          const { fixedPromo } = options
          const badge = getBadge()

          return createBasicQuantityItem(
            {
              badge: badge.label,
              badgeColor: badge.color,
              currency,
              discount: deal,
              outOfStock,
              piecePrice: calculatePromoPrice(fixedPromo, pricePerPiece),
              promoPrice: fixedPromo
                ? calculatePromoPrice(fixedPromo, priceTotal)
                : undefined,
              price: priceTotal,
              quantity: String(quantity)
            },
            priceDisplayOptions,
            priceFormatter,
            piecePriceFormatter
          )
        }
      ),
    [priceBreaks]
  )
}
