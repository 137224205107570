import VirtualDielineEditor from "../virtual-dieline-editor"
import { DesignVersion } from "../../../../types/design.version"
import migrations from "./virtual-dieline-migrations"
import { VirtualDielineMigration } from "./virtual-dieline-migrations/virtual-dieline-migration.interface"

export class VirtualDielineMigrator {
  private readonly migrations: VirtualDielineMigration[]

  constructor(
    private virtualDielineEditor: VirtualDielineEditor,
    private designDataFormatVersion: DesignVersion
  ) {
    this.migrations = migrations.map((klass) => {
      return new klass()
    })
  }

  public async migrate() {
    for (const migration of this.migrations) {
      if (this.designDataFormatVersion < migration.designVersion) {
        await migration.migrate(this.virtualDielineEditor)
      }
    }
  }
}
