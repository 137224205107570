import React, { PropsWithChildren } from "react"
import cxBinder from "classnames/bind"

import styles from "./PickerToggle.module.scss"
import Colour from "../../../../models/colour"
import { AnalyticsProps, TestsProps } from "../../types"
const cx = cxBinder.bind(styles)

export enum PickerToggleType {
  oval = "oval",
  rounded = "rounded",
}

interface PickerToggleProps extends AnalyticsProps, TestsProps {
  type?: PickerToggleType
  backgroundColor?: Colour
  onClick?: (e: any) => void
}

export const PickerToggle = ({
  type = PickerToggleType.oval,
  backgroundColor,
  children,
  onClick,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget = "button",
  e2eTargetName = "picker-toggle",
}: PropsWithChildren<PickerToggleProps>) => {
  const hasBackground = !!backgroundColor?.getHex()
  const hasContent = !!children

  return (
    <button
      className={cx("toggle", type)}
      onClick={onClick}
      disabled={!onClick}
      analytics-section={analyticsSection}
      analytics-type={analyticsType}
      analytics-name={analyticsName}
      analytics-id={analyticsId}
      e2e-target={e2eTarget}
      e2e-target-name={e2eTargetName}
    >
      <div
        style={{ backgroundColor: backgroundColor?.getHex() }}
        className={cx("color", {
          empty: !hasBackground && !hasContent,
        })}
      >
        {children}
      </div>
    </button>
  )
}
