import fabric from "../../../../../../../libs/vendors/Fabric"
import { I18N } from "../../../../../../../ui/i18n"
import { getFormatMessage } from "shared-libs/src/js/libs/others/i18n"
import {
  loadSvgFromString,
  defaultTextSettings,
  Colors,
  placeholderMargin,
} from "./logo-placeholder-slot.helper"

const plus = `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19" fill="#3B61FF" stroke="white" stroke-width="2"/>
<path d="M29.5999 18.9334H21.0665V10.4H18.9333V18.9334H10.3999V21.0669H18.9333V29.6H21.0665V21.0669H29.5999V18.9334Z" fill="white"/>
</svg>
`
const trash = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="16" cy="16" r="15.5" fill="#3B61FF" stroke="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.1231 10.0236L22.3443 10.8868C23.0559 11.0768 23.4785 11.8086 23.2879 12.5196L10.4077 9.0692C10.5991 8.3576 11.3295 7.9356 12.0405 8.1258L15.2605 8.9884C15.4513 8.2776 16.1831 7.8556 16.8941 8.0458L18.1823 8.3908C18.8941 8.581 19.3159 9.3126 19.1251 10.0236H19.1231ZM8.6665 14.6668V13.3334V13.3332H21.9999V14.6666H20.6665L19.3333 24H11.3333L9.9999 14.6668H8.6665ZM13.3333 22H14.6665V15.3334H13.3333V22ZM15.9999 22H17.3333V15.3334H15.9999V22Z" fill="white"/>
</svg>`

export async function createUserInteractivePlaceholder(
  size: number
): Promise<fabric.Group> {
  const formatMessage = await getFormatMessage()
  const text = formatMessage({ id: I18N.editorTools.logoPlaceholder.addLogo })

  const placeholderText = new fabric.IText(text.toUpperCase(), {
    ...defaultTextSettings,
    originY: "bottom",
    top: 30,
    fill: Colors.blue,
  })

  const backgroundPlaceholder = new fabric.Circle({
    selectable: false,
    hasControls: false,
    originX: "center",
    originY: "center",
    radius: 75,
    fill: Colors.white,
    strokeDashArray: [3, 3],
    stroke: Colors.blue,
  })

  const trashIcon = await loadSvgFromString(trash)
  const plusIcon = await loadSvgFromString(plus)

  trashIcon.set({
    left: 55,
    top: -placeholderMargin,
    scaleX: 0.9,
    scaleY: 0.9,
    opacity: 0,
    hoverCursor: "pointer",
    lockUniScaling: true,
    id: "logo-placeholder-trash-button",
  })

  plusIcon.set({
    top: -20,
    scaleX: 0.9,
    scaleY: 0.9,
    originX: "center",
    originY: "center",
    hoverCursor: "pointer",
    id: "logo-placeholder-plus-button",
  })

  const placeholder = new fabric.Group(
    [backgroundPlaceholder, plusIcon, placeholderText, trashIcon],
    {
      subTargetCheck: true,
      width: size,
      originX: "center",
      originY: "center",
      scaleX: size / (backgroundPlaceholder.width! + placeholderMargin),
      scaleY: size / (backgroundPlaceholder.width! + placeholderMargin),
    }
  )

  placeholder.on("mouseover", function () {
    trashIcon.opacity = 1
    backgroundPlaceholder.fill = Colors.blue
    placeholderText.fill = Colors.white
    placeholder.canvas?.renderAll()
  })

  placeholder.on("mouseout", function () {
    trashIcon.opacity = 0
    backgroundPlaceholder.fill = Colors.white
    placeholderText.fill = Colors.blue
    placeholder.canvas?.renderAll()
  })

  return placeholder
}
