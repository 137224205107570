import { EffectControllable } from "./effect-controllable.interface"
import { TextObjectController } from "../../../render-engine/modules/vd-editor/modules/assets-module/canvas-object-controller/text-object.controller"
import Colour from "../../../models/colour"
import { EffectOptions, EffectUiConfig } from "../types"

export class StrokeEffectController implements EffectControllable {
  constructor(private objectController: TextObjectController) {}

  public getOptions(): EffectOptions {
    const color = this.objectController.getStrokeColor()
    const thickness = this.objectController.getStrokeThickness()

    return {
      color,
      thickness,
    }
  }

  public getUiConfig(): EffectUiConfig {
    return {
      thickness: {
        min: 0,
        max: 10,
        step: 1,
      },
      color: true,
    }
  }

  public init() {
    if (this.objectController.hasStroke()) {
      return
    }

    this.apply({
      thickness: 2,
      color: new Colour("#f5a0c5"),
    })
  }

  public apply(options: EffectOptions) {
    this.objectController.setStyles({
      stroke: options.color,
      strokeWidth: options.thickness,
    })
  }

  public clear() {
    this.objectController.setStyles({
      strokeWidth: 0,
    })
  }
}
