import { ConsentCategory } from "../consts"
import type { ConsentCategoryType, Preferences } from "../types"
import {
  getPreferencesArrays,
  loadPreferences,
  savePreferences,
} from "./preferences-handler"

const activeConsents = {}

export const activateConsents = (preferences: Preferences) => {
  if (!preferences) {
    updateRunningConsents()
    return
  }

  let hasActiveConsentsChanged = false

  for (const category in preferences) {
    if (activeConsents[category] === preferences[category]) {
      continue
    }
    hasActiveConsentsChanged = true
    if (preferences[category]) {
      activateConsent(category as ConsentCategoryType)
      // Activate essential consents if any other consents are active
      if (!activeConsents[ConsentCategory.Essential]) {
        activateConsent(ConsentCategory.Essential)
      }
    } else {
      deactivateConsent(category as ConsentCategoryType)
    }
  }

  if (hasActiveConsentsChanged) {
    updateRunningConsents()
  }
}

function activateConsent(category: ConsentCategoryType) {
  activeConsents[category] = true
}
function deactivateConsent(category: ConsentCategoryType) {
  activeConsents[category] = false
}

export const isConsentActive = (category: ConsentCategoryType) => {
  return activeConsents[category]
}

export const getActiveConsents = () => {
  return activeConsents
}

export const fixExistingConsents = (preferences: Preferences | undefined) => {
  if (!preferences) return

  // adds marketing and analytics consent when marketingAndAnalytics is active

  if (preferences[ConsentCategory.MarketingAndAnalytics]) {
    preferences["marketing"] = true
    preferences["analytics"] = true
  }
  savePreferences(preferences)
}

export const updateRunningConsents = () => {
  const preferences = loadPreferences()
  if (!preferences || !window.analytics) {
    console.warn("[Analytics] No preferences or analytics instance found.")
    return
  }

  console.info("[Analytics] Updating consents")

  window.analytics.consent({
    consentManagement: getPreferencesArrays(),
  })
}
