import { PantonePickerColorObject } from "dsl/src/organisms/PickerColor/PickerColorTypes"
import PANTONE_COLORS_MAP from "dsl/src/organisms/PickerPantone/PantoneColorsMap"
import _uniqBy from "lodash/uniqBy"

const COLORS = [
  ..._uniqBy(PANTONE_COLORS_MAP, (color) => color.pantoneName),
  {
    pantoneName: "5395 U",
    color: "#464c59",
    r: 70,
    g: 67,
    b: 89,
    h: 0.6139,
    s: 0.213,
    v: 0.349,
  },
  // grey
  {
    pantoneName: "2162 U",
    color: "#9da5b3",
    r: 157,
    g: 165,
    b: 179,
    h: 0.6056,
    s: 0.123,
    v: 0.702,
  },
  // light blue
  {
    pantoneName: "3105 U",
    color: "#4ccede",
    r: 76,
    g: 206,
    b: 222,
    h: 0.5194,
    s: 0.658,
    v: 0.871,
  },
  // dark blue
  {
    pantoneName: "2147 U",
    color: "#424f84",
    r: 66,
    g: 79,
    b: 132,
    h: 0.6333,
    s: 0.5,
    v: 0.518,
  },
  // yellow
  {
    pantoneName: "Yellow U",
    color: "#ffe800",
    r: 255,
    g: 232,
    b: 0,
    h: 0.1528,
    s: 1,
    v: 1,
  },
  // green
  {
    pantoneName: "2418 U",
    color: "#008759",
    r: 0,
    g: 135,
    b: 89,
    h: 0.4444,
    s: 1,
    v: 0.529,
  },
  // pink
  {
    pantoneName: "232 U",
    color: "#F16AB7",
    r: 241,
    g: 106,
    b: 183,
    h: 0.9056,
    s: 0.56,
    v: 0.945,
  },
  // orange
  {
    pantoneName: "136 U",
    color: "#ffad4a",
    r: 255,
    g: 173,
    b: 74,
    h: 0.0917,
    s: 0.71,
    v: 1,
  },
  // red
  {
    pantoneName: "032 U",
    color: "#F5333F",
    r: 245,
    g: 5163,
    b: 78,
    h: 0.9889,
    s: 0.792,
    v: 0.961,
  },
  // gold
  {
    pantoneName: "871 C",
    color: "#84754E",
    r: 132,
    g: 117,
    b: 78,
    h: 0.1204,
    s: 0.4091,
    v: 0.5176,
  },
  // silver
  {
    pantoneName: "877 C",
    color: "#8A8D8F",
    r: 138,
    g: 141,
    b: 143,
    h: 0.5667,
    s: 0.035,
    v: 0.5608,
  },

  {
    pantoneName: "Red 032 C",
    color: "#EF3341",
    r: 239,
    g: 51,
    b: 65,
    h: 0.9888,
    s: 0.5667,
    v: 0.79,
  },
  {
    pantoneName: "Yellow 012 C",
    color: "#FCD703",
    r: 252,
    g: 215,
    b: 3,
    h: 0.1417,
    s: 0.99,
    v: 0.99,
  },
  {
    pantoneName: "212 C",
    color: "#F04E98",
    r: 240,
    g: 78,
    b: 152,
    h: 0.925,
    s: 0.68,
    v: 0.94,
  },
  {
    pantoneName: "Process Blue C",
    color: "#2785CA",
    r: 39,
    g: 133,
    b: 202,
    h: 0.5694,
    s: 0.81,
    v: 0.79,
  },
]

const TISSUE_BACKGROUND_COLORS_PANTONE_NAMES = new Set([
  "5395 U",
  "2162 U",
  "3105 U",
  "2147 U",
  "Yellow U",
  "2418 U",
  "232 U",
  "136 U",
  "032 U",
])
/* tissue paper color palette */
export const PANTONE_COLORS_DB_TISSUE_BACKGROUND: PantonePickerColorObject[] =
  COLORS.filter((color) =>
    TISSUE_BACKGROUND_COLORS_PANTONE_NAMES.has(color.pantoneName)
  )

const METALLIC_COLORS_PANTONE_NAMES = new Set(["871 C", "877 C"])
/** supported metallic Pantone, i.e. gold and silver */
export const PANTONE_COLORS_DB_METALLIC: PantonePickerColorObject[] =
  COLORS.filter((color) => METALLIC_COLORS_PANTONE_NAMES.has(color.pantoneName))

const REPLICATOR_PATTERN_COLORS_PANTONE_NAMES = new Set([
  "Neutral Black C",
  "White",
  "Yellow 012 C",
  "212 C",
  "Red 032 C",
  "Process Blue C",
  "Violet C",
  "7738 C",
  "871 C",
  "877 C",
])
export const PANTONE_COLORS_REPLICATOR_PATTERN: PantonePickerColorObject[] =
  COLORS.filter((color) =>
    REPLICATOR_PATTERN_COLORS_PANTONE_NAMES.has(color.pantoneName)
  )

/** handpicked (predefined) default favorite Pantones */
export const PANTONE_COLORS_DB_DEFAULT_FAVORITE = [
  "White",
  "Neutral Black C",
  "Cool Gray 1 C",
  "Cool Gray 7 C",
  // reds
  "178 C",
  "185 C",
  // oranges
  "151 C",
  "7499 C",
  "1535 C",
  // yellows
  "3945 C",
  "394 C",
  // greens
  "374 C",
  "353 C",
  "375 C",
  "355 C",
  // cyans
  "311 C",
  "2925 C",
  "7698 C",
  // blues
  "2707 C",
  "2727 C",
  "2935 C",
  // violets
  "252 C",
  "2592 C",
  "Violet C",
  "2627 C",
  // magentas
  "232 C",
  "226 C",
  "221 C",
]
