import React from "react"
import styles from "./Error.module.scss"
import { SimpleHeader } from "../../../dsl/organisms/Header/SimpleHeader"
import { Button } from "dsl/src/atoms/Button"

export interface ErrorProps {
  title: string
  description: string
  link?: {
    label: string
    onClick: () => void
  }
}

export function Error({ title, description, link }: ErrorProps) {
  return (
    <div className={styles.wrapper}>
      <SimpleHeader />
      <div className={styles.content}>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.description}>{description}</div>
        {link && (
          <div className={styles.button}>
            <Button onClick={link.onClick}>{link.label}</Button>
          </div>
        )}
      </div>
    </div>
  )
}
