import { makeObservable } from "mobx"
import { commonFeatureFlags } from "shared-libs/src/js/libs/feature-flags/feature-flags"

export class CommonFeatureFlagsService {
  private featureFlags = commonFeatureFlags

  private constructor() {
    makeObservable(this)
  }

  public static async init(): Promise<CommonFeatureFlagsService> {
    const featureFlagsService = new CommonFeatureFlagsService()

    return featureFlagsService
  }
}
