import { GridLayoutProcessor } from "./layout-processors/grid-layout.processor"
import { AlternatingLayoutProcessor } from "./layout-processors/alternating-layout.processor"
import { Layout, LayoutList, LayoutName } from "./types"

export const defaultLayoutName: LayoutName = "straight-grid"

export const layouts: LayoutList = {
  "straight-grid": {
    name: "straight-grid",
    scale: 0.5,
    rows: 8,
    cols: 5,
    angle: 0,
    klass: GridLayoutProcessor,
  },
  "straight-alternating": {
    name: "straight-alternating",
    scale: 0.5,
    rows: 8,
    cols: 3,
    angle: 0,
    klass: AlternatingLayoutProcessor,
  },
  "angled-grid": {
    name: "angled-grid",
    scale: 0.5,
    rows: 8,
    cols: 5,
    angle: 45,
    klass: GridLayoutProcessor,
  },
  "angled-alternating": {
    name: "angled-alternating",
    scale: 0.5,
    rows: 8,
    cols: 5,
    angle: 45,
    klass: AlternatingLayoutProcessor,
  },
}

export function isLayoutStraight(layout: LayoutName): boolean {
  return layout === "straight-grid" || layout === "straight-alternating"
}

export function isLayoutAngled(layout: LayoutName): boolean {
  return layout === "angled-grid" || layout === "angled-alternating"
}

export function getStraightLayoutNames(): LayoutName[] {
  return ["straight-grid", "straight-alternating"]
}

export function getAngledLayoutNames(): LayoutName[] {
  return ["angled-grid", "angled-alternating"]
}

export function isLayoutAlternating(layoutName: LayoutName): boolean {
  return (
    layoutName === "straight-alternating" || layoutName === "angled-alternating"
  )
}

export function isLayoutGrid(layoutName: LayoutName): boolean {
  return layoutName === "straight-grid" || layoutName === "angled-grid"
}

export function buildLayoutName(
  newPart: string,
  selectedLayoutName?: LayoutName
): LayoutName {
  if (newPart === "straight") {
    if (selectedLayoutName?.includes("grid")) {
      return "straight-grid"
    }

    return "straight-alternating"
  }

  if (newPart === "angled") {
    if (selectedLayoutName?.includes("grid")) {
      return "angled-grid"
    }

    return "angled-alternating"
  }

  if (newPart === "grid") {
    if (selectedLayoutName?.includes("straight")) {
      return "straight-grid"
    }

    return "angled-grid"
  }

  if (selectedLayoutName?.includes("straight")) {
    return "straight-alternating"
  }

  return "angled-alternating"
}

export const defaultLayout: Layout = layouts[defaultLayoutName]
