import React from "react"
import { observer } from "mobx-react-lite"
import { ModalShareDesignForm } from "./partials/ModalShareDesignForm"
import { ModalShareDesignInfo } from "./partials/ModalShareDesignInfo"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"

export const ModalShareDesign = observer(function () {
  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver } = containerSet
  const { productDesignStore, productDesignUiController } =
    designAndProductDriver
  const { isShareModalOpen } = productDesignUiController

  if (!isShareModalOpen) {
    return null
  }

  const {
    isProductDesignSharing,
    isProductDesignSaving,
    isSharingWithEmail,
    isSharedWithEmail,
    shareWithEmailError,
    meta: { public: isProductDesignPublic },
  } = productDesignStore.state
  const isFormVisible = isProductDesignPublic && !isProductDesignSharing

  const onClose = () => {
    productDesignUiController.setIsShareModalOpen(false)
  }

  const onShareClick = () => {
    productDesignUiController.share()
  }

  const onSendEmailClick = (email: string) => {
    productDesignUiController.shareWithEmail(email)
  }

  if (isFormVisible) {
    return (
      <ModalShareDesignForm
        loading={isSharingWithEmail}
        success={isSharedWithEmail}
        error={shareWithEmailError}
        onClose={onClose}
        onSend={onSendEmailClick}
      />
    )
  }

  return (
    <ModalShareDesignInfo
      onClose={onClose}
      loading={isProductDesignSaving}
      onClick={onShareClick}
    />
  )
})
