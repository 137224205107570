import { PackhelpEditableObject } from "../../../object-extensions/packhelp-objects"
import { AssetColorController } from "../asset-color.controller"
import VirtualDielineEditor from "../../../virtual-dieline-editor"

export class ColorHelper {
  public static applyDefaultColor(
    virtualDielineEditor: VirtualDielineEditor,
    object: PackhelpEditableObject
  ) {
    const colorController = new AssetColorController(virtualDielineEditor)
    const color = colorController.getColorForcedByColorMode()

    if (color) {
      colorController.recolorObject(color, object)
    }
  }
}
