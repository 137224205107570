import EventEmitter from "eventemitter3"
import fabric from "../../../../../libs/vendors/Fabric"
import {
  IndexConfigFragments,
  PackhelpObject,
  VirtualDieline,
  VirtualDielineSpace,
} from "../../object-extensions/packhelp-objects"
import { SpaceId } from "../../../../../libs/products-render-config/types"
import { DielineNavigator } from "../dieline-navigator/dieline-navigator"

const NoPrintZoneColor = "#FFFFFF"
export const NoPrintZoneOpacity = 0.3
export const NoPrintZoneHighlightedOpacity = 0.5

export class NoPrintZoneCreator {
  constructor(
    private readonly virtualDieline: VirtualDieline,
    private readonly dielineNavigator: DielineNavigator,
    private readonly eventEmitter: EventEmitter
  ) {}

  public async create(
    spaceId: SpaceId,
    noPrintZone: VirtualDielineSpace,
    index: number
  ) {
    const vdSpaceBoundingRect =
      this.dielineNavigator.getSpaceBoundingRect(spaceId)

    const space = this.virtualDieline
      .getObjects()
      .find((space) => space.id === spaceId)

    if (!space) {
      return
    }

    const offsetX = space.left - noPrintZone.left
    const offsetY = space.top - noPrintZone.top

    const left = vdSpaceBoundingRect.left - offsetX
    const top = vdSpaceBoundingRect.top - offsetY
    const zone = new fabric.Rect() as PackhelpObject

    zone.set({
      selectable: false,
      hasControls: false,
      strokeWidth: 0,
      opacity: NoPrintZoneOpacity,
      visible: true,
      left: left,
      top: top,
      width: noPrintZone.width,
      height: noPrintZone.height,
      fill: NoPrintZoneColor,
      id: `no_print_${spaceId}_${index}`,
      originSpaceArea: spaceId,
      indexConfig: {
        fragment: IndexConfigFragments.TOP,
        index: index,
      },
      hoverCursor: "default",
    })

    zone.on("mouseover", ({ e, target }) => {
      this.eventEmitter.emit("noPrintZoneMouseOver", e)
      if (target) {
        target.set({ opacity: NoPrintZoneHighlightedOpacity })
        target.canvas?.renderAll()
      }
    })

    zone.on("mouseout", ({ target }) => {
      this.eventEmitter.emit("noPrintZoneMouseOut")
      if (target) {
        target.set({ opacity: NoPrintZoneOpacity })
        target.canvas?.renderAll()
      }
    })

    return zone
  }
}
