import { SpaceClippingHelper } from "../helpers/space-clipping.helper"
import { ClipPathModes } from "../../../services/clip-path.generator"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { PackhelpEditableLogoPlaceholderSlot } from "../../../object-extensions/packhelp-objects"
import { ObjectController } from "./object.controller"
import { EditableObjectTypes } from "../../../../../../types/asset.types"
import { ee, eventTree } from "../../../../../../events/editor.events"

export class LogoPlaceholderSlotObjectController extends ObjectController {
  constructor(
    protected readonly canvasObject: PackhelpEditableLogoPlaceholderSlot,
    protected readonly virtualDielineEditor: VirtualDielineEditor
  ) {
    super(canvasObject, virtualDielineEditor)

    if (
      this.canvasObject.assetType !==
      EditableObjectTypes.assetLogoPlaceholderSlot
    ) {
      throw new Error("Type not supported")
    }
  }

  public async setSpaceClipping() {
    await SpaceClippingHelper.setSpaceClipping(
      this.virtualDielineEditor,
      this.canvasObject.originSpaceArea,
      this.canvasObject,
      ClipPathModes.FillMode
    )
  }

  public plusClicked() {
    ee.emit(eventTree.logo.plusClicked)
  }

  public attachEventListeners() {
    this.canvasObject.attachEventListeners({
      onPlusClicked: () => {
        const controller = new LogoPlaceholderSlotObjectController(
          this.canvasObject,
          this.virtualDielineEditor
        )
        controller.plusClicked()
      },
      onTrashClicked: () => {
        const controller = new LogoPlaceholderSlotObjectController(
          this.canvasObject,
          this.virtualDielineEditor
        )
        controller.remove()
      },
    })
  }

  public isColorModificationAvailable(): boolean {
    return false
  }

  public isGroupable(): boolean {
    return false
  }
}
