import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { CustomSizeDimensionConfig } from "@ph/product-api"
import styles from "./CustomSizeOptionPicker.module.scss"
import cxBinder from "classnames/bind"
import TextField from "dsl/src/atoms/TextField/TextField"
import { Button, ButtonSizes } from "dsl/src/atoms/Button"
import arrowUpIconUrl from "../../../../../assets/_icons/arrow-up.svg"
import arrowDownIconUrl from "../../../../../assets/_icons/arrow-down.svg"
import { I18N } from "../../../../../i18n"
import _sortBy from "lodash/sortBy"
import { FormGroup } from "../../../../molecules/FormGroup/FormGroup"
import { useContainerSet } from "../../../../../../_containers-react/_editor-app-hooks"
import { SvgIcon } from "../../../../atoms/SvgIcon/SvgIcon"

const cx = cxBinder.bind(styles)
const i18n = I18N.generic

export const CustomSizeOptionPicker = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { sizeTabController } = ui

  const {
    product,
    currentSku,
    customSize,
    isCustomSizeFormVisible,
    isCustomSizeFormTouched,
    dynamicVariantError,
    customSizeConfig,
    customSizeValidationResult,
    isCustomSizeApplyingDisabled,
    isAnyActionDisabled,
  } = sizeTabController
  const variant = product.getDefaultVariant()
  const size = variant.size
  const unit = size.units.length
  const active = !!size.isCustom

  if (!customSizeConfig) {
    return null
  }

  const onClick = () => {
    sizeTabController.setIsCustomSizeFormVisible(!isCustomSizeFormVisible)
  }

  const onDimensionChange = (type: string, value: number) => {
    sizeTabController.setIsCustomSizeFormTouched(true)

    sizeTabController.setCustomSize({
      ...customSize,
      [type]: value,
    })
  }

  const onApply = () => {
    sizeTabController.setIsCustomSizeFormTouched(false)

    if (Object.values(customSize).length === 0) {
      return
    }

    sizeTabController.changeSize(currentSku, { size: customSize })
  }

  const getDimensionValidationError = (
    config: CustomSizeDimensionConfig,
    validation: { min: boolean; max: boolean } = { min: true, max: true }
  ) => {
    if (!validation.min) {
      return `${t(i18n.min)} ${config.min} ${unit}`
    }

    if (!validation.max) {
      return `${t(i18n.max)} ${config.max} ${unit}`
    }
  }

  const valueToString = (value?: number): string => {
    if (!value) {
      return ""
    }

    return value.toString()
  }

  const sortedDimensions = _sortBy(
    Object.entries<CustomSizeDimensionConfig>(customSizeConfig.dimensions),
    ([key]) => ["width", "length", "height"].indexOf(key)
  )

  return (
    <div className={cx("wrapper", { active })}>
      <button
        className={styles.button}
        onClick={onClick}
        analytics-section="left-tab-configuration"
        analytics-type="option-picker"
        analytics-name="size"
        analytics-id="custom-size"
        e2e-target="button"
        e2e-target-name="custom-size"
      >
        {isCustomSizeFormVisible ? (
          <SvgIcon url={arrowUpIconUrl} size="s" />
        ) : (
          <SvgIcon url={arrowDownIconUrl} size="s" />
        )}

        {t(i18n.customSize)}

        <span className={styles.note}>
          {t(i18n.getQuoteMinQty, {
            qty: customSizeConfig.minQuantity,
          })}
        </span>
      </button>

      {isCustomSizeFormVisible && (
        <div className={styles.content}>
          <FormGroup>
            {sortedDimensions.map(([key, config]) => {
              const error = getDimensionValidationError(
                config,
                customSizeValidationResult?.dimensions[key]
              )

              return (
                <FormGroup key={key} label={t(i18n.dimensions[key])} vertical>
                  <TextField
                    type="number"
                    value={valueToString(customSize[key])}
                    suffix={unit}
                    onChange={(e) => {
                      onDimensionChange(key, Number(e.target["value"]))
                    }}
                    error={!!error}
                    e2eTarget="input"
                    e2eTargetName={key}
                    disabled={isAnyActionDisabled}
                  />

                  {error && <div className={styles.error}>{error}</div>}
                </FormGroup>
              )
            })}
          </FormGroup>

          {customSizeValidationResult?.generic.map((result) => (
            <div className={styles.error}>{result.message}</div>
          ))}

          {dynamicVariantError && !isCustomSizeFormTouched && (
            <div className={styles.error}>{dynamicVariantError}</div>
          )}

          <FormGroup inline>
            <Button
              size={ButtonSizes.small}
              disabled={isCustomSizeApplyingDisabled}
              onClick={onApply}
              e2eTarget="button"
              e2eTargetName="apply-custom-size"
            >
              {t(i18n.apply)}
            </Button>
          </FormGroup>
        </div>
      )}
    </div>
  )
})
