import React from "react"
import { Notification } from "../../atoms/Notification/Notification"
import {
  AssetError,
  getTranslationKeyForAssetError,
} from "../../../../services/asset-service/asset.errors"
import errorIconUrl from "../../../assets/_icons/error.svg"
import { useTranslate } from "../../../hooks/useTranslate"

export interface UploadErrorProps {
  error: AssetError
  filename?: string
}

export function UploadError({ error, filename }: UploadErrorProps) {
  const t = useTranslate()

  return (
    <Notification iconUrl={errorIconUrl} type="error">
      {filename && (
        <>
          {filename}
          <br />
        </>
      )}
      {t(getTranslationKeyForAssetError(error))}
    </Notification>
  )
}
