import { observable, action, makeObservable } from "mobx"
import EventEmitter from "eventemitter3"
import {
  FontLoaderService,
  FontFamilyDefinition,
  FontFamilyConfig,
  FontConfig,
  FontStyle,
  FontWeight,
} from "../libs/services/fonts-loader-service/fonts-loader.service"
import { FontsFinder } from "../libs/services/fonts-loader-service/fonts-finder"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"

export type FontWeightStyleConfig = {
  fontStyle: FontStyle
  fontWeight: FontWeight
}

export type fontFamilyConfig = {
  id: string
  name: string
  config: FontFamilyConfig
}

export class FontsConfigStore {
  @observable public isFontsLoading: boolean = false
  @observable public isAllLoaded: boolean = false
  @observable public fontFamiliesList: FontFamilyDefinition[] = []

  private readonly fontsProvider = new FontLoaderService()

  constructor(private readonly ee: AllEditorEventsEmitter) {
    makeObservable(this)

    this.ee.once(eventTree.ui.mainUISceneLoadedFull, () => {
      setTimeout(this.loadAllAvailableFonts.bind(this), 500)
    })
  }

  @action
  public async preloadDesignFonts(fontsFinder: FontsFinder) {
    this.setFontLoading(true)
    const fontsIds: string[] = fontsFinder.findFonts()
    const loadedFonts = await this.fontsProvider.preloadDesignFonts(fontsIds)
    this.setFontFamiliesList(loadedFonts)
    this.setFontLoading(false)
  }

  @action
  private async loadAllAvailableFonts() {
    if (this.isAllLoaded) {
      return
    }

    this.setFontLoading(true)
    const loadedFonts = await this.fontsProvider.preloadAllFonts()
    this.setFontFamiliesList(loadedFonts)
    this.setFontLoading(false)
    this.setAllFontsLoaded()
  }

  @action
  private setAllFontsLoaded() {
    this.isAllLoaded = true
  }

  @action
  private setFontFamiliesList(loadedFonts: FontFamilyDefinition[]) {
    this.fontFamiliesList = loadedFonts
  }

  @action
  private setFontLoading(isLoading: boolean) {
    this.isFontsLoading = isLoading
  }

  public findFontFamily(query) {
    return this.fontFamiliesList.find((font) => {
      return font.id === query
    })
  }

  public getFontConfig(fontName): FontConfig {
    if (!this.fontFamiliesList.length) {
      return {
        italic: false,
        bold: false,
        boldItalic: false,
      }
    } else {
      return this.fontsProvider.getSettingsForFont(fontName)
    }
  }
}
