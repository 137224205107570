import { PackhelpObject } from "../../../object-extensions/packhelp-objects"
import { TempBackgroundImageCreator } from "./temp-background-image.creator"
import { TempLayers } from "../../../types/render-engine.types"
import VirtualDielineEditor from "../../../virtual-dieline-editor"
import { BackgroundsLayers } from "../types"

export class TempBackgroundImageController {
  constructor(private readonly vdEditor: VirtualDielineEditor) {}

  public async applyTempBackgroundImage(
    space: PackhelpObject,
    backgroundImage: PackhelpObject
  ): Promise<void> {
    const tempBackgroundImage = await this.createTempBackgroundImage(
      space,
      backgroundImage
    )

    this.removeTempBackgroundImage()

    this.vdEditor.addOnCanvas(tempBackgroundImage, false)
  }

  public removeTempBackgroundImage() {
    const tempBackgroundImage = this.getTempBackgroundImage()

    if (tempBackgroundImage) {
      this.vdEditor.fabricCanvas.remove(tempBackgroundImage)
    }
  }

  public getTempBackgroundImage(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(TempLayers.TEMP_BACKGROUND_IMAGE)
  }

  public async refreshTempBackgroundImage(
    space: PackhelpObject
  ): Promise<void> {
    const backgroundImage = this.getGlobalBackgroundImage()

    if (!backgroundImage) {
      return this.removeTempBackgroundImage()
    }

    await this.applyTempBackgroundImage(space, backgroundImage)
  }

  public setTempBackgroundImageVisibility(isVisible: boolean) {
    const tempBackgroundImage = this.getTempBackgroundImage()

    if (tempBackgroundImage) {
      tempBackgroundImage.set({
        visible: isVisible,
      })
    }
  }

  private getGlobalBackgroundImage(): PackhelpObject | undefined {
    return this.vdEditor.getCanvasObjectById(BackgroundsLayers.BACKGROUND_IMAGE)
  }

  private async createTempBackgroundImage(
    space: PackhelpObject,
    backgroundImage: PackhelpObject
  ): Promise<PackhelpObject> {
    const tempBackgroundCreator = new TempBackgroundImageCreator(
      space,
      backgroundImage,
      this.vdEditor
    )

    return await tempBackgroundCreator.create()
  }
}
