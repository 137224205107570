import { KeyboardScope } from "../../libs/services/keyboard-controls.service"

export enum KeyboardEvents {
  setScope = "keyboard.scope.set",
  clearScope = "keyboard.scope.clear",
  spaceDown = "keyboard.space.down",
  spaceUp = "keyboard.space.up",
}

export type KeyboardEventTypes = {
  [KeyboardEvents.setScope]: (scope: KeyboardScope) => void
  [KeyboardEvents.clearScope]: (scope: KeyboardScope) => void
  [KeyboardEvents.spaceDown]: () => void
  [KeyboardEvents.spaceUp]: () => void
}
