import { PackhelpApi } from "../packhelp-api"

export interface QrCodeReqOptions {
  url: string
  labelRows?: Array<string>
  labelPosition?: string
}

export class QrCodesApi {
  constructor(private api: PackhelpApi) {}

  public async getQrCodeSrc(options: QrCodeReqOptions): Promise<string> {
    const { data } = await this.api.ax.post(
      `v2/qr_codes/generate`,
      {
        url: options.url,
        label_rows: options.labelRows,
        label_position: options.labelPosition,
      },
      this.api.withAuthToken()
    )

    return data.data.qr_code_svg_url
  }
}
