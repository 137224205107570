import React, { useRef } from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import {
  Dropzone,
  DropzoneProps,
  DropzoneRef,
} from "../../../atoms/Dropzone/Dropzone"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { ContextNav } from "../../../atoms/ContextNav/ContextNav"
import { CheckInfoBox } from "../../../molecules/CheckInfoBox/CheckInfoBox"
import { TabSection } from "../Tab/TabSection"
import { ContextNavItem } from "../../../atoms/ContextNav/ContentNavItem"
import { UploadError } from "../../../molecules/UploadError/UploadError"

const i18nUploader = I18N.component.assetsUploader
const i18nPlaceholder = I18N.editorTools.logoPlaceholder

export const TabLogo = observer(function () {
  const t = useTranslate()
  const dropzoneRef = useRef<DropzoneRef>()

  const [containerSet] = useContainerSet((c) => [
    c.designAndProductDriver,
    c.assets,
    c.logo,
  ])

  if (!containerSet) {
    return null
  }

  const { designAndProductDriver, assets, logo } = containerSet
  const { assetStore } = designAndProductDriver
  const { imagesController } = assets
  const { logoStore, logoUiController } = logo
  const { logo: logoAsset } = assetStore

  const onLogoClick = () => {
    if (!logoAsset) {
      return
    }

    imagesController.addImage(logoAsset.getImageAsset())
  }

  const onLogoReuploadClick = () => {
    dropzoneRef.current?.open()
  }

  const onLogoRemoveClick = () => {
    assetStore.removeAssetAsLogo()
  }

  const onDrop = (files: File[]) => {
    files.forEach((file) => assetStore.addLogo(file))
  }

  const onLogoPlaceholderCheck = () => {
    logoUiController.toggleLogoPlaceholder()
  }

  const getState = (): DropzoneProps["state"] => {
    if (logoAsset?.isUploading && !logoAsset?.error) {
      return "uploading"
    }

    return "initial"
  }

  return (
    <Tab title={t(I18N.tabs.logo)} e2eTargetName="logo">
      <TabSection>
        <Dropzone
          ref={dropzoneRef}
          onDrop={onDrop}
          state={getState()}
          label={t(i18nUploader.uploadImage)}
          analytics-section="left-tab-logo"
          analytics-name="upload-logo"
        >
          {logoAsset?.hasUploaded && (
            <div
              onClick={onLogoClick}
              analytics-section="left-tab-logo"
              analytics-type="thumbnail"
              analytics-name="logo"
              e2e-target="button"
              e2e-target-name="logo"
            >
              <img src={logoAsset.previewUri} alt="Logo" />
            </div>
          )}
        </Dropzone>

        {logoAsset?.error && (
          <UploadError filename={logoAsset.name} error={logoAsset.error} />
        )}

        {logoAsset?.hasUploaded && (
          <ContextNav>
            <ContextNavItem
              label={t(i18nUploader.reuploadLogo)}
              onClick={onLogoReuploadClick}
              analyticsSection="left-tab-logo"
              analyticsType="button"
              analyticsName="upload-new-logo"
              e2eTargetName="upload-new-logo"
            />
            <ContextNavItem
              label={t(i18nUploader.removeLogo)}
              onClick={onLogoRemoveClick}
              analyticsSection="left-tab-logo"
              analyticsType="button"
              analyticsName="remove-logo"
              e2eTargetName="remove-logo"
            />
          </ContextNav>
        )}
      </TabSection>

      {logoStore.isLogoPlaceholdersAvailable && (
        <CheckInfoBox
          label={t(i18nPlaceholder.logoPlaceholder)}
          description={t(i18nPlaceholder.description)}
          onChange={onLogoPlaceholderCheck}
          checked={logoStore.isLogoPlaceholdersEnabled}
          analyticsSection="left-tab-logo"
          analyticsType="checkbox"
          analyticsName="logo-placeholder"
          analyticsId={
            logoStore.isLogoPlaceholdersEnabled ? "enable" : "disable"
          }
        />
      )}
    </Tab>
  )
})
