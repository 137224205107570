import React, { PropsWithChildren } from "react"
import styles from "./Step.module.scss"
import { Label } from "../Label/Label"
import { useTranslate } from "../../../hooks/useTranslate"
import { I18N } from "../../../i18n"
import { SvgIcon } from "../SvgIcon/SvgIcon"

export interface StepProps {
  number?: number
  iconUrl?: string
  title: string
}

export function Step({
  children,
  number,
  title,
  iconUrl,
}: PropsWithChildren<StepProps>) {
  const t = useTranslate()

  return (
    <div className={styles.wrapper}>
      {number && (
        <Label>
          {t(I18N.generic.step)} {number}
        </Label>
      )}
      <h3 className={styles.title}>
        {iconUrl && <SvgIcon url={iconUrl} />}
        {title}
      </h3>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  )
}
