import FabricAssetLoader from "../../../../libs/services/loaders/fabric-asset.loader"
import VirtualDielineEditor from "../virtual-dieline-editor"
import { v4 as uuidv4 } from "uuid"
import {
  PackhelpCanvas,
  PackhelpEditableObject,
  PackhelpEditableShape,
  PackhelpEditableText,
  PackhelpObject,
} from "../object-extensions/packhelp-objects"
import {
  is2dInterfaceObject,
  isActiveSelection,
  isAssetGroup,
  isAssetLogoPlaceholderSlot,
  isEditableObject,
  isGroup,
  isInteractiveCanvas,
} from "../../../../types/asset.types"
import { SpaceId } from "../../../../libs/products-render-config/types"
import { Text } from "../../../../models/text"
import { Shape } from "../../../../models/shape"
import { MaskController } from "./assets-module/mask-controller/mask.controller"
import {
  ActiveObjectEvent,
  CanvasEvent,
  CanvasEventObjectModifiedAction,
} from "../../../../events/partials/domain.events"
import { PredefinedTextAssetDto } from "../../../../types/predefined-asset.types"
import { PredefinedText } from "../../../../models/predefined-text"
import { AssetColorController } from "./assets-module/asset-color.controller"
import { getEditableObjects } from "./assets-module/canvas-object-controller/helpers"
import { PredefinedTextCreator } from "./assets-module/canvas-object-creator/predefined-text.creator"
import { SpaceClippingHelper } from "./assets-module/helpers/space-clipping.helper"
import { ControlsHelper } from "./assets-module/helpers/controls.helper"
import { ScaleHelper } from "./assets-module/helpers/scale.helper"
import { TextCreator } from "./assets-module/canvas-object-creator/text.creator"
import { ShapeCreator } from "./assets-module/canvas-object-creator/shape.creator"
import { ImageAsset } from "../../../../models/image-asset"
import { ImageCreator } from "./assets-module/canvas-object-creator/image.creator"
import { assetParamsToIncludeInClonedObject } from "./assets-module/types"
import { LogoPlaceholderSlotCreator } from "./assets-module/canvas-object-creator/logo-placeholder-slot-creator/logo-placeholder-slot.creator"
import { SnappingController } from "./assets-module/canvas-object-controller/object-controllers/snapping.controller"
import { IEvent } from "fabric/fabric-impl"
import { eventTree } from "../../../../events/editor.events"
import { SmartGuidelinesController } from "./assets-module/smart-guidelines-controller/smart-guidelines.controller"
import { RotationHelper } from "./assets-module/helpers/rotation.helper"

export default class AssetsModule {
  private eventsSwitchedOn = false
  private readonly smartGuidelinesController?: SmartGuidelinesController

  constructor(public virtualDielineEditor: VirtualDielineEditor) {
    if (isInteractiveCanvas(this.canvas)) {
      this.smartGuidelinesController = new SmartGuidelinesController(
        this.canvas
      )
    }
  }

  public setEditableObjectsSelectability(isSelectable: boolean): void {
    this.getEditableObjects().forEach((object) => {
      object.set({
        selectable: isSelectable,
        evented: isSelectable,
      })

      if (isAssetGroup(object)) {
        object.set({
          subTargetCheck: isSelectable,
        })
      }
    })
  }

  public setActiveObject(object: PackhelpObject) {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    this.canvas.setActiveObject(object)
    this.canvas.renderAll()
  }

  public setEditableObjectsVisibility(isVisible: boolean): void {
    this.getEditableObjects().forEach((object) => {
      this.setObjectVisibility(object, isVisible)
    })
  }

  public showAssetsNotInSpace(spaceId: SpaceId, shouldShow: boolean): void {
    this.getAssetsNotInSpace(spaceId).forEach((object) => {
      this.setObjectVisibility(object, shouldShow)
    })
  }

  public showAssetsInSpace(spaceId: SpaceId, shouldShow: boolean): void {
    this.getAssetsInSpace(spaceId).forEach((object) => {
      this.setObjectVisibility(object, shouldShow)
    })
  }

  private getAssetsNotInSpace(spaceId: SpaceId): PackhelpEditableObject[] {
    return this.getEditableObjects().filter((object) => {
      return (
        !object.isSpaceClippingDisabled && object.originSpaceArea !== spaceId
      )
    })
  }

  private getAssetsInSpace(spaceId: SpaceId): PackhelpEditableObject[] {
    return this.getEditableObjects().filter((object) => {
      return (
        object.isSpaceClippingDisabled || object.originSpaceArea === spaceId
      )
    })
  }

  public getEditableObjects(): PackhelpEditableObject[] {
    return getEditableObjects(this.canvas)
  }

  public getEditableObjectById(id: string): PackhelpEditableObject | undefined {
    return this.getEditableObjects().find((object) => object.id === id)
  }

  public getOverscaledObjects(): PackhelpObject[] {
    return this.canvas.getObjects().filter((object) => object.isOverscaled)
  }

  public async loadImage(imageUrl: string, options, mimeType?) {
    return FabricAssetLoader.loadAsset(imageUrl, options, mimeType)
  }

  public async addImage(
    imageAsset: ImageAsset,
    options: {
      spaceId: SpaceId
      shouldSelect: boolean
      templateId?: number | null
    }
  ): Promise<PackhelpEditableObject> {
    const creator = new ImageCreator(this.virtualDielineEditor, options.spaceId)
    const object = await creator.create(imageAsset, {
      templateId: options.templateId,
    })
    this.virtualDielineEditor.addOnCanvas(object, true)

    if (this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()) {
      await this.createMaskAndAttachItToObject(object)
    }

    if (options.shouldSelect) {
      this.setActiveObject(object)
    }

    return object
  }

  public transferMaskBetweenObjects(
    object1: PackhelpEditableObject,
    object2: PackhelpEditableObject
  ) {
    const controller = object1.maskController

    if (controller) {
      controller.changeParent(object2)

      object2.set({ maskController: controller })
      object1.set({ maskController: undefined })
    }
  }

  public async createMaskAndAttachItToObject(object: PackhelpEditableObject) {
    const maskController = new MaskController(object, this.virtualDielineEditor)
    await maskController.init()

    this.virtualDielineEditor.ee.emit(eventTree.graphics.maskAttached)
  }

  public async addShape(
    shape: Shape,
    options: {
      spaceId: SpaceId
      shouldSelect?: boolean
      shouldApplyClippingMask?: boolean
    }
  ): Promise<PackhelpEditableShape> {
    const creator = new ShapeCreator(this.virtualDielineEditor)
    const object = await creator.create(shape, options.spaceId)
    this.virtualDielineEditor.addOnCanvas(object, true)

    if (
      options.shouldApplyClippingMask &&
      this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()
    ) {
      await this.createMaskAndAttachItToObject(object)
    }

    if (!!options.shouldSelect) {
      this.setActiveObject(object)
    }

    return object
  }

  public async addText(
    spaceId: SpaceId,
    text: Text,
    shouldSelect: boolean
  ): Promise<PackhelpEditableText> {
    const creator = new TextCreator(this.virtualDielineEditor)
    const object = await creator.create(text, spaceId)
    this.virtualDielineEditor.addOnCanvas(object, true)

    if (this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()) {
      await this.createMaskAndAttachItToObject(object)
    }

    if (shouldSelect) {
      this.setActiveObject(object)
    }

    return object
  }

  public async addPredefinedText(
    spaceId: SpaceId,
    {
      asset,
      predefinedText,
    }: { asset: PredefinedTextAssetDto; predefinedText: PredefinedText },
    shouldSelect: boolean
  ): Promise<PackhelpEditableObject> {
    const creator = new PredefinedTextCreator(this.virtualDielineEditor)
    const object = await creator.create(asset, predefinedText, spaceId)
    this.virtualDielineEditor.addOnCanvas(object, true)

    if (this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()) {
      await this.createMaskAndAttachItToObject(object)
    }

    if (shouldSelect) {
      this.setActiveObject(object)
    }

    return object
  }

  public async addLogoPlaceholderSlot(spaceId: SpaceId, shouldSelect: boolean) {
    const creator = new LogoPlaceholderSlotCreator(this.virtualDielineEditor)
    const object = await creator.create(spaceId)
    this.virtualDielineEditor.addOnCanvas(object, true)

    if (this.virtualDielineEditor.backgroundsModule.isClippingMaskAvailable()) {
      await this.createMaskAndAttachItToObject(object)
    }

    if (shouldSelect) {
      this.setActiveObject(object)
    }

    return object
  }

  public async cloneAsset(
    object: PackhelpEditableObject
  ): Promise<PackhelpEditableObject> {
    return new Promise((resolve) => {
      object.clone(async (cloned) => {
        const activeSpaceId =
          this.virtualDielineEditor.dielineNavigator.getActiveSpaceId()
        if (object.clipPath && activeSpaceId) {
          await SpaceClippingHelper.evokeSpaceClipping(
            this.virtualDielineEditor,
            activeSpaceId,
            cloned
          )
        }

        cloned.set({
          id: uuidv4(),
        })

        resolve(cloned)
      }, assetParamsToIncludeInClonedObject)
    })
  }

  public switchOnAssetsModuleEvents() {
    if (this.eventsSwitchedOn) return

    this.eventsSwitchedOn = true

    this.canvas.on(CanvasEvent.selectionCreated, this.onSelectionCreated)
    this.canvas.on(CanvasEvent.selectionUpdated, this.onSelectionCreated)
    this.canvas.on(CanvasEvent.objectMoving, this.onObjectMoving)
    this.canvas.on(CanvasEvent.objectSnapped, this.onObjectMoving)
    this.canvas.on(CanvasEvent.objectRotating, this.onObjectRotating)
    this.canvas.on(CanvasEvent.objectScaling, this.onObjectScaling)
    this.canvas.on(CanvasEvent.textChanged, this.onObjectRestyled)
    this.canvas.on(CanvasEvent.textChanged, this.onTextChanged)
    this.canvas.on(CanvasEvent.objectRestyled, this.onObjectRestyled)
    this.canvas.on(CanvasEvent.objectRecolored, this.onObjectRecolored)
    this.canvas.on(CanvasEvent.selectionCleared, this.onSelectionCleared)
    this.canvas.on(CanvasEvent.objectAdded, this.onObjectAdded)
    this.canvas.on(CanvasEvent.objectRemoved, this.onObjectRemoved)
    this.canvas.on(CanvasEvent.objectModified, this.onObjectModified)
    this.canvas.on(CanvasEvent.mouseOver, this.onMouseOver)
    this.canvas.on(CanvasEvent.mouseOut, this.onMouseOut)
    this.canvas.on(CanvasEvent.mouseDown, this.onMouseOut)

    this.smartGuidelinesController?.attachEventListeners()
  }

  public switchOffAssetsModuleEvents() {
    this.eventsSwitchedOn = false

    this.canvas.off(CanvasEvent.selectionCreated, this.onSelectionCreated)
    this.canvas.off(CanvasEvent.selectionUpdated, this.onSelectionCreated)
    this.canvas.off(CanvasEvent.objectMoving, this.onObjectMoving)
    this.canvas.off(CanvasEvent.objectSnapped, this.onObjectMoving)
    this.canvas.off(CanvasEvent.objectRotating, this.onObjectRotating)
    this.canvas.off(CanvasEvent.objectScaling, this.onObjectScaling)
    this.canvas.off(CanvasEvent.textChanged, this.onObjectRestyled)
    this.canvas.off(CanvasEvent.textChanged, this.onTextChanged)
    this.canvas.off(CanvasEvent.objectRestyled, this.onObjectRestyled)
    this.canvas.off(CanvasEvent.objectRecolored, this.onObjectRecolored)
    this.canvas.off(CanvasEvent.selectionCleared, this.onSelectionCleared)
    this.canvas.off(CanvasEvent.objectAdded, this.onObjectAdded)
    this.canvas.off(CanvasEvent.objectRemoved, this.onObjectRemoved)
    this.canvas.off(CanvasEvent.objectModified, this.onObjectModified)
    this.canvas.off(CanvasEvent.mouseOver, this.onMouseOver)
    this.canvas.off(CanvasEvent.mouseOut, this.onMouseOut)
    this.canvas.off(CanvasEvent.mouseDown, this.onMouseOut)

    this.smartGuidelinesController?.detachEventListeners()
  }

  public clearActiveObject = () => {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    this.canvas.clearContext(this.canvas.contextTop)
    this.canvas.discardActiveObject().renderAll()
    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.deselected)
  }

  private onSelectionCleared = (e) => {
    if (e.deselected) {
      for (const object of e.deselected) {
        object.set({
          minScaleLimit: 0,
          isMinScaleLimitReached: false,
        })
      }
    }

    this.clearActiveObject()
  }

  private onObjectRecolored = (e: IEvent) => {
    const currentObj = e.target as PackhelpEditableObject
    const colorController = new AssetColorController(this.virtualDielineEditor)

    colorController.recolorDielineColorableObjectsIfNeeded(
      colorController.getObjectColor(currentObj)
    )

    this.canvas.renderAll()
  }

  private onObjectRestyled = (e: IEvent) => {
    const currentObj = e.target as PackhelpEditableObject

    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
    this.canvas.fire(CanvasEvent.objectModified, {
      target: currentObj,
    })
    this.canvas.renderAll()
  }

  private onTextChanged = (e: IEvent): void => {
    this.resetSnapping(e.target as PackhelpEditableObject)
  }

  private onObjectRotating = (e: IEvent) => {
    const currentObj = e.target as PackhelpEditableObject

    RotationHelper.alignObjectRotation(currentObj)

    if (isActiveSelection(currentObj)) {
      for (const object of currentObj.getObjects()) {
        if (object.maskController) {
          object.maskController.updateRotationAndPosition()
        }
      }
    }

    if (currentObj.maskParentId) {
      return
    }

    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  private onObjectScaling = (e: IEvent) => {
    const currentObj = e.target as PackhelpEditableObject

    if (isActiveSelection(currentObj)) {
      for (const object of currentObj.getObjects()) {
        if (!object.maskController) {
          continue
        }

        object.maskController.updateScaleAndPosition()
      }
    }

    currentObj.set({
      isOverscaled: ScaleHelper.isOverscaled(currentObj),
      isMinScaleLimitReached: currentObj.scaleX! <= currentObj.minScaleLimit!,
    })

    if (currentObj.maskParentId) {
      return
    }

    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  private onObjectAdded = (e: IEvent) => {
    this.virtualDielineEditor.eventEmitter.emit(
      CanvasEvent.objectAdded,
      e.target
    )
  }

  private onObjectRemoved = (e: IEvent) => {
    this.virtualDielineEditor.eventEmitter.emit(
      CanvasEvent.objectRemoved,
      e.target
    )
  }

  private onObjectModified = (e: IEvent & { action?: string }) => {
    const currentObj = e.target as PackhelpEditableObject

    if (e.action === CanvasEventObjectModifiedAction.scale) {
      this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.resized)
    }

    if (e.action === CanvasEventObjectModifiedAction.drag) {
      this.refreshOriginSpaceArea(currentObj)
    }

    if (e.action === CanvasEventObjectModifiedAction.move) {
      this.refreshOriginSpaceArea(currentObj)
      this.resetSnapping(currentObj)
    }

    this.virtualDielineEditor.eventEmitter.emit(
      CanvasEvent.objectModified,
      e.target
    )
  }

  public refreshOriginSpaceArea(object: PackhelpObject): void {
    if (!this.virtualDielineEditor.isSpaceClippingTogglingAvailable()) {
      return
    }

    const center = object.getCenterPoint()
    const originSpaceArea =
      this.virtualDielineEditor.spaceHighlightModule.findSpace(
        center
      )?.originSpaceArea

    if (originSpaceArea) {
      object.set({
        originSpaceArea,
      })
    }

    if (isGroup(object)) {
      for (const groupObject of object.getObjects()) {
        groupObject.set({ originSpaceArea })
      }
    }

    if (object.maskParentId) {
      const maskParent = this.canvas
        .getObjects()
        .find((obj) => obj.id === object.maskParentId)

      if (maskParent) {
        this.refreshOriginSpaceArea(maskParent)
      }
    }
  }

  private onSelectionCreated = () => {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    const currentObj = this.canvas.getActiveObject() as PackhelpEditableObject

    currentObj.set({
      minScaleLimit: ScaleHelper.calcMinScaleLimit(
        this.virtualDielineEditor,
        currentObj
      ),
      isMinScaleLimitReached: false,
    })

    ControlsHelper.setControls(currentObj)

    if (isActiveSelection(currentObj)) {
      for (const object of currentObj.getObjects()) {
        if (object.maskParentId) {
          currentObj.removeWithUpdate(object)
        } else if (object.maskController) {
          object.maskController.updatePosition()
        }
      }

      if (currentObj.getObjects().length === 1) {
        const objToSelect = currentObj.getObjects()[0]

        this.canvas.discardActiveObject()
        this.canvas.setActiveObject(objToSelect)

        return
      }
    }

    this.virtualDielineEditor.eventEmitter.emit(
      ActiveObjectEvent.selected,
      currentObj
    )
  }

  private onObjectMoving = (e: IEvent) => {
    const currentObj = e.target as PackhelpEditableObject

    this.refreshOriginSpaceArea(currentObj)

    if (isActiveSelection(currentObj)) {
      for (const object of currentObj.getObjects()) {
        if (!object.maskController) {
          continue
        }

        object.maskController.updatePosition()
      }
    }

    if (currentObj.maskParentId) {
      return
    }

    this.virtualDielineEditor.eventEmitter.emit(ActiveObjectEvent.modified)
  }

  private onMouseOver = (e: IEvent) => {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    const currentObj = e.target as PackhelpEditableObject

    if (!currentObj || currentObj === this.canvas.getActiveObject()) {
      return
    }

    ControlsHelper.decorateHoverControls(currentObj)
    currentObj._renderControls(this.canvas.contextTop, {
      hasControls: false,
    })
  }

  private onMouseOut = (e: IEvent) => {
    if (!isInteractiveCanvas(this.canvas)) {
      return
    }

    const currentObj = e.target

    if (!currentObj) {
      return
    }

    this.canvas.clearContext(this.canvas.contextTop)
  }

  private resetSnapping(object: PackhelpEditableObject): void {
    const snapping = new SnappingController(object)
    snapping.reset()
  }

  public set2dInterfaceObjectsVisibility(visible: boolean, spaceId?: SpaceId) {
    const objects = spaceId
      ? this.getAssetsInSpace(spaceId)
      : this.getEditableObjects()
    objects.filter(is2dInterfaceObject).forEach((object) => {
      this.setObjectVisibility(object, visible)
    })
  }

  public setObjectVisibility(object: PackhelpObject, isVisible: boolean): void {
    const isObjectVisible =
      isVisible &&
      !(
        isAssetLogoPlaceholderSlot(object) &&
        (!object.enabled || !this.virtualDielineEditor.isEditMode)
      )
    const isObjectSelectable =
      isObjectVisible &&
      isInteractiveCanvas(this.canvas) &&
      this.canvas.selection

    object.set({
      visible: isObjectVisible,
      isEditable: isObjectVisible,
      selectable: isObjectSelectable,
      evented: isObjectSelectable,
    })

    if (isEditableObject(object) && object.maskController) {
      object.maskController.setVisibility(isObjectVisible)
    }
  }

  private get canvas(): PackhelpCanvas {
    return this.virtualDielineEditor.fabricCanvas
  }
}
