import { DielineNavigator } from "../dieline-navigator/dieline-navigator"
import {
  EditContext,
  SpaceId,
} from "../../../../../libs/products-render-config/types"
import { ProductRenderPilot } from "../../../../../libs/products-render-config/product-render-pilot"
import { PackhelpObject } from "../../object-extensions/packhelp-objects"
import { SafeOperatingZoneForCalculationCreator } from "./creators/safe-operating-zone-for-calculation.creator"
import { SafeZoneForDisplayCreator } from "./creators/safe-zone-for-display.creator"

export class SafeZoneCreator {
  private readonly safeOperatingZoneForCalculationCreator: SafeOperatingZoneForCalculationCreator
  private readonly safeZoneForDisplayCreator: SafeZoneForDisplayCreator

  constructor(
    editContext: EditContext,
    dielineNavigator: DielineNavigator,
    productRenderPilot: ProductRenderPilot
  ) {
    this.safeOperatingZoneForCalculationCreator =
      new SafeOperatingZoneForCalculationCreator(
        editContext,
        dielineNavigator,
        productRenderPilot
      )

    this.safeZoneForDisplayCreator = new SafeZoneForDisplayCreator(
      editContext,
      dielineNavigator,
      productRenderPilot
    )
  }

  public async createSafeZoneForDisplay(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpObject> {
    return this.safeZoneForDisplayCreator.create(spaceId, index)
  }

  public async createSafeOperatingZoneForCalculation(
    spaceId: SpaceId,
    index: number
  ): Promise<PackhelpObject> {
    return this.safeOperatingZoneForCalculationCreator.create(spaceId, index)
  }
}
