// Experiment: Pricing experiments - display DMS Discount promotion on selected products

export const calculatePromoPrice = (
  discount?: number,
  price?: number
): number | undefined => {
  if (!discount) {
    return price
  }

  if (!price) {
    return undefined
  }

  return (price * (100 - discount)) / 100
}
