var BoxFinish;
(function (BoxFinish) {
    BoxFinish["PRINT_FINISH_NONE"] = "foilNone";
    BoxFinish["PRINT_FINISH_FOIL_GLOSSY"] = "foilGlossy";
    BoxFinish["PRINT_FINISH_FOIL_MATTE"] = "foilMatte";
    BoxFinish["PRINT_FINISH_SLEEVE_WHITE"] = "sleeveWhite";
    BoxFinish["PRINT_FINISH_SLEEVE_KRAFT"] = "sleeveKraft";
    BoxFinish["PRINT_FINISH_SINGLE_STRIP"] = "singleStrip";
    BoxFinish["PRINT_FINISH_DOUBLE_STRIP"] = "doubleStrip";
})(BoxFinish || (BoxFinish = {}));
export default BoxFinish;
