import fabric from "../../../../../../libs/vendors/Fabric"
import {
  SceneDecoration,
  VirtualDielineSpace,
} from "../../../object-extensions/packhelp-objects"
import { SceneDimensions } from "../../../virtual-dieline-editor"
import { RotationHelper } from "../../assets-module/helpers/rotation.helper"

type CartesianCoords = {
  top: number
  left: number
}

export type Size = {
  width: number
  height: number
}

export type Point = {
  x: number
  y: number
}

export type SpaceBoundingRect = {
  top: number
  left: number
  width: number
  height: number
  centerX: number
  centerY: number
}

export enum AvailableRotations {
  none = 0,
  verticalLeft = 90,
  verticalRight = -90,
  upsideDown = 180,
}

export class TranslationCalculator {
  private readonly canvasSize: number

  constructor(canvasSize: number) {
    this.canvasSize = canvasSize
  }

  public calculateSpaceBoundingRect(
    space: VirtualDielineSpace,
    config: {
      withRotation: boolean
    }
  ) {
    const rotation = Number(space.rotation)

    if (!config.withRotation || !rotation) {
      return this.calculateSpacePositionOnDielineNormal(space)
    }

    if (
      rotation === AvailableRotations.verticalLeft ||
      rotation === AvailableRotations.verticalRight
    ) {
      return this.calculateSpacePositionOnDielineVertical(space)
    } else if (rotation === AvailableRotations.upsideDown) {
      return this.calculateSpacePositionOnDielineUpsideDown(space)
    }

    return this.calculateSpacePositionOnDielineNormal(space)
  }

  public calculateAssetCenterPositionInSpace(
    space: VirtualDielineSpace,
    objectSize: Size,
    isSpaceActive = true
  ): CartesianCoords {
    const spaceCoordsOnDieline = this.calculateSpaceBoundingRect(space, {
      withRotation: isSpaceActive,
    })

    const multiplier =
      isSpaceActive && space.rotation === AvailableRotations.upsideDown ? -1 : 1
    const offsetX =
      (spaceCoordsOnDieline.width - multiplier * objectSize.width) / 2
    const offsetY =
      (spaceCoordsOnDieline.height - multiplier * objectSize.height) / 2

    return {
      left: spaceCoordsOnDieline.left + multiplier * offsetX,
      top: spaceCoordsOnDieline.top + multiplier * offsetY,
    }
  }

  public calculateSpaceCenterOnScene = (
    space: VirtualDielineSpace,
    sceneDimensions: SceneDimensions
  ): fabric.Point => {
    const spaceOnDielineCoords = this.calculateSpaceBoundingRect(space, {
      withRotation: true,
    })

    const centerX = spaceOnDielineCoords.centerX - sceneDimensions.width / 2
    const centerY = spaceOnDielineCoords.centerY - sceneDimensions.height / 2

    return new fabric.Point(centerX, centerY)
  }

  public calculateSpaceToDielineZoomRatio = (
    space: VirtualDielineSpace,
    sceneDimensions: SceneDimensions,
    isSpaceZoomActive: boolean
  ): number => {
    const spaceDimensions = this.getSpaceDimensions(space)

    const widthRatio = sceneDimensions.width / spaceDimensions.width
    const heightRatio = sceneDimensions.height / spaceDimensions.height

    const ratio = Math.min(widthRatio, heightRatio)

    if (isSpaceZoomActive) {
      return ratio
    }

    const dieline = space.group as unknown as VirtualDielineSpace
    const sceneDecoration = dieline.sceneDecoration as SceneDecoration

    if (!sceneDecoration) {
      return ratio
    }

    return ratio * sceneDecoration.editZone.zoom
  }

  private getSpaceDimensions(space: VirtualDielineSpace): {
    width: number
    height: number
  } {
    if (!space.width || !space.height) {
      throw new Error("virtual dieline space is incompatible")
    }

    const objectRotation = Number(space.rotation)
    let width = space.width
    let height = space.height

    if (
      objectRotation === AvailableRotations.verticalLeft ||
      objectRotation === AvailableRotations.verticalRight
    ) {
      width = space.height
      height = space.width
    }

    return { width, height }
  }

  public calculateCanvasCenterPoint = () => {
    return new fabric.Point(this.canvasSize / 2, this.canvasSize / 2)
  }

  private calculateSpacePositionOnDielineUpsideDown = (
    space: VirtualDielineSpace
  ): SpaceBoundingRect => {
    const { width, height } = space
    const normalPosition = this.calculateSpacePositionOnDielineNormal(space)

    if (!width || !height) {
      throw new Error("virtual dieline space is incompatible")
    }

    const rotatedPosition = RotationHelper.rotatePointAroundCanvasCenter(
      new fabric.Point(normalPosition.left!, normalPosition.top!),
      AvailableRotations.upsideDown
    )

    return {
      left: rotatedPosition.x,
      top: rotatedPosition.y,
      centerX: rotatedPosition.x - width / 2,
      centerY: rotatedPosition.y - height / 2,
      width,
      height,
    }
  }

  private calculateSpacePositionOnDielineVertical = (
    space: VirtualDielineSpace
  ): SpaceBoundingRect => {
    const transformation = Number(space.rotation)
    const normalPosition = this.calculateSpacePositionOnDielineNormal(space)
    const { width, height } = space

    if (!width || !height) {
      throw new Error("virtual dieline space is incompatible")
    }

    let leftRelativeToCanvas
    let topRelativeToCanvas
    let spaceCenterX
    let spaceCenterY

    if (transformation > 0) {
      const leftTransformedToTop = this.canvasSize - normalPosition.top
      leftRelativeToCanvas = leftTransformedToTop - space.height
      topRelativeToCanvas = normalPosition.left
      spaceCenterX = leftRelativeToCanvas + space.height / 2
      spaceCenterY = topRelativeToCanvas + space.width / 2
    } else {
      const leftTransformedToTop = this.canvasSize - normalPosition.left
      leftRelativeToCanvas = normalPosition.top
      topRelativeToCanvas = leftTransformedToTop - space.width!
      spaceCenterX = leftRelativeToCanvas + space.height / 2
      spaceCenterY = topRelativeToCanvas + space.width / 2
    }

    return {
      left: leftRelativeToCanvas,
      top: topRelativeToCanvas,
      centerX: spaceCenterX,
      centerY: spaceCenterY,
      height: width,
      width: height,
    }
  }

  private calculateSpacePositionOnDielineNormal(
    space: VirtualDielineSpace
  ): SpaceBoundingRect {
    const dielineObject = space.group as fabric.Group

    const spacePositionRelativeToDieline =
      this.calculatePositionRelativeToDieline(space)
    const leftRelativeToCanvas =
      dielineObject.left! + spacePositionRelativeToDieline.left
    const topRelativeToCanvas =
      dielineObject.top! + spacePositionRelativeToDieline.top
    const height = space.height
    const width = space.width

    const spaceCenterX = leftRelativeToCanvas + width / 2
    const spaceCenterY = topRelativeToCanvas + height / 2

    return {
      left: leftRelativeToCanvas,
      top: topRelativeToCanvas,
      centerX: spaceCenterX,
      centerY: spaceCenterY,
      height,
      width,
    }
  }

  private calculatePositionRelativeToDieline = (
    space: VirtualDielineSpace
  ): CartesianCoords => {
    const dielineObject = space.group

    return {
      left: dielineObject.width / 2 + space.left,
      top: dielineObject.height / 2 + space.top,
    }
  }
}
