import { PackhelpApi } from "../../packhelp-api"
import { GetCertificatesQuery } from "./queries/get-certificates.query"
import {
  Certificate,
  GetCertificatesQueryOptions,
} from "./certificates.api.types"

export class CertificatesApi {
  constructor(private readonly api: PackhelpApi) {}

  public async getCertificates(
    options: GetCertificatesQueryOptions
  ): Promise<Certificate[]> {
    return new GetCertificatesQuery(this.api, options).call()
  }
}
