import { ProductRenderPilot } from "../../../libs/products-render-config/product-render-pilot"
import { EditContext } from "../../../libs/products-render-config/types"
import { VirtualDielineParser } from "./virtual-dieline.parser"
import { VirtualDieline } from "../vd-editor/object-extensions/packhelp-objects"

export class VirtualDielineLoader {
  constructor(
    private readonly productRenderPilot: ProductRenderPilot,
    private readonly canvasWidth: number
  ) {}

  public async load(
    svg: string,
    editContext: EditContext
  ): Promise<VirtualDieline> {
    const dielineParser = new VirtualDielineParser(
      this.canvasWidth,
      this.productRenderPilot.getRotationMap(editContext),
      this.productRenderPilot.getConfig().scenesDecorations?.[editContext]
    )

    return dielineParser.parse(svg)
  }
}
