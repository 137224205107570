var SkuFinish;
(function (SkuFinish) {
    SkuFinish["SKU_FINISH_NONE"] = "foil-none";
    SkuFinish["SKU_FINISH_FOIL_GLOSSY"] = "foil-glossy";
    SkuFinish["SKU_FINISH_FOIL_MATTE"] = "foil-matte";
    SkuFinish["SKU_FINISH_SLEEVE_WHITE"] = "sleeve-white";
    SkuFinish["SKU_FINISH_SLEEVE_KRAFT"] = "sleeve-kraft";
    SkuFinish["SKU_FINISH_SINGLE_STRIP"] = "single-strip";
    SkuFinish["SKU_FINISH_DOUBLE_STRIP"] = "double-strip";
})(SkuFinish || (SkuFinish = {}));
export default SkuFinish;
