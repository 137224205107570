import { default as usePortalBase } from "react-useportal"

export const usePortal = ({
  offsetTop = 0,
  offsetLeft = 0,
}: {
  offsetTop?: number
  offsetLeft?: number
}) => {
  const calculatePortalStyle = (portal: Element, trigger: Element): string => {
    let portalWidth = portal.firstElementChild?.scrollWidth || 0
    const portalHeight = portal.scrollHeight
    const { left, top, width, height } = trigger.getBoundingClientRect()

    if (portalWidth === window.innerWidth) {
      portalWidth = 0
    }

    let leftPosition = left + offsetLeft
    let topPosition = top + height + offsetTop

    if (topPosition + portalHeight > window.innerHeight) {
      topPosition = top - portalHeight - offsetTop
    }

    if (leftPosition + portalWidth > window.innerWidth) {
      leftPosition = left - portalWidth + width - offsetLeft
    }

    return `
      position: fixed;
      z-index: 7000;
      top: ${topPosition}px;
      left: ${leftPosition}px;
    `
  }

  const { isOpen, openPortal, closePortal, togglePortal, Portal } =
    usePortalBase({
      onOpen: ({ portal, targetEl }) => {
        // Wait for portal render to be able to calculate its content size
        setTimeout(() => {
          portal.current.style.cssText = calculatePortalStyle(
            portal.current,
            targetEl.current
          )
        }, 0)
      },
      onClose: ({ portal }) => portal.current.removeAttribute("style"),
      onWheel: () => closePortal(),
    })

  return {
    Portal,
    openPortal,
    closePortal,
    isPortalOpen: isOpen,
    togglePortal,
  }
}
