import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { UtilEnvContainer } from "./util.container"
import { EcommerceMaybeContainer } from "./ecommerce-maybe.container"
import { ApiSessionContainer } from "./api-session.container"
import type { AnalyticsController } from "../controllers/analytics.controller"

async function importDeps() {
  const { AnalyticsController } = await import(
    "../controllers/analytics.controller"
  )
  const { setPageTaint } = await import(
    "shared-libs/src/js/libs/analytics/rum/rum._global-taint"
  )

  return {
    AnalyticsController,
    setPageTaint,
  }
}

export async function provideAnalyticsMaybeContainer(
  utilEnvContainer: UtilEnvContainer,
  apiSessionContainer: ApiSessionContainer,
  designAndProduct: DesignAndProductDriverContainer,
  ecommerceContainer: EcommerceMaybeContainer
): Promise<AnalyticsMaybeContainer> {
  const { sessionStore } = apiSessionContainer
  const { ee, appConfig, uri } = utilEnvContainer
  const { productDesignStore, productDriver } = designAndProduct
  const { productPricingStore, cartStore } = ecommerceContainer

  if (
    !appConfig.integrations.rudderstack ||
    productDriver.state.productRenderPilot.getEditorMode() === "designer"
  ) {
    return {
      available: false,
      analyticsController: undefined,
    }
  }

  const { AnalyticsController, setPageTaint } = await importDeps()

  setPageTaint("Editor")

  const analyticsController = new AnalyticsController(
    {
      appConfig,
      ee,
      uri,
    },
    {
      productStore: productDriver.productStore,
      productDesignStore,
      sessionStore,
      productPricingStore,
      cartStore,
    }
  )

  return {
    available: true,
    analyticsController,
  }
}

export type AnalyticsMaybeContainer =
  | {
      available: true
      analyticsController: AnalyticsController
    }
  | {
      available: false
      analyticsController: undefined
    }
