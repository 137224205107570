import { computed } from "mobx"
import ProductDriver from "../../drivers/product.driver"
import { ProductDesignStore } from "../../stores/product-design.store"
import { ProductRenderPilot } from "../../libs/products-render-config/product-render-pilot"
import {
  AvailableEditorRelatedProduct,
  ProductManager,
  VariantCustomization,
} from "@ph/product-api"
import { SidebarStore } from "../../stores/sidebar.store"
import { ChangeProductController } from "../change-product-controller/change-product.controller"
import { PropertyGroup } from "../../types/ui.types"
import { EditorMode } from "../../libs/products-render-config/types"

export class ConfigurationTabController {
  private readonly productDriver: ProductDriver
  private readonly changeProductController: ChangeProductController
  private readonly productDesignStore: ProductDesignStore
  private readonly sidebarStore: SidebarStore

  constructor(
    services: {
      productDriver: ProductDriver
      changeProductController: ChangeProductController
    },
    stores: {
      productDesignStore: ProductDesignStore
      sidebarStore: SidebarStore
    }
  ) {
    this.productDriver = services.productDriver
    this.changeProductController = services.changeProductController
    this.productDesignStore = stores.productDesignStore
    this.sidebarStore = stores.sidebarStore
  }

  public openSizeTab(): void {
    this.sidebarStore.selectTab("size")
  }

  public async changeProduct(sku: string): Promise<void> {
    await this.productDriver.switchToFullProductView()

    return this.changeProductController.changeProduct(sku, this.customization)
  }

  @computed
  public get isAnyActionDisabled(): boolean {
    return this.changeProductController.isSelectingDisabled
  }

  @computed
  public get isChangeSizeAvailable(): boolean {
    const { isEditMode } = this.productDesignStore
    const { isAfterPurchaseEdit } = this.productRenderPilot
    const hasMultipleSizes =
      this.productRenderPilot.getAvailableSizes().length > 1

    return isEditMode && !isAfterPurchaseEdit && hasMultipleSizes
  }

  @computed
  public get selectedSize(): string {
    return this.product.variantManager.getSize()
  }

  @computed
  public get propertyGroups(): PropertyGroup[] {
    const groups = this.product.addonsManager
      .getAvailableConfigurablePropertiesGroupedByType()
      .filter((group) => group.properties.length > 1)

    for (const group of groups) {
      for (const property of group.properties) {
        const propertyEditorMode = this.product.getVariantEditorMode(
          property.sku
        )

        property["isDbyOnly"] =
          this.editorMode !== "dby" && propertyEditorMode === "dby"
      }
    }

    return groups
  }

  @computed
  public get currentSku(): string {
    return this.product.variantManager.getSku()
  }

  @computed
  public get isRelatedProductSectionAvailable(): boolean {
    return this.relatedProducts.length > 0
  }

  @computed
  public get relatedProducts(): AvailableEditorRelatedProduct[] {
    return this.product.variantManager.getEditorRelatedProducts()
  }

  @computed
  private get productRenderPilot(): ProductRenderPilot {
    return this.productDriver.state.productRenderPilot
  }

  @computed
  private get editorMode(): EditorMode {
    return this.productRenderPilot.getEditorMode()
  }

  @computed
  private get product(): ProductManager {
    return this.productDriver.productStore.product
  }

  private get customization(): VariantCustomization | undefined {
    return this.productDriver.productStore.customization
  }
}
