import React, { RefObject, useLayoutEffect, useRef } from "react"
import scrollIntoView from "scroll-into-view-if-needed"
import cxBinder from "classnames/bind"
import { PickerOptionLabel } from "./PickerOptionLabel"
import { PickerOptionValue } from "./PickerOptionValue"
import { PickerOptionIndicator } from "./PickerOptionIndicator"
import { QuantityItem, QuantityItemType } from "../../types"
import styles from "./PickerOption.module.scss"

const cx = cxBinder.bind(styles)

interface PickerOptionProps {
  item: QuantityItem
  isSelected: boolean
  isMatching?: boolean
  isMobile?: boolean
  scrollRef?: RefObject<HTMLElement>
  onSelect: (item: QuantityItem, immediate?: boolean) => void
  onItemAction?: (item: QuantityItem) => void
}
export const PickerOption = (props: PickerOptionProps) => {
  const {
    item,
    scrollRef,
    isSelected,
    isMatching,
    isMobile,
    onSelect,
    onItemAction
  } = props
  const itemRef = useRef<HTMLLIElement>(null)

  const isDisabled = item.outOfStock

  const handleSelect = () => {
    if (isDisabled) {
      return
    }

    const immediate = isMobile && item.type === QuantityItemType.Calculate

    onSelect(item, immediate)
  }

  const handleKeyDown = (event: any) => {
    if (isDisabled) {
      return
    }

    const { previousElementSibling, nextElementSibling } = event.target
    const isArrowUp = event.key === "ArrowUp"
    const isArrowDown = event.key === "ArrowDown"
    if (isArrowUp && previousElementSibling) {
      previousElementSibling.focus()
    }
    if (isArrowDown && nextElementSibling) {
      nextElementSibling.focus()
    }
    if (event.key === "Enter") {
      onSelect(item)
    }
  }

  useLayoutEffect(() => {
    if (isMatching) {
      setImmediate(() => {
        itemRef.current &&
          scrollIntoView(itemRef.current, {
            behavior: "smooth",
            boundary: scrollRef && scrollRef.current
          })
      })
    }
  }, [isMatching])

  return (
    <li
      ref={itemRef}
      className={cx("option", {
        "option--matching": isMatching,
        "option--mobile": isMobile,
        "option--selected": isSelected,
        "option--disabled": isDisabled
      })}
      tabIndex={0}
      onClick={handleSelect}
      onKeyDown={handleKeyDown}
      data-quantity={item.quantity}
      e2e-target="list-item"
      e2e-target-name={item.quantity}
    >
      <PickerOptionValue value={item.quantity} />
      <PickerOptionLabel item={item} onItemAction={onItemAction} />
      {!isMobile && <PickerOptionIndicator value={isSelected} />}
    </li>
  )
}
