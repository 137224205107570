import React from "react"
import { Tooltip } from "../Tooltip/Tooltip"
import cxBinder from "classnames/bind"
import styles from "./IconListItem.module.scss"
import { SvgIcon, SvgIconProps } from "../SvgIcon/SvgIcon"
import { AnalyticsProps, TestsProps } from "../../types"

const cx = cxBinder.bind(styles)

interface IconListItemProps extends AnalyticsProps, TestsProps {
  iconUrl: string
  iconActiveUrl?: string
  stateIconUrl?: string
  label: string
  onClick: () => void
  active?: boolean
  disabled?: boolean
  size?: SvgIconProps["size"]
}

export function IconListItem({
  iconUrl,
  iconActiveUrl,
  stateIconUrl,
  label,
  onClick,
  active = false,
  disabled = false,
  size,
  analyticsSection,
  analyticsType,
  analyticsName,
  analyticsId,
  e2eTarget,
  e2eTargetName,
}: IconListItemProps) {
  return (
    <Tooltip text={label}>
      <button
        disabled={disabled}
        className={cx("item", { active })}
        onClick={onClick}
        analytics-section={analyticsSection}
        analytics-type={analyticsType}
        analytics-name={analyticsName}
        analytics-id={analyticsId}
        e2e-target={e2eTarget}
        e2e-target-name={e2eTargetName}
      >
        <div className={cx("icon", size)}>
          <SvgIcon url={iconUrl} size={size} />
          {iconActiveUrl && <SvgIcon url={iconActiveUrl} size={size} />}
        </div>

        {stateIconUrl && (
          <div className={styles["state-icon"]}>
            <SvgIcon url={stateIconUrl} size="s" />
          </div>
        )}
      </button>
    </Tooltip>
  )
}
