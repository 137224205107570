import type { DesignAndProductDriverContainer } from "./design-and-product-driver.container"
import type { UtilEnvContainer } from "./util.container"
import type { ScreenShotGenerator } from "../services/screenshot.generator"
import { PatternMaybeContainer } from "./asset-pattern-maybe.container"
import { TemplatesMaybeContainer } from "./templates-maybe.container"

async function importDeps() {
  const { ScreenShotGenerator } = await import(
    "../services/screenshot.generator"
  )

  return {
    ScreenShotGenerator,
  }
}

export async function provideToolingMaybeContainer(
  designAndProduct: DesignAndProductDriverContainer,
  envUtil: UtilEnvContainer,
  patterns: PatternMaybeContainer,
  templates: TemplatesMaybeContainer
): Promise<ToolingMaybeContainer> {
  const { productDriver } = designAndProduct
  const { uri, ee } = envUtil

  if (!uri.isDevToolsMode()) {
    return {
      available: false,
    }
  }

  const { ScreenShotGenerator } = await importDeps()
  const services = {}

  if (templates.available) {
    services["template"] = {
      store: templates.templatesStore,
      controller: templates.templatesController,
    }
  }

  if (patterns.available) {
    services["pattern"] = {
      controller: patterns.patternsController,
      store: patterns.patternsStore,
    }
  }

  return {
    available: true,
    screenGen: new ScreenShotGenerator(productDriver, ee, services),
  }
}

export type ToolingMaybeContainer =
  | {
      available: true
      screenGen: ScreenShotGenerator
    }
  | {
      available: false
    }
