import { MutableRefObject, useCallback, useEffect, useRef } from "react"

export const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
  handler: (e?: MouseEvent) => any,
  when: boolean = true
): void => {
  const savedHandler = useRef(handler)

  const memoizedCallback = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !e.composedPath().includes(ref.current)) {
        savedHandler.current(e)
      }
    },
    [ref]
  )

  useEffect(() => {
    savedHandler.current = handler
  })

  useEffect(() => {
    if (when) {
      document.addEventListener("click", memoizedCallback)
      return () => {
        document.removeEventListener("click", memoizedCallback)
      }
    }
  }, [ref, handler, when, memoizedCallback])
}
