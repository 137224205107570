var Product;
(function (Product) {
    Product["PRE_PRINTED_MAILER_BOX_ECO"] = "prePrintedMailerBoxEco";
    Product["PRE_PRINTED_MAILER_BOX_FULL_COLOUR"] = "prePrintedMailerBoxFullColour";
    Product["MAILER_BOX"] = "mailerBox";
    Product["MAILER_BOX_ECO"] = "mailerBoxEco";
    Product["MAILER_BOX_ECO_COLOR"] = "mailerBoxEcoColor";
    Product["MAILER_BOX_FULL_COLOR"] = "mailerBoxFullColor";
    Product["MAILER_BOX_LITE_COLOR"] = "mailerBoxLiteColor";
    Product["MAILER_BOX_WHITE_INK"] = "mailerBoxWhiteInk";
    Product["DELIVERY_MAILER_BOX"] = "deliveryMailerBox";
    Product["DELIVERY_MAILER_BOX_PLAIN"] = "deliveryMailerBoxPlain";
    Product["SLEEVED_MAILER_BOX"] = "sleevedMailerBox";
    Product["MAILER_BOX_PLAIN"] = "mailerBoxPlain";
    Product["SHIPPING_BOX"] = "shippingBox";
    Product["SHIPPING_BOX_PLAIN"] = "shippingBoxPlain";
    Product["SHIPPING_BOX_PLAIN_V2"] = "shippingBoxPlainV2";
    Product["PRODUCT_BOX"] = "productBox";
    Product["PRODUCT_BOX_TWO_PIECES"] = "productBoxTwoPieces";
    Product["PRODUCT_BOX_WINE_BOX"] = "productBoxWineBox";
})(Product || (Product = {}));
export default Product;
