import React from "react"
import { observer } from "mobx-react-lite"
import { Tab } from "../Tab/Tab"
import { useTranslate } from "../../../../hooks/useTranslate"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { TabSection } from "../Tab/TabSection"
import { TemplatesSubTab } from "./partials/TemplatesSubTab"
import { PatternsSubTab } from "./partials/PatternsSubTab"
import { SubTab } from "../../../../../types/ui.types"
import { SubTabSwitcher } from "../../../molecules/SubTabSwitcher/SubTabSwitcher"
import { SubTabContent } from "../Tab/SubTabContent"

export const TabTemplatesPatterns = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.ui])

  if (!containerSet) {
    return null
  }

  const { ui } = containerSet
  const { templatesPatternsTabController } = ui
  const { activeSubTab, subTabs } = templatesPatternsTabController
  const { expandedPatternCategory, isSubTabSwitcherVisible } =
    templatesPatternsTabController

  const onSubTabClick = (subTab: SubTab) => {
    templatesPatternsTabController.setActiveSubTab(subTab)
  }

  const onBackClick = () => {
    templatesPatternsTabController.setExpandedPatternCategory()
  }

  const title = expandedPatternCategory
    ? t(expandedPatternCategory.titleIntl)
    : t(I18N.tabs.templates)
  const backLink = expandedPatternCategory
    ? { label: t(I18N.tabs.patterns), onClick: onBackClick }
    : undefined

  return (
    <Tab title={title} backLink={backLink} e2eTargetName="templatesAndPatterns">
      {isSubTabSwitcherVisible && (
        <TabSection>
          <SubTabSwitcher
            subTabs={subTabs}
            onClick={onSubTabClick}
            activeSubTab={activeSubTab}
            analyticsSection="left-tab-templates"
          />
        </TabSection>
      )}

      <SubTabContent visible={activeSubTab?.id === "templates"}>
        <TemplatesSubTab />
      </SubTabContent>

      <SubTabContent visible={activeSubTab?.id === "patterns"}>
        <PatternsSubTab />
      </SubTabContent>
    </Tab>
  )
})
