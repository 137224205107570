export const config = {
  thickness: 2,
  rotation: {
    initial: 90,
  },
  folding: {
    target: {
      closed: 100,
      opened: 90,
    },
  },
  spaces: {
    right: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: -90,
        step: 1,
      },
    },
    right_left_flap: {
      parent: "right",
      folding: {
        axis: "x",
        angle: 90,
        step: 2,
      },
    },
    right_right_flap: {
      parent: "right",
      folding: {
        axis: "x",
        angle: -90,
        step: 2,
        hidden: true,
      },
    },
    left: {
      parent: "bottom",
      folding: {
        axis: "y",
        angle: 90,
        step: 1,
      },
    },
    left_left_flap: {
      parent: "left",
      folding: {
        axis: "x",
        angle: 90,
        step: 2,
      },
    },
    left_right_flap: {
      parent: "left",
      folding: {
        axis: "x",
        angle: -90,
        step: 2,
        hidden: true,
      },
    },
    back: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: 90,
        step: 3,
      },
    },
    top: {
      parent: "back",
      folding: {
        axis: "x",
        angle: 90,
        step: 7,
      },
    },
    top_left_flap: {
      parent: "top",
      folding: {
        axis: "y",
        angle: 90,
        step: 6,
      },
    },
    top_right_flap: {
      parent: "top",
      folding: {
        axis: "y",
        angle: -90,
        step: 6,
      },
    },
    top_front_flap: {
      parent: "top",
      folding: {
        axis: "x",
        angle: 90,
        step: 6,
      },
    },
    front: {
      parent: "bottom",
      folding: {
        axis: "x",
        angle: -90,
        step: 3,
        shifting: true,
      },
    },
    front_foldline_left: {
      parent: "front",
      folding: {
        axis: "x",
        angle: -90,
        step: 4,
      },
    },
    front_foldline_right: {
      parent: "front",
      folding: {
        axis: "x",
        angle: -90,
        step: 4,
      },
    },
    front_foldline_center: {
      parent: "front",
      folding: {
        axis: "x",
        angle: -90,
        step: 4,
      },
    },
    front_inside: {
      parent: "front_foldline_center",
      folding: {
        axis: "x",
        angle: -90,
        step: 5,
      },
    },
  },
}
