export const editor = {
  EDITOR_MODE_2D: "2D",
  EDITOR_MODE_3D: "3D",
  VIEW_3D: "3D",
  CAMERA_FOV: 45,
  CANVAS_WIDTH: 780,
  CANVAS_HEIGHT: 600,
  SIDES: {
    front: "front",
    top: "top",
    back: "back",
    left: "left",
    right: "right",
    bottom: "bottom",
  },

  SIDE_NAMES: {
    front: "Przód",
    top: "Góra",
    back: "Tył",
    left: "Lewy bok",
    right: "Prawy bok",
    bottom: "Spód",
  },
  VIEW_MODES: {
    preview: "preview",
    edit: "edit",
  },
  SIDE_EDITOR_EDIT_BG_ALPHA: 0.8,
  SIDE_EDITOR_3DRENDER_EXPORT_BG_ALPHA: 0.9,
  SIDE_EDITOR_SAFEZONE_TIME: 1000,
  B3D_ANIM_SPEED_OVER_EDGE: 800,
  B3D_ANIM_SPEED: 1200,
  FILE_SIZE_ID_ORIGINAL: "original",
  FILE_SIZE_ID_ORIGINAL_BW: "original__BW",
  FILE_SIZE_ID_ORIGINAL_LIMITED: "originalLimited",
  FILE_SIZE_ID_ORIGINAL_LIMITED_BW: "originalLimited__BW",
  FILE_SIZE_ID_THUMBNAIL: "thumbnail",
  FILE_SIZE_ID_THUMBNAIL_BW: "thumbnail__BW",
  FILE_SIZE_ID_MONOCHROME_SUFFIX: "__BW",
  FILE_SIZE_ID_CONVERSION_PREFIX: "__z_converted__",

  FILE_SIZE_THUMBNAIL_MAX_WIDTH: 300,
  FILE_SIZE_THUMBNAIL_MAX_HEIGHT: 300,
  FILE_COLORSPACE_RGB: "rgb",
  FILE_COLORSPACE_MONOCHROME: "monochrome",
  FILE_COLORSPACE_MONOCHROME_WHITE: "monochromeWhite",
  MISSING_THUMBNAIL_URL: "/thumbnails/small/missing.png",
  // Print Qualities
  PRINT_QUALITY_STANDARD: "printQualityStandard",
  PRINT_QUALITY_HD_PLUS: "printQualistyHdPlus",
  PRINT_QUALITY_HD_PLUS_GLOSSY: "printQualistyHdPlusGlossy",
  DISPLAY_PRINT_QUALITY_STANDARD: "Standard",
  DISPLAY_PRINT_QUALITY_HD_PLUS: "HD+",
  // Creator editing modes based on sku
  CREATOR_MODE_NO_PRINT: "noPrint",
  CREATOR_MODE_SAMPLE_PACKS: "samples",
}
