import { ConsentCategory } from "../consts"
import { activateConsents } from "./consents-handler"

export const PREFERENCES_KEY = "ph-pref"

const isConsentDisabled = localStorage.getItem("consentDisabled") === "true"

export const getPreferencesArrays = () => {
  const loadedPreferences = loadPreferences() || {}
  const preferences = {
    ...initialPreferences,
    ...loadedPreferences,
  }

  return {
    allowedConsentIds: Object.keys(preferences).filter(
      (key) => preferences[key]
    ),
    deninedConsentIds: Object.keys(preferences).filter(
      (key) => !preferences[key]
    ),
  }
}

export const initialPreferences = Object.values(ConsentCategory).reduce(
  (acc, category) => {
    acc[category] = isConsentDisabled
    return acc
  },
  {}
)

export const loadPreferences = () => {
  const preferences = localStorage.getItem(PREFERENCES_KEY)
  try {
    return JSON.parse(preferences)
  } catch {
    return null
  }
}

export const savePreferences = (preferences) => {
  localStorage.setItem(PREFERENCES_KEY, JSON.stringify(preferences))
  window.dispatchEvent(new StorageEvent("storage", { key: PREFERENCES_KEY }))
}

export const onPreferencesSaved = (callback) => {
  window.addEventListener("storage", (event) => {
    if (event.key === PREFERENCES_KEY) {
      activateConsents(loadPreferences())
      callback(loadPreferences())
    }
  })
}

export const acceptAll = () => {
  const preferences = Object.values(ConsentCategory).reduce((acc, category) => {
    acc[category] = true
    return acc
  }, {})

  savePreferences(preferences)
}
