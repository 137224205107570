export const routes = {
  ROUTE_ROOT: "routeRoot",
  ROUTE_BLANK: "routeBlank",
  ROUTE_FETCH_ERROR: "routeFetchError",

  ROUTE_PRODUCT_SELECT: "routeProductSelect",
  ROUTE_PRODUCT_CONFIGURATOR: "routeProductConfigurator",
  ROUTE_BOX_TYPE: "routeBoxType",
  ROUTE_BOX: "routeBox",
  ROUTE_BOX_ID: "routeBoxId",
  ROUTE_BOX_QUANTITY_QUALITY: "routeBoxIdOrderQuantity",
  ROUTE_ORDER_DETAILS: "routeOrderDetails",
  ROUTE_ORDER_PAYMENT: "routeOrderPayment",
  ROUTE_ORDER_PAYMENT_RETURN: "routeOrderPaymentReturn",
  ROUTE_CART: "routeCart",
  ROUTE_ACCOUNT: "routeAccount",
  ROUTE_ACCOUNT_SETTINGS: "routeAccountSettings",
  ROUTE_ACCOUNT_SUPPORT_REFORESTATION: "routeAccountSupportReforestation",
  ROUTE_ACCOUNT_ORDERS: "routeOrders",
  ROUTE_ACCOUNT_ORDERS_ORDER: "routeOrdersOrder",
  ROUTE_ACCOUNT_ORDERS_RAQ_DETAILS: "routeOrdersRaqDetails",
  ROUTE_ACCOUNT_BOXES: "routeBoxes",
  ROUTE_ACCOUNT_WALLET: "routeAccountWallet",
  ROUTE_OFFER_REVIEW: "routeOfferReview",
  ROUTE_DASHBOARD_QUOTES: "routeDashboardQuotes",
  ROUTE_DASHBOARD_QUOTE_DETAILS: "routeDashboardQuoteDetails",
  ROUTE_DASHBOARD_ORDERS: "routeDashboardOrders",
  ROUTE_DASHBOARD_ITEMS: "routeDashboardItems",
  ROUTE_SAMPLE_PACK: "routeSamplePack",
  ROUTE_WALLET: "routeWallet",
  ROUTE_ACCOUNT_ORDERS_QR_DETAILS: "routeAccountOrdersQrDetails", // TODO: MAGIC VARIABLE BOOGALOO - FIX ME

  ROUTE_SIGN_IN: "routeSignIn",
  ROUTE_SIGN_OUT: "routeSignOut",
  ROUTE_REGISTER: "routeRegister",
  ROUTE_CONFIRM_EMAIL: "routeConfirmEmail",
  ROUTE_SET_PASSWORD_AFTER_RESET: "routeSetPasswordAfterReset",
}

export const PUBLIC_ROUTE_IDS = [
  /* zc.ROUTE_ROOT, DO NOT TURN ON AS IT'S An EDGE-CASE DUE TO REACT ROUTER
      It is handled automatically in the App.store.js.

      The reason is that '/' is always passed as matching route along the route
      objects that match given url.
    */
  routes.ROUTE_BLANK,
  routes.ROUTE_PRODUCT_SELECT,
  routes.ROUTE_PRODUCT_CONFIGURATOR,
  routes.ROUTE_BOX_TYPE,
  routes.ROUTE_CART,
  // These routes SHOULD be Public because:
  // - they trigger login modals themselves
  // - they could be used for presentation mode
  // - they could be delaying logging in requirement (after changes for example)
  routes.ROUTE_BOX,
  routes.ROUTE_BOX_ID,

  routes.ROUTE_SIGN_IN,
  routes.ROUTE_SIGN_OUT,
  routes.ROUTE_REGISTER,
  routes.ROUTE_CONFIRM_EMAIL,
  routes.ROUTE_SET_PASSWORD_AFTER_RESET,

  routes.ROUTE_OFFER_REVIEW,
  routes.ROUTE_DASHBOARD_QUOTES,
  routes.ROUTE_DASHBOARD_QUOTE_DETAILS,
  routes.ROUTE_DASHBOARD_ORDERS,
  routes.ROUTE_DASHBOARD_ITEMS,

  routes.ROUTE_SAMPLE_PACK,
]
