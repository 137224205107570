import RegionConfig from "../../../configs/region-config/interfaces/js/region-config"

export const formatPriceWithCurrency = (
  price: string,
  currency: string
): string => {
  const locale = RegionConfig.getCurrentLocaleWithCountryCode()

  return parseFloat(price).toLocaleString(locale, {
    style: "currency",
    currency,
  })
}
