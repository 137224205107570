import type { Alpha2CountryCode } from "@ph/api-studio"
import SelectWithSearch from "dsl/src/atoms/Select/SelectWithSearch"
import { SelectSizes } from "dsl/src/atoms/Select/types"
import { FormField } from "dsl/src/molecules/FormField/FormField"
import React from "react"
import {
  TranslatedCountry,
  getTranslatedShippingCountries,
} from "../../../../libs/shipping-countries/get-shipping-countries"
import { CountrySelectItem } from "../../../../shared-components/form-fields/country-select/CountrySelectItem"
import { useTranslate } from "../../../../shared-components/i18n/useTranslate"
import { i18nId } from "../utils/translations"

interface FormFieldShippingCountryProps {
  countryIso: Alpha2CountryCode
  handleCountryChange: (countryIso: Alpha2CountryCode) => void
}

const countryOptions = getTranslatedShippingCountries()

export const FormFieldShippingCountry = (
  props: FormFieldShippingCountryProps
) => {
  const { countryIso, handleCountryChange } = props
  const translate = useTranslate()

  const handleOnChange = (country: TranslatedCountry) => {
    const countryIso = country.id
    handleCountryChange(countryIso)
  }

  const selectedCountry = countryOptions.find(({ id }) => id === countryIso)

  return (
    <FormField
      label={translate(i18nId.ShippingDestination)}
      field={
        <SelectWithSearch
          e2eTarget="country-select"
          items={countryOptions}
          handleSelectedItemChange={handleOnChange}
          renderItem={CountrySelectItem}
          selectedItem={selectedCountry}
          size={SelectSizes.medium}
          searchPlaceholder={translate(i18nId.ShippingDestinationPlaceholder)}
        />
      }
    />
  )
}
