export enum ActiveObjectEvents {
  copy = "activeObject.copy",
  paste = "activeObject.paste",
  delete = "activeObject.delete",
  moveUp = "activeObject.moveUp",
  moveDown = "activeObject.moveDown",
  moveLeft = "activeObject.moveLeft",
  moveRight = "activeObject.moveRight",
  deselect = "activeObject.deselect",
  selected = "activeObject.selected",
  deselected = "activeObject.deselected",
  fontChanged = "activeObject.modified.font",
}

export type ActiveObjectEventTypes = {
  [ActiveObjectEvents.copy]: () => void
  [ActiveObjectEvents.paste]: () => void
  [ActiveObjectEvents.delete]: () => void
  [ActiveObjectEvents.moveUp]: () => void
  [ActiveObjectEvents.moveDown]: () => void
  [ActiveObjectEvents.moveLeft]: () => void
  [ActiveObjectEvents.moveRight]: () => void
  [ActiveObjectEvents.deselect]: () => void
  [ActiveObjectEvents.selected]: () => void
  [ActiveObjectEvents.deselected]: () => void
  [ActiveObjectEvents.fontChanged]: () => void
}
