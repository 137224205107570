import React, { useEffect, useState } from "react"
import { initRudderstack } from "shared-libs/src/js/libs/analytics/rudderstack/initializer"
import { RUM_Markers } from "shared-libs/src/js/libs/analytics/rum/rum.app-markers"
import { Theater } from "./ui/theater/Theater"
import { exposeApis } from "./services/expose-cli-api"
import { useContainer } from "./_containers-react/_editor-app-hooks"
import { EnsureEcommerceContainer } from "./_containers-react/ensure-ecommerce"
import { mountLiveChat } from "shared-libs/src/js/shared-views/live-chat/utils/mount-live-chat"
import { TheaterLoader } from "./ui/theater/TheaterLoader"
import { Error500 } from "./ui/theater/errors/Error500/Error500"
import { ErrorUnauthorized } from "./ui/theater/errors/ErrorUnauthorized/ErrorUnauthorized"

export function App() {
  const [classicRootStore, classicRootStoreError] =
    useContainer().classicRootStore

  const [error, setError] = useState<any>(null)
  const [isUnauthorized, setIsUnauthorized] = useState<any>(false)

  useEffect(() => {
    RUM_Markers.editor.mark.react_loaded()
    initRudderstack()
  }, [])

  useEffect(() => {
    if (!classicRootStoreError) {
      return
    }

    setIsUnauthorized(
      [403, 404].includes(classicRootStoreError.response?.status)
    )

    console.error(classicRootStoreError)

    window.Sentry?.captureException(classicRootStoreError)
    setError(classicRootStoreError)
  }, [classicRootStoreError])

  useEffect(() => {
    try {
      if (classicRootStore) {
        RUM_Markers.editor.mark.root_store_finished()
        exposeApis()
      }
    } catch (e: any) {
      console.error(e)

      window.Sentry?.captureException(e)
      setError(e)
    }
  }, [classicRootStore])

  useEffect(mountLiveChat, [])

  if (isUnauthorized) {
    return <ErrorUnauthorized />
  }

  if (error) {
    return <Error500 />
  }

  if (classicRootStore) {
    return (
      <EnsureEcommerceContainer>
        <Theater />
      </EnsureEcommerceContainer>
    )
  }

  return <TheaterLoader />
}
