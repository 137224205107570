import VirtualDielineEditor from "../../../../../virtual-dieline-editor"
import { PackhelpObject } from "../../../../../object-extensions/packhelp-objects"
import { BackgroundImageAdjuster } from "../../../../backgrounds-module/background-image/background-image.adjuster"
import { isAssetSvgImage } from "../../../../../../../../types/asset.types"
import { ObjectCleanerService } from "../../../../../../../../libs/services/object-cleaner.service"

export class TemplateBackgroundImageLoader {
  private readonly backgroundImageAdjuster: BackgroundImageAdjuster

  public constructor(private readonly vdEditor: VirtualDielineEditor) {
    this.backgroundImageAdjuster = new BackgroundImageAdjuster(vdEditor)
  }

  public async load(backgroundImage: PackhelpObject) {
    this.clear(backgroundImage)
    await this.backgroundImageAdjuster.adjust(backgroundImage)
    this.vdEditor.fabricCanvas.add(backgroundImage)
  }

  private clear(backgroundImage: PackhelpObject) {
    if (isAssetSvgImage(backgroundImage)) {
      ObjectCleanerService.clearSvgObject(backgroundImage)
    }
  }
}
