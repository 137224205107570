import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import restartIconUrl from "../../../../assets/_shape-icons/start-again.svg"
import { I18N } from "../../../../i18n"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Modal } from "../Modal/Modal"

const i18n = I18N.modals.restart

export const ModalRestartDesignConfirmation = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.designAndProductDriver])

  if (!containerSet) {
    return null
  }

  const { productDesignUiController } = containerSet.designAndProductDriver
  const { isRestartConfirmationModalOpen } = productDesignUiController

  if (!isRestartConfirmationModalOpen) {
    return null
  }

  const onConfirm = () => {
    productDesignUiController.restart()
  }

  const onClose = () => {
    productDesignUiController.setIsRestartConfirmationModalOpen(false)
  }

  return (
    <Modal
      title={t(i18n.title)}
      iconUrl={restartIconUrl}
      onClose={onClose}
      link={{
        label: t(i18n.confirm),
        onClick: onConfirm,
      }}
      center
    >
      {t(i18n.description)}
    </Modal>
  )
})
