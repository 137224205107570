import React from "react"
import Slider from "dsl/src/atoms/Slider/Slider"
import styles from "./MonochromeSlider.module.scss"

export interface MonochromeSliderProps {
  onChange: (value: number) => void
  value: number
}

const thresholdTiles: number[] = [...Array(10).keys()].reverse()

export function MonochromeSlider({ onChange, value }: MonochromeSliderProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.slider}>
        <Slider
          min={1}
          max={10}
          step={1}
          value={value * 10}
          onChange={(sliderValue) => onChange(sliderValue / 10)}
          hideTrack
        />
      </div>
      <ul className={styles["steps-list"]}>
        {thresholdTiles.map((tile) => (
          <li key={tile} className={styles["steps-list-item"]}>
            <div className={styles.step} />
          </li>
        ))}
      </ul>
    </div>
  )
}
