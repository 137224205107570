import React from "react"
import { default as BaseSkeleton } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

export interface SkeletonProps {
  width?: number
  height?: number
  borderRadius?: number
}

export function Skeleton({ width, height, borderRadius }: SkeletonProps) {
  return (
    <BaseSkeleton width={width} height={height} borderRadius={borderRadius} />
  )
}
