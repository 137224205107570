export const EventsConsts = {
  RFM_CROSS_SELL_EVENT: "RFM Cross Sell Email Sent",
  ADD_NEW_SIZE_MODAL_OPENED: "Add New Size Modal Opened",
  CART_DROPDOWN_VIEWED: "Cart Dropdown Viewed",
  CART_VIEWED: "Cart Viewed",
  CHECKOUT_STARTED: "Checkout Started",
  CHECKOUT_STEP_COMPLETED: "Checkout Step Completed",
  CHECKOUT_STEP_VIEWED: "Checkout Step Viewed",
  COUPON_APPLIED: "Coupon Applied",
  COUPON_DENIED: "Coupon Denied",
  COUPON_ENTERED: "Coupon Entered",
  COUPON_REMOVED: "Coupon Removed",
  DESIGN_DELETED: "Design Deleted",
  DESIGN_DUPLICATED: "Design Duplicated",
  DESIGN_METHOD_SELECTED: "Design Method Selected",
  DESIGN_MATERIAL_ADDED: "Design Material Added",
  DESIGN_SAVED: "Design Saved",
  EDITOR_METHOD_VIEWED: "Editor Method Viewed",
  DESIGN_SHARED: "Design Shared",
  CUSTOM_OFFER_VIEWED: "Custom Offer Viewed",
  CUSTOM_OFFER_EXPIRED: "Expired Custom Offer Viewed",
  EDITOR_BUTTON_CLICKED: "Editor Panel Button Clicked",
  ORDER_COMPLETED: "Order Completed",
  ORDER_UPDATED: "Order Updated",
  ORDER_VIEWED: "Order Viewed",
  PAYMENT_INFO_ENTERD: "Payment Info Entered",
  PRODUCT_ADDED: "Product Added",
  PRODUCT_ADDON_CLICKED: "Product Addon Clicked",
  PRODUCT_AMOUNT_CHANGED: "Product Amount Changed",
  PRODUCT_LIST_VIEWED: "Product List Viewed",
  PRODUCT_SIZE_CHANGED: "Product Size Changed",
  PRODUCT_SIZE_SELECTED: "Product Size Selected",
  REORDER_ADDED: "Reorder Added to Cart",
  SINGLE_DESIGN_VIEWED: "Design Viewed",
  UPSELL_DESIGN_METHOD_SELECTED: "Upsell Design Method Selected",
  UPSELL_DESIGN_METHOD_SELECTION_VIEWED:
    "Upsell Design Method Selection Viewed",
  UPSELL_PRODUCT_CLICKED: "Upsell Product Clicked",
  UPSELL_PRODUCT_LIST_VIEWED: "Upsell Product List Viewed",
  PRODUCT_PAGE_AMOUNT_PICKER_VIEWED: "Lp Product Page Amount Picker Viewed",
  EDIT_DESIGN_CLICKED: "Edit Design Clicked",
}

export enum EventBusinessUnit {
  Plus = "plus",
  Studio = "studio",
}

export enum EventLocation {
  Cart = "cart",
  Catalogue = "catalogue",
  Configurator = "configurator",
  ConfiguratorLight = "configurator-light",
  ProductQuotationSystem = "pqs",
  StudioShop = "shop",
  ProductPage = "product-page",
  Dashboard = "dashboard",
  Editor = "editor",
  EditorDby = "editor-dby",
  EditorReview = "review-editor",
  QuoteSuccess = "quote-success",
  SummaryAndReview = "summary & review",
  SummaryAndReviewStep1 = "summary-review-step-1",
  SummaryAndReviewStep2 = "summary-review-step-2",
  SummaryAndReviewStep3 = "summary-review-step-3",
  QrSuccessPage = "QR-success-page",
  RemindMeOnDesktop = "remind me on desktop",
  RemindMeOnDesktopModal = "remind me on desktop modal",
  BriefForm = "brief-form",
  DesignShowcase = "design-showcase",
  UseCases = "use-case",
  SummaryReview = "summary-review",
  QuoteContactForm = "quote-contact-form",
  Search = "search",
}
