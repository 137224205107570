import React from "react"
import { observer } from "mobx-react-lite"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { Button } from "dsl/src/atoms/Button"
import { I18N } from "../../../../i18n"
import { useTranslate } from "../../../../hooks/useTranslate"

export const HeaderSaveTemplateButton = observer(function () {
  const t = useTranslate()
  const [containerSet] = useContainerSet((c) => [c.designerMode])

  if (!containerSet) {
    return null
  }

  const { designerMode } = containerSet

  if (!designerMode.available) {
    return null
  }

  const { designerModeStore, designerModeController } = designerMode
  const { isLoading, isSaving } = designerModeStore

  if (!isLoading && !designerModeController.isSavingAvailable) {
    return null
  }

  const onClick = () => {
    designerModeController.saveAsTemplate()
  }

  return (
    <Button
      disabled={isLoading || isSaving}
      onClick={onClick}
      e2eTargetName="save"
    >
      {t(I18N.generic.save)}
    </Button>
  )
})
