import {
  IndexConfigFragmentBottom,
  IndexConfigFragments,
  PackhelpGroup,
  PackhelpImage,
  PackhelpObject,
  VirtualDieline,
} from "../object-extensions/packhelp-objects"
import { assetParamsToIncludeInClonedObject } from "../modules/assets-module/types"
import { TempLayers } from "../types/render-engine.types"
import { CreateTempBackgroundService } from "../modules/dieline-navigator/services/create-temp-background"

export class TempDielineCreator {
  constructor(
    private readonly virtualDieline: VirtualDieline,
    private readonly config: {
      background?: PackhelpGroup
      texture?: PackhelpImage
    }
  ) {}

  public async call(): Promise<{
    dieline: PackhelpObject
    stroke: PackhelpObject
    clipPath: PackhelpObject
  }> {
    const dielineObjects = await Promise.all([
      this.createDieline(),
      this.createDielineStroke(),
      this.createDielineClipPath(),
    ])

    return {
      dieline: dielineObjects[0],
      stroke: dielineObjects[1],
      clipPath: dielineObjects[2],
    }
  }

  private async createDielineClipPath(): Promise<PackhelpObject> {
    const dieline = await this.cloneDieline()

    for (const space of dieline.getObjects()) {
      space.fill = "#fff"
      space.stroke = "#000"
    }

    return dieline
  }

  private async createDielineStroke(): Promise<PackhelpObject> {
    const dieline = await this.cloneDieline()

    for (const space of dieline.getObjects()) {
      space.fill = "transparent"
      space.stroke = "#000"
    }

    dieline.set({
      ...this.getObjectProperties(
        TempLayers.TEMP_DIELINE_STROKE,
        IndexConfigFragmentBottom.TEMP_DIELINE_STROKE
      ),
      objectCaching: false,
    })

    return dieline
  }

  private async createDieline(): Promise<PackhelpObject> {
    const dieline = await this.cloneDieline()
    const { background, texture } = this.config

    if (background) {
      for (const bgObject of background.getObjects()) {
        const space = dieline
          .getObjects()
          .find((dielineObject) => dielineObject.id === bgObject.id)

        if (space) {
          space.fill = bgObject.fill === "transparent" ? "#fff" : bgObject.fill
        }
      }
    }

    const dielineWithTexture = await new CreateTempBackgroundService(dieline, {
      texture,
    }).generate()

    dielineWithTexture.set(
      this.getObjectProperties(
        TempLayers.TEMP_DIELINE,
        IndexConfigFragmentBottom.TEMP_DIELINE
      )
    )

    return dielineWithTexture
  }

  private async cloneDieline(): Promise<PackhelpGroup> {
    return await new Promise<PackhelpGroup>((resolve) =>
      this.virtualDieline.clone(resolve, assetParamsToIncludeInClonedObject)
    )
  }

  private getObjectProperties(
    id: TempLayers,
    index: IndexConfigFragmentBottom
  ) {
    return {
      id,
      selectable: false,
      evented: false,
      indexConfig: {
        fragment: IndexConfigFragments.BOTTOM,
        index,
      },
    }
  }
}
