import React, { ReactNode, RefObject } from "react"
import { QuantityPicker } from "dsl/src/components/QuantityPicker/QuantityPicker"
import {
  Direction,
  PriceBreak,
  PriceDisplayOptions,
  PriceFormatter,
} from "dsl/src/components/QuantityPicker/types"
import { TranslateFunction, useTranslate } from "../i18n/useTranslate"
import { i18nId } from "./utils/product-quantity.translations"
import { getPriceWithTax } from "../../shared-views/configurators/use-case-configurator/components/quantity-select/utils/get-price-with-tax"
import { FormattedCurrency } from "../formatted-currency/FormattedCurrency"
import { formatPriceWithCurrency } from "../formatted-currency/format-price-with-currency"
import { QuotingAppsI18nId } from "../quoting-apps/_helpers/quoting-apps.translations"
import { TType, Typography } from "dsl/src/atoms/Typography/Typography"

interface Translations {
  calculateLabel?: string
  confirmLabel?: string
  customValueLabel?: string
  headerLabel?: string
  outOfStockLabel?: string
  placeholder?: string
  searchPlaceholder?: string
}

interface ProductQuantityProps {
  consise?: boolean
  direction?: Direction
  disabled?: boolean
  footer?: ReactNode
  getPriceBreak: (quantity: number) => PriceBreak
  listRef?: RefObject<HTMLUListElement>
  maxQuantity: number
  maxQuantityForStandardOffer?: number
  minQuantity: number
  minQuantityForCustomOffer?: number
  onClose?: () => void
  onOpen?: () => void
  piecePriceFormatter?: PriceFormatter
  priceBreaks: PriceBreak[]
  priceDisplayOptions?: PriceDisplayOptions
  priceFormatter?: PriceFormatter
  quantity: number
  scrollRef?: RefObject<HTMLDivElement>
  setQuantity: (quantity: number) => void
  step: number
  translations?: Translations
  withPulsatingGlow?: boolean
  isOpenOnInit?: boolean
  analyticsSection?: string
  analyticsType?: string
  analyticsName?: string
  analyticsId?: string
}

export const ProductQuantity = (props: ProductQuantityProps) => {
  const {
    consise,
    direction,
    disabled,
    footer,
    getPriceBreak,
    listRef,
    maxQuantity,
    maxQuantityForStandardOffer,
    minQuantity,
    minQuantityForCustomOffer,
    onClose,
    onOpen,
    priceBreaks,
    priceDisplayOptions = {},
    quantity,
    scrollRef,
    setQuantity,
    step,
    translations = {},
    withPulsatingGlow,
    isOpenOnInit,
    analyticsSection,
    analyticsType,
    analyticsName,
    analyticsId,
  } = props

  const translate = useTranslate()

  const translatedLabels: Translations = {
    calculateLabel: translate(i18nId.CalculatePrice),
    confirmLabel: translate(i18nId.Button),
    customValueLabel: translate(i18nId.GetAQuote),
    headerLabel: translate(i18nId.Placeholder),
    outOfStockLabel: translate(i18nId.OutOfStock),
    placeholder: translate(i18nId.Placeholder),
    searchPlaceholder: translate(i18nId.SearchPlaceholder),
    ...translations,
  }

  const renderFormattedPrice = (price: number, currency: string) => {
    return <FormattedCurrency value={price} currency={currency} />
  }

  const renderFormattedPiecePriceFactory =
    (translate: TranslateFunction) =>
    (pricePerPiece: number, currency: string, withTax = false) => {
      return (
        <Typography type={TType.Body13_350}>
          {formatPriceWithCurrency(pricePerPiece.toString(), currency)}
          {translate(QuotingAppsI18nId.PerPiece)}
        </Typography>
      )
    }

  const priceFormatter = props.priceFormatter || renderFormattedPrice

  const piecePriceFormatter =
    props.piecePriceFormatter || renderFormattedPiecePriceFactory(translate)

  return (
    <QuantityPicker
      consise={consise}
      direction={direction}
      disabled={disabled}
      footer={footer}
      getPriceBreak={getPriceBreak}
      listRef={listRef}
      maxQuantity={maxQuantity}
      maxQuantityForStandardOffer={maxQuantityForStandardOffer}
      minQuantity={minQuantity}
      minQuantityForCustomOffer={minQuantityForCustomOffer}
      onClose={onClose}
      onOpen={onOpen}
      piecePriceFormatter={piecePriceFormatter}
      priceBreaks={priceBreaks}
      priceDisplayOptions={priceDisplayOptions}
      priceFormatter={priceFormatter}
      quantity={quantity}
      scrollRef={scrollRef}
      setQuantity={setQuantity}
      step={step}
      translations={translatedLabels}
      withPulsatingGlow={withPulsatingGlow}
      isOpenOnInit={isOpenOnInit}
      analyticsSection={analyticsSection}
      analyticsType={analyticsType}
      analyticsName={analyticsName}
      analyticsId={analyticsId}
    />
  )
}
