import { InputFile } from "filestack-js"
import { NamedInputFile } from "filestack-js/build/main/lib/api/upload"

export const isFile = (fileInput: any): fileInput is File => {
  return fileInput && fileInput.lastModified && fileInput.name
}

export const isNamedFile = (fileInput: any): fileInput is NamedInputFile => {
  return fileInput && fileInput.file
}

export function parseFileNaming(file?: InputFile) {
  let fileName = ""

  if (typeof file === "string") {
    fileName = file.split("/").pop() || ""
  }

  if ((isFile(file) || isNamedFile(file)) && !!file.name) {
    fileName = file.name
  }

  let name = fileName
  let ext = ""

  if (fileName.lastIndexOf(".") !== -1) {
    name = fileName.substr(0, fileName.lastIndexOf("."))
    ext = fileName.substr(fileName.lastIndexOf(".") + 1)
  }

  return {
    fileName,
    name,
    ext,
  }
}

/**
 * 12 symbols should last us 100+ years :D
 * @see https://zelark.github.io/nano-id-cc/
 * @see https://gist.github.com/gordonbrander/2230317
 */
export function uniqueID(): string {
  return nanoid(12)
}

/**
 * @see https://github.com/ai/nanoid
 * @param t - number
 */
export function nanoid(t = 21) {
  let e = "",
    r = crypto.getRandomValues(new Uint8Array(t))
  for (; t--; ) {
    let n = 63 & r[t]
    e +=
      n < 36
        ? n.toString(36)
        : n < 62
        ? (n - 26).toString(36).toUpperCase()
        : n < 63
        ? "_"
        : "-"
  }
  return e
}
