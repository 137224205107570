import { useState, useEffect } from "react"
import { breakpoint } from "../../theme/breakpoint"

/**
 * useMatchQuery matches a given query listening to window resize.
 * @param {string} query that is to be matched by the hook (optional)
 * @returns {boolean}
 */
export const useMatchQuery = (query?: string): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const mediaQuery = query || `(max-width: ${breakpoint.TABLET.MD}px)`

  const matchMediaChangeHandler: any = ({ matches }: { matches: boolean }) => {
    setIsMobile(matches)
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(mediaQuery)

    // check onload
    matchMediaChangeHandler(matchMedia)
    // watch the changes
    matchMedia.addListener(matchMediaChangeHandler)
    return () => {
      matchMedia.removeListener(matchMediaChangeHandler)
    }
  }, [mediaQuery])

  return isMobile
}
