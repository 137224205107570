import React from "react"
import { I18N } from "../../../../../i18n"
import { LinkProps, TabSection } from "../../Tab/TabSection"
import { TileList } from "../../../../atoms/Tile/TileList"
import { PatternTile } from "../../../../atoms/PatternTile/PatternTile"
import Badge from "dsl/src/atoms/Badge/Badge"
import { formatPriceWithCurrency } from "shared-libs/src/js/shared-components/formatted-currency/format-price-with-currency"
import { useTranslate } from "../../../../../hooks/useTranslate"
import { Pattern, PatternCategory } from "../../../../../../models/pattern"
import { useCurrency } from "../../../../../hooks/useCurrency"

export interface PatternCategoryProps {
  category: PatternCategory
  activePattern?: Pattern
  onExpandClick: (category: PatternCategory) => void
  onPatternClick: (pattern: Pattern) => void
  getPrice: (pattern: Pattern) => number
  expanded?: boolean
  disabled?: boolean
  initialElementsCount?: number
}

export function PatternCategorySection({
  category,
  activePattern,
  onExpandClick,
  onPatternClick,
  getPrice,
  expanded = false,
  disabled = false,
  initialElementsCount = 4,
}: PatternCategoryProps) {
  const t = useTranslate()
  const currency = useCurrency()

  const isHeaderVisible = !expanded
  const isLinkVisible =
    isHeaderVisible && category.patterns.length > initialElementsCount

  const title = isHeaderVisible ? t(category.titleIntl) : undefined
  const link: LinkProps | undefined = isLinkVisible
    ? {
        label: t(I18N.generic.seeAll),
        onClick: () => onExpandClick(category),
        analyticsSection: "left-tab-templates",
        analyticsType: "button",
        analyticsName: "see-all",
        analyticsId: category.name,
      }
    : undefined

  return (
    <TabSection key={category.name} title={title} link={link}>
      <TileList>
        {category.patterns.map((pattern, index) => {
          if (!expanded && index >= initialElementsCount) {
            return null
          }

          const price = getPrice(pattern)
          const isBadgeVisible = !!price && !!currency
          const isActive = activePattern === pattern

          return (
            <PatternTile
              key={pattern.id}
              active={isActive}
              disabled={disabled || isActive}
              pattern={pattern}
              onClick={() => onPatternClick(pattern)}
              badge={
                isBadgeVisible && (
                  <Badge>
                    {formatPriceWithCurrency(price.toString(), currency)}
                  </Badge>
                )
              }
              analyticsSection="left-tab-templates"
              analyticsType="thumbnail"
              analyticsName="pattern"
              analyticsId={pattern.name}
              e2eTarget="button"
              e2eTargetName="pattern"
            />
          )
        })}
      </TileList>
    </TabSection>
  )
}
