import fabric from "../../../../../../../libs/vendors/Fabric"
import {
  defaultBackgroundSettings,
  defaultTextSettings,
  Colors,
  placeholderMargin,
} from "./logo-placeholder-slot.helper"

export function createDesignerModePlaceholder(size: number): fabric.Group {
  const backgroundPlaceholder = new fabric.Circle(defaultBackgroundSettings)

  const placeholderText = new fabric.IText("LOGO PLACEHOLDER", {
    ...defaultTextSettings,
    fill: Colors.black,
    originY: "center",
  })

  const placeholder = new fabric.Group(
    [backgroundPlaceholder, placeholderText],
    {
      width: size,
      originX: "center",
      originY: "center",
      scaleX: size / (backgroundPlaceholder.width! + placeholderMargin),
      scaleY: size / (backgroundPlaceholder.width! + placeholderMargin),
    }
  )

  return placeholder
}
