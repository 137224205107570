import React from "react"
import { observer } from "mobx-react-lite"
import { useTranslate } from "../../../../hooks/useTranslate"
import { useContainerSet } from "../../../../../_containers-react/_editor-app-hooks"
import { I18N } from "../../../../i18n"
import { Tab } from "../Tab/Tab"
import { TabSection } from "../Tab/TabSection"
import { OptionPickerList } from "../../../atoms/OptionPicker/OptionPickerList"
import { OptionPicker } from "../../../atoms/OptionPicker/OptionPicker"

export const TabDevTools = observer(function () {
  const t = useTranslate()

  const [containerSet] = useContainerSet((c) => [c.tooling])

  if (!containerSet) {
    return null
  }

  const { tooling } = containerSet

  if (!tooling.available) {
    return null
  }

  const { screenGen } = tooling
  const { position } = screenGen.state

  const onDownloadThumbnailClick = () => {
    screenGen.saveScreen()
  }

  const onDownloadTemplatesClick = () => {
    screenGen.saveTemplates()
  }

  const onDownloadPatternsClick = () => {
    screenGen.savePatterns()
  }

  return (
    <Tab title={t(I18N.tabs.devTools)} e2eTargetName="dev-tools">
      <TabSection title="Camera position">
        <p>X: {position.x}</p>
        <p>Y: {position.y}</p>
        <p>Z: {position.z}</p>
      </TabSection>
      <TabSection>
        <OptionPickerList type="vertical">
          <OptionPicker
            label="Download current thumbnail"
            onClick={onDownloadThumbnailClick}
          />
          <OptionPicker
            label="Download all templates"
            onClick={onDownloadTemplatesClick}
          />
          <OptionPicker
            label="Download all patterns"
            onClick={onDownloadPatternsClick}
          />
        </OptionPickerList>
      </TabSection>
    </Tab>
  )
})
