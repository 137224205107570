import React, { useState, useLayoutEffect, useRef } from "react"
import cxBinder from "classnames/bind"
import ResizeObserver from "resize-observer-polyfill"
import styles from "./ScrollWithShadow.module.scss"
const cx = cxBinder.bind(styles)

export interface ScrollWithShadowProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  tooltip?: string
}

const ScrollWithShadow = (props: ScrollWithShadowProps) => {
  const { children, id, tooltip, ...other } = props

  const [canScrollDown, setCanScrollContent] = useState(false)
  const [tooltipVisible, setTooltipVisibility] = useState(true)

  const scrollWrapper = useRef<HTMLDivElement>(null)

  const handleScrollContent = () => {
    setCanScrollContent(
      !(
        scrollWrapper &&
        scrollWrapper.current &&
        scrollWrapper.current.scrollHeight - scrollWrapper.current.scrollTop ===
          scrollWrapper.current.clientHeight
      )
    )
  }

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleScrollContent()
    })
    resizeObserver.observe(scrollWrapper.current!)
    scrollWrapper.current!.addEventListener("scroll", handleScrollContent)

    return () => {
      if (scrollWrapper.current) {
        scrollWrapper.current!.removeEventListener(
          "scroll",
          handleScrollContent
        )
        resizeObserver.unobserve(scrollWrapper.current)
      }
    }
  }, [scrollWrapper])

  const showTooltip = tooltip && tooltipVisible && canScrollDown

  return (
    <div
      className={cx("scroll-wrapper", {
        "scroll-wrapper--bottom-shadowed": canScrollDown
      })}
      {...other}
    >
      <div id={id} className={cx("scroll-content")} ref={scrollWrapper}>
        {children}
      </div>
      {showTooltip && (
        <div
          className={styles.tooltip}
          onClick={() => setTooltipVisibility(false)}
        >
          <div>
            {tooltip} {"\u{25BC}"}
          </div>
        </div>
      )}
    </div>
  )
}

export const OptionalScrollWithShadow = (props: {
  children: React.ReactNode
  shouldRenderShadowScroll: boolean
  tooltip?: string
}) => {
  const { children, shouldRenderShadowScroll, tooltip } = props

  return shouldRenderShadowScroll ? (
    <ScrollWithShadow tooltip={tooltip}>{children}</ScrollWithShadow>
  ) : (
    <>{children}</>
  )
}

export { ScrollWithShadow, ScrollWithShadow as default }
