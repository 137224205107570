export const colors = {
  primary: {
    white: "#FFFFFF",
    "rich-blue": "#00061A",
    "dark-blue": "#0B38D5",
    "ph-blue": "#2757FF",
    "powder-blue": "#D6E6FF",
    "powder-blue-light": "#EAF2FF",

    "grey-600": "#555555",
    "grey-500": "#86868B",
    "grey-400": "#CFCFCF",
    "grey-300": "#E7E7E7",
    "grey-200": "#F2F2F2",
    "grey-100": "#F8F8F8",

    red: "#8A140C",
    "red-light": "#F0DBDB",

    yellow: "#DDC911",
    "yellow-light": "#F7F2BE",

    green: "#0B693D",
    "bright-green": "#00A141",

    olive: "#BAC97E",
    "olive-light": "#EBF0DB"
  },

  transparent: {
    "white-70": "rgba(#FFFFFF, 0.7)",
    "rich-blue-70": "rgba(#00061A, 0.7)"
  }
}
