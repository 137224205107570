import React from "react"

export interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  active?: boolean
  disabled?: boolean
  e2eTarget?: string
  e2eTargetName?: string
}

const LinkBase = (props: LinkProps) => {
  const { children, e2eTarget, e2eTargetName, ...other } = props

  return (
    <a
      e2e-target={e2eTarget || "link"}
      e2e-target-name={e2eTargetName}
      {...other}
    >
      {children}
    </a>
  )
}

export { LinkBase, LinkBase as default }
