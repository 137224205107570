import { VolumePriceDto } from "../../types"

/**
 * API returns last range.end as Ruby's Infinity,
 * so we need to change it to:
 * last range.end = last range.start
 * to be able to handle Quote Requests properly.
 */
export function adjustMaxRange(prices: VolumePriceDto[]): VolumePriceDto[] {
  const lastPrice = prices[prices.length - 1]
  lastPrice.range.end = lastPrice.range.start

  return prices
}
