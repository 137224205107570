import {
  DesignItemType,
  EcommerceDesignItem,
} from "../../../../../types/design.types"

export const CertificateType = "service-fsc-certificate"
export type CertificateType = typeof CertificateType

export class Certificate implements EcommerceDesignItem {
  constructor(
    public readonly variantType: DesignItemType,
    public readonly variantId: number,
    public readonly name: string,
    public readonly pricing: {
      taxRate?: number
      prices: Record<number, { netPrice: number; grossPrice?: number }>
    }
  ) {}

  public hasPriceFor(quantity: number): boolean {
    return this.pricing.prices[quantity] !== undefined
  }

  public get taxRate(): number | undefined {
    return this.pricing.taxRate
  }

  // We need to return 0 in case the pricing is not loaded for given quantity (to not throw an error).
  // It is handled by UI.

  public getNetPrice(lineItemQuantity: number): number {
    return this.pricing.prices[lineItemQuantity]?.netPrice || 0
  }

  public getGrossPrice(lineItemQuantity: number): number | undefined {
    return this.pricing.prices[lineItemQuantity]?.grossPrice || 0
  }
}

export interface GetCertificatesQueryOptions {
  region: string
  pricing: Record<number, number>
  productName: string
  taxCountryISO?: string
}

export const ServiceTypes = {
  OrderBased: "order_based",
  LineItemBased: "line_item_based",
} as const

export type ServiceTypes = (typeof ServiceTypes)[keyof typeof ServiceTypes]
