import THREE from "editor/src/libs/vendors/THREE"

class ObjLoader {
  public static load(path: string): Promise<THREE.Group> {
    return new Promise<THREE.Group>((resolve) => {
      const loader = new THREE.OBJLoader()

      loader.load(
        path,
        resolve,
        this.onProgres.bind(this),
        this.onError.bind(this)
      )
    })
  }

  private static onProgres(xhr) {
    // console.log((xhr.loaded / (xhr.total || 1)) * 100 + "% loaded")
  }

  private static onError(err) {
    throw new Error(`Can't load obj path ${err}`)
  }
}

export default ObjLoader
