import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import cxBinder from "classnames/bind"
import styles from "./SvgIcon.module.scss"
import { useContainerSet } from "../../../../_containers-react/_editor-app-hooks"

const cx = cxBinder.bind(styles)

export interface SvgIconProps {
  url: string
  size?: "m" | "s" | "xs" | "l" | "xl"
}

function stripSvg(svg: string) {
  return svg.replace(/<svg[^>]*>/, "").replace(/<\/svg>/, "")
}
const randomId = () => Math.random().toString(36).substring(2, 15)
export const SvgIcon = observer(({ url, size = "m" }: SvgIconProps) => {
  const [svg, setSvg] = React.useState<string>("")
  const [containerSet] = useContainerSet((c) => [c.envUtil])
  const [id] = React.useState(randomId())

  const disallowUseSvg = ["injected", "api-customers"].includes(
    containerSet?.envUtil?.appConfig.api.ecommerce.mode
  )

  useEffect(() => {
    async function fetchSvg(url: string) {
      const response = await fetch(url)
      const svg = await response.text()
      const strippedSvg = stripSvg(svg)
      setSvg(strippedSvg)
    }

    if (disallowUseSvg && !svg) {
      fetchSvg(url)
    }
  }, [url, disallowUseSvg])

  if (!containerSet) {
    return null
  }

  if (disallowUseSvg) {
    return (
      <svg className={cx("wrapper", size)}>
        <g id={id} dangerouslySetInnerHTML={{ __html: svg }} />
        <use href={`#${id}`} />
      </svg>
    )
  }

  return (
    <svg className={cx("wrapper", size)}>
      <use href={`${url}#icon`}></use>
    </svg>
  )
})
