import { Alpha2CountryCode } from "@ph/api-studio"
import { VariantCustomization } from "@ph/product-api"
import { ShippingDestinationStore } from "shared-libs/src/js/shared-views/forms/shipping-destination-form/store/shipping-destination.store"
import { UrlManipulatorProvider } from "../services/manipulators/url.manipulator"
import { EcommerceStore } from "../stores/ecommerce.store"
import { AllEditorEventsEmitter, eventTree } from "../events/editor.events"
import { ProductPricingStore } from "../stores/product-pricing.store"
import { ProductStore } from "../stores/product.store"
import { CartStore } from "../stores/cart.store"
import { ProductDesignStore } from "../stores/product-design.store"
import { DynamicVariantController } from "./dynamic-variant.controller"

export class MissingShippingDestinationError extends Error {}

export class EcommerceController {
  private shippingDestinationCallback?: () => void

  private readonly dynamicVariantController: DynamicVariantController
  private readonly ee: AllEditorEventsEmitter
  private readonly uri: UrlManipulatorProvider
  private readonly ecommerceStore: EcommerceStore
  private readonly productStore: ProductStore
  private readonly productPricingStore: ProductPricingStore
  private readonly shippingDestinationStore: ShippingDestinationStore
  private readonly cartStore: CartStore
  private readonly productDesignStore: ProductDesignStore

  constructor(
    services: {
      dynamicVariantController: DynamicVariantController
      ee: AllEditorEventsEmitter
      uri: UrlManipulatorProvider
    },
    stores: {
      ecommerceStore: EcommerceStore
      productStore: ProductStore
      productPricingStore: ProductPricingStore
      shippingDestinationStore: ShippingDestinationStore
      cartStore: CartStore
      productDesignStore: ProductDesignStore
    },
    private readonly isInstantPurchase: boolean
  ) {
    this.dynamicVariantController = services.dynamicVariantController
    this.ee = services.ee
    this.uri = services.uri
    this.ecommerceStore = stores.ecommerceStore
    this.productStore = stores.productStore
    this.productPricingStore = stores.productPricingStore
    this.shippingDestinationStore = stores.shippingDestinationStore
    this.cartStore = stores.cartStore
    this.productDesignStore = stores.productDesignStore

    this.ee.on(eventTree.pd.qtyChanged, this.onQtyChanged.bind(this))
    this.ee.on(eventTree.pd.metaLoaded, this.onDesignMetaLoaded.bind(this))
    this.ee.on(eventTree.pd.skuChangeEnded, this.onSkuChanged.bind(this))
  }

  public init(): void {
    const process = () => {
      const qtyFromUrl = this.uri.getQtyFromUrl() || 0

      if (this.isInstantPurchase && qtyFromUrl) {
        this.setSelectedQuantity(qtyFromUrl)
        return
      }

      if (!this.ecommerceStore.selectedQuantity) {
        this.setSelectedQuantity(
          Math.max(
            qtyFromUrl,
            this.productPricingStore.getMinQty(this.productStore.product)
          )
        )
      }
    }

    if (!this.productPricingStore.isLoadingPricing) {
      return process()
    }

    this.ee.once(eventTree.pricing.pricingLoaded, process)
  }

  public clearDynamicVariant(): void {
    this.dynamicVariantController.clear()
  }

  public async loadDynamicVariant(
    sku: string,
    customization?: VariantCustomization
  ): Promise<void> {
    return this.dynamicVariantController.load(sku, customization)
  }

  public async addToCart(): Promise<void> {
    if (this.ecommerceStore.isSkippingDesign) {
      const { ecommerceSku, selectedQuantity } = this.ecommerceStore

      this.uri.redirectToDesignLaterUrl(ecommerceSku, selectedQuantity)
    } else {
      await this.cartStore?.triggerAddingLineItemToCart({
        shouldLockDesign: !this.productDesignStore.isDesignLocked,
      })
    }
  }

  public redirectToQuoteRequestForm(): void {
    const { quoteRequestFormUrl } = this.ecommerceStore

    if (!quoteRequestFormUrl) {
      throw new Error("Quote Request Form url is not provided")
    }

    const doRedirect = () => (window.location.href = quoteRequestFormUrl)

    this.ee.once(eventTree.pd.saved, doRedirect)
    this.ee.once(eventTree.pd.saveSkippedNotValid, doRedirect)
    this.ee.emit(eventTree.pd.saveTriggered)
  }

  public async saveShippingDestination(
    countryIso: Alpha2CountryCode,
    zipCode: string
  ): Promise<void> {
    await this.shippingDestinationStore.saveShippingDestination(
      countryIso,
      zipCode
    )

    if (this.shippingDestinationCallback) {
      this.shippingDestinationCallback()
      this.shippingDestinationCallback = undefined
    }
  }

  public showShippingDestinationModal(callback?: () => void): void {
    this.shippingDestinationCallback = callback
    this.shippingDestinationStore.setIsModalOpen(true)
  }

  public closeShippingDestinationModal(): void {
    this.shippingDestinationCallback = undefined
    this.shippingDestinationStore.setIsModalOpen(false)
  }

  public setSelectedQuantity(quantity: number): void {
    const { product } = this.productStore
    const unitPrice = this.productPricingStore.getUnitPrice(product, quantity)
    const hasShippingDestination =
      !!this.shippingDestinationStore.shippingDestination

    if (!this.isInstantPurchase && !unitPrice.type && !hasShippingDestination) {
      return this.showShippingDestinationModal(() =>
        this.setSelectedQuantity(quantity)
      )
    }

    this.ecommerceStore.setSelectedQuantity(quantity)
  }

  private async onQtyChanged(qty: number): Promise<void> {
    const { product, productSku, customization } = this.productStore

    if (
      this.isInstantPurchase ||
      this.productPricingStore.isPricingLoaded(product, qty)
    ) {
      return
    }

    await this.loadDynamicVariant(productSku, customization)
  }

  private async onSkuChanged(): Promise<void> {
    const { product } = this.productStore

    const minQuantity = this.productPricingStore.getMinQty(product)
    const quantity = this.ecommerceStore.selectedQuantity

    if (quantity < minQuantity) {
      this.ecommerceStore.setSelectedQuantity(minQuantity)
    }
  }

  private onDesignMetaLoaded(): void {
    const process = () => {
      this.loadDynamicVariant(
        this.productStore.productSku,
        this.productStore.customization
      ).catch(() => {
        // Do not throw error when no dynamic variant found for saved design.
        // Quote Request button will be shown as fallback.
        this.ecommerceStore.setDynamicVariantError()
      })
    }

    if (!this.productPricingStore.isLoadingPricing) {
      return process()
    }

    this.ee.once(eventTree.pricing.pricingLoaded, process)
  }
}
